import React, { Component, createRef } from 'react'
import { RouteComponentProps } from 'react-router'
import Radium from 'radium'
import { connect } from 'react-redux'

import { GridSize } from '@material-ui/core/Grid'
import Icon from '@mdi/react'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { mdiChartDonut, mdiAlertCircle, mdiArrowRightBoldCircle } from '@mdi/js'

import { ColorPalette } from '../../config/colors'
import { ActionType } from '../../store/actions/actions'
import completedScreens from '../completedScreens'
import { APP_VERSION } from '../../cloud-config'
import { toLowerCaseCustom, sortArrayOfObjects, formatDateAndTime } from '../../utils'
import { AllScreenNames } from '../../config'
import NavigationBar from '../../components/Navigation/NavigationBar'
import SectionHeader from '../../components/Headings/SectionHeaderPrimary'
import LoadingModal from '../../components/Modals/LoadingModal'
import AlertModalOneButton from '../../components/Modals/AlertModalOneButton'
import SideMenu from '../../components/Navigation/SideMenu'
import ProfileViewingOptions from '../../components/GeneralUI/ProfileViewingOptions/ProfileViewingOptions'
import DataTable from '../../components/Tables/DataTable/DataTable'
import ButtonGeneric from '../../components/BaseComponents/Buttons/ButtonGeneric'
import { PeopleFlowCombinedReducer } from '../../store'
import { Profile } from '../../models'
import { SessionService } from '../../services'
import { fetchPerformanceData } from '../../provider/remoteData'
import { PeopleRouteState } from '../../types'

const columnConfig = [
  { id: 'parsedDate', label: 'Date' },
  { id: 'actual', label: 'Actual (actUnit)' },
  { id: 'target', label: 'Target (tarUnit)' },
  { id: 'margin', label: 'Margin (marUnit)' },
]

interface PerformanceProps extends RouteComponentProps {
  idPassport: string
  password: string
  selectedAssociation: string
  profile: Profile
  profilePic: string
  currentScreen: AllScreenNames
  hasWorkforceAccess: boolean

  updateState: (data: any) => void
  changeScreen: (screen: string) => void
}

interface PerformanceState {
  loadingModalOpen: boolean
  warningModalOpen: boolean
  accountModalOpen: boolean
  forgotPasswordModalOpen: boolean
  settingsModalOpen: boolean
  loadingModalMessage: string
  warningModalHeader: string
  warningModalMessage: string
  // sideMenuComponents: JSX.Element;
  sideMenuVisible: boolean
  tableWidth: GridSize
  performanceData: any[]
  selectedRowItemId: string
  authorisedItemIds: string[]
  columnConfig: { id: string; label: string }[]
  latestActionedTimestamp: number
  latestActionableTimestamp: number
  customComponentConfig: any
  height: number
}

class Performance extends Component<PerformanceProps, PerformanceState> {
  tableRef: React.RefObject<DataTable> | null = null
  sideMenuComponents = (<div />)

  initialModalState = {
    loadingModalOpen: false,
    warningModalOpen: false,
    accountModalOpen: false,
    forgotPasswordModalOpen: false,
    settingsModalOpen: false,
    height: window.innerHeight,
  }

  state: PerformanceState = {
    ...this.initialModalState,
    loadingModalMessage: '',
    warningModalHeader: '',
    warningModalMessage: '',
    // sideMenuComponents: <div />,
    sideMenuVisible: true,
    tableWidth: 9,
    performanceData: [],
    selectedRowItemId: '',
    authorisedItemIds: [],
    columnConfig,
    latestActionedTimestamp: 0,
    latestActionableTimestamp: 0,
    customComponentConfig: {
      leftComponentWidth: 50,
      rightComponentWidth: 60,
    },
  }

  async componentDidMount() {
    const { idPassport, password, profile, history, selectedAssociation, updateState } = this.props
    try {
      window.addEventListener('onbeforeunload', this.goToProfiles)
      this.tableRef = createRef()
      updateState({
        currentSection: AllScreenNames.PEOPLE,
        currentScreen: AllScreenNames.PERFORMANCE,
      })
      this.setState({ loadingModalOpen: true, loadingModalMessage: 'Loading performance data...' })

      const { name, surname } = profile.getPersonalInfo()
      const pk = profile.getPk()
      if (!pk) {
        SessionService.logout()
        history.push('/login')
        return
      }

      this.initialiseSideMenu(name, surname, pk)
      const token = await SessionService.prepareAuthTokens(idPassport, password)
      const rawPerformanceData = await fetchPerformanceData(selectedAssociation, pk, APP_VERSION, token)
      const { performanceData, authorisedItemIds, columnConfig, latestActionedTimestamp, latestActionableTimestamp } =
        this.initialiseUI(rawPerformanceData)

      this.setState(
        {
          ...this.initialModalState,
          performanceData,
          authorisedItemIds,
          columnConfig,
          latestActionedTimestamp,
          latestActionableTimestamp,
        },
        () => {
          if (!this.tableRef?.current) {
            return
          }
          this.tableRef.current.reload()
        },
      )
    } catch (error) {
      // console.log("error: ", error)
      this.closeModals()
    }
  }

  viewDisciplinaryAction(disciplineItemId: string, date: string) {
    if (date === this.state.latestActionableTimestamp.toString()) {
      if (disciplineItemId) {
        this.props.updateState({ disciplineItemUidToLoad: disciplineItemId })
        this.props.history.push(`/people/discipline`)
      } else {
        this.displayWarning({ code: 'ComingSoon' })
      }
    } else {
      this.displayWarning({ code: 'Expired' })
    }
  }

  initialiseSideMenu = (name: string, surname: string, idPassport: string) => {
    this.sideMenuComponents = (
      <ProfileViewingOptions
        profilePic={this.props.profilePic}
        name={`${name} ${surname}`}
        idPassport={idPassport}
        onClick={(screen: AllScreenNames) => this.sideMenuHandler(screen)}
        currentScreen={this.props.currentScreen}
      />
    )
    // this.setState({ sideMenuComponents });
  }

  generateRowComponent(config: any): JSX.Element | null {
    let component = null
    if (config.side === 'left') {
      component = (
        <div
          style={{
            ...styles.leftComponentContainer,
            width: this.state.customComponentConfig.leftComponentWidth,
          }}>
          <Icon
            path={config.shouldDiscipline ? mdiAlertCircle : mdiChartDonut}
            size={1.1}
            color={config.shouldDiscipline ? ColorPalette.WARNING_RED : ColorPalette.LIGHT_GREY}
          />
        </div>
      )
    } else if (config.side === 'right') {
      component = (
        <button
          key={`${config.date}`}
          style={{
            ...styles.rightComponentContainer,
            width: this.state.customComponentConfig.rightComponentWidth,
          }}
          onClick={() => this.viewDisciplinaryAction(config.disciplineItemUid, config.date)}>
          <Icon
            path={mdiArrowRightBoldCircle}
            size={0.85}
            color={config.disciplineItemUid ? ColorPalette.WARNING_RED : ColorPalette.LIGHT_GREY}
          />
        </button>
      )
    }
    return component
  }

  initialiseUI(intialPerformanceData: any[]) {
    try {
      let latestActionedTimestamp = 0
      let latestActionableTimestamp = 0
      let formattedData: any[] = []
      let authorisedItemIds: string[] = []
      intialPerformanceData.forEach((monthData) => {
        const monthDataWithId = [...monthData.data].map((performanceItem) => {
          const { day, month, year } = formatDateAndTime(new Date(parseInt(performanceItem.date)))
          performanceItem = {
            ...performanceItem,
            id: performanceItem.date, // Need 'id' key for UI table
            parsedDate: `${day} ${month} ${year}`,
            leftComponent: this.generateRowComponent({
              side: 'left',
              shouldDiscipline: performanceItem.result,
              date: performanceItem.date,
            }),
            rightComponent: this.generateRowComponent({
              side: 'right',
              disciplineItemUid: performanceItem.disciplineItemUid,
              date: performanceItem.date,
            }),
          }

          if (performanceItem.disciplineItemUid) {
            const timestamp = parseInt(performanceItem.disciplineItemUid.split('_')[1])
            latestActionedTimestamp = timestamp >= latestActionedTimestamp ? timestamp : latestActionedTimestamp
          } else if (performanceItem.date) {
            const timestamp = parseInt(performanceItem.date)
            if (timestamp >= latestActionableTimestamp) {
              latestActionableTimestamp = timestamp
              authorisedItemIds = [performanceItem.id]
            }
          }
          return performanceItem
        })
        formattedData = [...formattedData, ...monthDataWithId]
      })
      let existingItemIds: any = {}
      formattedData = formattedData.filter((item) => {
        if (!existingItemIds[item.id]) {
          existingItemIds[item.id] = true
          return true
        } else {
          return false
        }
      })
      const performanceData = sortArrayOfObjects('date', 'descending', formattedData)
      const completeColumnConfig = this.formatTableHeader(performanceData, this.state.columnConfig)
      return {
        performanceData,
        authorisedItemIds,
        columnConfig: completeColumnConfig,
        latestActionedTimestamp,
        latestActionableTimestamp,
      }
    } catch (error) {
      throw error
    }
  }

  formatTableHeader(performanceData: any[], columnConfig: { id: string; label: string }[]) {
    if (performanceData.length) {
      columnConfig = columnConfig.map((columnItem) => {
        columnItem.label = columnItem.label.includes('actUnit')
          ? (columnItem.label = columnItem.label.replace('actUnit', performanceData[0].actualUnit))
          : columnItem.label.replace('actUnit', '')
        columnItem.label = columnItem.label.includes('tarUnit')
          ? (columnItem.label = columnItem.label.replace('tarUnit', performanceData[0].targetUnit))
          : columnItem.label.replace('tarUnit', '')
        columnItem.label = columnItem.label.includes('marUnit')
          ? (columnItem.label = columnItem.label.replace('marUnit', performanceData[0].marginUnit))
          : columnItem.label.replace('marUnit', '')
        return columnItem
      })
    }
    return columnConfig
  }

  closeModals() {
    this.setState({ ...this.initialModalState })
  }

  sideMenuHandler(screen: AllScreenNames): void {
    if (completedScreens.includes(screen)) {
      this.props.updateState({ currentScreen: screen })
      this.props.history.push(`/people/${toLowerCaseCustom(screen.split(' ').join(''))}`)
    } else {
      this.props.history.push('/comingsoon')
    }
  }

  goToProfiles() {
    this.closeModals()
    window.scrollTo(0, 0)
    setTimeout(
      () =>
        this.props.history.push('/people', {
          customFilterState: (this.props.location.state as PeopleRouteState).prevCustomFilterState,
        }),
      30,
    )
  }

  displayWarning(error: { code: string; message?: string }) {
    let header = 'Warning'
    let warning = ''

    try {
      if (error.code === 'NetworkError') {
        warning = 'Seems like your internet connection is down. Reconnect to the network, then try again.'
      } else if (error.code === 'ComingSoon') {
        header = 'Coming soon'
        warning = 'This feature will be available soon.'
      } else if (error.code === 'Expired') {
        header = 'Expired'
        warning =
          'This item has expired. You may only take disciplinary action on the latest unactioned offence. \n\n(This ensures that your employees are timeously warned and that they are given an opportunity to rectify their behaviour between warnings).'
      } else if ('message' in error) {
        warning =
          "The following error message was returned when logging in:\n\n'" +
          error.message +
          "'. \n\nRefresh the page and try again. If unsuccessful, then contact tech support"
      } else {
        warning = 'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
      }
    } catch (error) {
      warning = 'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
    }

    this.setState({
      ...this.initialModalState,
      warningModalOpen: true,
      warningModalHeader: header,
      warningModalMessage: warning,
    })
  }

  render() {
    return (
      <div style={styles.container}>
        <NavigationBar match={this.props.match} location={this.props.location} history={this.props.history} />

        <SectionHeader
          style={styles.sectionHeader}
          disabled={true}
          onClick={() => this.setState({ sideMenuVisible: false, tableWidth: 10 })}>
          {this.props.currentScreen}
        </SectionHeader>

        <div style={styles.contentContainer}>
          <SideMenu visible={this.state.sideMenuVisible} menuComponents={this.sideMenuComponents} />
          <div style={styles.rightSide}>
            <ButtonGeneric
              style={styles.backButton}
              iconBefore={<ChevronLeftIcon style={styles.buttonIconStyle} />}
              label={'BACK'}
              onClick={() => this.goToProfiles()}
            />
            <div style={styles.rightSideContent}>
              <DataTable
                ref={this.tableRef}
                tableData={this.state.performanceData}
                columnConfig={this.state.columnConfig}
                customComponentConfig={this.state.customComponentConfig}
                tableWidth={this.state.tableWidth}
                onRowClick={() => ({})}
                selectedRowItemId={this.state.selectedRowItemId}
                authorisedItemIds={this.state.authorisedItemIds}
                disableRowClick={true}
              />
            </div>
          </div>
        </div>

        <AlertModalOneButton
          open={this.state.warningModalOpen}
          header={this.state.warningModalHeader}
          body={this.state.warningModalMessage}
          buttonLabel={'Ok'}
          onClick={() => this.closeModals()}
        />
        <LoadingModal open={this.state.loadingModalOpen}>{this.state.loadingModalMessage}</LoadingModal>
        <AlertModalOneButton
          open={!this.props.hasWorkforceAccess}
          header={'Not Authorised'}
          body={"You don't have permission to view performance data."}
          buttonLabel={'Ok'}
          opaqueBackground={true}
          onClick={() => this.props.history.goBack()}
        />
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255, 1), rgba(209,210,230, 1))',
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto 1.5%',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    paddingInline: 'max(2em, 2%)',
    overflow: 'hidden',
  },
  rightSideContent: {
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    display: 'flex',
    flex: 1,
    backgroundColor: ColorPalette.CARD_WHITE,
  },
  iconStyle: {
    color: ColorPalette.DARK_GREY,
    width: '1.1rem',
    height: '1.1rem',
    marginRight: 10,
    marginLeft: 10,
  },
  leftComponentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rightComponentContainer: {
    height: 40,
    borderRadius: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    outline: 0,
    backgroundColor: ColorPalette.CARD_WHITE,
    ':hover': {
      backgroundColor: ColorPalette.BUTTON_HOVER_GREY,
    },
    ':active': {
      backgroundColor: ColorPalette.BUTTON_ACTIVE_GREY,
    },
  },
  buttonIconStyle: {
    color: ColorPalette.PRIMARY_TEXT,
    width: '1.2rem',
    height: '1.2rem',
  },
  backButton: {
    fontWeight: '550',
    fontSize: '0.8rem',
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    ':hover': {
      color: ColorPalette.PRIMARY_BLUE,
    },
    ':active': {
      color: ColorPalette.DARK_GREY,
    },
  },
}

const mapStateToProps = (state: PeopleFlowCombinedReducer) => {
  return {
    idPassport: state.sessionManager.idPassport,
    password: state.sessionManager.password,
    selectedAssociation: state.sessionManager.selectedAssociation,
    currentSection: state.sessionManager.currentSection,
    currentScreen: state.sessionManager.currentScreen,
    profile: state.sessionManager.profile,
    profilePic: state.sessionManager.profilePic,
    hasWorkforceAccess: state.sessionManager.hasWorkforceAccess,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateState: (data: any) => dispatch({ type: ActionType.UPDATE_STATE, data }),
    changeScreen: (screen: string) => dispatch({ type: ActionType.CHANGE_SCREEN, data: { currentScreen: screen } }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Radium(Performance))
