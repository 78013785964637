export interface IRawDataChanges {
    rawData: Record<
        string,
        Record<
            string,
            {
                old: any;
                new: any;
                name: string;
                surname: string;
                appVersion: string;
            }
        >
    >;
}

export default class UpdateTrackerItemRawData {
    deviceUUID: string;
    idPassport: string;
    uid: string;
    name: string;
    surname: string;
    changes: IRawDataChanges;

    constructor(idPassport: string, uid: string, name: string, surname: string, deviceUUID: string) {
        this.deviceUUID = deviceUUID;
        this.idPassport = idPassport;
        this.uid = uid;
        this.name = name;
        this.surname = surname;
        this.changes = {
            rawData: {},
        };
    }
}
