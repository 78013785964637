type ValidSizing = "SMALL" | "NORMAL" | "LARGE" | "XLARGE";

export interface ITextStyle {
    fontSizeName: ValidSizing;
    fontWeight: ValidSizing;
    fontStyle: ValidSizing;
}

export class FormFieldConfig {
    page: any;
    label: string;
    key: string;
    coordinates: { x: number; y: number };
    textStyle: ITextStyle;

    constructor(page: any) {
        this.label = "";
        this.key = "";
        this.page = page;
        this.coordinates = { x: 50, y: 50 };
        this.textStyle = {
            fontSizeName: "SMALL",
            fontWeight: "NORMAL",
            fontStyle: "NORMAL",
        };
    }
}
