import { requestConfig, requestInstances } from '../screens/Requests/dummyData'
import { writeLocalFileToRemoteStorage, addUnderScores } from '../utils'
import {
  ClientRequestsFactory,
  RequestInstanceConfigFactory,
  IRequestInstanceConfig,
  IClientRequestInstance,
  ClientRequestInstanceFactory,
} from '../models/clientRequests'
import { SessionService } from './sessionService'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

export interface IRequestService {
  createNewRequestInstance(requestConfig: IRequestInstanceConfig, userFullName: string): IClientRequestInstance
  fetchRequestConfig(selectedAssociation: string): Promise<any>
  fetchRequestBatch(selectedAssociation: string, startRequestId?: string | undefined): Promise<any>
  uploadForms(
    selectedAssociation: string,
    selectedForms: Record<string, any>[],
    requestName: string,
  ): Promise<Record<string, any>[]>
}

class RequestService implements IRequestService {
  async fetchRequestConfig(selectedAssociation: string) {
    try {
      // const token = await SessionService.prepareAuthTokens();
      // const params: Record<string, any> = { selectedEmployer: selectedAssociation };
      await this.mockhttpRequest()
      // const { result } = (
      //     await httpPost("insert-service-category-here", "/insert/insert-endpoint-name", params, token)
      // ).data;
      // if (result !== "success") {
      //     throw { code: "FetchRequestConfigError" };
      // }
      let fetchedRequestConfig: Record<string, any> = requestConfig
      let allRequestConfig: Record<string, IRequestInstanceConfig> = {}
      Object.keys(fetchedRequestConfig).forEach((requestName) => {
        allRequestConfig[requestName] = RequestInstanceConfigFactory.create(fetchedRequestConfig[requestName])
      })
      return allRequestConfig
    } catch (error) {
      throw error
    }
  }

  async fetchRequestBatch(selectedAssociation: string, startRequestId?: string | undefined) {
    try {
      // const token = await SessionService.prepareAuthTokens();
      // const params: Record<string, any> = { selectedEmployer: selectedAssociation, startRequestId };
      await this.mockhttpRequest()
      // const { result } = (
      //     await httpPost("insert-service-category-here", "/insert/insert-endpoint-name", params, token)
      // ).data;
      // if (result !== "success") {
      //     throw { code: "FetchRequestBatchError" };
      // }
      return ClientRequestsFactory.create(requestInstances)
    } catch (error) {
      throw error
    }
  }

  mockhttpRequest(): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(), 500)
    })
  }

  generateSteps = (stepNames: string[]) => {
    const stepsFormat: Record<string, any> = {
      formUpload: {
        forms: [],
        active: true,
        completed: false,
      },
      auth: {
        auths: [],
        active: false,
        completed: false,
      },
      email: {
        defaultEmails: [],
        sentTo: [],
        autoSend: false,
        active: false,
        completed: false,
      },
    }
    let steps: Record<string, any> = {}
    stepNames.forEach((stepName) => (steps[stepName] = stepsFormat[stepName]))
    return steps
  }

  createNewRequestInstance(requestConfig: IRequestInstanceConfig, userFullName: string): IClientRequestInstance {
    const creationTime = new Date().getTime()
    const instance = {
      id: creationTime,
      createdUnixMs: creationTime,
      status: 'OPEN',
      createdBy: userFullName,
      requestConfig,
      steps: this.generateSteps(requestConfig.getOrderedStepNameArray()),
      logs: [],
    }
    return ClientRequestInstanceFactory.create(instance)
  }

  async uploadForms(
    selectedAssociation: string,
    selectedForms: Record<string, any>[],
    requestName: string,
  ): Promise<Record<string, any>[]> {
    const currentTime = new Date().getTime()
    let updatedFileData: Record<string, any>[] = []
    await Promise.all(
      selectedForms.map(async (fileObject) => {
        const path = `Companies/${selectedAssociation}/Requests/Forms/${requestName.toUpperCase()}/${currentTime}___${addUnderScores(
          fileObject.name,
        )}`
        await writeLocalFileToRemoteStorage(path, fileObject.file, fileObject.type || '')
        fileObject.url = path
        updatedFileData.push(fileObject)
      }),
    )
    return updatedFileData
  }
}

export class RequestServiceFactory {
  static create(): IRequestService {
    return new RequestService()
  }
}
