type DotProps = {
    title?: string;
    colour: string;
    styles?: React.CSSProperties;
};

export const Dot = (props: DotProps) => {
    let style: React.CSSProperties = { ...styles.dot, backgroundColor: props.colour };
    if (props.styles) {
        style = { ...style, ...props.styles };
    }
    return <div title={props.title} style={style} />;
};

const styles = {
    dot: {
        width: 8,
        height: 8,
        borderRadius: 30,
    },
};
