import { ColorPalette } from '../../config/colors'

import React, { Component } from 'react'
import Radium from 'radium'
import ButtonBlue from '../BaseComponents/Buttons/ButtonBlue'
import ButtonGrey from '../BaseComponents/Buttons/ButtonGrey'
import { Property } from 'csstype'

interface AlertModalTwoButtonProps {
  open: boolean
  header?: string
  body: string | React.ReactElement
  buttonLabel1: string
  buttonLabel2: string
  buttonDisabled1?: boolean
  buttonDisabled2?: boolean
  style?: React.CSSProperties
  bodyStyle?: React.CSSProperties
  buttonStyle?: React.CSSProperties
  onClick1: () => void
  onClick2: () => void
  dismiss: () => void
}

interface AlertModalTwoButtonState {
  open: boolean
  mounted: boolean
}

class AlertModalTwoButton extends Component<AlertModalTwoButtonProps, AlertModalTwoButtonState> {
  state: AlertModalTwoButtonState = {
    open: false,
    mounted: false,
  }

  componentDidMount() {
    this.setState({ open: this.props.open, mounted: this.props.open })
  }

  componentDidUpdate(prevProps: AlertModalTwoButtonProps) {
    if (prevProps.open !== this.props.open) {
      this.setState({ open: this.props.open }, () =>
        setTimeout(
          () => {
            this.setState({ mounted: this.props.open })
          },
          this.props.open ? 0 : 50,
        ),
      )
    }
  }

  render() {
    styles.screenContainer = {
      ...styles.screenContainer,
      animation: this.state.open ? 'x 0.05s ease-in' : 'x 0.1s ease-out',
      animationName: this.state.open ? fadeInBackground : fadeOutBackground,
    }

    styles.cardContainer = {
      ...styles.cardContainer,
      animation: this.state.open ? 'x 0.05s ease-in' : 'x 0.1s ease-out',
      animationName: this.state.open ? fadeInCard : fadeOutCard,
    }

    let modalContents = null
    if (this.state.mounted) {
      modalContents = (
        <>
          <button style={styles.screenContainer} onClick={this.props.dismiss} />
          <div style={{ ...styles.cardContainer, ...this.props.style }}>
            {this.props.header && (
              <h1 style={{ ...styles.textStyle, fontWeight: 'bold', fontSize: '1.2rem' }}>{this.props.header}</h1>
            )}
            <div style={{ ...styles.textStyle, ...this.props.bodyStyle }}>{this.props.body}</div>

            <div style={{ ...styles.buttonContainer, ...this.props.buttonStyle }}>
              <ButtonGrey
                style={{ width: '48%' }}
                onClick={() => this.props.onClick1()}
                disabled={this.props.buttonDisabled1}>
                {this.props.buttonLabel1}
              </ButtonGrey>
              <ButtonBlue
                style={{ width: '48%' }}
                onClick={() => this.props.onClick2()}
                disabled={this.props.buttonDisabled2}>
                {this.props.buttonLabel2}
              </ButtonBlue>
            </div>
          </div>
        </>
      )
    }
    return modalContents
  }
}

const fadeInBackground = Radium.keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
}) as Property.AnimationName

const fadeOutBackground = Radium.keyframes({
  '0%': { opacity: 1 },
  '100%': { opacity: 0 },
}) as Property.AnimationName

const fadeInCard = Radium.keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1, marginRight: window.innerHeight * 0.05 },
}) as Property.AnimationName

const fadeOutCard = Radium.keyframes({
  '0%': { marginRight: window.innerHeight * 0.05 },
  '50%': { opacity: 0 },
  '100%': { marginRight: -window.innerHeight * 0.13, opacity: 0 },
}) as Property.AnimationName

let styles: Record<string, React.CSSProperties> = {
  screenContainer: {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    width: window.innerWidth,
    height: window.innerHeight,
    backgroundColor: ColorPalette.MODAL_BACKGROUND_OVERLAY,
    zIndex: 10000,
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 0,
    border: 'none',
    overflow: 'hidden',
  },
  cardContainer: {
    zIndex: 10000,
    position: 'absolute',
    top: window.innerHeight * 0.16,
    left: window.innerWidth * 0.4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
    backgroundColor: ColorPalette.CARD_WHITE,
    marginTop: window.innerHeight * 0.06,
    marginRight: window.innerHeight * 0.05,
    width: window.innerWidth * 0.24,
    padding: '25px 30px 30px 30px',
    paddingBottom: 30,
    borderRadius: 8,
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 15,
  },
  textStyle: {
    fontSize: '0.9rem',
    fontWeight: '300',
    textAlign: 'center',
    color: ColorPalette.PRIMARY_TEXT,
    padding: '0px 10px',
    marginTop: 0,
  },
}

export default Radium(AlertModalTwoButton)
