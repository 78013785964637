import React from 'react'

import TextInputBasic from './TextInputBasic'
import { ColorPalette } from '../../../config/colors'
import { toUpperCaseCustom } from '../../../utils'

interface TextInputOutlinedProps {
  value: string
  placeholder: string
  disabled: boolean
  style?: React.CSSProperties | undefined
  textHandler: (e: React.ChangeEvent<{ value: string }>) => void
  onFocus?: (e: React.FocusEvent) => void
  onClick?: (e: React.MouseEvent) => void
  type?: string
  label?: string
  textAreaRows?: number
  autoFocus?: boolean
  icon?: React.ReactElement
}

function onFocus(props: TextInputOutlinedProps, e: React.FocusEvent): void {
  if (props.onFocus === undefined) {
    return
  }
  props.onFocus(e)
}

const textInputOutlined: React.FC<TextInputOutlinedProps> = (props: TextInputOutlinedProps) => {
  return (
    <div style={{ ...styles.container, ...props.style }} onClick={props.onClick}>
      <p style={{ ...styles.label }}>{toUpperCaseCustom(props.label)}</p>
      {props.icon ? (
        <div style={{ display: 'flex' }}>
          {props.icon}
          <TextInputBasic
            style={styles.textStyle}
            value={props.value}
            placeholder={props.placeholder}
            disabled={props.disabled}
            onFocus={(e) => onFocus(props, e)}
            onChange={props.textHandler}
            type={props.type}
            textAreaRows={props.textAreaRows}
            autoFocus={props.autoFocus}
          />
        </div>
      ) : (
        <TextInputBasic
          style={styles.textStyle}
          value={props.value}
          placeholder={props.placeholder}
          disabled={props.disabled}
          onFocus={(e) => onFocus(props, e)}
          onChange={props.textHandler}
          type={props.type}
          textAreaRows={props.textAreaRows}
          autoFocus={props.autoFocus}
        />
      )}
    </div>
  )
}

const styles = {
  container: {
    zIndex: 1,
    marginTop: 20,
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid rgba(195,205,225,0.6)`,
    borderRadius: 12,
    paddingBottom: 15,
  },
  label: {
    color: ColorPalette.LIGHT_GREY,
    backgroundColor: ColorPalette.CARD_WHITE,
    zIndex: 2,
    marginTop: -8,
    alignSelf: 'center',
    fontSize: '0.65rem',
    paddingLeft: 10,
    paddingRight: 10,
  },
  textStyle: {
    color: ColorPalette.PRIMARY_TEXT,
    paddingBottom: 8,
    borderBottomWeight: 1,
    borderBottomStyle: 'solid' as 'solid',
    borderBottomColor: ColorPalette.PRIMARY_TEXT,
    marginLeft: 15,
    marginRight: 20,
    marginTop: 2,
    width: '92%',
  },
}

export default textInputOutlined
