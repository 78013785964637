import { ColorPalette } from '../../config/colors'

import React, { Component } from 'react'

import profileAvatar from '../../assets/profileAvatar.png'

interface LogHeaderSectionProps {
  mode: string
  title: string
  metaData: string
}

interface LogHeaderSectionState {
  profilePic: string
  profilePicValid: boolean
}

class LogHeaderSection extends Component<LogHeaderSectionProps, LogHeaderSectionState> {
  state = {
    profilePic: '',
    profilePicValid: true,
  }

  render() {
    styles.profilePicDimensionContainer = {
      ...styles.profilePicDimensionContainer,
      width: window.innerHeight * (this.state.profilePicValid ? 0.065 : 0.055),
      height: window.innerHeight * (this.state.profilePicValid ? 0.065 : 0.055),
    }

    styles.profilePicStyle = {
      ...styles.profilePicStyle,
      width: window.innerHeight * (this.state.profilePicValid ? 0.065 : 0.055),
    }

    let image = (
      <div style={styles.imageContainer}>
        <div style={styles.profilePicDimensionContainer}>
          <img
            style={styles.profilePicStyle}
            src={this.state.profilePicValid ? this.state.profilePic : profileAvatar}
            onError={() => this.setState({ profilePicValid: false })}
            alt={'Data Log Header'}
          />
        </div>
      </div>
    )
    if (this.props.mode === 'individual') {
      image = <div style={styles.imagePlaceholder} />
    }

    return (
      <div style={styles.container}>
        {image}

        <div style={{ ...styles.textContainer, ...styles.shadow }}>
          <p style={{ ...styles.titleStyle }}>{this.props.title}</p>
          <p style={{ ...styles.metaDataStyle }}>{this.props.metaData}</p>
        </div>
      </div>
    )
  }
}

const styles: Record<string, React.CSSProperties> = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: 55,
    marginBottom: 15,
    width: '100%',
  },
  profilePic: {
    width: 46,
    height: 46,
    borderRadius: 30,
  },
  shadow: {
    shadowOpacity: 0.16,
    shadowRadius: 2,
    shadowOffset: {
      width: 0,
      height: 2,
    },
  } as React.CSSProperties,
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '85%',
    marginLeft: 10,
    paddingLeft: 5,
    paddingRight: 5,
    marginTop: 5,
    borderBottom: `1px solid ${ColorPalette.PRIMARY_TEXT}`,
  },
  titleStyle: {
    fontSize: '0.8rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginTop: 2,
    marginBottom: 0,
    color: ColorPalette.PRIMARY_TEXT,
  },
  metaDataStyle: {
    fontSize: '0.75rem',
    marginTop: 3,
    color: ColorPalette.SECONDARY_TEXT,
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: ColorPalette.DARK_GREY,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.45)',
    marginTop: 0,
    width: window.innerHeight * 0.065,
    height: window.innerHeight * 0.065,
    borderRadius: window.innerHeight * 0.5,
  },
  profilePicDimensionContainer: {
    overflow: 'hidden',
    borderRadius: window.innerHeight * 0.2,
  },
  profilePicStyle: {
    height: 'auto',
  },
  imagePlaceholder: {
    height: 46,
    width: 46,
    borderRadius: 30,
    border: `5px solid ${ColorPalette.VERY_LIGHT_GREY}`,
    borderColor: ColorPalette.VERY_LIGHT_GREY,
    borderWidth: 5,
  },
}

export default LogHeaderSection
