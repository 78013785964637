import Icon from '@mdi/react'
import { mdiPencil } from '@mdi/js'
import Spinner from 'react-spinkit'

import TextInputBasic from '../../BaseComponents/Text/TextInputBasic'
import { ColorPalette } from '../../../config/colors'

type GraphSelectorProps = {
  isLoading?: boolean
  label: string
  placeholder: string
  iconPath?: string
  style?: React.CSSProperties
  onClick: () => void
}

export const GraphParameter = (props: GraphSelectorProps) => {
  const { label, placeholder, iconPath, style, onClick } = props

  let content = (
    <div style={{ padding: 8 }}>
      <Spinner
        style={{ transform: 'scale(0.6)' }}
        fadeIn="quarter"
        name="three-bounce"
        color={ColorPalette.PRIMARY_BLUE}
      />
    </div>
  )
  if (!props.isLoading) {
    content = (
      <div style={styles.value}>
        <TextInputBasic
          style={styles.textStyle}
          value={''}
          placeholder={placeholder}
          disabled={true}
          onFocus={(e) => null}
          onChange={() => null}
        />
        <div>
          <Icon path={iconPath || mdiPencil} size={0.7} style={{ color: ColorPalette.PRIMARY_BLUE }} />
        </div>
      </div>
    )
  }
  return (
    <div style={{ ...styles.container, ...style }} onClick={onClick}>
      <p style={{ ...styles.label }}>{label}</p>
      {content}
    </div>
  )
}

const styles = {
  container: {
    zIndex: 1,
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid rgba(195,205,225,0.6)`,
    borderRadius: 12,
    paddingBottom: 10,
    width: '100%',
    cursor: 'pointer',
  },
  value: {
    display: 'flex',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid' as 'solid',
    borderBottomColor: ColorPalette.PRIMARY_TEXT,
    width: 'calc(100% - 32px)',
    marginTop: 5,
  },
  label: {
    color: ColorPalette.LIGHT_GREY,
    backgroundColor: ColorPalette.CARD_WHITE,
    zIndex: 2,
    marginTop: -8,
    alignSelf: 'center',
    fontSize: '0.65rem',
    paddingLeft: 10,
    paddingRight: 10,
  },
  textStyle: {
    color: ColorPalette.PRIMARY_TEXT,
    fontSize: '0.85rem',
    paddingLeft: 30,
    paddingBottom: 8,
    marginTop: 2,
    marginRight: 20,
    width: '92%',
    textAlign: 'center' as 'center',
  },
}
