import React from 'react'
import Radium from 'radium'
import Spinner from 'react-spinkit'
import { ColorPalette } from '../../../config/colors'

interface IButtonMainProps {
  children: React.ReactNode
  disabled?: boolean
  loading?: boolean
  title?: string
  style?: React.CSSProperties | undefined
  onClick: (e: React.MouseEvent) => void
}

const buttonMain: React.FC<IButtonMainProps> = (props: IButtonMainProps) => {
  let buttonContents = props.children
  if (props.loading) {
    buttonContents = (
      <Spinner style={{ transform: 'scale(0.6)' }} fadeIn="quarter" name="three-bounce" color="rgba(245,245,245,1)" />
    )
  }

  return (
    <button
      style={{ ...drawerButton, ...props.style }}
      onClick={props.onClick}
      disabled={props.disabled}
      title={props.title}>
      {buttonContents}
    </button>
  )
}

const drawerButton = {
  display: 'flex',
  flexDirection: 'row' as 'row',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: 35,
  borderRadius: 30,
  backgroundImage: `linear-gradient(to bottom, ${ColorPalette.LIGHT_GREY}, ${ColorPalette.MEDIUM_GREY})`,
  fontSize: '0.9rem',
  fontFamily: 'roboto',
  color: 'rgba(250, 250, 250, 1)',
  border: 'none',
  outline: 0,
  boxShadow: '0px 2px 6px rgba(0, 0, 10, 0.27)',
  ':hover': {
    filter: 'brightness(85%)',
  },
  ':active': {
    filter: 'brightness(50%)',
  },
  cursor: 'pointer',
}

export default Radium(buttonMain)
