import { Group } from '../../types'
import { DateRangeModal } from '../Modals/DateRangeModal'

type GroupMemberExpiryDateModalProps = {
  open: boolean
  group?: Group
  initialStartDate: Date
  initialExpiryDate: Date
  toggle: () => void
  onDateSelection: (startDate?: Date, expiryDate?: Date) => void
}

const GroupMemberExpiryDateModal = (props: GroupMemberExpiryDateModalProps) => {
  const { open, group, initialStartDate, initialExpiryDate, toggle, onDateSelection } = props

  const handleDateSelection = (startDate: Date, expiryDate: Date) => onDateSelection(startDate, expiryDate)

  let modal = null
  modal = (
    <DateRangeModal
      open={open}
      title="Proposed Start & End Dates"
      initialStartDate={initialStartDate}
      initialExpiryDate={initialExpiryDate}
      minDate={group?.startDate}
      maxDate={group?.endDate}
      onCancelClick={toggle}
      onSaveClick={handleDateSelection}
    />
  )

  return modal
}

export default GroupMemberExpiryDateModal
