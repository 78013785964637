import { Component } from 'react'
import Icon from '@mdi/react'
import { mdiTabletAndroid } from '@mdi/js'
import { DeviceLog } from './DeviceLogBlock'

import ButtonBlue from '../BaseComponents/Buttons/ButtonBlue'
import { ColorPalette } from '../../config/colors'
import { formatDateAndTime, removeUnderscores, toUpperCaseCustom } from '../../utils'
import { DeviceLogContentItem } from './DeviceLogContentItem'

interface DeviceLogContentProps {
  logData: DeviceLog
  openProfileListViewer: () => void
}

export type ProfilePkChanges = {
  adHocDocProfilePksToSync: string[]
  competencyProfilePksToSync: string[]
  discActionProfilePksToSync: string[]
  discPhotoProfilePksToSync: string[]
  docProfilePksToSync: string[]
  employmentProfilePksToSync: string[]
  faceShotProfilePksToSync: string[]
  rawDataProfilePksToSync: string[]
}

class DeviceLogContent extends Component<DeviceLogContentProps> {
  openTimestampViewerWrapper() {
    if (!this.props.openProfileListViewer) {
      return
    }
    this.props.openProfileListViewer()
  }

  getTimeStampFromUnix(unixMs: number) {
    const { day, month, year, hour, minutes } = formatDateAndTime(new Date(unixMs))
    return `${hour}:${minutes}, ${day} ${month} ${year}`
  }

  checkUnsyncedChangesStatus({
    adHocDocProfilePksToSync,
    competencyProfilePksToSync,
    discActionProfilePksToSync,
    discPhotoProfilePksToSync,
    docProfilePksToSync,
    employmentProfilePksToSync,
    faceShotProfilePksToSync,
    rawDataProfilePksToSync,
  }: ProfilePkChanges) {
    return adHocDocProfilePksToSync.length ||
      competencyProfilePksToSync.length ||
      discActionProfilePksToSync.length ||
      discPhotoProfilePksToSync.length ||
      docProfilePksToSync.length ||
      employmentProfilePksToSync.length ||
      faceShotProfilePksToSync.length ||
      rawDataProfilePksToSync.length
      ? true
      : false
  }

  render() {
    const {
      adHocDocProfilePksToSync,
      competencyProfilePksToSync,
      discActionProfilePksToSync,
      discPhotoProfilePksToSync,
      docProfilePksToSync,
      employmentProfilePksToSync,
      faceShotProfilePksToSync,
      rawDataProfilePksToSync,
      currentAppVersion,
      oldestSupportedVersion,
      lastLoginUnixMs,
      lastOnlineLoginUnixMs,
      deviceUuid,
      deviceUuidApple,
      lastSyncStep,
      lastSyncStepUnixMs,
      lastSyncedAssociation,
      name,
      surname,
      username,
      sessionLoginType,
    } = this.props.logData

    const hasUnSyncedChanges = this.checkUnsyncedChangesStatus({
      adHocDocProfilePksToSync,
      competencyProfilePksToSync,
      discActionProfilePksToSync,
      discPhotoProfilePksToSync,
      docProfilePksToSync,
      employmentProfilePksToSync,
      faceShotProfilePksToSync,
      rawDataProfilePksToSync,
    })

    const appVersionUpToDate = currentAppVersion >= oldestSupportedVersion

    return (
      <div style={styles.container}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 5,
          }}>
          <Icon path={mdiTabletAndroid} size={6.5} color={ColorPalette.MEDIUM_GREY} />
          <ButtonBlue
            disabled={!hasUnSyncedChanges}
            style={styles.buttonStyle}
            onClick={() => this.openTimestampViewerWrapper()}>
            Unsynced Data
          </ButtonBlue>
        </div>

        <div style={{ ...styles.textContainer, ...styles.shadow }}>
          <p
            style={{
              ...styles.titleStyle,
            }}>{`Device: ${deviceUuidApple}`}</p>
          <p
            style={{
              ...styles.subTitleStyle,
            }}>{`App version: ${currentAppVersion} | App instance: ${deviceUuid}`}</p>
          <DeviceLogContentItem styles={{ marginTop: 16 }}>
            {`Current user (English name): ${name} ${surname}`}
          </DeviceLogContentItem>
          <DeviceLogContentItem>{`Current user (username): ${username}`}</DeviceLogContentItem>
          <DeviceLogContentItem>
            {`Last logged in: ${toUpperCaseCustom(sessionLoginType)} at ${this.getTimeStampFromUnix(lastLoginUnixMs)}`}
          </DeviceLogContentItem>
          <DeviceLogContentItem>
            {`Last downloaded config: ${this.getTimeStampFromUnix(lastOnlineLoginUnixMs)}`}
          </DeviceLogContentItem>
          <DeviceLogContentItem>
            {`Last synced account: ${removeUnderscores(toUpperCaseCustom(lastSyncedAssociation))}`}
          </DeviceLogContentItem>
          <DeviceLogContentItem>
            {`Last sync step: ${lastSyncStep} at ${this.getTimeStampFromUnix(lastSyncStepUnixMs)}`}
          </DeviceLogContentItem>
          <DeviceLogContentItem colour={hasUnSyncedChanges ? ColorPalette.WARNING_RED : undefined}>
            {`Has unsynced changes: ${hasUnSyncedChanges ? 'YES' : 'NO'}`}
          </DeviceLogContentItem>
          <DeviceLogContentItem colour={!appVersionUpToDate ? ColorPalette.WARNING_RED : undefined}>
            {`App version up-to-date: ${appVersionUpToDate ? 'YES' : 'NO'}`}
          </DeviceLogContentItem>
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'flex-start',
    marginBottom: 5,
    width: '100%',
  },
  shadow: {
    shadowOpacity: 0.16,
    shadowRadius: 2,
    shadowOffset: {
      width: 0,
      height: 2,
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'flex-start',
    width: '60%',
    marginTop: 7,
    marginLeft: 10,
    paddingLeft: 0,
    paddingRight: 5,
  },
  titleStyle: {
    width: '100%',
    fontSize: '0.8rem',
    fontWeight: 'bold',
    textTransform: 'uppercase' as 'uppercase',
    marginTop: -2,
    marginBottom: 0,
    paddingBottom: 3,
    color: ColorPalette.PRIMARY_BLUE,
  },
  subTitleStyle: {
    width: '100%',
    fontSize: '0.8rem',
    fontWeight: 450,
    marginTop: -2,
    marginBottom: 0,
    paddingBottom: 3,
    color: ColorPalette.PRIMARY_TEXT,
    borderBottom: `1px solid ${ColorPalette.MEDIUM_GREY}`,
  },
  metaDataStyle: {
    fontSize: '0.75rem',
    padding: 0,
    margin: 0,
    color: ColorPalette.PRIMARY_TEXT,
  },
  buttonStyle: {
    marginTop: 12,
    height: 22,
    width: '72%',
    fontSize: '0.75rem',
    boxShadow: '0px 2px 4px rgba(0, 0, 30, 0.2)',
  },
}

export default DeviceLogContent
