import { ColorPalette } from '../../config/colors'

import { Component } from 'react'
import Radium from 'radium'
import ButtonBlue from '../BaseComponents/Buttons/ButtonBlue'
import FilterButton from '../BaseComponents/Buttons/FilterButton'
import ButtonGrey from '../BaseComponents/Buttons/ButtonGrey'

interface IFieldFilterProps {
  filterConfig: { key: string; label: string }[]
  filterState: { selectedField: string; filterType: string; filterValue: string; key?: string }[]
  isDataQueryFilter?: boolean
  toggleFilter: () => void
  updateFilterState: (
    filterState: { selectedField: string; filterType: string; filterValue: string; key?: string }[],
  ) => void
}

interface FieldFilterState {
  fieldOptions: string[]
  warningIndices: number[]
  filterOptions: string[]
}

class FieldFilter extends Component<IFieldFilterProps, FieldFilterState> {
  state: FieldFilterState = {
    fieldOptions: [],
    warningIndices: [],
    filterOptions: [],
  }

  componentDidMount() {
    const { filterConfig } = this.props
    const fieldOptions = [...filterConfig].map((item) => item.label)
    this.setState({ fieldOptions })
  }

  addFilter = (e: React.MouseEvent) => {
    e.stopPropagation()
    const filterState = [
      {
        selectedField: '',
        filterType: '=',
        filterValue: '',
        key: '',
      },
      ...this.props.filterState,
    ]
    this.stateUpdateHandler(filterState)
  }

  removeAllFilters = () => {
    this.stateUpdateHandler([])
  }

  deleteFilter = (e: React.MouseEvent, index: number) => {
    e.stopPropagation()
    let { warningIndices } = this.state
    const filterState = [...this.props.filterState].filter((item, i) => i !== index)
    warningIndices = [...warningIndices].filter((warningIndex) => warningIndex !== index)
    this.setState({ warningIndices })
    this.props.updateFilterState(filterState)
  }

  setFilterField = (index: number, result: string) => {
    if (result.length) {
      const filterState = [...this.props.filterState].map((filterItem, i) => {
        if (i === index) {
          filterItem.selectedField = result
        }
        return filterItem
      })
      this.stateUpdateHandler(filterState)
    }
  }

  toggleFilterType = (index: number) => {
    const filterState = [...this.props.filterState].map((filterItem, i) => {
      if (i === index) {
        if (filterItem.filterType === '=') {
          filterItem.filterType = '<='
        } else if (filterItem.filterType === '<=') {
          filterItem.filterType = '>='
        } else {
          filterItem.filterType = '='
        }
      }
      return filterItem
    })
    this.stateUpdateHandler(filterState)
  }

  setFilterValue = (index: number, text: string) => {
    const filterState = [...this.props.filterState].map((filterItem, i) => {
      if (i === index) {
        filterItem.filterValue = text
      }
      return filterItem
    })
    this.stateUpdateHandler(filterState)
  }

  stateUpdateHandler = (
    filterState: { selectedField: string; filterType: string; filterValue: string; key?: string }[],
  ) => {
    try {
      const { filterConfig } = this.props
      let warningIndices = [] as number[]
      let updatedFilterState = [...filterState].map((filterItem, index) => {
        filterConfig.forEach((item) => (item.label === filterItem.selectedField ? (filterItem.key = item.key) : null))
        if (
          isNaN(parseInt(filterItem.filterValue)) &&
          (filterItem.filterType === '>=' || filterItem.filterType === '<=') &&
          isNaN(Date.parse(filterItem.filterValue))
        ) {
          warningIndices = [...warningIndices, index]
        }
        return filterItem
      })
      this.setState({ warningIndices })
      this.props.updateFilterState(updatedFilterState)
    } catch (error) {
      // console.log("error: ", error)
    }
  }

  generateFilterList = () => {
    const { warningIndices } = this.state
    const { filterState } = this.props
    if (!filterState.length) {
      return null
    }
    const filterButtonArray = filterState.map((item, index) => {
      let warning = false
      if (warningIndices.includes(index)) {
        warning = true
      }

      return (
        <FilterButton
          filterType={item.filterType}
          filterValue={item.filterValue}
          selectedField={item.selectedField}
          setFilterField={(result) => this.setFilterField(index, result)}
          toggleFilterType={() => this.toggleFilterType(index)}
          textHandler={(text) => this.setFilterValue(index, text)}
          deleteFilter={(e) => this.deleteFilter(e, index)}
          fields={this.state.fieldOptions}
          warning={warning}
        />
      )
    })
    return <div style={styles.filterButtonArrayContainer}>{filterButtonArray}</div>
  }

  render() {
    const filterList = this.generateFilterList()
    let removeAllFiltersButton = null
    const numberOfFilters = this.props.filterState.length

    const applyFiltersButton = (
      <ButtonBlue
        style={{ ...styles.button }}
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation()
          this.props.toggleFilter()
        }}>
        Done
      </ButtonBlue>
    )

    if (numberOfFilters > 1) {
      removeAllFiltersButton = (
        <ButtonGrey
          style={{ ...styles.button, marginBottom: 12 }}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation()
            this.removeAllFilters()
            this.props.toggleFilter()
          }}>
          Reset
        </ButtonGrey>
      )
    }

    return (
      <div style={{ ...styles.filterMenuContainer }}>
        <h1 style={{ ...styles.textStyle }}> Filters</h1>
        <div style={{ ...styles.dividerLine }} />
        <ButtonGrey style={{ ...styles.button, marginTop: 10, marginBottom: 12 }} onClick={this.addFilter}>
          + Add filter
        </ButtonGrey>
        {filterList}
        {removeAllFiltersButton}
        {applyFiltersButton}
      </div>
    )
  }
}

const styles = {
  filterMenuContainer: {
    position: 'absolute' as 'absolute',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    zIndex: 4,
    width: window.innerWidth * 0.4,
    backgroundColor: ColorPalette.CARD_WHITE,
    paddingTop: 25,
    paddingBottom: 55,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 8,
    boxShadow: '0px 2px 8px rgba(0, 0, 20, 0.2)',
    maxHeight: window.innerHeight * 0.8,
    cursor: 'default',
  },
  filterButtonArrayContainer: {
    marginBottom: 50,
    width: '94%',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
  },
  drawerButton: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.9rem',
    fontFamily: 'Roboto',
    fontWeight: '500',
    color: ColorPalette.PRIMARY_LIGHT_TEXT,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    height: '100%',
    width: '100%',
    outline: 0,
    border: `0px solid rgba(255,255,255,0)`,
    ':hover': {
      filter: 'brightness(90%)',
    },
    ':active': {
      filter: 'brightness(80%)',
    },
  },
  textStyle: {
    textAlign: 'center' as 'center',
    alignSelf: 'center',
    fontFamily: 'roboto',
    fontWeight: 'bold',
    color: ColorPalette.PRIMARY_TEXT,
    paddingLeft: 10,
    paddingRight: 10,
    fontSize: '1.2rem',
  },
  dividerLine: {
    width: '86%',
    alignSelf: 'center',
    marginTop: 10,
    marginBottom: 10,
    borderBottom: `1px solid ${ColorPalette.PRIMARY_BLUE}`,
  },
  button: {
    width: '92%',
    cursor: 'pointer',
  },
}

export default Radium(FieldFilter)
