export enum DataCaptureMode {
    /**
     * Dataflow UI is generated using only the dataflow config.
     */
    STANDARD = "STANDARD",
    /**
     * Just In Time (JIT): Field names are extracted from forms that need to be completed and 
       a new dataflow is generated using those fields just before form signing.
     */
    JIT = "JIT",
    /**
     * Joins any JIT dataflow with the STANDARD configure dataflow
     */
    COMBINATION = "COMBINATION",
}
