import React, { Component } from 'react'
import { cloneDeep, get } from 'lodash'

import { filterDataFlow, getSectionCompletionState, inputValidator } from '../../utils/workflowUtils'
import DataFlowNavigator from '../Navigation/DataFlowNavigator'
import DataFlowSummary from '../DataFlows/DataFlowSummary'
import { toUpperCaseCustom } from '../../utils'
import { IFieldConfigItem, ISectionConfigItem } from '../../interfaces'

interface DataFlowManagerProps {
  fieldConfig: Record<string, Record<string, any>>
  sectionConfig: ISectionConfigItem[]
  dataFlow: Record<string, Record<string, any>[]>
  data: Record<string, any>
  showSectionHeader: boolean
  highlightEmpty: boolean
  dataFlowDisabled: boolean
  selectedRole?: string
  verificationHandler: (value: string | string[], verificationType: string) => void | undefined
}

interface DataFlowManagerState {
  showSectionHeader: boolean
  showForm: boolean
  data: Record<string, any>
  sectionCompletionState: Record<string, boolean>
  dataFlowFiltered: Record<string, IFieldConfigItem[]>
  selectedSectionFlowFiltered: IFieldConfigItem[]
  dataFlowFilters: Record<string, any>
  currentSectionName: string
  currentSectionLabel: string
  fieldWarnings: Record<string, string>
}

class DataFlowManager extends Component<DataFlowManagerProps, DataFlowManagerState> {
  dataFlowNavigatorMenuRef: React.RefObject<DataFlowNavigator>

  constructor(props: DataFlowManagerProps) {
    super(props)
    this.dataFlowNavigatorMenuRef = React.createRef()
  }

  state: DataFlowManagerState = {
    showSectionHeader: false,
    showForm: false,
    data: {},
    sectionCompletionState: {},
    dataFlowFiltered: {},
    selectedSectionFlowFiltered: [],
    dataFlowFilters: {},
    currentSectionName: '',
    currentSectionLabel: '',
    fieldWarnings: {},
  }

  componentDidMount() {
    this.dataFlowNavigatorMenuRef = React.createRef()
    const { dataFlow = {}, data = {}, showSectionHeader = false, sectionConfig = [] } = this.props

    if (sectionConfig.length) {
      const currentSectionName = sectionConfig[0].sectionName
      const [dataFlowFiltered, dataFlowFilters] = filterDataFlow(dataFlow, data)
      const sectionCompletionState = getSectionCompletionState(dataFlowFiltered, data)

      this.setState({
        data: cloneDeep(data),
        showSectionHeader,
        sectionCompletionState,
        dataFlowFiltered,
        dataFlowFilters,
        selectedSectionFlowFiltered: dataFlowFiltered[currentSectionName],
        currentSectionName,
        currentSectionLabel: sectionConfig[0].sectionLabel,
        showForm: true,
      })
    }
  }

  getCapturedData = () => {
    return this.state.data
  }

  setVerifiedData = (verifiedData: Record<string, any>) => {
    let { data } = this.state
    let verifiedFields = get(data, 'verifiedFields', [])
    verifiedFields = [...verifiedFields, ...Object.keys(verifiedData)]
    data = { ...data, ...verifiedData, verifiedFields }
    this.setState({ data })
  }

  areAllSectionsComplete = (treatAllFieldsAsCompuslory: boolean) => {
    let complete = true
    const { dataFlowFiltered, data } = this.state
    const sectionCompletionState = getSectionCompletionState(dataFlowFiltered, data, treatAllFieldsAsCompuslory)
    Object.keys(sectionCompletionState).forEach((section) =>
      !sectionCompletionState[section] ? (complete = false) : null,
    )
    return complete
  }

  goToSubmenu = (nextSectionName: string) => {
    const { dataFlowFiltered, data } = this.state
    const sectionCompletionState = getSectionCompletionState(dataFlowFiltered, data)

    this.setState({
      selectedSectionFlowFiltered: dataFlowFiltered[nextSectionName],
      sectionCompletionState,
      currentSectionName: nextSectionName,
    })
  }

  remountForm(isPriField: boolean) {
    if (isPriField) {
      this.setState({ showForm: false }, () => this.setState({ showForm: true }))
    }
  }

  textHandler = (
    text: string,
    key: string,
    validationType: string | undefined,
    compulsory: boolean,
    autoPopulate: Record<string, Record<string, any>> = {},
    isPriField: boolean,
  ) => {
    let warning = ''
    if (validationType !== undefined) {
      warning = inputValidator(compulsory, validationType, text).warning
    }
    let data = { ...this.state.data, [key]: toUpperCaseCustom(text) }
    if (text in autoPopulate) {
      data = { ...data, ...autoPopulate[text] }
    }
    const fieldWarnings = { ...this.state.fieldWarnings, [key]: warning }
    this.setState({ data, fieldWarnings }, () => this.remountForm(isPriField && text in autoPopulate))
  }

  selectionHandler = (
    values: string[],
    key: string,
    workflowBranch: boolean,
    autoPopulate: Record<string, Record<string, any>> = {},
    isPriField: boolean,
  ) => {
    const { dataFlow } = this.props
    let { data, dataFlowFiltered, dataFlowFilters, currentSectionName } = this.state
    let selectedSectionFlowFiltered = dataFlowFiltered[currentSectionName]
    data[key] = values
    if (values.length && values[0] in autoPopulate) {
      data = { ...cloneDeep(data), ...autoPopulate[values[0]] }
    }
    if (workflowBranch) {
      const filterResult = filterDataFlow(dataFlow, data)
      dataFlowFiltered = filterResult[0]
      dataFlowFilters = filterResult[1]
      selectedSectionFlowFiltered = dataFlowFiltered[currentSectionName]
    }
    this.setState({ data, dataFlowFiltered, dataFlowFilters, selectedSectionFlowFiltered }, () =>
      this.remountForm(isPriField),
    )
  }

  render() {
    const {
      data,
      showSectionHeader,
      currentSectionName,
      selectedSectionFlowFiltered,
      currentSectionLabel,
      fieldWarnings,
      showForm,
    } = this.state

    const {
      sectionConfig,
      fieldConfig,
      highlightEmpty,
      dataFlowDisabled,
      selectedRole,
      verificationHandler = () => ({}),
    } = this.props

    let sectionMenu = null
    if (showSectionHeader) {
      sectionMenu = (
        <DataFlowNavigator
          ref={this.dataFlowNavigatorMenuRef}
          sectionConfig={sectionConfig}
          currentSectionName={currentSectionName}
          selectedRole={selectedRole}
          onSectionClick={(sectionName) => this.goToSubmenu(sectionName)}
        />
      )
    }
    let form = null
    if (showForm) {
      form = (
        <DataFlowSummary
          style={{ marginTop: showSectionHeader ? 0 : 35 }}
          data={data}
          fieldConfig={fieldConfig}
          sectionLabel={currentSectionLabel}
          sectionFlow={selectedSectionFlowFiltered}
          fieldWarnings={fieldWarnings}
          highlightEmpty={highlightEmpty}
          dataFlowDisabled={dataFlowDisabled}
          verifiedFields={data.verifiedFields ? data.verifiedFields : {}}
          textHandler={(
            text: string,
            key: string,
            validationType: string | undefined,
            compulsory: boolean,
            autoPopulate: Record<string, Record<string, any>> = {},
            isPriField: boolean,
          ) => this.textHandler(text, key, validationType, compulsory, autoPopulate, isPriField)}
          selectionHandler={(
            values: string[],
            key: string,
            workflowBranch: boolean,
            autoPopulate: Record<string, Record<string, any>>,
            isPriField: boolean,
          ) => this.selectionHandler(values, key, workflowBranch, autoPopulate, isPriField)}
          verificationHandler={(value: string | string[], verificationType: string) =>
            verificationHandler(value, verificationType)
          }
        />
      )
    }

    return (
      <div>
        {sectionMenu}
        {form}
      </div>
    )
  }
}

export default DataFlowManager
