export const APP_VERSION = '1.10.10'
export const PROJECT_BUILT_TIMESTAMP = 1721640141 // this is currently used to reset the cache on the client side

const idPoolMap = {
  staging: 'eu-west-1:53250023-3c94-45fc-adaf-058de67dc20f',
  prod: 'eu-west-1:945ed7a5-fe04-4186-bf93-5db49f17af01',
}
const userpoolIdMap = {
  staging: 'eu-west-1_XrdhhKGBb',
  prod: 'eu-west-1_E3ghwkfDI',
}

const webClientMap = {
  staging: '17vbi6pq6ojq66jto97qa43h78',
  prod: '3req3caub15rmapbl5dh7r0s53',
}

export const getAwsConfig = (env) => {
  return {
    aws_project_region: 'af-south-1',
    aws_cognito_region: 'eu-west-1',
    aws_cognito_identity_pool_id: idPoolMap[env],
    aws_user_pools_id: userpoolIdMap[env],
    aws_user_pools_web_client_id: webClientMap[env],
    oauth: {},
    aws_user_files_s3_bucket: `peopleflow-service-${env}-general-storage`,
    aws_user_files_s3_bucket_region: 'af-south-1',

    API: {
      endpoints: [
        // Need to get the custom/alias endpoints working on staging
        {
          name: 'people_flow_import_export',
          endpoint: `https://${env}.peopleflow.co.za/e`,
          region: 'af-south-1',
        },
        {
          name: 'people_flow_data_sync',
          endpoint: `https://${env}.peopleflow.co.za/s`,
          region: 'af-south-1',
        },
        {
          name: 'people_flow_device_state',
          endpoint: `https://${env}.peopleflow.co.za/d`,
          region: 'af-south-1',
        },
        {
          name: 'people_flow_user',
          endpoint: `https://${env}.peopleflow.co.za/u`,
          region: 'af-south-1',
        },
        {
          name: 'people_flow_billing',
          endpoint: `https://${env}.peopleflow.co.za/b`,
          region: 'af-south-1',
        },
        {
          name: 'people_flow_core',
          endpoint: `https://${env}.peopleflow.co.za/core`,
          region: 'af-south-1',
        },
        {
          name: 'verifyIDAuth',
          endpoint: 'https://www.verifyid.co.za/webservice/authenticate',
        },
        {
          name: 'checkCredits',
          endpoint: 'https://www.verifyid.co.za/webservice/my_credits',
        },
        {
          name: 'verifyID',
          endpoint: 'https://www.verifyid.co.za/webservice/said_verification',
        },
      ],
    },
  }
}
