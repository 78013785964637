import { cloneDeep } from 'lodash'

import { APP_VERSION } from '../cloud-config'
import { getTimeBasedDocRecords, triggerDisciplineFormGeneration } from '../provider'
import { SessionService } from './sessionService'
import { CommonTs } from '../models/common-ts/commonts'
import { exportProfileDataTemplate, submitProfileImportPlan } from '../provider/routes/dataExport'
import { AuthCredentials, DocAuditReportResponse } from '../types'
import { ImportRecordQuery } from '../types/dataExport'
import { updateProfile } from '../provider/routes/employees'

export class ImportExportService {
  async exportRemoteData(
    selectedAssociation: any,
    selectedProfiles: string[],
    selectedExportTemplate: any,
    selectedRole: string,
    emails: any,
    authCredentials: AuthCredentials,
  ): Promise<void> {
    if (selectedRole === '') {
      selectedRole = 'ADMIN'
    }
    const payload = {
      selectedEmployer: selectedAssociation,
      selectedRole,
      selectedProfiles,
      selectedExportTemplate,
      emails,
      webVersion: APP_VERSION,
    }
    const { username, password } = authCredentials
    const token = await SessionService.prepareAuthTokens(username, password)
    exportProfileDataTemplate(payload, token)
  }

  async exportDocAuditReports(
    selectedAssociation: any,
    emails: any,
    cohorts: string[],
    authCredentials: AuthCredentials,
  ): Promise<DocAuditReportResponse> {
    const params = {
      emails,
      cohorts,
      ignoreJobType: true,
      flagUnpersisted: true,
    }

    const { username, password } = authCredentials
    const token = await SessionService.prepareAuthTokens(username, password)
    return await getTimeBasedDocRecords(selectedAssociation, params, token)
  }

  async generateBulkDisciplineForms(
    selectedAssociation: string,
    selectedDate: Date,
    emails: string[],
    authCredentials: AuthCredentials,
  ): Promise<void> {
    try {
      const { username, password } = authCredentials
      const token = await SessionService.prepareAuthTokens(username, password)
      await triggerDisciplineFormGeneration(selectedAssociation, selectedDate, emails, token)
    } catch (error) {
      throw error
    }
  }

  async planProfileImport(
    selectedEmployer: string,
    importFileName: string,
    bulkActionConfig: CommonTs.Import.IPlanConfig,
    authCredentials: AuthCredentials,
    inverseSetQuery?: ImportRecordQuery,
  ): Promise<CommonTs.Import.IPlanResponse> {
    // TODO: "prod" must also be dynamic to enable "staging"
    try {
      const { username, password } = authCredentials
      const token = await SessionService.prepareAuthTokens(username, password)
      const data = await submitProfileImportPlan(
        selectedEmployer,
        importFileName,
        bulkActionConfig,
        token,
        inverseSetQuery,
      )
      return data
    } catch (error) {
      throw error
    }
  }

  async planTerminationImport(
    selectedEmployer: string,
    csvFileName: string,
    authCredentials: AuthCredentials,
    options?: CommonTs.Import.ITerminateProfileOptions,
    defaultTerminationDate?: string,
    removeImportRecords?: boolean,
    cohort?: string,
  ): Promise<CommonTs.Import.IImportPlan[]> {
    let configOptions: CommonTs.Import.ITerminateProfileOptions = options ? cloneDeep(options) : {}
    if (defaultTerminationDate) {
      configOptions.defaultTerminationDate = defaultTerminationDate
    }

    const inverseSetQuery: ImportRecordQuery | undefined = removeImportRecords
      ? {
          trashStatus: CommonTs.Profile.TrashStatus.NONE,
          employmentStatus: CommonTs.Profile.EmploymentStatus.EMPLOYEE,
          cohort: cohort || '',
        }
      : undefined

    const bulkActionConfig = {
      action: CommonTs.Import.IImportAction.TERMINATE,
      options: configOptions,
      allowablePlanWarningReasons: [
        CommonTs.Import.InvalidationReason.UNKNOWN_KEY,
        CommonTs.Import.PlanProblemReason.CORRUPT_EXISTING_TERMINATION_STATE,
      ],
      filter: {
        ignoreValidations: [
          CommonTs.Import.PlanProblemReason.INSUFFICIENT_INPUT,
          CommonTs.Import.PlanProblemReason.ALREADY_TERMINATED,
          CommonTs.Import.PlanProblemReason.NOTHING_TO_DO,
          CommonTs.Import.PlanProblemReason.PROFILE_DOES_NOT_EXIST,
        ],
      },
    }
    const results = await this.planProfileImport(
      selectedEmployer,
      csvFileName,
      bulkActionConfig,
      authCredentials,
      inverseSetQuery,
    )
    return results.filterResults
  }

  async applyProfileChange(
    selectedEmployer: string,
    profileChange: CommonTs.Import.ProfileChange,
    authCredentials: AuthCredentials,
  ): Promise<any> {
    try {
      const { username, password } = authCredentials
      const token = await SessionService.prepareAuthTokens(username, password)
      return await updateProfile(selectedEmployer, profileChange, token)
    } catch (error) {
      throw error
    }
  }
}
