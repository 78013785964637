import React, { Component } from 'react'
import Spinner from 'react-spinkit'
import Dialog from '@material-ui/core/Dialog'
import { ColorPalette } from '../../config/colors'

interface LoadingModalProps {
  open: boolean
  style?: React.CSSProperties
}

class LoadingModal extends Component<LoadingModalProps> {
  render() {
    // TODO: use /components/Modals/Modal.tsx after web factor lands
    return (
      <Dialog
        open={this.props.open}
        BackdropProps={{ invisible: true }}
        PaperProps={{ style: { borderRadius: 8 } }}
        style={{ backgroundColor: ColorPalette.MODAL_BACKGROUND_OVERLAY_SOFT, ...this.props.style }}>
        <div style={{ width: window.innerWidth * 0.25, display: 'flex', flexDirection: 'column' }}>
          <h1 style={{ ...textStyle }}>{this.props.children}</h1>

          <Spinner
            style={{
              transform: 'scale(0.8)',
              alignSelf: 'center',
              marginBottom: '18%',
              marginTop: '10%',
            }}
            fadeIn="quarter"
            name="three-bounce"
            color={ColorPalette.PRIMARY_TEXT}
          />
        </div>
      </Dialog>
    )
  }
}

const textStyle = {
  textAlign: 'center' as 'center',
  alignSelf: 'center',
  fontFamily: 'roboto',
  fontWeight: 'bold',
  color: ColorPalette.PRIMARY_TEXT,
  fontSize: '1.2rem',
  paddingLeft: 10,
  paddingRight: 10,
  marginTop: 25,
}

export default LoadingModal
