import GroupCard from '../../components/Groups/GroupCard'
import { Group, GroupStatus } from '../../types'
import { isSameDate } from '../../utils'

type GroupCardContainerProps = {
  group: Group
  index: number
  groupStatusFilters: GroupStatus[]
  initialDate: Date
  toggleDateRangeModal: (groupId?: string) => void
  onViewGroupClick: () => void
}

export const GroupCardContainer = (props: GroupCardContainerProps) => {
  const { group, index, groupStatusFilters, initialDate, toggleDateRangeModal, onViewGroupClick } = props

  let status: GroupStatus = 'Active'
  let today = initialDate
  if (!isSameDate(group.startDate, today) && group.startDate > today) {
    status = 'Upcoming'
  } else if (!isSameDate(group.endDate, today) && group.endDate < today) {
    status = 'Closed'
  }
  if (!groupStatusFilters.includes(status)) {
    return null
  }

  return (
    <div style={styles.groupItemContainer} key={`group-${group.division}_${group.name}_${index}`}>
      <GroupCard
        status={status}
        groupId={group.id}
        groupName={group.name}
        activeDatePeriod={{
          startDate: group.startDate,
          endDate: group.endDate,
        }}
        editableDateRange={status !== 'Closed'}
        groupMembers={group.members || {}}
        toggleEditDateRange={toggleDateRangeModal}
        onViewGroupClick={onViewGroupClick}
      />
    </div>
  )
}

const styles = {
  groupItemContainer: { flexDirection: 'column' as 'column', flex: 0.5, maxWidth: 'calc(50% - 2em)' }, // maxWidth like it is to keep successive card rows same size as first
}
