export const RequestedPpeConfirmation = (props: {
  numberOfRequestedPpe: number
  numberOfPeopleWithRequestedPpe: number
}) => (
  <div>
    {`You are about to request ${props.numberOfRequestedPpe} PPE item${props.numberOfRequestedPpe > 1 ? 's' : ''} for ${
      props.numberOfPeopleWithRequestedPpe
    } ${props.numberOfPeopleWithRequestedPpe > 1 ? 'people' : 'person'}`}
  </div>
)
