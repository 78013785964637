import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { mdiExport, mdiPackageVariantClosedPlus, mdiUpload } from '@mdi/js'

import NavigationBar from '../../components/Navigation/NavigationBar'
import SectionHeaderPrimary from '../../components/Headings/SectionHeaderPrimary'
import SideMenu from '../../components/Navigation/SideMenu'
import { Toolbar, ActionButtonType } from '../../components/GeneralUI/Toolbar'
import RouteLeavingGuard from '../../components/Navigation/RouteLeavingGuard'
import LoadingModal from '../../components/Modals/LoadingModal'
import AlertModalTwoButton from '../../components/Modals/AlertModalTwoButton'
import { ColorPalette } from '../../config/colors'
import { PeopleFlowCombinedReducer } from '../../store'
import PPEStockSideMenu from '../../components/SideMenus/PpeStockSideMenu'
import PPEStockDetails from '../../components/Reporting/PpeStockDetails/PpeStockDetails'
import { PpeStockToAdd, StockToAdd } from '../../components/Reporting/PpeStockDetails/PpeStockToAdd'
import AlertModalOneButton from '../../components/Modals/AlertModalOneButton'
import { PPEService, SessionService } from '../../services'
import {
  getStockDetailDisplayData,
  getTypesSkus,
  getUniqueTypeLabels,
  groupInstanceItemsByKey,
} from '../../utils/ppeUtils'
import { CsvFileProcessingModal } from '../../components/Modals/Csv/CsvFileProcessingModal'
import {
  checkEmailFormat,
  CsvProcessIdentifier,
  fuzzyMatch,
  getCsvFieldHeaderKeyByKey,
  getCsvFieldHeaderKeyByName,
  getCsvFieldHeaderNames,
  toLowerCaseCustom,
} from '../../utils'
import { AssociationSettingsRepository, UserRepository } from '../../repositories'
import { PpeInstance, PpeInstancePayload, PpeType } from '../../types'
import InfoCollectorModal from '../../components/Modals/InfoCollector'

type PpeStockProps = {
  match: any
  location: any
  history: any
}

const ppeService = new PPEService()
const csvHeader = ['Item', 'SKU', 'Size', 'Quantity', 'Location', 'Purchase Order No', 'Supplier']
const csvProcess = CsvProcessIdentifier.PPE_STOCK

export const PpeStock = (props: PpeStockProps) => {
  const idPassport = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.idPassport)
  const password = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.password)
  const selectedAssociation = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedAssociation,
  )
  const userRepo = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.userRepo) as UserRepository
  const associationRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.associationRepo,
  ) as AssociationSettingsRepository
  const orgConfig = associationRepo.getOrganisationConfig(selectedAssociation)

  const [selectedTypeName, setSelectedItemName] = useState('All')
  const [stockToAddMode, setStockToAddMode] = useState(false)
  const [types, setTypes] = useState<PpeType[]>([])
  const [allItems, setAllItems] = useState<PpeInstance[]>([])
  const [changesMade, setChangesMade] = useState(false)
  const [selectedStockItems, setSelectedStockItems] = useState<string[]>([])
  const [stockToAddItems, setStockToAddItems] = useState<StockToAdd[]>([])
  const [csvUploadModalOpen, setCsvUploadModalOpen] = useState(false)
  const [loadingModalOpen, setLoadingModalOpen] = useState(false)
  const [savingModalOpen, setSavingModalOpen] = useState(false)
  const [saveFailedModalOpen, setSaveFailedModalOpen] = useState(false)
  const [confirmStockAdditionModalOpen, setConfirmStockAdditionModalOpen] = useState(false)
  const [stockAdditionSuccessModalOpen, setStockAdditionSuccessModalOpen] = useState(false)
  const [stockAdditionWarningModalOpen, setStockAdditionWarningModalOpen] = useState(false)
  const [exportEmailCollectorModalOpen, setExportEmailCollectorModalOpen] = useState(false)
  const [exportEmailPendingModalOpen, setExportEmailPendingModalOpen] = useState(false)
  const [stockAdditionWarningMessage, setStockAdditionWarningMessage] = useState<string | Element>('')

  useEffect(() => {
    initialise()
  }, [])

  const initialise = async () => {
    setLoadingModalOpen(true)
    getPpeTypes()
    await getPpeItems()
    setLoadingModalOpen(false)
  }

  const getPpeTypes = () => {
    const types = ppeService.getTypes(selectedAssociation, associationRepo)
    setTypes(types)
  }

  const getPpeItems = async () => {
    const items = await ppeService.getItems(selectedAssociation, { username: idPassport, password })
    setAllItems(Object.values(items))
  }

  const getItemDetails = useCallback(() => {
    let skuGroupedItems = groupInstanceItemsByKey(allItems, 'sku')
    const allTypesSelected = selectedTypeName === 'All'

    if (allTypesSelected) {
      const itemDetailsToDisplay = prepareGroupedItemsForDisplay(skuGroupedItems)
      return itemDetailsToDisplay
    }

    const typesMatchingSelected = types.filter((type) => type.label === selectedTypeName)
    const typeSKUs = getTypesSkus(typesMatchingSelected)
    const itemsBySelectedCategory = allItems.filter((item) => typeSKUs.includes(item.sku))
    skuGroupedItems = groupInstanceItemsByKey(itemsBySelectedCategory, 'sku')
    const itemDetailsToDisplay = prepareGroupedItemsForDisplay(skuGroupedItems, !allTypesSelected)
    return itemDetailsToDisplay
  }, [allItems, selectedTypeName])

  const prepareGroupedItemsForDisplay = (skuGroupedItems: any, drillDownOnSize?: boolean) => {
    let itemsToDisplay: any[] = []

    if (drillDownOnSize) {
      Object.keys(skuGroupedItems).forEach((sku: string) => {
        const skuItems = skuGroupedItems[sku]
        const sizeGroupedItems = groupInstanceItemsByKey(skuItems, 'ppeSize')
        Object.keys(sizeGroupedItems).forEach((size: string) => {
          const rowData = getStockDetailDisplayData(sizeGroupedItems, 'ppeSize', size, types, sku)
          itemsToDisplay.push(rowData)
        })
      })
      return itemsToDisplay
    }

    Object.keys(skuGroupedItems).forEach((sku: string) => {
      const rowData = getStockDetailDisplayData(skuGroupedItems, 'sku', sku, types)
      itemsToDisplay.push(rowData)
    })
    return itemsToDisplay
  }

  const prepareItemsToUploadForDisplay = (fileRows: any[]) => {
    let itemsToDisplay: any[] = []
    fileRows.forEach((row: Record<string, string>) => {
      let item: Record<string, string> = {}
      Object.keys(row).forEach((key: string) => {
        const labelKey = getCsvFieldHeaderKeyByKey(orgConfig, csvProcess, key)
        if (labelKey) {
          item[labelKey] = row[key]
        }
      })
      itemsToDisplay.push(item)
    })

    return itemsToDisplay
  }

  const closeModals = () => {
    setCsvUploadModalOpen(false)
  }

  const toggleSaveFailedModal = () => setSaveFailedModalOpen((saveFailedModalOpen) => !saveFailedModalOpen)
  const toggleCSVFileUploadModal = () => {
    setCsvUploadModalOpen((csvUploadModalOpen) => !csvUploadModalOpen)
  }

  const toggleConfirmStockAdditionModal = () => {
    setConfirmStockAdditionModalOpen((confirmStockAdditionModalOpen) => !confirmStockAdditionModalOpen)
  }

  const toggleStockAdditionModal = () => {
    setStockAdditionSuccessModalOpen((stockAdditionSuccessModalOpen) => !stockAdditionSuccessModalOpen)
  }

  const toggleEmailCollectorModal = () => {
    setExportEmailCollectorModalOpen((exportEmailCollectorModalOpen) => !exportEmailCollectorModalOpen)
  }

  const toggleExportEmailPendingModal = () => {
    setExportEmailPendingModalOpen((exportEmailPendingModalOpen) => !exportEmailPendingModalOpen)
  }

  const getActionButtons = () => {
    let actionButtons: ActionButtonType[] = [
      {
        label: 'EXPORT',
        iconPath: mdiExport,
        onClick: toggleEmailCollectorModal,
        disabled: false,
        title: 'Export stock data to email address(es)',
      },
    ]

    if (stockToAddMode) {
      actionButtons.push({
        label: 'UPLOAD STOCK',
        iconPath: mdiUpload,
        onClick: toggleConfirmStockAdditionModal,
        disabled: selectedStockItems.length === 0,
        title: 'Upload selected stock items',
      })
    } else {
      actionButtons.push({
        label: 'ADD STOCK',
        iconPath: mdiPackageVariantClosedPlus,
        onClick: toggleCSVFileUploadModal,
        disabled: false,
        title: 'Upload new stock times via CSV file',
      })
    }
    return actionButtons
  }

  const compilePayload = (item: StockToAdd): PpeInstancePayload => {
    const { sku, size, poNumber, quantity } = item
    return {
      // @ts-ignore
      sku: sku as string,
      ppeSize: size,
      association: selectedAssociation,
      purchaseOrderId: poNumber,
      qty: quantity,
    }
  }

  const initiateStockImport = async (stockItems: any) => {
    setSavingModalOpen(true)
    let payload: PpeInstancePayload[] = []
    stockItems.forEach((item: any) => {
      payload.push(compilePayload(item))
    })
    await ppeService.upsertItem(selectedAssociation, payload, { username: idPassport, password })
    setSavingModalOpen(false)
  }

  const addSelectedStockItems = async () => {
    const selectedItems = selectedStockItems.map((itemRowCompositeId: string) => {
      const [itemLabel, itemSku, itemSize] = itemRowCompositeId.split('-')
      return stockToAddItems.find(
        (item: any) => item.item === itemLabel && item.sku === itemSku && item.size === itemSize,
      )
    })
    toggleConfirmStockAdditionModal()
    await initiateStockImport(selectedItems)
    await getPpeItems()
    toggleStockAdditionModal()
  }

  const initiateStockAdditionView = (fileData: any[]) => {
    closeModals()
    const stockItems = prepareItemsToUploadForDisplay(fileData)
    setStockToAddItems(stockItems)
    setStockToAddMode(true)
  }

  const transformStockImportSupplierName = (supplierName: string) => {
    const supplierOptions = Object.keys(orgConfig.ppeSuppliers).map((key) => ({
      supplierId: key,
      supplierName: orgConfig.ppeSuppliers[key],
    }))
    const result = fuzzyMatch(supplierOptions, supplierName, {
      keys: ['supplierName'],
      threshold: 0.4, // 0 is a perfect match, 1 is a perfect mismatch
      includeScore: true,
    })
    if (result) {
      // @ts-ignore
      return result[0]?.item?.supplierName
    } else {
      setStockAdditionWarningMessage(`PPE supplier ${supplierName} specified in import file is not recognised`)
      setStockAdditionWarningModalOpen(true)
    }
    return supplierName
  }

  const checkStockImportItemExists = (sku: any) => {
    const skuNotRecognised = !types.find((type: PpeType) => type.skus?.includes(sku))
    if (skuNotRecognised) {
      setStockAdditionWarningMessage(
        `SKU ${sku} is not configured.\n\nVisit CONFIGURATOR > PPE to configure the SKU then retry the stock import.`,
      )
      setStockAdditionWarningModalOpen(true)
    }
    return sku
  }

  const transformStockImportFileContent = (value: string, header?: string | number) => {
    if (header === 'sku') {
      value = value.toUpperCase()
      return checkStockImportItemExists(value)
    }
    if (header === 'supplier') {
      const supplierName = transformStockImportSupplierName(value)
      if (supplierName) {
        return supplierName
      }
      setStockAdditionWarningMessage(
        `Supplier ${value} is not known.\n\nVisit CONFIGURATOR > PPE to add a new SKU and linked supplier.`,
      )
      setStockAdditionWarningModalOpen(true)
    }

    return value
  }

  const getDefaultHeaderKey = (index: number) => {
    if (index === 0) {
      return 'item'
    }
    if (index === 1) {
      return 'sku'
    }
    if (index === 2) {
      return 'size'
    }
    if (index === 3) {
      return 'quantity'
    }
    if (index === 4) {
      return 'location'
    }
    if (index === 5) {
      return 'poNumber'
    }
    if (index === 6) {
      return 'supplier'
    }
    return ''
  }

  const getDefaultHeaderLabel = (key: string) => {
    return {
      item: 'Item',
      sku: 'SKU',
      size: 'Size',
      quantity: 'Quantity',
      location: 'Location',
      poNumber: 'Purchase Order No',
      supplier: 'Supplier',
    }[key]
  }

  const transformStockImportFilesHeaders = (header: string, index: number) => {
    const defaultHeaderKey = getDefaultHeaderKey(index)
    const headerKey = getCsvFieldHeaderKeyByName(orgConfig, csvProcess, header, defaultHeaderKey)
    return headerKey
  }

  const handleStockToAddSelection = (selectedItems: string[]) => {
    setSelectedStockItems(selectedItems)
  }

  const triggerExportEmailSend = async (emailAddresses: string[]) => {
    // TODO: prepareAuthTokens inside ppeStockExport, passing authCredentials
    const token = await SessionService.prepareAuthTokens(idPassport, password)
    ppeService.ppeStockExport(selectedAssociation, emailAddresses, token)
    toggleEmailCollectorModal()
    toggleExportEmailPendingModal()
  }

  const typeNames = getUniqueTypeLabels(types)

  const content = stockToAddMode ? (
    <PpeStockToAdd items={stockToAddItems || []} onItemSelection={handleStockToAddSelection} />
  ) : selectedTypeName ? (
    <PPEStockDetails selectedType={selectedTypeName} items={typeNames.length > 0 ? getItemDetails() : []} />
  ) : null

  const pageHeader = stockToAddMode ? 'Add stock' : `Stock - ${selectedTypeName}`

  let confirmationModal = null
  if (confirmStockAdditionModalOpen) {
    confirmationModal = (
      <AlertModalTwoButton
        open={confirmStockAdditionModalOpen}
        dismiss={toggleConfirmStockAdditionModal}
        onClick1={toggleConfirmStockAdditionModal}
        onClick2={addSelectedStockItems}
        buttonLabel1="Back"
        buttonLabel2="Yes, Continue"
        buttonDisabled2={selectedStockItems.length === 0}
        header="Are you sure?"
        buttonStyle={{ marginTop: 30 }}
        body={<StockUploadConfirmationMessage selectedStockItems={selectedStockItems} />}
      />
    )
  }

  let successModal = null
  if (stockAdditionSuccessModalOpen) {
    successModal = (
      <AlertModalOneButton
        open={stockAdditionSuccessModalOpen}
        dismiss={toggleStockAdditionModal}
        onClick={() => {
          setStockToAddMode(false)
          toggleStockAdditionModal()
        }}
        buttonLabel="Close"
        header="Stock added successfully"
        body={<StockUploadSuccessMessage selectedStockItems={selectedStockItems} />}
      />
    )
  }
  let warningModal = null
  if (stockAdditionWarningModalOpen) {
    warningModal = (
      <AlertModalOneButton
        open={stockAdditionWarningModalOpen}
        dismiss={() => setStockAdditionWarningModalOpen(false)}
        onClick={() => {
          setStockAdditionWarningModalOpen(false)
          setStockAdditionWarningMessage('')
        }}
        buttonLabel="Close"
        header="Warning"
        body={<div style={{ whiteSpace: 'break-spaces' }}>{stockAdditionWarningMessage}</div>}
      />
    )
  }
  if (saveFailedModalOpen) {
    warningModal = (
      <AlertModalOneButton
        open={saveFailedModalOpen}
        header="Failed to save changes"
        body={
          <div>
            Changes won't be permanently applied. Please try again.
            <p>If problem persists, please contact customer support.</p>
          </div>
        }
        buttonLabel="Ok"
        onClick={toggleSaveFailedModal}
      />
    )
  }

  let exportEmailCollectorModal = null
  if (exportEmailCollectorModalOpen) {
    const user = userRepo.getCurrentUserEntity()
    const userEmailAddress = user.getEmail()
    exportEmailCollectorModal = (
      <InfoCollectorModal
        open={true}
        defaultItems={[userEmailAddress]}
        header="EMAIL TO"
        subHeader="Which email addresses should we send to?"
        warningMessage="Add at least one email address"
        validateInput={checkEmailFormat}
        transformInput={toLowerCaseCustom}
        placeholder="Email address"
        minimumItems={1}
        dismiss={toggleEmailCollectorModal}
        onSuccess={triggerExportEmailSend}
        onReject={toggleEmailCollectorModal}
      />
    )
  }

  let exportEmailPendingModal = null
  if (exportEmailPendingModalOpen) {
    exportEmailPendingModal = (
      <AlertModalOneButton
        open={exportEmailPendingModalOpen}
        header="Stock export triggered"
        body="Stock data export has been triggered and will be sent to the specified email address(es)"
        buttonLabel="Close"
        onClick={toggleExportEmailPendingModal}
      />
    )
  }

  const csvFileHeaders = getCsvFieldHeaderNames(orgConfig, csvProcess, csvHeader)
  return (
    <div style={styles.container}>
      <NavigationBar match={props.match} location={props.location} history={props.history} />
      <SectionHeaderPrimary style={styles.sectionHeader} disabled={true} searchString={''} onClick={() => ({})}>
        {pageHeader}
      </SectionHeaderPrimary>
      <div style={styles.contentContainer}>
        <SideMenu
          visible={!stockToAddMode}
          menuComponents={
            <PPEStockSideMenu
              typeNames={typeNames.sort()}
              selectedItemName={selectedTypeName}
              onItemSelection={(stockItem: string) => setSelectedItemName(stockItem)}
            />
          }
        />
        <div style={styles.rightSide}>
          <Toolbar actionButtons={getActionButtons()} navButtons={{ left: { label: 'BACK' } }} />
          <div style={styles.rightSideContent}>{content}</div>
        </div>
      </div>
      {confirmationModal}
      {successModal}
      {warningModal}
      {exportEmailCollectorModal}
      {exportEmailPendingModal}
      <LoadingModal open={loadingModalOpen} key="loadingModal">
        Loading data...
      </LoadingModal>
      <LoadingModal open={savingModalOpen} key="savingModal">
        Saving changes...
      </LoadingModal>
      <CsvFileProcessingModal
        open={csvUploadModalOpen}
        title="UPLOAD NEW STOCK"
        instructions={<CSVFileUploadInstructions />}
        expectedHeaderColumns={csvFileHeaders}
        csvProcess={csvProcess}
        getDefaultHeaderLabel={getDefaultHeaderLabel}
        transformValue={transformStockImportFileContent}
        transformHeader={transformStockImportFilesHeaders}
        onCancelClick={toggleCSVFileUploadModal}
        onSubmitClick={initiateStockAdditionView}
      />
      <RouteLeavingGuard
        when={changesMade} // should have true value if there are any changes unsaved
        navigate={(path) => props.history.push(path)}
        shouldBlockNavigation={(location) => true}
        alertHeader="Discard changes"
        alertBody={
          <div>
            You have unsaved changes.
            <br />
            Are you sure you want to leave this page without saving?
          </div>
        }
      />
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255, 1), rgba(209,210,230, 1))',
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto 1%',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    paddingInline: 'max(2em, 2%)',
    width: '100%',
    overflow: 'hidden',
  },
  rightSideContent: {
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    display: 'flex',
    flex: 1,
    overflow: 'auto',
    backgroundColor: ColorPalette.CARD_WHITE,
  },
}

const CSVFileUploadInstructions = () => (
  <>
    <p>
      Download CSV template to add necessary row data. Select completed file below for stock to be processed and loaded.
    </p>
  </>
)

const StockUploadConfirmationMessage = (props: { selectedStockItems: string[] }) => (
  <div>{`You are about to upload ${props.selectedStockItems.length} stock items`}</div>
)

const StockUploadSuccessMessage = (props: { selectedStockItems: string[] }) => (
  <div>{`${props.selectedStockItems.length} Items added successfully into the stock management system`}</div>
)
