import React from 'react'

import DataTable from '../../Tables/DataTable/DataTable'
import { IColumnConfig } from '../../Tables/DataTable/DataTableHeader'

export type StockToAdd = {
  item: string
  sku: string
  size: string
  quantity: number
  location: string
  poNumber: string
  supplier: string
}

interface PpeStockToAddProps {
  items: StockToAdd[]
  selectedAssociation?: string
  selectedItem?: string
  style?: React.CSSProperties
  onItemSelection: (selectedItemIds: string[]) => void
}

export const PpeStockToAdd = (props: PpeStockToAddProps) => {
  const { items, onItemSelection } = props

  const tableColumnConfig: IColumnConfig[] = [
    { id: 'item', label: 'Item', sizeFactor: 1 },
    { id: 'sku', label: 'SKU', sizeFactor: 1 },
    { id: 'size', label: 'Size', sizeFactor: 1 },
    { id: 'quantity', label: 'Quantity', sizeFactor: 1 },
    { id: 'location', label: 'Location', sizeFactor: 1 },
    { id: 'poNumber', label: 'PO Number', sizeFactor: 1 },
    { id: 'supplier', label: 'Supplier', sizeFactor: 1 },
  ]

  const handleRowSelect = (selectedItems: Record<string, boolean>) => {
    onItemSelection(Object.keys(selectedItems))
  }

  const data = items.filter((item) => item.supplier)
  const tableData = data.map((item) => {
    return {
      ...item,
      id: `${item.item}-${item.sku}-${item.size}`,
    }
  })

  return (
    <DataTable
      tableData={tableData}
      columnConfig={tableColumnConfig}
      tableWidth={10}
      filterState={{}}
      selectedRowItemId={''}
      authorisedItemIds={[]}
      isSelectionEnabled={true}
      rowsOnSelect={handleRowSelect}
    />
  )
}
