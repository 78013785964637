import { useState } from 'react'
import Radium from 'radium'
import { difference, isEmpty, isEqual } from 'lodash'
import Icon from '@mdi/react'
import { mdiAlert } from '@mdi/js'

import { ColorPalette } from '../../../config/colors'
import DisciplineActionTile from './DisciplineActionTile'
import ButtonBlue from '../../BaseComponents/Buttons/ButtonBlue'
import ButtonGrey from '../../BaseComponents/Buttons/ButtonGrey'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import OptionsSelectorModal from '../../Modals/OptionsSelectorModal'
import AlertTwoButton from '../../Modals/AlertModalTwoButton'
import { getConfiguredProcessInfo, processTypes, removeUnderscores } from '../../../utils'
import DataAndDocsModal from './DataAndDocsModal'

interface IDisciplinaryActionOrderProps {
  processConfig: any
  selectedOffence: string
  selectedDivision: string
  selectedJobType: string
  selectedJobSubType: string
  setOffenceConfigActionExpiry: (action: string, expiry: number) => void
  addOffenceConfigActionFlow: (actionFlowName: string) => void
  removeOffenceConfigActionFlow: (actionFlowItemIndex: number) => void
  setOffenceConfigActionFlowItems: (actionFlowItems: string[]) => void
  // saveDocsToSign: (jobType: string, docsToSign: string[]) => void;
  // saveDataFlows: (jobType: string, dataFlows: string[]) => void;
}

const DisciplinaryActionOrder = (props: IDisciplinaryActionOrderProps) => {
  const {
    selectedOffence,
    selectedDivision,
    selectedJobType,
    selectedJobSubType,
    processConfig,
    setOffenceConfigActionExpiry,
  } = props

  const sourceData = {
    division: selectedDivision || 'ADMIN',
    jobType: selectedJobType || 'allJobTypes',
    jobSubType: selectedJobSubType || 'allJobSubTypes',
    offence: selectedOffence || 'allOffences',
  }
  const offenceActionFlow = getConfiguredProcessInfo('actionFlow', processTypes.discipline, sourceData, processConfig)
  const offenceActionExpiry = getConfiguredProcessInfo(
    'actionExpiry',
    processTypes.discipline,
    sourceData,
    processConfig,
  )
  const actionFlowItemsMinusTerminate = isEmpty(offenceActionFlow)
    ? []
    : offenceActionFlow.filter((action: string) => action !== 'TERMINATE')
  const [draggableActionFlowItems, setDraggableActionFlowItems] = useState(actionFlowItemsMinusTerminate)
  const [newActionItem, setNewActionItem] = useState('')
  const [optionsSelectorModalOpen, setOptionsSelectorModalOpen] = useState(false)
  const [actionFlowItemToDeleteIndex, setActionFlowItemToDeleteIndex] = useState(-1)
  const [dataAndDocsModalOpenActionFlowItemName, setDataAndDocsModalOpen] = useState('')

  const toggleOptionsSelectorModal = () => setOptionsSelectorModalOpen((currentValue) => !currentValue)

  let borderBottomAdjust: React.CSSProperties = {}

  const onDragEndReorder = ({ draggableId, source, destination }: any) => {
    if (!destination) {
      return
    }
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return
    }
    const newDraggableActionFlowItems = [...draggableActionFlowItems]
    const [movedItem] = newDraggableActionFlowItems.splice(source.index, 1)
    // @ts-ignore
    newDraggableActionFlowItems.splice(destination.index, 0, movedItem)
    setDraggableActionFlowItems([...newDraggableActionFlowItems])
  }

  const deleteAction = (index: number) => {
    const newDraggableActionFlowItems = [...draggableActionFlowItems]
    newDraggableActionFlowItems.splice(index, 1)
    setDraggableActionFlowItems([...newDraggableActionFlowItems])
    props.removeOffenceConfigActionFlow(index)
  }

  const saveNewActionFlowItemOrder = () => {
    const newItemOrder = [...draggableActionFlowItems, 'TERMINATE']
    props.setOffenceConfigActionFlowItems(newItemOrder)
  }

  const { ...offenceActionOptions } = offenceActionExpiry
  const offenceReadableName = selectedOffence === 'allOffences' ? 'All Offences' : removeUnderscores(selectedOffence)
  return (
    <div style={styles.section}>
      <h1 style={{ ...styles.sectionHeader, ...borderBottomAdjust }}>{offenceReadableName}</h1>
      {draggableActionFlowItems.length === 0 && (
        <div style={styles.offenceMissingFromConfig}>
          <Icon path={mdiAlert} size={1} color={ColorPalette.PRIMARY_BLUE} style={{ marginRight: '.75em' }} />
          <span style={{ fontSize: '1rem', marginTop: 15 }}>Something is wrong.</span>
          <span>{`"${offenceReadableName}" might be missing from config.`}</span>
        </div>
      )}
      {draggableActionFlowItems.length > 0 && (
        <div
          style={{
            display: 'flex',
            overflowX: 'auto',
            margin: '50px 10px 0px 50px',
          }}>
          <DragDropContext onDragEnd={onDragEndReorder}>
            <Droppable droppableId="actionFlowItemsOrder" direction="horizontal">
              {(provided) => (
                <div ref={provided.innerRef} style={{ display: 'flex' }} {...provided.droppableProps}>
                  {draggableActionFlowItems.map((action: any, index: number) => (
                    <DraggableActionItem
                      action={action}
                      expiry={offenceActionExpiry[action]}
                      index={index}
                      key={action}
                      onDataAndDocsClick={(actionFlowItemName: string) => setDataAndDocsModalOpen(actionFlowItemName)}
                      onDeleteClick={() => setActionFlowItemToDeleteIndex(index)} // TODO: add are you sure?
                      onExpiryChange={(expiryInMilliseconds: number) =>
                        setOffenceConfigActionExpiry(action, expiryInMilliseconds)
                      }
                      draggable={draggableActionFlowItems.length > 1}
                    />
                  ))}
                  <div>{provided.placeholder}</div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div>
            <DisciplineActionTile key="terminate" action="TERMINATE" draggable={false} />
          </div>
          <div style={styles.addFlowItemCard} onClick={toggleOptionsSelectorModal}>
            <div
              style={{
                fontSize: '2.5em',
                color: ColorPalette.PRIMARY_BLUE,
                position: 'absolute' as 'absolute',
                top: '1%',
                right: '8%',
              }}>
              +
            </div>
            ADD FLOW ITEM
          </div>
          <div style={{ minWidth: 150 }} />
        </div>
      )}
      <OptionsSelectorModal
        open={optionsSelectorModalOpen}
        onSuccess={() => {
          props.addOffenceConfigActionFlow(newActionItem)
          setDraggableActionFlowItems([...draggableActionFlowItems, newActionItem])
          toggleOptionsSelectorModal()
        }}
        onReject={toggleOptionsSelectorModal}
        cancelLabel="Cancel"
        successLabel="Save"
        label1="New flow item"
        // @ts-ignore
        options1={difference(Object.keys(offenceActionOptions), offenceActionFlow)}
        selectedOption1={newActionItem}
        onChange={(e) => setNewActionItem(e.target.value)}
      />
      <AlertTwoButton
        open={actionFlowItemToDeleteIndex > -1}
        dismiss={() => setActionFlowItemToDeleteIndex(-1)}
        onClick1={() => setActionFlowItemToDeleteIndex(-1)}
        onClick2={() => {
          deleteAction(actionFlowItemToDeleteIndex)
          setActionFlowItemToDeleteIndex(-1)
        }}
        buttonStyle={{ marginTop: 30 }}
        buttonLabel1="No"
        buttonLabel2="Yes"
        header="Confirm"
        body="Are you sure you want to remove this from the action flow?"
      />
      <DataAndDocsModal
        open={dataAndDocsModalOpenActionFlowItemName !== ''}
        dismiss={() => setDataAndDocsModalOpen('')}
        selectedPrimaryKey={selectedOffence}
        selectedSecondaryKey={dataAndDocsModalOpenActionFlowItemName}
        selectedProcessName="discipline"
        selectedJobType={selectedJobType}
        selectedOffence={selectedOffence}
        selectedOffenceAction={dataAndDocsModalOpenActionFlowItemName}
        onSaveClick={() => setDataAndDocsModalOpen('')}
      />
      {actionFlowItemsMinusTerminate.length === draggableActionFlowItems.length &&
        !isEqual(actionFlowItemsMinusTerminate, draggableActionFlowItems) && (
          <div
            style={{
              display: 'flex',
              gap: 20,
              paddingBottom: '3em',
              width: '91%',
              margin: '40px auto',
            }}>
            <ButtonGrey onClick={() => setDraggableActionFlowItems(actionFlowItemsMinusTerminate)}>
              Undo item re-order
            </ButtonGrey>
            <ButtonBlue onClick={saveNewActionFlowItemOrder}>Save item re-order</ButtonBlue>
          </div>
        )}
    </div>
  )
}

const DraggableActionItem = (props: any) => (
  <Draggable draggableId={props.action} index={props.index}>
    {(provided) => (
      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
        <DisciplineActionTile
          key={`${props.action}_test`}
          action={props.action}
          expiry={props.expiry}
          draggable={props.draggable}
          onDeleteClick={props.onDeleteClick}
          onExpiryChange={props.onExpiryChange}
        />

        <div style={{ padding: 15, marginBottom: '1em' }}>
          <ButtonGrey style={{ fontSize: '0.85rem' }} onClick={() => props.onDataAndDocsClick(props.action)}>
            Data & Docs
          </ButtonGrey>
        </div>
      </div>
    )}
  </Draggable>
)

const styles = {
  section: {
    width: '100%',
    margin: '4% 0',
    paddingBottom: '20px',
  },
  sectionHeader: {
    marginTop: 0,
    fontSize: '1rem',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    color: ColorPalette.PRIMARY_TEXT,
    textAlign: 'left' as 'left',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid' as 'solid',
    borderBottomColor: ColorPalette.PRIMARY_BLUE,
    padding: '0em 0.5em 0.5em 0.5em',
    display: 'flex',
    alignItems: 'center',
  },
  sectionInput: {
    width: '100%',
    maxWidth: '1000px',
    marginTop: 40,
    // cursor: "pointer",
  },
  button: {
    fontWeight: '550',
    fontSize: '0.8rem',
    backgroundColor: ColorPalette.CARD_WHITE,
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    paddingLeft: 30,
    marginTop: 0,
    width: '170px',
    ':hover': {
      backgroundColor: 'inherit',
    },
    ':active': {
      backgroundColor: 'inherit',
    },
    cursor: 'pointer',
  },
  buttonIcon: {
    width: '1.3rem',
    height: '1.3rem',
    marginRight: '15px',
  },
  addFlowItemCard: {
    fontSize: '0.8rem',
    color: ColorPalette.PRIMARY_TEXT,
    margin: 2,
    minWidth: window.innerWidth * 0.12,
    width: window.innerWidth * 0.12,
    height: window.innerWidth * 0.11,
    backgroundImage: `linear-gradient(to bottom, ${ColorPalette.CARD_WHITE}, ${ColorPalette.OFF_WHITE})`,
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 2px 6px rgba(0, 0, 60, 0.16)',
    outline: 0,
    border: 'none',
    ':hover': {
      fontWeight: 500,
      backgroundImage: `linear-gradient(to top, ${ColorPalette.BUTTON_HOVER_GREY}, ${ColorPalette.BUTTON_HOVER_GREY})`,
    },
    ':active': {
      filter: 'brightness(85%)',
    },
    position: 'relative' as 'relative',
    userSelect: 'none' as 'none',
    cursor: 'pointer',
  },
  offenceMissingFromConfig: {
    color: ColorPalette.PRIMARY_TEXT,
    marginTop: '18%',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

export default Radium(DisciplinaryActionOrder)
