import { mdiDownload } from '@mdi/js'
import Icon from '@mdi/react'
import { ColorPalette } from '../../../config'
import { CsvService } from '../../../services'

type CsvTemplateDownloadProps = {
  label?: string
  headerColumnNames: string[]
}

export const CsvTemplateDownload = (props: CsvTemplateDownloadProps) => {
  const { label, headerColumnNames } = props

  const generateAndDownloadCsvFile = async () => {
    const csvData = CsvService.compileCsvTemplateHeaderRow(headerColumnNames)
    CsvService.downloadCsvFile(csvData)
  }

  return (
    <div onClick={generateAndDownloadCsvFile} style={styles.downloadContainer}>
      <Icon path={mdiDownload} color={ColorPalette.PRIMARY_TEXT} style={styles.buttonIconStyle} />
      {label || 'Download CSV template'}
    </div>
  )
}

const styles = {
  buttonIconStyle: {
    marginRight: 5,
    width: 15,
    height: 'auto',
  },
  downloadContainer: {
    color: ColorPalette.PRIMARY_TEXT,
    margin: '2em auto 0em',
    fontWeight: 'bolder',
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'center',
    cursor: 'pointer',
  },
}
