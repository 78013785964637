import { ColorPalette } from '../../config/colors'

import Radium from 'radium'
import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import SearchIcon from '@material-ui/icons/Search'
import Lottie from 'react-lottie'

import TextInputBasic from '../BaseComponents/Text/TextInputBasic'
import syncArrows from '../../assets/syncArrows.json'
import { Property } from 'csstype'

interface SectionHeaderProps {
  searchEnabled?: boolean
  style?: React.CSSProperties | undefined
  labelStyle?: React.CSSProperties | undefined
  children: React.ReactNode | string
  disabled?: boolean
  downloading?: boolean
  searchString?: string

  onClick: () => void
  textHandler?: (e: React.ChangeEvent<{ value: string }>) => void
}

interface SectionHeaderState {
  searchEnabled: boolean
  showSearchBox: boolean
  marginLeft: string
}

export class SectionHeaderComponent extends Component<SectionHeaderProps, SectionHeaderState> {
  state = {
    searchEnabled: false,
    showSearchBox: false,
    marginLeft: '32%',
  }

  componentDidUpdate(prevProps: SectionHeaderProps) {
    if (prevProps.searchEnabled !== this.props.searchEnabled) {
      this.setState({ searchEnabled: this.props.searchEnabled || false })
    }
  }

  disableSearch() {
    this.setState({ searchEnabled: false }, () => {
      setTimeout(() => this.setState({ marginLeft: '32%', showSearchBox: false }), 0.15)
    })
  }

  enableSearch() {
    this.setState({ searchEnabled: true }, () => {
      setTimeout(() => this.setState({ marginLeft: '2.5%', showSearchBox: true }), 0.15)
    })
    this.props.onClick()
  }

  textHandler(e: React.ChangeEvent<{ value: string }>): void {
    if (typeof this.props.textHandler === 'undefined') {
      return
    }
    this.props.textHandler(e)
  }

  render() {
    let length = 1

    if (Array.isArray(this.props.children) || typeof this.props.children === 'string') {
      length = this.props.children.length
    }

    const searchDisbaled = {
      marginLeft: window.innerWidth * 0.15 - (this.props.children ? length * 2.5 : 0),
    }

    const searchEnabled = {
      marginLeft: '2.5%',
    }

    const fadeOut = Radium.keyframes({
      '0%': searchEnabled,
      '100%': searchDisbaled,
    }) as Property.AnimationName

    const fadeIn = Radium.keyframes({
      '0%': searchDisbaled,
      '100%': searchEnabled,
    }) as Property.AnimationName

    styles.text = {
      ...styles.text,
      marginLeft: this.state.marginLeft,
      animation: this.state.searchEnabled ? 'x 0.15s ease-in' : 'x 0.15s ease-out',
      animationName: this.state.searchEnabled ? fadeIn : fadeOut,
      animationFillMode: 'forwards',
    }

    let filterButton = (
      <button
        style={{ ...styles.buttonContainer }}
        onClick={() => (this.state.searchEnabled ? this.disableSearch() : this.enableSearch())}
        disabled={this.props.disabled}>
        <SearchIcon style={styles.iconStyle} />
      </button>
    )
    if (this.props.disabled) {
      filterButton = <div style={{ ...styles.placeholderContainer }} />
    }

    let searchBox = null
    if (this.state.showSearchBox && this.state.searchEnabled) {
      searchBox = (
        <div style={{ ...styles.searchContainer }}>
          <TextInputBasic
            style={{ opacity: 0.9 }}
            value={this.props.searchString || ''}
            placeholder={'Search'}
            onChange={(e: React.ChangeEvent<{ value: string }>) => this.textHandler(e)}
          />
        </div>
      )
    }

    let downloadIndicator = null
    if (this.props.downloading) {
      downloadIndicator = (
        <Lottie speed={1.6} options={{ animationData: syncArrows, loop: true }} style={styles.downloadIndicator} />
      )
    }
    return (
      <div style={{ ...styles.container, ...this.props.style }}>
        <div style={{ ...styles.line }} />
        <Grid
          item
          container
          style={{ ...styles.container2 }}
          direction="row"
          justify="flex-start"
          alignItems="center"
          xs={10}
          sm={8}
          md={6}
          lg={6}>
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            {filterButton}
            <h1 style={{ ...styles.text, ...this.props.labelStyle }}>{this.props.children}</h1>
            {searchBox}
          </div>
          {downloadIndicator}
        </Grid>
      </div>
    )
  }
}

let styles: Record<string, React.CSSProperties | Record<string, React.CSSProperties>> = {
  container: {
    width: '80%',
    alignSelf: 'center',
    height: 40,
    backgroundColor: 'rgba(255,255,255,0)',
    zIndex: 1,
  },
  container2: {
    position: 'relative',
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    // top: 0,
    margin: '-20px auto 0',
    width: '55%',
    height: 40,
    borderRadius: 20,
    backgroundColor: ColorPalette.MEDIUM_GREY,
    backgroundImage: `linear-gradient(to bottom, ${ColorPalette.HEADER_BOTTOM}, ${ColorPalette.HEADER_BOTTOM})`,
    boxShadow: '0px 2px 4px rgba(60,60,60, 0.3)',
  },
  line: {
    width: '100%',
    position: 'relative',
    alignSelf: 'center',
    // top: 20,
    height: 1,
    backgroundColor: ColorPalette.MEDIUM_GREY,
  },
  iconStyle: {
    color: ColorPalette.CARD_WHITE,
    width: '1.3rem',
    height: '1.3rem',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '10%',
    height: '100%',
    borderRadius: 30,
    border: 'none',
    outline: 0,
    backgroundColor: 'rgba(255,255,255,0)',
    ':hover': {
      filter: 'brightness(85%)',
      backgroundColor: 'rgba(255,255,255,0.2)',
    },
    ':active': {
      filter: 'brightness(50%)',
    },
  },
  placeholderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '10%',
    height: '100%',
    borderRadius: 30,
    border: 'none',
    outline: 0,
    backgroundColor: 'rgba(255,255,255,0)',
  },
  searchContainer: {
    marginLeft: '1.5%',
    marginRight: '1.5%',
    paddingLeft: '1.5%',
    width: '50%',
    height: '40%',
    display: 'flex',
    alignItems: 'center',
    borderLeft: `2px solid ${ColorPalette.PRIMARY_LIGHT_TEXT}`,
  },
  text: {
    marginLeft: '32%',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    fontWeight: '550',
    color: ColorPalette.PRIMARY_LIGHT_TEXT,
  },
  downloadIndicator: {
    height: '55%',
    width: '5%',
    marginLeft: -window.innerWidth * 0.03,
  },
}

export default Radium(SectionHeaderComponent)
