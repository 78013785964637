import { useEffect, useState } from 'react'
import Icon from '@mdi/react'
import { mdiAlertCircle } from '@mdi/js'

import { ColorPalette } from '../../../config'
import ButtonBlue from '../../BaseComponents/Buttons/ButtonBlue'
import ButtonGrey from '../../BaseComponents/Buttons/ButtonGrey'
import { CalenderRanges } from '../../BaseComponents/Calendar/CalendarRanges'
import Modal from '../Modal'

type DateRangeConfigItem = {
  label: string
  defaultStartDateMs?: number
  defaultEndDateMs?: number
  maxRangeMs?: number
  maxRangeExceededMessage?: string
  messageGenerator?: (dateRangeLabel: string) => string
}

export type DateRangeConfig = DateRangeConfigItem[]

export type DateRangePoint = 'startDate' | 'endDate'
export type DateRange = {
  startDate: Date
  endDate: Date
  // dateRangeExceedsMax?: boolean
  // startExceedsEndDate?: boolean
}

export type DateRangeSelections = Record<string, DateRange>

type DateRangesModalProps = {
  open: boolean
  title: string
  dateRangeConfig: DateRangeConfig
  onCancelClick: () => void
  onSaveClick: (dateRangeSelections: DateRangeSelections) => void
  onInvalidDateRangeSave?: () => void
}

export function DateRangesModal(props: DateRangesModalProps) {
  const { open, title, dateRangeConfig, onCancelClick, onSaveClick, onInvalidDateRangeSave } = props

  const [dateRangeSelections, setDateRangeSelections] = useState<DateRangeSelections>({})

  useEffect(() => {
    const dateRanges = initialiseDateRangeStateFromConfig()
    setDateRangeSelections(dateRanges)
  }, [])

  const initialiseDateRangeStateFromConfig = () => {
    const initialDateRangeSelections: DateRangeSelections = {}
    const today = new Date()
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)

    dateRangeConfig.forEach((configItem) => {
      const startDate = configItem.defaultStartDateMs ? new Date(configItem.defaultStartDateMs) : yesterday
      const endDate = configItem.defaultEndDateMs ? new Date(configItem.defaultEndDateMs) : today
      initialDateRangeSelections[configItem.label] = {
        startDate,
        endDate,
      }
    })
    return initialDateRangeSelections
  }

  const validateMaxDateRange = (selectionLabel: string) => {
    const dateRange = dateRangeSelections[selectionLabel]

    const config = dateRangeConfig.find((configItem) => configItem.label === selectionLabel)
    if (!config) {
      return false
    }
    const maxRangeMs = config.maxRangeMs
    if (!maxRangeMs) {
      return true
    }
    const startDateMs = dateRange.startDate.getTime()
    const endDateMs = dateRange.endDate.getTime()

    const dateSelectionExceedsMaxRange = endDateMs - startDateMs > maxRangeMs
    if (dateSelectionExceedsMaxRange) {
      return false
    }

    return true
  }

  const validateStartBeforeEndDate = (selectionLabel: string) => {
    const dateRange = dateRangeSelections[selectionLabel]
    const startDateMs = dateRange.startDate.getTime()
    const endDateMs = dateRange.endDate.getTime()
    const isStartBeforeEndDate = startDateMs < endDateMs
    if (isStartBeforeEndDate) {
      return true
    }
    return false
  }

  const handleDateChange = (date?: number, rangePoint?: DateRangePoint, id?: string) => {
    if (!date || !rangePoint || !id) {
      return
    }
    const currentDateRangeSelections = { ...dateRangeSelections }
    const newDate = new Date(date)
    currentDateRangeSelections[id][rangePoint] = newDate

    setDateRangeSelections(currentDateRangeSelections)
  }

  const handleSaveClick = () => {
    const dateRanges = dateRangeSelections
    const invalidDateRange = Object.keys(dateRanges).some(
      (key: string) => !validateMaxDateRange(key) || !validateStartBeforeEndDate(key),
    )
    if (invalidDateRange) {
      if (onInvalidDateRangeSave) {
        onInvalidDateRangeSave()
      }
      return
    }
    onSaveClick(dateRanges)
  }

  const handleDateRangeValidation = (selectionLabel: string) => {
    if (!validateStartBeforeEndDate(selectionLabel)) {
      return <InvalidDateRange message="Start date must be before end date" />
    }
    if (!validateMaxDateRange(selectionLabel)) {
      return <InvalidDateRange message="Date range exceeds maximum range" />
    }
    return null
  }

  let modal = (
    <Modal
      open={open}
      title={title}
      actionButtons={[
        <ButtonGrey onClick={onCancelClick}>Cancel</ButtonGrey>,
        <ButtonBlue onClick={handleSaveClick}>Save</ButtonBlue>,
      ]}>
      <CalenderRanges
        dateRangeConfig={dateRangeConfig}
        selections={dateRangeSelections}
        onDateRangeChange={handleDateChange}
        validateDateRange={handleDateRangeValidation}
        style={{ padding: '0 7.5px' }}
      />
    </Modal>
  )

  // / <Dialog
  //   open={true}
  //   onBackdropClick={onCancelClick}
  //   BackdropProps={{ invisible: true }}
  //   PaperProps={{ style: { borderRadius: 8 } }}
  //   style={styles.dialog}>
  //   <div style={styles.contentContainer}>
  //     <h1 style={styles.title}>{title}</h1>

  //  <div style={styles.buttons}>
  //     <ButtonGrey onClick={onCancelClick} style={{ marginRight: '1em' }}>
  //       Cancel
  //     </ButtonGrey>
  //     <ButtonBlue onClick={handleSaveClick}>Save</ButtonBlue>
  //   </div>
  // </div

  return modal
}

const styles = {
  contentContainer: {
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
    padding: '2.5em',
  },
  buttons: { display: 'flex', marginTop: '5em' },
  dialog: { backgroundColor: ColorPalette.MODAL_BACKGROUND_OVERLAY, borderRadius: 8 },
  title: {
    marginTop: 0,
    marginBottom: '1.5em',
    textAlign: 'center' as 'center',
    alignSelf: 'center',
    fontFamily: 'roboto',
    color: ColorPalette.PRIMARY_TEXT,
    paddingLeft: 10,
    paddingRight: 10,
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
  textSubHeaderStyle: {
    marginTop: 45,
    textAlign: 'center' as 'center',
    alignSelf: 'center',
    fontFamily: 'roboto',
    fontWeight: 'normal',
    color: ColorPalette.PRIMARY_TEXT,
    paddingLeft: 20,
    paddingRight: 20,
  },
  additionalInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '1em',
  },
}

const InvalidDateRange = (props: { message: string }) => (
  <div style={styles.additionalInfo}>
    <Icon path={mdiAlertCircle} size={1} color={ColorPalette.DARK_MEDIUM_GREY} />
    <p style={{ color: ColorPalette.DARK_MEDIUM_GREY, fontSize: '0.8rem', marginLeft: 5 }}>{props.message}</p>
  </div>
)
