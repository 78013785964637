import { ColorPalette } from '../../../config/colors'
import { removeUnderscores } from '../../../utils'

import React from 'react'
import { ScrollView } from '@cantonjs/react-scroll-view'
import ScrollMenu from 'react-horizontal-scrolling-menu'
import { DisciplineAction } from '../../../models'

interface ActionFlowsProps {
  data: any
  generateActionComponent: (disciplineAction: DisciplineAction) => JSX.Element
}

function renderActionSection(
  actionFlowData: DisciplineAction[],
  section: string,
  generateActionComponent: (disciplineAction: DisciplineAction) => JSX.Element,
): JSX.Element {
  return (
    <div key={section} style={styles.actionFlowContainer}>
      <p style={styles.actionTitle}>{removeUnderscores(section)}</p>
      <div style={{ ...styles.line }} />
      <ScrollMenu
        translate={0}
        wheel={false}
        dragging={true}
        alignCenter={false}
        wrapperStyle={{ paddingBottom: 30, paddingLeft: 10 }}
        data={actionFlowData.map((dataItem) => generateActionComponent(dataItem))}
      />
    </div>
  )
}

const actionFlows: React.FC<ActionFlowsProps> = (props: ActionFlowsProps) => {
  let actionFlows = (
    <div style={{ ...styles.promptContainer }}>
      <p style={{ ...styles.text, color: ColorPalette.DARK_MEDIUM_GREY }}>No active cases</p>
    </div>
  )

  if (Object.keys(props.data).length) {
    actionFlows = (
      <ScrollView style={styles.scrollContainer} contentContainerStyle={{ paddingBottom: 100 }}>
        {Object.keys(props.data).map((section) =>
          renderActionSection(props.data[section], section, props.generateActionComponent),
        )}
      </ScrollView>
    )
  }
  return actionFlows
}

const styles = {
  promptContainer: {
    marginTop: window.innerHeight * 0.3,
    width: '100%',
    height: '78%',
    alignItems: 'center',
    textAlign: 'center' as 'center',
  },
  text: {
    color: ColorPalette.PRIMARY_LIGHT_TEXT,
    fontWeight: 'normal',
    fontSize: 15,
  },
  scrollContainer: {
    marginTop: 30,
    width: '86%',
    height: window.innerHeight * 0.62,
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'flex-start',
  },
  actionFlowContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: 50,
  },
  actionTitle: {
    textAlign: 'left' as 'left',
    fontSize: '0.8rem',
    textTransform: 'uppercase' as 'uppercase',
    color: ColorPalette.PRIMARY_TEXT,
    margin: 0,
    marginTop: 10,
    marginLeft: 20,
  },
  line: {
    width: '100%',
    borderTop: `1px solid ${ColorPalette.PRIMARY_BLUE}`,
    marginTop: 10,
    paddingBottom: 15,
  },
  tileButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '94%',
    alignSelf: 'center',
  },
  row: {
    flex: 1,
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: 100,
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
}

export default actionFlows
