import { get, keys, set, update } from 'idb-keyval'
import { LocalStorageProvider } from '../provider'

export const hasClientCachedTimeBasedDocs = async (selectedAssociation: string, selectedCohort: string) => {
  const keyName = `${selectedAssociation}-${selectedCohort}-timebased-docs`
  const existingDocsCacheKeys = await keys()
  return keyName in existingDocsCacheKeys
}

export const initTimeBasedDocsCache = async (selectedAssociation: string, selectedCohort: string) => {
  const keyName = `${selectedAssociation}-${selectedCohort}-timebased-docs`
  await update(keyName, () => {
    return { timestamp: Date.now(), docs: {} }
  })
}

export const getAllCachedTimeBasedDocs = async (
  selectedAssociation: string,
  selectedCohort: string,
  checkIfCacheExist: boolean = true,
) => {
  const keyName = `${selectedAssociation}-${selectedCohort}-timebased-docs`
  let cacheExists = true

  if (checkIfCacheExist) {
    const existingDocsCacheKeys = await keys()
    cacheExists = existingDocsCacheKeys.includes(keyName)
  }
  if (cacheExists) {
    const obj = await get(keyName)
    if (obj) {
      const { docs } = obj
      return docs
    }
  }
  return []
}

export const getTimeBasedDocsCacheTimestamp = async (
  selectedAssociation: string,
  selectedCohort: string,
  checkIfCacheExist: boolean = true,
) => {
  const storageKey = `${selectedAssociation}-${selectedCohort}-timebased-docs`
  let cacheExists = true
  if (checkIfCacheExist) {
    cacheExists = await LocalStorageProvider.hasStorageKey(storageKey)
  }
  if (cacheExists) {
    const obj = await LocalStorageProvider.getData(storageKey)
    if (obj) {
      const { timestamp } = obj
      return timestamp
    }
  }
  return
}

// export const getCachedTimeBasedDocs = async (selectedAssociation: string, idPassport: string) => {
//     const keyName = `${selectedAssociation}-${selectedCohort}-timebased-docs`;
//     const cacheExists = await hasClientCachedTimeBasedDocs(selectedAssociation);
//     if (cacheExists) {
//         const value = await get(keyName);
//         if (value) {
//             const docs = JSON.parse(value);
//             return docs[idPassport];
//         }
//     }
//     return;
// };

const hasSufficientStorage = async () => {
  if ('storage' in navigator && 'estimate' in navigator.storage) {
    const { quota: storageQuota, usage: storageUsage }: { quota?: number; usage?: number } =
      await navigator.storage.estimate()
    const storagePercentageAvailable =
      storageQuota && storageUsage ? ((storageQuota - storageUsage) / storageQuota) * 100 : 49
    return storagePercentageAvailable >= 50
  }
  return false
}

// export const updateCachedTimeBasedDocs = async (selectedAssociation: string, idPassport: string, docs: any) => {
//     if (!hasSufficientStorage()) {
//         return;
//     }

//     const keyName = `${selectedAssociation}-${selectedCohort}-timebased-docs`;
//     const currentTimeBasedDocs = await getCachedTimeBasedDocs(selectedAssociation, idPassport);

//     currentTimeBasedDocs[idPassport] = docs;
//     return update(keyName, cachedDocsJson => {
//         const cachedDocs = cachedDocsJson ? JSON.parse(cachedDocsJson) : {};
//         const newDocs = { ...cachedDocs, ...currentTimeBasedDocs };
//         return JSON.stringify(newDocs);
//     });
// };

export const replaceAllCachedTimeBasedDocs = async (selectedAssociation: string, selectedCohort: string, docs: any) => {
  if (!hasSufficientStorage()) {
    console.error('Insufficient storage to cache timebased docs')
    return
  }
  const keyName = `${selectedAssociation}-${selectedCohort}-timebased-docs`
  return set(keyName, { timestamp: Date.now(), docs: docs })
}
