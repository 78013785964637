import { useEffect, useRef } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController,
} from 'chart.js'

import { Bar } from 'react-chartjs-2'
import { ColorPalette } from '../../config'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController,
)

const BarChart = (props: any) => {
  const chartRef = useRef<any>()

  useEffect(() => {
    if (chartRef.current) {
      const canvas = chartRef.current.canvas
      canvas.parentNode.style.height = `calc(100vh - ${canvas.offsetTop}px - 16px)`
    }
  }, [])

  const { data, labels, title, showLegend = true } = props

  const plugins: any = {
    legend: {
      display: showLegend,
      position: 'top' as const,
    },
  }
  if (title) {
    plugins.title = {
      display: true,
      text: title,
    }
  }

  const scaleConfig = {
    grid: {
      display: false,
    },
    ticks: {
      padding: 5,
      color: ColorPalette.SECONDARY_TEXT,
      font: {
        weight: '350',
        size: 12,
      },
    },
  }

  const options = { 
    responsive: true,
    maintainAspectRatio: false,
    plugins,
    scales: { x: scaleConfig, y: scaleConfig },
  }

  const chartData = {
    labels,
    datasets: data,
  }

  return (
    <div style={props.style}>
      <Bar ref={chartRef} data={chartData} options={options} />
    </div>
  )
}

export default BarChart
