/**
 * Test using "pf employees filter -e prod -f zodProfile" since this processes all profiles
 */

// import { ALL_CLIENTS } from "../const.js";
import { z } from 'zod'
// import { EmploymentStatus, TrashStatus } from "./Profile.js";

export const BILLED_CLIENTS = [
  'ambrosia',
  'backsberg_berries',
  'berrybase',
  'boplaas',
  'eurafruit',
  'farmagrowers',
  'glen_fruin',
  'graaff_fruit',
  'rietfontein',
  'hoopenberg_berries',
  'howbill',
  'isilumko',
  'kbos',
  'krispy',
  'la_plaisante',
  'le_arc_berries',
  'peopleflow',
  'modelpak',
  'mosaic',
  'morester',
  'paul_cluver',
  'rora_orchards',
  'six33_berries_packing',
  'strathbreede',
  'stanislaus',
  'thusanyo',
  'vadersgawe',
  'vizual',
  'vizual_security',
  'vizual_health',
  'windermere',
  'steenkamp_lbd',
] as const

export const UNBILLED_CLIENT = ['backsberg_vineyards', 'demo', 'demo_labour_broker', 'picsa', 'haygrove'] as const

export const ALL_CLIENTS = [...BILLED_CLIENTS, ...UNBILLED_CLIENT] as const

export enum EmploymentStatus {
  EMPLOYEE = 'EMPLOYEE',
  CANDIDATE = 'CANDIDATE',
}

export enum TrashStatus {
  NONE = 'NONE',
  TRASHED = 'TRASHED',
  PERMANENT = 'PERMANENT',
}

const employmentStatusSchema = z.nativeEnum(EmploymentStatus)
const trashStatusSchema = z.nativeEnum(TrashStatus)

const processesSchema = z.enum(['employ', 'terminate', 'transfer', 'promote'])

const generalDataBaseSchema = z.object({
  employmentStatus: employmentStatusSchema,
  name: z.union([z.string(), z.array(z.string())]).optional(),
  idPassport: z.string().optional(),
})

const clientNameSchema = z
  .enum(ALL_CLIENTS)
  .or(z.enum(['UNKNOWN']))
  .catch((_) => 'UNKNOWN')

const generalDataDynamicDataSchema = z.record(
  z.string(),
  z.union([
    z.string(),
    z.number(),
    z.array(z.string()),
    z.array(z.number()),
    z.boolean(),
    z.array(z.boolean()),
    z.null(),
    z.undefined(),
    employmentStatusSchema,
    z.object({}), // For faceShots... why?????
  ]),
)
const generalDataSchema = z.intersection(generalDataBaseSchema, generalDataDynamicDataSchema)

const ddbRecordSchema = z.object({
  idPassport: z.string(),
  uid: z.string(),
  changeClassifier: z.boolean().optional(),
})

const segmentType = z.union([z.string(), z.array(z.string()), z.undefined()]).default('')

const baseSegmentSchema = z.object({
  division: segmentType,
  jobType: segmentType,
  jobSubType: z.string().default(''),
})

const employmentSegmentSchema = baseSegmentSchema.extend({
  action: processesSchema,
})

const competencySegmentSchema = baseSegmentSchema.extend({
  competency: z.string(),
})

const disciplineSegmentSchema = baseSegmentSchema.extend({
  offence: z.string().optional(),
})

const rawDataSchema = z.intersection(
  baseSegmentSchema,
  z.record(
    z.string(),
    z.union([
      z.string(),
      z.number(),
      z.array(z.string()),
      z.array(z.number()),
      z.boolean(),
      z.array(z.boolean()),
      z.null(),
      z.object({}),
    ]),
  ),
)

const employmentHistoryEventRawDataSchema = z.intersection(employmentSegmentSchema, rawDataSchema)
const disciplineHistoryEventRawDataSchema = z.intersection(disciplineSegmentSchema, rawDataSchema)

const docsV2Schema = z.object({
  docId: z.string(),
  docType: z.enum(['Contract', 'PhotoCopy']),
  unixMs: z.number(),
})

const employmentHistoryEventSchema = z.object({
  docs: z.array(z.string()),
  docsV2: z.array(docsV2Schema).optional(),
  photos: z.array(z.string()).optional(),
  rawData: employmentHistoryEventRawDataSchema.optional(),
  segment: employmentSegmentSchema.optional(),
})

const competencyHistoryEventSchema = z.object({
  docs: z.array(z.string()),
  photos: z.array(z.string()).optional(),
  uid: z.string().optional(),
  rawData: z
    .object({
      competencyCapturedByName: z.string(),
      competencyName: z.string(),
      competencyCapturedByIdPassport: z.string(),
      competencyIssuedDate: z.string().optional(),
      competencyCapturedDate: z.string(),
      competencyExpiryDate: z.string().optional(),
    })
    .optional(),
  segment: competencySegmentSchema.optional(),
})

const disciplineHistoryEventSchema = z.object({
  uid: z.string(),
  isPaused: z.boolean().optional(),
  docs: z.array(z.string()).catch([]).optional(),
  offence: z.string().optional(),
  custom: z.union([z.boolean(), z.array(z.string())]).optional(),
  rawData: disciplineHistoryEventRawDataSchema.optional(),
  type: z.string().optional(),
  photos: z.array(z.string()).optional(),
  actionLabel: z.string().optional(),
  segment: disciplineSegmentSchema.optional(),
})

const disciplineHistoryAndFlows = z.object({
  disciplineHistory: z.array(disciplineHistoryEventSchema).optional(),
  activeActionFlows: z.any().optional(),
})
const disciplineSchema = z.record(clientNameSchema, disciplineHistoryAndFlows)

const profileDocsSchema = z.record(clientNameSchema, z.array(z.string()).catch([]))

export const ddbProfileSchema = ddbRecordSchema.merge(
  z.object({
    generalData: generalDataSchema,
    cohorts: z.array(z.string()),
    trashStatus: trashStatusSchema,
    pfid: z.string(),
    client: clientNameSchema.optional(),
    docs: profileDocsSchema.optional(),
    faceShots: z.any().optional(),
    employment: z
      .object({
        history: z.array(employmentHistoryEventSchema),
      })
      .optional(),
    competencies: z
      .object({
        history: z.array(competencyHistoryEventSchema),
      })
      .optional(),
    discipline: disciplineSchema.optional(),
    updatedMs: z.record(z.string(), z.union([z.string(), z.number()])).optional(),
  }),
)

export type DdbProfileDocument = z.infer<typeof ddbProfileSchema>
type DocsV2 = z.infer<typeof docsV2Schema>
type EmploymentHistoryEvent = z.infer<typeof employmentHistoryEventSchema>
type DisciplineHistoryAndFlows = z.infer<typeof disciplineHistoryAndFlows>
type ClientName = z.infer<typeof clientNameSchema>

function isString(val: any) {
  return typeof val === 'string' || val instanceof String
}

export function safeToNumber(val: any) {
  if (!isString(val)) {
    return val
  }
  return +val
}

function parseDocEntry(docName: string) {
  const [unixMsStr, docTypeStr, docIdStr] = docName.split('___')

  function getDocId(docIdStr: string) {
    if (!docIdStr) {
      return '???'
    }
    const docIdStrSplit = docIdStr.split('.')
    if (docIdStrSplit.length === 0) {
      return '???'
    }
    if (docIdStrSplit.length === 1) {
      return docIdStrSplit[0]
    }
    // remove extension
    docIdStrSplit.pop()
    return docIdStrSplit.join('')
  }

  function parseDocType(docTypeStr: string): DocsV2['docType'] {
    switch (docTypeStr) {
      case 'Contract':
        return 'Contract'
      case 'PhotoCopy':
        return 'PhotoCopy'
      default:
        throw new Error(`Unknown doc type ${docTypeStr}`)
    }
  }
  return {
    docId: getDocId(docIdStr),
    docType: parseDocType(docTypeStr),
    unixMs: parseInt(unixMsStr),
  }
}

export function inflateDoc(doc: DdbProfileDocument) {
  doc.employment?.history.forEach((event: EmploymentHistoryEvent) => {
    event.segment = employmentSegmentSchema.parse(event.rawData)
    event.docsV2 = event.docs.map(parseDocEntry)
  })

  const clientNames = Object.keys(doc.discipline || {})
  for (const clientName of clientNames) {
    const clientDiscipline: DisciplineHistoryAndFlows | undefined = doc.discipline
      ? doc.discipline[clientName as ClientName]
      : undefined

    if (!clientDiscipline) {
      continue
    }
    const clientDisciplineHistory = clientDiscipline.disciplineHistory
    if (!clientDisciplineHistory) {
      continue
    }

    clientDisciplineHistory.forEach((event: any) => {
      if (!event) {
        return
      }
      event.segment = disciplineSegmentSchema.parse(event.rawData)
    })
  }
}
