import { Component } from 'react'
import Radium from 'radium'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { camelCase } from 'lodash'
import LabelCollector from '../Modals/LabelCollector'
import TextInputOutlined from '../BaseComponents/Text/TextInputOutlined'
import { isInViewport, toUpperCaseCustom } from '../../utils'
import { ColorPalette } from '../../config/colors'
import { SideMenuOptions } from './SideMenuOptions'
import { IFieldConfigItem } from '../../interfaces'

dayjs.extend(relativeTime)

interface IDataFieldsSideMenuProps {
  selectedField: string
  fieldConfig: any
  onFieldClick: (dataField: string, newFieldConfigItem?: IFieldConfigItem) => void
}

interface IDataFieldsSideMenuState {
  addPositionModalOpen: boolean
  scrollToNewlyAddedField: string
  searchString: string
}

class DataFieldsSideMenu extends Component<IDataFieldsSideMenuProps, IDataFieldsSideMenuState> {
  refs: Record<string, HTMLDivElement> = {}

  state = {
    addPositionModalOpen: false,
    scrollToNewlyAddedField: '',
    searchString: '',
  }

  componentDidUpdate(
    prevProps: Readonly<IDataFieldsSideMenuProps>,
    prevState: Readonly<IDataFieldsSideMenuState>,
    snapshot?: any,
  ): void {
    // logic here ensures that a newly added field, which was duplicated shows up in the center of the side menu
    if (
      this.props.selectedField !== '' &&
      prevProps.selectedField !== this.props.selectedField &&
      this.refs[this.props.selectedField]
    ) {
      const selectedFieldShows = isInViewport(this.refs[this.props.selectedField], 100) === false
      if (selectedFieldShows) {
        this.refs[this.props.selectedField].scrollIntoView({ block: 'center' })
      }
    }
  }

  saveNewField = (newFieldName: string) => {
    const key = camelCase(newFieldName)
    this.props.fieldConfig[key] = {
      key,
      compulsory: false,
      compType: '',
      label: newFieldName.toUpperCase(),
      // dateFormat: "",
      multiLine: false,
      // keyboardType: "",
      // validationType: "",
      // maxLength: undefined,
      suggestionsEnabled: false,
    }
    this.setState({ scrollToNewlyAddedField: key }, () => {
      const newFieldItemConfig = this.props.fieldConfig[key]
      this.props.onFieldClick(key, newFieldItemConfig)
    })
    this.closeModal()
  }

  closeModal = () => this.setState({ addPositionModalOpen: false })

  searchHandler = (e: any) => {
    this.setState({ searchString: toUpperCaseCustom(e.target.value) })
  }

  optionButtonRefCallBack = (dataField: string, el: HTMLDivElement) => {
    this.refs = { ...this.refs, [dataField]: el }
    if (this.state.scrollToNewlyAddedField === dataField) {
      if (el) {
        el.scrollIntoView({ block: 'center' })
        this.setState({ scrollToNewlyAddedField: '' })
        this.props.onFieldClick(dataField)
      }
    }
  }

  generateAddFieldOptionLabel = (dataField: string) => (
    <div
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
      title={this.props.fieldConfig[dataField].label || dataField.toUpperCase()}>
      {this.props.fieldConfig[dataField].label}
    </div>
  )

  generateAddFieldCustomIcon = (dataField: string) => {
    const fieldConfig = this.props.fieldConfig
    return (
      <div
        style={{
          marginRight: 12,
          color: ColorPalette.PRIMARY_BLUE,
        }}>
        <div style={styles.icon}>
          {fieldConfig[dataField].compType
            ? fieldConfig[dataField].compType[0] === 't' && fieldConfig[dataField].dateFormat
              ? 'D'
              : fieldConfig[dataField].compType[0].toUpperCase()
            : '?'}
        </div>
      </div>
    )
  }

  getAddFieldOptionStyle = (dataField: string) => ({
    fontSize: 'smaller',
    maxWidth: '100%',
    backgroundColor:
      this.props.selectedField === dataField ? ColorPalette.BUTTON_ACTIVE_GREY : ColorPalette.OFF_WHITE_LIGHT,
  })

  render() {
    const { fieldConfig, onFieldClick } = this.props

    let fieldConfigObj = { ...fieldConfig }

    fieldConfigObj = Object.keys(fieldConfigObj)
      .sort((a, b) => {
        if (!fieldConfigObj[a].label) return -1
        if (!fieldConfigObj[b].label) return 1
        return fieldConfigObj[a].label.localeCompare(fieldConfigObj[b].label)
      })
      .reduce((acc, key) => {
        // @ts-ignore
        acc[key] = fieldConfigObj[key]
        return acc
      }, {})

    let dataFields = Object.keys(fieldConfigObj)

    if (this.state.searchString.length) {
      dataFields = dataFields.filter((field) => field.toUpperCase().includes(this.state.searchString))
    }

    return (
      <div style={styles.container}>
        <h1
          style={{
            marginTop: 0,
            fontSize: '0.9rem',
            fontWeight: '550',
            fontFamily: 'Roboto',
            color: ColorPalette.PRIMARY_TEXT,
            width: '84%',
            textAlign: 'center' as 'center',
          }}>
          DATA FIELDS
        </h1>
        <TextInputOutlined
          style={styles.textStyle}
          label="FIELD SEARCH"
          placeholder="Search for field"
          value={this.state.searchString}
          textHandler={this.searchHandler}
          disabled={false}
        />
        <div style={styles.dividerLine} id="sidemenuOptions" />
        <SideMenuOptions
          addButtonLabel="Add field"
          addButtonClick={() => this.setState({ addPositionModalOpen: true })}
          addButtonIconStyle={styles.addButton}
          options={dataFields}
          optionLabel={this.generateAddFieldOptionLabel}
          optionCustomIcon={this.generateAddFieldCustomIcon}
          optionClick={onFieldClick}
          optionStyle={this.getAddFieldOptionStyle}
          optionIconStyle={{ marginLeft: -1 }}
          optionButtonRefCallBack={this.optionButtonRefCallBack}
        />
        <LabelCollector
          open={this.state.addPositionModalOpen}
          warning="Enter field name"
          placeholder="Enter field name"
          buttonLabel="Add"
          iconName="setting"
          dismiss={this.closeModal}
          submit={(newFieldName: string) => this.saveNewField(newFieldName)}
        />
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    width: '100%',
    overflow: 'auto',
  },
  dividerLine: {
    width: '86%',
    marginTop: 30,
    // marginBottom: 20,
    borderBottom: `1px solid ${ColorPalette.LIGHT_GREY}`,
  },
  textStyle: {
    color: ColorPalette.PRIMARY_TEXT,
    padding: '0px 12px 10px 12px',
    marginTop: 20,
    minWidth: 120,
    width: '80%',
  },
  icon: {
    border: `2.5px solid ${ColorPalette.PRIMARY_BLUE}`,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 20,
    height: 20,
    fontSize: '1.1sem',
    fontWeight: 'bold',
    marginRight: 3,
    color: ColorPalette.PRIMARY_BLUE,
  },
  addButton: {
    width: '1.5rem',
    height: '1.5rem',
    marginRight: 15,
    marginLeft: -2,
  },
}

export default Radium(DataFieldsSideMenu)
