import { Component } from 'react'
import Radium from 'radium'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import Icon from '@mdi/react'
import { mdiChartBox, mdiChartBar } from '@mdi/js'

import { SideMenuOptions } from './SideMenuOptions'
import CalendarRange from '../BaseComponents/Calendar/CalendarRange'
import { ColorPalette } from '../../config/colors'

dayjs.extend(relativeTime)

interface IBillingSideMenuProps {
  billingAmountTotal: string
  billingCategories: string[]
  selectedCategory: string
  selectedDateRange: Record<string, Date>
  // selectedAssociation: string;
  // userIdPassport: string;
  // organisationConfig: any;
  onBillingCategoryClick: (jobPosition: string) => void
  onDateRangeChange: (dateRange: Record<string, Date>) => void
}

class BillingSideMenu extends Component<IBillingSideMenuProps> {
  state = {
    addPositionModalOpen: false,
    searchString: '',
  }

  render() {
    const {
      billingAmountTotal,
      billingCategories,
      selectedCategory,
      selectedDateRange,
      onBillingCategoryClick,
      onDateRangeChange,
    } = this.props

    return (
      <div style={styles.container}>
        <div style={styles.imageContainer}>
          <Icon path={mdiChartBar} size={3} color={ColorPalette.BUTTON_WHITE} />
        </div>
        <div
          style={{
            fontFamily: 'Roboto',
            textAlign: 'center',
            color: ColorPalette.PRIMARY_TEXT,
          }}>
          <p style={styles.nameStyle}>BILL FOR PERIOD:</p>
          <p style={styles.amountStyle}>{billingAmountTotal ? `R ${billingAmountTotal}` : '-'}</p>
        </div>

        <div style={{ ...styles.calendarRangeContainer }}>
          <CalendarRange
            dateRange={selectedDateRange}
            onDateChange={(date?: number, rangePoint?: string) => {
              if (!date || !rangePoint) {
                return
              }
              onDateRangeChange({ ...selectedDateRange, [rangePoint]: new Date(date) })
            }}
            minDate={new Date(2023, 0, 1)}
            maxDate={new Date()}
            orientation="horizontal" // TODO: make horisontal when screen is wide enough
            inputStyle={{
              border: 'none',
              transform: 'scale(0.8)',
              padding: 0,
            }}
            textStyle={{ marginTop: '0.75rem', marginBottom: 0, paddingBottom: 0, fontSize: '0.85em' }}
          />
        </div>
        <div style={styles.dividerLine} />
        <SideMenuOptions
          options={billingCategories}
          optionLabel={(category: string) => category}
          optionClick={onBillingCategoryClick}
          optionStyle={(category: string) => ({
            backgroundColor: selectedCategory === category ? ColorPalette.BUTTON_ACTIVE_GREY : 'transparent',
          })}
          optionMdiIconPath={mdiChartBox}
        />
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    width: '100%',
    overflow: 'auto',
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: ColorPalette.PRIMARY_BLUE,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.45)',
    marginTop: 25,
    alignSelf: 'center',
    width: window.innerHeight * 0.115,
    height: window.innerHeight * 0.115,
    borderRadius: window.innerHeight * 0.5,
    color: ColorPalette.VERY_LIGHT_GREY,
  },
  nameStyle: {
    marginTop: 20,
    fontSize: '0.9rem',
    fontWeight: '550',
    width: '100%',
  },
  amountStyle: {
    marginTop: -10,
    marginRight: 5,
    fontSize: '0.8rem',
    fontWeight: '500',
    width: '100%',
  },
  calendarRangeContainer: {
    marginTop: 10,
    width: '86%',
    paddingLeft: '1%',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    borderRadius: 12,
    border: `1px solid ${ColorPalette.VERY_LIGHT_GREY}`,
    paddingTop: 5,
    paddingBottom: 5,
  },
  dividerLine: {
    width: '86%',
    marginTop: 25,
    marginBottom: 0,
    borderBottom: `1px solid ${ColorPalette.LIGHT_GREY}`,
  },
  pickerStyle: {
    margin: '0 auto',
    minWidth: 120,
    width: '72%',
    backgroundColor: ColorPalette.CARD_WHITE,
  },
  textStyle: {
    color: ColorPalette.PRIMARY_TEXT,
    paddingBottom: 6,
    borderBottomWeight: 1,
    borderBottomStyle: 'solid' as 'solid',
    borderBottomColor: ColorPalette.PRIMARY_TEXT,
    // marginBottom: 10,
    marginTop: 30,
    minWidth: 120,
    width: '72%',
  },
}

export default Radium(BillingSideMenu)
