import { ColorPalette } from '../../../config'
import { CsvTemplateDownload } from './CsvTemplateDownload'

type CsvFileSelectInstructionsProps = {
  showTemplateDownloadButton?: boolean
  instructions: string | React.ReactNode
  expectedHeaderColumnNames: string[]
}

export const CsvFileSelectInstructions = (props: CsvFileSelectInstructionsProps) => {
  const { showTemplateDownloadButton } = props

  let downloadTemplate = null
  if (showTemplateDownloadButton) {
    downloadTemplate = <CsvTemplateDownload headerColumnNames={props.expectedHeaderColumnNames} />
  }

  return (
    <div style={styles.container}>
      {downloadTemplate}
      <p style={styles.paragraph}>{props.instructions}</p>
    </div>
  )
}

const styles = {
  container: { margin: '0em 1em 2em 1em', textAlign: 'center' as 'center' },
  paragraph: { textAlign: 'center' as 'center', color: ColorPalette.PRIMARY_TEXT, marginTop: '20px' },
}
