import { ColorPalette } from '../../config/colors'
import { formatDateAndTime } from '../../utils'

import React from 'react'
import Radium from 'radium'

interface SyncIndicatorProps {
  mode?: string
  syncedAt?: number
  local?: boolean
  downloadedEverywhere?: boolean
  downloadedToDevice?: boolean
  outstandingUpdates?: boolean
  style?: React.CSSProperties | undefined
  changeTallyTracker?: any
}

const syncIndicatorDots: React.FC<SyncIndicatorProps> = (props: SyncIndicatorProps) => {
  let syncedAt = null
  if (props.syncedAt) {
    const { day, month, hour, minutes } = formatDateAndTime(new Date(props.syncedAt))
    syncedAt = <p style={styles.text}>{`${hour}:${minutes}, ${day} ${month}`}</p>
  }

  let localChangesUploaded: JSX.Element | null = null
  let downloadedToDevice: JSX.Element | null = null
  let downloadedEverywhere: JSX.Element | null = null
  let outstandingUpdates: JSX.Element | null = null
  let outstandingUpdatesSummary: JSX.Element | null = null

  if (props.mode !== 'device') {
    localChangesUploaded = <div style={styles.bulletPoint} />
    if (props.local) {
      localChangesUploaded = <div style={{ ...styles.bulletPoint, backgroundColor: ColorPalette.PRIMARY_BLUE }} />
    }

    downloadedToDevice = <div style={{ ...styles.bulletPoint, backgroundColor: ColorPalette.PRIMARY_BLUE }} />

    downloadedEverywhere = <div style={styles.bulletPoint} />
    if (props.downloadedEverywhere && props.downloadedToDevice) {
      downloadedEverywhere = <div style={{ ...styles.bulletPoint, backgroundColor: ColorPalette.PRIMARY_BLUE }} />
    }
  } else {
    if (props.changeTallyTracker) {
      const { docs, faceShots, rawData } = props.changeTallyTracker
      const total = Object.keys({ ...docs, ...faceShots, ...rawData }).length
      const rawDataUpdates = Object.keys(rawData).length
      const faceShotUpdates = Object.keys(faceShots).length
      const docUpdates = Object.keys(docs).length

      outstandingUpdatesSummary = (
        <>
          <p
            style={{
              ...styles.text,
              borderLeft: `2px solid ${ColorPalette.PRIMARY_BLUE}`,
              paddingLeft: 10,
            }}>{`${total} profile${total !== 1 ? 's' : ''} to upload`}</p>
          <p style={{ ...styles.text, fontStyle: 'italic', marginRight: 10 }}>{`-`}</p>
          <p
            style={{
              ...styles.text,
              fontStyle: 'italic',
              marginRight: 20,
            }}>{`Data: ${rawDataUpdates},  Faceshots: ${faceShotUpdates},  Docs: ${docUpdates}`}</p>
        </>
      )
    }

    outstandingUpdates = <div style={styles.bulletPoint} />
    if (!props.outstandingUpdates) {
      outstandingUpdates = <div style={{ ...styles.bulletPoint, backgroundColor: ColorPalette.PRIMARY_BLUE }} />
    }
  }

  return (
    <button style={{ ...styles.buttonStyle, ...props.style }}>
      {syncedAt}
      {outstandingUpdatesSummary}
      {localChangesUploaded}
      {downloadedToDevice}
      {downloadedEverywhere}
      {outstandingUpdates}
    </button>
  )
}

const styles = {
  bulletPoint: {
    width: 9,
    height: 9,
    borderRadius: 6,
    marginLeft: 1,
    marginRight: 1,
    border: `2px solid ${ColorPalette.PRIMARY_BLUE}`,
  },
  text: {
    margin: 0,
    marginTop: -10,
    marginBottom: -10,
    marginRight: 10,
    fontFamily: 'roboto',
    fontWeight: '400',
    fontSize: '0.7rem',
    color: ColorPalette.PRIMARY_BLUE,
  },
  buttonStyle: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center',
    outline: 0,
    border: 'none',
    backgroundColor: 'transparent',
    ':hover': {
      backgroundColor: ColorPalette.BUTTON_HOVER_GREY,
    },
    ':active': {
      backgroundColor: ColorPalette.BUTTON_ACTIVE_GREY,
    },
  },
}

export default Radium(syncIndicatorDots)
