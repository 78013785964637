import { ColorPalette } from '../../config/colors'
import { toUpperCaseCustom } from '../../utils'
import { IRequestInstanceConfig, ClientRequestStepTypes } from '../../models/clientRequests'

import React, { Component } from 'react'
import Radium from 'radium'
import { ScrollView } from '@cantonjs/react-scroll-view'
import { mdiAccountMultipleCheck, mdiShieldLockOutline, mdiEmailSend, mdiTextBoxPlus, mdiArrowLeftBold } from '@mdi/js'
import Icon from '@mdi/react'
import ButtonGeneric from '../BaseComponents/Buttons/ButtonGeneric'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

interface RequestManagerMenuProps {
  selectedRequestName: string
  createdBy: string
  creationUnix: number
  isRequestClosed: boolean
  selectedStepName: ClientRequestStepTypes | undefined
  requestStepOrder: ClientRequestStepTypes[]
  requestConfig: IRequestInstanceConfig
  currentStepName: ClientRequestStepTypes | undefined
  completedStepNames: ClientRequestStepTypes[]
  onStepClicked: (stepName: ClientRequestStepTypes) => void
}

interface RequestManagerMenuState {
  creationDate: string
}

class RequestManagerSideMenu extends Component<RequestManagerMenuProps, RequestManagerMenuState> {
  state: RequestManagerMenuState = {
    creationDate: '',
  }

  componentDidMount() {
    const creationDate = `${dayjs.unix(this.props.creationUnix / 1000).format('YYYY-MM-DD, HH:mm')}`
    this.setState({ creationDate })
  }

  getRequestIcon = () => {
    switch (this.props.selectedRequestName) {
      case 'Labour Requistion':
        return <Icon path={mdiAccountMultipleCheck} color={ColorPalette.PRIMARY_BLUE} style={styles.requestIcon} />
      default:
        return <Icon path={mdiAccountMultipleCheck} color={ColorPalette.PRIMARY_BLUE} style={styles.requestIcon} />
    }
  }

  getNavButtonIcon = (stepName: ClientRequestStepTypes | string) => {
    const stepNameIconMap: Record<string, any> = {
      formUpload: mdiTextBoxPlus,
      auth: mdiShieldLockOutline,
      email: mdiEmailSend,
    }
    if (stepName in stepNameIconMap) {
      return (
        <Icon
          path={stepNameIconMap[stepName]}
          color={
            this.props.completedStepNames.includes(stepName as ClientRequestStepTypes)
              ? ColorPalette.PRIMARY_BLUE
              : ColorPalette.LIGHT_GREY
          }
          style={styles.navButtonIcon}
        />
      )
    } else if (this.props.isRequestClosed) {
      return <div />
    } else {
      return (
        <Icon
          path={mdiArrowLeftBold}
          color={ColorPalette.PRIMARY_BLUE}
          style={{ ...styles.navButtonIcon, width: 22, marginLeft: 15 }}
        />
      )
    }
  }

  generateNavButtons = (
    requestStepOrder: ClientRequestStepTypes[],
    requestConfig: IRequestInstanceConfig,
    currentStepName: ClientRequestStepTypes | undefined,
  ) => {
    let navButtons: JSX.Element | JSX.Element[] = <p style={styles.noStepsMessage}>{`No configured steps`}</p>
    if (requestStepOrder.length) {
      navButtons = requestStepOrder.map((stepName) => {
        if (stepName === currentStepName) {
          return (
            <ButtonGeneric
              key={stepName}
              style={{
                ...styles.button,
                backgroundColor:
                  this.props.selectedStepName === stepName ? ColorPalette.BUTTON_HOVER_GREY : 'transparent',
              }}
              iconBefore={this.getNavButtonIcon(stepName)}
              iconAfter={this.getNavButtonIcon('currentStepIcon')}
              label={requestConfig.getStepLabel(stepName)}
              onClick={() => this.props.onStepClicked(stepName)}
            />
          )
        } else {
          return (
            <ButtonGeneric
              key={stepName}
              style={{
                ...styles.button,
                backgroundColor:
                  this.props.selectedStepName === stepName ? ColorPalette.BUTTON_HOVER_GREY : 'transparent',
              }}
              iconBefore={this.getNavButtonIcon(stepName)}
              label={requestConfig.getStepLabel(stepName)}
              onClick={() => this.props.onStepClicked(stepName)}
            />
          )
        }
      })
    }

    const component = <div style={styles.versionCategory}>{navButtons}</div>
    return component
  }

  render() {
    const requestIcon = this.getRequestIcon()
    return (
      <div style={styles.container}>
        {requestIcon}

        <p style={styles.nameStyle}>{`${this.props.selectedRequestName}`}</p>
        <p style={styles.userNameStyle}>{` ${toUpperCaseCustom(this.props.createdBy)}  -  ${
          this.state.creationDate
        }`}</p>

        <div style={styles.dividerLine} />

        <ScrollView style={{ height: window.innerHeight * 0.5 }} contentContainerStyle={{ paddingBottom: 40 }}>
          {this.generateNavButtons(this.props.requestStepOrder, this.props.requestConfig, this.props.currentStepName)}
        </ScrollView>
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  nameStyle: {
    marginTop: 5,
    marginBottom: 0,
    fontSize: '0.9rem',
    fontWeight: '550',
    fontFamily: 'Roboto',
    color: ColorPalette.PRIMARY_TEXT,
    width: '84%',
    marginRight: 5,
    textAlign: 'center' as 'center',
  },
  userNameStyle: {
    marginTop: 2,
    marginBottom: 0,
    fontStyle: 'italic',
    fontSize: '0.7rem',
    fontWeight: '350',
    fontFamily: 'Roboto',
    color: ColorPalette.PRIMARY_TEXT,
    width: '84%',
    marginRight: 5,
    textAlign: 'center' as 'center',
  },
  dividerLine: {
    width: '86%',
    marginTop: 20,
    marginBottom: 10,
    borderBottom: `1px solid ${ColorPalette.LIGHT_GREY}`,
  },
  versionCategory: {
    width: window.innerWidth * 0.21,
    marginTop: 10,
    marginBottom: 20,
  },
  categoryHeader: {
    fontSize: '0.8rem',
    fontWeight: '600',
    color: ColorPalette.SECONDARY_TEXT,
    marginLeft: 30,
  },
  noStepsMessage: {
    color: ColorPalette.TERTIARY_TEXT,
    fontSize: '0.8rem',
    fontWeight: '600',
    width: '100%',
    textAlign: 'center' as 'center',
    // marginLeft: 50,
    marginBottom: 30,
  },
  button: {
    fontWeight: '550',
    fontSize: '0.8rem',
    backgroundColor: ColorPalette.CARD_WHITE,
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    paddingLeft: 30,
    marginTop: 0,
    ':hover': {
      backgroundColor: 'rgba(242,242,250,1)',
    },
    ':active': {
      backgroundColor: 'rgba(222,222,230,1)',
    },
  },
  iconStyle: {
    marginTop: 30,
    width: '5.5rem',
    height: '5.5rem',
    color: ColorPalette.PRIMARY_BLUE,
  },
  buttonIcon: {
    width: '1.3rem',
    height: '1.3rem',
    color: ColorPalette.PRIMARY_BLUE,
    marginRight: 15,
  },
  navButtonIcon: {
    width: 18,
    height: 'auto',
    marginRight: 15,
  },
  requestIcon: {
    marginTop: 15,
    width: 90,
    height: 90,
    marginRight: 25,
  } as React.CSSProperties,
}

export default Radium(RequestManagerSideMenu)
