import Checkbox from '../../components/BaseComponents/Checkboxes/Checkbox'
import { ColorPalette } from '../../config'
import { GroupStatus } from '../../types'

type GroupStatusFilterProps = {
  groupStatusOptions: GroupStatus[]
  selectedFilterOptions: GroupStatus[]
  onFilterOptionClick: (e: React.ChangeEvent) => void // Update the type here
}

export const GroupStatusFilter = (props: GroupStatusFilterProps) => {
  const { groupStatusOptions, selectedFilterOptions, onFilterOptionClick } = props

  const renderStatusFilterOptions = () => (
    <div style={styles.statusFilterOptions}>
      <div style={{ fontWeight: 500, marginLeft: '1.5em' }}>Status filter:</div>
      {groupStatusOptions.map((status: GroupStatus, index: number) => {
        const checked = selectedFilterOptions.includes(status)
        return (
          <Checkbox
            id={status}
            label={status}
            checked={checked}
            onClick={onFilterOptionClick}
            checkboxStyle={{
              ...styles.statusFilterCheckbox,
              color: checked ? ColorPalette.PRIMARY_BLUE : ColorPalette.TERTIARY_TEXT,
            }}
            labelStyle={styles.statusFilterCheckboxLabel}
            key={`checkbox_${status}_${index}`}
          />
        )
      })}
    </div>
  )

  const statusFilterOptions = renderStatusFilterOptions()
  return <div style={styles.statusFilterOptions}>{statusFilterOptions}</div>
}

const styles = {
  statusFilterOptions: {
    display: 'flex',
    alignItems: 'center',
    gap: '2.5em',
    backgroundColor: ColorPalette.DARK_GREY,
    minHeight: 45,
    color: ColorPalette.OFF_WHITE,
    position: 'sticky' as 'sticky',
    top: 0,
    zIndex: 1,
  },
  statusFilterCheckbox: {
    padding: 0,
    borderRadius: 0,
    marginRight: 5,
    border: `1px solid ${ColorPalette.SECONDARY_TEXT}`,
    backgroundColor: ColorPalette.CARD_WHITE,
    width: 16,
    height: 16,
  },
  statusFilterCheckboxLabel: {
    width: 'fit-content',
    color: ColorPalette.OFF_WHITE,
  },
}
