import { Profile } from '../models'
import { LocalStorageProvider } from '../provider'
import { profileIdentifier } from '../config/localStorage'

export const persistSingleProfileUpdate = (selectedAssociation: string, profile: Profile) => {
  const associationProfileIdentifier = `${selectedAssociation}#${profileIdentifier}${profile.getPk()}`
  LocalStorageProvider.setData(associationProfileIdentifier, profile)
}

export const persistCurrentProfileData = async (
  employeeProfiles: Record<string, Record<string, any>>,
  selectedAssociation: string,
) => {
  if ('storage' in navigator && 'estimate' in navigator.storage) {
    const { quota: storageQuota, usage: storageUsage }: { quota?: number; usage?: number } =
      await navigator.storage.estimate()
    const storagePercentageAvailable =
      storageQuota && storageUsage ? ((storageQuota - storageUsage) / storageQuota) * 100 : 49
    if (storagePercentageAvailable < 50) {
      // TODO: Show a warning modal to the user that the browser storage is almost full.
      // AND/OR first attempt to delete profiles linking to another client
      return
    }
  }
  if (employeeProfiles) {
    for (const profileData of Object.values(employeeProfiles)) {
      const profile = new Profile(profileData)
      const profilePk = profile.getPk()
      const associationProfileIdentifier = `${selectedAssociation}#${profileIdentifier}${profilePk}`
      await LocalStorageProvider.setData(associationProfileIdentifier, profile)
    }
  }
}

export const getProfilePkFromStorageKey = (storageKey: string) => {
  if (storageKey.includes(profileIdentifier)) {
    const parts = storageKey.split(profileIdentifier)
    const pk = parts[1]
    return pk
  }
  return ''
}

export const getAssociationNameFromProfileStorageKey = (storageKey: string) => {
  if (storageKey.includes(profileIdentifier)) {
    const parts = storageKey.split(profileIdentifier)
    const association = parts[0].replace('#', '')
    return association
  }
  return ''
}
