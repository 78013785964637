import { PieChart } from 'react-minimal-pie-chart'
import Radium from 'radium'
import React, { PureComponent } from 'react'
import { cloneDeep, isEqual } from 'lodash'
import { ColorPalette } from '../../config/colors'
import { changeRgbaColourTransparency } from '../../utils/colour'

interface IPieChartProps {
  config: { title: string; value: number; color: string }[]
  numberOfDocs: number
  numberOfDocsTotal: number
  showDocsPercentage?: boolean
  style?: React.CSSProperties | undefined
  onClick?: (segmentId: string) => void
}

interface IPieChartState {
  titleLabel: string
  valueLabel: string
  config: { title: string; value: number; color: string }[]
  hoverOverSegmentIndex: number
}

export class DocRingChartComponent extends PureComponent<IPieChartProps, IPieChartState> {
  state: IPieChartState = {
    titleLabel: 'All Docs',
    valueLabel: '',
    config: [],
    hoverOverSegmentIndex: -1,
  }

  componentDidUpdate(prevProps: IPieChartProps, prevState: IPieChartState) {
    if (!isEqual(this.props.config, this.state.config) || !isEqual(prevProps.config, this.props.config)) {
      const numberOfDocsInPreviousSegment = prevProps.config.reduce((acc, obj) => acc + obj.value, 0)
      const numberOfDocsInSegment = this.props.config.reduce((acc, obj) => acc + obj.value, 0)
      const shouldUpdateTitleAndLabel =
        numberOfDocsInPreviousSegment < this.props.numberOfDocs && this.props.numberOfDocs === numberOfDocsInSegment
      this.reloadChart(shouldUpdateTitleAndLabel)
      return
    }
  }

  reloadChart = (resetTitleAndLabel: boolean = false) => {
    const { config, numberOfDocs, numberOfDocsTotal } = this.props
    const { titleLabel, valueLabel, hoverOverSegmentIndex } = this.state

    const simulateClickOnSegmentToRefresh = () => {
      if (numberOfDocs > 0 && numberOfDocs < numberOfDocsTotal) {
        const currentSelectedSegment = this.state.config.findIndex((segment) => segment.value === numberOfDocs)
        if (currentSelectedSegment !== -1) {
          this.onClick(null, currentSelectedSegment)
        }
      }
    }

    let newStateChange: IPieChartState = {
      config: cloneDeep(config),
      titleLabel,
      valueLabel,
      hoverOverSegmentIndex,
    }
    if (resetTitleAndLabel) {
      newStateChange.titleLabel = 'All Docs'
      newStateChange.valueLabel = numberOfDocs ? `${numberOfDocs} / ${numberOfDocsTotal}` : numberOfDocsTotal.toString()
    }
    this.setState(newStateChange, simulateClickOnSegmentToRefresh)
  }

  onMouseOut = (event: any, segmentIndex: number) => {
    this.setState({ hoverOverSegmentIndex: -1 })
  }

  onMouseOver = (event: any, segmentIndex: number) => {
    this.setState({ hoverOverSegmentIndex: segmentIndex })
  }

  onClick = (event: any, segmentIndex: number) => {
    try {
      let { config, titleLabel, valueLabel } = this.state
      const { title, value } = config[segmentIndex]
      if (titleLabel !== title && valueLabel !== `${value}`) {
        this.setState({
          titleLabel: title,
          valueLabel:
            value && value < this.props.numberOfDocsTotal
              ? `${value} / ${this.props.numberOfDocsTotal}`
              : this.props.numberOfDocsTotal.toString(),
        })
        if (this.props.onClick === undefined) {
          return
        }
        this.props.onClick(title)
      }
    } catch (error) {
      console.error('error: ', error)
    }
  }

  checkAllZeros = (config: { title: string; value: number; color: string }[] = []) => {
    let encounteredNonZero = false
    config.forEach((configItem) => (configItem.value ? (encounteredNonZero = true) : null))
    return !encounteredNonZero
  }

  showNumberOfDocsSplit = () =>
    this.props.showDocsPercentage &&
    this.props.numberOfDocs &&
    !isNaN(this.props.numberOfDocs) &&
    this.props.numberOfDocsTotal &&
    !isNaN(this.props.numberOfDocsTotal) &&
    this.props.numberOfDocs / this.props.numberOfDocsTotal > 0 &&
    this.props.numberOfDocs / this.props.numberOfDocsTotal < 1

  render() {
    const { config, titleLabel, valueLabel, hoverOverSegmentIndex } = this.state
    const isAllZero = this.checkAllZeros(config)

    const width = window.innerWidth * 0.15
    let chart = (
      <div style={styles.chartContainer}>
        <div
          style={{
            ...styles.noDataContainer,
            width: width * 1.08,
            marginTop: window.innerHeight * 0.012,
          }}
        />
        <div
          style={{
            ...styles.centerCircleStyle,
            top: -width * 0.91,
            left: width * 0.165,
            width: width * 0.75,
            height: width * 0.75,
          }}>
          <p style={styles.label2Text}>No data</p>
        </div>
      </div>
    )

    if (config.length && !isAllZero) {
      let percentage = null
      if (this.showNumberOfDocsSplit()) {
        percentage = (
          <p style={styles.label2Text}>{Math.round((this.props.numberOfDocs / this.props.numberOfDocsTotal) * 100)}%</p>
        )
      }
      const chartConfig = config.map((entry, i) => {
        if (hoverOverSegmentIndex === i) {
          return {
            ...entry,
            color: changeRgbaColourTransparency(entry.color, 0.5),
          }
        }
        return entry
      })

      chart = (
        <div style={styles.chartContainer}>
          <PieChart
            radius={45}
            lineWidth={35}
            segmentsStyle={{ transition: 'stroke .3s', cursor: 'pointer' }}
            segmentsShift={1}
            onMouseOut={this.onMouseOut}
            onMouseOver={this.onMouseOver}
            onClick={this.onClick}
            data={chartConfig}></PieChart>
          <div
            style={{
              ...styles.centerCircleStyle,
              top: '-59%',
            }}>
            <p style={styles.label1Text}>{titleLabel}</p>
            <p style={styles.label2Text}>{valueLabel}</p>
            {percentage}
          </div>
        </div>
      )
    }
    return chart
  }
}

const styles = {
  chartContainer: {
    alignSelf: 'center',
    width: '85%',
    marginTop: -40,
  },
  centerCircleStyle: {
    position: 'relative' as 'relative',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    zIndex: 150,
    backgroundColor: 'transparent',
    height: 50,
  },
  label1Text: {
    margin: 0,
    marginTop: '1%',
    position: 'relative' as 'relative',
    textAlign: 'center' as 'center',
    fontFamily: 'roboto',
    fontWeight: '600',
    fontSize: '0.7rem',
    color: ColorPalette.LIGHT_GREY,
  },
  label2Text: {
    margin: 0,
    marginTop: 5,
    fontFamily: 'roboto',
    fontWeight: '400',
    fontSize: '0.8rem',
    color: ColorPalette.LIGHT_GREY,
  },
  noDataContainer: {
    borderRadius: '50%',
    backgroundColor: ColorPalette.VERY_LIGHT_GREY,
  },
}

export default Radium(DocRingChartComponent)
