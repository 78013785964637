import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Radium from 'radium'
import PDFViewer from 'pdf-viewer-reactjs'
import { Document } from 'react-pdf'
import {
  mdiPlus,
  mdiDraw,
  mdiTextShort,
  mdiContentCopy,
  mdiTextBoxCheck,
  mdiTextBoxRemove,
  mdiFileUpload,
  mdiContentDuplicate,
} from '@mdi/js'
import Icon from '@mdi/react'

import SimplePagination from '../GeneralUI/Pagination/SimplePagination'
import Field from '../FormBuilder/Field'
import Signature from '../FormBuilder/Signature'
import ButtonGrey from '../BaseComponents/Buttons/ButtonGrey'
import ButtonBlue from '../BaseComponents/Buttons/ButtonBlue'
import DocFieldSettingsModal from './DocFieldSettingsModal'
import DocSignatureSettingsModal from './DocSignatureSettingsModal'
import LabelCollector from './LabelCollector'
import AlertModalOneButton from './AlertModalOneButton'
import { FormFieldConfig } from '../../models/forms/field.config'
import { FormSignatureConfig } from '../../models/forms/signature.config'
import { ColorPalette } from '../../config/colors'
import { toPfKeySchema, removeUnderscores, toUpperCaseCustom } from '../../utils'
import { cloneDeep, isEmpty } from 'lodash'
import { PeopleFlowCombinedReducer } from '../../store/reducers'
import { AssociationSettingsRepository } from '../../repositories'
import { ErrorCodeTypes } from '../../enums/errorCodeTypes'
import { SignatureConfig } from '../../types'

interface DocumentEditorModalProps {
  disabled?: boolean
  formFieldConfig: Record<string, any>
  allFieldConfig: Record<string, any>
  docName: string
  editModeEnabled?: boolean
  docStatus: string
  allClientUsers: { name: string; surname: string; idPassport: string }[]
  document: any

  updatePdfTemplate: () => void
  clonePdfTemplate: () => void
  save: (formFieldConfig: Record<string, any>, allFieldConfig: Record<string, string>, status: string) => void
  dismiss: () => void
}

const initialModalState = {
  formFieldSettingsModalOpen: false,
  formSignatureSettingsModalOpen: false,
  labelCollectorModalOpen: false,
  warningModalOpen: false,
  warningModalHeader: '',
  warningModalMessage: '',
}

const DocumentEditorModal = (props: DocumentEditorModalProps) => {
  const associationRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.associationRepo,
  ) as AssociationSettingsRepository
  const selectedAssociation = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedAssociation,
  )
  const signatureConfig: SignatureConfig = associationRepo.getSignatureConfig(selectedAssociation)
  const initialDataCaptureSettings = {
    compulsory: false,
    authedSignatories: [],
    pinProtected: false,
    signatoryType: signatureConfig.signatoryTypes[0],
    allSignatoryTypes: signatureConfig.signatoryTypes,
  }
  const [modalState, setModalState] = useState({ ...initialModalState })
  const [isFirstPage, setIsFirstPage] = useState(true)
  const [isLastPage, setIsLastPage] = useState(false)
  const [missingFieldWarningId, setMissingFieldWarningId] = useState('')
  const [numberOfPages, setNumberOfPages] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  const [formFieldConfig, setFormFieldConfig] = useState<Record<string, any>>({})
  const [allFieldConfig, setAllFieldConfig] = useState<Record<string, any>>({})
  const [selectedDataCaptureSettings, setSelectedDataCaptureSettings] = useState(initialDataCaptureSettings)
  const [selectedFieldId, setSelectedFieldId] = useState('')
  const [allFieldOptions, setAllFieldOptions] = useState(['Choose a field', '+ Add new field'])
  const [newCustomFieldAdded, setNewCustomFieldAdded] = useState<{ fieldLabel: string; fieldId: string } | {}>({})

  useEffect(() => {
    const { formFieldConfig, allFieldConfig } = props
    const allFieldOptions = generateFieldOptions(allFieldConfig)
    setFormFieldConfig(formFieldConfig)
    setAllFieldConfig(allFieldConfig)
    setAllFieldOptions(allFieldOptions)
  }, [])

  useEffect(() => {
    if (
      !isEmpty(newCustomFieldAdded) &&
      newCustomFieldAdded.hasOwnProperty('fieldLabel') &&
      newCustomFieldAdded.hasOwnProperty('fieldId')
    ) {
      // @ts-ignore
      fieldSelectionHandler(newCustomFieldAdded.fieldLabel, newCustomFieldAdded.fieldId)
      setNewCustomFieldAdded({})
    }
  }, [!isEmpty(newCustomFieldAdded)])

  const closeModals = () => {
    setModalState({ ...initialModalState })
  }

  const updatePdfHandler = () => {
    props.updatePdfTemplate()
  }

  const handleFormResizing = () => {
    const { docStatus, save } = props
    if (docStatus === 'published') {
      save(formFieldConfig, allFieldConfig, 'toggleDocStatus')
    } else if (docStatus === 'drafts') {
      updatePdfHandler()
    }
  }

  const generateFieldOptions = (allFieldConfig: Record<string, any> = {}) => {
    const standardOptions = ['Choose a field', '+ Add new field']
    let allFieldOptions: string[] = []
    Object.keys(allFieldConfig).forEach((key) => {
      if (allFieldConfig[key].label) {
        allFieldOptions.push(toUpperCaseCustom(allFieldConfig[key].label))
      }
    })
    allFieldOptions = allFieldOptions.sort()
    return [...standardOptions, ...allFieldOptions]
  }

  const fieldSelectionHandler = (label: string, fieldId: string) => {
    if (label === '+ Add new field') {
      setModalState({ ...initialModalState, labelCollectorModalOpen: true })
      setSelectedFieldId(fieldId)
      setMissingFieldWarningId('')
      return
    }
    let config = cloneDeep(formFieldConfig)
    for (const key of Object.keys(allFieldConfig)) {
      if (toUpperCaseCustom(allFieldConfig[key].label) === label) {
        config[fieldId].key = key
        break
      }
    }
    setFormFieldConfig(config)
    setMissingFieldWarningId('')
  }

  const loadNext = () => {
    const nextPage = currentPage + 1
    if (nextPage < numberOfPages) {
      setCurrentPage(nextPage)
      setIsFirstPage(false)
      setIsLastPage(false)
    } else if (nextPage === numberOfPages) {
      setCurrentPage(nextPage)
      setIsFirstPage(false)
      setIsLastPage(true)
    }
  }

  const loadPrevious = () => {
    const previousPage = currentPage - 1
    if (previousPage > 1) {
      setCurrentPage(previousPage)
      setIsFirstPage(false)
      setIsLastPage(false)
    } else if (previousPage === 1) {
      setCurrentPage(previousPage)
      setIsFirstPage(true)
      setIsLastPage(false)
    }
  }

  const getMaxPageCount = (numberOfPages: number) => {
    let isLastPage = false
    if (numberOfPages === 1) {
      isLastPage = true
    }
    setNumberOfPages(numberOfPages)
    setIsLastPage(isLastPage)
  }

  const onControlButtonClicked = (
    type: 'field' | 'signature',
    fieldId: string,
    functionType: string,
    newValue: any,
  ) => {
    if (functionType === 'settings') {
      if (type === 'field' && !allFieldConfig[formFieldConfig[fieldId].key]) {
        setMissingFieldWarningId(fieldId)
        return
      }
      const modalVariableName = type === 'field' ? 'formFieldSettingsModalOpen' : 'formSignatureSettingsModalOpen'
      const dataCaptureSettings =
        type === 'field'
          ? { ...allFieldConfig[formFieldConfig[fieldId].key], key: formFieldConfig[fieldId].key }
          : formFieldConfig[fieldId].dataCaptureSettings
      setSelectedDataCaptureSettings(dataCaptureSettings)
      setSelectedFieldId(fieldId)
      setModalState((modalState) => ({ ...modalState, [modalVariableName]: true }))
    } else if (functionType === 'delete') {
      let config = cloneDeep(formFieldConfig)
      delete config[fieldId]
      setFormFieldConfig(config)
    } else {
      let styleVariableName = type === 'field' ? 'textStyle' : 'signatureStyle'
      let config = cloneDeep(formFieldConfig)
      if (
        (newValue === 'bold' && formFieldConfig[fieldId][styleVariableName][functionType] === 'bold') ||
        (newValue === 'italic' && formFieldConfig[fieldId][styleVariableName][functionType] === 'italic')
      ) {
        newValue = 'normal'
      }
      config[fieldId][styleVariableName][functionType] = newValue
      setFormFieldConfig(config)
    }
  }

  const saveDataCaptureSettings = (settings: any, fieldType: string) => {
    if (fieldType === 'signature') {
      let config = cloneDeep(formFieldConfig)
      config[selectedFieldId].dataCaptureSettings = settings
      setFormFieldConfig(config)
    } else {
      let config = cloneDeep(allFieldConfig)
      config[formFieldConfig[selectedFieldId].key] = {
        ...allFieldConfig[formFieldConfig[selectedFieldId].key],
        ...settings,
      }
      setAllFieldConfig(config)
    }
    setModalState({ ...initialModalState })
  }

  const addNewComponent = (type: string) => {
    let maxIdNumber = 0
    const allFieldIds = Object.keys(formFieldConfig)
    allFieldIds.forEach((fieldId) => (parseInt(fieldId) > maxIdNumber ? (maxIdNumber = parseInt(fieldId)) : null))
    let config = cloneDeep(formFieldConfig)
    config[`${maxIdNumber + 1}`] =
      type === 'field' ? new FormFieldConfig(currentPage) : new FormSignatureConfig(currentPage, signatureConfig)
    setFormFieldConfig(config)
  }

  const addCustomField = (label: string) => {
    const key = toPfKeySchema(label)
    label = toUpperCaseCustom(removeUnderscores(key))

    if (allFieldConfig[key]) {
      throw { code: ErrorCodeTypes.ALREADY_EXISTS }
    } else {
      let config = cloneDeep(allFieldConfig)
      config[key] = { label, key, compType: 'text' }
      const allFieldOptions = generateFieldOptions(config)
      setModalState({ ...initialModalState })
      setAllFieldConfig(config)
      setAllFieldOptions(allFieldOptions)
      setNewCustomFieldAdded({ fieldLabel: label, fieldId: selectedFieldId })
    }
  }

  const onDrag = (newCoordinates: { x: number; y: number }, fieldId: string) => {
    const newX = formFieldConfig[fieldId].coordinates.x + newCoordinates.x
    const newY = formFieldConfig[fieldId].coordinates.y + newCoordinates.y

    let config = cloneDeep(formFieldConfig)
    config[fieldId].coordinates.x = newX
    config[fieldId].coordinates.y = newY
    setFormFieldConfig(config)
  }

  const initialiseFieldSettings = () => {
    let draggableFields: (JSX.Element | null)[] = []
    Object.keys(formFieldConfig).forEach((fieldId) => {
      const { key, coordinates, page, textStyle, signatureStyle } = formFieldConfig[fieldId]
      if (page === currentPage) {
        let component = null
        if (textStyle) {
          const isValidConfig = (key && allFieldConfig[key]) || !key
          const label = allFieldConfig[key] ? allFieldConfig[key].label : ''
          const compType = allFieldConfig[key] ? allFieldConfig[key].compType : 'text'

          component = (
            <Field
              key={`field_${fieldId}`}
              allFieldOptions={allFieldOptions}
              showMissingFieldWarning={missingFieldWarningId === fieldId}
              label={label}
              textStyle={textStyle}
              coordinates={coordinates}
              compType={compType}
              disabled={!props.editModeEnabled}
              onDrag={(newCoordinates) => onDrag(newCoordinates, fieldId)}
              fieldSelectionHandler={(text) => fieldSelectionHandler(text, fieldId)}
              onStyleButtonClicked={(functionType, newValue) =>
                onControlButtonClicked('field', fieldId, functionType, newValue)
              }
            />
          )
          if (isValidConfig) {
            draggableFields.push(component)
          }
        } else if (signatureStyle) {
          component = (
            <Signature
              key={`signature_${fieldId}`}
              signatureStyle={signatureStyle}
              coordinates={coordinates}
              disabled={!props.editModeEnabled}
              onDrag={(newCoordinates) => onDrag(newCoordinates, fieldId)}
              onStyleButtonClicked={(functionType, newValue) =>
                onControlButtonClicked('signature', fieldId, functionType, newValue)
              }
            />
          )
          draggableFields.push(component)
        }
      }
    })
    return draggableFields
  }

  const handleLoadSuccess = async (pdfObject: any) => {
    try {
      const page = await pdfObject.getPage(1)
      const approvedWidth = 595
      const approvedHeight = 842
      const width = page._pageInfo.view[2]
      const height = page._pageInfo.view[3]

      if (
        Math.abs(approvedWidth - width) / approvedWidth > 0.02 ||
        Math.abs(approvedHeight - height) / approvedHeight > 0.02
      ) {
        setModalState((modalState) => ({
          ...modalState,
          warningModalOpen: true,
          warningModalHeader: 'Wrong dimensions',
          warningModalMessage:
            'Your form is not in A4 format. It must be 210mm x 297mm (portrait) to prevent problems during document signing.',
        }))
      }
    } catch (error) {
      console.error('error: ', error)
    }
  }

  const draggableFields = initialiseFieldSettings()
  const isDocDraftOrDepublished = props.docStatus === 'drafts' || props.docStatus === 'depublished'

  let statusChangeButton = (
    <ButtonBlue
      style={{
        ...styles.documentEditorButton,
        marginLeft: 20,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        width: '8%',
      }}
      title={isDocDraftOrDepublished ? 'Publish' : 'Depublish'}
      onClick={() => props.save(formFieldConfig, allFieldConfig, 'toggleDocStatus')}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
        <Icon
          size={0.78}
          path={props.docStatus === 'drafts' || props.docStatus === 'depublished' ? mdiTextBoxCheck : mdiTextBoxRemove}
          color={ColorPalette.CARD_WHITE}
        />
      </div>
    </ButtonBlue>
  )

  let copyToDraftsButton: JSX.Element | null = (
    <ButtonBlue
      style={{
        ...styles.documentEditorButton,
        marginLeft: 1,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        width: '8%',
      }}
      title="Copy to drafts"
      onClick={() => props.save(formFieldConfig, allFieldConfig, 'copyToDrafts')}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
        <Icon size={0.65} path={mdiContentCopy} />
      </div>
    </ButtonBlue>
  )

  let addFieldButton = null
  let uploadNewPdfButton = null
  if (props.editModeEnabled) {
    copyToDraftsButton = null
    uploadNewPdfButton = (
      <ButtonBlue
        style={{
          ...styles.documentEditorButton,
          marginLeft: 1,
          borderRadius: 0,
          width: '8%',
        }}
        title="Upload new"
        onClick={updatePdfHandler}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Icon size={0.7} path={mdiFileUpload} />
        </div>
      </ButtonBlue>
    )
    addFieldButton = (
      <ButtonBlue
        style={{ ...styles.documentEditorButton, marginLeft: 1, borderRadius: 0, width: '8%' }}
        title="Add field"
        onClick={() => addNewComponent('field')}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Icon size={0.55} path={mdiPlus} color={ColorPalette.CARD_WHITE} />
          <Icon size={0.75} path={mdiTextShort} color={ColorPalette.CARD_WHITE} />
        </div>
      </ButtonBlue>
    )
  }

  let addSignatureButton = null
  if (props.editModeEnabled) {
    addSignatureButton = (
      <ButtonBlue
        style={{
          ...styles.documentEditorButton,
          marginLeft: 1,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          width: '8%',
        }}
        title="Add signature"
        onClick={() => addNewComponent('signature')}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Icon size={0.55} path={mdiPlus} color={ColorPalette.CARD_WHITE} />
          <Icon size={0.75} path={mdiDraw} color={ColorPalette.CARD_WHITE} />
        </div>
      </ButtonBlue>
    )
  }

  let cloneTemplateButton = (
    <ButtonBlue
      style={{
        ...styles.documentEditorButton,
        marginLeft: 1,
        borderRadius: 0,
        width: '8%',
      }}
      onClick={props.clonePdfTemplate}
      title="Clone template">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Icon size={0.65} path={mdiContentDuplicate} color={ColorPalette.CARD_WHITE} />
      </div>
    </ButtonBlue>
  )

  let formFieldSettingsModal = null
  if (modalState.formFieldSettingsModalOpen) {
    formFieldSettingsModal = (
      <DocFieldSettingsModal
        open={true}
        dismiss={closeModals}
        settingsLocked={props.disabled || false}
        settings={selectedDataCaptureSettings}
        saveAndClose={(settings) => saveDataCaptureSettings(settings, 'field')}
      />
    )
  }

  let formSignatureSettingsModal = null
  if (modalState.formSignatureSettingsModalOpen) {
    formSignatureSettingsModal = (
      <DocSignatureSettingsModal
        settingsLocked={props.disabled}
        settings={selectedDataCaptureSettings}
        possibleSignatories={props.allClientUsers || []}
        saveAndClose={(settings) => saveDataCaptureSettings(settings, 'signature')}
        allSignatoryTypesDefault={initialDataCaptureSettings.allSignatoryTypes}
      />
    )
  }

  let labelCollectorModal = null
  if (modalState.labelCollectorModalOpen) {
    labelCollectorModal = (
      <LabelCollector
        cardContainer={{ left: 235 }}
        open={modalState.labelCollectorModalOpen}
        warning={'Enter field name'}
        placeholder={'Enter new field name'}
        buttonLabel={'Create'}
        iconName={'rawData'}
        dismiss={() => closeModals()}
        submit={(label) => addCustomField(label)}
      />
    )
  }

  let modalContents = null
  if (modalState.warningModalOpen) {
    modalContents = (
      <AlertModalOneButton
        open={modalState.warningModalOpen}
        header={modalState.warningModalHeader}
        body={modalState.warningModalMessage}
        buttonLabel={'Ok'}
        onClick={modalState.warningModalHeader.includes('dimension') ? handleFormResizing : closeModals}
      />
    )
  } else {
    modalContents = (
      <>
        <button style={styles.screenContainer} onClick={() => props.dismiss()} />
        <div style={styles.cardContainer}>
          <div style={{ ...styles.buttonContainer }}>
            <ButtonGrey
              onClick={
                props.editModeEnabled
                  ? () => props.save(formFieldConfig, allFieldConfig, 'saveAndClose')
                  : props.dismiss
              }
              style={{ ...styles.documentEditorButton }}>
              {props.editModeEnabled ? 'Save and Close' : 'Close'}
            </ButtonGrey>

            {statusChangeButton}
            {cloneTemplateButton}
            {copyToDraftsButton}
            {uploadNewPdfButton}
            {addFieldButton}
            {addSignatureButton}

            <SimplePagination
              style={{ width: '40%', paddingRight: 0 }}
              buttonStyle={{ backgroundColor: ColorPalette.BUTTON_HOVER_GREY }}
              previousLabel={'PREVIOUS'}
              nextLabel={'NEXT'}
              isPrevious={!isFirstPage}
              isNext={!isLastPage}
              loadPrevious={() => loadPrevious()}
              loadNext={() => loadNext()}
            />
          </div>

          {formFieldSettingsModal}
          {formSignatureSettingsModal}
          {labelCollectorModal}

          <div
            style={{
              position: 'absolute',
              left: 160,
              top: 90,
              height: window.innerHeight * 0.88,
              width: window.innerWidth * 0.34,
              overflow: 'hidden',
              display: 'flex',
              boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)',
            }}>
            {draggableFields}

            <Document
              file={props.document}
              onLoadSuccess={props.docStatus !== 'depublished' ? (variables) => handleLoadSuccess(variables) : () => {}}
            />

            <PDFViewer
              scale={0.82}
              externalInput={true}
              page={currentPage}
              hideNavbar={false}
              document={props.document}
              getMaxPageCount={(numberofpages) => getMaxPageCount(numberofpages)}
            />
          </div>
        </div>
      </>
    )
  }

  return modalContents
}

let styles: Record<string, React.CSSProperties> = {
  screenContainer: {
    zIndex: 1000,
    position: 'absolute',
    top: 0,
    left: 0,
    width: window.innerWidth,
    height: window.innerHeight,
    backgroundColor: 'rgba(120,120,130,0.7)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 0,
    border: 'none',
    overflow: 'hidden',
  },
  cardContainer: {
    zIndex: 1001,
    position: 'absolute',
    top: 5,
    left: window.innerWidth * 0.5 - 403,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
    backgroundColor: ColorPalette.BUTTON_HOVER_GREY,
    width: 806,
    height: '99%',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    overflow: 'hidden',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    paddingLeft: 80,
    paddingRight: 50,
  },
  documentEditorButton: {
    fontSize: '0.75rem',
    width: '22%',
    boxShadow: '0px 2px 4px rgba(0, 0, 10, 0.17)',
    height: 30,
  },
}

export default Radium(DocumentEditorModal)
