import TextInputOutlined from '../../BaseComponents/Text/TextInputOutlined'
import PickerContained from '../../BaseComponents/Pickers/PickerContained'
import { ColorPalette } from '../../../config/colors'

interface ISecondaryTerminationOptionsProps {
  selectedPrimaryTerminationReason: string
  primaryTerminationReasons: string[]
  secondaryTerminationReasons: string[]
  onPrimaryReasonChange: (reason: string) => void
  openSelectorModal: (selectorIdentifier: string, selectorItems: string[]) => void
}

export const SecondaryTerminationOptions = (props: ISecondaryTerminationOptionsProps) => {
  const {
    primaryTerminationReasons,
    secondaryTerminationReasons,
    selectedPrimaryTerminationReason,
    onPrimaryReasonChange,
    openSelectorModal,
  } = props

  return (
    <div style={styles.section}>
      <h1 style={styles.sectionHeader}>SECONDARY TERMINATION OPTIONS</h1>
      <div style={{ ...styles.sectionInput }}>
        <PickerContained
          style={{ padding: '10px 37px 15px', width: '100%' }}
          label="SELECTED PRIMARY OPTION"
          items={primaryTerminationReasons}
          value={selectedPrimaryTerminationReason}
          onChange={(e: any) => onPrimaryReasonChange(e.target.value)}
        />
      </div>
      <TextInputOutlined
        style={styles.sectionInput}
        label="SUB-REASONS FOR SELECTED PRIMARY OPTION"
        placeholder="Click to add options"
        value={
          secondaryTerminationReasons && secondaryTerminationReasons.length > 0
            ? secondaryTerminationReasons.join('; ')
            : ''
        }
        onClick={() => openSelectorModal('secondaryTerminationReasons', secondaryTerminationReasons)}
        textHandler={() => ''}
        disabled={false}
        type="textarea"
      />
    </div>
  )
}

const styles = {
  section: {
    width: '100%',
    margin: '4% 0',
    paddingBottom: '20px',
  },
  sectionHeader: {
    marginTop: 0,
    fontSize: '1.15rem',
    fontWeight: 'normal',
    fontFamily: 'Roboto',
    color: ColorPalette.PRIMARY_TEXT,
    textAlign: 'left' as 'left',
    borderBottomWidth: 2,
    borderBottomStyle: 'solid' as 'solid',
    borderBottomColor: ColorPalette.PRIMARY_BLUE,
    padding: '0 .5em',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sectionInput: {
    margin: '40px 1.5em 0px 1.5em',
  },
}
