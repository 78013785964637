import { ColorPalette } from '../../../config/colors'
import { toLowerCaseCustom, addUnderScores, removeUnderscores } from '../../../utils'

import React from 'react'
import Radium from 'radium'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import Icon from '@mdi/react'
import {
  mdiAccountMultiple,
  mdiAccountVoice,
  mdiFileDocumentEdit,
  mdiCloseCircleOutline,
  mdiAccountAlert,
} from '@mdi/js'

dayjs.extend(relativeTime)

interface IDisciplineActionTileProp {
  customAction: string
  actionLabel: string
  onClick: () => void
  action: string
  active: boolean
  authedRoles: string[]
  expiry: string
}

const disciplineActionTile: React.FC<IDisciplineActionTileProp> = (props: IDisciplineActionTileProp) => {
  const iconForAction: Record<string, string> = {
    counsel: mdiAccountMultiple,
    verbal: mdiAccountVoice,
    'written_(1)': mdiFileDocumentEdit,
    'written_(2)': mdiFileDocumentEdit,
    'written_(final)': mdiFileDocumentEdit,
    'notice_(1)': mdiAccountAlert,
    'notice_(2)': mdiAccountAlert,
    terminate: mdiCloseCircleOutline,
  }

  const customActionLabel = (
    <div
      style={{
        ...styles.customActionLabelContainer,
        backgroundColor: props.customAction ? ColorPalette.PRIMARY_BLUE : ColorPalette.LIGHT_GREY,
      }}>
      <p style={{ ...styles.customActionLabelText }}>{props.actionLabel}</p>
    </div>
  )

  return (
    <div style={styles.container}>
      <button style={{ ...styles.card }} onClick={props.onClick}>
        <Icon
          size={1.3}
          path={iconForAction[addUnderScores(toLowerCaseCustom(props.action))]}
          color={props.active ? ColorPalette.PRIMARY_BLUE : ColorPalette.LIGHT_GREY}
        />

        <div
          style={{
            ...styles.dividerLine,
            borderTop: `1px solid ${props.active ? ColorPalette.PRIMARY_BLUE : ColorPalette.LIGHT_GREY}`,
          }}
        />
        <p style={{ ...styles.headerTabText }}>{removeUnderscores(props.action)}</p>
        <p style={{ ...styles.tabTimeText, marginTop: 10 }}>
          {props.expiry ? 'Expires:' : props.authedRoles && props.authedRoles.length ? 'Scheduled' : '.'}
        </p>
        <p style={{ ...styles.tabTimeText, marginTop: 3, fontSize: '0.7rem' }}>{props.expiry ? props.expiry : '-'}</p>
      </button>
      {customActionLabel}
    </div>
  )
}

const styles = {
  card: {
    margin: 2,
    width: window.innerWidth * 0.1,
    height: window.innerWidth * 0.09,
    backgroundImage: `linear-gradient(to bottom, ${ColorPalette.CARD_WHITE}, ${ColorPalette.DRAWER_WHITE})`,
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 2px 6px rgba(0, 0, 60, 0.16)',
    outline: 0,
    border: 'none',
    ':hover': {
      filter: 'brightness(95%)',
    },
    ':active': {
      filter: 'brightness(85%)',
    },
  },
  dividerLine: {
    width: '60%',
    marginTop: '4%',
  },
  tabTimeText: {
    margin: 0,
    marginTop: 10,
    fontSize: '0.7rem',
    color: ColorPalette.LIGHT_GREY,
  },
  headerTabText: {
    margin: 0,
    marginTop: 10,
    fontSize: '0.7rem',
    textTransform: 'uppercase' as 'uppercase',
    color: ColorPalette.PRIMARY_TEXT,
  },
  customActionLabelContainer: {
    padding: 1,
    width: '97%',
    marginTop: 10,
    backgroundColor: ColorPalette.PRIMARY_BLUE,
    alignItems: 'center',
    justifyContent: 'center',
  },
  customActionLabelText: {
    color: ColorPalette.PRIMARY_LIGHT_TEXT,
    fontSize: '0.6rem',
    margin: 0,
    padding: '0px 5px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

export default Radium(disciplineActionTile)
