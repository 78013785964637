import { ISessionState, onSessionManagerLoad } from './sessionManager'

export interface PeopleFlowCombinedReducer {
  sessionManager: ISessionState
}

export const execLoadHooks: PersistedStateLoadHook = (state: PeopleFlowCombinedReducer) => {
  state = onSessionManagerLoad(state)
  return state
}

export type PersistedStateLoadHook = (state: PeopleFlowCombinedReducer) => PeopleFlowCombinedReducer
