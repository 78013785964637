import { ColorPalette } from '../../config/colors'
import { Dot } from '../GeneralUI/Dot/Dot'

type DeviceLogContentItemProps = {
  children: string
  colour?: string
  styles?: React.CSSProperties
}

export const DeviceLogContentItem = (props: DeviceLogContentItemProps) => {
  let style: React.CSSProperties = styles.metaDataStyle
  const textProbablyTruncated = props.children.length > 45
  if (props.styles) {
    style = { ...style, ...props.styles }
  }
  if (textProbablyTruncated) {
    style = { ...style, cursor: 'help' }
  }
  return (
    <div style={style}>
      <div>
        <Dot colour={props.colour || ColorPalette.PRIMARY_TEXT} styles={{ marginRight: '0.75em' }} />
      </div>
      <div title={props.children} style={styles.itemText}>
        {props.children}
      </div>
    </div>
  )
}

const styles = {
  metaDataStyle: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '0.75rem',
    padding: '0.05em 0',
    color: ColorPalette.PRIMARY_TEXT,
    width: '100%',
    cursor: 'pointer',
    userSelect: 'none' as 'none',
  },
  itemText: {
    width: '100%',
    whiteSpace: 'nowrap' as 'nowrap',
    overflow: 'clip',
    textOverflow: 'ellipsis',
  },
}
