import { SignatureFieldConfig } from "./signature.field.config";

export class FormSignatureConfig {
    page: any;
    coordinates: { x: number; y: number };
    dataCaptureSettings: SignatureFieldConfig;
    signatureStyle: any;

    constructor(page: any, signatureConfig?: any) {
        this.page = page;
        this.coordinates = { x: 50, y: 50 };
        this.signatureStyle = {
            signatureSizeName: "SMALL",
        };
        this.dataCaptureSettings = new SignatureFieldConfig(signatureConfig);
    }
}
