import { ColorPalette } from '../../config/colors'

import React from 'react'
import Radium from 'radium'
import { ScrollView } from '@cantonjs/react-scroll-view'
import Icon from '@mdi/react'
import { mdiFileDocument, mdiViewList, mdiAccount } from '@mdi/js'

import ButtonGeneric from '../BaseComponents/Buttons/ButtonGeneric'
import DocRingChart from '../DataVisualisation/DocRingChart'
import { removeUnderscores } from '../../utils'

export enum ViewModes {
  PER_DOCUMENT = 'PER_DOCUMENT',
  PER_PERSON = 'PER_PERSON',
  ALL = 'ALL',
}

interface DocValidityProps {
  chartConfig: { title: string; value: number; color: string }[]
  selectedViewMode: ViewModes
  numberOfDocs: number
  numberOfDocsTotal: number
  onClick: (screen: ViewModes) => void
  setFilter: (segmentTitle: string) => void
}

export const DocValiditySideMenu = (props: DocValidityProps) => (
  <div style={styles.container}>
    <DocRingChart
      numberOfDocs={props.numberOfDocs}
      numberOfDocsTotal={props.numberOfDocsTotal}
      showDocsPercentage={true}
      onClick={props.setFilter}
      config={props.chartConfig}
    />
    <div>
      <p style={styles.subHeaderStyle}>OVERALL STATS</p>
      <div style={styles.initialInstruction}>{getInitialInstruction(props.numberOfDocs, props.numberOfDocsTotal)}</div>
    </div>
    <div style={styles.dividerLine} />
    <ScrollView contentContainerStyle={{ paddingBottom: 40 }}>
      <ButtonGeneric
        style={{
          ...styles.button,
          backgroundColor:
            props.selectedViewMode === ViewModes.PER_DOCUMENT ? ColorPalette.BUTTON_ACTIVE_GREY : 'transparent',
        }}
        iconBefore={
          <Icon
            style={{ ...styles.buttonIcon, width: '1.25rem', height: '1.25rem' }}
            path={mdiFileDocument}
            color={ColorPalette.PRIMARY_BLUE}
          />
        }
        label={removeUnderscores(ViewModes.PER_DOCUMENT)}
        onClick={() => props.onClick(ViewModes.PER_DOCUMENT)}
      />
      <ButtonGeneric
        style={{
          ...styles.button,
          backgroundColor:
            props.selectedViewMode === ViewModes.PER_PERSON ? ColorPalette.BUTTON_ACTIVE_GREY : 'transparent',
        }}
        iconBefore={<Icon style={styles.buttonIcon} path={mdiAccount} color={ColorPalette.PRIMARY_BLUE} />}
        label={removeUnderscores(ViewModes.PER_PERSON)}
        onClick={() => props.onClick(ViewModes.PER_PERSON)}
      />
      <ButtonGeneric
        style={{
          ...styles.button,
          backgroundColor: props.selectedViewMode === ViewModes.ALL ? ColorPalette.BUTTON_ACTIVE_GREY : 'transparent',
        }}
        iconBefore={<Icon style={styles.buttonIcon} path={mdiViewList} color={ColorPalette.PRIMARY_BLUE} />}
        label={ViewModes.ALL}
        onClick={() => props.onClick(ViewModes.ALL)}
      />
    </ScrollView>
  </div>
)

function getInitialInstruction(numberOfDocs: number, numberOfDocsTotal: number) {
  if (numberOfDocs === undefined || numberOfDocs === 0 || numberOfDocs === numberOfDocsTotal) {
    return 'Click on a chart segment to filter by'
  }
  return ' '
}

const styles: Record<string, React.CSSProperties | Record<string, React.CSSProperties>> = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    width: '100%',
  },
  subHeaderStyle: {
    fontSize: '0.8rem',
    fontWeight: '550',
    fontFamily: 'Roboto',
    color: ColorPalette.PRIMARY_TEXT,
    width: '100%',
    textAlign: 'center' as 'center',
    marginTop: '-1em',
  },
  dividerLine: {
    marginTop: 10,
    width: '86%',
    marginLeft: 20,
    marginBottom: 20,
    borderBottom: `1px solid ${ColorPalette.LIGHT_GREY}`,
  },
  button: {
    fontWeight: '550',
    fontSize: '0.8rem',
    backgroundColor: ColorPalette.CARD_WHITE,
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    paddingLeft: 30,
    marginTop: 0,
    ':hover': {
      backgroundColor: 'rgba(242,242,250,1)',
    },
    ':active': {
      backgroundColor: 'rgba(222,222,230,1)',
    },
  },
  buttonIcon: {
    width: '1.3rem',
    height: '1.3rem',
    color: ColorPalette.PRIMARY_BLUE,
    marginRight: 15,
  },
  initialInstruction: {
    fontSize: '0.7em',
    color: ColorPalette.PRIMARY_TEXT,
    textAlign: 'center',
    marginBottom: '0.5em',
  },
}

export default Radium(DocValiditySideMenu)
