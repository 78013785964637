import { userIdentifier } from '../config/localStorage'
import { User, UserProfileSchema } from '../models/user/user'
import { LocalStorageProvider } from '../provider'
import { Username } from '../types'

export class UserRepository {
  private associationUserEntities: Record<string, User> = {}
  private loggedInUserEntity: User

  constructor(loggedInUser: UserProfileSchema) {
    this.loggedInUserEntity = new User(loggedInUser)
    this.putUserEntity(this.loggedInUserEntity)
  }

  getStorageId(username: Username) {
    return `${userIdentifier}${username}`
  }

  getAssocationUserEntities() {
    return this.associationUserEntities
  }

  getCurrentUserEntity() {
    return this.loggedInUserEntity
  }

  putUserEntity(userEntity: User) {
    const storageKey = this.getStorageId(userEntity.getUsername())
    LocalStorageProvider.setData(storageKey, userEntity)
  }

  setAssociationUser(user: UserProfileSchema) {
    this.associationUserEntities[user.username] = new User(user)
  }

  setAssociationUsers(users: UserProfileSchema[]) {
    let entities = {} as Record<string, User>
    users.map((userSchema) => (entities[userSchema.username] = new User(userSchema)))
    this.associationUserEntities = entities
  }
}
