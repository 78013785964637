import { useState } from 'react'
import Icon from '@mdi/react'
import { mdiPlusCircle } from '@mdi/js'

import ButtonGeneric from '../../BaseComponents/Buttons/ButtonGeneric'
import { ColorPalette } from '../../../config/colors'
import { DataQueryFilterList } from './DataQueryFilterList'
import { QueryFilterSettingsModal } from '../../Modals/DataQuery'
import { FilterTypeEnum } from '../../../enums'
import { ComparatorType, Filter } from '../../../types'

type DataQueryFiltersProps = {
  filters: Filter[][]
  onDoneClick: (filters: Filter[][]) => void
}

export const DataQueryFilters = (props: DataQueryFiltersProps) => {
  const [filterState, setFilterState] = useState<Filter[][]>(props.filters || [])
  const [fieldOptionsState, setFieldOptionsState] = useState<string[]>([])
  const [dataTypeSelectorIndexes, setDataTypeSelectorIndexes] = useState<number[]>([])
  const [querySettingsModalOpen, setQuerySettingsModalOpen] = useState(false)
  const [selectedBlockIndex, setSelectedBlockIndex] = useState<number | null>(null)
  const [selectedFilterIndex, setSelectedFilterIndex] = useState(0)

  const toggleDataTypeSelector = (orBlockIndex?: number, orBlockFilterIndex?: number) => {
    if (orBlockIndex !== undefined && orBlockFilterIndex !== undefined) {
      setDataTypeSelectorIndexes([orBlockIndex, orBlockFilterIndex])
      return
    }
    setDataTypeSelectorIndexes([])
  }

  const addFilter = () => {
    const newFilter: Filter = {
      selectedTarget: '',
      comparatorType: 'equals',
      filterType: FilterTypeEnum.FIELD,
      filterValue: '',
      key: '',
    }
    setFilterState((existingFilters: Filter[][]) => {
      // add new filter on last block element in array
      const filters = [...existingFilters]
      const numberOfFilters = filters.length
      if (numberOfFilters > 0) {
        filters[numberOfFilters - 1].push(newFilter)
      } else {
        filters.push([newFilter])
      }
      return filters
    })
    const numberOfOrBlocks = filterState.length
    const lastOrBlockIndex = numberOfOrBlocks - 1
    if (lastOrBlockIndex > -1) {
      const numberOfFiltersInLastBlock = filterState[lastOrBlockIndex].length
      const filterIndex = numberOfFiltersInLastBlock - 1
      setSelectedFilterIndex(filterIndex)
    }
    setQuerySettingsModalOpen(true)
  }

  const setFilterField = (index: number, result: string, orBlockIndex: number) => {
    if (result.length) {
      const filters = [...filterState]
      filters[orBlockIndex][index] = {
        ...filters[orBlockIndex][index],
        selectedTarget: result,
      }
      setFilterState(filters)
    }
  }

  const setFilterValue = (index: number, value: string | Record<string, Date>, orBlockIndex: number) => {
    const filters = [...filterState]
    filters[orBlockIndex][index] = {
      ...filters[orBlockIndex][index],
      filterValue: value,
    }
    setFilterState(filters)
  }

  const deleteFilter = (index: number, orBlockIndex: number) => {
    const filters = [...filterState]
    if (filters[orBlockIndex].length === 1) {
      filters.splice(orBlockIndex, 1)
    } else {
      filters[orBlockIndex] = filters[orBlockIndex].filter((item, i) => i !== index)
    }
    setFilterState(filters)
    props.onDoneClick(filters)
  }

  const handleFilterSelection = (filterInsideBlockIndex: number, orBlockIndex: number) => {
    setSelectedBlockIndex(orBlockIndex)
    setSelectedFilterIndex(filterInsideBlockIndex)
    setQuerySettingsModalOpen(true)
  }

  const getOrBlockIndex = () => {
    const lastOrBlockIndex = filterState.length - 1
    const orBlockIndex = selectedBlockIndex ?? lastOrBlockIndex
    return orBlockIndex
  }

  const prepFiltersForSaving = (
    dataType: FilterTypeEnum,
    targetName: string,
    comparatorType: ComparatorType,
    comparatorValue?: string | Record<string, Date>,
  ) => {
    const filters = [...filterState]
    const orBlockIndex = getOrBlockIndex()
    const filter = filters[orBlockIndex][selectedFilterIndex]
    filter.comparatorType = comparatorType
    filter.filterType = dataType
    filter.filterValue = comparatorValue || ''
    filter.selectedTarget = targetName
    setFilterState(filters)
    return filters
  }

  const handleQuerySettingsCancel = () => {
    setSelectedFilterIndex(0)
    setQuerySettingsModalOpen(false)
  }

  const handleQuerySettingsDone = (
    dataType: FilterTypeEnum,
    targetName: string,
    comparatorType: ComparatorType,
    comparatorValue?: string | Record<string, Date>,
  ) => {
    const filters = prepFiltersForSaving(dataType, targetName, comparatorType, comparatorValue)
    setQuerySettingsModalOpen(false)
    props.onDoneClick(filters)
  }

  let querySettingsModal = null
  if (querySettingsModalOpen) {
    const orBlockIndex = getOrBlockIndex()
    const filter = filterState[orBlockIndex][selectedFilterIndex]
    const selectedTarget = Array.isArray(filter.selectedTarget) ? filter.selectedTarget[0] : filter.selectedTarget
    const filterType = filter.filterType

    const comparatorType =
      filter.filterType === FilterTypeEnum.DOCUMENT && typeof filter.filterValue === 'object'
        ? 'TIME RANGE'
        : filter.comparatorType

    querySettingsModal = (
      <QueryFilterSettingsModal
        target={selectedTarget || ''}
        selectedType={filterType}
        comparatorType={comparatorType}
        comparatorValue={filter.filterValue as string}
        onCancelClick={handleQuerySettingsCancel}
        onDoneClick={handleQuerySettingsDone}
      />
    )
  }

  const addButtonIcon = <Icon style={styles.buttonIcon} path={mdiPlusCircle} color={ColorPalette.PRIMARY_BLUE} />
  return (
    <div style={{ marginTop: filterState.length === 0 ? 0 : 40 }}>
      <DataQueryFilterList
        filters={filterState}
        fieldOptions={fieldOptionsState}
        setFilters={setFilterState}
        setFilterField={setFilterField}
        toggleFilterType={() => null}
        setFilterValue={setFilterValue}
        deleteFilter={deleteFilter}
        toggleDataTypeSelector={toggleDataTypeSelector}
        onClick={handleFilterSelection}
        triggerFilterSave={(filters: Filter[][]) => props.onDoneClick(filters)}
      />
      <ButtonGeneric
        key="addFilterButton"
        style={{
          ...styles.button,
          marginTop: filterState.length === 0 ? 0 : 50,
          marginLeft: filterState.length === 0 ? 0 : 100,
        }}
        iconBefore={addButtonIcon}
        label="ADD FILTER"
        onClick={addFilter}
      />
      {querySettingsModal}
    </div>
  )
}

const styles = {
  button: {
    fontWeight: '550',
    fontSize: '0.8rem',
    height: 40,
    paddingLeft: 30,
    backgroundColor: 'transparent',
    color: ColorPalette.PRIMARY_TEXT,
    ':hover': {
      backgroundColor: 'rgba(242,242,250,1)',
    },
    ':active': {
      backgroundColor: 'rgba(222,222,230,1)',
    },
    marginBottom: '3em',
  },
  buttonIcon: {
    width: '1.5rem',
    height: '1.5rem',
    color: ColorPalette.PRIMARY_BLUE,
    marginRight: 15,
  },
}
