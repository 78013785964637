import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { cloneDeep } from 'lodash'

import {
  CustomFilterConfig,
  CustomFilterState,
  DataTableFilterState,
  applyFilters,
  cleanFilterState,
  generateFilterState,
  shouldCalculateFilterState,
} from '../../Tables/DataTable/DataTable'
import DataTableHeader, { IColumnConfig } from '../../Tables/DataTable/DataTableHeader'
import { ExpandableTableList } from './ExpandableTableList'
import { PeopleFlowCombinedReducer } from '../../../store'
import { AssociationSettingsRepository } from '../../../repositories'

interface PpeStockDetailsProps {
  selectedType: string
  items: any[]
  style?: React.CSSProperties
}

const PpeStockDetails = (props: PpeStockDetailsProps) => {
  const associationRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.associationRepo,
  ) as AssociationSettingsRepository
  const selectedAssociation = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedAssociation,
  )

  const [tableData, setTableData] = useState<Record<string, any>[]>([])
  const [allFilterState, setAllFilterState] = useState<DataTableFilterState>({})
  const [allSortedData, setAllSortedData] = useState<Record<string, any>[]>([])
  const [customFilterConfig, setCustomFilterConfig] = useState<CustomFilterConfig[]>([])
  const [customFilterState, setCustomFilterState] = useState<CustomFilterState[]>([])
  const [customFilterOpen, setCustomFilterOpen] = useState(false)
  const [headerRefreshTimestamp, setHeaderRefreshTimestamp] = useState<number>(Date.now())

  let tableColumnConfig: IColumnConfig[] = [
    { id: 'sku', label: 'SKU', sizeFactor: 1 },
    { id: 'total', label: 'Total', sizeFactor: 1 },
    { id: 'booked', label: 'Booked', sizeFactor: 1 },
    { id: 'available', label: 'Available', sizeFactor: 1 },
    // { id: 'mismatched', label: 'Mismatched', sizeFactor: 1 },
  ]

  if (props.selectedType === 'All') {
    tableColumnConfig = [{ id: 'item', label: 'Item', sizeFactor: 1 }, ...tableColumnConfig]
  } else {
    tableColumnConfig = [{ id: 'size', label: 'Size', sizeFactor: 1 }, ...tableColumnConfig]
  }

  useEffect(() => {
    try {
      // let { filterState } = props;
      const filterState = {}
      if (filterState && Object.keys(filterState).length) {
        updateFilterState(filterState)
        // refreshTableHeader();
      } else {
        let allFilterState = generateFilterState(tableColumnConfig, tableData, {})
        const data = applyFilters(tableData, allFilterState)
        setAllFilterState(allFilterState)
        setAllSortedData(data)
        // this.setState({ allSortedData: tableData, allFilterState }, () =>
        //     this.loadPage(null, this.state.currentPage, true),
        // );
      }
      initialiseCustomFilterConfig()
    } catch (error) {
      console.error('error:', error)
    }
  }, [])

  const refreshTableHeader = () => setHeaderRefreshTimestamp(Date.now())

  const toggleCustomFilter = (saveAndClose: boolean) => {
    let filterState = cloneDeep(customFilterState)
    if (saveAndClose) {
      filterState = cleanFilterState(filterState)
      // applyCustomFilters(filterState);
    }
    setCustomFilterOpen(!saveAndClose)
    setCustomFilterState(filterState)
  }

  const initialiseCustomFilterConfig = () => {
    const customFilterConfig = getFilterConfig()
    setCustomFilterConfig(customFilterConfig)
  }

  const getFilterConfig = (): CustomFilterConfig[] => {
    const config: CustomFilterConfig[] = tableColumnConfig.map((configItem) => {
      return {
        key: configItem.id,
        label: configItem.label,
      }
    })
    const fieldConfig = associationRepo.getFieldConfig(selectedAssociation)
    // NOTE: function belows relies on filterable fields existing in fieldConfig
    return associationRepo.populateFilterConfig(config, fieldConfig)
  }

  const updateFilterState = (updatedFilterState: DataTableFilterState, callback?: () => void) => {
    if (shouldCalculateFilterState(updatedFilterState)) {
      updatedFilterState = generateFilterState(tableColumnConfig, tableData, updatedFilterState)
    }
    const data = applyFilters(tableData, updatedFilterState)
    setAllFilterState(updatedFilterState)
    setAllSortedData(data)
    if (callback) {
      callback()
    }
  }

  const removeFilters = (rowItemId: string) => {
    Object.keys(allFilterState).forEach((headerKey) => {
      if (headerKey === rowItemId) {
        Object.keys(allFilterState[headerKey]).forEach((filterKey) => (allFilterState[headerKey][filterKey] = false))
      }
    })
    updateFilterState(allFilterState)
    refreshTableHeader()
  }

  const { items } = props

  const columnConfig = tableColumnConfig.map((configItem) => {
    return { ...configItem, sizeFactor: configItem?.sizeFactor || 1 }
  })
  const combinedSizeFactor = columnConfig.reduce<number>((arr, el) => {
    return arr + el.sizeFactor
  }, 0)

  const listData = [
    // {
    //   label: 'GAUTENG',
    //   items: [],
    // },
    {
      label: 'KWAZULU NATAL',
      items: items.map((item: any) => ({
        item: item.item || '',
        size: item.size || '',
        sku: item.sku,
        total: item.total,
        booked: item.booked,
        available: item.total - item.booked,
        mismatched: item.mismatched,
      })),
    },
    // {
    //   label: 'WESTERN CAPE',
    //   items: [],
    // },
  ]

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <DataTableHeader
        columnConfig={tableColumnConfig}
        combinedSizeFactor={combinedSizeFactor}
        customComponentConfig={undefined}
        columnIndexToSort={''} // this.state.columnIndexToSort
        sortDirection={'asc'} // sortDirection: "asc" | "desc" | "ascending" | "descending";
        allSelected={false}
        tableWidth={9}
        filterData={(updatedFilterState) => {
          // this.setLoadingModalMessage("Applying filter...");
          setTimeout(() => {
            updateFilterState(updatedFilterState) // this.setLoadingModalMessage("")
          }, 100)
        }}
        allFilterState={allFilterState}
        onSelectAll={() => null}
        removeFilters={(rowItemId: string) => {
          // this.setLoadingModalMessage("Removing filter...");
          setTimeout(() => {
            removeFilters(rowItemId)
            // this.setLoadingModalMessage("");
          }, 100)
        }}
        onSortRequest={(sortcol: string) => null}
        customFilterConfig={customFilterConfig}
        customFilterState={customFilterState}
        customFilterOpen={customFilterOpen}
        updateCustomFilterState={(filterState) => setCustomFilterState(filterState)}
        toggleCustomFilter={(saveAndClose: boolean) => toggleCustomFilter(saveAndClose)}
        isSortEnabled={false}
        disableHoverEffect={true}
        key={`${headerRefreshTimestamp}_${
          //${JSON.stringify(tableColumnConfig)}
          customFilterConfig ? Object.keys(customFilterConfig).join('-') : ''
        }`}
      />
      <ExpandableTableList
        columnConfig={columnConfig}
        listData={listData}
        noItemsInGroupingMessage="No stock items available"
      />
    </div>
  )
}

export default PpeStockDetails
