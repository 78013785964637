export const QUERY_OPERATIONS_TO_READABLE: Record<string, string> = {
  beginsWith: 'BEGINS WITH',
  contains: 'CONTAINS',
  equals: 'EQUALS',
  exists: 'EXISTS',
  doesntExist: 'DOES NOT EXIST',
  greatThanEqualTo: 'GREATER THAN OR EQUAL TO',
  hasChanged: 'HAS CHANGED',
  lessThanEqualTo: 'LESS THAN OR EQUAL TO',
}

export const QUERY_OPERATIONS_TO_KEY: Record<string, string> = {
  'BEGINS WITH': 'beginsWith',
  CONTAINS: 'contains',
  EQUALS: 'equals',
  EXISTS: 'exists',
  'DOES NOT EXIST': 'doesntExist',
  'GREATER THAN OR EQUAL TO': 'greatThanEqualTo',
  'HAS CHANGED': 'hasChanged',
  'LESS THAN OR EQUAL TO': 'lessThanEqualTo',
}
