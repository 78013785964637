import { ColorPalette } from '../../../config'
import CalendarRange from './CalendarRange'
import { DateRange, DateRangeConfig, DateRangePoint } from '../../Modals/DateRanges/DateRangesModal'

type CalenderRangesProps = {
  dateRangeConfig: DateRangeConfig
  selections: Record<string, DateRange>
  style?: React.CSSProperties
  onDateRangeChange: (date?: number, rangePoint?: DateRangePoint, id?: string) => void
  validateDateRange: (selectionLabel: string) => JSX.Element | null
}

export const CalenderRanges = (props: CalenderRangesProps) => {
  const { selections, style, onDateRangeChange, validateDateRange } = props

  const renderRanges = () =>
    props.dateRangeConfig.map((configItem, index) => {
      const label = configItem.label
      const subHeaderStyle = index > 0 ? { ...styles.textSubHeaderStyle, marginTop: 45 } : styles.textSubHeaderStyle

      return (
        <div key={index} style={style || {}}>
          <h3 style={subHeaderStyle}>{configItem.label.toUpperCase()}</h3>
          <CalendarRange
            id={label}
            orientation="horizontal"
            dateFormat="yyyy/MM/dd"
            dateRange={selections[label] as Pick<DateRange, 'startDate' | 'endDate'>}
            // @ts-expect-error
            onDateChange={onDateRangeChange}
            dialogStyle={{ zIndex: 30000 }}
            minEndDate={selections[label].startDate}
          />
          {validateDateRange(label)}
        </div>
      )
    })

  const dateRanges = renderRanges()
  return <div>{dateRanges}</div>
}

const styles = {
  textSubHeaderStyle: {
    textAlign: 'center' as 'center',
    alignSelf: 'center',
    fontFamily: 'roboto',
    fontWeight: 'normal',
    color: ColorPalette.PRIMARY_TEXT,
    paddingLeft: 20,
    paddingRight: 20,
  },
  additionalInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '1em',
  },
}
