import { ColorPalette } from '../../config/colors'

import React, { forwardRef } from 'react'

import ButtonGeneric from '../BaseComponents/Buttons/ButtonGeneric'
import { GridSize } from '@material-ui/core'
import { mdiChartBar, mdiCog, mdiSync, mdiTableAccount } from '@mdi/js'
import Icon from '@mdi/react'

interface TabbedNavigatorProps {
  currentTabName: string
  tabs: { tabName: string; iconName: string; iconSpin?: boolean; iconColour?: string }[]
  buttonStyle?: React.CSSProperties | undefined
  tableWidth?: GridSize
  containerRef?: React.RefObject<HTMLDivElement>
  editable?: boolean
  scrollMenuStyle?: React.CSSProperties | undefined
  scrollWrapperStyle?: React.CSSProperties | undefined
  onTabClick: (sectionName: string) => void
  onSectionEditClick?: (sectionLabel: string) => void
  onSectionAddClick?: () => void
  onSectionRemoveClick?: () => void
}

export const TabNavigator = forwardRef((props: TabbedNavigatorProps, ref) => {
  const generateButtons = (): JSX.Element[] => {
    const buttonArray = props.tabs?.map((tab) => {
      const { tabName, iconName } = tab
      return (
        <ButtonGeneric
          key={tabName}
          style={{
            ...styles.buttonStyle,
            backgroundColor:
              props.currentTabName === tabName ? ColorPalette.CARD_WHITE : ColorPalette.BUTTON_HOVER_GREY,
            ...props.buttonStyle,
            width: '100%',
          }}
          iconBefore={
            tabIconPaths[iconName] ? (
              <Icon
                path={tabIconPaths[iconName]}
                size={0.8}
                spin={tab.iconSpin}
                color={tab.iconColour}
                style={{ marginLeft: '1em', marginRight: '0.75em' }}
              />
            ) : (
              <Icon path={mdiCog} size={0.8} style={{ marginLeft: '1em', marginRight: '0.75em' }} />
            )
          }
          iconAfter={
            props.editable ? (
              <div
                style={styles.editButtonStyle}
                onClick={
                  props.onSectionEditClick
                    ? // @ts-ignore (suppressing "Cannot invoke an object which is possibly 'undefined'")
                      () => props.onSectionEditClick(sectionLabel)
                    : undefined
                }>
                EDIT
              </div>
            ) : undefined
          }
          label={tabName}
          labelStyle={styles.labelStyle}
          onClick={() => props.onTabClick(tabName)}
        />
      )
    })

    return buttonArray
  }

  return (
    <div ref={props.containerRef} style={styles.container}>
      {generateButtons()}
    </div>
  )
})

const navBarHeight = 40
const arrowButtonWidth = 20
const styles = {
  container: {
    height: navBarHeight,
    width: '100%',
    display: 'flex',
    // flexDirection: "row" as "row",
    alignItems: 'center',
    // justifyContent: "flex-start",
    alignSelf: 'center',
    boxShadow: '0px 0px 2px rgba(120,120,130, 0.2)',
    // position: "-webkit-sticky",  // Safari
    position: 'sticky' as 'sticky',
    top: 0,
    zIndex: 2,
  },
  buttonStyle: {
    paddingLeft: 5,
    width: 175,
    height: navBarHeight,
    fontWeight: '550',
    fontSize: '0.75rem',
    color: ColorPalette.SECONDARY_TEXT,
    marginTop: 0,
    boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.2)',
    ':hover': {
      backgroundColor: ColorPalette.CARD_WHITE,
    },
    ':active': {
      filter: 'brightness(98%)',
      backgroundColor: ColorPalette.CARD_WHITE,
    },
  },
  arrowButtonStyle: {
    width: arrowButtonWidth,
    height: 40,
    fontWeight: '550',
    fontSize: '0.8rem',
    justifyContent: 'center',
    backgroundColor: ColorPalette.DARK_GREY,
    color: ColorPalette.PRIMARY_LIGHT_TEXT,
    marginTop: 0,
    boxShadow: '0px -1px 6px rgba(0, 0, 0, 0.15)',
    ':hover': {
      filter: 'brightness(65%)',
    },
    ':active': {
      filter: 'brightness(35%)',
    },
  },
  arrowIcon: {
    color: ColorPalette.PRIMARY_LIGHT_TEXT,
    width: '1.6rem',
    height: '1.6rem',
  },
  iconStyle: {
    color: ColorPalette.DARK_GREY,
    width: '1.1rem',
    height: '1.1rem',
    marginRight: 10,
    marginLeft: 10,
  },
  editButtonStyle: {
    marginInline: '1em',
    borderRadius: '100px',
    backgroundColor: ColorPalette.PRIMARY_BLUE,
    color: ColorPalette.CARD_WHITE,
    border: 'none',
    padding: '.25em .75em',
    fontSize: 'smaller',
    cursor: 'pointer',
    marginLeft: 'auto',
    marginRight: '1em',
  },
  labelStyle: {
    display: 'block',
    whiteSpace: 'nowrap' as 'nowrap',
    overflowX: 'hidden' as 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: '1em',
  },
  plus: {
    marginLeft: '4em',
    borderRadius: '100px',
    backgroundColor: ColorPalette.PRIMARY_BLUE,
    color: ColorPalette.CARD_WHITE,
    border: 'none',
    fontSize: '1.1em',
    padding: '.1em 1em',
    cursor: 'pointer',
  },
}

const tabIconPaths: Record<string, any> = {
  cog: mdiCog,
  ['table-account']: mdiTableAccount,
  ['chart-bar']: mdiChartBar,
  sync: mdiSync,
}

export default TabNavigator
