import { forwardRef } from 'react'
import { TrendList } from './TrendList'
import NewGraphButton from './NewGraphButton'
import { TrendGraph } from './TrendGraph'
import { UserFeedback } from '../../GeneralUI/Feedback/UserFeedback'
import { QueryTrend, TrendConfig } from '../../../types'

type DataQueryTrendsProps = {
  selectedTrendId: string
  selectedTrendName: string
  trendConfig: TrendConfig[]
  trendData: QueryTrend[]
  xValues: string[]
  yValues: number[]
  setSelectedTrendId: (id: string) => void
  toggleTrendConfigurator: () => void
}

export const DataQueryTrends = forwardRef((props: DataQueryTrendsProps, ref) => {
  const { selectedTrendId, xValues, yValues, setSelectedTrendId, toggleTrendConfigurator } = props
  let feebackMessage = null
  let trendGraph = null
  if (!selectedTrendId) {
    if (props.trendConfig.length) {
      feebackMessage = <UserFeedback message="Select a trend to view" style={{ paddingBottom: '4em' }} />
    } else {
      feebackMessage = <UserFeedback message="No trends configured" style={{ paddingBottom: '4em' }} />
    }
  }

  if (selectedTrendId) {
    trendGraph = (
      <TrendGraph
        selectedTrendId={selectedTrendId}
        selectedTrendName={props.selectedTrendName}
        xValues={xValues}
        yValues={yValues}
        toggleTrendConfigurator={toggleTrendConfigurator}
      />
    )
  }

  return (
    <div
      // @ts-ignore
      ref={ref}
      // @ts-ignore
      style={styles.containerStyle}
      key={`graph_container`}>
      <div style={{ height: '40px' }} />
      <div style={styles.graphView}>
        <NewGraphButton
          onClick={() => {
            setSelectedTrendId('')
            toggleTrendConfigurator()
          }}
        />
        <TrendList
          trendConfig={props.trendConfig}
          selectedTrendId={selectedTrendId}
          onClick={(trendId) => setSelectedTrendId(trendId)}
        />
      </div>
      {feebackMessage}
      {trendGraph}
    </div>
  )
})

const styles = {
  containerStyle: {
    height: '100%',
    width: '88%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column' as 'column',
  },
  section: {
    width: '100%',
    height: '100%',
    margin: '1em 0',
    paddingBottom: '20px',
  },
  graphView: {
    display: 'flex',
    alignItems: 'center',
    gap: '1em',
  },
  noGraphsContainer: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}
