import { APP_VERSION } from '../../cloud-config'
import { Profile } from '../../models'
import { refreshUpdateTracker } from '../../utils'
import { httpPost } from '../remoteData'

export async function createBulkDisciplineActon(
  idPassport: string,
  offence: any,
  action: any,
  selectedAssociation: string,
  selectedRoles: any,
  token: string,
) {
  const payload = {
    idPassport,
    offence,
    action,
    selectedEmployer: selectedAssociation,
    selectedRoles,
  }
  const { data } = await httpPost('people_flow_data_sync', '/data-sync/create-bulk-discipline-action', payload, token)
  if (data.result !== 'success') {
    throw { code: 'UnknownError' }
  }
  return data
}

export async function updateDisciplinaryData(
  selectedAssociation: string,
  disciplinaryActionsTracker: any,
  token: string,
) {
  const { data } = await httpPost(
    'people_flow_data_sync',
    '/data-sync/update-disciplinary-data',
    { selectedEmployer: selectedAssociation, disciplinaryActionsTracker },
    token,
  )
  if (data.result !== 'success') {
    throw { code: 'DisciplineUploadFailed' }
  }
  return data
}

export async function linkDocsToProfile(association: string, params: Record<string, any>, token: string) {
  const payload = { selectedEmployer: association, ...params }
  const { data } = await httpPost('people_flow_data_sync', '/data-sync/link-docs-to-profile', payload, token)
  if (data.result !== 'success') {
    if (data.result === 'doesntExist') {
      throw { code: 'ProfileDoesntExist' }
    }
    throw { code: 'UnknownError' }
  }
  return data
}

export async function renameAllFiles(association: string, profilesChanged: any[], token: string) {
  const { data } = await httpPost(
    'people_flow_data_sync',
    '/data-sync/rename-all-files',
    { profilesChanged, selectedEmployer: association, appVersion: APP_VERSION },
    token,
  )
  if (data.result !== 'success' || !data.unsuccessfulItems || data.unsuccessfulItems.length) {
    throw { code: 'ChangeIdFailed' }
  }
  return data
}

export async function updateEmployeeData(
  association: string,
  rawDataChanges: Record<string, { old: any; new: any }>,
  profile: Profile,
  userInfo: { name: string; surname: string; idPassport: string },
  token: string,
) {
  const updateItem = refreshUpdateTracker(rawDataChanges, profile, userInfo)
  const pk = profile.getPk()
  const updateTrackerBatch = { [pk]: updateItem }
  const { data } = await httpPost(
    'people_flow_data_sync',
    '/data-sync/update-employee-data',
    { updateTrackerBatch, selectedEmployer: association, dataCategory: 'rawData' },
    token,
  )
  if (data.result !== 'success') {
    throw { code: 'SaveChangesError' }
  }
  return data
}
