import { useState, useEffect, memo } from 'react'
import { useSelector } from 'react-redux'

import NavigationBar from '../../components/Navigation/NavigationBar'
import SectionHeaderPrimary from '../../components/Headings/SectionHeaderPrimary'

import { ColorPalette } from '../../config/colors'
import SideMenu from '../../components/Navigation/SideMenu'
import BillingSideMenu from '../../components/SideMenus/BillingSideMenu'

import { PeopleFlowCombinedReducer } from '../../store'
import { getBillingVerifications, getBillingProfiles } from '../../provider'
import { BillTrendEntry, BillingUiState, CountsBillingDataObject, VerificationsBillingCategoryItem } from '../../types'
import { SessionService } from '../../services'
import { BillingDetails } from '../../components/Billing/BillingDetails'

type BillingProps = {
  match: any
  location: any
  history: any
  selectedAssociation: string
}

const Billing = (props: BillingProps) => {
  const selectedAssociation = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedAssociation,
  )
  const idPassport = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.idPassport)
  const password = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.password)
  const [selectedCategory, setSelectedCategory] = useState('ALL')
  const [selectedDateRange, setSelectedDateRange] = useState<Record<string, Date>>({
    startDate: new Date(new Date().getTime() - 2408000000),
    endDate: new Date(),
  })
  const [data, setData] = useState<BillingUiState>({
    people: [],
    bankVerifications: [],
    idVerifications: [],
  })

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    const token = await SessionService.prepareAuthTokens(idPassport, password)
    await getPeopleCounts(token)
    await getVerifications(token)
  }

  const getPeopleCounts = async (token: string) => {
    const data = await getBillingProfiles(selectedAssociation, token)

    const sortedResponseData = data.sort((a: any, b: any) => a.unixMs - b.unixMs)
    const peopleData = sortedResponseData.map((item: any) => ({
      date: new Date(item.unixMs).toISOString(),
      employees: item.employees,
      candidates: item.candidates,
    }))
    setData({ ...data, people: peopleData })
  }

  const getVerifications = async (token: string) => {
    const response = await getBillingVerifications(selectedAssociation, token)

    const bankVerificationData = response.data.bankverification
    if (bankVerificationData && bankVerificationData.length) {
      setBankVerificationData(bankVerificationData)
    }
    const idVerificationData = response.data.idverification
    if (idVerificationData && idVerificationData.length) {
      setIdVerificationData(idVerificationData)
    }
  }

  const setBankVerificationData = (bankVerificationData: BillTrendEntry<number>[]) => {
    const sortedData = bankVerificationData.sort((a: any, b: any) => {
      return a.unixMs - b.unixMs
    })
    const bankVerifications = sortedData.map((item: any) => ({
      date: new Date(item.unixMs).toISOString(),
      price: item.billValue?.price,
    }))
    setData((data) => ({ ...data, bankVerifications }))
  }

  const setIdVerificationData = (idVerificationData: any) => {
    const sortedData = idVerificationData.sort((a: any, b: any) => {
      return a.unixMs - b.unixMs
    })
    const idVerifications = sortedData.map((item: any) => ({
      date: new Date(item.unixMs).toISOString(),
      price: item.billValue?.price,
    }))
    setData((data) => ({ ...data, idVerifications }))
  }

  const getDataMatchingDataRange = (data: CountsBillingDataObject[] | VerificationsBillingCategoryItem[]) => {
    // @ts-ignore
    return data.filter((item: CountsBillingDataObject | VerificationsBillingCategoryItem) => {
      const itemDate = new Date(item.date)
      return itemDate >= selectedDateRange.startDate && itemDate <= selectedDateRange.endDate
    })
  }

  const getBillingAmountTotal = (
    peopleDataMatchingDateRange: CountsBillingDataObject[],
    bankVerificationsMatchingDateRange: VerificationsBillingCategoryItem[],
    idVerificationsMatchingDateRange: VerificationsBillingCategoryItem[],
    maxCandidatesInPeriod: number,
    maxEmployeesInPeriod: number,
  ) => {
    let countsPriceTotal = 0
    let bankVerificationsPriceTotal = 0
    let idVerificationsPriceTotal = 0

    if (peopleDataMatchingDateRange.length) {
      countsPriceTotal =
        peopleDataMatchingDateRange[peopleDataMatchingDateRange.length - 1].candidates.price * maxCandidatesInPeriod +
        peopleDataMatchingDateRange[peopleDataMatchingDateRange.length - 1].employees.price * maxEmployeesInPeriod
    }

    if (bankVerificationsMatchingDateRange.length) {
      const verificationsMatchingDataRange = getDataMatchingDataRange(bankVerificationsMatchingDateRange)
      // @ts-ignore
      bankVerificationsPriceTotal = verificationsMatchingDataRange.length
        ? verificationsMatchingDataRange.reduce(
            // @ts-ignore
            (acc: number, item: VerificationsBillingCategoryItem) => acc + item.price,
            0,
          )
        : 0
    }
    if (idVerificationsMatchingDateRange.length) {
      const verificationsMatchingDataRange = getDataMatchingDataRange(idVerificationsMatchingDateRange)
      // @ts-ignore
      idVerificationsPriceTotal = verificationsMatchingDataRange.length
        ? verificationsMatchingDataRange.reduce(
            // @ts-ignore
            (acc: number, item: VerificationsBillingCategoryItem) => acc + item.price,
            0,
          )
        : 0
    }
    // return countsPriceTotal + bankVerificationsPriceTotal + idVerificationsPriceTotal;
    return countsPriceTotal.toFixed(2)
  }

  const peopleDataMatchingDateRange = getDataMatchingDataRange(data.people) as CountsBillingDataObject[]
  let billingCategories = ['ALL']
  if (peopleDataMatchingDateRange.length) {
    billingCategories = [...billingCategories, 'CANDIDATES', 'EMPLOYEES']
  }
  const bankVerificationsMatchingDateRange = getDataMatchingDataRange(
    data.bankVerifications,
  ) as VerificationsBillingCategoryItem[]
  if (bankVerificationsMatchingDateRange.length) {
    billingCategories = [...billingCategories, 'BANK VERIFICATIONS']
  }
  const idVerificationsMatchingDateRange = getDataMatchingDataRange(
    data.idVerifications,
  ) as VerificationsBillingCategoryItem[]
  if (idVerificationsMatchingDateRange.length) {
    billingCategories = [...billingCategories, 'ID VERIFICATIONS']
  }

  const maxCandidatesInPeriod = Math.max.apply(
    Math,
    peopleDataMatchingDateRange.map((item: any) => item.candidates.quantity),
  )
  const maxEmployeesInPeriod = Math.max.apply(
    Math,
    peopleDataMatchingDateRange.map((item: any) => item.employees.quantity),
  )
  const billingAmountTotal = getBillingAmountTotal(
    peopleDataMatchingDateRange,
    bankVerificationsMatchingDateRange,
    idVerificationsMatchingDateRange,
    maxCandidatesInPeriod,
    maxEmployeesInPeriod,
  )

  const candidateBar = {
    label: 'Candidates',
    data: peopleDataMatchingDateRange.map((item: any) => item.candidates.quantity),
    backgroundColor: ColorPalette.PRIMARY_BLUE,
  }
  const employeeBar = {
    label: 'Employees',
    data: peopleDataMatchingDateRange.map((item: any) => item.employees.quantity),
    backgroundColor: ColorPalette.DARK_GREY,
  }

  const lineStyle = { backgroundColor: 'rgba(0,0,0,0)', borderWidth: 3, radius: 0 }
  const candidateLine = {
    type: 'line',
    label: 'Max Candidates',
    // @ts-ignore
    data: [...peopleDataMatchingDateRange].fill(maxCandidatesInPeriod),
    borderColor: ColorPalette.PRIMARY_BLUE_TRANSPARENT,
    ...lineStyle,
  }
  const employeeLine = {
    type: 'line',
    label: 'Max Employees',
    // @ts-ignore
    data: [...peopleDataMatchingDateRange].fill(maxEmployeesInPeriod),
    borderColor: ColorPalette.DARK_GREY_TRANSPARENT,
    ...lineStyle,
  }

  let graphDataSets = [candidateBar, employeeBar, candidateLine, employeeLine]
  // if (idVerificationsMatchingDateRange.length) {
  //     graphDataSets.push({
  //         label: "ID verifications",
  //         data: idVerificationsMatchingDateRange.map((item: any) => (item.idVerifications ? 1 : 0)),
  //         backgroundColor: ColorPalette.MEDIUM_GREY,
  //     });
  // }
  if (selectedCategory === 'CANDIDATES') {
    graphDataSets = [candidateBar, candidateLine]
  }
  if (selectedCategory === 'EMPLOYEES') {
    graphDataSets = [employeeBar, employeeLine]
  }
  // if (selectedCategory === "ID VERIFICATIONS") {
  //     graphDataSets.splice(0, 2);
  // }

  return (
    <div style={styles.container}>
      <NavigationBar match={props.match} location={props.location} history={props.history} />
      <SectionHeaderPrimary style={styles.sectionHeader} disabled={true} searchString={''} onClick={() => ({})}>
        Billing
      </SectionHeaderPrimary>

      <div style={styles.contentContainer}>
        <SideMenu
          visible={true}
          menuComponents={
            <BillingSideMenu
              billingAmountTotal={billingAmountTotal}
              billingCategories={billingCategories}
              selectedCategory={selectedCategory}
              selectedDateRange={selectedDateRange}
              onBillingCategoryClick={(category: string) => setSelectedCategory(category)}
              onDateRangeChange={(dateRange: Record<string, Date>) => setSelectedDateRange(dateRange)}
            />
          }
        />
        <BillingDetails
          data={peopleDataMatchingDateRange}
          dataSets={graphDataSets}
          maxCandidatesInPeriod={
            selectedCategory === 'ALL' || selectedCategory === 'CANDIDATES' ? maxCandidatesInPeriod : undefined
          }
          maxEmployeesInPeriod={
            selectedCategory === 'ALL' || selectedCategory === 'EMPLOYEES' ? maxEmployeesInPeriod : undefined
          }
          key={`billingDetails_${selectedCategory}`}
        />
      </div>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255, 1), rgba(209,210,230, 1))',
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto 1.5%',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
}

export default memo(Billing)
