import { useState } from 'react'
import TableRow from '@material-ui/core/TableRow'

import { SimpleTableCell } from './SimpleTableCell'
import { ColorPalette } from '../../../config'

type SimpleTableRowProps = {
  row: Record<string, string>
  stickyFirstColumn?: boolean
  onRowClick?: (rowData: Record<string, string>) => void
}

export const SimpleTableRow = (props: SimpleTableRowProps) => {
  const [isHovering, setIsHovering] = useState(false)

  const handleMouseEnter = () => {
    setIsHovering(true)
  }

  const handleMouseLeave = () => {
    setIsHovering(false)
  }

  const renderRows = (rowKeys: string[]) =>
    rowKeys.map((key: string, index: number) => (
      <SimpleTableCell
        index={index}
        data={row[key]}
        stickyFirstColumn={props.stickyFirstColumn}
        key={`tableCell_${key}_${index}`}
      />
    ))

  const { id, ...row } = props.row
  const rowKeys = Object.keys(row)

  const handleRowClick = (rowData: Record<string, string>) => {
    if (!props.onRowClick) {
      return
    }
    props.onRowClick(rowData)
  }

  const containerStyle: React.CSSProperties = {}
  if (props.onRowClick) {
    containerStyle.cursor = 'pointer'
    if (isHovering) {
      containerStyle.backgroundColor = ColorPalette.BUTTON_HOVER_GREY
    }
  }

  const rows = renderRows(rowKeys)

  return (
    <TableRow
      key={id}
      onClick={() => handleRowClick(props.row)}
      style={containerStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      {' '}
      {rows}
    </TableRow>
  )
}
