import { ColorPalette } from '../../config/colors'

interface HeadingProps {
  header: string
  icon?: JSX.Element
  containerStyle?: React.CSSProperties
  textStyle?: React.CSSProperties
}

const SectionHeaderSecondary: React.FC<HeadingProps> = (props: HeadingProps) => {
  return (
    <div style={{ ...styles.containerStyle, ...props.containerStyle }}>
      <div style={styles.sectionHeaderContainer}>
        <p style={{ ...styles.sectionHeaderText, ...props.textStyle }}>{props.header}</p>
        {props.icon}
      </div>
      <div style={styles.headerUnderLine} />
    </div>
  )
}

const styles = {
  containerStyle: {
    marginTop: 15,
    width: '100%',
    display: 'flex',
    alignSelf: 'center',
    flexDirection: 'column' as 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  sectionHeaderContainer: {
    width: '100%',
    // alignSelf: "center",
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center',
  },
  sectionHeaderText: {
    fontSize: '0.8rem',
    fontWeight: '500',
    fontFamily: 'Roboto',
    paddingLeft: 10,
    paddingRight: 10,
    margin: 0,
    color: ColorPalette.PRIMARY_TEXT,
  },
  headerUnderLine: {
    width: '100%',
    alignSelf: 'center',
    marginTop: 5,
    marginBottom: 0,
    borderBottom: `1px solid ${ColorPalette.PRIMARY_BLUE}`,
  },
}
export default SectionHeaderSecondary
