import { isEmpty } from 'lodash'
import { execLoadHooks, PeopleFlowCombinedReducer } from '../store'

export class ReduxRepository {
  static saveStateSnapshot(stateObj: PeopleFlowCombinedReducer) {
    if (!stateObj || !stateObj.sessionManager || !stateObj.sessionManager.currentSection) {
      return
    }
    localStorage.setItem('stateSnapshot', JSON.stringify(stateObj))
  }

  static loadStateSnapshot() {
    const state = localStorage.getItem('stateSnapshot')
    const stateObj = JSON.parse(state || '{}')
    if (isEmpty(state) || !state) {
      return {}
    }
    localStorage.removeItem('stateSnapshot')
    return execLoadHooks(stateObj)
  }
}
