import { cloneDeep, intersection, isEmpty, omit, pick } from 'lodash'
import { isNumericString, removeUnderscores } from './stringUtils'
import { ProfilePk } from '../models'
import { AssociationSettingsRepository, ProfileRepository } from '../repositories'
import {
  PpeAction,
  PpeActionRecordType,
  PpeInstance,
  PpeInstanceRecordSk,
  PpeRecordSk,
  PpeSuppliers,
  PpeType,
  PpeTypeUpsert,
} from '../types'
import { PpeActions, PpeItemAvailabilityStatus } from '../enums'

export const getUniqueTypeLabels = (types: PpeType[]) => {
  const typeLabels = [...new Set(types.map((type) => removeUnderscores(type.label)))]
  return typeLabels
}

export const getUniqueTypeLabelsInclSku = (types: PpeType[]) => {
  const typesWithSkus: string[] = []
  types.forEach((type) => {
    const label = removeUnderscores(type.label)
    type.skus?.forEach((sku) => {
      typesWithSkus.push(`${label} - ${sku}`)
    })
  })
  return typesWithSkus
}

export const getTypesSkus = (types: PpeType[]) => {
  const skus: string[] = []
  types.forEach((type) => {
    type.skus?.forEach((sku) => {
      skus.push(sku)
    })
  })
  return skus
}

export const sortSizeValues = (values: string[]) => {
  if (values && values.length > 0) {
    const firstValue = values[0]
    const clothingSizesMap: Record<string, number> = {
      XS: 1,
      S: 2,
      M: 3,
      L: 4,
      XL: 5,
      '1XL': 6,
      XXL: 7,
      '2XL': 8,
      XXXL: 9,
      '3XL': 10,
    }
    if (isNumericString(firstValue) || !Object.keys(clothingSizesMap).includes(firstValue)) {
      const numericValues = values.map((value) => parseInt(value))
      numericValues.sort((a, b) => a - b)
      const stringValues = numericValues.map((value) => value.toString())
      return stringValues
    }
    // at this point assuming clothing sizes have been entered and needs sorting
    const sortClothingSizes = (a: string, b: string) => {
      const aOrderValue = clothingSizesMap[a]
      const bOrderValue = clothingSizesMap[b]
      if (aOrderValue < bOrderValue) {
        return -1
      }
      if (aOrderValue > bOrderValue) {
        return 1
      }
      return 0
    }
    const sortedSizes = values.sort(sortClothingSizes)
    return sortedSizes
  }
  return values
}

export const getPpeTypesFromFieldConfig = (fieldConfig: Record<string, any>, keysToExtract: string[]) => {
  const ppeTypes: PpeType[] = []
  Object.keys(fieldConfig).forEach((key) => {
    const field = fieldConfig[key]
    if (field.isPpe) {
      const type: PpeType = pick(field, keysToExtract) as PpeType
      ppeTypes.push(type)
    }
  })
  return ppeTypes
}

export const getPpeTypeLabelFromKey = (types: Partial<PpeType>[], key: string) => {
  const type = types.find((type) => type.key === key)
  return type?.label || ''
}

export const getInstanceRelatedtoAction = (
  actionRecord: PpeActionRecordType,
  allInstances: Record<PpeRecordSk, PpeInstance>,
) => {
  if (!isEmpty(actionRecord) && !isEmpty(allInstances) && actionRecord.rawData.ppeInstanceId) {
    const instance = allInstances[actionRecord.rawData.ppeInstanceId]
    return instance
  }
  return {}
}

export const getLatestAction = (allRelatedActions: Record<PpeRecordSk, PpeActionRecordType>) => {
  const actions = Object.values(allRelatedActions)
  actions.sort((a, b) => b.rawData.dateActioned - a.rawData.dateActioned)
  const latestAction = actions[0]
  return latestAction
}

export const getActionsRelatedToRequests = (
  requestSks: string[],
  allActions: Record<PpeRecordSk, PpeActionRecordType>,
  status?: PpeAction,
) => {
  const actions = Object.values(allActions)
  const actionsMatchingRequests = actions.filter(
    (action) => action.rawData.requestId && requestSks.includes(action.rawData.requestId),
  )
  if (status) {
    const relatedActionsSks = actionsMatchingRequests.map((action) => action.sk)
    const actionsRelatedToRequests = pick(allActions, relatedActionsSks)
    return actionsRelatedToRequests
  }
  const relatedActionsSks = actionsMatchingRequests.map((action) => action.sk)
  const actionsRelatedToRequests = pick(allActions, relatedActionsSks)
  return actionsRelatedToRequests
}

export const getInstancesRelatedToRequests = (requestSks: string[], allInstances: Record<PpeRecordSk, PpeInstance>) => {
  const instances = Object.values(allInstances)
  const instancesMatchingRequests = instances.filter((instance) => {
    return intersection(requestSks, instance.requestHistory).length > 0
  })
  const relatedInstancesSks = instancesMatchingRequests.map((instance) => instance.sk)
  const instancesRelatedToRequests = pick(allInstances, relatedInstancesSks)
  return instancesRelatedToRequests
}

export const getActionRelatedToInstance = (
  instanceSk: string,
  allActions: Record<PpeRecordSk, PpeActionRecordType>,
  status?: PpeAction,
) => {
  const actions = Object.values(allActions)
  const actionsMatchingInstance = actions.filter((action) => action.rawData.ppeInstanceId === instanceSk)
  if (status) {
    const actionsMatchingStatus = actionsMatchingInstance.filter(
      (action: PpeActionRecordType) => action.rawData.ppeAction === status,
    )
    return actionsMatchingStatus[0]
  }
  return actionsMatchingInstance[0]
}

export const createActionRecord = (obj: any): Partial<PpeActionRecordType> => {
  const data: Pick<PpeActionRecordType, 'rawData'> = {
    rawData: obj,
  }
  return data
}

export const groupInstanceItemsByKey = (allItems: PpeInstance[], key: string) => {
  const grouped = allItems.reduce((acc, item: PpeInstance) => {
    if (!acc[item[key]]) {
      acc[item[key]] = []
    }
    acc[item[key]].push(item)
    return acc
  }, {} as any)
  return grouped
}

export const getLatestActionByInstanceId = (actions: PpeActionRecordType[]) => {
  const grouped = groupActionsByInstanceId(actions)
  const latestActionByInstanceId = Object.keys(grouped).reduce((acc, instanceId) => {
    const instanceActions = grouped[instanceId]
    const latestAction = getLatestAction(instanceActions)
    acc[instanceId] = latestAction
    return acc
  }, {} as any)
  return latestActionByInstanceId
}

export const groupActionsByInstanceId = (actionsWithInstanceId: PpeActionRecordType[]) => {
  const actions = actionsWithInstanceId
  const grouped = actions.reduce((acc, item: PpeActionRecordType) => {
    const instanceId = item.rawData.ppeInstanceId
    // @ts-expect-error
    if (!acc[instanceId]) {
      // @ts-expect-error
      acc[instanceId] = []
    }
    // @ts-expect-error
    acc[instanceId].push(item)
    return acc
  }, {} as any)
  return grouped
}

export const getInstancesByPpeKeys = (instances: PpeInstance[], ppeKeys: string[]) => {
  return instances.filter((instance) => ppeKeys.includes(instance.ppeKey))
}

export const getInstanceBySk = (allInstances: PpeInstance[], instanceSk: string) => {
  return allInstances.find((instance) => instance.sk === instanceSk)
}

export const getNumberOfReservedItems = (ppeItems: PpeInstance[]) =>
  ppeItems.filter((item: PpeInstance) =>
    [PpeItemAvailabilityStatus.RESERVED, PpeItemAvailabilityStatus.ISSUED].includes(item.availability),
  ).length

export const getStockDetailDisplayData = (
  groupedItems: Record<string, PpeInstance[]>,
  groupingKey: string,
  groupingValue: string,
  types: PpeType[],
  sku?: string,
) => {
  const items = groupedItems[groupingValue]
  const { ppeKey } = items[0]
  const totalNumberOfItems = items.length
  const reservedItems = getNumberOfReservedItems(items)
  const typeLabel = types.find((type) => type.key === ppeKey)?.label

  return {
    item: typeLabel || 'Unknown',
    size: groupingKey === 'ppeSize' ? groupingValue : '',
    sku: groupingKey === 'sku' ? groupingValue : sku || '',
    total: totalNumberOfItems,
    booked: reservedItems,
    // mismatched: 0, // TODO: implement mismatched items..?
  }
}

export const getPpeInPossession = (
  profileRepo: ProfileRepository,
  profilePk: ProfilePk,
  ppeKeysToCheck?: string[],
  instances?: PpeInstance[],
): Record<PpeInstanceRecordSk, PpeActionRecordType>[] => {
  let ppeInPossessionBySk: Record<PpeInstanceRecordSk, PpeActionRecordType>[] = []
  // let ppeReturnedBySk: Record<PpeInstanceRecordSk, PpeActionRecordType>[] = []

  const profile = profileRepo.getProfile(profilePk)
  const ppe: any = profile?.getPpe()
  const actions = ppe?.history || []

  if (actions.length === 0) {
    return [] // or return number rather...?
  }

  let instanceIdsToConsider: string[] = []
  if (ppeKeysToCheck && ppeKeysToCheck.length > 0 && instances) {
    const instancesToConsider = getInstancesByPpeKeys(instances, ppeKeysToCheck)
    instanceIdsToConsider = instancesToConsider.map((instance) => instance.sk)
  }

  const actionsIssued = actions.filter(
    (action: PpeActionRecordType) => action.rawData.ppeAction === PpeActions.ISSUE_PPE_ITEM,
  )
  const actionsReturn = actions.filter(
    (action: PpeActionRecordType) => action.rawData.ppeAction === PpeActions.INITIATE_RETURN_INSTANCE,
  )
  const latestIssueActionByInstanceId = getLatestActionByInstanceId(actionsIssued)
  const latestReturnActionByInstanceId = getLatestActionByInstanceId(actionsReturn)

  Object.keys(latestIssueActionByInstanceId).forEach((instanceId) => {
    const latestIssueAction = latestIssueActionByInstanceId[instanceId]
    const latestReturnAction = latestReturnActionByInstanceId[instanceId]

    if (latestIssueAction && !latestReturnAction) {
      if (instanceIdsToConsider.length === 0) {
        ppeInPossessionBySk.push({ [instanceId]: latestIssueAction })
        return
      }

      if (instanceIdsToConsider.includes(instanceId)) {
        ppeInPossessionBySk.push({ [instanceId]: latestIssueAction })
        return
      }
    }
    if (latestIssueAction && latestReturnAction) {
      const issueDate = latestIssueAction.rawData.dateActioned
      const returnDate = latestReturnAction.rawData.dateActioned
      if (issueDate > returnDate) {
        if (instanceIdsToConsider.length === 0) {
          ppeInPossessionBySk.push({ [instanceId]: latestIssueAction })
          return
        }

        if (instanceIdsToConsider.includes(instanceId)) {
          ppeInPossessionBySk.push({ [instanceId]: latestIssueAction })
          return
        }
      }
    }
  })
  return ppeInPossessionBySk
}

export const getAllSupplierOptions = (suppliers: PpeSuppliers) => {
  const allSupplierSizingOptions = Object.values(suppliers).map((supplier) => supplier.supplierSizeOptions)
  const selectorItems = [...new Set(allSupplierSizingOptions.flat())]
  return selectorItems
}

export const preparePpeTypeUpsertPayload = (
  item: PpeTypeUpsert & { sku: string },
  selectedAssociation: string,
  associationRepo: AssociationSettingsRepository,
) => {
  const fieldConfig = associationRepo.getFieldConfig(selectedAssociation)
  const existingType = fieldConfig[item.key] as PpeType | undefined

  if (existingType && existingType.isPpe) {
    const skuExistsAlready = existingType.skus?.includes(item.sku)
    item.suppliers = existingType.suppliers ? cloneDeep(existingType.suppliers) : {}
    if (skuExistsAlready) {
      item.skus = existingType.skus
      item.suppliers[item.sku] = {
        bookValue: item.bookValue as string,
        bookValueCurrency: 'ZAR',
        supplier: item.supplier as string,
        supplierSizeOptions: item.selectorItems as string[],
      }
    } else {
      item.skus = [...new Set([...existingType.skus, item.sku])]
    }
  } else {
    item.skus = [item.sku]
    item.suppliers = {}
  }

  item.suppliers[item.sku] = {
    bookValue: item.bookValue as string,
    bookValueCurrency: 'ZAR',
    supplier: item.supplier as string,
    supplierSizeOptions: item.selectorItems as string[],
  }

  item.selectorItems = getAllSupplierOptions(item.suppliers)

  const payload = omit(item, ['sku']) // 'sku' was added to aid payload-compiling logic, but it's not a field in the PpeType model
  return payload
}

export const getFieldConfigExclPpeItems = (
  associationRepo: AssociationSettingsRepository,
  selectedAssociation: string,
) => {
  const fieldConfig = associationRepo.getFieldConfig(selectedAssociation)
  const fieldConfigExclPpeItems = { ...fieldConfig }
  Object.values(fieldConfigExclPpeItems).forEach((field: any) => {
    if (field.isPpe) {
      delete fieldConfigExclPpeItems[field.key]
    }
  })
  return fieldConfigExclPpeItems
}
