import { QueryResults } from '../types'

export const getUniqueQueryResults = (queryResults: QueryResults) => {
  if (!queryResults.results) {
    return queryResults
  }
  const uniqueResults = queryResults.results.filter(
    (result, index, self) => index === self.findIndex((t) => t.profilePk === result.profilePk),
  )
  return {
    ...queryResults,
    results: uniqueResults,
  }
}

export const removeEmptyProfilePkQueryResults = (queryResults: QueryResults) => {
  if (!queryResults.results) {
    return queryResults
  }
  const nonEmptyResults = queryResults.results.filter((result) => result.profilePk !== '')
  return { ...queryResults, results: nonEmptyResults }
}
