import { ColorPalette } from '../../config/colors'
import { ActionType } from '../../store/actions/actions'
import { AllScreenNames } from '../../config'
import { IClientRequests, IClientRequestInstance, IRequestInstanceConfig } from '../../models/clientRequests'

import React, { Component } from 'react'
import Radium from 'radium'
import { connect } from 'react-redux'
import { createStyles, withStyles } from '@material-ui/core/styles'

import Grid, { GridSize } from '@material-ui/core/Grid'

import DataTable from '../../components/Tables/DataTable/DataTable'
import NavigationBar from '../../components/Navigation/NavigationBar'
import SectionHeader, { SectionHeaderComponent } from '../../components/Headings/SectionHeaderPrimary'
import LoadingModal from '../../components/Modals/LoadingModal'
import AlertModalOneButton from '../../components/Modals/AlertModalOneButton'
import SideMenu from '../../components/Navigation/SideMenu'
import ButtonGeneric from '../../components/BaseComponents/Buttons/ButtonGeneric'
import OptionsSelectorModal from '../../components/Modals/OptionsSelectorModal'
import { PickerHandlerEvent } from '../../components/BaseComponents/Pickers/Picker'
import { RequestServiceFactory } from '../../services'
import { RouteComponentProps } from 'react-router'
import { PeopleFlowCombinedReducer } from '../../store'
import { Property } from 'csstype'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { AssociationSettingsRepository, UserRepository } from '../../repositories'

dayjs.extend(relativeTime)

const columnConfig = [
  { id: 'createdAt', label: 'Date' },
  { id: 'eventName', label: 'Event' },
  { id: 'currentStep', label: 'Current step' },
]

interface RequestHandlerProps extends RouteComponentProps {
  allRequests: IClientRequests
  associationRepo: AssociationSettingsRepository
  userRepo: UserRepository
  selectedAssociation: string
  idPassport: string
  selectedRequest: IClientRequestInstance
  currentScreen: AllScreenNames
  currentSection: AllScreenNames
  classes: Record<string, string>
  hasWorkforceAccess: boolean
  hasRequestHandlerAccess: boolean
  hasUserManagementAccess: boolean

  updateState: (data: any) => void
  changeScreen: (screen: string) => void
}

interface RequestHandlerState {
  loadingModalOpen: boolean
  warningModalOpen: boolean
  loadingModalMessage: string
  warningModalHeader: string
  warningModalMessage: string
  sideMenuComponents: JSX.Element
  downloading: boolean
  sideMenuVisible: boolean
  requestSelectorModalOpen: boolean
  selectedRequests: IClientRequestInstance[]
  requestRenderData: Record<string, any>[]
  allRequestLabels: string[]
  selectedRequestLabel: string

  authorisedItemIds: string[]
  tableWidth: GridSize
  selectedRowItemId: string
  searchString: string
  selectedDocConfig: any
  allClientUsers: any
}

class RequestHandler extends Component<RequestHandlerProps, RequestHandlerState> {
  sectionHeaderRef: React.RefObject<SectionHeaderComponent>
  primaryTableRef: React.RefObject<DataTable>

  constructor(props: RequestHandlerProps) {
    super(props)
    this.sectionHeaderRef = React.createRef()
    this.primaryTableRef = React.createRef()
  }

  RequestService = RequestServiceFactory.create()

  initialModalState = {
    loadingModalOpen: false,
    warningModalOpen: false,
    requestSelectorModalOpen: false,
  }

  state: RequestHandlerState = {
    ...this.initialModalState,
    loadingModalMessage: '',
    warningModalHeader: '',
    warningModalMessage: '',
    selectedRequestLabel: '',
    sideMenuComponents: <div />,
    downloading: false,
    sideMenuVisible: false,
    authorisedItemIds: [],
    tableWidth: 10,
    selectedRowItemId: '',
    searchString: '',
    selectedDocConfig: {},
    allClientUsers: [],
    allRequestLabels: [],
    selectedRequests: [],
    requestRenderData: [],
  }

  async componentDidMount() {
    try {
      console.log('currentScreen: ', this.props.currentScreen)
      console.log('currentSection: ', this.props.currentSection)

      this.remount()
    } catch (error: any) {
      if (
        error !== undefined &&
        error.code &&
        (error.code === 'NoClientSettings' ||
          error.code === 'NoClientSettingsForSelectedClient' ||
          error.code === 'NoClientSettingsInitialised')
      ) {
        setTimeout(() => this.props.history.replace({ pathname: 'login' }), 3000)
      }
      this.displayWarning(error)
    }
  }

  componentDidUpdate(prevProps: RequestHandlerProps) {
    const screenChanged = prevProps.currentSection !== this.props.currentSection
    if (screenChanged) {
      this.remount()
    }
  }

  remount = async () => {
    this.restoreCachedRequestConfig()
    const { allRequests, selectedRequests, requestRenderData, allRequestLabels } = await this.initialiseRequests()
    this.props.updateState({ allRequests })
    this.setState(
      {
        selectedRequests,
        requestRenderData,
        allRequestLabels,
      },
      () => this.reloadTable(),
    )
  }

  closeModals() {
    this.setState({ ...this.initialModalState })
  }

  generateAllRequestLabel = (allRequestConfig: Record<string, IRequestInstanceConfig>) => {
    return Object.keys(allRequestConfig).map((requestName) => allRequestConfig[requestName].getRequestName())
  }

  restoreCachedRequestConfig(): void {
    const { selectedAssociation, associationRepo } = this.props
    const requestConfig = associationRepo.getRequestConfig(selectedAssociation)
    if (requestConfig !== undefined) {
      const allRequestLabels = this.generateAllRequestLabel(requestConfig)
      this.setState({ allRequestLabels })
    }
  }

  async initialiseRequests(): Promise<any> {
    this.setState({ downloading: true })
    const { selectedAssociation, currentSection, associationRepo } = this.props
    // const allRequests = await this.RequestService.fetchRequestBatch(selectedAssociation)
    const allRequests = this.props.allRequests
    const requestConfig = await this.RequestService.fetchRequestConfig(selectedAssociation)
    const { selectedRequests, requestRenderData } = this.getSelectedRequests(allRequests, currentSection)
    const allRequestLabels = this.generateAllRequestLabel(requestConfig)
    associationRepo.setRequestConfig(selectedAssociation, requestConfig)
    this.setState({ downloading: false })
    return { allRequests, selectedRequests, requestRenderData, allRequestLabels }
  }

  getSelectedRequests(
    allRequests: IClientRequests,
    currentSection: AllScreenNames,
  ): { selectedRequests: IClientRequestInstance[]; requestRenderData: Record<string, string>[] } {
    let selectedRequests
    if (currentSection === AllScreenNames.OPEN_REQUESTS) {
      selectedRequests = allRequests.getActiveRequests()
    } else {
      selectedRequests = allRequests.getClosedRequests()
    }
    const requestRenderData = this.generateRequestRenderData(selectedRequests)
    return { selectedRequests, requestRenderData }
  }

  generateRequestRenderData(selectedRequests: IClientRequestInstance[]): Record<string, string>[] {
    const requestRenderData = selectedRequests.map((requestInstance) => {
      return {
        createdAt: dayjs.unix(requestInstance.getCreationTime() / 1000).format('YYYY-MM-DD, HH:mm'),
        eventName: requestInstance.getRequestName(),
        currentStep: requestInstance.getCurrentStepLabel(),
        id: requestInstance.getRequestId(),
      }
    })
    return requestRenderData
  }

  searchHandler(event: React.ChangeEvent<{ value: string }>) {
    this.setState({ searchString: event.target.value })
    this.primaryTableRef?.current?.search(event.target.value)
  }

  createRequest = () => {
    const { associationRepo, userRepo, selectedAssociation } = this.props
    const { selectedRequestLabel } = this.state
    const user = userRepo.getCurrentUserEntity()
    const { name, surname } = user.getPersonalUserInfo()
    const selectedRequestConfig = associationRepo.getSelectedRequestConfig(selectedAssociation, selectedRequestLabel) // Create a new type for all request config
    if (selectedRequestConfig === undefined) {
      return
    }

    const selectedRequest = this.RequestService.createNewRequestInstance(selectedRequestConfig, `${name} ${surname}`)
    this.props.updateState({ selectedRequest })
    this.props.history.replace({ pathname: `requestmanager` })
  }

  selectRequest(rowItemId: string) {
    const selectedRequest: IClientRequestInstance = this.props.allRequests.getRequestById(rowItemId)
    this.props.updateState({ selectedRequest })
    this.props.history.replace({ pathname: `requestmanager` })
  }

  reloadTable = () => {
    if (this.primaryTableRef && this.primaryTableRef.current) {
      const { requestRenderData } = this.state
      const authorisedItemIds: string[] = [...requestRenderData].map((item) => item.id)
      this.setState({ authorisedItemIds, requestRenderData }, () => this.primaryTableRef?.current?.reload())
    } else if (this.props.currentScreen === AllScreenNames.REQUEST_HANDLER) {
      setTimeout(this.reloadTable, 250)
    }
  }

  requestPicker = (event: PickerHandlerEvent) => {
    this.setState({ selectedRequestLabel: event.target.value })
  }

  displayWarning(error: any) {
    let warning = ''

    try {
      if (error.code === 'NetworkError') {
        warning = 'Seems like your internet connection is down. Reconnect to the network, then try again.'
      } else if (error.code === 'LocalRequestInstanceCreationError') {
        warning =
          'There was a problem initiating this request. Close PeopleFlow, then login again. If there is still a problem then contact tech support for assistance.'
      } else if (
        error.code === 'NoClientSettings' ||
        error.code === 'NoClientSettingsForSelectedClient' ||
        error.code === 'NoClientSettingsInitialised'
      ) {
        warning = 'You will need to login again to continue'
      } else if ('message' in error) {
        warning =
          "The following error message was returned:\n\n'" +
          error.message +
          "'. \n\nRefresh the page and try again. If unsuccessful, then contact tech support"
      } else {
        warning = 'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
      }
    } catch (error) {
      warning = 'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
    }

    this.setState({
      ...this.initialModalState,
      warningModalOpen: true,
      warningModalHeader: 'Warning',
      warningModalMessage: warning,
    })
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.container}>
        <NavigationBar
          match={this.props.match}
          location={this.props.location}
          history={this.props.history}
          reloadPageData={() => this.reloadTable()}
        />
        <SectionHeader
          ref={this.sectionHeaderRef}
          style={{ marginTop: 25 }}
          downloading={this.state.downloading}
          searchString={this.state.searchString}
          textHandler={(e) => this.searchHandler(e)}
          onClick={() => this.setState({ sideMenuVisible: false, tableWidth: 10 })}>
          {this.props.currentSection}
        </SectionHeader>

        <SideMenu visible={this.state.sideMenuVisible} menuComponents={this.state.sideMenuComponents} />

        <div
          style={{
            width: '100%',
            alignSelf: 'center',
            marginLeft: this.state.sideMenuVisible ? window.innerWidth * 0.46 : window.innerWidth * 0.16,
            animation: this.state.sideMenuVisible ? 'x 0.15s ease-in' : 'x 0.15s ease-out',
            animationName: this.state.sideMenuVisible ? fadeIn : fadeOut,
          }}>
          <ButtonGeneric
            style={{
              ...getStyle('newFormButton'),
              width: (window.innerWidth * (this.state.tableWidth as number)) / 12,
            }}
            label={'+ NEW REQUEST'}
            onClick={() => this.setState({ sideMenuVisible: false, requestSelectorModalOpen: true })}
          />
          <Grid
            className={classes.outerCard}
            item
            container
            direction="column"
            justify="flex-start"
            alignItems="center"
            xs={this.state.tableWidth}>
            <DataTable
              ref={this.primaryTableRef}
              tableData={this.state.requestRenderData}
              columnConfig={columnConfig}
              tableWidth={this.state.tableWidth}
              onRowClick={(rowData: Record<string, any>) => this.selectRequest(rowData.id)}
              selectedRowItemId={this.state.selectedRowItemId}
              authorisedItemIds={this.state.authorisedItemIds}
            />
          </Grid>
        </div>

        <OptionsSelectorModal
          open={this.state.requestSelectorModalOpen}
          onSuccess={() => this.createRequest()}
          onReject={() => this.closeModals()}
          successLabel={'Start'}
          cancelLabel={'Cancel'}
          label1={'Request type'}
          options1={this.state.allRequestLabels}
          optionsName1={'selectedRequestLabel'}
          selectedOption1={this.state.selectedRequestLabel}
          onChange={(event) => this.requestPicker(event)}
        />

        <AlertModalOneButton
          open={this.state.warningModalOpen}
          header={this.state.warningModalHeader}
          body={this.state.warningModalMessage}
          buttonLabel={'Ok'}
          onClick={() => this.closeModals()}
        />

        <AlertModalOneButton
          open={!this.props.hasRequestHandlerAccess}
          header={'Not Authorised'}
          body={"You don't have permission to view requests"}
          buttonLabel={'Ok'}
          opaqueBackground={true}
          onClick={() => this.props.history.goBack()}
        />
        <LoadingModal open={this.state.loadingModalOpen}>{this.state.loadingModalMessage}</LoadingModal>
      </div>
    )
  }
}

const hidden = {
  marginLeft: window.innerWidth * 0.16,
}

const visible = {
  marginLeft: window.innerWidth * 0.47,
}

const fadeOut = Radium.keyframes({
  '0%': visible,
  '100%': hidden,
}) as Property.AnimationName

const fadeIn = Radium.keyframes({
  '0%': hidden,
  '100%': visible,
}) as Property.AnimationName

const styles = createStyles({
  container: {
    minHeight: window.innerHeight,
    maxHeight: window.innerHeight,
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255, 1), rgba(209,210,230, 1))',
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  outerCard: {
    zIndex: 1,
    alignSelf: 'center',
    backgroundColor: ColorPalette.CARD_WHITE,
    minHeight: window.innerHeight * 0.76,
    marginTop: window.innerHeight * 0,
    boxShadow: '0px 3px 10px rgba(60,60,60, 0.1)',
  },
  tableName: {
    marginTop: window.innerHeight * 0.055,
    height: window.innerHeight * 0.7,
    width: '94%',
  },
  newFormButton: {
    marginTop: window.innerHeight * 0.048,
    fontWeight: 'bolder',
    fontSize: '0.8rem',
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    ':hover': {
      color: ColorPalette.PRIMARY_BLUE,
    },
    ':active': {
      color: ColorPalette.DARK_GREY,
    },
  },
})

function getStyle(
  key: 'container' | 'outerCard' | 'tableName' | 'newFormButton',
): React.CSSProperties | Record<string, React.CSSProperties> | undefined {
  const x = styles[key]
  if (!x) {
    return undefined
  }
  return x as React.CSSProperties | Record<string, React.CSSProperties>
}

const mapStateToProps = (state: PeopleFlowCombinedReducer) => {
  return {
    idPassport: state.sessionManager.idPassport,
    associationRepo: state.sessionManager.associationRepo as AssociationSettingsRepository,
    userRepo: state.sessionManager.userRepo as UserRepository,
    allRequests: state.sessionManager.allRequests,
    selectedAssociation: state.sessionManager.selectedAssociation,
    currentSection: state.sessionManager.currentSection,
    currentScreen: state.sessionManager.currentScreen,
    selectedFormIndex: state.sessionManager.selectedFormIndex,
    selectedRequest: state.sessionManager.selectedRequest,
    hasWorkforceAccess: state.sessionManager.hasWorkforceAccess,
    hasRequestHandlerAccess: state.sessionManager.hasRequestHandlerAccess,
    hasUserManagementAccess: state.sessionManager.hasUserManagementAccess,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateState: (data: any) => dispatch({ type: ActionType.UPDATE_STATE, data }),
    changeScreen: (screen: string) => dispatch({ type: ActionType.CHANGE_SCREEN, data: { currentScreen: screen } }),
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(Radium(RequestHandler)),
)
