import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import Radium from 'radium'

import { mdiArrowRightBoldCircle, mdiInformation, mdiArchiveCheck, mdiDownload } from '@mdi/js'

import DataTable from '../../components/Tables/DataTable/DataTable'
import NavigationBar from '../../components/Navigation/NavigationBar'
import SectionHeader from '../../components/Headings/SectionHeaderPrimary'
import LoadingModal from '../../components/Modals/LoadingModal'
import { ColorPalette } from '../../config/colors'
import { ActionType } from '../../store/actions/actions'
import { PeopleFlowCombinedReducer } from '../../store'
import { CommonTs } from '../../models/common-ts/commonts'
import { Toolbar } from '../../components/GeneralUI/Toolbar/Toolbar'
import ProcessTerminations from './ProcessTerminations'
import Icon from '@mdi/react'
import { removeUnderscores, toSentenceCase } from '../../utils'
import { CsvService } from '../../services'
import { ActionButtonType, NavButton } from '../../components/GeneralUI/Toolbar'

const columnConfig = [
  { id: 'id', label: 'ID / Passport', sizeFactor: 1 },
  { id: 'firstName', label: 'Name', sizeFactor: 1 },
  { id: 'surname', label: 'Surname', sizeFactor: 1 },
  { id: 'intendedChange', label: 'Intended Change', sizeFactor: 1 },
  { id: 'terminationDate', label: 'Date', sizeFactor: 1 },
]

interface BulkTerminationsProps extends RouteComponentProps {
  selectedAssociation: string
}

type RouteState = {
  records: CommonTs.Import.IImportPlan[]
  csvFileName: string
  idPassportsNotFound: string[]
}
type ProgressState = 'PLAN_RESULTS_SELECT' | 'APPLY_RESULTS'

interface BulkTerminationsState {
  progressState: ProgressState
  loadingModalOpen: boolean
  loadingModalMessage: string
  records: CommonTs.Import.IImportPlan[]
  applyResults: any[]
  csvFileName: string
  idPassportsNotFound: string[]
  searchString: string
  selectedRowItemId: string
  terminationsProcessed: boolean
}

class BulkTerminations extends Component<BulkTerminationsProps, BulkTerminationsState> {
  sectionHeaderRef: React.RefObject<any>
  primaryTableRef: React.RefObject<DataTable>

  constructor(props: BulkTerminationsProps) {
    super(props)
    this.sectionHeaderRef = React.createRef()
    this.primaryTableRef = React.createRef()
  }

  initialModalState = {
    loadingModalOpen: false,
  }

  state: BulkTerminationsState = {
    ...this.initialModalState,
    progressState: 'PLAN_RESULTS_SELECT',
    loadingModalMessage: '',
    records: (this.props.location.state as RouteState).records,
    applyResults: [],
    searchString: '',
    selectedRowItemId: '',
    csvFileName: (this.props.location.state as RouteState).csvFileName,
    idPassportsNotFound: (this.props.location.state as RouteState).idPassportsNotFound,
    terminationsProcessed: false,
  }

  nextState(progressState: ProgressState): ProgressState {
    switch (progressState) {
      case 'PLAN_RESULTS_SELECT':
        return 'APPLY_RESULTS'
      case 'APPLY_RESULTS':
        return 'APPLY_RESULTS'
    }
  }

  previousState(progressState: ProgressState): ProgressState {
    switch (progressState) {
      case 'PLAN_RESULTS_SELECT':
        return 'PLAN_RESULTS_SELECT'
      case 'APPLY_RESULTS':
        return 'PLAN_RESULTS_SELECT'
    }
  }

  nextLabel(progressState: ProgressState): string {
    switch (progressState) {
      case 'PLAN_RESULTS_SELECT':
        return 'PROCESS TERMINATION'
      case 'APPLY_RESULTS':
        return 'DONE'
    }
  }

  closeModals() {
    this.setState({ ...this.initialModalState })
  }

  searchHandler(event: React.ChangeEvent<{ value: string }>) {
    this.setState({ searchString: event.target.value })
    if (!this.primaryTableRef?.current) {
      return
    }
    this.primaryTableRef.current.search(event.target.value)
  }

  getSelectForProcessingTableData() {
    return this.state.records.map(({ current, problems, record }: CommonTs.Import.IImportPlan) => {
      let blockingProblemsFound = problems.length > 0
      let warningMessage = blockingProblemsFound
        ? `${toSentenceCase(problems[0].key)}; ${removeUnderscores(problems[0].reason)}`
        : ''

      const problemWithExistingTerminationDateIndex = problems.findIndex(
        (problem: any) => problem.key === 'employmentStatus' && problem.reason === 'CORRUPT_EXISTING_TERMINATION_STATE',
      )
      if (problemWithExistingTerminationDateIndex > -1) {
        blockingProblemsFound = problems.length > 1 // ignoring the existing termination date problem
        if (!blockingProblemsFound) {
          warningMessage = 'Record with existing termination date which will be overwritten.'
        } else {
          warningMessage = `${toSentenceCase(problems[1].key)}; ${removeUnderscores(problems[1].reason)}`
        }
      }

      const idPassportProblem = problems.find((problem: any) => problem.key === 'idPassport')
      if (!current || idPassportProblem) {
        if (idPassportProblem && idPassportProblem.reason === 'PROFILE_DOES_NOT_EXIST') {
          warningMessage = 'Unknown ID/Passport; profile does not exist in the system.'
        } else {
          warningMessage = `${problems[0].key}; ${removeUnderscores(problems[0].reason)}`
        }
        return {
          id: record.generalData.idPassport,
          firstName: '',
          surname: '',
          disableSelect: blockingProblemsFound,
          onHoverMessage: warningMessage || null,
        }
      }

      return {
        id: current.generalData.idPassport,
        firstName: current.generalData.name,
        surname: current.generalData.surname,
        intendedChange: 'Employee > Candidate',
        terminationDate: record.generalData.terminationDate,
        disableSelect: blockingProblemsFound,
        onHoverMessage: problems.length > 0 ? warningMessage : null,
        rightComponent:
          problems.length > 0 ? (
            <div style={styles.infoIcon}>
              <Icon path={mdiInformation} color={ColorPalette.OFF_WHITE} size={0.75} />
            </div>
          ) : (
            <div style={styles.noInfoIcon}></div>
          ),
      }
    })
  }

  getTerminationChangesToApply(validSelectionIds: string[]) {
    return validSelectionIds.flatMap((idPassport: string) => {
      return this.state.records
        .filter((r) => {
          return r.current && r.current.generalData.idPassport === idPassport
        })
        .map((r) => {
          return {
            idPassport,
            id: r.current?.idPassport,
            firstName: r.current?.generalData.name || '',
            surname: r.current?.generalData.surname || '',
            intendedChange: 'Employee > Candidate',
            terminationDate: r.current?.generalData.terminationDate || '',
            uid: r.current?.uid || '',
            patchWithBefore: r.patchWithBefore || [],
          }
        })
    })
  }

  generateAndDownloadListOfUnknownIds = async () => {
    const { idPassportsNotFound } = this.state
    if (idPassportsNotFound) {
      const data = idPassportsNotFound.join('\n')
      CsvService.downloadCsvFile(data, 'terminate_unknowns_ids.csv')
    }
  }

  getActionButtons = () => {
    const actionButtons: ActionButtonType[] = []
    const nextButtonLabel: string = this.nextLabel(this.state.progressState)

    if (nextButtonLabel === 'DONE') {
      if (this.state.terminationsProcessed) {
        actionButtons.push({
          iconPath: mdiArchiveCheck,
          label: nextButtonLabel,
          onClick: () => {
            this.setState({ terminationsProcessed: false }, () => {
              this.props.history.push('/people/employees')
            })
          },
        })
      }
    } else {
      if (this.state.idPassportsNotFound && this.state.idPassportsNotFound.length > 0) {
        actionButtons.push({
          iconPath: mdiDownload,
          label: 'DOWNLOAD UNKNOWN ID/PASSPORTS',
          title: 'Download list of ID/Passports not found in the system',
          onClick: () => this.generateAndDownloadListOfUnknownIds(),
        })
      }
      actionButtons.push({
        iconPath: mdiArrowRightBoldCircle,
        label: nextButtonLabel,
        onClick: () => {
          const progressState = this.nextState(this.state.progressState)
          this.setState({ progressState })
        },
      })
    }

    return actionButtons
  }

  handleBackNavButtonClick = () => {
    const progressState = this.previousState(this.state.progressState)
    this.setState({ progressState })
    this.props.history.push('/people/employees', {
      // @ts-ignore
      openTerminationModal: this.props.history.location.state?.via === 'bulkActions', // trigger termination file upload if we came via bulk actions
    })
  }

  render() {
    const selectForProcessingTableData = this.getSelectForProcessingTableData()
    const validSelectionIds = selectForProcessingTableData
      .filter((row) => row.disableSelect === false)
      .map((row) => row.id)
    const terminationChangesToApply = this.getTerminationChangesToApply(validSelectionIds)
    const actionButtons = this.getActionButtons()

    return (
      <div style={{ ...styles.container }}>
        <NavigationBar
          match={this.props.match}
          location={this.props.location}
          history={this.props.history}
          primaryTableRef={this.primaryTableRef} // destined for SubMenu via prop drilling
        />
        <SectionHeader
          ref={this.sectionHeaderRef}
          style={styles.sectionHeader}
          downloading={false}
          searchString={this.state.searchString}
          textHandler={(e) => this.searchHandler(e)}
          onClick={() => null}>
          Bulk Terminations
        </SectionHeader>

        <div style={styles.contentContainer}>
          <div style={{ ...styles.main, paddingInline: 'min(5em, 5%)' }}>
            <Toolbar
              actionButtons={actionButtons}
              navButtons={{
                left:
                  this.state.progressState === 'APPLY_RESULTS' ? undefined : { onClick: this.handleBackNavButtonClick },
              }}
            />
            <div style={styles.mainContent}>
              {this.state.progressState === 'PLAN_RESULTS_SELECT' && (
                <DataTable
                  ref={this.primaryTableRef}
                  tableData={selectForProcessingTableData}
                  columnConfig={columnConfig}
                  tableWidth={10}
                  filterState={undefined}
                  onRowClick={(rowData: Record<string, any>) => this.setState({ selectedRowItemId: rowData.id })}
                  selectedRowItemId={this.state.selectedRowItemId}
                  authorisedItemIds={[]}
                  customFilterConfig={[]}
                  filterModeEnabled={false}
                  isSelectionEnabled={false}
                  disabled={true}
                  customComponentConfig={{ rightComponentWidth: 40 }}
                  initialSelectedRows={validSelectionIds}
                />
              )}
              {this.state.progressState === 'APPLY_RESULTS' && (
                <ProcessTerminations
                  terminationChangesToApply={terminationChangesToApply}
                  onChangesApplied={() => this.setState({ terminationsProcessed: true })}
                />
              )}
            </div>
          </div>
        </div>
        <LoadingModal open={this.state.loadingModalOpen}>{this.state.loadingModalMessage}</LoadingModal>
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255, 1), rgba(209,210,230, 1))',
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto 1.5%',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
  main: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    width: '100%',
    overflow: 'hidden',
  },
  mainContent: {
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    display: 'flex',
    flex: 1,
    backgroundColor: ColorPalette.CARD_WHITE,
    overflow: 'auto',
  },
  tableNavButtonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  toolbarButton: {
    width: 180,
    fontWeight: 'bolder' as 'bolder',
    fontSize: '0.8rem',
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    ':hover': {
      color: ColorPalette.PRIMARY_BLUE,
    },
    ':active': {
      color: ColorPalette.DARK_GREY,
    },
  },
  infoIcon: {
    backgroundColor: 'rgba(220, 20, 60, 0.25)',
    width: 40,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noInfoIcon: {
    borderBottom: `1px solid ${ColorPalette.VERY_LIGHT_GREY}`,
    width: 40,
    height: '100%',
  },
}

const mapStateToProps = (state: PeopleFlowCombinedReducer) => {
  return {
    idPassport: state.sessionManager.idPassport,
    selectedAssociation: state.sessionManager.selectedAssociation,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateState: (data: any) => dispatch({ type: ActionType.UPDATE_STATE, data }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Radium(BulkTerminations))
