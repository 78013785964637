import Icon from '@mdi/react'
import ButtonGeneric from '../../BaseComponents/Buttons/ButtonGeneric'
import { ColorPalette } from '../../../config'

export type ActionButtonType = {
  iconPath: string
  iconColor?: string
  iconSpin?: boolean
  iconStyle?: React.CSSProperties
  label: string
  title?: string
  disabled?: boolean
  labelStyle?: React.CSSProperties
  style?: React.CSSProperties
  onClick: () => void | Promise<void>
}

export const ActionButton = (props: ActionButtonType) => {
  const { iconPath, iconColor, iconSpin, iconStyle, label, title, disabled, labelStyle, style, onClick } = props
  return (
    <ButtonGeneric
      iconBefore={
        <Icon
          style={{ width: '1.25rem', marginRight: '0.5em', ...iconStyle }}
          path={iconPath}
          spin={iconSpin}
          color={iconColor || ColorPalette.PRIMARY_BLUE}
        />
      }
      onClick={onClick}
      label={label}
      labelStyle={{ color: ColorPalette.SECONDARY_TEXT, ...labelStyle }}
      style={{
        width: 'max-content',
        fontSize: '0.8rem',
        cursor: disabled ? 'not-allowed' : 'pointer',
        ...style,
      }}
      title={title}
      disabled={disabled}
    />
  )
}
