import { Dialog } from '@material-ui/core'
import { useState } from 'react'
import { ColorPalette } from '../../config'
import CalendarRange from '../BaseComponents/Calendar/CalendarRange'
import ButtonBlue from '../BaseComponents/Buttons/ButtonBlue'
import ButtonGrey from '../BaseComponents/Buttons/ButtonGrey'

type DateRangeModalProps = {
  open: boolean
  title: string
  initialStartDate: Date
  initialExpiryDate: Date
  dateFormat?: string
  dateRangeSelectorOrientation?: 'horizontal' | 'vertical'
  minDate?: Date
  maxDate?: Date
  onCancelClick: () => void
  onSaveClick: (startDate: Date, endDate: Date) => void
}

export const DateRangeModal = (props: DateRangeModalProps) => {
  const {
    open,
    title,
    dateFormat,
    dateRangeSelectorOrientation,
    initialStartDate,
    initialExpiryDate,
    minDate,
    maxDate,
    onCancelClick,
    onSaveClick,
  } = props

  const [dateRange, setDateRange] = useState<Record<string, Date>>({
    startDate: initialStartDate,
    endDate: initialExpiryDate,
  })
  const [startDate, setStartDate] = useState<Date>(initialStartDate)
  const [endDate, setEndDate] = useState<Date>(initialExpiryDate)

  const handleDateChange = (date?: number, rangePoint?: string) => {
    if (!date || !rangePoint) {
      return
    }
    let newDateRange = { ...dateRange }
    if (rangePoint === 'startDate') {
      const newDate = new Date(date)
      setStartDate(newDate)
      newDateRange.startDate = newDate
    }
    if (rangePoint === 'endDate') {
      const newDate = new Date(date)
      setEndDate(newDate)
      newDateRange.endDate = newDate
    }
    setDateRange(newDateRange)
  }

  const handleCancelClick = () => {
    onCancelClick()
  }

  const handleSaveClick = () => {
    onSaveClick(startDate, endDate)
  }

  let modal = null
  if (open) {
    // TODO: use /components/Modals/Modal.tsx after web factor lands
    modal = (
      <Dialog
        open={true}
        onBackdropClick={handleCancelClick}
        BackdropProps={{ invisible: true }}
        PaperProps={{ style: { borderRadius: 8 } }}
        style={styles.dialog}>
        <div style={styles.contentContainer}>
          <h1 style={styles.title}>{title}</h1>
          <CalendarRange
            orientation={dateRangeSelectorOrientation || 'vertical'}
            dateFormat={dateFormat || 'yyyy/MM/dd'}
            dateRange={dateRange}
            onDateChange={handleDateChange}
            dialogStyle={{ zIndex: 30000 }}
            minDate={minDate}
            maxDate={maxDate}
          />
          <div style={styles.buttons}>
            <ButtonGrey onClick={handleCancelClick} style={{ marginRight: '1em' }}>
              Cancel
            </ButtonGrey>
            <ButtonBlue onClick={handleSaveClick}>Save</ButtonBlue>
          </div>
        </div>
      </Dialog>
    )
  }

  return modal
}

const styles = {
  contentContainer: {
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
    padding: '2.5em',
    borderRadius: 8,
  },
  buttons: { display: 'flex', marginTop: '2.5em' },
  dialog: { backgroundColor: ColorPalette.MODAL_BACKGROUND_OVERLAY },
  title: {
    marginTop: 0,
    marginBottom: '1.5em',
    textAlign: 'center' as 'center',
    alignSelf: 'center',
    fontFamily: 'roboto',
    color: ColorPalette.PRIMARY_TEXT,
    paddingLeft: 10,
    paddingRight: 10,
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
}
