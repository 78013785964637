import { ColorPalette } from '../../config/colors'

import React from 'react'
import Radium from 'radium'

import DeviceLogContent from './DeviceLogContent'

export type DeviceLog = {
  adHocDocProfilePksToSync: string[]
  competencyProfilePksToSync: string[]
  discActionProfilePksToSync: string[]
  discPhotoProfilePksToSync: string[]
  docProfilePksToSync: string[]
  employmentProfilePksToSync: string[]
  faceShotProfilePksToSync: string[]
  rawDataProfilePksToSync: string[]
  currentAppVersion: number
  oldestSupportedVersion: number
  lastLoginUnixMs: number
  lastOnlineLoginUnixMs: number
  lastSyncStepUnixMs: number
  lastOnlineLoginAppVersion: number
  deviceUuid: string
  deviceUuidApple: string
  lastSyncStep: string
  lastSyncedAssociation: string
  name: string
  surname: string
  username: string
  platform: 'app' | 'web'
  sessionLoginType: 'online' | 'offline'
  eventType: 'mobileAppLog'
}

interface DeviceLogBlockProps {
  style?: React.CSSProperties | undefined
  logData: DeviceLog
  openProfileListViewer: () => void
  index: number
}

const deviceLogBlock: React.FC<DeviceLogBlockProps> = (props: DeviceLogBlockProps) => {
  return (
    <div style={{ ...styles.container, ...props.style }}>
      <DeviceLogContent logData={props.logData} openProfileListViewer={props.openProfileListViewer} />
    </div>
  )
}

let styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'flex-start',
    borderRadius: 10,
    padding: '30px 5px 30px 15px',
    width: '45%',
    backgroundColor: ColorPalette.BUTTON_HOVER_GREY,
    boxShadow: '0px 2px 6px rgba(0, 0, 30, 0.2)',
    margin: '30px 25px 0px 25px',
  },
  rowItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 15,
    paddingVertical: 1,
  },
  rowItemIcon: {
    width: '1rem',
    height: '1rem',
    color: ColorPalette.DARK_GREY,
  },
  rowItemText: {
    color: ColorPalette.SECONDARY_TEXT,
    fontSize: '0.8rem',
    margin: 0,
    marginLeft: 20,
  },
}

export default Radium(deviceLogBlock)
