import { APP_VERSION } from '../cloud-config'
import { Profile, UserProfileSchema } from '../models'
import { linkDocsToProfile, renameAllFiles, updateEmployeeData } from '../provider/routes/dataSync'
import { SessionService } from './sessionService'
import { AuthCredentials } from '../types'

export class ProfileService {
  static async assignDocumentsToProfile(
    idPassport: string,
    selectedAssociation: string,
    selectedFolder: any,
    newlyCreatedDocs: any,
    userProfile: UserProfileSchema,
    authCredentials: AuthCredentials,
  ): Promise<any> {
    const params = {
      profileInfo: { idPassport }, // Adapt this on both app and here to be a simpler/uniform structure
      selectedFolder,
      newlyCreatedDocs,
      webVersion: APP_VERSION,
      assignedBy: `${userProfile.name} ${userProfile.surname}`,
    }
    try {
      const { username, password } = authCredentials
      const token = await SessionService.prepareAuthTokens(username, password)
      const data = await linkDocsToProfile(selectedAssociation, params, token)
      const { importTracker } = data
      return importTracker
    } catch (error) {
      throw error
    }
  }

  static async changeId(
    selectedAssociation: string,
    idChangedObject: any,
    authCredentials: AuthCredentials,
  ): Promise<void> {
    try {
      const { username, password } = authCredentials
      const token = await SessionService.prepareAuthTokens(username, password)
      await renameAllFiles(selectedAssociation, idChangedObject, token)
    } catch (error) {
      throw error
    }
  }

  static async saveProfileChanges(
    selectedAssociation: string,
    rawDataChanges: Record<string, { old: any; new: any }>,
    profile: Profile,
    userInfo: { name: string; surname: string; idPassport: string },
    authCredentials: AuthCredentials,
  ): Promise<any> {
    try {
      const { username, password } = authCredentials
      const token = await SessionService.prepareAuthTokens(username, password)
      await updateEmployeeData(selectedAssociation, rawDataChanges, profile, userInfo, token)
    } catch (error) {
      throw error
    }
  }
}
