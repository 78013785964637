import { useEffect, useState } from 'react'
import { ScrollView } from '@cantonjs/react-scroll-view'

import ButtonGeneric from './ButtonGeneric'
import { ColorPalette } from '../../../config'
import CheckboxTab from '../Checkboxes/CheckboxTab'
import ButtonGrey from './ButtonGrey'
import ButtonBlue from './ButtonBlue'
import { removeUnderscores } from '../../../utils'

type ButtonWithFilterProps = {
  disabled?: boolean
  label?: string | React.ReactChild[] | Element
  buttonWidth?: number
  buttonIndex?: number
  iconBefore?: JSX.Element
  iconAfter?: JSX.Element
  style?: React.CSSProperties
  filterState?: Record<string, boolean>
  filterMenuVisible?: boolean
  labelStyle?: React.CSSProperties
  title?: string
  upperCaseLabel?: boolean
  refCallback?: (element: HTMLDivElement) => void
  filterData?: (newFilterState: Record<string, boolean>) => void
  closeFilterMenu?: () => void
  resetFilters?: () => void
  onClick: () => void
}

export const ButtonWithFilter = (props: ButtonWithFilterProps) => {
  const [filterMenuVisible, setFilterMenuVisible] = useState(false)
  const [filterOptions, setFilterOptions] = useState([] as string[])
  const [selectedFilterOptions, setSelectedFilterOptions] = useState([] as string[])

  useEffect(() => {
    const { filterState } = props
    if (filterState !== undefined) {
      const filterOptions = Object.keys(filterState)
      let selectedFilterOptions = [] as string[]
      filterOptions.forEach((key) => (filterState[key] ? selectedFilterOptions.push(key) : null))
      setFilterOptions(filterOptions)
      setSelectedFilterOptions(selectedFilterOptions)
    }
  }, [])

  useEffect(() => {
    if (props.filterMenuVisible !== undefined && filterMenuVisible !== props.filterMenuVisible) {
      setFilterMenuVisible(props.filterMenuVisible)
    }
  }, [props.filterMenuVisible])

  const selectionHandler = (selections: string[]) => {
    if (selections.length === 0) {
      setSelectedFilterOptions([])
      return
    }

    if (selections.length === 1) {
      setSelectedFilterOptions(selections)
      return
    }
    const mostRecentSelection = selections.pop()
    if (selections.length > 0 && mostRecentSelection !== undefined) {
      setSelectedFilterOptions([mostRecentSelection])
    }
  }

  const filterHandler = () => {
    if (props.filterData === undefined) {
      return
    }
    let newFilters = {} as Record<string, boolean>
    filterOptions.forEach((filterKey) => {
      let selectedOption = removeUnderscores(filterKey)
      newFilters[filterKey] = selectedFilterOptions.includes(selectedOption)
    })
    props.filterData(newFilters)
    if (props.closeFilterMenu) {
      props.closeFilterMenu()
    } else {
      setFilterMenuVisible(false)
    }
  }

  const handleClearFilter = () => {
    selectionHandler([])
    if (props.resetFilters) {
      props.resetFilters()
    }
  }

  const handleClick = () => {
    setFilterMenuVisible(false)
    props.onClick()
  }

  let filterComponent = null
  if (filterMenuVisible) {
    const width = props.buttonWidth || 100
    const buttonIndex = props.buttonIndex || 0
    const left = width * buttonIndex
    const positionFactor = left < window.innerWidth / 3 ? window.innerWidth * 0.24 : window.innerWidth * 0.14
    const hasFilterOptions = filterOptions.length > 0
    let filterMenuStyle = { ...styles.filterMenuContainer, left: left + positionFactor }

    filterComponent = (
      <div style={styles.overlayContainer}>
        <div style={filterMenuStyle}>
          <ScrollView
            style={{ paddingBottom: 20 }}
            contentContainerStyle={{ padding: '20px 40px 0px 40px', maxHeight: 275, overflow: 'auto' }}>
            {hasFilterOptions ? (
              <CheckboxTab
                labelStyle={styles.checkboxLabel}
                lineContainerStyle={{ padding: 0 }}
                values={selectedFilterOptions}
                defaultValues={[]}
                selectorItems={filterOptions}
                selectionHandler={(newFilters) => selectionHandler(newFilters)}
                disabled={false}
                maxItems={100}
                maxItemsPerRow={3}
              />
            ) : (
              <div style={{ color: ColorPalette.PRIMARY_TEXT, paddingTop: '1.5em', textAlign: 'left' }}>
                No distinct filter options
              </div>
            )}
          </ScrollView>
          <div style={{ margin: '1em 1.5em 2.25em' }}>
            {selectedFilterOptions.length > 0 && (
              <ButtonGrey style={{ width: '100%' }} onClick={handleClearFilter}>
                Reset
              </ButtonGrey>
            )}
            <ButtonBlue
              style={{ width: '100%', marginTop: 12 }}
              onClick={() => (hasFilterOptions ? filterHandler() : setFilterMenuVisible(false))}>
              {hasFilterOptions ? 'Done' : 'Ok'}
            </ButtonBlue>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <ButtonGeneric {...props} onClick={handleClick} style={props.style} refCallback={props.refCallback} />
      {filterComponent}
    </>
  )
}

let styles = {
  filterMenuContainer: {
    position: 'absolute' as 'absolute',
    top: window.innerHeight * 0.28,
    padding: '0px 10px 0px 10px',
    zIndex: 300,
    backgroundColor: ColorPalette.CARD_WHITE,
    borderRadius: 8,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
    maxHeight: window.innerHeight * 0.6,
    margin: '0 auto',
  },
  overlayContainer: {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    backgroundColor: ColorPalette.MODAL_BACKGROUND_OVERLAY_SOFT,
    width: window.innerWidth,
    height: window.innerHeight,
    zIndex: 200,
    overflow: 'hidden',
  },
  checkboxLabel: {
    color: ColorPalette.PRIMARY_TEXT,
    width: window.innerWidth * 0.05,
    textAlign: 'left' as 'left',
  },
}
