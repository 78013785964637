import Icon from '@mdi/react'
import { mdiAccountGroup, mdiPencil } from '@mdi/js'
import Radium from 'radium'

import { ColorPalette } from '../../config/colors'
import ButtonBlue from '../BaseComponents/Buttons/ButtonBlue'
import { GroupMemberStats } from './GroupMemberStats'
import { GroupMembers, GroupStatus } from '../../types'

type DatePeriod = {
  startDate: Date
  endDate: Date
}

type GroupCardProps = {
  status: GroupStatus
  groupId: string
  groupName: string
  activeDatePeriod: DatePeriod
  groupMembers: GroupMembers
  editableDateRange?: boolean
  style?: React.CSSProperties
  toggleEditDateRange: (groupId: string) => void
  onViewGroupClick: () => void
}

const GroupCard = (props: GroupCardProps) => {
  let statusIndicatorColor = ColorPalette.PRIMARY_BLUE // Upcoming
  if (props.status === 'Active') {
    statusIndicatorColor = ColorPalette.DARK_GREY
  }
  if (props.status === 'Closed') {
    statusIndicatorColor = ColorPalette.WARNING_RED
  }

  const isComplete = props.status === 'Closed'

  return (
    <div
      style={{
        ...styles.container,
        backgroundColor: isComplete ? ColorPalette.OFF_WHITE_LIGHT : ColorPalette.BUTTON_HOVER_GREY,
        ...props.style,
      }}>
      <div style={styles.icon}>
        <div style={styles.imageContainer}>
          <div style={styles.profilePicDimensionContainer}>
            <Icon path={mdiAccountGroup} size={1.55} />
          </div>
        </div>
      </div>
      <div
        style={{
          ...styles.summary,
          color: isComplete ? ColorPalette.DISABLED_TEXT : ColorPalette.PRIMARY_TEXT,
        }}>
        <div style={styles.summaryHeaderContainer}>
          <div style={styles.summaryHeader}>
            <h3 style={styles.summaryTitle}>{props.groupName}</h3>
            {props.activeDatePeriod && (
              <div style={styles.summarySubtitle}>
                <div>
                  {props.activeDatePeriod.startDate.toLocaleDateString()} -{' '}
                  {props.activeDatePeriod.endDate.toLocaleDateString()}
                </div>
                <div
                  title="Edit date range"
                  onClick={() => props.toggleEditDateRange(props.groupId)}
                  style={
                    props.editableDateRange ? styles.editDateRange : { ...styles.editDateRange, visibility: 'hidden' }
                  }>
                  <Icon path={mdiPencil} size={0.85} style={{ paddingTop: '0.25em' }} />
                </div>
              </div>
            )}
          </div>
          <div
            title="View/manage group members"
            style={{
              paddingBottom: '1em',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}>
            <div style={styles.summaryStatus}>
              {props.status}
              <div
                style={{
                  ...styles.summaryStatusIndicator,
                  backgroundColor: statusIndicatorColor,
                }}></div>
            </div>
            <ButtonBlue onClick={props.onViewGroupClick} style={styles.buttonStyle}>
              VIEW
            </ButtonBlue>
          </div>
        </div>
        <div style={styles.summaryDetails}>
          {props.groupMembers ? (
            <GroupMemberStats members={props.groupMembers} isComplete={isComplete} />
          ) : (
            <GroupMemberStats members={{}} />
          )}
        </div>
      </div>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    gap: '1em',
    padding: '0.25em 1.5em 0.5em',
    boxShadow: 'rgba(0, 0, 30, 0.2) 0px 2px 6px',
    borderRadius: 10,
    minWidth: 550,
  },
  icon: {
    color: ColorPalette.OFF_WHITE,
    paddingTop: '1.25em',
  },
  summary: {
    padding: '1.5em 1.5em 1.5em 0em',
    width: '100%',
  },
  summaryTitle: {
    margin: 0,
    fontWeight: 'bold',
    fontSize: '0.8rem',
  },
  summarySubtitle: {
    color: ColorPalette.SECONDARY_TEXT,
    marginTop: 2,
    fontSize: '0.75rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '1em',
  },
  summaryStatus: {
    display: 'flex',
    alignItems: 'center' as 'center',
    justifyContent: 'flex-end' as 'flex-end',
  },
  summaryStatusIndicator: {
    borderRadius: '50%',
    padding: '0.35em',
    marginLeft: '0.5em',
  },
  summaryHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '10px',
    borderBottom: `1px solid ${ColorPalette.PRIMARY_TEXT}`,
  },
  summaryHeader: {
    paddingBottom: '0.5em',
  },
  summaryDetails: {
    padding: '0em 1em 0em 2em',

    marginTop: '1.5em',
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: ColorPalette.DARK_GREY,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.45)',
    marginTop: 0,
    width: window.innerHeight * 0.065,
    height: window.innerHeight * 0.065,
    borderRadius: window.innerHeight * 0.5,
  },
  profilePicDimensionContainer: {
    overflow: 'hidden',
    borderRadius: window.innerHeight * 0.2,
  },
  editDateRange: {
    cursor: 'pointer',
    ':hover': {
      color: ColorPalette.PRIMARY_BLUE,
    },
  },
  buttonStyle: {
    height: 22,
    width: 60,
    boxShadow: '0px 2px 6px rgba(0, 0, 30, 0.3)',
    fontSize: '0.7rem',
    marginTop: '0.5em',
    marginRight: 5,
  },
}

export default Radium(GroupCard)
