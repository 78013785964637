import React from 'react'
import Radium from 'radium'
import Spinner from 'react-spinkit'
import { ColorPalette } from '../../../config/colors'

interface IButtonMainProps {
  children: React.ReactNode
  loading?: boolean
  disabled?: boolean
  style?: React.CSSProperties | undefined
  title?: string
  onClick: (e: any) => void
}

const buttonMain: React.FC<IButtonMainProps> = (props: IButtonMainProps) => {
  let buttonContents = props.children
  if (props.loading) {
    buttonContents = (
      <Spinner style={{ transform: 'scale(0.6)' }} fadeIn="quarter" name="three-bounce" color="rgba(245,245,245,1)" />
    )
  }

  let buttonStyle = { ...styles.buttonContainer, ...props.style }
  if (props.disabled) {
    buttonStyle.cursor = 'not-allowed'
    buttonStyle.opacity = 0.5
  }
  return (
    <button style={buttonStyle} onClick={props.onClick} disabled={props.disabled} title={props.title}>
      {buttonContents}
    </button>
  )
}

const styles = {
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    margin: '0px 5px',
    height: 35,
    borderRadius: 30,
    backgroundImage: `linear-gradient(to bottom, ${ColorPalette.PRIMARY_BLUE}, ${ColorPalette.SECONDARY_BLUE})`,
    fontSize: '0.9rem',
    fontFamily: 'roboto',
    color: 'rgba(250, 250, 250, 1)',
    border: 'none',
    outline: 0,
    boxShadow: '0px 2px 6px rgba(0, 0, 30, 0.3)',
    ':hover': {
      filter: 'brightness(85%)',
    },
    ':active': {
      filter: 'brightness(50%)',
    },
    cursor: 'pointer',
  },
}

export default Radium(buttonMain)
