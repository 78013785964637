import { IClientRequestInstance, ClientRequestInstanceFactory, RequestStatuses } from "./..";

export interface IClientRequests {
    getAllRequests(): IClientRequestInstance[];
    getActiveRequests(): IClientRequestInstance[];
    getClosedRequests(): IClientRequestInstance[];
    getRequestById(requestId: string): IClientRequestInstance;
    updateRequestInstance(requestInstance: IClientRequestInstance): void;
}

class ClientRequests implements IClientRequests {
    private requestData: IClientRequestInstance[] = [];

    constructor(json: any) {
        if (json === undefined) {
            return;
        }
        this.requestData = this.initialise(json);
    }

    initialise(json: Record<string, any>[]): IClientRequestInstance[] {
        return json.map(jsonItem => ClientRequestInstanceFactory.create(jsonItem));
    }

    getAllRequests(): IClientRequestInstance[] {
        return this.requestData;
    }

    getActiveRequests(): IClientRequestInstance[] {
        let activeRequests: IClientRequestInstance[] = [];
        if (this.requestData !== undefined) {
            this.requestData.forEach(requestInstance => {
                if (requestInstance.getRequestStatus() === RequestStatuses.OPEN) {
                    activeRequests.push(requestInstance);
                }
            });
        }
        return activeRequests;
    }

    getClosedRequests(): IClientRequestInstance[] {
        let activeRequests: IClientRequestInstance[] = [];
        if (this.requestData !== undefined) {
            this.requestData.forEach(requestInstance =>
                requestInstance.getRequestStatus() === RequestStatuses.CLOSED
                    ? activeRequests.push(requestInstance)
                    : null,
            );
        }
        return activeRequests;
    }

    getRequestById(requestId: string): IClientRequestInstance {
        let selectedRequest;
        if (this.requestData !== undefined) {
            this.requestData.forEach(requestInstance =>
                requestInstance.getRequestId() === requestId ? (selectedRequest = requestInstance) : null,
            );
        }
        if (!selectedRequest) {
            throw { code: "NoRequestWithSelectedId" };
        }
        return selectedRequest;
    }

    updateRequestInstance(requestInstance: IClientRequestInstance): void {
        if (this.requestData === undefined) {
            this.requestData = [requestInstance];
            return;
        }
        this.requestData = [...this.requestData].filter(
            existingRequestInstace => existingRequestInstace.getRequestId() !== requestInstance.getRequestId(),
        );
        this.requestData.unshift(requestInstance);
    }
}

export class ClientRequestsFactory {
    static create(json: any): IClientRequests {
        if (json === undefined) {
            json = [];
        }
        return new ClientRequests(json);
    }
}
