import { ValidEmploymentStatus } from '../types'
import { toUpperCaseCustom } from './stringUtils'

export function isValidEmploymentStatus(k: string): k is ValidEmploymentStatus {
  return ['EMPLOYEE', 'CANDIDATE'].includes(toUpperCaseCustom(k))
}

export function toProcessConfigKey(str: ValidEmploymentStatus): string {
  switch (str) {
    case 'EMPLOYEE':
      return 'generalEmployee'
    case 'CANDIDATE':
      return 'generalCandidate'
    default:
      return `general${str}`
  }
}
