import { ColorPalette } from '../../config/colors'
import { toUpperCaseCustom } from '../../utils'

import React, { Component } from 'react'
import Radium from 'radium'
import Icon from '@mdi/react'
import { mdiCog, mdiImageSizeSelectLarge, mdiChevronDown, mdiDelete } from '@mdi/js'

import ButtonGeneric from '../BaseComponents/Buttons/ButtonGeneric'

interface SignatureConfigControllerProps {
  style?: React.CSSProperties | undefined
  signatureSizeName: string

  onClick: (functionType: string, item?: string) => void
}

interface SignatureConfigControllerState {
  subMenuOpen: string
}

class SignatureConfigController extends Component<SignatureConfigControllerProps, SignatureConfigControllerState> {
  iconMap = {
    settings: mdiCog,
    signatureSizeName: mdiImageSizeSelectLarge,
    delete: mdiDelete,
  }

  styleSettings = [
    {
      functionType: 'settings',
    },
    {
      functionType: 'signatureSizeName',
      subMenuItems: ['TINY', 'SMALL', 'MEDIUM', 'LARGE', 'HUGE'],
    },
    {
      functionType: 'delete',
    },
  ]

  state = {
    subMenuOpen: '',
  }

  toggleSubMenu(subMenuName: string, shouldShow: boolean) {
    this.setState({ subMenuOpen: shouldShow ? subMenuName : '' })
  }

  render() {
    return (
      <div style={{ ...styles.container, ...this.props.style }}>
        {this.styleSettings.map((buttonConfig, index) => {
          const { functionType, subMenuItems } = buttonConfig
          const borderRadiusStyle = {
            borderTopLeftRadius: index === 0 ? 20 : 0,
            borderBottomLeftRadius: index === 0 ? 20 : 0,
            borderTopRightRadius: index === this.styleSettings.length - 1 ? 20 : 0,
            borderBottomRightRadius: index === this.styleSettings.length - 1 ? 20 : 0,
          }

          let arrow = null
          let subMenu = null
          if (subMenuItems) {
            arrow = <Icon path={mdiChevronDown} size={0.5} color={ColorPalette.PRIMARY_TEXT} />
            if (this.state.subMenuOpen === functionType) {
              subMenu = (
                <div style={styles.subMenuContainer}>
                  {subMenuItems.map((item, i) => {
                    return (
                      <ButtonGeneric
                        key={`buttongeneric_${i}`}
                        style={styles.subMenuButton}
                        label={toUpperCaseCustom(item)}
                        onClick={() => this.props.onClick(functionType, item)}
                      />
                    )
                  })}
                </div>
              )
            }
          }

          return (
            <div
              key={`signatureoutermapkey_${index}`}
              style={{
                ...borderRadiusStyle,
                backgroundColor: functionType === 'settings' ? ColorPalette.PRIMARY_BLUE : ColorPalette.LIGHT_GREY,
              }}
              onMouseEnter={() => this.toggleSubMenu(functionType, true)}
              onMouseLeave={() => this.toggleSubMenu(functionType, false)}>
              <button
                key={`${functionType}_${index}`}
                disabled={subMenuItems ? true : false}
                onClick={() => this.props.onClick(functionType)}
                style={{
                  ...styles.styleButton,
                  ...borderRadiusStyle,
                  backgroundColor: functionType === 'settings' ? ColorPalette.PRIMARY_BLUE : ColorPalette.BUTTON_WHITE,
                  paddingRight: index === this.styleSettings.length - 1 ? 10 : 7,
                }}>
                <Icon
                  path={(this.iconMap as any)[functionType]}
                  size={0.7}
                  color={functionType === 'settings' ? ColorPalette.CARD_WHITE : ColorPalette.PRIMARY_TEXT}
                />
                {arrow}
              </button>
              {subMenu}
            </div>
          )
        })}
      </div>
    )
  }
}

let styles = {
  container: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000000,
  },
  styleButton: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 1,
    marginRight: 0,
    height: 30,
    paddingLeft: 7,
    paddingRight: 7,
    minWidth: 50,
    border: `2px solid rgba(255,255,255,0)`,
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
    ':hover': {
      filter: 'brightness(96%)',
    },
    ':active': {
      filter: 'brightness(90%)',
    },
  },
  subMenuContainer: {
    position: 'absolute' as 'absolute',
    width: 100,
    marginTop: 2,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    backgroundColor: ColorPalette.BUTTON_HOVER_GREY,
    padding: 5,
  },
  subMenuButton: {
    height: 30,
    fontSize: '0.6rem',
    fontWeight: 'normal',
    color: ColorPalette.PRIMARY_TEXT,
    backgroundColor: ColorPalette.BUTTON_HOVER_GREY,
    ':hover': {
      filter: 'brightness(96%)',
    },
    ':active': {
      filter: 'brightness(90%)',
    },
  },
  textStyle: {
    fontFamily: 'roboto',
    fontWeight: 'normal',
    fontSize: '0.6rem',
    marginLeft: 3,
  },
}

export default Radium(SignatureConfigController)
