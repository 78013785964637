import Storage from '@aws-amplify/storage'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { cloneDeep } from 'lodash'
import { base64ToBlob } from 'base64-blob'

import { formatDateAndTime } from './calendar'
import { getRemoteFile } from '../provider/remoteData'

export function filePicker(event: React.ChangeEvent<{ value: string }>): Record<string, any>[] {
  const filelist = (event?.target as HTMLInputElement)?.files
  if (filelist === null || filelist.length === 0) {
    throw { code: 'UnknownError' }
  }

  return Array.from(filelist).map((fileitem: File) => {
    const url = URL.createObjectURL(fileitem)
    return {
      file: fileitem,
      name: fileitem.name,
      type: fileitem.type,
      url,
    }
  })
}

export async function writeLocalFileToRemoteStorage(path: string, file: any, contentType: string): Promise<any> {
  if (contentType === 'base64') {
    file = await base64ToBlob(file)
  }

  return await Storage.put(path, file, { contentType })
}

export async function writeBlobToRemoteStorage(path: string, blob: any, contentType: string): Promise<any> {
  return await Storage.put(path, blob, { contentType })
}

export async function writeRemoteFilesToLocalStorage(
  docsToDownload: { docPath: string; filename: string }[],
): Promise<any> {
  let zip = new JSZip()
  for (let i = 0; i < docsToDownload.length; i++) {
    const { filename, docPath } = docsToDownload[i]
    const file = await getRemoteFile(docPath, { download: true })
    zip.file(filename, file.Body, { binary: true })
  }

  const blob = await zip.generateAsync({ type: 'blob' })
  const { day, month, year, minutes, hour } = formatDateAndTime(new Date())
  saveAs(blob, `PeopleFlow Files - ${day} ${month} ${year}, ${hour}-${minutes}.zip`)
}

export async function writeSingleRemoteFiletTLocalStorage(docPath: string) {
  await getRemoteFile(docPath, { download: true })
}

export async function writeDataToCsvFile(filePath: string, csvHeaderColumns: string[], csvRowData: string[][]) {
  const dataRowArray = cloneDeep(csvRowData)
  dataRowArray.unshift(csvHeaderColumns)
  const csvDataString = generateCsvRows(dataRowArray)
  const csvBlob = new Blob([csvDataString], { type: 'text/csv' })
  await writeBlobToRemoteStorage(filePath, csvBlob, 'text/csv')
}

function generateCsvRow(data: string[]): string {
  let csvRow: string[] = []
  csvRow.push(data.join(','))
  // returning the array joining with new line
  return csvRow.join('\n')
}

function generateCsvRows(data: string[][]): string {
  let csvRows: string[] = []
  data.forEach((item: any) => {
    const row = generateCsvRow(item)
    csvRows.push(row)
  })
  // returning the array joining with new line
  return csvRows.join('\n')
}
