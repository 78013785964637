import { ColorPalette, AllScreenNames } from '../../config'
import { connect } from 'react-redux'

import { Component } from 'react'

import Lottie from 'react-lottie'

import NavigationBar from '../../components/Navigation/NavigationBar'

import comingSoon from '../../assets/comingSoon.json'
import { ActionType } from '../../store/actions/actions'
import { RouteComponentProps } from 'react-router'
import { PeopleFlowCombinedReducer } from '../../store'

interface IComingSoonProps extends RouteComponentProps {
  updateState: (arg: any) => void
  changeScreen: (screen: AllScreenNames) => void
}

interface IComingSoonState {}

class ComingSoon extends Component<IComingSoonProps, IComingSoonState> {
  componentDidMount() {
    this.props.updateState({
      currentSection: AllScreenNames.COMING_SOON,
      currentScreen: AllScreenNames.COMING_SOON,
    })
  }

  componentDidUpdate() {
    this.props.updateState({
      currentSection: AllScreenNames.COMING_SOON,
      currentScreen: AllScreenNames.COMING_SOON,
    })
  }

  render() {
    return (
      <div style={styles.container}>
        <NavigationBar history={this.props.history} match={this.props.match} location={this.props.location} />
        <Lottie speed={1.6} options={{ animationData: comingSoon, loop: false }} style={{ ...styles.animation }} />
        <p style={{ ...styles.text }}>Coming Soon...</p>
      </div>
    )
  }
}

const styles = {
  container: {
    minHeight: window.innerHeight,
    maxHeight: window.innerHeight,
    display: 'flex',
    flexDirection: 'column' as 'column',
    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255, 1), rgba(209,210,230, 1))',
    overflowX: 'hidden' as 'hidden',
    overflowY: 'hidden' as 'hidden',
  },
  animation: {
    marginTop: window.innerHeight * 0.09,
    width: 450,
    height: 450,
  },
  text: {
    alignSelf: 'center',
    fontSize: '3rem',
    fontWeight: '550',
    color: ColorPalette.PRIMARY_TEXT,
    marginTop: -100,
  },
}

const mapStateToProps = (state: PeopleFlowCombinedReducer) => {
  return {
    currentSection: state.sessionManager.currentSection,
    currentScreen: state.sessionManager.currentScreen,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateState: (data: any) => dispatch({ type: ActionType.UPDATE_STATE, data }),
    changeScreen: (screen: AllScreenNames) =>
      dispatch({ type: ActionType.CHANGE_SCREEN, data: { currentScreen: screen } }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ComingSoon)
