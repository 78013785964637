import { RequestInstanceConfigFactory, IRequestInstanceConfig, RequestStatuses, ClientRequestStepTypes } from './'
import { UserProfileSchema } from '../user/user'
import { ErrorCodeTypes } from '../../enums/errorCodeTypes'

export interface IClientRequestInstance {
  addEmailsToCurrentStep(emails: string[]): void
  addFormsToCurrentStep(newForms: Record<string, any>[], userInfo: UserProfileSchema): void
  authorise(userInfo: UserProfileSchema): void
  declineAuth(userInfo: UserProfileSchema): void
  getRequiredAuthQty(): number
  getRequestName(): string
  getRequestStatus(): RequestStatuses
  getRequestCreator(): string
  getCreationTime(): number
  getStepLabel(stepName: ClientRequestStepTypes | undefined): string
  getCurrentStepLabel(): string
  getCurrentStepName(): ClientRequestStepTypes | undefined
  getRequestId(): string
  getRequestConfig(): IRequestInstanceConfig
  getOrderedStepNameArray(): ClientRequestStepTypes[]
  getSteps(): Record<string, any>
  getStepState(stepName: ClientRequestStepTypes | undefined): Record<string, any>
  getStepConfig(stepName: ClientRequestStepTypes | undefined): Record<string, any>
  getLogs(): Record<string, string>[]
  getCompletedStepNames(): ClientRequestStepTypes[]
  loadNextStep(): void
  withdrawAuthorisation(userInfo: UserProfileSchema): void
}

class ClientRequestInstance implements IClientRequestInstance {
  private id: string = ''
  private status: RequestStatuses = RequestStatuses.OPEN
  private createdUnixMs: number = 0
  private createdBy: string = ''
  private requestConfig = RequestInstanceConfigFactory.create({})
  private steps: Record<string, any> = {}
  private logs: Record<string, string>[] = []

  constructor(json: any) {
    if (!json) {
      return
    }
    this.id = json.id
    this.status = json.status
    this.createdUnixMs = json.createdUnixMs
    this.createdBy = json.createdBy
    this.steps = json.steps
    this.logs = json.logs
    this.requestConfig =
      json.requestConfig !== undefined && 'isRequestInstanceConfig' in json.requestConfig
        ? json.requestConfig
        : RequestInstanceConfigFactory.create(json.requestConfig)
  }

  authorise(userInfo: UserProfileSchema): void {
    this.steps.auth.auths = [...this.steps.auth.auths].filter(
      (authItem) => authItem.idPassport === userInfo.username && authItem.choice === 'authed',
    )
    this.steps.auth.auths.unshift({
      name: `${userInfo.name} ${userInfo.surname}`,
      idPassport: `${userInfo.username}`,
      choice: 'authed',
      unixMs: new Date().getTime(),
    })
  }

  declineAuth(userInfo: UserProfileSchema): void {
    this.steps.auth.auths = [...this.steps.auth.auths].filter(
      (authItem) => authItem.idPassport === userInfo.username && authItem.choice === 'declined',
    )
    this.steps.auth.auths.unshift({
      name: `${userInfo.name} ${userInfo.surname}`,
      idPassport: `${userInfo.username}`,
      choice: 'declined',
      unixMs: new Date().getTime(),
    })
  }

  withdrawAuthorisation(userInfo: UserProfileSchema): void {
    this.steps.auth.auths = [...this.steps.auth.auths].filter((authItem) => authItem.idPassport !== userInfo.username)
  }

  getRequiredAuthQty(): number {
    return this.requestConfig.getRequiredAuthQty()
  }

  addEmailsToCurrentStep(emails: string[]): void {
    const currentStepName = this.getCurrentStepName()
    if (currentStepName === undefined) {
      return
    }
    this.steps[currentStepName].sentTo = emails

    this.logs.push({
      unixMs: `${new Date().getTime()}`,
      message: `Forms were sent to the following email addresses: ${emails.join(', ')}`,
    })
    this.loadNextStep()
  }

  addFormsToCurrentStep(newForms: Record<string, any>[], userInfo: UserProfileSchema): void {
    const currentStepName = this.getCurrentStepName()
    if (currentStepName === undefined) {
      return
    }
    this.steps[currentStepName].forms.push(...newForms)
    this.steps[currentStepName].completedByIdPassport = userInfo.username

    const formNameArray = [...newForms].map((formData) => formData.name)
    this.logs.push({
      unixMs: `${new Date().getTime()}`,
      message: `${userInfo.name} ${userInfo.surname} uploaded the following forms: ${formNameArray.join(', ')}`,
    })
    this.loadNextStep()
  }

  getRequestStatus(): RequestStatuses {
    return this.status
  }

  getCreationTime(): number {
    if (!this.requestConfig) {
      throw { code: ErrorCodeTypes.INVALID_REQUEST_INSTANCE_TIMESTAMP }
    }
    return this.createdUnixMs
  }

  getRequestId(): string {
    return this.id
  }

  getRequestName(): string {
    return this.requestConfig.getRequestName()
  }

  getRequestConfig(): IRequestInstanceConfig {
    return this.requestConfig
  }

  getStepConfig(stepName: ClientRequestStepTypes): Record<string, any> {
    const stepConfig = this.getRequestConfig().getStepConfig(stepName)
    return stepConfig
  }

  getRequestCreator(): string {
    return this.createdBy
  }

  getCurrentStepName(): ClientRequestStepTypes | undefined {
    const stepNames = this.getOrderedStepNameArray()
    let currentStepName = undefined
    stepNames.forEach((stepName) => {
      const { active, completed } = this.steps[stepName]
      if (active && !completed) {
        currentStepName = stepName
      }
    })
    if (currentStepName === undefined) {
      currentStepName = stepNames[stepNames.length - 1]
    }
    return currentStepName
  }

  getCurrentStepLabel(): string {
    const currentStepName = this.getCurrentStepName()
    return this.requestConfig.getStepLabel(currentStepName)
  }

  getLogs(): Record<string, string>[] {
    return this.logs
  }

  getOrderedStepNameArray(): ClientRequestStepTypes[] {
    return this.requestConfig.getOrderedStepNameArray()
  }

  getStepLabel(stepName: ClientRequestStepTypes | undefined): string {
    return this.requestConfig.getStepLabel(stepName)
  }

  getSteps(): Record<string, any> {
    return this.steps
  }

  getStepState(stepName: ClientRequestStepTypes | undefined): Record<string, any> {
    if (!stepName) {
      return {}
    }
    return this.getSteps()[stepName]
  }

  getCompletedStepNames(): ClientRequestStepTypes[] {
    const requestState = this.getSteps()
    let completedStepNames: ClientRequestStepTypes[] = []
    Object.keys(requestState).forEach((stepName) =>
      requestState[stepName].completed ? completedStepNames.push(stepName as ClientRequestStepTypes) : null,
    )
    return completedStepNames
  }

  loadNextStep() {
    const currentStepName = this.getCurrentStepName()
    if (currentStepName === undefined) {
      return
    }
    const orderedSteps = this.getOrderedStepNameArray()
    this.steps[currentStepName].active = false
    this.steps[currentStepName].completed = true

    if (orderedSteps.indexOf(currentStepName) === orderedSteps.length - 1) {
      this.status = RequestStatuses.CLOSED
      return
    }
    for (let i = 0; i < orderedSteps.length; i++) {
      if (orderedSteps[i] === currentStepName && i !== orderedSteps.length - 1) {
        this.steps[orderedSteps[i + 1]].active = true
        return
      }
    }
  }
}

export class ClientRequestInstanceFactory {
  static create(json: any): IClientRequestInstance {
    return new ClientRequestInstance(json)
  }
}
