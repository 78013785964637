import { Auth } from 'aws-amplify'
import { CognitoUser } from '@aws-amplify/auth'

import { checkPasswordFormat, reformatUsername, validateForgotPasswordInputs } from '../utils'
import { PROJECT_BUILT_TIMESTAMP } from '../cloud-config'
import { LocalStorageProvider, appLogin } from '../provider'
import { AssociationSettingsRepository, ProfileRepository, UserRepository } from '../repositories'
import { difference } from 'lodash'
import { ErrorCodeTypes } from '../enums/errorCodeTypes'
import { AllAssociationSettings, AuthCredentials } from '../types'

export type CoreRepos = {
  userRepo: UserRepository
  associationRepo: AssociationSettingsRepository
  profileRepo?: ProfileRepository
  clientSettings?: Record<string, any>
}

export class SessionService {
  static async initialiseSession(
    selectedAssociation: string,
    idPassport: string,
    authCredentials: AuthCredentials,
  ): Promise<CoreRepos> {
    try {
      const { username, password } = authCredentials
      const token = await SessionService.prepareAuthTokens(username, password)
      const response = await appLogin(selectedAssociation, idPassport, token, true)
      const { result, userProfile, clientSettings } = response
      if (result !== 'success') {
        if (result === 'noAccess') {
          throw { code: ErrorCodeTypes.NO_WEB_ACCESS }
        }
        throw { code: ErrorCodeTypes.LOGIN_FAILED }
      }

      await this.checkAndHandleLocalResetTimestamp(PROJECT_BUILT_TIMESTAMP)
      return {
        userRepo: new UserRepository(userProfile),
        associationRepo: {} as AssociationSettingsRepository,
        clientSettings,
      }
    } catch (error) {
      throw error
    }
  }

  static async fetchSystemConfig(selectedAssociation: string, idPassport: string, authCredentials: AuthCredentials) {
    try {
      const { username, password } = authCredentials
      const token = await SessionService.prepareAuthTokens(username, password)
      const response = await appLogin(selectedAssociation, idPassport, token)
      const { result, userProfile, clientSettings } = response
      if (result !== 'success') {
        if (result === 'noAccess') {
          throw { code: ErrorCodeTypes.NO_WEB_ACCESS }
        }
        throw { code: ErrorCodeTypes.LOGIN_FAILED }
      }
      const userRepo = new UserRepository(userProfile)
      const associationRepo = new AssociationSettingsRepository()

      return { userRepo, associationRepo, clientSettings }
    } catch (error) {
      throw error
    }
  }

  static async fetchAllAssociationSettings(
    selectedAssociation: string,
    authCredentials: AuthCredentials,
  ): Promise<AllAssociationSettings> {
    try {
      const { username, password } = authCredentials
      const token = await SessionService.prepareAuthTokens(username, password)
      const response = await appLogin(selectedAssociation, username, token)
      let { result, clientSettings } = response
      if (result !== 'success') {
        if (result === 'noAccess') {
          throw { code: ErrorCodeTypes.NO_WEB_ACCESS }
        }
        throw { code: ErrorCodeTypes.LOGIN_FAILED }
      }
      return clientSettings
    } catch (error) {
      throw error
    }
  }

  static async checkAndHandleLocalResetTimestamp(lastLocalResetTimestamp: number) {
    const localRefreshTimestamp = await LocalStorageProvider.getData('idbResetTimestamp')
    if (localRefreshTimestamp) {
      if (lastLocalResetTimestamp && localRefreshTimestamp < lastLocalResetTimestamp) {
        await LocalStorageProvider.resetDb()
        await LocalStorageProvider.setData('idbResetTimestamp', lastLocalResetTimestamp)
      }
    } else {
      await LocalStorageProvider.resetDb()
      await LocalStorageProvider.setData('idbResetTimestamp', lastLocalResetTimestamp)
    }
  }

  static async getJwtToken(): Promise<string> {
    return Auth.currentSession()
      .then((session: any) => {
        const jwtToken = session.getIdToken().getJwtToken()
        return jwtToken
      })
      .catch((error: any) => {
        throw error
      })
  }

  static async getToken() {
    try {
      const token = await Auth.currentSession()
      const idToken = token.getIdToken()
      return idToken.getJwtToken()
    } catch (error) {
      throw error
    }
  }

  static async prepareAuthTokens(username: string, password: string) {
    try {
      const session = await Auth.currentSession()
      return session.getIdToken().getJwtToken()
    } catch (error) {
      console.error(`prepareAuthTokens username: ${username}`, error)
      try {
        const user = await Auth.signIn({ username, password })
        return user.signInUserSession.idToken.jwtToken
      } catch (err) {
        throw err
      }
    }
  }

  static forgotPassword(idPassport: string): Promise<any> {
    idPassport = reformatUsername(idPassport)

    if (!idPassport.length) {
      return new Promise((res, rej) => rej(new Error('InvalidIdPassport')))
    }

    return Auth.forgotPassword(idPassport)
  }

  static submitChangedPassword(idPassport: string, otp: string, newPassword: string = ''): Promise<any> {
    const validationResult = validateForgotPasswordInputs(otp, newPassword)
    if (validationResult.result !== 'success') {
      return new Promise((res, rej) => rej(validationResult))
    }
    return Auth.forgotPasswordSubmit(idPassport, otp, newPassword)
  }

  static async submitNewPassword(
    awsUser?: CognitoUser,
    newPassword?: string,
    confirmNewPassword?: string,
  ): Promise<void> {
    if (!newPassword || !confirmNewPassword) {
      throw { code: ErrorCodeTypes.NO_INPUT }
    } else if (newPassword !== confirmNewPassword) {
      throw { code: ErrorCodeTypes.PASSWORD_MISMATCH }
    } else if (!checkPasswordFormat(newPassword)) {
      throw { code: ErrorCodeTypes.INVALID_PASSWORD }
    }
    await Auth.completeNewPassword(awsUser, newPassword, {})
  }

  static async login(selectedAssociation: string, username: string, password: string): Promise<CoreRepos> {
    try {
      username = reformatUsername(username)
      if (username.length === 0 || password.length === 0) {
        throw { code: ErrorCodeTypes.MISSING_INFO }
      }
      const user = await Auth.signIn({ username: username, password })
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        // Not great style to transport the user object in the exception
        // like this, but hey, we'll make it better soon.
        throw { code: ErrorCodeTypes.NEW_PASSWORD_REQUIRED, user }
      }

      const token = await this.getToken()
      const { userRepo, associationRepo, clientSettings } = await this.fetchSystemConfig(
        selectedAssociation,
        username,
        { username, password },
      )

      const profileRepo = new ProfileRepository(selectedAssociation)
      await profileRepo.hydrateProfileEntitiesFromCache(selectedAssociation)

      const allAssociations: string[] = Object.keys(clientSettings as Record<string, any>)
      const otherAssociations = allAssociations.filter((association) => association !== selectedAssociation)
      const hydratedAssociations = await associationRepo.initHydratedCachedSettings(otherAssociations)
      const associationsToRefresh = difference(otherAssociations, hydratedAssociations)
      const refreshedAssociationSettings = await this.refreshAssociationSettings(associationsToRefresh, username, token)
      await associationRepo.initialise({ ...clientSettings, ...refreshedAssociationSettings }, hydratedAssociations)

      return { userRepo, associationRepo, profileRepo }
    } catch (error) {
      //@ts-ignore
      if (error?.message === 'timeout of 0ms exceeded') {
        throw { code: ErrorCodeTypes.NETWORK_TIMEOUT }
      }
      throw error
    }
  }

  static async refreshAssociationSettings(associationNames: string[], username: string, password: string) {
    let hydratedClientSettings = {}
    await Promise.all(
      associationNames.map(async (associationName) => {
        const associationSettings = await this.fetchAllAssociationSettings(associationName, {
          username,
          password,
        })
        hydratedClientSettings = {
          ...hydratedClientSettings,
          [associationName]: associationSettings[associationName],
        }
      }),
    )
    return hydratedClientSettings
  }

  static async logout(): Promise<void> {
    try {
      await Auth.signOut()
      localStorage.clear()
    } catch (err: any) {
      throw err
    }
  }

  static async fetchSettings(sessionManager: any, excludeAssociations?: string[]) {
    const { idPassport, password, selectedAssociation } = sessionManager
    const { userRepo, associationRepo, clientSettings } = await this.fetchSystemConfig(
      selectedAssociation,
      idPassport,
      {
        username: idPassport,
        password,
      },
    )
    if (clientSettings) {
      await associationRepo.initialise(clientSettings, excludeAssociations)
    }
    return { userRepo, associationRepo }
  }

  static async updateSettings(
    sessionManager: any,
    userRepo: UserRepository,
    associationRepo: AssociationSettingsRepository,
  ) {
    const profileRepo = new ProfileRepository(sessionManager.selectedAssociation)
    await profileRepo.hydrateProfileEntitiesFromCache(sessionManager.selectedAssociation)
    sessionManager.userRepo = userRepo
    sessionManager.associationRepo = associationRepo
    sessionManager.profileRepo = profileRepo

    return sessionManager
  }
}
