import React, { PureComponent, createRef } from 'react'
import Radium from 'radium'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { camelCase, isEmpty } from 'lodash'
import { mdiInformationOutline, mdiContentDuplicate, mdiUndo, mdiContentSave, mdiPlus } from '@mdi/js'
import Icon from '@mdi/react'

import { startCase, difference } from 'lodash'

import { ColorPalette } from '../../config/colors'
import { ActionType } from '../../store/actions/actions'
import LoadingModal from '../../components/Modals/LoadingModal'
import ButtonRed from '../../components/BaseComponents/Buttons/ButtonRed'
import NavigationBar from '../../components/Navigation/NavigationBar'
import SectionHeaderPrimary from '../../components/Headings/SectionHeaderPrimary'
import RouteLeavingGuard from '../../components/Navigation/RouteLeavingGuard'
import LabelCollector from '../../components/Modals/LabelCollector'
import AlertOneButton from '../../components/Modals/AlertModalOneButton'
import AlertTwoButton from '../../components/Modals/AlertModalTwoButton'
import { SessionService, ConfigManagerService } from '../../services'
import ButtonGeneric from '../../components/BaseComponents/Buttons/ButtonGeneric'
import DataFlowDetails from '../../components/Configurator/DataFlows/DataFlowDetails'
import { BackButton } from '../../components/BaseComponents/Buttons/BackButton'
import SideMenu from '../../components/Navigation/SideMenu'
import DataFlowsSideMenu from '../../components/SideMenus/DataFlowsSideMenu'
import { PeopleFlowCombinedReducer } from '../../store'
import { AssociationSettingsRepository, UserRepository } from '../../repositories'
import { IDataFlow, IFieldConfigItem, ISectionConfigItem } from '../../interfaces'
import { getFieldConfigExclPpeItems } from '../../utils'

interface IDataFlowsProps extends RouteComponentProps {
  selectedAssociation: string
  idPassport: string
  password: string
  associationRepo: AssociationSettingsRepository
  userRepo: UserRepository
  updateState: (obj: any) => void
}

interface IDataFlowsState {
  savingModalOpen: boolean
  unsavedChangesModalOpen: boolean
  saveFailedModalOpen: boolean
  selectedFlow: string
  selectedSection: string
  selectorModalOpen: boolean
  selectorItems: string[]
  selectorItemsDisplay: string
  addNewSection: boolean
  editSectionLabel: string
  editAction: 'rename' | 'remove' | null
  newFieldOrder: string[]
  addFieldModalOpen: boolean
  key: string
  [index: string]: any
}

const restoreOriginalDataFlowName = (name: string) => `dataFlow___${name}`

class DataFlows extends PureComponent<IDataFlowsProps, IDataFlowsState> {
  private sectionHeaderRef: React.RefObject<any>

  configManagerService = new ConfigManagerService()

  constructor(props: IDataFlowsProps) {
    super(props)
    this.sectionHeaderRef = createRef()
  }

  initialModalState = {
    duplicateModalOpen: false,
    savingModalOpen: false,
    selectorModalOpen: false,
    unsavedChangesModalOpen: false,
    saveFailedModalOpen: false,
    addNewSection: false,
    addFieldModalOpen: false,
  }

  initialCoreState = {
    selectedSection: '',
    selectorIdentifier: '',
    selectorItems: [],
    selectorItemsDisplay: '',
    editSectionLabel: '',
    editAction: null,
    detailsRefreshTimestamp: 0,
    newFieldOrder: [''],
    key: '',
  }

  state: IDataFlowsState = {
    ...this.initialModalState,
    // @ts-ignore
    selectedFlow: this.props.match?.params?.dataFlow
      ? // @ts-ignore
        restoreOriginalDataFlowName(this.props.match.params.dataFlow)
      : '',
    ...this.initialCoreState,
  }

  componentDidUpdate(prevProps: Readonly<IDataFlowsProps>, prevState: Readonly<IDataFlowsState>, snapshot?: any): void {
    if (
      // @ts-ignore
      prevProps.match?.params?.dataFlow !== this.props.match?.params?.dataFlow &&
      // @ts-ignore
      this.props.match.params.dataFlow !== this.state.selectedFlow
    ) {
      this.setState({
        // @ts-ignore
        selectedFlow: restoreOriginalDataFlowName(this.props.match.params.dataFlow),
        ...this.initialCoreState,
      })
    }
  }

  closeModals = () => {
    this.setState({ ...this.initialModalState, editSectionLabel: '', editAction: null })
  }

  toggleSaveFailedModal = () => this.setState((prevState) => ({ saveFailedModalOpen: !prevState.saveFailedModalOpen }))
  toggleDuplicateModal = () => this.setState((prevState) => ({ duplicateModalOpen: !prevState.duplicateModalOpen }))
  toggleAddFieldModal = () => this.setState((prevState) => ({ addFieldModalOpen: !prevState.addFieldModalOpen }))

  saveSectionName = async (sectionLabel: string) => {
    this.toggleSavingModal()

    const { selectedFlow, editSectionLabel } = this.state
    const { idPassport, password, selectedAssociation, associationRepo } = this.props

    const dataFlowsConfig = associationRepo.getAllDataFlows(selectedAssociation)
    let sectionConfigItemIndex = dataFlowsConfig[selectedFlow].sectionConfig.findIndex(
      (configObj: ISectionConfigItem) => configObj.sectionLabel === editSectionLabel,
    )
    if (sectionConfigItemIndex > -1) {
      const sectionConfigItem = dataFlowsConfig[selectedFlow].sectionConfig[sectionConfigItemIndex]
      const previousSectionName = sectionConfigItem.sectionName
      const newSectionLabel = sectionLabel.toUpperCase()
      const newSectionName = camelCase(sectionLabel)

      sectionConfigItem.sectionName = newSectionName
      sectionConfigItem.sectionLabel = newSectionLabel
      // first rename section config item object properties

      let changes = [
        {
          editedBy: idPassport,
          updatePath: ['sectionConfig'],
          updatedData: dataFlowsConfig[selectedFlow].sectionConfig,
          updatedMs: +new Date(),
        },
      ]

      await ConfigManagerService.updateConfig(
        selectedAssociation,
        'dataFlow',
        changes,
        { username: idPassport, password },
        selectedFlow,
      )

      setTimeout(() => null, 750)

      // secondly replace previous section with clone that has new section name
      const dataUnderPreviousSectionName = dataFlowsConfig[selectedFlow].dataFlow[previousSectionName]
      // remove existing
      delete dataFlowsConfig[selectedFlow].dataFlow[previousSectionName]
      // add & replace with new
      dataFlowsConfig[selectedFlow].dataFlow[newSectionName] = dataUnderPreviousSectionName

      changes = [
        {
          editedBy: idPassport,
          updatePath: ['dataFlow'],
          // @ts-ignore (ignore what might be an incorrect backend type issue)
          updatedData: dataFlowsConfig[selectedFlow].dataFlow,
          updatedMs: +new Date(),
        },
      ]

      const response = await ConfigManagerService.updateConfig(
        selectedAssociation,
        'dataFlow',
        changes,
        { username: idPassport, password },
        selectedFlow,
      )

      if (response.result === 'success') {
        const { selectedAssociation, associationRepo, updateState } = this.props
        associationRepo.setDataFlowConfig(selectedAssociation, selectedFlow, response.updatedConfig)
      } else {
        this.toggleSaveFailedModal()
      }
    }
    this.closeModals()
  }

  saveNewSection = async (newSectionLabel: string) => {
    this.setState({ savingModalOpen: true, addNewSection: false })

    const { selectedFlow } = this.state
    const { idPassport, password, selectedAssociation, associationRepo } = this.props

    const dataFlowsConfig = associationRepo.getAllDataFlows(selectedAssociation)
    const newSectionName = camelCase(newSectionLabel)

    // add section config item
    const newSectionConfigItem = {
      iconName: 'custom',
      sectionLabel: newSectionLabel.toUpperCase(),
      sectionName: newSectionName,
    }
    let changes = [
      {
        editedBy: idPassport,
        updatePath: ['sectionConfig'],
        updatedData: [...dataFlowsConfig[selectedFlow].sectionConfig, newSectionConfigItem],
        updatedMs: +new Date(),
      },
    ]

    const token = await SessionService.prepareAuthTokens(idPassport, password)
    await ConfigManagerService.updateConfig(
      selectedAssociation,
      'dataFlow',
      changes,
      { username: idPassport, password },
      selectedFlow,
    )

    // create corresponding dataFlow item
    changes = [
      {
        editedBy: idPassport,
        updatePath: ['dataFlow', newSectionName],
        updatedData: [],
        updatedMs: +new Date(),
      },
    ]

    const response = await ConfigManagerService.updateConfig(
      selectedAssociation,
      'dataFlow',
      changes,
      { username: idPassport, password },
      selectedFlow,
    )

    if (response.result === 'success') {
      const { selectedAssociation, associationRepo, updateState } = this.props
      associationRepo.setDataFlowConfig(selectedAssociation, selectedFlow, response.updatedConfig)
    } else {
      this.toggleSaveFailedModal()
    }

    this.setState({ selectedSection: newSectionName })
    this.closeModals()
  }

  removeSection = async () => {
    const editSectionLabel = this.state.editSectionLabel
    this.setState({ savingModalOpen: true, editAction: null, editSectionLabel: '' })

    const { selectedFlow } = this.state
    const { idPassport, password, selectedAssociation, associationRepo } = this.props
    const dataFlowsConfig = associationRepo.getAllDataFlows(selectedAssociation)
    const sectionConfig = dataFlowsConfig[selectedFlow].sectionConfig

    const sectionConfigItem = sectionConfig.find(
      (configItem: ISectionConfigItem) => configItem.sectionLabel === editSectionLabel,
    )
    const sectionConfigItemIndex = sectionConfig.findIndex(
      (configItem: ISectionConfigItem) => configItem.sectionLabel === editSectionLabel,
    )

    // add section config item
    if (sectionConfigItem) {
      const sectionName = sectionConfigItem.sectionName

      let updatedSectionConfig = [...sectionConfig]
      if (sectionConfigItemIndex > -1) {
        updatedSectionConfig.splice(sectionConfigItemIndex, 1)
      }

      let changes = [
        {
          editedBy: idPassport,
          updatePath: ['sectionConfig'],
          updatedData: updatedSectionConfig,
          updatedMs: +new Date(),
        },
      ]
      const token = await SessionService.prepareAuthTokens(idPassport, password)
      await ConfigManagerService.updateConfig(
        selectedAssociation,
        'dataFlow',
        changes,
        { username: idPassport, password },
        selectedFlow,
      )

      // remove corresponding dataFlow item
      delete dataFlowsConfig[selectedFlow].dataFlow[sectionName]
      changes = [
        {
          editedBy: idPassport,
          updatePath: ['dataFlow'],
          // @ts-ignore (ignore what might be an incorrect backend type issue)
          updatedData: dataFlowsConfig[selectedFlow].dataFlow,
          updatedMs: +new Date(),
        },
      ]

      const response = await ConfigManagerService.updateConfig(
        selectedAssociation,
        'dataFlow',
        changes,
        { username: idPassport, password },
        selectedFlow,
      )

      if (response.result === 'success') {
        const { selectedAssociation, associationRepo, updateState } = this.props
        associationRepo.setDataFlowConfig(selectedAssociation, selectedFlow, response.updatedConfig)
      } else {
        this.toggleSaveFailedModal()
      }
    }
    this.setState({ detailsRefreshTimestamp: +new Date() })
    this.closeModals()
  }

  addFieldToSection = async (newFieldConfigItem: IFieldConfigItem, sectionName: string) => {
    this.setState({ savingModalOpen: true })

    const { selectedFlow } = this.state
    const { idPassport, password, selectedAssociation, associationRepo } = this.props
    const dataFlowsConfig = associationRepo.getAllDataFlows(selectedAssociation)
    const dataFlowItemData = dataFlowsConfig[selectedFlow].dataFlow[sectionName]

    if (!dataFlowItemData.find((fieldConfigItem: IFieldConfigItem) => fieldConfigItem.key === newFieldConfigItem.key)) {
      // @ts-ignore
      dataFlowItemData.push({ key: newFieldConfigItem.key })
    }

    let changes = [
      {
        editedBy: idPassport,
        updatePath: ['dataFlow', sectionName],
        updatedData: dataFlowItemData,
        updatedMs: +new Date(),
      },
    ]

    const response = await ConfigManagerService.updateConfig(
      selectedAssociation,
      'dataFlow',
      changes,
      { username: idPassport, password },
      selectedFlow,
    )

    if (response.result === 'success') {
      const { selectedAssociation, associationRepo, updateState } = this.props
      associationRepo.setDataFlowConfig(selectedAssociation, selectedFlow, response.updatedConfig)
    } else {
      this.toggleSaveFailedModal()
    }

    this.setState({ savingModalOpen: false, detailsRefreshTimestamp: +new Date() })
  }

  removeFieldFromSection = async (fieldKey: string, sectionName: string) => {
    this.setState({ savingModalOpen: true })

    const { selectedFlow } = this.state
    const { idPassport, password, selectedAssociation, associationRepo } = this.props
    const dataFlowsConfig = associationRepo.getAllDataFlows(selectedAssociation)
    const dataFlowItemData = dataFlowsConfig[selectedFlow].dataFlow[sectionName]

    const fieldIndex = dataFlowItemData.findIndex(
      (fieldConfigItem: IFieldConfigItem) => fieldConfigItem.key === fieldKey,
    )
    if (fieldIndex > -1) {
      dataFlowItemData.splice(fieldIndex, 1)
    }

    let changes = [
      {
        editedBy: idPassport,
        updatePath: ['dataFlow', sectionName],
        updatedData: dataFlowItemData,
        updatedMs: +new Date(),
      },
    ]

    const response = await ConfigManagerService.updateConfig(
      selectedAssociation,
      'dataFlow',
      changes,
      { username: idPassport, password },
      selectedFlow,
    )

    if (response.result === 'success') {
      const { selectedAssociation, associationRepo, updateState } = this.props
      associationRepo.setDataFlowConfig(selectedAssociation, selectedFlow, response.updatedConfig)
    } else {
      this.toggleSaveFailedModal()
    }

    this.setState({ savingModalOpen: false, detailsRefreshTimestamp: +new Date() })
  }

  saveNewFieldOrder = async () => {
    this.toggleSavingModal()

    const { selectedFlow, selectedSection, newFieldOrder } = this.state
    const { idPassport, password, selectedAssociation, associationRepo } = this.props

    let changes = [
      {
        editedBy: idPassport,
        updatePath: ['dataFlow', selectedSection],
        updatedData: newFieldOrder,
        updatedMs: +new Date(),
      },
    ]

    const response = await ConfigManagerService.updateConfig(
      selectedAssociation,
      'dataFlow',
      changes,
      { username: idPassport, password },
      selectedFlow,
    )

    if (response.result === 'success') {
      associationRepo.setDataFlowConfig(selectedAssociation, selectedFlow, response.updatedConfig)
    } else {
      this.toggleSaveFailedModal()
    }

    this.setState({ detailsRefreshTimestamp: +new Date(), newFieldOrder: [''], savingModalOpen: false }) // NOTE: should probably not be necessary once updateState is used
  }

  openSelectorModal = () => this.setState({ selectorModalOpen: true })
  closeSelectorModal = () => this.setState({ selectorModalOpen: false })

  toggleSavingModal = () => this.setState((prevState) => ({ savingModalOpen: !prevState.savingModalOpen }))

  removeDataFlowPart = (name: string) => name.replace('dataFlow___', '')

  saveAndChangeFlow = (selectedFlow: string) => {
    const fieldOrderChanged = this.state.newFieldOrder.length > 1
    if (fieldOrderChanged) {
      this.setState({ unsavedChangesModalOpen: true })
      return
    }
    this.props.history.push(`/configurator/dataflows/${this.removeDataFlowPart(selectedFlow)}`)
    this.setState({ selectedFlow, ...this.initialCoreState })
  }

  copySettingsAndSaveNewFlow = async (newFlowName: string) => {
    const key = `dataFlow___${startCase(newFlowName).replace(/\s/g, '___')}`
    // replace all spaces with underscores

    const { selectedFlow } = this.state
    const { idPassport, selectedAssociation, associationRepo, password } = this.props

    const dataFlowsConfig = associationRepo.getAllDataFlows(selectedAssociation)
    const currentSelectedFlowData = dataFlowsConfig[selectedFlow]
    // dataFlowsConfig[key] = { ...currentSelectedFlowData };

    let changes = [
      {
        editedBy: idPassport,
        updatePath: [],
        updatedData: { ...currentSelectedFlowData },
        updatedMs: +new Date(),
      },
    ]
    let response = await ConfigManagerService.updateConfig(
      selectedAssociation,
      'dataFlow',
      changes,
      { username: idPassport, password },
      key,
    )

    if (response.result === 'success') {
      associationRepo.setDataFlowConfig(selectedAssociation, key, response.updatedConfig)
    } else {
      this.toggleSaveFailedModal()
    }

    this.toggleDuplicateModal()
  }

  handleFlowAdded = (dataFlowName: string, updatedConfig: IDataFlow) => {
    const { selectedAssociation, associationRepo } = this.props
    // update Redux state now that new flow been saved to db
    associationRepo.setDataFlowConfig(selectedAssociation, dataFlowName, updatedConfig)
    this.props.updateState({ associationRepo })
  }

  handleFieldAddition = (fieldLabel: string) => {
    const { selectedAssociation, associationRepo } = this.props
    const fieldConfig = associationRepo.getFieldConfig(selectedAssociation)
    const fieldLabelToKeyMapping: Record<string, string> = Object.keys(fieldConfig).reduce((acc, key) => {
      // @ts-ignore
      acc[fieldConfig[key].label] = key
      return acc
    }, {})
    const fieldKey = fieldLabelToKeyMapping[fieldLabel]
    const fieldConfigItem = fieldConfig[fieldKey]

    this.addFieldToSection(fieldConfigItem, this.state.selectedSection)
    this.toggleAddFieldModal()
  }

  render() {
    const { selectedAssociation, associationRepo, userRepo } = this.props
    const {
      selectedFlow,
      selectedSection,
      detailsRefreshTimestamp,
      editAction,
      editSectionLabel,
      addNewSection,
      duplicateModalOpen,
      savingModalOpen,
      unsavedChangesModalOpen,
      saveFailedModalOpen,
      addFieldModalOpen,
    } = this.state

    const dataFlowsConfig = associationRepo.getAllDataFlows(selectedAssociation)
    const fieldConfig = getFieldConfigExclPpeItems(associationRepo, selectedAssociation)
    const organisationConfig = associationRepo.getOrganisationConfig(selectedAssociation)

    const allDataFields = Object.keys(fieldConfig)
    const selectedSectionFields = selectedSection
      ? dataFlowsConfig[selectedFlow].dataFlow[selectedSection].map((item: IFieldConfigItem) => item.key)
      : []
    const availableDataFields = selectedSectionFields.length
      ? difference(allDataFields, selectedSectionFields)
      : allDataFields

    const flowDetails: any = selectedFlow ? dataFlowsConfig[selectedFlow] : {}

    const fieldOrderChanged = this.state.newFieldOrder.length > 1
    const user = userRepo.getCurrentUserEntity()

    return (
      <div style={styles.container}>
        <NavigationBar match={this.props.match} location={this.props.location} history={this.props.history} />
        <SectionHeaderPrimary
          ref={this.sectionHeaderRef}
          style={styles.sectionHeader}
          disabled={true}
          searchString={''}
          onClick={() => ({})}>
          Data Flows
        </SectionHeaderPrimary>
        <div style={styles.contentContainer}>
          <SideMenu
            visible={true}
            menuComponents={
              <DataFlowsSideMenu
                selectedFlow={selectedFlow}
                selectedAssociation={selectedAssociation}
                dataflowConfig={dataFlowsConfig}
                organisationConfig={organisationConfig}
                onDivisionChange={(division: string) => this.setState({ selectedDivision: division })}
                onFlowClick={this.saveAndChangeFlow}
                onFlowAdded={this.handleFlowAdded}
              />
            }
          />
          <DataFlowDetailsContainer
            selectedFlow={selectedFlow}
            selectedSection={selectedSection}
            onDuplicateClick={this.toggleDuplicateModal}
            onSectionAddClick={() => this.setState({ addNewSection: true })}
            fieldOrderChanged={fieldOrderChanged}
            onUndoFieldReorderClick={() =>
              this.setState({
                newFieldOrder: [],
                detailsRefreshTimestamp: +new Date(),
              })
            }
            onSaveFieldReorderClick={this.saveNewFieldOrder}
            toggleAddFieldModal={this.toggleAddFieldModal}>
            {isEmpty(flowDetails) ? (
              <SelectFlowInstruction />
            ) : (
              <DataFlowDetails
                selectedFlow={selectedFlow}
                dataFlow={selectedFlow ? dataFlowsConfig[selectedFlow].dataFlow : undefined}
                sectionConfig={selectedFlow ? dataFlowsConfig[selectedFlow]?.sectionConfig : undefined}
                selectedSection={this.state.selectedSection}
                onSectionClick={(sectionName: string) => this.setState({ selectedSection: sectionName })}
                onSectionEditClick={(sectionLabel: string) =>
                  this.setState({ editSectionLabel: sectionLabel, editAction: 'rename' })
                }
                onFieldRemoveClick={this.removeFieldFromSection}
                onOrderChange={(dataFlowItems: string[]) =>
                  this.setState({
                    newFieldOrder: dataFlowItems,
                    // detailsRefreshTimestamp: +new Date(),
                  })
                }
                openSelectorModal={this.openSelectorModal}
                remount={() => this.setState({ detailsRefreshTimestamp: +new Date() })}
                key={`${selectedFlow}_${detailsRefreshTimestamp}`} // triggers re-render on each flow selection from the sidemenu & sectionConfig update
              />
            )}
          </DataFlowDetailsContainer>
        </div>
        <AlertOneButton
          open={unsavedChangesModalOpen}
          onClick={() => this.closeModals()}
          buttonLabel="Ok"
          header="UNSAVED CHANGES"
          body="Cannot switch context without first undoing or saving field ordering changes"
        />
        <AlertOneButton
          open={saveFailedModalOpen}
          header="Failed to save changes"
          body={
            <div>
              Changes won't be permanently applied. Please try again.
              <p>If problem persists, please contact customer support.</p>
            </div>
          }
          buttonLabel={'Ok'}
          onClick={this.toggleSaveFailedModal}
        />
        <LoadingModal open={savingModalOpen}>Saving changes...</LoadingModal>
        <RouteLeavingGuard
          when={fieldOrderChanged} // TODO: should be true if there are changes unsaved
          navigate={(path) => this.props.history.push(path)}
          shouldBlockNavigation={(location) => true}
          alertHeader="Discard changes"
          alertBody={
            <div>
              You have unsaved changes.
              <br />
              Are you sure you want to leave this page without saving?
            </div>
          }
        />
        <AlertTwoButton
          open={editSectionLabel?.length > 0 && editAction === 'remove'}
          dismiss={this.closeModals}
          onClick1={this.closeModals}
          onClick2={this.removeSection}
          buttonLabel1="No, cancel"
          buttonLabel2="Yes, proceed"
          header="Confirm Removal"
          buttonStyle={{ marginTop: 30 }}
          body={`Are you sure you want to remove the "${editSectionLabel}" section?`}
        />
        <LabelCollector
          open={editSectionLabel?.length > 0 && editAction === 'rename'}
          warning="Enter new section name"
          placeholder={`Rename '${editSectionLabel}'`}
          buttonLabel="Confirm rename"
          buttonDisabledBehaviour={true}
          footer={
            <ButtonRed style={{ marginTop: 10 }} onClick={() => this.setState({ editAction: 'remove' })}>
              Delete section
            </ButtonRed>
          }
          iconName="setting"
          dismiss={this.closeModals}
          submit={this.saveSectionName}
        />
        <LabelCollector
          open={addNewSection}
          warning="Enter section name"
          placeholder="Enter section name"
          buttonLabel="Add"
          iconName="setting"
          dismiss={this.closeModals}
          submit={this.saveNewSection}
        />
        <LabelCollector
          open={duplicateModalOpen}
          warning="Enter data flow name"
          placeholder="Enter data flow name"
          buttonLabel="Add"
          iconName="setting"
          dismiss={this.toggleDuplicateModal}
          submit={this.copySettingsAndSaveNewFlow}
        />
        <LabelCollector
          open={addFieldModalOpen}
          warning="Choose field name"
          placeholder="Choose field name"
          buttonLabel="Add"
          iconName="setting"
          dismiss={this.toggleAddFieldModal}
          submit={this.handleFieldAddition}
          pickerItems={availableDataFields.map((fieldKey) => fieldConfig[fieldKey].label).sort()}
          buttonDisabledBehaviour={true}
        />
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255, 1), rgba(209,210,230, 1))',
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto 1%',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    paddingInline: 'max(2em, 2%)',
    width: 'calc(100vw - max(2em, 2%) - max(260px, 20%))', // screen width minus inline padding and the side menu size
  },
  rightSideContent: {
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    display: 'flex',
    flex: 1,
    overflow: 'hidden',
    height: 'auto',
    backgroundColor: ColorPalette.CARD_WHITE,
  },
  button: {
    fontWeight: 'bolder',
    fontSize: '0.8rem',
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    ':hover': {
      color: ColorPalette.PRIMARY_BLUE,
    },
    ':active': {
      color: ColorPalette.DARK_GREY,
    },
  },
  sections: {
    width: '100%',
    overflow: 'auto',
  },
}

const mapStateToProps = (state: PeopleFlowCombinedReducer) => {
  return {
    idPassport: state.sessionManager.idPassport,
    password: state.sessionManager.password,
    associationRepo: state.sessionManager.associationRepo as AssociationSettingsRepository,
    userRepo: state.sessionManager.userRepo as UserRepository,

    selectedAssociation: state.sessionManager.selectedAssociation,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateState: (data: any) => dispatch({ type: ActionType.UPDATE_STATE, data }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Radium(DataFlows))

const SelectFlowInstruction = () => (
  <div
    style={{
      paddingTop: '23.5%',
      fontSize: '0.85rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: ColorPalette.SECONDARY_TEXT,
      width: '100%',
    }}>
    <Icon path={mdiInformationOutline} size={1} color={ColorPalette.PRIMARY_BLUE} style={{ marginRight: '.75em' }} />
    Select a data capture flow on the left to manage settings
  </div>
)

const DataFlowDetailsContainer = (props: any) => (
  <div style={styles.rightSide}>
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', alignContent: 'center', gap: 80 }}>
        <BackButton />
        <div style={{ display: 'flex', gap: 20 }}>
          {props.fieldOrderChanged ? (
            <>
              <ButtonGeneric
                iconBefore={
                  <Icon
                    style={{ width: '1.25rem', marginRight: '0.5em' }}
                    path={mdiUndo}
                    color={ColorPalette.PRIMARY_BLUE}
                  />
                }
                onClick={props.onUndoFieldReorderClick}
                label="UNDO FIELD RE-ORDER"
                labelStyle={{ color: ColorPalette.SECONDARY_TEXT }}
                style={
                  props.selectedFlow === ''
                    ? { fontSize: '0.8rem', width: 'max-content', cursor: 'not-allowed' }
                    : { fontSize: '0.8rem', width: 'max-content' }
                }
                disabled={props.selectedFlow === ''}
              />
              <ButtonGeneric
                iconBefore={
                  <Icon
                    style={{ width: '1.25rem', marginRight: '0.5em' }}
                    path={mdiContentSave}
                    color={ColorPalette.PRIMARY_BLUE}
                  />
                }
                onClick={props.onSaveFieldReorderClick}
                label="SAVE FIELD RE-ORDER"
                labelStyle={{ color: ColorPalette.SECONDARY_TEXT }}
                style={
                  props.selectedFlow === ''
                    ? { fontSize: '0.8rem', width: 'max-content', cursor: 'not-allowed' }
                    : { fontSize: '0.8rem', width: 'max-content' }
                }
                disabled={props.selectedFlow === ''}
              />
            </>
          ) : null}
        </div>
      </div>
      <div style={{ display: 'flex', gap: 35, padding: 10 }}>
        {props.selectedFlow !== '' && (
          <ButtonGeneric
            iconBefore={
              <Icon
                style={{ width: '1.25rem', marginRight: '0.5em' }}
                path={mdiPlus}
                color={ColorPalette.PRIMARY_BLUE}
              />
            }
            onClick={props.onSectionAddClick}
            label="ADD SECTION"
            labelStyle={{ color: ColorPalette.SECONDARY_TEXT }}
            title="Add a new section to the current selected flow"
            style={{ fontSize: '0.8rem', width: 'max-content' }}
          />
        )}
        {props.selectedSection && (
          <ButtonGeneric
            iconBefore={
              <Icon
                style={{ width: '1.25rem', marginRight: '0.5em' }}
                path={mdiPlus}
                color={ColorPalette.PRIMARY_BLUE}
              />
            }
            onClick={props.toggleAddFieldModal}
            label="ADD FIELD"
            labelStyle={{ color: ColorPalette.SECONDARY_TEXT }}
            title="Add a new field to the current selected section"
            style={{ fontSize: '0.8rem' }}
          />
        )}
        {props.selectedFlow !== '' && (
          <ButtonGeneric
            iconBefore={
              <Icon
                style={{ width: '1.25rem', marginRight: '0.5em' }}
                path={mdiContentDuplicate}
                color={ColorPalette.PRIMARY_BLUE}
              />
            }
            onClick={props.onDuplicateClick}
            label="DUPLICATE CONFIG"
            labelStyle={{ color: ColorPalette.SECONDARY_TEXT }}
            title="Duplicate the current selected flow with its fields and sections"
            style={
              props.selectedFlow === ''
                ? { fontSize: '0.8rem', width: 'max-content', cursor: 'not-allowed' }
                : { fontSize: '0.8rem', width: 'max-content' }
            }
            disabled={props.selectedFlow === ''}
          />
        )}
      </div>
    </div>
    <div style={styles.rightSideContent}>
      <div style={styles.sections}>{props.children}</div>
    </div>
  </div>
)
