import { AllScreenNames } from '../../config'
import { isPeopleFlowEmployee } from '../../utils'
import { IMenuLabel, SubMenu } from '../../models'

import { mdiAlertCircle, mdiAccountMultipleCheck, mdiFileDocumentMultiple, mdiClipboardClock } from '@mdi/js'
import { AssociationSettingsRepository, UserRepository } from '../../repositories'

export class NavigationContent {
  static getMenuLabels(
    userRepo: UserRepository,
    associationRepo: AssociationSettingsRepository,
    selectedAssociation: string,
    selectedRole?: string,
  ): IMenuLabel[] {
    const { permissionResult } = associationRepo.getUserAccess(selectedAssociation, selectedRole)
    const hasPpeAccess =
      permissionResult?.hasPpeStockManagementAccess || permissionResult?.hasPpeIssueRequestCreationAccess
    let reportingLabels = [{ label: AllScreenNames.DOCUMENT_VALIDITY, screenName: AllScreenNames.DOCUMENT_VALIDITY }]
    let bulkActionLabels: SubMenu[] = []
    let peopleLabels: SubMenu[] = []
    let requestLabels: SubMenu[] = []
    let menuLabels: IMenuLabel[] = [
      {
        label: AllScreenNames.REPORTING,
        screenName: AllScreenNames.REPORTING,
        subMenus: reportingLabels,
        positionRight: hasPpeAccess ? 543 : 425,
        hasAccess: (() => {
          if (permissionResult === undefined) {
            return false
          }
          return permissionResult.hasDataLogsAccess || permissionResult.hasDeviceLogsAccess
        })(),
      },
    ]
    if (selectedRole !== 'AUDITOR') {
      const hasPpeConfigAccess =
        permissionResult?.hasPpeSuperuserAccess || permissionResult?.hasPpeStockManagementAccess

      const hasPpeAccess =
        permissionResult?.hasPpeSuperuserAccess ||
        permissionResult?.hasPpeStockManagementAccess ||
        permissionResult?.hasPpeIssueRequestCreationAccess

      const user = userRepo.getCurrentUserEntity()
      const userInfo = user.getPersonalUserInfo()
      const isStaff = isPeopleFlowEmployee(userInfo)

      menuLabels.push({
        label: AllScreenNames.CONFIGURATOR,
        screenName: AllScreenNames.CONFIGURATOR,
        subMenus: (() => {
          let arr = [{ label: AllScreenNames.USERS, screenName: AllScreenNames.USERS }]
          if (permissionResult?.hasFormsAccess) {
            arr.unshift({ label: AllScreenNames.FORMS, screenName: AllScreenNames.FORMS })
          }
          if (isStaff) {
            arr.unshift({ label: AllScreenNames.DATA_QUERIES, screenName: AllScreenNames.DATA_QUERIES })
          }
          if (hasPpeConfigAccess) {
            arr.splice(1, 0, {
              label: AllScreenNames.PPE_CONFIG,
              screenName: AllScreenNames.PPE_CONFIG,
            })
          }
          if (permissionResult?.hasConfigEditAccess) {
            arr.splice(hasPpeConfigAccess ? 2 : 1, 0, {
              label: AllScreenNames.PROCESSES,
              screenName: AllScreenNames.PROCESSES,
            })
            arr = [
              {
                label: AllScreenNames.DATA_FIELDS,
                screenName: AllScreenNames.DATA_FIELDS,
              },
              {
                label: AllScreenNames.DATA_FLOWS,
                screenName: AllScreenNames.DATA_FLOWS,
              },
              ...arr,
            ]
          }

          if (isStaff) {
            arr.unshift({
              label: AllScreenNames.CONFIGURATOR_CONFIG,
              screenName: AllScreenNames.CONFIGURATOR_CONFIG,
            })
          }
          return arr
        })(),
        positionRight: hasPpeAccess ? 673 : 555,
        hasAccess: (() => {
          if (permissionResult === undefined) {
            return false
          }
          return permissionResult.hasUserManagementAccess || permissionResult.hasFormsAccess
        })(),
      })

      bulkActionLabels = [
        { label: AllScreenNames.IMPORT, screenName: AllScreenNames.IMPORT },
        { label: AllScreenNames.EXPORT, screenName: AllScreenNames.EXPORT },
        { label: AllScreenNames.DISCIPLINE, screenName: AllScreenNames.DISCIPLINE },
      ]
      peopleLabels = [
        { label: AllScreenNames.EMPLOYEE, screenName: AllScreenNames.EMPLOYEE },
        { label: AllScreenNames.CANDIDATE, screenName: AllScreenNames.CANDIDATE },
        { label: AllScreenNames.TRASH, screenName: AllScreenNames.TRASH },
      ]
      requestLabels = [{ label: AllScreenNames.REQUESTS_PPE, screenName: AllScreenNames.REQUESTS_PPE }]

      if (permissionResult?.hasBulkTerminationAccess) {
        bulkActionLabels.push({ label: AllScreenNames.TERMINATE, screenName: AllScreenNames.TERMINATE })
      }
      if (permissionResult?.hasBillingAccess) {
        reportingLabels.unshift({ label: AllScreenNames.BILLING, screenName: AllScreenNames.BILLING })
      }
      if (permissionResult?.hasGroupManageAccess || hasPpeAccess) {
        peopleLabels.push({ label: AllScreenNames.GROUPS, screenName: AllScreenNames.GROUPS })
      }
      if (permissionResult?.hasPpeStockManagementAccess) {
        reportingLabels.push({ label: AllScreenNames.PPE_STOCK, screenName: AllScreenNames.PPE_STOCK })
      }

      let defaultLabels = [
        {
          label: AllScreenNames.GLOBAL_LOGS,
          screenName: AllScreenNames.GLOBAL_LOGS,
          subMenus: [
            { label: AllScreenNames.DATA_LOGS, screenName: AllScreenNames.DATA_LOGS },
            { label: AllScreenNames.DEVICE_LOGS, screenName: AllScreenNames.DEVICE_LOGS },
          ],
          positionRight: 40,
          hasAccess: (() => {
            if (permissionResult === undefined) {
              return false
            }
            return permissionResult.hasDataLogsAccess || permissionResult.hasDeviceLogsAccess
          })(),
        },
        {
          label: AllScreenNames.BULK_ACTIONS,
          screenName: AllScreenNames.BULK_ACTIONS,
          subMenus: bulkActionLabels,
          positionRight: 175,
          hasAccess: (() => {
            if (permissionResult === undefined) {
              return false
            }
            return permissionResult.hasWorkforceAccess
          })(),
          subSubMenus: {
            [AllScreenNames.EXPORT]: [
              {
                iconName: mdiAccountMultipleCheck,
                screenName: AllScreenNames.EXPORT_PROFILES,
                label: 'PROFILES',
              },
              {
                iconName: mdiFileDocumentMultiple,
                screenName: AllScreenNames.EXPORT_DOCS,
                label: 'DOCS',
              },
              {
                iconName: mdiClipboardClock,
                screenName: AllScreenNames.EXPORT_DOC_VALIDITY_DATA,
                label: 'DOC VALIDITY DATA',
              },
            ],
            [AllScreenNames.DISCIPLINE]: [
              {
                iconName: mdiAlertCircle,
                screenName: AllScreenNames.BULK_ACTIONS, // TODO: change?
                label: 'UNDER PERFORMANCE',
              },
            ],
          },
        },
        {
          label: AllScreenNames.PEOPLE,
          screenName: AllScreenNames.PEOPLE,
          subMenus: peopleLabels,
          positionRight: 315,
          hasAccess: (() => {
            if (permissionResult === undefined) {
              return false
            }
            return permissionResult.hasWorkforceAccess || permissionResult.hasTrashAccess
          })(),
        },
      ]

      if (hasPpeAccess) {
        defaultLabels = [
          ...defaultLabels,
          {
            label: AllScreenNames.REQUESTS,
            screenName: AllScreenNames.REQUESTS,
            subMenus: requestLabels,
            positionRight: 420,
            hasAccess: (() => {
              if (permissionResult === undefined) {
                return false
              }
              return permissionResult.hasWorkforceAccess || permissionResult.hasTrashAccess
            })(),
          },
        ]
      }

      menuLabels = [...defaultLabels, ...menuLabels]
    }

    return menuLabels
  }
}
