import Papa from 'papaparse'

export enum CsvDateFormatStandard {
  ISO = 'ISO',
  UK = 'UK',
  US = 'US',
}
export type CsvDateFormatStandardType = CsvDateFormatStandard.ISO | CsvDateFormatStandard.UK | CsvDateFormatStandard.US

export type CsvFileContentTranformers = {
  transformValue?: (value: string, header?: string | number) => string
  transformHeader?: (header: string, index: number) => string
}

export class CsvService {
  static csvToJson = async (csvFile: File, transforms?: CsvFileContentTranformers) => {
    return new Promise((resolve, reject) => {
      const config = {
        header: true, // assumes the first row in your CSV file contains headers
        skipEmptyLines: true, // ignores empty lines in CSV file
        transform: (value: string, header: string | number) => {
          // throw new Error('transform should not be called')
          let transformedValue = value.trim() // default transform
          if (transforms?.transformValue) {
            // do any further transforms
            transformedValue = transforms.transformValue(transformedValue, header)
          }
          return transformedValue
        },
        transformHeader: (header: string, index: number) => {
          let transformedHeader = header.trim() // default transform
          if (transforms?.transformHeader) {
            // do any further transforms
            transformedHeader = transforms.transformHeader(transformedHeader, index)
          }
          return transformedHeader
        },
        complete: (result: any) => {
          resolve(result.data)
        },
        error: (error: any) => {
          console.error('csvToJson error: ', error)
          reject(error.message)
        },
      }

      Papa.parse(csvFile, config)
    })
  }

  static jsonToCsv = (jsonData: any, config?: Record<string, any>) => {
    // https://www.papaparse.com/docs#json-to-csv
    return Papa.unparse(jsonData, config)
  }

  static compileCsvTemplateHeaderRow = (headers: string[]) => {
    let csvRows = []
    csvRows.push(headers.join(','))
    // returning the array joining with new line
    return csvRows.join('\n')
  }

  static downloadCsvFile = (data: string, fileName: string = 'template.csv') => {
    // https://www.geeksforgeeks.org/how-to-create-and-download-csv-file-in-javascript/

    // create a Blob for having a csv file format and pass the data with type
    const blob = new Blob([data], { type: 'text/csv' })
    // create an object for downloading url
    const url = window.URL.createObjectURL(blob)
    // create an anchor(a) tag of HTML
    const a = document.createElement('a')
    // pass the blob downloading url
    a.setAttribute('href', url)
    // set the anchor tag attribute for downloading and passing the download file name
    a.setAttribute('download', fileName)
    // perform download with click
    a.click()
  }
}

// export const parse = async (csvFile: File) => {}
