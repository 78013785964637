import { ActionButton, ActionButtonType } from './ActionButton'
import { NavButton, NavButtonType } from './NavButton'

type ToolbarProps = {
  navButtons?: {
    left?: NavButtonType
    right?: NavButtonType
  }
  actionButtons?: ActionButtonType[]
  style?: React.CSSProperties
}

export const Toolbar = (props: ToolbarProps) => {
  const renderNavButton = (navButtonProps: NavButtonType, direction: 'left' | 'right', index: number) => (
    <NavButton {...navButtonProps} direction={direction} key={`navButton-${navButtonProps.label}_${index}`} />
  )

  const renderActionButton = (actionButtonProps: ActionButtonType, index: number) => (
    <ActionButton {...actionButtonProps} key={`actionButton-${actionButtonProps.label}_${index}`} />
  )

  const renderActionButtons = (actionButtons: ActionButtonType[]) => {
    return actionButtons.map((actionButtonProps, index) => renderActionButton(actionButtonProps, index))
  }

  let backButton = null
  if (props.navButtons?.left) {
    backButton = <div>{renderNavButton(props.navButtons.left, 'left', 0)}</div>
  }

  let nextButton = null
  if (props.navButtons?.right) {
    nextButton = <div>{renderNavButton(props.navButtons.right, 'right', 0)}</div>
  }

  let actionButtons = null
  if (props.actionButtons && props.actionButtons.length > 0) {
    actionButtons = <div style={styles.actionButtonContainer}>{renderActionButtons(props.actionButtons)}</div>
  }

  return (
    <div style={{ ...styles.container, ...props.style }}>
      <div>{backButton}</div>
      <div style={styles.actionButtons}>
        {actionButtons}
        {nextButton}
      </div>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  actionButtons: {
    display: 'flex',
    gap: 60,
  },
  actionButtonContainer: {
    display: 'flex',
    gap: 30,
    height: 40,
  },
}
