import { ColorPalette } from '../../config'
import { removeUnderscores } from '../../utils'
import { StatusDot } from '../../utils/groups'
import { ExpandableSection } from '../GeneralUI/ExpandableSection/ExpandableSection'
import { GroupMembers, GroupMember } from '../../types'

type GroupMemberStatsProps = {
  members: GroupMembers
  isComplete?: boolean | undefined
}

export const GroupMemberStats = (props: GroupMemberStatsProps) => {
  const today = new Date()

  // const getDaysToExpiry = (terminationDate: Date) =>
  //     Math.ceil((terminationDate.getTime() - today.getTime()) / (1000 * 3600 * 24));

  // NOTE: hopefully the following state calcs could be removed post-demo if backend returns member data with these counts
  const getTotalMembers = (members: GroupMembers) => {
    return Object.keys(members).length
  }

  const getUpcomingMembers = (members: GroupMembers, jobType?: string) => {
    return Object.values(members).filter((memberDetails: GroupMember) => {
      if (memberDetails && memberDetails.proposedStartDate && today < memberDetails.proposedStartDate) {
        if (jobType) {
          return memberDetails.jobType === jobType
        }
        return true
      }
      return false
    }).length
  }

  const getActiveMembers = (members: GroupMembers, jobType?: string) => {
    return Object.values(members).filter((memberDetails: GroupMember) => {
      if (
        memberDetails &&
        memberDetails.proposedStartDate &&
        memberDetails.proposedTerminationDate &&
        today >= memberDetails.proposedStartDate &&
        today <= memberDetails.proposedTerminationDate
      ) {
        if (jobType) {
          return memberDetails.jobType === jobType
        }
        return true
      }
      return false
    }).length
  }

  const getCompletedMembers = (members: GroupMembers, jobType?: string) => {
    return Object.values(members).filter((memberDetails: GroupMember) => {
      if (memberDetails && memberDetails.proposedTerminationDate && today > memberDetails.proposedTerminationDate) {
        if (jobType) {
          return memberDetails.jobType === jobType
        }
        return true
      }
      return false
    }).length
  }

  const getAllGroupMemberJobTypes = (members: GroupMembers) => [
    ...new Set(Object.values(members).map((member: GroupMember) => member.jobType)),
  ]

  const totalMembers = getTotalMembers(props.members)
  const activeMembers = getActiveMembers(props.members)
  const completedMembers = getCompletedMembers(props.members)
  const groupMemberJobTypes = getAllGroupMemberJobTypes(props.members)

  return (
    <div>
      <div
        style={{
          display: 'flex',
          gap: '3em',
          color: props.isComplete ? ColorPalette.DISABLED_TEXT : ColorPalette.PRIMARY_TEXT,
        }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1em' }}>
          <StatusDot status="Upcoming" />
          {`${totalMembers - activeMembers - completedMembers} Upcoming`}
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1em' }}>
          <StatusDot status="Active" />
          {`${activeMembers} Active`}
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1em' }}>
          <StatusDot status="Closed" />
          {`${completedMembers} Closed`}
        </div>
      </div>
      <ExpandableSection
        title={'Counts by job type'}
        initialCollapsed={true}
        style={{ marginTop: '1.5em', paddingBottom: '10px' }}
        titleStyle={{
          color: props.isComplete ? ColorPalette.DISABLED_TEXT : ColorPalette.PRIMARY_TEXT,
        }}
        buttonStyle={{ backgroundColor: 'inherit', width: 'fit-content' }}>
        <div style={{ padding: '1em 0em 0em 1.5em' }}>
          {groupMemberJobTypes.length > 0 ? (
            groupMemberJobTypes.map((jobType: string) => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '2em',
                  fontSize: '0.85em',
                }}
                key={jobType}>
                <div style={{ width: '60%' }}>{`-  ${removeUnderscores(jobType)}`}</div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5em' }}>
                  <StatusDot status="Upcoming" />
                  {getUpcomingMembers(props.members, jobType)}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5em' }}>
                  <StatusDot status="Active" />
                  {getActiveMembers(props.members, jobType)}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5em' }}>
                  <StatusDot status="Closed" />
                  {getCompletedMembers(props.members, jobType)}
                </div>
              </div>
            ))
          ) : (
            <div style={{ fontSize: '0.95em' }}>No members in group</div>
          )}
        </div>
      </ExpandableSection>
    </div>
  )
}
