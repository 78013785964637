import React from 'react'
import Icon from '@mdi/react'
import { mdiInformationOutline } from '@mdi/js'
import { ColorPalette } from '../../../config'

export const UserFeedback = (props: { message: string; style?: React.CSSProperties }) => (
  <div style={{ ...styles.userFeedback, ...props.style }}>
    <Icon path={mdiInformationOutline} size={1} style={{ color: ColorPalette.PRIMARY_BLUE, marginRight: '0.8em' }} />{' '}
    {props.message}
  </div>
)

const styles = {
  userFeedback: {
    display: 'flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    color: ColorPalette.SECONDARY_TEXT,
    fontSize: '0.85rem',
  },
}
