/**
 *
 * @param params - either the emails or the passport number that is being
 * tested against
 * @returns - a boolean indicating whether or not the params belong to an
 * employee of PeopleFlow
 */
export function isPeopleFlowEmployee(params: {
  username?: string
  name?: string
  surname?: string
  email?: string
}): boolean {
  const { username, name, email, surname } = params
  if (email !== undefined) {
    const includes = [
      'jason@peopleflow.co.za',
      'richard@peopleflow.co.za',
      'justin@peopleflow.co.za',
      'justinhall@quietlife.co.za',
      'jane@peopleflow.co.za',
      'paul@peopleflow.co.za',
      'maydiene@peopleflow.co.za',
      'nicki.bleeker@gmail.com',
      'angelique@peopleflow.co.za',
      'stefan@peopleflow.co.za',
    ].includes(email)
    if (includes) {
      return true
    }
  }

  if (username === '9403210106081') {
    return true
  }

  if (name !== undefined && surname !== undefined) {
    if (name === 'JANE' && surname === 'MANN') {
      return true
    }

    if (name === 'JASON' && surname === 'GEORGE') {
      return true
    }

    if (name === 'RICHARD' && surname === 'SMITH') {
      return true
    }

    if (name === 'PAUL' && surname === 'KIM') {
      return true
    }

    if (name === 'MAYDIENE' && surname === 'LOUW') {
      return true
    }

    if (name === 'Nicki' && surname === 'Bleeker') {
      return true
    }
  }

  return false
}
