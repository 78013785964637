import { ColorPalette } from '../../config/colors'

import React, { Component } from 'react'
import Radium from 'radium'
import { mdiShieldLockOutline, mdiEmailSend, mdiTextBoxPlus, mdiArrowLeftBold, mdiWrench } from '@mdi/js'
import Icon from '@mdi/react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import ButtonGeneric from '../BaseComponents/Buttons/ButtonGeneric'
import ButtonRound from '../BaseComponents/Buttons/ButtonRound'
import { flattenEntries, randomIdString, sortArrayOfObjects } from '../../utils'

dayjs.extend(relativeTime)

export interface ConfigScreenOption {
  title: string
  icon: string
  getConfig: (selectedEmployer: string) => any
}

interface ConfigManagerMenuProps {
  selectedViewMode: string
  configViewOptions: Record<string, ConfigScreenOption>
  onClick: (configKey: string) => void
}

interface ConfigManagerMenuState {}

class ConfigManagerSideMenu extends Component<ConfigManagerMenuProps, ConfigManagerMenuState> {
  state: ConfigManagerMenuState = {}

  componentDidMount() {}

  getNavButtonIcon = (stepName: string) => {
    const stepNameIconMap: Record<string, any> = {
      formUpload: mdiTextBoxPlus,
      auth: mdiShieldLockOutline,
      email: mdiEmailSend,
    }
    if (stepName in stepNameIconMap) {
      return <Icon path={stepNameIconMap[stepName]} color={ColorPalette.PRIMARY_BLUE} style={styles.navButtonIcon} />
    } else {
      return (
        <Icon
          path={mdiArrowLeftBold}
          color={ColorPalette.PRIMARY_BLUE}
          style={{ ...styles.navButtonIcon, width: 22, marginLeft: 15 }}
        />
      )
    }
  }

  render() {
    const configOptions = flattenEntries(this.props.configViewOptions)
    return (
      <div style={styles.container}>
        <ButtonRound
          style={{ marginTop: 30 }}
          diameter={80}
          icon={<Icon path={mdiWrench} size={2} color={ColorPalette.PRIMARY_BLUE} />}
          label={''}
          onClick={() => {}}
        />
        <p
          style={{
            marginTop: 15,
            fontSize: '0.9rem',
            fontWeight: '550',
            fontFamily: 'Roboto',
            color: ColorPalette.PRIMARY_TEXT,
            width: '84%',
            textAlign: 'center' as 'center',
          }}>
          CLIENT CONFIGURATION
        </p>
        <div style={styles.dividerLine} />
        {sortArrayOfObjects('title', 'descending', configOptions).map((configSet) => {
          return (
            <ButtonGeneric
              key={randomIdString()}
              style={{
                ...styles.button,
                backgroundColor:
                  this.props.selectedViewMode === configSet.key ? ColorPalette.BUTTON_ACTIVE_GREY : 'transparent',
              }}
              iconBefore={<Icon style={styles.buttonIcon} path={configSet.icon} color={ColorPalette.PRIMARY_BLUE} />}
              label={configSet.title}
              onClick={() => this.props.onClick(configSet.key)}
            />
          )
        })}
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    width: '100%',
    overflow: 'auto',
  },
  nameStyle: {
    marginTop: 5,
    marginBottom: 0,
    fontSize: '0.9rem',
    fontWeight: '550',
    fontFamily: 'Roboto',
    color: ColorPalette.PRIMARY_TEXT,
    width: '84%',
    marginRight: 5,
    textAlign: 'center' as 'center',
  },
  userNameStyle: {
    marginTop: 2,
    marginBottom: 0,
    fontStyle: 'italic',
    fontSize: '0.7rem',
    fontWeight: '350',
    fontFamily: 'Roboto',
    color: ColorPalette.PRIMARY_TEXT,
    width: '84%',
    marginRight: 5,
    textAlign: 'center' as 'center',
  },
  dividerLine: {
    width: '86%',
    marginTop: 20,
    marginBottom: 10,
    borderBottom: `1px solid ${ColorPalette.LIGHT_GREY}`,
  },
  versionCategory: {
    width: window.innerWidth * 0.21,
    marginTop: 10,
    marginBottom: 20,
  },
  categoryHeader: {
    fontSize: '0.8rem',
    fontWeight: '600',
    color: ColorPalette.SECONDARY_TEXT,
    marginLeft: 30,
  },
  noStepsMessage: {
    color: ColorPalette.TERTIARY_TEXT,
    fontSize: '0.8rem',
    fontWeight: '600',
    width: '100%',
    textAlign: 'center' as 'center',
    // marginLeft: 50,
    marginBottom: 30,
  },
  button: {
    fontWeight: '550',
    fontSize: '0.8rem',
    backgroundColor: ColorPalette.CARD_WHITE,
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    paddingLeft: 30,
    marginTop: 0,
    ':hover': {
      backgroundColor: 'rgba(242,242,250,1)',
    },
    ':active': {
      backgroundColor: 'rgba(222,222,230,1)',
    },
  },
  iconStyle: {
    marginTop: 30,
    width: '5.5rem',
    height: '5.5rem',
    color: ColorPalette.PRIMARY_BLUE,
  },
  buttonIcon: {
    width: '1.3rem',
    height: '1.3rem',
    color: ColorPalette.PRIMARY_BLUE,
    marginRight: 15,
  },
  navButtonIcon: {
    width: 18,
    height: 'auto',
    marginRight: 15,
  },
  ConfigIcon: {
    marginTop: 15,
    width: 90,
    height: 90,
    marginRight: 25,
  } as React.CSSProperties,
}

export default Radium(ConfigManagerSideMenu)
