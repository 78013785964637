import { useState } from 'react'
import Radium from 'radium'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { mdiAlphaQCircleOutline } from '@mdi/js'

import { ColorPalette } from '../../config/colors'
import { removeUnderscores, toUpperCaseCustom } from '../../utils'
import TextInputOutlined from '../BaseComponents/Text/TextInputOutlined'
import { SideMenuOptions } from './SideMenuOptions'

dayjs.extend(relativeTime)

interface PpeStockSideMenuProps {
  typeNames: string[]
  selectedItemName: string
  // onItemAddClick: () => void;
  onItemSelection: (item: string) => void
}

const PpeStockSideMenu = (props: PpeStockSideMenuProps) => {
  const [searchString, setSearchString] = useState('')

  const searchHandler = (e: any) => {
    setSearchString(toUpperCaseCustom(e.target.value))
  }

  const getOptionStyle = (stockName: string) => {
    return {
      fontSize: 'smaller',
      maxWidth: '100%',
      backgroundColor: selectedItemName === stockName ? ColorPalette.BUTTON_ACTIVE_GREY : ColorPalette.OFF_WHITE_LIGHT,
    }
  }

  const { selectedItemName, onItemSelection } = props
  let stockOptions = ['All', ...props.typeNames]
  if (searchString.length) {
    stockOptions = stockOptions.filter((flow) => {
      return flow.toUpperCase().includes(searchString)
    })
  }

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>PPE Stock</h1>
      <TextInputOutlined
        style={styles.textStyle}
        label="ITEM SEARCH"
        placeholder="Search for item"
        value={searchString}
        textHandler={searchHandler}
        disabled={false}
      />
      <div style={styles.optionsContainer}>
        <SideMenuOptions
          options={stockOptions}
          optionLabel={(stockName: string) => removeUnderscores(stockName)}
          optionClick={onItemSelection}
          optionMdiIconPath={mdiAlphaQCircleOutline}
          optionStyle={getOptionStyle}
        />
      </div>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    width: '100%',
    overflow: 'auto',
  },
  textStyle: {
    color: ColorPalette.PRIMARY_TEXT,
    padding: '0px 12px 10px 12px',
    marginTop: 20,
    minWidth: 120,
    width: '80%',
  },
  header: {
    marginTop: 0,
    fontSize: '1rem',
    fontWeight: '550',
    fontFamily: 'Roboto',
    color: ColorPalette.PRIMARY_TEXT,
    width: '84%',
    textAlign: 'center' as 'center',
  },
  optionsContainer: {
    backgroundColor: ColorPalette.OFF_WHITE_LIGHT,
    height: '80vh',
    width: '100%',
  },
}

export default Radium(PpeStockSideMenu)
