import { FormFieldConfig } from "./field.config";

export class DocVersionConfig {
    version: string;
    author: string;
    fieldConfig: Record<string, FormFieldConfig>;
    constructor(version: string, author: string, page: any) {
        this.version = version;
        this.author = author;
        this.fieldConfig = { "1": new FormFieldConfig(page) };
    }
}
