import { ColorPalette } from '../../config/colors'

import { Component } from 'react'
import Radium from 'radium'
import Icon from '@mdi/react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import ButtonGeneric from '../BaseComponents/Buttons/ButtonGeneric'
import { randomIdString } from '../../utils'
import { mdiPlusCircleOutline, mdiCogBox } from '@mdi/js'
import { PickerHandlerEvent } from '../BaseComponents/Pickers/Picker'
import DivisionPicker from '../BaseComponents/Pickers/PickerContained'
import LabelCollector from '../Modals/LabelCollector'

dayjs.extend(relativeTime)

interface ITerminationReasonsSideMenuProps {
  selectedVersion: string
  selectedDivision: string
  organisationConfig: any
  // terminationReasonConfig: any;
  onVersionClick: (dataField: string) => void
  onDivisionChange: (division: string) => void
}

class TerminationReasonsSideMenu extends Component<ITerminationReasonsSideMenuProps> {
  state = {
    addReasonModalOpen: false,
  }

  saveNewReason = (newReasonName: string) => {
    // const key = camelCase(newReasonName);
    // this.props.fieldConfig[key] = {};
    this.closeModal()
  }

  closeModal = () => this.setState({ addReasonModalOpen: false })

  render() {
    const {
      // terminationReasonConfig,
      organisationConfig,
      selectedDivision,
      selectedVersion,
      onVersionClick,
      onDivisionChange,
    } = this.props
    const divisions = ['All', ...Object.keys(organisationConfig.divisionConfig.config)]
    const versions: string[] = ['Latest version']

    return (
      <div style={styles.container}>
        <h1
          style={{
            marginTop: 0,
            fontSize: '1.25rem',
            fontWeight: '550',
            fontFamily: 'Roboto',
            color: ColorPalette.PRIMARY_TEXT,
            width: '84%',
            textAlign: 'center' as 'center',
          }}>
          TERMINATION REASONS
        </h1>
        <DivisionPicker
          label="division"
          value={selectedDivision}
          items={divisions}
          onChange={(e: PickerHandlerEvent) => onDivisionChange(e.target.value === 'All' ? '' : e.target.value)}
          style={{
            ...styles.pickerStyle,
            color: ColorPalette.PRIMARY_TEXT,
          }}
        />
        <div style={styles.dividerLine} />
        {versions.length > 0
          ? versions.map((version) => {
              return (
                <ButtonGeneric
                  key={randomIdString()}
                  style={{
                    ...styles.button,
                    backgroundColor: selectedVersion === version ? ColorPalette.BUTTON_ACTIVE_GREY : 'transparent',
                  }}
                  iconBefore={<Icon style={styles.buttonIcon} path={mdiCogBox} color={ColorPalette.PRIMARY_BLUE} />}
                  label={version}
                  onClick={() => onVersionClick(version)}
                />
              )
            })
          : null}
        <ButtonGeneric
          key={randomIdString()}
          style={{
            ...styles.button,
            backgroundColor: 'transparent',
            color: ColorPalette.LIGHT_GREY,
          }}
          iconBefore={
            <Icon style={styles.buttonIcon} path={mdiPlusCircleOutline} color={ColorPalette.PRIMARY_BLUE} title="Add" />
          }
          label="Add field"
          onClick={() => this.setState({ addReasonModalOpen: true })}
        />
        <LabelCollector
          open={this.state.addReasonModalOpen}
          warning="Enter field name"
          placeholder="Enter field name"
          buttonLabel="Add"
          iconName="setting"
          dismiss={this.closeModal}
          submit={this.saveNewReason}
        />
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    width: '100%',
    overflow: 'auto',
  },
  dividerLine: {
    width: '86%',
    marginTop: 30,
    marginBottom: 20,
    borderBottom: `1px solid ${ColorPalette.LIGHT_GREY}`,
  },
  button: {
    fontWeight: '550',
    fontSize: '0.8rem',
    backgroundColor: ColorPalette.CARD_WHITE,
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    paddingLeft: 30,
    marginTop: 0,
    ':hover': {
      backgroundColor: 'rgba(242,242,250,1)',
    },
    ':active': {
      backgroundColor: 'rgba(222,222,230,1)',
    },
  },
  buttonIcon: {
    width: '1.3rem',
    height: '1.3rem',
    color: ColorPalette.PRIMARY_BLUE,
    marginRight: 15,
  },
  pickerStyle: {
    margin: '0 auto',
    minWidth: 120,
    width: '62%',
    backgroundColor: ColorPalette.CARD_WHITE,
  },
}

export default Radium(TerminationReasonsSideMenu)
