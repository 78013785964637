import { Component } from 'react'
import Dialog from '@material-ui/core/Dialog'
import { ScrollView } from '@cantonjs/react-scroll-view'

import { ColorPalette } from '../../config/colors'
import imageNotFound from '../../assets/imageNotFound.png'

interface FileViewerProps {
  open: boolean
  files: any[]

  dismiss: () => void
}

class FileViewer extends Component<FileViewerProps> {
  state: FileViewerProps = {} as FileViewerProps

  render() {
    // TODO: use /components/Modals/Modal.tsx after web factor lands
    return (
      <Dialog open={this.props.open} PaperProps={{ style: { borderRadius: 8 } }} onClick={() => this.props.dismiss()}>
        <div style={styles.container}>
          <ScrollView style={{ height: window.innerHeight * 0.75 }}>
            {[...this.props.files].map((fileUri, i) => (
              <img
                alt={'file viewer'}
                style={styles.profilePicStyle}
                src={(this.state as any)[`failedImage_${i}`] ? imageNotFound : fileUri}
                onError={() => this.setState({ [`failedImage_${i}`]: true })}
              />
            ))}
          </ScrollView>
        </div>
      </Dialog>
    )
  }
}

const styles = {
  container: {
    backgroundColor: ColorPalette.CARD_WHITE,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column' as 'column',
    padding: 30,
  },
  profilePicStyle: {
    height: 'auto',
    width: window.innerHeight * 0.6,
    marginBottom: 10,
  },
}

export default FileViewer
