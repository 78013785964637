import { ColorPalette } from '../../../config'
import ButtonBlue from '../../BaseComponents/Buttons/ButtonBlue'
import ButtonRound from '../../BaseComponents/Buttons/ButtonRound'
import OptionsSelectorModal from '../../Modals/OptionsSelectorModal'
import TextInputBasic from '../Text/TextInputBasic'

import React, { Component } from 'react'
import Radium from 'radium'
import Icon from '@mdi/react'
import { mdiClose } from '@mdi/js'

interface IFilterButtonProps {
  filterType: string
  filterValue: string
  selectedField: string
  fields: string[]
  warning: boolean

  textHandler: (text: string) => void
  toggleFilterType: () => void
  deleteFilter: (e: React.MouseEvent) => void
  setFilterField: (field: string) => void
}

interface IFilterButtonState {
  fieldSelectorModalOpen: boolean
  comparatorSelectorModalOpen: boolean
}

class FilterButton extends Component<IFilterButtonProps, IFilterButtonState> {
  initialModalState = {
    fieldSelectorModalOpen: false,
    comparatorSelectorModalOpen: false,
  }
  state: IFilterButtonState = {
    ...this.initialModalState,
  }

  closeModals = () => {
    this.setState({ ...this.initialModalState })
  }

  render() {
    let fieldSelector = null
    if (this.state.fieldSelectorModalOpen) {
      fieldSelector = (
        <OptionsSelectorModal
          style={{ top: -window.innerHeight * 0.3, left: -window.innerWidth * 0.13 }}
          open={true}
          onSuccess={this.closeModals}
          onReject={this.closeModals}
          successLabel={'Done'}
          label1={'Filter field'}
          options1={this.props.fields}
          selectedOption1={this.props.selectedField}
          searchEnabled1={true}
          onChange={(event) => this.props.setFilterField(event.target.value)}
        />
      )
    }
    return (
      <div style={{ ...styles.container }}>
        <div style={{ display: 'flex', flexDirection: 'row' as 'row', alignItems: 'center' }}>
          <ButtonBlue
            style={{
              width: '50%',
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              margin: 0,
              marginRight: 2,
              backgroundImage: 'none',
              backgroundColor: this.props.warning ? ColorPalette.WARNING_RED : ColorPalette.DARK_GREY,
            }}
            onClick={() => this.setState({ fieldSelectorModalOpen: true })}>
            {this.props.selectedField ? this.props.selectedField : 'Choose a field'}
          </ButtonBlue>

          <ButtonBlue
            style={{
              width: '15%',
              margin: 0,
              marginRight: 2,
              borderRadius: 0,
              backgroundImage: 'none',
              backgroundColor: this.props.warning ? ColorPalette.WARNING_RED : ColorPalette.DARK_GREY,
            }}
            onClick={() => this.props.toggleFilterType()}>
            {this.props.filterType}
          </ButtonBlue>
          <TextInputBasic
            style={styles.textStyle}
            value={this.props.filterValue}
            placeholder={'Enter filter value'}
            onChange={(event) => this.props.textHandler(event.target.value)}
          />
          <ButtonRound
            style={styles.filter}
            icon={<Icon size={0.8} path={mdiClose} color={ColorPalette.CARD_WHITE} />}
            onClick={(e: React.MouseEvent) => this.props.deleteFilter(e)}
          />
        </div>
        {fieldSelector}
      </div>
    )
  }
}

const styles = {
  container: {
    width: '90%',
    height: 35,
    marginTop: 20,
  },
  textStyle: {
    color: ColorPalette.PRIMARY_TEXT,
    height: 35,
    paddingLeft: 10,
    border: `1px solid ${ColorPalette.DARK_GREY}`,
    borderTopRightRadius: 100,
    borderBottomRightRadius: 100,
    width: '40%',
  },
  filter: {
    marginLeft: 20,
    width: 25,
    height: 25,
    backgroundColor: ColorPalette.LIGHT_GREY,
    borderRadius: 40,
  },
}

export default Radium(FilterButton)
