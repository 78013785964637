import { ColorPalette } from '../../../config/colors'
import { toUpperCaseCustom } from '../../../utils'
import { AllScreenNames } from '../../../config'

import React, { Component } from 'react'
import Radium from 'radium'
import { ScrollView } from '@cantonjs/react-scroll-view'
import Person from '@material-ui/icons/Person'
import FaceIcon from '@material-ui/icons/Face'
import DescriptionIcon from '@material-ui/icons/Description'
import DonutLargeIcon from '@material-ui/icons/DonutLarge'
import ViewListIcon from '@material-ui/icons/ViewList'
import ButtonGeneric from '../../BaseComponents/Buttons/ButtonGeneric'
import profileAvatar from '../../../assets/profileAvatar.png'
import Spinner from 'react-spinkit'

interface ProfileViewingOptionsProps {
  // profilePic either returns a string
  profilePic: string | (() => Promise<string>)
  idPassport: string
  currentScreen: AllScreenNames
  name: string
  restrictedAuditorRoleAccess?: boolean
  onClick: (screen: AllScreenNames) => void
}

interface ProfileViewingOptionsState {
  profilePic: string
  profilePicValid: boolean
  loading: boolean
}

export class ProfileViewingOptionsComponent extends Component<ProfileViewingOptionsProps, ProfileViewingOptionsState> {
  state = {
    profilePic: '',
    profilePicValid: true,
    loading: false,
  }

  componentDidMount() {
    this.reloadProfilePic()
  }

  async reloadProfilePic(): Promise<void> {
    this.setState({ profilePicValid: false, profilePic: '', loading: true })
    return new Promise(async (res) => {
      switch (typeof this.props.profilePic) {
        case 'string':
          this.setState({ profilePicValid: true, profilePic: this.props.profilePic, loading: false })
          break
        case 'function':
          const profilePic = await this.props.profilePic()
          this.setState({ profilePicValid: true, profilePic, loading: false })
      }
      res()
    })
  }

  render() {
    styles.profilePicDimensionContainer = {
      ...styles.profilePicDimensionContainer,
      width: window.innerHeight * (this.state.profilePicValid ? 0.135 : 0.125),
      height: window.innerHeight * (this.state.profilePicValid ? 0.135 : 0.125),
    }

    styles.profilePicStyle = {
      ...styles.profilePicStyle,
      width: window.innerHeight * (this.state.profilePicValid ? 0.135 : 0.125),
    }

    return (
      <div style={styles.container}>
        <div style={styles.imageContainer}>
          <div style={styles.profilePicDimensionContainer}>
            {!this.state.loading ? (
              <img
                style={styles.profilePicStyle}
                alt={'profile'}
                src={this.state.profilePicValid ? this.state.profilePic : profileAvatar}
                onError={() => this.setState({ profilePicValid: false })}
              />
            ) : (
              <Spinner
                style={{ transform: 'scale(0.6)', flex: '1 1 0px' }}
                fadeIn="quarter"
                name="circle"
                color="rgba(245,245,245,1)"
              />
            )}
          </div>
        </div>
        <p style={styles.nameStyle}>{toUpperCaseCustom(this.props.name)}</p>
        <p style={styles.idStyle}>{this.props.idPassport}</p>
        <div style={styles.dividerLine} />

        <ScrollView style={{ height: window.innerHeight * 0.42 }} contentContainerStyle={{ paddingBottom: 40 }}>
          {!this.props.restrictedAuditorRoleAccess && (
            <>
              <ButtonGeneric
                style={{
                  ...styles.button,
                  backgroundColor:
                    this.props.currentScreen === AllScreenNames.DATA_MANAGER
                      ? ColorPalette.BUTTON_ACTIVE_GREY
                      : 'transparent',
                }}
                iconBefore={<Person style={styles.buttonIcon} />}
                label={'PROFILE'}
                onClick={() => this.props.onClick(AllScreenNames.DATA_MANAGER)}
              />
              <ButtonGeneric
                style={{
                  ...styles.button,
                  backgroundColor:
                    this.props.currentScreen === AllScreenNames.FACE_SHOTS
                      ? ColorPalette.BUTTON_ACTIVE_GREY
                      : 'transparent',
                }}
                iconBefore={<FaceIcon style={styles.buttonIcon} />}
                label={'FACESHOTS'}
                onClick={() => this.props.onClick(AllScreenNames.FACE_SHOTS)}
              />
            </>
          )}
          <ButtonGeneric
            style={{
              ...styles.button,
              backgroundColor:
                this.props.currentScreen === AllScreenNames.DOCUMENTS ? ColorPalette.BUTTON_ACTIVE_GREY : 'transparent',
            }}
            iconBefore={<DescriptionIcon style={styles.buttonIcon} />}
            label={'DOCS'}
            onClick={() => this.props.onClick(AllScreenNames.DOCUMENTS)}
          />
          {!this.props.restrictedAuditorRoleAccess && (
            <>
              <ButtonGeneric
                style={{
                  ...styles.button,
                  backgroundColor:
                    this.props.currentScreen === AllScreenNames.PERFORMANCE
                      ? ColorPalette.BUTTON_ACTIVE_GREY
                      : 'transparent',
                }}
                iconBefore={<DonutLargeIcon style={styles.buttonIcon} />}
                label={'PERFORMANCE'}
                onClick={() => this.props.onClick(AllScreenNames.PERFORMANCE)}
              />
              {/* <ButtonGeneric
                            style={{
                                ...styles.button,
                                backgroundColor:
                                    this.props.currentScreen === AllScreenNames.DISCIPLINE
                                        ? ColorPalette.BUTTON_ACTIVE_GREY
                                        : "transparent",
                            }}
                            iconBefore={<ErrorIcon style={styles.buttonIcon} />}
                            label={"DISCIPLINE"}
                            onClick={() => this.props.onClick(AllScreenNames.DISCIPLINE)}
                        /> */}
              <ButtonGeneric
                style={{
                  ...styles.button,
                  backgroundColor:
                    this.props.currentScreen === AllScreenNames.LOGS ? ColorPalette.BUTTON_ACTIVE_GREY : 'transparent',
                }}
                iconBefore={<ViewListIcon style={styles.buttonIcon} />}
                label={'CHANGE LOGS'}
                onClick={() => this.props.onClick(AllScreenNames.LOGS)}
              />
            </>
          )}
        </ScrollView>
      </div>
    )
  }
}

const styles: Record<string, React.CSSProperties | Record<string, React.CSSProperties>> = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    width: '100%',
    // height: "100%",
    flex: 1,
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: ColorPalette.DARK_GREY,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.45)',
    marginTop: 25,
    alignSelf: 'center',
    width: window.innerHeight * 0.135,
    height: window.innerHeight * 0.135,
    borderRadius: window.innerHeight * 0.5,
  },
  profilePicDimensionContainer: {
    overflow: 'hidden',
    borderRadius: window.innerHeight * 0.5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  profilePicStyle: {
    height: 'auto',
  },
  nameStyle: {
    marginTop: 15,
    fontSize: '0.9rem',
    fontWeight: '550',
    fontFamily: 'Roboto',
    color: ColorPalette.PRIMARY_TEXT,
    width: '100%',
    textAlign: 'center' as 'center',
  },
  idStyle: {
    marginTop: -10,
    fontSize: '0.8rem',
    fontWeight: '500',
    fontFamily: 'Roboto',
    color: ColorPalette.PRIMARY_TEXT,
    width: '100%',
    textAlign: 'center' as 'center',
  },
  dividerLine: {
    width: '86%',
    marginTop: 10,
    marginLeft: 20,
    marginBottom: 20,
    borderBottom: `1px solid ${ColorPalette.LIGHT_GREY}`,
  },
  button: {
    fontWeight: '550',
    fontSize: '0.8rem',
    backgroundColor: ColorPalette.CARD_WHITE,
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    paddingLeft: 30,
    marginTop: 0,
    ':hover': {
      backgroundColor: 'rgba(242,242,250,1)',
    },
    ':active': {
      backgroundColor: 'rgba(222,222,230,1)',
    },
  },
  buttonIcon: {
    width: '1.3rem',
    height: '1.3rem',
    color: ColorPalette.PRIMARY_BLUE,
    marginRight: 15,
  },
}

export default Radium(ProfileViewingOptionsComponent)
