import { useSelector } from 'react-redux'
import Radium from 'radium'
import Icon from '@mdi/react'
import { mdiClose } from '@mdi/js'

import { ColorPalette } from '../../../config'
import ButtonBlue from '../../BaseComponents/Buttons/ButtonBlue'
import ButtonRound from '../../BaseComponents/Buttons/ButtonRound'
import { PeopleFlowCombinedReducer } from '../../../store'
import { QUERY_OPERATIONS_TO_READABLE } from '../../../constants'
import { OperationsEnum } from '../../../enums'
import TextInputBasic from '../../BaseComponents/Text/TextInputBasic'
import { AssociationSettingsRepository } from '../../../repositories'
import ButtonGeneric from '../../BaseComponents/Buttons/ButtonGeneric'

type QueryFilterButtonProps = {
  filterComparatorType: string
  filterValue: string
  selectedField: string
  fields: string[]
  warning: boolean
  style?: React.CSSProperties
  textHandler: (text: string) => void
  toggleFilterType: () => void
  deleteFilter: (e: React.MouseEvent) => void
  setFilterField: (field: string) => void
  onClick?: () => void
}

const QueryFilterButton = (props: QueryFilterButtonProps) => {
  const associationRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.associationRepo,
  ) as AssociationSettingsRepository
  const selectedAssociation = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedAssociation,
  )

  const handleClick = () => {
    if (props.onClick) {
      props.onClick()
    }
  }

  const getFieldLabel = (fieldKey: string) => {
    const fieldConfig = associationRepo.getFieldConfig(selectedAssociation)
    return fieldConfig[fieldKey]?.label || fieldKey
  }

  const selectedFieldLabel = getFieldLabel(props.selectedField)
  const selectedField = selectedFieldLabel || 'Choose a field'

  const comparatorLabel = QUERY_OPERATIONS_TO_READABLE[props.filterComparatorType]
  const noComparatorValueRequired =
    [OperationsEnum.EXISTS as string, OperationsEnum.DOESNTEXIST as string, OperationsEnum.HASCHANGED].indexOf(
      props.filterComparatorType,
    ) > -1

  const backgroundColor = props.warning ? ColorPalette.WARNING_RED : ColorPalette.DARK_GREY
  return (
    <div style={{ ...styles.container, ...props.style }}>
      <div style={{ ...styles.buttonAreaContainer }}>
        <ButtonBlue style={{ ...styles.filterField, backgroundColor }} onClick={handleClick}>
          {selectedField}
        </ButtonBlue>

        <ButtonBlue
          style={{
            ...styles.filterComparator,
            backgroundColor,
            fontSize: '0.9rem',
            margin: 0,
          }}
          onClick={handleClick}>
          {comparatorLabel}
        </ButtonBlue>

        <ButtonGeneric
          key="addFilterButton"
          style={{
            ...styles.filterValueFieldStyle,
            paddingLeft: 15,
            backgroundColor: ColorPalette.PRIMARY_LIGHT_TEXT,
            opacity: noComparatorValueRequired ? 0.5 : 1,
            //@ts-ignore
            [':hover']: {
              filter: 'brightness(95%)',
            },
            //@ts-ignore
            [':active']: {
              filter: 'brightness(75%)',
            },
          }}
          label={noComparatorValueRequired ? '' : props.filterValue ? props.filterValue : 'Add filter value'}
          onClick={handleClick}
          disabled={noComparatorValueRequired}
        />
        <ButtonRound
          style={styles.filter}
          icon={<Icon size={0.8} path={mdiClose} color={ColorPalette.CARD_WHITE} />}
          onClick={(e: React.MouseEvent) => props.deleteFilter(e)}
        />
      </div>
    </div>
  )
}

const styles = {
  container: {
    height: 35,
    marginTop: 20,
  },
  buttonAreaContainer: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  filterValueFieldStyle: {
    color: ColorPalette.PRIMARY_TEXT,
    height: 35,
    paddingLeft: 10,
    border: `1px solid ${ColorPalette.DARK_GREY}`,
    borderTopRightRadius: 100,
    borderBottomRightRadius: 100,
    width: window.innerWidth * 0.18,
    placeholderColor: ColorPalette.PRIMARY_LIGHT_TEXT,
    fontSize: '0.9rem',
    fontFamily: 'roboto',
    fontWeight: 'normal',
  },
  filter: {
    marginLeft: 20,
    width: 25,
    height: 25,
    backgroundColor: ColorPalette.LIGHT_GREY,
    borderRadius: 40,
  },
  filterField: {
    width: window.innerWidth * 0.18,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    margin: 0,
    marginRight: 2,
    backgroundImage: 'none',
  },
  filterComparator: {
    width: window.innerWidth * 0.14,
    margin: 0,
    marginRight: 2,
    borderRadius: 0,
    backgroundImage: 'none',
  },
}

export default Radium(QueryFilterButton)
