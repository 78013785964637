import { useEffect, useState } from 'react'
import Icon from '@mdi/react'
import { mdiChevronDown, mdiChevronUp } from '@mdi/js'

import { ColorPalette } from '../../../config/colors'
import { IColumnConfig } from '../../Tables/DataTable/DataTableHeader'
import DataTableBody from '../../Tables/DataTable/DataTableBody'
import { UserFeedback } from '../../GeneralUI/Feedback/UserFeedback'

type ListItem = {}

type Grouping = {
  label: string
  items: ListItem[]
}

type ExpandableTableListProps = {
  columnConfig: IColumnConfig[]
  listData: Grouping[]
  noItemsInGroupingMessage?: string
}

export const ExpandableTableList = (props: ExpandableTableListProps) => {
  const [expandedGroupingIndexes, setExpandedGroupingIndexes] = useState<number[]>([])

  useEffect(() => {
    props.listData.forEach((grouping, index) => {
      if (grouping.items.length > 0) {
        setExpandedGroupingIndexes([...expandedGroupingIndexes, index])
      }
    })
  }, [props.listData])

  const toggleGrouping = (index: number) => {
    if (expandedGroupingIndexes.includes(index)) {
      setExpandedGroupingIndexes(expandedGroupingIndexes.filter((i) => i !== index))
    } else {
      setExpandedGroupingIndexes([...expandedGroupingIndexes, index])
    }
  }

  const getIconPath = (index: number) => {
    if (expandedGroupingIndexes.includes(index)) {
      return mdiChevronUp
    } else {
      return mdiChevronDown
    }
  }

  const getItemsToRender = () =>
    listData.map((data, index) => {
      const isExpanded = expandedGroupingIndexes.includes(index)
      let items = null
      let rows: ListItem[] = listData[index].items
      const itemsToShowForGrouping = rows.length > 0
      if (!itemsToShowForGrouping) {
        return <UserFeedback message={props.noItemsInGroupingMessage || 'No items to display'} />
      }
      if (isExpanded) {
        items = (
          <DataTableBody
            data={rows}
            columnConfig={columnConfig}
            combinedSizeFactor={columnConfig.length}
            customComponentConfig={[]}
            isSelectionEnabled={false}
            selectedRows={[]}
            onRowSelect={(selectedRow: Record<string, any>) => null}
            onClick={() => null}
            customFilterConfig={[]}
            disableRowClick={true}
            tableWidth={9}
            authorisedItemIds={null}
            selectedRowItemId={''}
            style={{ height: 'unset' }}
            disabled={true}
            key={`tableGroupingItems_${index}`}
          />
        )
      }

      let icon: JSX.Element | undefined = undefined
      if (itemsToShowForGrouping) {
        icon = <Icon path={getIconPath(index)} size={1} />
      }

      return (
        <div
          style={{ display: 'flex', flexDirection: 'column', flex: 1, marginTop: '2px' }}
          key={`${data.label}_${index}`}>
          {/* <ButtonGeneric
              style={{ ...styles.cellStyle, width: "100%" }}
              label={data.label}
              labelStyle={{ display: "flex", justifyContent: "space-between", width: "100%" }}
              onClick={() => toggleGrouping(index)}
              iconAfter={icon}
              disabled={!itemsToShowForGrouping}
          /> */}
          {items}
        </div>
      )
    })

  const { columnConfig, listData } = props
  const listItems = getItemsToRender()
  return <>{listItems}</>
}

const styles = {
  cellStyle: {
    height: 34,
    padding: '2px 20px',
    backgroundColor: ColorPalette.DARK_GREY,
    fontSize: '0.8rem',
    textOverflow: 'ellipsis' as 'ellipsis',
    flexShrink: 1,
    whiteSpace: 'nowrap' as 'nowrap',
    overflow: 'hidden',
  },
}
