import { toUpperCaseCustom } from './stringUtils'

export function getInitials(name: string, surname: string): string {
  let initials = ''
  if (name === '' || surname === '') {
    return ''
  }

  initials += name[0]
  initials += surname[0]

  return toUpperCaseCustom(initials)
}

export function formatMobileNumber(mobile: string): string {
  if (mobile === '') {
    return ''
  }
  let newMobile: string[] = []
  let x = ''
  newMobile = mobile.split('')
  newMobile.splice(3, 0, ' ')
  newMobile.splice(7, 0, ' ')
  x = newMobile.join('')
  return x
}
