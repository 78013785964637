import React, { useState } from 'react'
import EditIcon from '@material-ui/icons/Edit'

import TextInputLabelled from '../../BaseComponents/Text/TextInputLabelled'
import ItemSelector from '../../Modals/ItemSelector'
import { ensureArrayFormat } from '../../../utils'

interface IProps {
  jsonKey: string
  label: string
  values: string[]
  selectorItems: string[]
  selectorHandler: (values: string[], jsonKey: string) => void
  singleSelectionMode: boolean
}

export const ItemSelectorTextBox = React.memo((props: IProps) => {
  const { jsonKey, label, selectorItems, selectorHandler, singleSelectionMode } = props
  const values = ensureArrayFormat(props.values)
  const [itemSelectorModalOpen, setItemSelectorModalOpen] = useState(false)
  return (
    <>
      <TextInputLabelled
        key={jsonKey}
        label={label}
        value={values && values.length > 0 ? values.join('; ') : ''}
        multiline={false}
        maxLength={100}
        textHandler={() => null}
        icon="docs"
        endAdornmentIcon={(style) => <EditIcon style={{ ...style, margin: 0 }} />}
        endAdornmentOnClick={() => setItemSelectorModalOpen(true)}
      />
      {itemSelectorModalOpen && (
        <ItemSelector
          key="itemSelector"
          visible={true}
          header={`Select option${singleSelectionMode ? '' : 's'}`}
          submitText="Next"
          items={{ [jsonKey]: selectorItems }}
          showSearchBox={selectorItems.length > 5}
          singleSelectionMode={singleSelectionMode}
          preSelectedItems={values}
          onDismiss={() => setItemSelectorModalOpen(false)}
          onSubmit={(values: string[]) => {
            selectorHandler(values, jsonKey)
            setItemSelectorModalOpen(false)
          }}
        />
      )}
    </>
  )
})
