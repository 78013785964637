export function getDataSyncTemp(storageKey: string) {
  if (!storageKey) {
    return
  }

  const jsonData = sessionStorage.getItem(storageKey)
  if (!jsonData) {
    return
  }

  const parsedData = JSON.parse(jsonData)
  return parsedData
}

export function setDataSyncTemp(storageKey: string, data: any) {
  if (!storageKey) {
    return
  }

  if (!data) {
    sessionStorage.setItem(storageKey, '')
    return
  }

  const jsonData = JSON.stringify(data)
  sessionStorage.setItem(storageKey, jsonData)
}
