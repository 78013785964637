type AssignedToProps = {
  groupsBelongingTo: string[]
}

export const AssignedTo = (props: AssignedToProps) => (
  <div>
    <p>
      <span style={{ fontWeight: 500 }}>Currently also assigned to:</span>
      <br />
      {props.groupsBelongingTo.join(', ')}
    </p>
  </div>
)
