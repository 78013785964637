import { pick } from 'lodash'

import { ConfigManagerService, SessionService } from '.'
import { PpeActionRecord } from '../models/ppe'
import { AssociationSettingsRepository } from '../repositories'
import {
  AuthCredentials,
  PpeInstance,
  PpeInstancePayload,
  PpeRecordSk,
  PpeRequestsResponse,
  PpeSupplierKeyValuePairs,
  PpeType,
  PpeTypeUpsert,
  PpeUpsertResponse,
} from '../types'
import { getPpeInstances, getPpeRequests, updatePpeActionRecord, updatePpeInstance } from '../provider/routes/ppe'
import { httpPost } from '../provider/remoteData'

export class PPEService {
  getItems = async (
    association: string,
    authCredentials: AuthCredentials,
  ): Promise<Record<PpeRecordSk, PpeInstance>> => {
    const { username, password } = authCredentials
    const token = await SessionService.prepareAuthTokens(username, password)
    const { instanceRecordDtos } = await getPpeInstances(association, token)
    return instanceRecordDtos
  }

  upsertItem = async (
    association: string,
    payload: PpeInstancePayload,
    authCredentials: AuthCredentials,
  ): Promise<any> => {
    const { username, password } = authCredentials
    const token = await SessionService.prepareAuthTokens(username, password)
    return await updatePpeInstance(association, payload, token)
  }

  getTypes = (association: string, associationRepo: AssociationSettingsRepository): PpeType[] => {
    const fieldConfig = associationRepo.getFieldConfig(association)
    const ppeTypeKeys = Object.keys(fieldConfig).filter((key) => fieldConfig[key].isPpe)
    return ppeTypeKeys.map((key) => {
      const ppeType = fieldConfig[key] as PpeType
      return ppeType
    })
  }

  upsertType = async (
    association: string,
    type: PpeTypeUpsert,
    username: string,
    password: string,
    associationRepo: AssociationSettingsRepository,
  ): Promise<PpeUpsertResponse> => {
    const userIdPassport = username
    const fieldKey = type.key
    const changes = [
      {
        editedBy: userIdPassport,
        updatePath: [fieldKey],
        updatedData: type,
        updatedMs: +new Date(),
      },
    ]
    const response: { result: any; updatedConfig: any } | { result: any; updatedConfig?: undefined } =
      await ConfigManagerService.updateConfig(association, 'fieldConfig', changes, { username, password })

    let updatedType: Record<string, PpeType> = {}
    if (response.result === 'success') {
      updatedType = pick(response.updatedConfig, fieldKey) as Record<string, PpeType>
      associationRepo.setFieldConfigItem(association, updatedType)
    }

    return { updatedType, updatedAssociationRepo: associationRepo }
  }

  getSuppliers = (association: string, associationRepo: AssociationSettingsRepository): PpeSupplierKeyValuePairs => {
    const ppeSuppliers = associationRepo.getOrganisationConfigPPESuppliers(association)
    return ppeSuppliers
  }
  upsertSuppliers = async (
    association: string,
    idPassport: string,
    password: string,
    associationRepo: AssociationSettingsRepository,
    suppliers: PpeSupplierKeyValuePairs,
  ): Promise<AssociationSettingsRepository | null> => {
    const changes = [
      {
        editedBy: idPassport,
        updatePath: ['ppeSuppliers'],
        updatedData: suppliers,
        updatedMs: +new Date(),
      },
    ]

    const response = await ConfigManagerService.updateConfig(association, 'organisationConfig', changes, {
      username: idPassport,
      password,
    })
    if (response.result === 'success') {
      associationRepo.setOrganisationConfigPPESuppliers(association, suppliers)
      return associationRepo
    }

    return null
  }

  getRequests = async (association: string, authCredentials: AuthCredentials) => {
    const { username, password } = authCredentials
    const token = await SessionService.prepareAuthTokens(username, password)
    return await getPpeRequests(association, token)
  }

  uploadActionRecord = async (
    association: string,
    actionRecord: Partial<PpeActionRecord>,
    authCredentials: AuthCredentials,
  ): Promise<any> => {
    const { username, password } = authCredentials
    const token = await SessionService.prepareAuthTokens(username, password)
    return await updatePpeActionRecord(association, actionRecord, token)
  }

  async ppeStockExport(association: string, emailAddresses: string[], token: string) {
    if (emailAddresses.length === 0) {
      throw new Error('NoEmailAddresses')
    }

    const payload = {
      targetEmails: emailAddresses,
    }
    const response = await httpPost(
      'people_flow_core',
      `/inventory/export/ppe/association/${association}/stock`,
      payload,
      token,
    )

    if (response.data.result !== 'success') {
      throw new Error('UnknownError')
    }
  }

  async ppeRequestExport(association: string, emailAddresses: string[], token: string) {
    if (emailAddresses.length === 0) {
      throw new Error('NoEmailAddresses')
    }

    const payload = {
      targetEmails: emailAddresses,
    }
    const response = await httpPost(
      'people_flow_core',
      `/inventory/export/ppe/association/${association}/movement`,
      payload,
      token,
    )

    if (response.data.result !== 'success') {
      throw new Error('UnknownError')
    }
  }
}
