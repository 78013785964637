import { Component, RefObject, createRef } from 'react'
import { connect } from 'react-redux'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

import ButtonBlue from '../../BaseComponents/Buttons/ButtonBlue'
import CalendarContained from '../../BaseComponents/Calendar/CalendarContained'
import {
  CsvProcessIdentifier,
  getCsvFieldHeaderKeyByName,
  getCsvFieldHeaderNames,
  isCsvDateField,
  processHasCsvDateField,
  parseDateString,
  writeLocalFileToRemoteStorage,
  dateObjToString,
} from '../../../utils'
import ButtonGrey from '../../BaseComponents/Buttons/ButtonGrey'
import Modal from '../Modal'
import { PeopleFlowCombinedReducer } from '../../../store'
import { CsvFileColumnValuePairs, CsvFileProcessingModal, ProcessingStep } from '../Csv/CsvFileProcessingModal'
import CheckboxTabLabelled from '../../BaseComponents/Checkboxes/CheckboxTabLabelled'
import { CsvDateFormatStandard } from '../../../services'
import { ColorPalette } from '../../../config'
import { AssociationSettingsRepository, ProfileRepository } from '../../../repositories'

interface ITerminationDataUploadModalProps {
  open: boolean
  selectedAssociation: string
  profileRepo: ProfileRepository
  associationRepo: AssociationSettingsRepository
  onNextClick: (
    fileName: string | null,
    defaultTerminationDate: string,
    idPassportsNotFound: string[],
    invertRecordsToTerminate: boolean,
  ) => void
  onCancelClick: () => void
  onInvalidFileContentFound: (issues: string[]) => void
  forwardedRef?: RefObject<HTMLDivElement>
}

interface ITerminationDataUploadModalState {
  loadingModalOpen: boolean
  selectedDate: string
  datePickerOpen: boolean
  uploadedFileName: string | null
  defaultDateModalOpen: boolean
  terminateIdsListed: boolean
  defaultDateStandard: CsvDateFormatStandard
}

const today = new Date().toISOString()
const csvProcessName = CsvProcessIdentifier.BULK_TERMINATION
const initialState = {
  loadingModalOpen: false,
  selectedDate: today,
  datePickerOpen: false,
  uploadedFileName: null,
  defaultDateModalOpen: true,
  terminateIdsListed: true,
  defaultDateStandard: CsvDateFormatStandard.UK,
}

export type TerminationDataUploadModalType = TerminationDataUploadModal

class TerminationDataUploadModal extends Component<ITerminationDataUploadModalProps, ITerminationDataUploadModalState> {
  state = initialState
  idPassportsNotFound = createRef<string[]>()
  csvFileContentIssues = createRef<string[]>()
  orgConfig: Record<string, any> = {}

  constructor(props: ITerminationDataUploadModalProps) {
    super(props)
    const { selectedAssociation, associationRepo } = props
    this.orgConfig = associationRepo.getOrganisationConfig(selectedAssociation)
  }

  toggleDatePickOpen = () => this.setState((prevState) => ({ datePickerOpen: !prevState.datePickerOpen }))

  toggleDefaultDateModalOpen = () => {
    this.setState((prevState) => ({ defaultDateModalOpen: !prevState.defaultDateModalOpen }))
  }

  toggleTerminateIdsListed = () => {
    this.setState({ terminateIdsListed: true })
  }
  toggleTerminateIdsNotListed = () => {
    this.setState({ terminateIdsListed: false })
  }

  changeSelectedDate = (date: MaterialUiPickersDate) => {
    this.setState({ selectedDate: dateObjToString(date as Date) })
  }

  uploadCsv = async (fileName: string, filePath: string, fileData: string[], fileType: string) => {
    const file = new File(fileData, fileName, { type: 'fileType' })
    await writeLocalFileToRemoteStorage(filePath, file, fileType)
  }

  hasValidContent = () => {
    if (this.csvFileContentIssues.current === null) {
      return true
    }
    return this.csvFileContentIssues.current.length === 0
  }

  processCsv = async (
    jsonFileContent: CsvFileColumnValuePairs[],
  ): Promise<{ uploadedFileName: string } | undefined> => {
    this.setState({ loadingModalOpen: true })
    const { selectedAssociation } = this.props
    const fileName = `bulktermination_import_${+new Date()}.csv`
    const filePath = `Companies/${selectedAssociation}/Imports/${fileName}`
    const fileHasValidContent = this.hasValidContent()
    const csvFileRows = jsonFileContent.map((obj: CsvFileColumnValuePairs, index: number) => {
      if (index === 0) {
        return `${Object.keys(obj).join(',')}\n${Object.values(obj).join(',')}\n`
      }
      const row = `${Object.values(obj).join(',')}\n`
      return row
    })
    if (!fileHasValidContent) {
      this.setState({ loadingModalOpen: false })
      this.props.onInvalidFileContentFound(this.csvFileContentIssues.current || [])
      return
    }
    await this.uploadCsv(fileName, filePath, csvFileRows, 'text/csv')
    this.setState({ loadingModalOpen: false, uploadedFileName: fileName })
    return { uploadedFileName: fileName }
  }

  getHeaders = () => {
    const headers = getCsvFieldHeaderNames(this.orgConfig, csvProcessName, ['ID/Passport', 'Termination Date'])
    return headers
  }

  initialValidationAndTransformValue = (value: string, header?: string | number) => {
    const { profileRepo } = this.props

    if (!header) {
      return value
    }

    if (header === 'idPassport') {
      let idPassport = value.toUpperCase()
      const profile = profileRepo.getProfileByIdPassport(idPassport)
      if (!profile) {
        const currentIdPassportsNotFound = [...new Set([...(this.idPassportsNotFound.current || []), idPassport])]
        this.idPassportsNotFound = { current: currentIdPassportsNotFound }
      } else {
        value = profile.getPk()
      }
    }
    if (isCsvDateField(this.orgConfig, csvProcessName, header as string, header === 'terminationDate')) {
      if (value === '') {
        const dateString = this.state.selectedDate
        value = dateString
      }
      const inputDateInAcceptedFormat = parseDateString(value, this.state.defaultDateStandard)
      if (!inputDateInAcceptedFormat) {
        const invalidDateMessage = 'invalid date field(s)'
        const currentCsvFileContentIssues = [
          ...new Set([...(this.csvFileContentIssues.current || []), invalidDateMessage]),
        ]
        this.csvFileContentIssues = { current: currentCsvFileContentIssues }
      }
      return inputDateInAcceptedFormat || value
    }

    return value
  }

  getDefaultHeaderKey = (index: number) => {
    if (index === 0) {
      return 'idPassport'
    }
    if (index === 1) {
      return 'terminationDate'
    }
    return ''
  }

  transformHeader = (header: string, index: number) => {
    const defaultHeaderKey = this.getDefaultHeaderKey(index)
    const headerKey = getCsvFieldHeaderKeyByName(this.orgConfig, csvProcessName, header, defaultHeaderKey)
    return headerKey
  }

  submissionHandler = async (jsonFileContent: CsvFileColumnValuePairs[]) => {
    await this.processCsv(jsonFileContent)
    const idPassportsNotFound = this.idPassportsNotFound.current || []
    this.props.onNextClick(this.state.uploadedFileName, this.state.selectedDate, idPassportsNotFound, false)
  }

  handleDateStandardSelection = (values: string[], isMount: boolean) => {
    if (!isMount) {
      const standard = values[0] as CsvDateFormatStandard
      if (standard === CsvDateFormatStandard.US) {
        const dateObj = new Date(this.state.selectedDate)
        const dateString = dateObj.toLocaleDateString('en-US')
        this.setState({ selectedDate: dateString })
      } else {
        const dateObj = new Date(this.state.selectedDate)
        const dateString = dateObj.toLocaleDateString('en-GB')
        this.setState({ selectedDate: dateString })
      }
      this.setState({ defaultDateStandard: standard })
    }
  }

  getInsertSections = () => {
    let defaultDateStandardSelector = null
    if (processHasCsvDateField(this.orgConfig, csvProcessName, true)) {
      defaultDateStandardSelector = (
        <>
          {/* NOTE: date standard selection could be moved inside CsvFileProcessingModal */}
          <CheckboxTabLabelled
            label="Are your dates in UK or US format?"
            values={[this.state.defaultDateStandard]}
            selectorItems={[CsvDateFormatStandard.UK, CsvDateFormatStandard.US]}
            maxItems={1}
            selectionHandler={this.handleDateStandardSelection}
            style={{ marginTop: 40 }}
          />
          <DateFormatStandardExplainer />
          <Modal
            open={this.state.defaultDateModalOpen}
            title="SELECT A DEFAULT DATE"
            actionButtons={[
              <ButtonGrey onClick={this.toggleDefaultDateModalOpen}>Cancel</ButtonGrey>,
              <ButtonBlue onClick={this.toggleDefaultDateModalOpen}>Next</ButtonBlue>,
            ]}>
            <p>For rows which are missing termination dates, which date should be used instead?</p>
            <CalendarContained
              format="dd/MM/yyyy"
              label="Selected Date"
              value={this.state.selectedDate}
              onChange={this.changeSelectedDate}
              onOpen={this.toggleDatePickOpen}
              onClose={this.toggleDatePickOpen}
              style={{ width: '100%' }}
            />
          </Modal>
        </>
      )
    }

    return {
      [ProcessingStep.FILE_TO_JSON]: defaultDateStandardSelector,
    }
  }

  getDefaultHeaderLabel = (key: string) => {
    if (key === 'idPassport') {
      return 'ID/Passport'
    }
    if (key === 'terminationDate') {
      return 'Termination Date'
    }
    return ''
  }

  render() {
    let modal = null
    if (this.props.open) {
      const headers = this.getHeaders()
      const insertSectionRenders = this.getInsertSections()
      modal = (
        <CsvFileProcessingModal
          open={true}
          title="UPLOAD TERMINATION DATA"
          instructions="Download and complete the CSV template, then select it below. "
          insertSection={insertSectionRenders}
          expectedHeaderColumns={headers}
          csvProcess={csvProcessName}
          getDefaultHeaderLabel={this.getDefaultHeaderLabel}
          transformValue={this.initialValidationAndTransformValue}
          transformHeader={this.transformHeader}
          onCancelClick={this.props.onCancelClick}
          onSubmitClick={this.submissionHandler}
        />
      )
    }
    return modal
  }
}

const mapStateToProps = (state: PeopleFlowCombinedReducer) => {
  return {
    associationRepo: state.sessionManager.associationRepo as AssociationSettingsRepository,
    selectedAssociation: state.sessionManager.selectedAssociation,
    profileRepo: state.sessionManager.profileRepo as ProfileRepository,
  }
}

export default connect(mapStateToProps)(TerminationDataUploadModal)

const DateFormatStandardExplainer = () => (
  <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
    <div style={{ paddingRight: '0.5em', color: ColorPalette.PRIMARY_BLUE }}>*</div>
    <div
      style={{
        fontSize: '0.8rem',
        paddingTop: '0.25em',
        textAlign: 'center',
        color: ColorPalette.TERTIARY_TEXT,
      }}>
      <b>UK</b>&nbsp;&nbsp;-&nbsp;&nbsp;day/month/year or year/month/day
      <br />
      <b>US</b>&nbsp;&nbsp;-&nbsp;&nbsp;month/day/year or year/day/month
    </div>
  </div>
)
