type JobRequirementsNotSatisfiedProps = {
  docsRequired: string[]
  belongsToMultipleGroups: boolean
  groupsBelongingTo: string[]
}

export const JobRequirementsNotSatisfied = (props: JobRequirementsNotSatisfiedProps) => (
  <div>
    <p>
      <span style={{ fontWeight: 500 }}>Job requirements not satisfied:</span>
      <br />
      {props.docsRequired.join(', ')}
      {props.belongsToMultipleGroups && (
        <>
          <br />
          <br />
          <span style={{ fontWeight: 500 }}>Currently also assigned to:</span>
          <br />
          {props.groupsBelongingTo.join(', ')}
        </>
      )}
    </p>
  </div>
)
