import React from 'react'
import Radium from 'radium'
import { ColorPalette } from '../../../config/colors'

interface IButtonStatusProps {
  hasTriggered: boolean
  triggeredLabel: string
  notTriggeredLabel: string
  disabled?: boolean
  style?: React.CSSProperties | undefined
  onClick: () => void
}

const buttonStatus: React.FC<IButtonStatusProps> = (props: IButtonStatusProps) => {
  const buttonContents = props.hasTriggered ? props.triggeredLabel : props.notTriggeredLabel
  return (
    <button
      style={{
        ...styles.buttonContainer,
        color: props.hasTriggered ? ColorPalette.LIGHT_GREY : ColorPalette.PRIMARY_LIGHT_TEXT,
        backgroundImage: props.hasTriggered
          ? 'none'
          : `linear-gradient(to bottom, ${ColorPalette.PRIMARY_BLUE}, ${ColorPalette.SECONDARY_BLUE})`,
        border: props.hasTriggered ? `1px solid ${ColorPalette.LIGHT_GREY}` : 'none',
        boxShadow: props.hasTriggered ? 'none' : '0px 2px 6px rgba(0, 0, 30, 0.3)',
        padding: props.hasTriggered ? '3px 6px 3px 6px' : '4px 11px 4px 11px',
        fontSize: props.hasTriggered ? '0.68rem' : '0.7rem',

        ...props.style,
      }}
      onClick={props.onClick}
      disabled={props.disabled}>
      {buttonContents}
    </button>
  )
}

const styles = {
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '3px 7px 3px 7px',
    borderRadius: 30,
    fontSize: '0.7rem',
    fontFamily: 'roboto',
    outline: 0,
    boxShadow: '0px 2px 6px rgba(0, 0, 30, 0.3)',
    ':hover': {
      filter: 'brightness(95%)',
    },
    ':active': {
      filter: 'brightness(50%)',
    },
  },
}

export default Radium(buttonStatus)
