import { getConfiguredProcessInfo, processTypes } from '../../utils/config'

export interface IDisciplineConfig {
  extractPossibleOffences(): string[]
  extractActionExpiry(division: string, jobType: string, jobSubType: string, offence: string, action: string): number
  extractActionFlowConfig(
    division: string,
    jobType: string,
    jobSubType: string,
    offence: string,
    action: string,
  ): string[]
  extractDataFlowNames(division: string, jobType: string, jobSubType: string, offence: string, action: string): string[]
  getDocNames(division: string, jobType: string, jobSubType: string, offence: string, action: string): string[]
  extractPoorPerformanceName(): string
}

export class DisciplineConfig implements IDisciplineConfig {
  private processConfig: any
  private offenceConfig: any
  private dataCaptureConfig: any
  private docCaptureConfig: any
  private poorPerformanceOffenceName: string | undefined

  constructor(processConfig: any) {
    if (!processConfig) {
      return
    }

    const offenceConfig = processConfig.discipline.offenceConfig

    this.processConfig = processConfig
    this.offenceConfig = offenceConfig
    this.dataCaptureConfig = offenceConfig.dataCaptureConfig
    this.docCaptureConfig = offenceConfig.docCaptureConfig
    this.poorPerformanceOffenceName = offenceConfig.poorPerformanceOffenceName
  }

  extractActionExpiry(division: string, jobType: string, jobSubType: string, offence: string, action: string): number {
    const sourceData = { division, jobType, jobSubType, offence, action }
    const actionExpiryConfig = getConfiguredProcessInfo(
      'actionExpiry',
      processTypes.discipline,
      sourceData,
      this.processConfig,
    )
    let actionExpiry = actionExpiryConfig[action]
    if (actionExpiry === undefined) {
      return 0
    }
    return actionExpiry
  }

  extractPossibleOffences(): string[] {
    return Object.keys(this.offenceConfig)
  }

  extractActionFlowConfig(
    division: string,
    jobType: string,
    jobSubType: string,
    offence: string,
    action: string,
  ): string[] {
    const sourceData = { division, jobType, jobSubType, offence, action }
    const actionFlowConfig = getConfiguredProcessInfo(
      'actionFlow',
      processTypes.discipline,
      sourceData,
      this.processConfig,
    ) as string[]
    return actionFlowConfig
  }

  extractDataFlowNames(
    division: string,
    jobType: string,
    jobSubType: string,
    offence: string,
    action: string,
  ): string[] {
    const sourceData = { division, jobType, jobSubType, offence, action }
    const dataFlowNames = getConfiguredProcessInfo(
      'dataFlowNames',
      processTypes.discipline,
      sourceData,
      this.processConfig,
    ) as string[]
    return dataFlowNames
  }

  getDocNames(division: string, jobType: string, jobSubType: string, offence: string, action: string): string[] {
    const sourceData = { division, jobType, jobSubType, offence, action }
    const docNames = getConfiguredProcessInfo(
      'docNames',
      processTypes.discipline,
      sourceData,
      this.processConfig,
    ) as string[]
    return docNames
  }

  extractPoorPerformanceName(): string {
    if (this.poorPerformanceOffenceName === undefined) {
      return ''
    }
    return this.poorPerformanceOffenceName
  }
}
