import { docCategories, docSubCategories } from '../../config/docCategories'
import { removeUnderscores, addUnderScores } from '../../utils'
import { ColorPalette } from '../../config/colors'

import { Component } from 'react'
import Radium from 'radium'
import { ScrollView } from '@cantonjs/react-scroll-view'
import { get, invert } from 'lodash'

import ButtonBlue from '../BaseComponents/Buttons/ButtonBlue'
import CheckboxTabLabelled from '../BaseComponents/Checkboxes/CheckboxTabLabelled'

interface DocDisplaySettingsModalProps {
  processConfig: any
  displaySettings: DocDisplaySettingsModalState
  docName: string
  saveAndClose: (displaySettings: any, processConfig: any) => void
  dismiss: () => void
}

interface DocDisplaySettingsModalState {
  allCategories: string[]
  selectedCategory: string[]
  allProcesses: string[]
  selectedProcesses: string[]
  subProcessesConfig: any
  mounted: boolean
  [index: string]: any
}

class DocDisplaySettingsModal extends Component<DocDisplaySettingsModalProps, DocDisplaySettingsModalState> {
  intialModalState = {
    selectorItemCollectorModalOpen: false,
  }

  state: DocDisplaySettingsModalState = {
    allCategories: [],
    selectedCategory: [],
    allProcesses: [],
    selectedProcesses: [],
    subProcessesConfig: {},
    mounted: false,
  }

  processKeyNameMap: Record<string, string> = {
    competenciesv2: 'COMPETENCIES',
    discipline: 'DISCIPLINE',
    employ: 'EMPLOYMENT',
    promote: 'PROMOTIONS',
    terminate: 'TERMINATIONS',
    transfer: 'TRANSFERS',
    generalCandidate: 'AD HOC (CANDIDATES)',
    generalEmployee: 'AD HOC (EMPLOYEES)',
  }

  componentDidMount() {
    try {
      const { displaySettings, processConfig, docName } = this.props
      const allCategories = Object.values(docCategories).map((category) => removeUnderscores(category))
      const { allProcesses, selectedProcesses, subProcessesConfig } = this.extractProcessSelectionConfig(
        processConfig,
        docName,
      )

      this.setState({
        ...displaySettings,
        selectedCategory: displaySettings.category,
        allCategories,
        allProcesses,
        selectedProcesses,
        subProcessesConfig,
        mounted: true,
      })
    } catch (error) {
      console.error('componentDidMount() error: ', error)
    }
  }

  closeModals = () => {
    this.setState({ ...this.intialModalState })
  }

  extractProcessSelectionConfig = (processConfig: any, docName: string) => {
    /* 
            This isn't pretty, but does the job for now. 
        */
    let allProcesses = Object.values(this.processKeyNameMap)
    let selectedProcesses: string[] = []
    let subProcessesConfig: any = {}

    Object.keys(processConfig).forEach((processKey: string) => {
      let allSubProcesses: string[] = []
      let selectedSubProcesses: string[] = []

      if (processKey === 'discipline') {
        allSubProcesses = docSubCategories.DISCIPLINE.map((subProcess) => removeUnderscores(subProcess))
        allSubProcesses.forEach((subProcess: string) => {
          if (
            addUnderScores(subProcess) in
              processConfig.discipline.docCaptureConfig.allDivisions.allJobTypes.allJobSubTypes.allOffences &&
            processConfig.discipline.docCaptureConfig.allDivisions.allJobTypes.allJobSubTypes.allOffences[
              addUnderScores(subProcess)
            ].docNames.includes(docName)
          ) {
            selectedSubProcesses.push(subProcess)
            if (!selectedProcesses.includes(this.processKeyNameMap[processKey])) {
              selectedProcesses.push(this.processKeyNameMap[processKey])
            }
          }
        })
      } else if (
        (processKey === 'terminate' &&
          get(
            processConfig[processKey],
            `docCaptureConfig.allDivisions.allJobTypes.allJobSubTypes.allTerminationReasons.allTerminationSubReasons.docNames`,
            [],
          ).includes(docName)) ||
        (processKey !== 'terminate' &&
          get(
            processConfig[processKey],
            `docCaptureConfig.allDivisions.allJobTypes.allJobSubTypes.docNames`,
            [],
          ).includes(docName))
      ) {
        selectedProcesses.push(this.processKeyNameMap[processKey])
      }
      subProcessesConfig[processKey] = { selectorItems: allSubProcesses, selectedSubProcesses }
    })

    return { allProcesses, selectedProcesses, subProcessesConfig }
  }

  selectionHandler(values: string[], selectionType: string, processName: string) {
    if (selectionType === 'category') {
      this.setState({ selectedCategory: values })
    } else if (selectionType === 'process') {
      let { subProcessesConfig } = this.state
      if (!values.includes('DISCIPLINE')) {
        subProcessesConfig.discipline.selectedSubProcesses = []
      }
      this.setState({ selectedProcesses: values, subProcessesConfig })
    } else if (selectionType === 'subProcess') {
      let { subProcessesConfig } = this.state
      subProcessesConfig[processName].selectedSubProcesses = values
      this.setState({ subProcessesConfig })
    }
  }

  saveAndClose = () => {
    let { processConfig, docName } = this.props
    const { selectedCategory, allProcesses, selectedProcesses, subProcessesConfig } = this.state

    allProcesses.forEach((processLabel) => {
      const processKey = invert(this.processKeyNameMap)[processLabel]
      if (processKey === 'discipline') {
        subProcessesConfig[processKey].selectorItems.forEach((subProcess: string) => {
          subProcess = addUnderScores(subProcess)
          if (
            !processConfig[processKey].docCaptureConfig.allDivisions.allJobTypes.allJobSubTypes.allOffences[subProcess]
          ) {
            processConfig.discipline.docCaptureConfig.allDivisions.allJobTypes.allJobSubTypes.allOffences[subProcess] =
              { docNames: [] }
          }
          processConfig[processKey].docCaptureConfig.allDivisions.allJobTypes.allJobSubTypes.allOffences[
            subProcess
          ].docNames = processConfig[processKey].docCaptureConfig.allDivisions.allJobTypes.allJobSubTypes.allOffences[
            subProcess
          ].docNames.filter((existingDocName: string) => existingDocName !== docName)
          if (subProcessesConfig[processKey].selectedSubProcesses.includes(removeUnderscores(subProcess))) {
            processConfig[processKey].docCaptureConfig.allDivisions.allJobTypes.allJobSubTypes.allOffences[
              subProcess
            ].docNames.push(docName)
          }
          if (
            !processConfig.discipline.docCaptureConfig.allDivisions.allJobTypes.allJobSubTypes.allOffences[subProcess]
              .docNames.length
          ) {
            delete processConfig.discipline.docCaptureConfig.allDivisions.allJobTypes.allJobSubTypes.allOffences[
              subProcess
            ]
          }
          // NOTE: duplicating to ADMIN division (which might be temporary)
          try {
            if (
              !processConfig[processKey].docCaptureConfig['ADMIN'].allJobTypes.allJobSubTypes.allOffences[subProcess]
            ) {
              processConfig.discipline.docCaptureConfig['ADMIN'].allJobTypes.allJobSubTypes.allOffences[subProcess] = {
                docNames: [],
              }
            }
            processConfig[processKey].docCaptureConfig['ADMIN'].allJobTypes.allJobSubTypes.allOffences[
              subProcess
            ].docNames = processConfig[processKey].docCaptureConfig['ADMIN'].allJobTypes.allJobSubTypes.allOffences[
              subProcess
            ].docNames.filter((existingDocName: string) => existingDocName !== docName)
            if (subProcessesConfig[processKey].selectedSubProcesses.includes(removeUnderscores(subProcess))) {
              processConfig[processKey].docCaptureConfig['ADMIN'].allJobTypes.allJobSubTypes.allOffences[
                subProcess
              ].docNames.push(docName)
            }
            if (
              !processConfig.discipline.docCaptureConfig['ADMIN'].allJobTypes.allJobSubTypes.allOffences[subProcess]
                .docNames.length
            ) {
              delete processConfig.discipline.docCaptureConfig['ADMIN'].allJobTypes.allJobSubTypes.allOffences[
                subProcess
              ]
            }
          } catch (error) {
            console.error('Something went wrong adding discipline docs to ADMIN division: ', error)
          }
        })
      } else if (processKey === 'terminate') {
        processConfig[
          processKey
        ].docCaptureConfig.allDivisions.allJobTypes.allJobSubTypes.allTerminationReasons.allTerminationSubReasons.docNames =
          processConfig[
            processKey
          ].docCaptureConfig.allDivisions.allJobTypes.allJobSubTypes.allTerminationReasons.allTerminationSubReasons.docNames.filter(
            (existingDocName: string) => existingDocName !== docName,
          )
        if (selectedProcesses.includes(processLabel)) {
          processConfig[
            processKey
          ].docCaptureConfig.allDivisions.allJobTypes.allJobSubTypes.allTerminationReasons.allTerminationSubReasons.docNames.push(
            docName,
          )
        }
        // NOTE: duplicating logic to ADMIN division (which might be temporary)
        try {
          processConfig[processKey].docCaptureConfig[
            'ADMIN'
          ].allJobTypes.allJobSubTypes.allTerminationReasons.allTerminationSubReasons.docNames = processConfig[
            processKey
          ].docCaptureConfig[
            'ADMIN'
          ].allJobTypes.allJobSubTypes.allTerminationReasons.allTerminationSubReasons.docNames.filter(
            (existingDocName: string) => existingDocName !== docName,
          )
          if (selectedProcesses.includes(processLabel)) {
            processConfig[processKey].docCaptureConfig[
              'ADMIN'
            ].allJobTypes.allJobSubTypes.allTerminationReasons.allTerminationSubReasons.docNames.push(docName)
          }
        } catch (error) {
          console.error('Something went wrong adding termination docs to ADMIN division: ', error)
        }
      } else {
        processConfig[processKey].docCaptureConfig.allDivisions.allJobTypes.allJobSubTypes.docNames = processConfig[
          processKey
        ].docCaptureConfig.allDivisions.allJobTypes.allJobSubTypes.docNames.filter(
          (existingDocName: string) => existingDocName !== docName,
        )
        if (selectedProcesses.includes(processLabel)) {
          processConfig[processKey].docCaptureConfig.allDivisions.allJobTypes.allJobSubTypes.docNames.push(docName)
        }
        // NOTE: duplicating logic to ADMIN division (which might be temporary)
        try {
          processConfig[processKey].docCaptureConfig['ADMIN'].allJobTypes.allJobSubTypes.docNames = processConfig[
            processKey
          ].docCaptureConfig['ADMIN'].allJobTypes.allJobSubTypes.docNames.filter(
            (existingDocName: string) => existingDocName !== docName,
          )
          if (selectedProcesses.includes(processLabel)) {
            processConfig[processKey].docCaptureConfig['ADMIN'].allJobTypes.allJobSubTypes.docNames.push(docName)
          }
        } catch (error) {
          console.error('Something went wrong adding docs to ADMIN division: ', error)
        }
      }
    })
    this.props.saveAndClose({ category: selectedCategory }, processConfig)
  }

  generateProcessSelectionCheckboxes = () => {
    const componentsToRender = [
      <CheckboxTabLabelled
        label={'Where will this be used?'}
        values={this.state.selectedProcesses}
        selectorItems={this.state.allProcesses}
        selectionHandler={(values) => this.selectionHandler(values, 'process', '')}
        style={{ width: '82%', marginTop: 30 }}
        maxItemsPerRow={3}
        maxItems={100}
      />,
    ]

    Object.keys(this.state.subProcessesConfig).forEach((process) => {
      if (
        this.state.selectedProcesses.includes(this.processKeyNameMap[process]) &&
        this.state.subProcessesConfig[process].selectorItems.length
      ) {
        componentsToRender.push(
          <CheckboxTabLabelled
            label={this.processKeyNameMap[process]}
            values={this.state.subProcessesConfig[process].selectedSubProcesses}
            selectorItems={this.state.subProcessesConfig[process].selectorItems}
            selectionHandler={(values) => this.selectionHandler(values, 'subProcess', process)}
            style={{ width: '82%', marginTop: 30 }}
            maxItemsPerRow={3}
            maxItems={100}
          />,
        )
      }
    })
    return componentsToRender
  }

  render() {
    let displaySelectionCheckbox = null
    let processSelectionCheckBoxes = null
    if (this.state.mounted) {
      displaySelectionCheckbox = (
        <CheckboxTabLabelled
          label={'Where should this be displayed?'}
          values={this.state.selectedCategory}
          selectorItems={this.state.allCategories}
          selectionHandler={(values) => this.selectionHandler(values, 'category', '')}
          style={{ width: '82%', marginTop: 30 }}
          maxItemsPerRow={3}
        />
      )
      processSelectionCheckBoxes = this.generateProcessSelectionCheckboxes()
    }

    return (
      <>
        <button style={styles.screenContainer} onClick={() => this.props.dismiss()} />
        <div style={styles.cardContainer}>
          <ScrollView style={{ width: '100%' }} contentContainerStyle={styles.scrollStyle}>
            <h1 style={styles.textStyle}>Document settings</h1>

            {displaySelectionCheckbox}

            <div style={{ ...styles.line }} />

            {processSelectionCheckBoxes}

            <ButtonBlue
              style={{
                marginTop: 60,
                marginBottom: 60,
                padding: '10px 0px',
                width: '84%',
                alignSelf: 'center',
              }}
              onClick={() => this.saveAndClose()}>
              {'Update and close'}
            </ButtonBlue>
          </ScrollView>
        </div>
      </>
    )
  }
}

let styles = {
  screenContainer: {
    zIndex: 1000,
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: ColorPalette.MODAL_BACKGROUND_OVERLAY,
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 0,
    border: 'none',
    overflow: 'hidden',
  },
  cardContainer: {
    zIndex: 1000,
    position: 'absolute' as 'absolute',
    top: window.innerHeight * 0.05,
    left: window.innerWidth * 0.5 - 393,
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
    backgroundColor: ColorPalette.CARD_WHITE,
    width: 786,
    padding: '20px 0px 0px 0px',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  scrollStyle: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    alignSelf: 'center',
    maxHeight: window.innerHeight * 0.92,
  },
  textStyle: {
    color: ColorPalette.PRIMARY_TEXT,
    textAlign: 'center' as 'center',
    alignSelf: 'center',
    fontFamily: 'roboto',
    fontWeight: 'bold',
    fontSize: '1.2rem',
    paddingLeft: 10,
    paddingRight: 10,
  },
  line: {
    width: '86%',
    borderTop: `1px solid ${ColorPalette.PRIMARY_BLUE}`,
    marginTop: 50,
    marginBottom: 20,
  },
}

export default Radium(DocDisplaySettingsModal)
