import { ColorPalette } from '../../config/colors'

import React, { Component } from 'react'
import Radium from 'radium'

interface ISideMenuProps {
  visible: boolean
  menuComponents: JSX.Element
  top?: number
  style?: React.CSSProperties
}

interface ISideMenuState {
  transitionIn: boolean
  mounted: boolean
}

class SideMenu extends Component<ISideMenuProps, ISideMenuState> {
  state: ISideMenuState = {
    transitionIn: true,
    mounted: false,
  }

  componentDidMount() {
    if (this.props.visible) {
      this.show()
    } else {
      this.hide()
    }
  }

  componentDidUpdate(prevProps: ISideMenuProps) {
    if (prevProps.visible !== this.props.visible) {
      if (this.props.visible) {
        this.show()
      } else {
        this.hide()
      }
    }
  }

  hide = () => {
    this.setState({ transitionIn: false }, () => setTimeout(() => this.setState({ mounted: false }), 80))
  }

  show = () => {
    this.setState({ transitionIn: true, mounted: true })
  }

  render() {
    let sideMenuItem = null
    const { style } = this.props
    if (this.state.mounted) {
      sideMenuItem = <div style={{ ...styles.sideMenuContainer, ...style }}>{this.props.menuComponents}</div>
    }

    return sideMenuItem
  }
}

export const SIDE_MENU_WIDTH = 'max(260px, 21%)'

let styles = {
  sideMenuContainer: {
    display: 'flex',
    width: SIDE_MENU_WIDTH,
    flexGrow: 0,
    flexShrink: 0,
    flexDirection: 'column' as 'column',
    alignItems: 'flex-start',
    backgroundColor: ColorPalette.CARD_WHITE,
    boxShadow: '0px 5px 10px rgba(140,140,150, 0.5)',
    borderRadius: '0px 15px 0px 0px',
    paddingTop: '2.5%',
    overflow: 'hidden',
  },
}

export default Radium(SideMenu)
