import React from 'react'
import Radium from 'radium'
import { mdiDownload } from '@mdi/js'

import { Toolbar } from '../../GeneralUI/Toolbar'

interface IDownloadButtonProps {
  json: Record<string, any> | any[]
  isPrimary?: boolean
  filename?: string
  style?: React.CSSProperties | undefined
}

export class DownloadAsFileButton extends React.Component<IDownloadButtonProps> {
  anchorRef: React.RefObject<HTMLAnchorElement>

  constructor(props: IDownloadButtonProps) {
    super(props)
    this.anchorRef = React.createRef<HTMLAnchorElement>()
  }

  onDownloadClicked() {
    if (this.anchorRef.current) {
      this.anchorRef.current.click()
    }
  }
  static createFileName(filename?: string): string {
    if (filename !== null && filename !== undefined) {
      if (filename.endsWith('.json')) {
        return filename
      }
      return `${filename}.json`
    }
    return `${new Date().getTime()}.json`
  }

  render() {
    const str = JSON.stringify(this.props.json, null, '  ')
    const blb = new Blob([str], { type: 'application/octetstream' })
    const url = URL.createObjectURL(blb)

    return (
      <>
        <Toolbar
          actionButtons={[
            {
              iconPath: mdiDownload,
              onClick: () => this.onDownloadClicked(),
              disabled: !this.props.json,
              label: 'DOWNLOAD',
            },
          ]}
        />
        <a
          href={url}
          ref={this.anchorRef}
          style={{ display: 'none' }}
          download={DownloadAsFileButton.createFileName(this.props.filename)}>
          Download
        </a>
      </>
    )
  }
}

export default Radium(DownloadAsFileButton)
