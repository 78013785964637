import { useEffect, useState, useRef } from 'react'
import { RouteComponentProps } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

import { withStyles, createStyles } from '@material-ui/core/styles'
import { CognitoUser } from '@aws-amplify/auth'
import { datadogRum } from '@datadog/browser-rum'
import Grid from '@material-ui/core/Grid'
import PeopleIcon from '@material-ui/icons/People'

import { ActionType } from '../../store/actions/actions'
import { SessionService } from '../../services'
import { ColorPalette, AllScreenNames } from '../../config'
import LoadingModal from '../../components/Modals/LoadingModal'
import CaptureIdPassportModal from '../../components/Modals/CaptureIdPassport'
import ForgotPasswordModal from '../../components/Modals/ForgotPassword'
import TextInputLabelled from '../../components/BaseComponents/Text/TextInputLabelled'
import ButtonBlue from '../../components/BaseComponents/Buttons/ButtonBlue'
import ButtonGeneric from '../../components/BaseComponents/Buttons/ButtonGeneric'
import AlertModalOneButton from '../../components/Modals/AlertModalOneButton'
import logo from '../../assets/PeopleFlow_background.png'
import logoHeader from '../../assets/logoHeader1.png'
import lines from '../../assets/lines.png'
import { PeopleFlowCombinedReducer } from '../../store'
import { IPermissionHandlerResult, IUserAccessResult } from '../../interfaces'
import NewPasswordModal from '../../components/Modals/NewPassword'
import { AssociationSettingsRepository, UserRepository } from '../../repositories'
import { LocalStorageProvider } from '../../provider'
import { ValidEmploymentStatus } from '../../types'

const { detect } = require('detect-browser')

interface LoginProps extends RouteComponentProps {
  setAuthenticated: (authenticated: boolean) => void
}

const Login = (props: LoginProps) => {
  let lastPressedMs = useRef(0)
  let counter = useRef(0)
  const screenHeight = window.innerHeight

  const initialModalState = {
    loadingModalOpen: false,
    warningModalOpen: false,
    captureIdPassportModalOpen: false,
    forgotPasswordModalOpen: false,
    newPasswordModalOpen: false,
    loadingModalMessage: '',
    warningModalHeader: '',
    warningModalMessage: '',
  }
  const [modalState, setModalState] = useState(initialModalState)

  const [isChrome, setIsChrome] = useState(true)
  const [initialiseAfterWarningOk, setInitialiseAfterWarningOk] = useState(false)
  const [awsUser, setAwsUser] = useState<CognitoUser | undefined>(undefined)
  const [idPassport, setIdPassport] = useState('')
  const [password, setPassword] = useState('')

  const associationRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.associationRepo,
  ) as AssociationSettingsRepository
  let selectedAssociation = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.selectedAssociation)
  const selectedRole = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.selectedRole)
  const loading = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.loading)

  const dispatch = useDispatch()
  const updateState = (data: any) => dispatch({ type: ActionType.UPDATE_STATE, data })
  const setAllEmployers = (allClientNames: string[]) =>
    dispatch({
      type: ActionType.SET_ALL_COMPANIES,
      data: { allClientNames },
    })
  const setAllRoles = (allRoles: string[]) => dispatch({ type: ActionType.SET_ALL_ROLES, data: { allRoles } })
  const setAllCohorts = (allCohorts: string[]) => dispatch({ type: ActionType.SET_ALL_COHORTS, data: { allCohorts } })

  useEffect(() => {
    reset()
  }, [])

  const reset = async () => {
    await logout()
    const browser = detect()
    setIsChrome(browser?.name === 'chrome')
    updateState({
      currentSection: AllScreenNames.LOGIN,
      currentScreen: AllScreenNames.LOGIN,
    })
    props.history.replace(`/`) // Necessary when an error occurs and user is navigated here as a fallback
  }

  const closeModals = () => setModalState(initialModalState)

  const logout = async (): Promise<void> => {
    updateState({
      loading: false,
      idPassport: '',
      password: '',
      docValidityFilterState: {},
    })
    props.setAuthenticated(false)
    return SessionService.logout().then(() => {
      if (process.env.NODE_ENV === 'production') {
        datadogRum.clearUser()
      }
    })
  }

  const initialise = (associationRepo: AssociationSettingsRepository, selectedAssociation: string) => {
    const loginEmploymentStatus: ValidEmploymentStatus = 'EMPLOYEE'
    const userAssociations = associationRepo.getAssociationNames()
    const userAssociationCohorts = associationRepo.getAllCohorts(selectedAssociation)
    const userAssociationRoles = associationRepo.getUserRoles(selectedAssociation)
    setAllEmployers(userAssociations)
    setAllRoles(userAssociationRoles)
    setAllCohorts(userAssociationCohorts)

    updateState({ selectedEmploymentStatus: loginEmploymentStatus })
    props.setAuthenticated(true)

    const userAccessResult: IUserAccessResult = associationRepo.getUserAccess(selectedAssociation, selectedRole)
    const { hasAccess, clientName, permissionResult } = userAccessResult

    if (!hasAccess) {
      displayWarning({ code: 'NoWebAccess' })
      return
    }

    const {
      path,
      currentScreen,
      currentSection,
      hasDataLogsAccess,
      hasDeviceLogsAccess,
      hasDocValidityAccess,
      hasFormsAccess,
      hasImportAccess,
      hasRequestHandlerAccess,
      hasTrashAccess,
      hasUserManagementAccess,
      hasWorkforceAccess,
      hasDocMarkingAccess,
    } = permissionResult as IPermissionHandlerResult

    setModalState((modalState) => ({ ...modalState, loadingModalOpen: false }))
    let initialPath = path
    if (selectedRole === 'AUDITOR') {
      initialPath = '/reporting/documentvalidity'
    }
    navigate(initialPath, {
      hasDataLogsAccess,
      hasDeviceLogsAccess,
      hasDocValidityAccess,
      hasFormsAccess,
      hasImportAccess,
      hasRequestHandlerAccess,
      hasTrashAccess,
      hasUserManagementAccess,
      hasWorkforceAccess,
      hasDocMarkingAccess,
      currentScreen,
      currentSection,
      // selectedAssociation: clientName,
      selectedAssociation,
    })
  }

  const setRumUser = (idPassport: string, userRepo: UserRepository) => {
    if (process.env.NODE_ENV === 'production') {
      const user = userRepo.getCurrentUserEntity()
      datadogRum.setUser({
        id: idPassport,
        name: user.getFullName(),
        email: user.getEmail(),
      })
    }
  }

  const getBareAssociationSettings = async (): Promise<Record<string, any>> => {
    let associationSettings: Record<string, any> = {}
    try {
      const result = await SessionService.initialiseSession(selectedAssociation, idPassport, {
        username: idPassport,
        password,
      })
      const { userRepo, clientSettings } = result

      if (clientSettings) {
        associationSettings = clientSettings
      }
      setRumUser(idPassport, userRepo)
      updateState({ idPassport, password })
    } catch (error: any) {
      console.error('ERROR', error)
      if (error?.code && error?.code === 'NewPasswordRequired') {
        const awsUser = error.user as CognitoUser
        newPassword(selectedAssociation, idPassport, awsUser)
      } else {
        displayWarning(error)
      }
      return {}
    }
    return { clientSettings: associationSettings }
  }

  const login = async (): Promise<void> => {
    if (!idPassport || !password) {
      displayWarning({ code: 'MissingInfo' })
      return
    }

    setModalState({
      ...initialModalState,
      loadingModalOpen: true,
      loadingModalMessage: 'Logging in',
    })
    const result: Record<string, any> | void = await getBareAssociationSettings()
    const availableClients = result && result.clientSettings ? Object.keys(result.clientSettings) : []
    if (availableClients.length > 0 && !availableClients?.includes(selectedAssociation)) {
      selectedAssociation = availableClients[0]
    }
    let assocRepo: AssociationSettingsRepository

    try {
      const { associationRepo, profileRepo, userRepo } = await SessionService.login(
        selectedAssociation,
        idPassport,
        password,
      )
      updateState({ associationRepo, profileRepo, userRepo, selectedAssociation })
      assocRepo = associationRepo
      // updateState({ idPassport, password });
    } catch (error: any) {
      if (error?.code && error?.code === 'NewPasswordRequired') {
        const awsUser = error.user as CognitoUser
        newPassword(selectedAssociation, idPassport, awsUser)
      } else {
        displayWarning(error)
      }
      return
    }
    setTimeout(() => {
      if (selectedAssociation === '') {
        const associationNames = assocRepo.getAssociationNames()
        if (associationNames.length > 0) {
          selectedAssociation = associationNames[0]
        }
      }
      if (!selectedAssociation) {
        setModalState((modalState) => ({ ...modalState, captureIdPassportModalOpen: true }))
        return
      }
      const organisationConfig = assocRepo.getOrganisationConfig(selectedAssociation)
      const userAccessAllow = organisationConfig?.stopGapResourceBasedPolicy?.allow?.find(
        (obj: Record<string, any>) => obj.idPassport === idPassport,
      )
      const userAccessDeny = organisationConfig?.stopGapResourceBasedPolicy?.deny?.find(
        (obj: Record<string, any>) => obj.idPassport === '*' || obj.idPassport === idPassport,
      )
      if (!userAccessAllow && userAccessDeny && userAccessDeny.access?.type && userAccessDeny.access?.expiryDate) {
        const accessType = userAccessDeny.access.type
        const expiryDate = new Date(
          userAccessDeny.access.expiryDate
            .split('-')
            .reverse() // reverse order because the Date constructor expects the year, month, and day in the format YYYY-MM-DD
            .join('-'),
        )
        const today = new Date()
        if (expiryDate < today) {
          if (accessType === 'arrears') {
            displayWarning({ code: 'accountInArrears' }, 'Account in arrears')
            updateState({ associationRepo })
            setInitialiseAfterWarningOk(true)
          }
          if (accessType === 'deactivated') {
            displayWarning({ code: 'accountDeactivated' }, 'Account deactivated')
          }
          return
        }
      }
      initialise(assocRepo, selectedAssociation)
    }, 1500)
  }

  const forgotPassword = async (idPassport: string) => {
    try {
      setModalState({
        ...initialModalState,
        loadingModalMessage: 'Loading...',
        loadingModalOpen: true,
      })
      updateState({ idPassport })
      await SessionService.forgotPassword(idPassport)
      setIdPassport(idPassport)
      setModalState({ ...initialModalState, forgotPasswordModalOpen: true })
    } catch (error) {
      displayWarning(error)
    }
  }

  const newPassword = async (selectedAssociation: string, idPassport: string, awsUser: CognitoUser) => {
    try {
      setModalState({
        ...initialModalState,
        loadingModalMessage: 'Loading...',
        loadingModalOpen: true,
      })
      updateState({ idPassport })
      setModalState({ ...initialModalState, newPasswordModalOpen: true })
      setAwsUser(awsUser)
    } catch (error) {
      displayWarning(error)
    }
  }

  const onEnvToggle = () => {
    const savedEnv = localStorage.getItem('env')
    let env = 'prod'
    if (!savedEnv || savedEnv === 'prod') {
      env = 'staging'
    }
    localStorage.setItem('env', env)
    setModalState({
      ...initialModalState,
      warningModalOpen: true,
      warningModalHeader: `Switched to ${env}`,
      warningModalMessage: `Refresh the page before logging in.`,
    })
  }

  const handlePeopleIconClicked = () => {
    const currentMs = new Date().getTime()
    if (currentMs - lastPressedMs.current < 500) {
      counter.current += 1
      if (counter.current >= 7) {
        onEnvToggle()
        counter.current = 0
      }
    } else {
      counter.current = 1
    }
    lastPressedMs.current = currentMs
  }

  const handlePeopleFlowIconClicked = (e: React.MouseEvent) => {
    const currentMs = new Date().getTime()
    if (currentMs - lastPressedMs.current < 500) {
      counter.current += 1
      if (counter.current >= 3) {
        LocalStorageProvider.resetDb()
        localStorage.clear()
        const target = e.target as HTMLElement
        // rotate target element as indication of IndexedDB being cleared
        target.style.transform = 'rotate(360deg)'
        target.style.transition = 'transform 1s'
        counter.current = 0
      }
    } else {
      counter.current = 1
    }
    lastPressedMs.current = currentMs
  }

  const navigate = (pathname: string, propsToUpdate = {}) => {
    updateState({ loading: false, ...propsToUpdate })
    setTimeout(() => props.history.push({ pathname }), 100)
  }

  const displayWarning = (error: any, title?: string): void => {
    let header = title || 'Warning'
    let warning = ''
    const onFinish = () => {
      setModalState({
        ...initialModalState,
        warningModalOpen: true,
        warningModalHeader: header,
        warningModalMessage: warning,
      })
    }
    if (!error?.code) {
      console.error('Unexpected error', error)
      warning =
        'We encountered a problem. Refresh the page and try again. If you are still unsuccessful, contact tech support for assistance'
      onFinish()
      return
    }

    switch (error.code) {
      case 'accountInArrears':
        warning = 'Your PeopleFlow account is in arrears. Settle the outstanding balance to prevent deactivation.'
        break
      case 'accountDeactivated':
        warning =
          'Your PeopleFlow account has been deactivated due to non-payment. Settle the balance to have services restored.'
        break
      case 'MissingInfo':
        warning = 'Enter both your ID number and password'
        break
      case 'NoWebAccess':
        header = 'Not authorised'
        warning = 'You are not authorised to access the PeopleFlow admin portal.'
        break
      case 'UserNotFoundException':
      case 'InvalidIdPassport':
        warning = "The ID number you entered isn't registered."
        break
      case 'NotAuthorizedException':
        warning = "The password you entered doesn't match this ID number."
        break
      case 'NewPasswordRequired':
        warning = 'You need to choose a new password. Log into the PeopleFlow app on your iPad to do so.'
        break
      case 'NetworkTimeout':
        warning = 'There was a network issue. Check your internet connection and firewall settings, and try again.'
        break
      case 'NetworkError':
        warning = 'Your internet connection is down. Reconnect, then try again.'
        break
      case 'LimitExceededException':
        warning = "You've reached your retry limit. Try again in a while."
        break
      case 'NoClientSettings':
      case 'NoClientSettingsInitialised':
      case 'NoClientSettingsForSelectedClient':
        warning = 'You will need to login again to continue'
        break
      case 'LoginFailed':
        warning = 'Login attempted failed. Please refresh and try again.'
        break
      default:
        warning = 'Unknown error. Please refresh and try again.'
        break
    }

    if ('message' in error && !warning) {
      warning =
        "The following error message was returned when logging in: '" +
        error.message +
        "'. Contact tech support to report the issue."
    }
    onFinish()
  }

  let pageContent = (
    <div
      style={{
        ...getStyle('container'),
        height: screenHeight,
        maxHeight: screenHeight,
      }}>
      <div
        style={{
          position: 'absolute',
          top: 120,
          right: 0,
          width: '45%',
          borderBottom: `0.6px solid rgba(90, 30, 130)`,
        }}
      />
      <img src={lines} alt="PeopleFlow" style={getStyle('line')} />

      <Grid
        style={getStyle('leftContainer')}
        container
        item
        direction="column"
        justify="center"
        alignItems="flex-start"
        md={6}
        lg={7}>
        <img src={logo} alt="PeopleFlow" style={getStyle('heroBackground')} />
      </Grid>

      <Grid style={getStyle('rightContainer')} direction="column" md={6} lg={5}>
        <Grid style={getStyle('logoContainer')} md={8} lg={12}>
          <img src={logoHeader} alt="PeopleFlow" style={getStyle('logoStyle')} onClick={handlePeopleFlowIconClicked} />
        </Grid>

        <Grid style={getStyle('cardContainer')} md={8} lg={8}>
          <button onClick={handlePeopleIconClicked} style={getStyle('iconContainer')}>
            <PeopleIcon style={getStyle('icon')} />
          </button>
          <TextInputLabelled
            containerStyle={{ marginTop: 40 }}
            primaryStyle={{ width: '80%', height: 50 }}
            inputLabelProps={{ color: 'secondary' }}
            label="ID number"
            value={idPassport}
            textHandler={(value) => setIdPassport(value)}
            type="text"
            multiline={false}
            maxLength={32}
            icon="id"
          />
          <TextInputLabelled
            containerStyle={{ marginTop: 24 }}
            primaryStyle={{ width: '80%', height: 50 }}
            inputLabelProps={{ color: 'secondary' }}
            label="Password"
            value={password}
            textHandler={(value) => setPassword(value)}
            type="password"
            multiline={false}
            maxLength={32}
            icon="password"
          />
          <ButtonBlue style={{ marginTop: '12%', flex: '0 0 auto' }} onClick={() => login()} loading={loading}>
            Login
          </ButtonBlue>
          <ButtonGeneric
            style={getStyle('forgotPasswordButton')}
            label={'Forgot password?'}
            onClick={() => setModalState((modalState) => ({ ...modalState, captureIdPassportModalOpen: true }))}
          />
        </Grid>
      </Grid>
      <CaptureIdPassportModal
        open={modalState.captureIdPassportModalOpen}
        submit={(idPassport) => forgotPassword(idPassport)}
        dismiss={() => closeModals()}
      />
      <ForgotPasswordModal
        open={modalState.forgotPasswordModalOpen}
        idPassport={idPassport}
        dismiss={() => closeModals()}
      />
      <NewPasswordModal open={modalState.newPasswordModalOpen} awsUser={awsUser} dismiss={() => closeModals()} />
      <AlertModalOneButton
        open={modalState.warningModalOpen}
        header={modalState.warningModalHeader}
        body={modalState.warningModalMessage}
        buttonLabel={'Ok'}
        onClick={() => {
          if (initialiseAfterWarningOk) {
            initialise(associationRepo, selectedAssociation)
          }
          closeModals()
        }}
      />
      <LoadingModal open={modalState.loadingModalOpen}>{modalState.loadingModalMessage}</LoadingModal>
    </div>
  )
  if (!isChrome || window.innerWidth < 960) {
    pageContent = (
      <div style={{ ...getStyle('chromeOnlyContainer') }}>
        <img src={logoHeader} alt="PeopleFlow" style={{ ...getStyle('chromeOnlyImage') }} />
        <div
          style={{
            marginTop: 20,
            width: '75%',
            borderBottom: `1.4px solid ${ColorPalette.PRIMARY_BLUE}`,
          }}
        />
        <p style={{ ...getStyle('chromeOnlyText') }}>Available on Chrome</p>
        <p style={{ ...getStyle('chromeOnlySubText') }}>(desktop version)</p>
      </div>
    )
  }

  return pageContent
}

const styles = createStyles({
  container: {
    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255, 1), rgba(220,220,230, 1))',
    backgroundSize: 'cover',
    overflowY: 'hidden',
    display: 'flex',
    position: 'relative',
  },
  leftContainer: {
    height: '100%',
    overflow: 'hidden',
    zIndex: 1,
    flex: '1 1 0px',
  },
  rightContainer: {
    zIndex: 2,
    height: '100%',
    flex: '1 1 0px',
    background: 'rgba(0, 0, 0, 0)',
    display: 'flex',
  },
  heroBackground: {
    marginTop: 300,
    marginLeft: -570,
    background: 'rgba(0, 0, 0, 0)',
    width: 1300,
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    alignItems: 'flex-start',
    paddingTop: '6%',
    paddingRight: '7%',
    height: 120,
    flex: '0 0 auto',
  },
  logoStyle: {
    height: 60,
  },
  cardContainer: {
    alignItems: 'center',
    backgroundColor: 'rgba(250,250,250,1)',
    boxShadow: '0px 3px 10px rgba(60,60,60, 0.1)',
    display: 'flex',
    flex: '0 0 auto',
    flexDirection: 'column' as 'column',
    marginBottom: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    maxWidth: '420px',
    paddingBottom: '3%',
    paddingLeft: '6%',
    paddingRight: '6%',
    paddingTop: '2%',
    width: '100%',
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
    border: `6px solid ${ColorPalette.LIGHT_GREY}`,
    width: 140,
    height: 140,
    alignSelf: 'center',
    marginTop: 40,
    boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.15)',
  },
  icon: {
    width: 100,
    height: 100,
    marginBottom: 10,
    alignSelf: 'center',
    color: ColorPalette.LIGHT_GREY,
  },
  warningText: {
    textAlign: 'center' as 'center',
    alignSelf: 'center',
    fontFamily: 'roboto',
    fontWeight: 'normal',
    color: ColorPalette.WARNING_RED,
    fontSize: 14,
    marginTop: 30,
  },
  line: {
    opacity: '20%',
    position: 'absolute' as 'absolute',
    top: 490,
    left: -5,
    width: '97%',
  },
  chromeOnlyContainer: {
    marginTop: -20,
    width: '100%',
    height: window.innerHeight,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  chromeOnlyImage: {
    width: window.innerWidth * 0.6,
    height: 'auto',
  },
  chromeOnlyText: {
    fontFamily: 'Roboto',
    fontStyle: 'italic',
    alignSelf: 'center',
    fontSize: '1.2rem',
    fontWeight: 'bolder',
    color: 'rgba(30,0,160,1)',
  },
  chromeOnlySubText: {
    fontFamily: 'Roboto',
    fontStyle: 'italic',
    fontSize: '1rem',
    fontWeight: 'bolder',
    alignSelf: 'center',
    color: 'rgba(30,0,160,1)',
    marginTop: -15,
  },
  forgotPasswordButton: {
    color: ColorPalette.SECONDARY_TEXT,
    justifyContent: 'center',
    height: 60,
  },
})

function getStyle(
  k:
    | 'container'
    | 'leftContainer'
    | 'heroBackground'
    | 'rightContainer'
    | 'logoContainer'
    | 'logoStyle'
    | 'cardContainer'
    | 'iconContainer'
    | 'icon'
    | 'warningText'
    | 'line'
    | 'chromeOnlyContainer'
    | 'chromeOnlyImage'
    | 'chromeOnlyText'
    | 'chromeOnlySubText'
    | 'forgotPasswordButton',
): React.CSSProperties | undefined {
  const x = styles[k]
  if (!x) {
    return undefined
  }
  return x as React.CSSProperties
}

export default withStyles(styles, { withTheme: true })(Login)
