import { ColorPalette } from '../../../config/colors'
import { forwardRef } from 'react'
import Radium from 'radium'
import { ITextInputProps } from './text.input'

type Ref = HTMLInputElement

const textInput = forwardRef<Ref, ITextInputProps>((props: ITextInputProps, ref) => {
  switch (props.type) {
    case 'textarea':
      return (
        <textarea
          // ref={ref}
          style={{ ...styles.defaultStyle, ...props.style }}
          placeholder={props.placeholder ? props.placeholder : 'Type here'}
          value={props.value}
          onChange={props.onChange}
          onFocus={props.onFocus}
          autoFocus={props.autoFocus ? props.autoFocus : true}
          disabled={props.disabled}
          rows={props.textAreaRows}
          onClick={props.onClick}
        />
      )
    default:
      return (
        <input
          ref={ref}
          type={props.type ? props.type : 'text'}
          style={{ ...styles.defaultStyle, ...props.style }}
          placeholder={props.placeholder ? props.placeholder : 'Type here'}
          value={props.value}
          onChange={props.onChange}
          onFocus={props.onFocus}
          autoFocus={props.autoFocus ? props.autoFocus : true}
          disabled={props.disabled}
          onClick={props.onClick}
        />
      )
  }
})

const styles = {
  defaultStyle: {
    color: ColorPalette.PRIMARY_LIGHT_TEXT,
    placeholderColor: ColorPalette.PRIMARY_LIGHT_TEXT,
    width: '100%',
    fontSize: '0.9rem',
    fontFamily: 'roboto',
    backgroundColor: 'rgba(255,255,255,0)',
    border: 'none',
    outline: 0,
  },
}

export default Radium(textInput)
