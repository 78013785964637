import { AllScreenNames } from '../config/allScreens'

export const rootPaths = [AllScreenNames.IMPORT]
export const buttonsWithCustomActions: string[] = [
  AllScreenNames.BULK_ACTIONS,
  AllScreenNames.BULK_DISCIPLINE_FORMS,
  AllScreenNames.DISCIPLINE,
  AllScreenNames.EXPORT,
  AllScreenNames.EXPORT_DOC_VALIDITY_DATA,
  AllScreenNames.EXPORT_DOCS,
  AllScreenNames.EXPORT_PROFILES,
  AllScreenNames.REPORTING,
  AllScreenNames.TERMINATE,
]
