import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import { ColorPalette } from '../../config/colors'
import { PeopleFlowCombinedReducer } from '../../store'
import CheckboxTabLabelled from '../BaseComponents/Checkboxes/CheckboxTabLabelled'
import TextInputLabelled from '../BaseComponents/Text/TextInputLabelled'
import { GroupService } from '../../services'
import { getInstancesByPpeKeys, getPpeInPossession } from '../../utils/ppeUtils'
import { UserFeedback } from '../GeneralUI/Feedback/UserFeedback'
import { AssociationSettingsRepository, ProfileRepository } from '../../repositories'
import { ProfilePk } from '../../models'
import { PpeActionRecordType, PpeForProfile, PpeInstance, PpeInstanceRecordSk } from '../../types'

export type GroupMemberPpeProps = {
  profilePk: string
  jobType: string
  scheduledStartDate: string
  worksite?: string
  location: string
  name: string
  surname: string
  idPassport: string
  existingSelections: PpeForProfile | undefined
  allPpeInstances?: PpeInstance[]
  onSelectionChange: (selections: PpeForProfile) => void
}

type Selector = {
  key: string
  label: string
  options: string[]
  value?: string
}

export const GroupMemberPpe = (props: GroupMemberPpeProps) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  /*
   @NICKI: This component is way too specific to PPE and could've been generalised fairly easily using similar render logic as that used when viewing profile data. 
   We HAVE to get into the habit of generalising components wherever possible to ensure re-usability and prevent bugs.
   e.g. The app has 1 field capturing component (ProfileDataCapturer.js) that is used for rendering dataflows and saving the necessary logic thereafter. We need to take a similar approach on the web.
  */
  // const allProfiles = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.allProfiles)
  const selectedAssociation = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedAssociation,
  )
  const selectedCohort = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.selectedCohort)
  const associationRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.associationRepo,
  ) as AssociationSettingsRepository
  const profileRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.profileRepo,
  ) as ProfileRepository

  const [selectors, setSelectors] = useState<Selector[]>([])
  const [selections, setSelections] = useState<PpeForProfile>(props.existingSelections || {})

  useEffect(() => {
    if (props.allPpeInstances && props.allPpeInstances.length > 0) {
      getSelectorsFromDataFlow(props.profilePk, props.allPpeInstances)
    }
  }, [props.profilePk, props.allPpeInstances])

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.style.height = `calc(100vh - ${scrollRef.current.offsetTop}px - 85px)`
      scrollRef.current.style.overflowY = 'auto'
    }
  }, [scrollRef])

  const indicateIssuedStatus = (options?: string[], currentIssuedSizeValue?: string) => {
    const opts = options ? [...options] : []
    if (currentIssuedSizeValue && opts && opts.length > 0) {
      const matchingOptionIndex = opts.findIndex((option) => option === currentIssuedSizeValue.toString())
      if (matchingOptionIndex > -1) {
        opts.splice(matchingOptionIndex, 1, `${currentIssuedSizeValue} (ISSUED)\u002A`)
      }
    }
    return opts || []
  }

  const getSelectorsFromDataFlow = async (profilePk: ProfilePk, allPpeInstances: PpeInstance[] = []) => {
    const allDataFlows = associationRepo.getAllDataFlows(selectedAssociation)
    const ppeIssueDataFlow = GroupService.getPpeSizeSelectionDataFlow(
      selectedAssociation,
      selectedCohort,
      associationRepo,
    )
    const dataFlowSelectors = allDataFlows[ppeIssueDataFlow]?.dataFlow.ppeIssue
    const dataFlowSelectorsKeys = dataFlowSelectors.map((selector: any) => selector.key)

    let selectors: Selector[] = []
    const allDataflowRelatedPpeInPossession: Record<PpeInstanceRecordSk, PpeActionRecordType>[] = getPpeInPossession(
      profileRepo,
      profilePk,
      dataFlowSelectorsKeys,
      allPpeInstances,
    )
    const ppeSksInPossession = allDataflowRelatedPpeInPossession.map((record) => Object.keys(record)[0])
    dataFlowSelectors.forEach((selector: any) => {
      const instances = getInstancesByPpeKeys(allPpeInstances, [selector.key])
      const ppeInPossession = instances.find((instance) => ppeSksInPossession.includes(instance.sk))
      const currentIssuedSizeValue = ppeInPossession?.ppeSize

      if (Object.keys(selector).length === 1 && selector.hasOwnProperty('key')) {
        // when data flow references field without overriding its original values found in field config
        const fieldConfig = associationRepo.getFieldConfig(selectedAssociation)
        const field = fieldConfig[selector.key]
        const { key, label, selectorItems } = field
        const options = indicateIssuedStatus(selectorItems, currentIssuedSizeValue)
        selectors.push({ key, label, options })
        return
      }

      const { key, label, selectorItems } = selector
      const options = indicateIssuedStatus(selectorItems, currentIssuedSizeValue)
      selectors.push({ key, label, options, value: selector.value })
    })
    setSelectors(selectors)
    if (selections) {
      if (isEmpty(selections)) {
        let initialSelections: PpeForProfile = {}
        selectors.forEach((selector) => {
          initialSelections[selector.key] = selector.value || ''
        })
        setSelections(initialSelections)
        props.onSelectionChange(initialSelections)
        return
      }
      setSelections(selections)
      props.onSelectionChange(selections)
    }
  }

  const handleSizeSelection = (key: string, size: string) => {
    const currentSelections = { ...selections }
    currentSelections[key] = size
    setSelections(currentSelections)
    props.onSelectionChange(currentSelections)
  }

  const renderSelectorComponents = () => {
    const components = selectors.map((selector: Selector, index: number) => {
      const { key, label, options } = selector
      const style: React.CSSProperties = { ...styles.sectionInput }
      if (index === 0) {
        style.margin = '20px 1.5em'
      }
      return (
        <CheckboxTabLabelled
          style={style}
          itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
          label={label}
          values={selections ? [selections[key]] : []}
          selectorItems={options}
          selectionHandler={(sizeSelection: any) => handleSizeSelection(key, sizeSelection[0])}
          maxItemsPerRow={5}
          key={`checkbox_${key}_${label}_${JSON.stringify(options)}`}
        />
      )
    })
    return components
  }

  const { name, surname, idPassport, jobType, scheduledStartDate, worksite, location } = props

  const noPpeInstock = !props.allPpeInstances || props.allPpeInstances.length === 0

  let noPpeStockWarning = null
  if (noPpeInstock) {
    noPpeStockWarning = <UserFeedback message="No PPE in stock. No selection currently possible." />
  }

  let selectorSection = null
  if (!noPpeInstock && selections) {
    selectorSection = (
      <>
        <AsteriskLegend />
        <div>{renderSelectorComponents()}</div>
      </>
    )
  }

  return (
    <div style={styles.details}>
      <div style={styles.section}>
        <h1 style={styles.sectionHeader}>{`${idPassport} - ${name} ${surname}`}</h1>
        <div ref={scrollRef}>
          <BasicInfo jobType={jobType} startDate={scheduledStartDate} worksite={worksite} location={location} />
          {noPpeStockWarning}
          {selectorSection}
        </div>
      </div>
    </div>
  )
}

const styles = {
  section: {
    width: '100%',
    margin: '2% 0',
  },
  sectionHeader: {
    marginTop: 0,
    fontSize: '1rem',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    color: ColorPalette.PRIMARY_TEXT,
    textAlign: 'left' as 'left',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid' as 'solid',
    borderBottomColor: ColorPalette.PRIMARY_BLUE,
    padding: '0em 0.5em 0.5em 0.5em',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sectionInput: {
    margin: '40px 1.5em 0px 1.5em',
  },
  details: {
    width: '91%',
    margin: '0 auto',
  },
}

type BasicInfoProps = {
  jobType: string
  startDate: string
  worksite?: string
  location: string
}

const Asterisk = () => (
  <span style={{ verticalAlign: 'super', color: ColorPalette.PRIMARY_TEXT, fontSize: '0.65rem' }}>*</span>
)
// NOTE: generalise and extract when needed by other components too
const AsteriskLegend = () => (
  <div style={{ ...styles.sectionInput, color: ColorPalette.PRIMARY_TEXT, fontSize: '0.8rem' }}>
    <Asterisk /> <i>last selected size</i>
  </div>
)

const BasicInfo = ({ jobType, startDate, worksite, location }: BasicInfoProps) => (
  <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: 40, marginBottom: 60 }}>
    <TextInputLabelled
      inputProps={{ color: ColorPalette.PRIMARY_TEXT, fontSize: '0.8rem' }}
      key="jobType"
      label="Job Type"
      value={jobType}
      maxLength={100}
      disabled={true}
      textHandler={(value, isMount) => null}
    />
    <TextInputLabelled
      inputProps={{ color: ColorPalette.PRIMARY_TEXT, fontSize: '0.8rem' }}
      key="startDate"
      label="Scheduled Start Date"
      value={startDate}
      maxLength={100}
      disabled={true}
      textHandler={(value, isMount) => null}
    />
    {/* <TextInputLabelled
      inputProps={{ color: ColorPalette.PRIMARY_TEXT, fontSize: '0.8rem' }}
      key="workSite"
      label="Worksite"
      value={worksite || '-'}
      maxLength={100}
      disabled={true}
      textHandler={(value, isMount) => null}
    />
    <TextInputLabelled
      inputProps={{ color: ColorPalette.PRIMARY_TEXT, fontSize: '0.8rem' }}
      key="location"
      label="Location"
      value={location || '-'}
      maxLength={100}
      disabled={true}
      textHandler={(value, isMount) => null}
    /> */}
  </div>
)
