import { mdiFileAlertOutline, mdiFileCheckOutline } from '@mdi/js'
import { filePicker } from '../../../utils'
import FileSelectorButton from '../../BaseComponents/Buttons/FileSelectorButton'
import { useState } from 'react'

type CsvFileSelectorProps = {
  instructions: string | React.ReactNode
  fileSelected: boolean
  insertSection?: React.ReactNode
  onFileSelection: (file: File) => void
  onCancel: () => void
}

export const CsvFileSelector = (props: CsvFileSelectorProps) => {
  const { instructions, fileSelected, insertSection, onFileSelection, onCancel } = props

  const [incorrectSelectedFileType, setIncorrectSelectedFileType] = useState(false)
  const [selectedFileName, setSelectedFileName] = useState('')

  const getLabel = () => {
    if (incorrectSelectedFileType) {
      return 'INCORRECT FILE TYPE. PLEASE SELECT A CSV FILE'
    }

    let fileSelectorButtonLabel = 'BROWSE AND SELECT FILE (CSV format only)'
    if (fileSelected) {
      fileSelectorButtonLabel = `CLICK TO CHOOSE A DIFFERENT FILE.`
    }
    return fileSelectorButtonLabel
  }

  const getIconPath = () => {
    if (incorrectSelectedFileType) {
      return mdiFileAlertOutline
    }
    if (fileSelected) {
      return mdiFileCheckOutline
    }
    return // mdiFilePlusOutline is FileSelectorButton's default icon
  }

  const handleFileSelection = async (e: React.ChangeEvent<{ value: string }>) => {
    const { file, type } = filePicker(e)[0]
    if (type !== 'text/csv') {
      setIncorrectSelectedFileType(true)
      return
    } else {
      setIncorrectSelectedFileType(false)
    }
    setSelectedFileName(file.name)
    onFileSelection(file)
  }

  const fileSelectorButtonLabel = getLabel()
  const iconPath = getIconPath()
  return (
    <div style={styles.container}>
      {instructions}
      <div>
        <FileSelectorButton
          style={styles.selector}
          id="addFile"
          buttonLabel={fileSelectorButtonLabel}
          fileName={selectedFileName}
          fileHandler={handleFileSelection}
          onCancelSelection={onCancel}
          iconPath={iconPath}
        />
      </div>
      {insertSection}
    </div>
  )
}

const styles = {
  container: {
    width: '94%',
  },
  selector: {
    height: '20vh',
    marginTop: '2em',
  },
}
