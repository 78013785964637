import { ClientRequestStepTypes } from './iclient.request.step.types'
import { sortArrayOfObjects } from '../../utils'

export interface IRequestInstanceConfig {
  getRequestName(): string
  getRequestKey(): string
  getDescription(): string
  getAuthor(): string
  getRequiredAuthQty(): number
  getTemplateCreationUnixMs(): number
  getStepLabel(stepName: ClientRequestStepTypes | undefined): string
  getStepConfig(stepName: ClientRequestStepTypes | undefined): Record<string, any>
  getStepOrder(): Record<string, any>[]
  getAllStepConfig(): Record<string, any>
  getOrderedStepNameArray(): ClientRequestStepTypes[]
  isRequestInstanceConfig: boolean
}

class RequestInstanceConfig implements IRequestInstanceConfig {
  private name: string = ''
  private key: string = ''
  private description: string = ''
  private author: string = ''
  private createdUnixMs: number = new Date().getTime()
  private config: Record<string, any> = {}
  private stepOrder: Record<string, any>[] = []
  isRequestInstanceConfig: boolean = true

  constructor(json: any) {
    if (!json) {
      return
    }
    this.name = json.name
    this.key = json.key
    this.description = json.description
    this.author = json.author
    this.createdUnixMs = typeof json.createdUnixMs === 'string' ? parseInt(json.createdUnixMs) : json.createdUnixMs
    this.config = json[`${json.key}Config`]
    this.stepOrder = json.stepOrder
    this.isRequestInstanceConfig = true
  }

  getRequestName(): string {
    return this.name
  }

  getRequestKey(): string {
    return this.key
  }

  getDescription(): string {
    return this.description
  }

  getAuthor(): string {
    return this.author
  }

  getRequiredAuthQty(): number {
    const authConfig = this.getStepConfig(ClientRequestStepTypes.AUTH)
    if ('requiredAuths' in authConfig) {
      return authConfig.requiredAuths
    }
    return 0
  }

  getStepOrder(): Record<string, any>[] {
    return this.stepOrder
  }

  getOrderedStepNameArray(): ClientRequestStepTypes[] {
    let stepOrder = this.getStepOrder()
    stepOrder = sortArrayOfObjects('index', 'ascending', stepOrder)
    return stepOrder.map((stepItem) => stepItem.stepName)
  }

  getTemplateCreationUnixMs(): number {
    return this.createdUnixMs
  }

  getStepLabel(stepName: ClientRequestStepTypes | undefined): string {
    const stepConfig = this.getStepConfig(stepName)
    if (!stepConfig.label) {
      return ''
    }
    return stepConfig.label
  }

  getAllStepConfig(): Record<string, any> {
    return this.config
  }

  getStepConfig(stepName: ClientRequestStepTypes | undefined): Record<string, any> {
    if (!stepName || !this.config[stepName]) {
      return {}
    }
    return this.config[stepName]
  }
}

export class RequestInstanceConfigFactory {
  static create(json: any): IRequestInstanceConfig {
    return new RequestInstanceConfig(json)
  }
}
