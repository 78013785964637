import { toUpperCaseCustom } from '../../utils'
import { ColorPalette } from '../../config/colors'

import React from 'react'
import Radium from 'radium'

import ButtonBlue from '../BaseComponents/Buttons/ButtonBlue'
import CheckboxTabLabelled from '../BaseComponents/Checkboxes/CheckboxTabLabelled'
import InfoCollectorModal from './InfoCollector'
import { SignatureFieldConfig } from '../../models'

interface SignatureFieldSettingsModalState {
  selectorItemCollectorModalOpen: boolean
  possibleSignatoryNames: string[]
  authedSignatories: { name: string; surname: string; idPassport: string }[]
  compulsory: boolean
  isCompulsory: string[]
  signatoryType: string
  pinProtected: boolean
  isPinProtected: string[]
  allSignatoryTypes: string[]
  activeSetting: string
  settings?: SignatureFieldConfig

  [index: string]: any
}

interface SignatureFieldSettingsModalProps {
  possibleSignatories: { name: string; surname: string; idPassport: string }[]
  settingsLocked?: boolean
  settings?: SignatureFieldConfig
  allSignatoryTypesDefault?: string[]
  saveAndClose: (arg: SignatureFieldSettingsModalState) => void
}

class SignatureFieldSettingsModal extends React.Component<
  SignatureFieldSettingsModalProps,
  SignatureFieldSettingsModalState
> {
  intialModalState = {
    selectorItemCollectorModalOpen: false,
  }

  state: SignatureFieldSettingsModalState = {
    ...this.intialModalState,
    possibleSignatoryNames: this.props.possibleSignatories.map(
      (signatoryData) => `${signatoryData.name} ${signatoryData.surname}`,
    ),
    authedSignatories: this.props.settings ? this.props.settings.authedSignatories : [],
    compulsory: false,
    pinProtected: !!this.props.settings?.pinProtected,
    signatoryType: '',
    isCompulsory: this.props.settings?.compulsory ? ['YES'] : ['NO'],
    isPinProtected: ['NO'],
    selectedSignatoryType: this.props.settings ? [this.props.settings.signatoryType] : [],
    allSignatoryTypes: this.props.settings?.allSignatoryTypes || this.props.allSignatoryTypesDefault || [],
    activeSetting: '',
  }

  closeModals() {
    this.setState({ ...this.intialModalState })
  }

  textHandler(key: 'authedSignatories' | 'selectedSignatoryType' | 'isCompulsory' | 'isPinProtected', value: string[]) {
    if (key === 'isPinProtected') {
      if (this.props.settingsLocked) {
        this.setState({ selectorItemCollectorModalOpen: true })
      } else if (value.includes('YES')) {
        this.setState({ [key]: value, selectorItemCollectorModalOpen: true })
      } else {
        this.setState({ [key]: value })
      }
    } else {
      this.setState({ [key]: value })
    }
  }

  saveAndClose() {
    const { selectedSignatoryType, isCompulsory, isPinProtected, authedSignatories } = this.state
    const authedSignatoryData: { name: string; surname: string; idPassport: string }[] = []
    authedSignatories.forEach((selectedSignatoryData) => {
      this.props.possibleSignatories.forEach((signatoryData) => {
        if (
          selectedSignatoryData.name === signatoryData.name &&
          selectedSignatoryData.surname === signatoryData.surname
        ) {
          authedSignatoryData.push(signatoryData)
        }
      })
    })

    const updatedSettings: SignatureFieldSettingsModalState = {
      signatoryType: selectedSignatoryType.length ? selectedSignatoryType[0] : '',
      compulsory: isCompulsory.includes('YES'),
      pinProtected: isPinProtected.includes('YES'),
      authedSignatories: authedSignatoryData,
    } as SignatureFieldSettingsModalState
    this.props.saveAndClose(updatedSettings)
  }

  private flattenAuthedSignatories(entries: (string | { name: string; surname: string })[]): string[] {
    return entries.map((x) => {
      if (typeof x === 'string') {
        return x
      }

      return `${x.name} ${x.surname}`
    })
  }

  render() {
    let infoCollectorModal = null
    if (this.state.selectorItemCollectorModalOpen) {
      infoCollectorModal = (
        <InfoCollectorModal
          cardContainerStyle={styles.cardContainer2}
          open={this.state.selectorItemCollectorModalOpen}
          type={'picker'}
          defaultItems={this.flattenAuthedSignatories(this.state.authedSignatories)}
          pickerItems={this.state.possibleSignatoryNames}
          placeholder={'Choose a user type'}
          header={'AUTHORISED SIGNATORIES'}
          subHeader={'Which users can authorise signing?'}
          warningMessage={'Add at least one item'}
          validateInput={() => true}
          transformInput={toUpperCaseCustom}
          successLabel={'Update'}
          minimumItems={1}
          onSuccess={(selections) => this.textHandler('authedSignatories', selections)}
          onReject={() => this.closeModals()}
          dismiss={this.closeModals}
        />
      )
    }

    return (
      <>
        <button style={styles.screenContainer} onClick={() => ({})} />
        <div style={styles.cardContainer}>
          <h1 style={styles.textStyle}>Signature settings</h1>
          <p style={styles.sectionHeader}>Signature capture settings</p>

          <CheckboxTabLabelled
            label={'Who should sign here?'}
            values={this.state.selectedSignatoryType}
            selectorItems={this.state.allSignatoryTypes}
            selectionHandler={(values) => this.textHandler('selectedSignatoryType', values)}
            style={{ width: '70%', marginTop: 40 }}
            itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
            disabled={this.props.settingsLocked}
            maxItemsPerRow={3}
          />
          <CheckboxTabLabelled
            label={'Is signing compulsory?'}
            values={this.state.isCompulsory}
            selectorItems={['YES', 'NO']}
            selectionHandler={(values) => this.textHandler('isCompulsory', values)}
            style={{ width: '70%', marginTop: 40 }}
            itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
            disabled={this.props.settingsLocked}
            maxItemsPerRow={2}
          />
          <CheckboxTabLabelled
            label={'Should signing be pin protected?'}
            values={this.state.isPinProtected}
            selectorItems={['YES', 'NO']}
            selectionHandler={(values) => this.textHandler('isPinProtected', values)}
            style={{ width: '70%', marginTop: 40 }}
            itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
            disabled={false}
            maxItemsPerRow={2}
          />

          <ButtonBlue
            style={{ marginTop: 60, padding: '10px 0px', width: '80%', alignSelf: 'center' }}
            onClick={() => this.saveAndClose()}>
            {'Update and close'}
          </ButtonBlue>
        </div>

        {infoCollectorModal}
      </>
    )
  }
}

let styles = {
  screenContainer: {
    zIndex: 1000,
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    width: window.innerWidth,
    height: window.innerHeight,
    backgroundColor: ColorPalette.MODAL_BACKGROUND_OVERLAY,
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 0,
    border: 'none',
    overflow: 'hidden',
  },
  cardContainer: {
    zIndex: 10000,
    position: 'absolute' as 'absolute',
    height: '100%',
    top: 10,
    left: 10,
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
    backgroundColor: ColorPalette.CARD_WHITE,
    width: 785,
    padding: '20px 0px 40px 0px',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    overflowY: 'scroll' as 'scroll',
  },
  cardContainer2: { left: window.innerWidth * 0.135 },
  textStyle: {
    color: ColorPalette.PRIMARY_TEXT,
    textAlign: 'center' as 'center',
    alignSelf: 'center',
    fontFamily: 'roboto',
    fontWeight: 'bold',
    fontSize: '1.2rem',
    paddingLeft: 10,
    paddingRight: 10,
  },
  sectionHeader: {
    color: ColorPalette.PRIMARY_TEXT,
    borderBottom: `1px solid ${ColorPalette.PRIMARY_BLUE}`,
    textTransform: 'uppercase' as 'uppercase',
    paddingBottom: 5,
    width: '80%',
    textAlign: 'center' as 'center',
    alignSelf: 'center',
    fontFamily: 'roboto',
    fontWeight: '400',
    fontSize: '0.75rem',
    paddingLeft: 10,
    paddingRight: 10,
  },
}

export default Radium(SignatureFieldSettingsModal)
