import { ColorPalette } from '../../config/colors'

import React, { Component } from 'react'
import Radium from 'radium'
import TextInputLabelled from '../BaseComponents/Text/TextInputLabelled'
import ButtonBlue from '../BaseComponents/Buttons/ButtonBlue'
import { Property } from 'csstype'

interface CaptureIdPassportProps {
  open: boolean

  submit: (idPassport: string) => void
  dismiss: () => void
}

interface CaptureIdPassportState {
  open: boolean
  loading: boolean
  mounted: boolean
  idPassport: string
  warning: string
}

class CaptureIdPassport extends Component<CaptureIdPassportProps, CaptureIdPassportState> {
  state: CaptureIdPassportState = {
    open: false,
    mounted: false,
    loading: false,
    idPassport: '',
    warning: '',
  }

  componentDidMount() {
    this.setState({ open: this.props.open, mounted: this.props.open })
  }

  componentDidUpdate(prevProps: CaptureIdPassportProps) {
    if (prevProps.open !== this.props.open) {
      this.setState({ open: this.props.open }, () =>
        setTimeout(
          () => {
            this.setState({ mounted: this.props.open })
          },
          this.props.open ? 0 : 50,
        ),
      )
    }
  }

  submit = async () => {
    const { idPassport } = this.state
    const noIdPassport = !idPassport ? true : false
    if (noIdPassport) {
      this.displayWarning({ code: 'InvalidIdPassport' })
    }

    this.props.submit(this.state.idPassport)
  }

  displayWarning(error: any) {
    let warning = ''
    if (error.code === 'InvalidOtp') {
      warning = 'Your OTP must be 6 digits'
    } else if (error.code === 'InvalidIdPassport') {
      warning = 'Enter your ID / Passport number'
    } else {
      warning = 'We encountered a problem. Refresh the page and try again.'
    }
    this.setState({ loading: false, warning })
  }

  render() {
    styles.screenContainer = {
      ...styles.screenContainer,
      animation: this.state.open ? 'x 0.05s ease-in' : 'x 0.1s ease-out',
      animationName: this.state.open ? fadeInBackground : fadeOutBackground,
    }

    styles.cardContainer = {
      ...styles.cardContainer,
      animation: this.state.open ? 'x 0.05s ease-in' : 'x 0.1s ease-out',
      animationName: this.state.open ? fadeInCard : fadeOutCard,
    }

    let modalContents = null
    if (this.state.mounted) {
      let warning = null
      if (this.state.warning) {
        warning = <p style={styles.warningTextStyle}>{this.state.warning}</p>
      }

      modalContents = (
        <>
          <button style={styles.screenContainer} onClick={() => this.props.dismiss()} />
          <div style={styles.cardContainer}>
            <TextInputLabelled
              containerStyle={{ marginTop: 0 }}
              primaryStyle={{ width: '75%', height: 50 }}
              inputLabelProps={{ color: 'secondary' }}
              label="Enter ID / passport"
              // placeholderColor={ColorPalette.SECONDARY_TEXT}
              value={this.state.idPassport}
              textHandler={(value) => this.setState({ idPassport: value })}
              type="text"
              multiline={false}
              maxLength={40}
              icon="name"
            />

            <ButtonBlue style={{ marginTop: 25, width: '100%' }} onClick={this.submit}>
              {'Next'}
            </ButtonBlue>
            {warning}
          </div>
        </>
      )
    }
    return modalContents
  }
}

const fadeInBackground = Radium.keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
}) as Property.AnimationName

const fadeOutBackground = Radium.keyframes({
  '0%': { opacity: 1 },
  '100%': { opacity: 0 },
}) as Property.AnimationName

const fadeInCard = Radium.keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1, marginRight: window.innerHeight * 0.05 },
}) as Property.AnimationName

const fadeOutCard = Radium.keyframes({
  '0%': { marginRight: window.innerHeight * 0.05 },
  '50%': { opacity: 0 },
  '100%': { marginRight: -window.innerHeight * 0.13, opacity: 0 },
}) as Property.AnimationName

let styles: Record<string, React.CSSProperties> = {
  screenContainer: {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    width: window.innerWidth,
    height: window.innerHeight,
    backgroundColor: ColorPalette.MODAL_BACKGROUND_OVERLAY,
    zIndex: 10000,
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 0,
    border: 'none',
    overflow: 'hidden',
  },
  cardContainer: {
    zIndex: 10000,
    position: 'absolute',
    top: window.innerHeight * 0.08,
    left: window.innerWidth * 0.4,
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
    backgroundColor: ColorPalette.CARD_WHITE,
    marginTop: window.innerHeight * 0.03,
    marginRight: window.innerHeight * 0.05,
    width: window.innerWidth * 0.22,
    padding: 30,
    paddingBottom: 30,
    borderRadius: 8,
  },
  textStyle: {
    fontSize: '0.8rem',
    fontWeight: '300',
    marginTop: -10,
    color: ColorPalette.PRIMARY_TEXT,
  },
  warningTextStyle: {
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: '0.9rem',
    fontWeight: '300',
    marginTop: 30,
    color: ColorPalette.WARNING_RED,
  },
}

export default Radium(CaptureIdPassport)
