import React, { Component } from 'react'
import Radium from 'radium'
import { connect } from 'react-redux'

import { GridSize } from '@material-ui/core/Grid'
import { RouteComponentProps } from 'react-router'
import { get, isEmpty } from 'lodash'
import Icon from '@mdi/react'
import { mdiArrowRightBold, mdiFile, mdiPlaylistPlus, mdiFilePlus } from '@mdi/js'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import { ColorPalette } from '../../config/colors'
import { ActionType } from '../../store/actions/actions'
import { docCategories, docsSectionConfig, AllScreenNames } from '../../config'
import { DocServiceFactory, DocsService } from '../../services/docService'
import NavigationBar from '../../components/Navigation/NavigationBar'
import SectionHeader from '../../components/Headings/SectionHeaderPrimary'
import LoadingModal from '../../components/Modals/LoadingModal'
import AlertModalOneButton from '../../components/Modals/AlertModalOneButton'
import { DocViewerConfig } from '../../components/Tables/DocumentTable/DocumentTable'
import DataTable from '../../components/Tables/DataTable/DataTable'
import { PeopleFlowCombinedReducer } from '../../store'
import { Profile, ProfilePk } from '../../models'
import { SessionService } from '../../services'
import DownloadQueueModal from '../../components/Modals/DownloadQueueModal'
import { toUpperCaseCustom } from '../../utils'
import { Toolbar } from '../../components/GeneralUI/Toolbar'
import { DocSelection } from '../../components/DocExport/DocSelection'
import { DateRangeSelections, DateRangesModal } from '../../components/Modals/DateRanges/DateRangesModal'
import { AssociationSettingsRepository, ProfileRepository, UserRepository } from '../../repositories'
import { AsyncTaskDocExport } from '../../types'
import { ISectionConfigItem } from '../../interfaces'
import { fetchProfileData } from '../../provider/remoteData'

dayjs.extend(relativeTime)

type DocsByProfilePk = Record<string, string[]>
export type DocDownloadQueueItem = {
  queueId: string
  status: string
  label: string
  fileLocation: { key: string }
}

interface DocExportProps extends RouteComponentProps {
  associationRepo: AssociationSettingsRepository
  userRepo: UserRepository
  currentScreen: AllScreenNames
  currentSection: AllScreenNames
  selectedAssociation: string
  idPassport: string
  password: string
  selectedCohort: string
  profileRepo: ProfileRepository
  searchString: string[]
  asyncTaskQueueDocExport: AsyncTaskDocExport[]
  itemsBeingGenerated: DocDownloadQueueItem[]

  updateState: (data: any) => void
}
interface DocExportState {
  loadingModalOpen: boolean
  warningModalOpen: boolean
  docDateRangesModalOpen: boolean
  downloadQueueModalOpen: boolean
  isUpdatingDocExportQueue: boolean
  loadingModalMessage: string
  warningModalHeader: string
  warningModalMessage: string
  tableWidth: GridSize
  sectionConfig: ISectionConfigItem[]
  selectedCategoryName: string
  docTableConfig: DocViewerConfig
  step: string
  selectAllEnabled: boolean
  selectedFolders: string[]
  profileData: any[]
  downloading: boolean
  processed: string[]
  authorisedPks: string[]
  searchString: string
  customProfileFilterConfig: { key: string; label: string }[]
  selectedDateRange: Record<string, any>
  dateSelectionRangeExceeded: boolean
  dateSelectAdditionalInfo: React.ReactNode
  itemsDownloading: string[]
}

class DocExports extends Component<DocExportProps, DocExportState> {
  dataTableRef: React.RefObject<DataTable>

  constructor(props: DocExportProps) {
    super(props)
    this.dataTableRef = React.createRef()
  }

  docsService = DocServiceFactory.create()

  initialModalState = {
    loadingModalOpen: false,
    warningModalOpen: false,
    docDateRangesModalOpen: false,
    downloadQueueModalOpen: false,
  }

  state: DocExportState = {
    ...this.initialModalState,
    loadingModalMessage: '',
    warningModalHeader: '',
    warningModalMessage: '',
    tableWidth: 10,
    sectionConfig: [],
    selectedCategoryName: 'TIME_SENSITIVE_DOCS',
    docTableConfig: {},
    processed: [],
    profileData: [],
    step: 'docSelection',
    searchString: '',
    isUpdatingDocExportQueue: false,
    selectAllEnabled: false,
    downloading: false,
    selectedFolders: [],
    authorisedPks: [],
    customProfileFilterConfig: [],
    selectedDateRange: {},
    dateSelectionRangeExceeded: false,
    dateSelectAdditionalInfo: null,
    itemsDownloading: [],
  }

  componentDidMount() {
    this.props.updateState({
      currentSection: AllScreenNames.TRANSFER,
      currentScreen: AllScreenNames.EXPORT_DOCS,
    })
    const { associationRepo, selectedAssociation } = this.props
    const customProfileFilterConfig = associationRepo.getProfileFilterConfig(selectedAssociation)
    const sectionConfig = this.generateSectionConfig()
    const selectedCategoryName = 'TIME_SENSITIVE_DOCS'
    this.setState({ selectedCategoryName, sectionConfig, customProfileFilterConfig }, () => {
      this.initialiseDocumentTable()
      this.updateQueueData(false)
    })
    this.clearItemsDownloading()
  }

  clearItemsDownloading() {
    this.setState({ itemsDownloading: [] })
  }

  closeModals() {
    this.setState({ ...this.initialModalState }, this.forceUpdate)
  }

  generateSectionConfig = (): ISectionConfigItem[] => {
    let sectionConfig = [
      { sectionLabel: 'TIME SENSITIVE DOCS', sectionName: 'TIME_SENSITIVE_DOCS' },
      ...docsSectionConfig,
    ]
    return sectionConfig
  }

  initialiseDocumentTable(): void {
    function generateDocTableConfig(
      selectedAssociation: string,
      associationRepo: AssociationSettingsRepository,
      docsService: DocsService,
    ): DocViewerConfig {
      let docTableConfig: DocViewerConfig = { TIME_SENSITIVE_DOCS: {} }
      const docConfig = associationRepo.getDocConfig(selectedAssociation)
      const allCompetencies = associationRepo.getAllPossibleCompetencies(selectedAssociation)
      allCompetencies.forEach((competency: string) => (docTableConfig.TIME_SENSITIVE_DOCS[competency] = []))

      let docNameCategoryMap: Record<string, string> = {}
      Object.values(docCategories).forEach((docCategory: any) => {
        docTableConfig[docCategory] = {}
        const categoryDocNames = docsService.extractDocGroup(docConfig, docCategory)
        categoryDocNames.forEach((categoryDocName: string) => (docNameCategoryMap[categoryDocName] = docCategory))
      })
      Object.keys(docNameCategoryMap).forEach((docName) => (docTableConfig[docNameCategoryMap[docName]][docName] = []))
      return docTableConfig
    }

    const { selectedAssociation, associationRepo } = this.props
    let docTableConfig = generateDocTableConfig(selectedAssociation, associationRepo, this.docsService)
    this.setState({ docTableConfig })
  }

  async docToolbarHandler(buttonId: string): Promise<void> {
    try {
      let { selectAllEnabled, selectedFolders, docTableConfig } = this.state

      switch (buttonId) {
        case 'selectAll':
          if (selectAllEnabled) {
            selectedFolders = []
          } else {
            Object.keys(docTableConfig).forEach(
              (category) => (selectedFolders = [...selectedFolders, ...Object.keys(docTableConfig[category])]),
            )
          }
          this.setState({
            selectAllEnabled: !selectAllEnabled,
            selectedFolders,
          })
          break
        case 'next':
          if (!selectedFolders.length) {
            throw { code: 'NoFolders' }
          }
          this.setState({ step: 'profileSelection', docDateRangesModalOpen: true })
          break
      }
    } catch (error) {
      this.displayWarning(error)
    }
  }

  async loadProfileData(initialLoad?: boolean) {
    this.setState({ loadingModalOpen: true, loadingModalMessage: 'Loading profile data...' })
    try {
      let { selectedAssociation, selectedCohort, profileRepo, associationRepo } = this.props
      const profiles = initialLoad ? undefined : profileRepo.getAllProfiles()
      const profileData = this.prepareProfileDataForDisplay(profiles)
      this.setState({ profileData, downloading: false }, () => {
        if (!this.dataTableRef?.current) {
          return
        }
        this.dataTableRef.current.reload()
      })
      let missingIdPassports: string[] = []
      const downloadedIdPassports = profiles === undefined ? [] : Object.keys(profiles)
      const authorisedPks = associationRepo.getAuthorisedPks(selectedAssociation, selectedCohort)
      for (const authedIdPassport of authorisedPks || []) {
        if (!downloadedIdPassports.includes(authedIdPassport) && !this.state.processed.includes(authedIdPassport)) {
          missingIdPassports.push(authedIdPassport)
        }
      }
      if (missingIdPassports.length) {
        this.setState({ downloading: true })
        await this.updateProfileData(/*allProfiles,*/ selectedAssociation, missingIdPassports)
      } else {
        this.setState({ loadingModalOpen: false, loadingModalMessage: '' })
      }
    } catch (error) {
      this.setState({ downloading: false, loadingModalOpen: false, loadingModalMessage: '' })
    }
  }

  async updateProfileData(
    // profiles: Record<ProfilePk, Profile>,
    selectedAssociation: string,
    idPassportsToFetch: string[],
  ) {
    const { idPassport, password, profileRepo, updateState } = this.props
    try {
      idPassportsToFetch = idPassportsToFetch.slice(0, 50)
      const token = await SessionService.prepareAuthTokens(idPassport, password)
      const fetchedProfiles = await fetchProfileData(selectedAssociation, idPassportsToFetch, token)
      await profileRepo.updateProfiles(fetchedProfiles)
      const allEmployerProfiles = profileRepo.getAllProfiles()
      const profileData = this.prepareProfileDataForDisplay(allEmployerProfiles)
      this.setState(
        (prevState) => ({
          profileData,
          processed: [...prevState.processed, ...Object.values(idPassportsToFetch)],
        }),
        this.loadProfileData,
      )
    } catch (error) {
      console.error('updateProfileData() error: ', error)
    }
  }

  prepareProfileDataForDisplay(allProfiles: Record<ProfilePk, Profile> | undefined): Record<string, any>[] {
    if (allProfiles === undefined) {
      return []
    }
    const { selectedAssociation } = this.props
    const { selectedDateRange, selectedFolders, customProfileFilterConfig } = this.state
    let profileData: Record<string, any>[] = []
    const profiles = Object.values(allProfiles)
    profiles.forEach((profile: Profile) => {
      const generalData = profile.getGeneralData() ? profile.getGeneralData() : { employmentStatus: 'CANDIDATE' }
      const startTimestamp = selectedDateRange.capturedDateRange.startDate.getTime()
      const endTimestamp = selectedDateRange.capturedDateRange.endDate.getTime()
      let fileNames = profile.getDocFileNameStrings(selectedAssociation, startTimestamp, endTimestamp)
      let numberOfDocs = fileNames.length
      if (numberOfDocs === 0) {
        return
      }
      fileNames = fileNames.filter((docName: string) => this.containsFolderName(selectedFolders, docName))
      numberOfDocs = fileNames.length
      if (numberOfDocs === 0) {
        return
      }
      const personalInfo = profile.getPersonalInfo()
      let { name, surname, idPassport } = personalInfo
      let returnData: Record<string, any> = {
        id: profile.getPk(),
        name,
        surname,
        idPassport,
        employmentStatus: generalData.employmentStatus,
        numberOfDocs,
        docs: fileNames,
      }
      customProfileFilterConfig.forEach(({ key, label }) => (returnData[key] = get(generalData, key, '')))
      profileData.push(returnData)
    })
    return profileData
  }

  generateDocTableConfig(): DocViewerConfig {
    let docTableConfig: DocViewerConfig = { TIME_SENSITIVE_DOCS: {} }
    const { selectedAssociation, associationRepo } = this.props
    const docConfig = associationRepo.getDocConfig(selectedAssociation)
    const allCompetencies = associationRepo.getAllPossibleCompetencies(selectedAssociation)
    allCompetencies.forEach((competency) => (docTableConfig.TIME_SENSITIVE_DOCS[competency] = []))

    let docNameCategoryMap: Record<string, string> = {}
    Object.values(docCategories).forEach((docCategory: any) => {
      docTableConfig[docCategory] = {}
      const categoryDocNames = this.docsService.extractDocGroup(docConfig, docCategory)
      categoryDocNames.forEach((categoryDocName: string) => (docNameCategoryMap[categoryDocName] = docCategory))
    })
    Object.keys(docNameCategoryMap).forEach((docName) => (docTableConfig[docNameCategoryMap[docName]][docName] = []))
    return docTableConfig
  }

  loadSection(selectedCategoryName: string) {
    this.setState({ selectedCategoryName } as DocExportState)
  }

  folderSelectionHandler(selectedFolder: string) {
    let { selectedFolders = [] } = this.state
    if (selectedFolders.includes(selectedFolder)) {
      selectedFolders = selectedFolders.filter((folder) => folder !== selectedFolder)
    } else {
      selectedFolders.push(selectedFolder)
    }
    this.setState({ selectedFolders })
  }

  onSelectAllDocs(selectAllState: boolean) {
    let selectedFolders = [] as string[]
    if (selectAllState) {
      Object.keys(this.state.docTableConfig).forEach((docCategory) => {
        Object.keys(this.state.docTableConfig[docCategory]).forEach((folder) => selectedFolders.push(folder))
      })
    }
    this.setState({ selectedFolders })
  }

  searchHandler(event: React.ChangeEvent<{ value: string }>) {
    this.setState({ searchString: event.target.value })
    if (!this.dataTableRef?.current) {
      return
    }
    this.dataTableRef.current.search(event.target.value)
  }

  containsFolderName(selectedFolders: string[], fileName: string) {
    fileName = toUpperCaseCustom(fileName)
    const found = selectedFolders.find((folder) => {
      return fileName.includes(toUpperCaseCustom(`___${folder}`))
    })
    return !!found
  }

  generateDocs = async () => {
    try {
      function generateDocBatches(allTargetDocs: DocsByProfilePk) {
        const BATCH_SIZE = 50
        let counter = 0
        let batchCounter = 0
        const docBatches = [] as DocsByProfilePk[]
        docBatches[batchCounter] = {}
        Object.keys(allTargetDocs).forEach((profilePk) => {
          docBatches[batchCounter][profilePk] = allTargetDocs[profilePk]
          counter += allTargetDocs[profilePk].length
          if (counter > BATCH_SIZE) {
            batchCounter++
            docBatches[batchCounter] = {}
            counter = 0
          }
        })
        return docBatches
      }

      async function createAsyncTasks(
        docBatches: DocsByProfilePk[],
        docsService: DocsService,
        userRepo: UserRepository,
        token: string,
      ) {
        docBatches = [...docBatches].filter((docBatch) => Object.keys(docBatch).length)
        let asyncTasks = [] as AsyncTaskDocExport[]
        const user = userRepo.getCurrentUserEntity()
        const { name, surname } = user.getPersonalUserInfo()
        for (let i = 0; i < docBatches.length; i++) {
          const docBatch = docBatches[i]
          const asyncTask = await docsService.triggerDocBatchGeneration(
            docBatch,
            selectedAssociation,
            docsService.getDownloadBatchLabel(docBatches.length - i, name, surname),
            token,
          )
          asyncTasks.unshift(asyncTask)
        }

        return asyncTasks
      }

      this.setState({
        ...this.initialModalState,
        loadingModalOpen: true,
        loadingModalMessage: 'Triggering document generation...',
      })
      const { idPassport, password, selectedAssociation, userRepo, itemsBeingGenerated } = this.props
      const selectedProfileIdIndex = this.dataTableRef?.current?.getSelectedRows()

      let targetDocs = {} as DocsByProfilePk
      const { profileData } = this.state
      profileData.forEach((renderItem) => {
        if (!selectedProfileIdIndex[renderItem.id]) {
          return
        }
        targetDocs[renderItem.id] = renderItem.docs
      })

      const docBatches = generateDocBatches(targetDocs)
      const token = await SessionService.prepareAuthTokens(idPassport, password)
      const asyncTasks = await createAsyncTasks(docBatches, this.docsService, userRepo, token)
      if (!asyncTasks.length) {
        throw { code: 'NoDocs' }
      }
      this.props.updateState({
        itemsBeingGenerated: [...itemsBeingGenerated, ...DocsService.asyncTasksToDownloadQueueItems(asyncTasks)],
      })
      this.updateQueueData(true)
    } catch (error) {
      console.error('error: ', error)
      this.displayWarning(error)
    }
  }

  async downloadFile(filePath: string, queueId: string): Promise<any> {
    try {
      const { idPassport, password, selectedAssociation } = this.props
      const itemsDownloading = this.state.itemsDownloading || []
      if (itemsDownloading.length && !itemsDownloading.includes(queueId)) {
        throw { code: 'DownloadInProgress' }
      }
      let newItemsDownloading = [...new Set([...itemsDownloading, queueId])]
      this.setState({ itemsDownloading: newItemsDownloading })
      if (filePath.includes('public')) {
        filePath = filePath.replace('public/', '')
      }
      await this.docsService.downloadDocBatch(filePath)
      newItemsDownloading = newItemsDownloading.filter((existingQueueId) => existingQueueId !== queueId)
      this.setState({ itemsDownloading: newItemsDownloading })
      const token = await SessionService.prepareAuthTokens(idPassport, password)
      const asyncTaskQueueDocExport = await this.docsService.clearDownloadQueue(selectedAssociation, queueId, token)
      this.props.updateState({ asyncTaskQueueDocExport })
      this.forceUpdate()
    } catch (error) {
      this.displayWarning(error)
    }
  }

  async updateQueueData(showModal: boolean) {
    function filterItemsBeingGenerated(
      asyncTaskQueueDocExport: AsyncTaskDocExport[] = [],
      itemsBeingGenerated: DocDownloadQueueItem[] = [],
    ) {
      const queueIds = [...asyncTaskQueueDocExport].map((item) => item.uid)
      itemsBeingGenerated = itemsBeingGenerated.filter(({ queueId }) => !queueIds.includes(queueId))
      return itemsBeingGenerated
    }
    if (showModal) {
      this.setState({ ...this.initialModalState, downloadQueueModalOpen: true, isUpdatingDocExportQueue: true })
    }
    let { idPassport, password, selectedAssociation, itemsBeingGenerated } = this.props
    const token = await SessionService.prepareAuthTokens(idPassport, password)
    const asyncTaskQueueDocExport = await this.docsService.fetchDocDownloadQueue(selectedAssociation, token)
    itemsBeingGenerated = filterItemsBeingGenerated(asyncTaskQueueDocExport, itemsBeingGenerated)
    this.props.updateState({ asyncTaskQueueDocExport, itemsBeingGenerated })
    this.setState({ isUpdatingDocExportQueue: false })
  }

  generateToolbarConfig = () => {
    const { step } = this.state
    if (step === 'docSelection') {
      return [
        {
          id: 'selectAll',
          label: 'Select',
          iconComponent: (
            <div style={{ marginRight: 15, marginTop: 1 }}>
              <Icon size={0.8} path={mdiFile} color={ColorPalette.CARD_WHITE} />
            </div>
          ),
        },
        {
          id: 'next',
          label: 'Next',
          iconComponent: (
            <div style={{ marginRight: 15, marginTop: 1 }}>
              <Icon size={1} path={mdiArrowRightBold} color={ColorPalette.CARD_WHITE} />
            </div>
          ),
        },
      ]
    } else {
      return [
        { id: 'idPassport', label: 'ID / Passport', sizeFactor: 1, iconComponent: <div /> },
        { id: 'name', label: 'Name', sizeFactor: 1, iconComponent: <div /> },
        { id: 'surname', label: 'Surname', sizeFactor: 1, iconComponent: <div /> },
        { id: 'employmentStatus', label: 'Status', sizeFactor: 1, iconComponent: <div /> },
        { id: 'numberOfDocs', label: 'Docs in filter range', sizeFactor: 1, iconComponent: <div /> },
      ]
    }
  }

  handleDateRangeSave = (dateRangeSelections: DateRangeSelections) => {
    if (this.state.dateSelectionRangeExceeded) {
      this.displayWarning({ code: 'DateRangeExceed' })
      return
    }
    const selectedDateRange = {
      capturedDateRange: {
        startDate: dateRangeSelections['CAPTURED ON'].startDate,
        endDate: dateRangeSelections['CAPTURED ON'].endDate,
      },
    }
    this.setState({ ...this.initialModalState, selectedDateRange }, () => this.loadProfileData(true))
  }

  handleDateRangeCancel = () => this.setState({ ...this.initialModalState, step: 'docSelection' })

  handleInvalidDateRangeSave = () => {
    this.setState({ dateSelectionRangeExceeded: true, step: 'docSelection' }, () =>
      this.displayWarning({ code: 'DateRangeInvalid' }),
    )
  }

  handleWarningModalOnClick = () => {
    if (this.state.dateSelectionRangeExceeded) {
      this.setState({ warningModalOpen: false })
      return
    }
    this.closeModals()
  }

  displayWarning(error: any) {
    let header = 'Warning'
    let warning = ''

    try {
      if (error.code === 'NetworkError') {
        warning = 'Seems like your internet connection is down. Reconnect to the network, then try again.'
      } else if (error.code === 'NoDocumentSelected') {
        header = 'Select a document'
        warning = 'Select a document'
      } else if (error.code === 'NoFolders') {
        header = 'None selected'
        warning = 'Select at least one type of document to continue.'
      } else if (error.code === 'NoDocs') {
        header = 'No docs'
        warning = 'There are no documents that meet your filter criteria'
      } else if (error.code === 'DateRangeInvalid') {
        header = 'Date selection range invalid'
        warning = 'Ensure date selections are valid and inside maximum date range'
      } else if (error.code === 'DateRangeExceed') {
        header = 'Date range exceeded'
        warning = 'The maximum date range is 1 month'
      } else if (error.code === 'DownloadInProgress') {
        header = 'Donwload in progress'
        warning = 'There is already a download in progress. Wait for it to finish then try again.'
      } else if ('message' in error) {
        warning =
          "The following error message was returned when logging in:\n\n'" +
          error.message +
          "'. \n\nRefresh the page and try again. If unsuccessful, then contact tech support"
      } else {
        warning = 'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
      }
    } catch (error) {
      warning = 'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
    }
    this.setState({
      ...this.initialModalState,
      warningModalOpen: true,
      warningModalHeader: header,
      warningModalMessage: warning,
    })
  }

  render() {
    const columnConfig = this.generateToolbarConfig()
    const {
      authorisedPks,
      customProfileFilterConfig,
      docTableConfig,
      docDateRangesModalOpen,
      downloadQueueModalOpen,
      isUpdatingDocExportQueue,
      profileData,
      sectionConfig,
      selectedCategoryName,
      selectedDateRange,
      selectedFolders,
      step,
      tableWidth,
    } = this.state

    let table = null
    if (step === 'docSelection') {
      table = (
        <DocSelection
          columnConfig={columnConfig}
          docTableConfig={docTableConfig}
          sectionConfig={sectionConfig}
          selectedCategoryName={selectedCategoryName}
          selectedFolders={selectedFolders}
          onSectionClick={(section: string) => this.loadSection(section)}
          onFolderSelection={(folder: string) => this.folderSelectionHandler(folder)}
          onSelectAllDocs={(selectAllState: boolean) => this.onSelectAllDocs(selectAllState)}
          onToolbarClicked={(buttonId: string) => this.docToolbarHandler(buttonId)}
        />
      )
    }
    if (step === 'profileSelection') {
      let keyTail = ''
      if (!isEmpty(selectedDateRange)) {
        keyTail = `${selectedDateRange.capturedDateRange.startDate.getTime()}-${selectedDateRange.capturedDateRange.endDate.getTime()}`
      }
      const key = `profileSelectionTable_${selectedCategoryName}_${keyTail}`
      table = (
        <DataTable
          ref={this.dataTableRef}
          tableData={profileData}
          columnConfig={columnConfig}
          tableWidth={tableWidth}
          onRowClick={(rowID) => ({})}
          selectedRowItemId={''}
          authorisedItemIds={authorisedPks}
          isSelectionEnabled={true}
          customFilterConfig={customProfileFilterConfig}
          disabled={true}
          key={key}
        />
      )
    }

    let tableNavButtons = [
      {
        label: 'VIEW QUEUE',
        iconPath: mdiPlaylistPlus,
        onClick: () => this.updateQueueData(true),
      },
    ]
    if (step === 'profileSelection') {
      tableNavButtons.push({
        label: 'GENERATE',
        iconPath: mdiFilePlus,
        onClick: () => this.generateDocs(),
      })
    }

    let docDateRangesModal = null
    if (docDateRangesModalOpen) {
      const MS_THIRTY_DAYS = 2592000000
      const config = [
        {
          label: 'CAPTURED ON',
          defaultStartDateMs: new Date().getTime() - MS_THIRTY_DAYS,
          maxRangeExceededMessage: 'The maximum selection date range is 1 month',
        },
      ]

      docDateRangesModal = (
        <DateRangesModal
          title="DATE FILTER"
          dateRangeConfig={config}
          open={true}
          onCancelClick={this.handleDateRangeCancel}
          onSaveClick={this.handleDateRangeSave}
          onInvalidDateRangeSave={this.handleInvalidDateRangeSave}
        />
      )
    }

    let downloadQueueModal = null
    if (downloadQueueModalOpen) {
      const itemsDownloading = this.state.itemsDownloading || []
      downloadQueueModal = (
        <DownloadQueueModal
          open={this.state.downloadQueueModalOpen}
          queuedTasks={this.props.asyncTaskQueueDocExport || []}
          itemsDownloading={itemsDownloading || []}
          itemsBeingGenerated={this.props.itemsBeingGenerated || []}
          header={'Download Queue'}
          body={''}
          isUpdatingDocExportQueue={isUpdatingDocExportQueue}
          updateQueueData={() => this.updateQueueData(true)}
          download={(filePath: string, queueId: string) => this.downloadFile(filePath, queueId)}
          dismiss={() => this.closeModals()}
        />
      )
    }

    return (
      <div style={styles.container}>
        <NavigationBar match={this.props.match} location={this.props.location} history={this.props.history} />

        <SectionHeader
          style={styles.sectionHeader}
          disabled={step === 'docSelection'}
          downloading={this.state.downloading}
          searchString={this.state.searchString}
          textHandler={(e) => this.searchHandler(e)}
          onClick={() => ({})}>
          {this.props.currentScreen}
        </SectionHeader>

        <div style={styles.contentContainer}>
          <div style={{ ...styles.rightSide, paddingInline: 'min(5em, 5%)' }}>
            <Toolbar
              actionButtons={tableNavButtons}
              navButtons={{
                left:
                  step === 'profileSelection' ? { onClick: () => this.setState({ step: 'docSelection' }) } : undefined,
              }}
            />
            <div
              style={
                step === 'docSelection'
                  ? { ...styles.rightSideContent, flexDirection: 'column' }
                  : styles.rightSideContent
              }>
              {table}
            </div>
          </div>
        </div>

        {docDateRangesModal}
        {downloadQueueModal}
        <AlertModalOneButton
          open={this.state.warningModalOpen}
          header={this.state.warningModalHeader}
          body={this.state.warningModalMessage}
          buttonLabel={'Ok'}
          onClick={this.handleWarningModalOnClick}
        />
        <LoadingModal open={this.state.loadingModalOpen}>{this.state.loadingModalMessage}</LoadingModal>
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column' as 'column',
    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255, 1), rgba(209,210,230, 1))',
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto 1.5%',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    paddingInline: 'max(2em, 2%)',
    overflow: 'hidden',
  },
  rightSideContent: {
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    display: 'flex',
    flex: 1,
    backgroundColor: ColorPalette.CARD_WHITE,
  },
  buttonIconStyle: {
    color: ColorPalette.PRIMARY_TEXT,
    width: '1.2rem',
    height: '1.2rem',
  },
  toolbarButton: {
    marginTop: window.innerHeight * 0,
    fontWeight: 'bolder' as 'bolder',
    fontSize: '0.8rem',
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    ':hover': {
      color: ColorPalette.PRIMARY_BLUE,
    },
    ':active': {
      color: ColorPalette.DARK_GREY,
    },
  },
  tableNavButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    width: (window.innerWidth * 10) / 12,
  },
  downloadButtonContainer: {
    marginRight: 10,
  },
  additionalInfo: {},
}

const mapStateToProps = (state: PeopleFlowCombinedReducer) => {
  return {
    idPassport: state.sessionManager.idPassport,
    password: state.sessionManager.password,
    associationRepo: state.sessionManager.associationRepo as AssociationSettingsRepository,
    userRepo: state.sessionManager.userRepo as UserRepository,
    profileRepo: state.sessionManager.profileRepo as ProfileRepository,
    selectedCohort: state.sessionManager.selectedCohort,
    selectedAssociation: state.sessionManager.selectedAssociation,
    currentSection: state.sessionManager.currentSection,
    currentScreen: state.sessionManager.currentScreen,
    profile: state.sessionManager.profile,
    asyncTaskQueueDocExport: state.sessionManager.asyncTaskQueueDocExport,
    itemsBeingGenerated: state.sessionManager.itemsBeingGenerated,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateState: (data: any) => dispatch({ type: ActionType.UPDATE_STATE, data }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Radium(DocExports))
