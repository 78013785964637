import React from 'react'
import Radium from 'radium'
import { ColorPalette } from '../../../config/colors'
import Icon from '@mdi/react'
import { mdiFilePdfBox, mdiMicrosoftExcel, mdiFileDelimited, mdiFileImage, mdiFile } from '@mdi/js'

const fileTypeSymbolMap: Record<string, any> = {
  'application/pdf': mdiFilePdfBox,
  'text/csv': mdiFileDelimited,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': mdiMicrosoftExcel,
  'application/vnd.ms-excel.sheet.macroenabled.12': mdiMicrosoftExcel,
  'image/jpeg': mdiFileImage,
  'image/png': mdiFileImage,
}

interface IFileTypeButtonProps {
  active?: boolean
  selected?: boolean
  fileType: string
  fileName: string
  disabled?: boolean
  style?: React.CSSProperties | undefined
  onClick: () => void
}

const fileTypeButton: React.FC<IFileTypeButtonProps> = (props: IFileTypeButtonProps) => {
  return (
    <div style={{ ...styles.container, ...props.style }}>
      <button
        style={{
          ...styles.button,
          border: props.selected ? `2px solid ${ColorPalette.PRIMARY_BLUE}` : 'none',
          filter: props.active ? 'brightness(95%)' : 'brightness(100%)',
        }}
        onClick={props.onClick}
        disabled={props.disabled}>
        <Icon
          path={props.fileType in fileTypeSymbolMap ? fileTypeSymbolMap[props.fileType] : mdiFile}
          color={ColorPalette.PRIMARY_BLUE}
          style={{ width: 24, height: 'auto' }}
        />
      </button>
      <p style={{ ...styles.fileNameText }}>{props.fileName}</p>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    width: 65,
    borderRadius: 10,
  },
  button: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 60,
    height: 60,
    borderRadius: 10,
    backgroundImage: `linear-gradient(to top, ${ColorPalette.OFF_WHITE}, ${ColorPalette.CARD_WHITE})`,
    border: 'none',
    outline: 0,
    boxShadow: '0px 2px 4px rgba(0, 0, 30, 0.15)',
    ':hover': {
      filter: 'brightness(95%)',
    },
    ':active': {
      filter: 'brightness(95%)',
    },
  },
  fileNameText: {
    margin: 0,
    marginTop: 10,
    fontSize: '0.7rem',
    textTransform: 'uppercase' as 'uppercase',
    textAlign: 'center' as 'center',
    color: ColorPalette.PRIMARY_TEXT,
    width: 110,
    whiteSpace: 'nowrap' as 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}

export default Radium(fileTypeButton)
