import { ColorPalette } from '../../config/colors'

import React, { Component } from 'react'
import { withStyles, createStyles } from '@material-ui/core/styles'
import Radium from 'radium'
import Grid from '@material-ui/core/Grid'

import PickerContained from '../BaseComponents/Pickers/PickerContained'
import ButtonBlue from '../BaseComponents/Buttons/ButtonBlue'
import { ScrollView } from '@cantonjs/react-scroll-view'
import { PickerHandlerEvent } from '../BaseComponents/Pickers/Picker'

interface MultiListViewerModalProps {
  open: boolean
  title?: string
  listData: Record<string, string[]>
  onClick: () => void
}

interface ProfileTimestampModalState {
  selectedListName: string
  listNames: string[]
  listComponents: JSX.Element[]
}

class MultiListViewerModal extends Component<MultiListViewerModalProps, ProfileTimestampModalState> {
  state: ProfileTimestampModalState = {
    selectedListName: '',
    listNames: [],
    listComponents: [],
  }

  componentDidMount() {
    const { listData = {} } = this.props
    const listNames = this.getListNamesWithUnsyncedItems(listData)
    const selectedListName = listNames[0]
    this.setState({
      selectedListName,
      listNames,
      listComponents: this.generateListItems(selectedListName, listData),
    })
  }

  getListNamesWithUnsyncedItems = (listData: Record<string, string[]>) => {
    let listNames: string[] = []
    Object.entries(listData).forEach(([key, value]) => {
      if (value.length > 0) {
        listNames.push(key)
      }
    })
    return listNames
  }

  onChange(event: PickerHandlerEvent) {
    const { listData } = this.props
    const listNames = this.getListNamesWithUnsyncedItems(listData)
    const selectedListName = event.target.value
    this.setState({
      selectedListName,
      listNames,
      listComponents: this.generateListItems(selectedListName, listData),
    })
  }

  generateListItems(listName: string, listData: Record<string, string[]>): JSX.Element[] {
    return listData[listName].map((listLabel) => <p style={{ ...getStyle('text'), fontWeight: 'bold' }}>{listLabel}</p>)
  }

  render() {
    let modalContents = null
    const { selectedListName, listComponents, listNames } = this.state
    const { open } = this.props
    if (open) {
      modalContents = (
        <div style={getStyle('container')}>
          <div style={getStyle('cardContainer')}>
            <Grid
              item
              container
              direction="column"
              justify="center"
              alignItems="flex-start"
              xs={12}
              sm={6}
              md={4}
              lg={6}>
              <div style={getStyle('cardStyle')}>
                {this.props.title && <h1 style={getStyle('title')}>{this.props.title}</h1>}
                <PickerContained
                  style={{ marginTop: 15 }}
                  items={listNames || []}
                  value={selectedListName}
                  onChange={(e: PickerHandlerEvent) => this.onChange(e)}
                  key={`listNamesPicker_${JSON.stringify(listNames)}`}
                />

                <div
                  style={{
                    width: '92%',
                    borderBottom: `1px solid ${ColorPalette.PRIMARY_BLUE}`,
                    marginTop: 25,
                  }}
                />

                <ScrollView style={getStyle('scrollContainer')} contentContainerStyle={{ paddingBottom: 100 }}>
                  {listComponents.length > 0 ? listComponents : 'No unsynced items'}
                </ScrollView>

                <Grid style={getStyle('buttonContainer')} item container lg={12}>
                  <ButtonBlue style={getStyle('labelledButton')} onClick={() => this.props.onClick()}>
                    Close
                  </ButtonBlue>
                </Grid>
              </div>
            </Grid>
          </div>
        </div>
      )
    }
    return modalContents
  }
}

const styles = createStyles({
  container: {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    zIndex: 200,
    paddingTop: 120,
    backgroundColor: ColorPalette.MODAL_BACKGROUND_OVERLAY,
    height: window.innerHeight,
    width: window.innerWidth,
    overflow: 'hidden',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    overflow: 'hidden',
  },
  cardStyle: {
    width: '75%',
    backgroundColor: ColorPalette.CARD_WHITE,
    borderRadius: 8,
    boxShadow: '0rem 0.5rem 0.8rem rgba(0, 0, 0, 0.25)',
    marginBottom: 30,
    paddingBottom: 30,
    paddingLeft: '6%',
    paddingRight: '6%',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    alignSelf: 'center',
  },
  buttonContainer: {
    marginTop: 40,
    display: 'flex',
    justifyContent: 'space-between',
  },
  text: {
    marginTop: -3,
    color: ColorPalette.PRIMARY_TEXT,
    fontSize: '0.9rem',
    fontFamily: 'roboto',
  },
  scrollContainer: {
    marginTop: 25,
    width: '86%',
    height: window.innerHeight * 0.28,
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'flex-start',
  },
  labelledButton: { marginHorizontal: 5, width: '100%' },
  title: {
    color: ColorPalette.PRIMARY_TEXT,
    fontWeight: 'bold',
    fontSize: '1.2rem',
    marginTop: 25,
  },
})

function getStyle(
  k:
    | 'container'
    | 'cardContainer'
    | 'cardStyle'
    | 'buttonContainer'
    | 'text'
    | 'scrollContainer'
    | 'labelledButton'
    | 'title',
  cancelLabel: boolean = false,
): React.CSSProperties | undefined {
  const x = styles[k]
  if (!x) {
    return undefined
  }
  if (k === 'labelledButton' && cancelLabel) {
    ;(x as any).width = '48%'
  }
  return x as React.CSSProperties
}

export default withStyles(styles, { withTheme: true })(Radium(MultiListViewerModal))
