const lower = "abcdefghijklmnopqrstuvwxyz";
const upper = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const numbs = "0123456789";
const arr = [lower, upper, numbs];

/**
 *
 * @param x Input number
 * @param limit The maximum saturation value of the array
 * @returns An integer value that has been rounded down and saturated
 */
function floorAndMinimum(x: number, limit: number): number {
    const y = Math.floor(x);
    return Math.min(y, limit);
}

/**
 *
 * @param num Upper bound of the random number
 * @returns A random number between 0 and the given parameter
 */
function rand(max: number): number {
    return Math.random() * max;
}

/**
 *
 * @param max Upper bound of the random number
 * @returns a random integer with maximum of the given parameter
 */
function randomIntegerInBounds(max: number): number {
    return floorAndMinimum(rand(max), max - 1);
}

/**
 *
 * @param length The length of the random string
 * @returns A string of that is hyphen delimited
 */
export function randomIdString(length: number = 50): string {
    let str = "";
    for (let i = 0; i < length; i++) {
        if (i % 5 === 0 && i !== 0) str += "-";

        const jdx = randomIntegerInBounds(arr.length);
        const idx = randomIntegerInBounds(arr[jdx].length);
        str += arr[jdx][idx];
    }
    return str;
}

export function roundToTwo(num: number) {
    const m = Number((Math.abs(num) * 100).toPrecision(15));
    return (Math.round(m) / 100) * Math.sign(num);
}

/**
 *
 * @param obj The object in which the key is nested
 * @param keyToFind The nested key to find in the object
 * @returns An array representing the objects path to the key
 */
export function getObjectKeyPath(obj: any, keyToFind: string) {
    let path: string[] = [];
    for (let key in obj) {
        if (key === keyToFind) {
            path.push(key);
            break;
        }
        if (Array.isArray(obj[key]) && obj[key].includes(keyToFind)) {
            path = [...path, key, keyToFind];
            break;
        }
        if (Array.isArray(obj[key]) || typeof obj[key] === "object") {
            let subPath = getObjectKeyPath(obj[key], keyToFind);
            if (subPath.length) {
                path = [...path, key, ...subPath];
                break;
            }
        }
    }
    return path;
}

export function isInViewport(elem: any, offset = 0) {
    const bounding = elem.getBoundingClientRect();
    return (
        bounding.top >= 0 && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) + offset
    );
}
