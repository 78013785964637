import { ColorPalette } from '../../config/colors'

import React, { Component } from 'react'
import Radium from 'radium'
import Draggable, { DraggableEvent } from 'react-draggable'
import Icon from '@mdi/react'
import { mdiDragVertical, mdiDraw } from '@mdi/js'

import SignatureConfigController from './SignatureConfigController'

interface SignatureProps {
  coordinates: { x: number; y: number }
  signatureStyle: { signatureSizeName: string }
  onDrag: (...args: any[]) => void
  disabled?: boolean

  onStyleButtonClicked: (functionType: string, item?: string | undefined) => void
}

interface SignatureState {
  initialX: number
  initialY: number
  signatureControllerVisible: boolean
  isDragging: boolean
  hasInteracted: boolean
}

class Signature extends Component<SignatureProps, SignatureState> {
  pageX?: number
  pageY?: number

  signatureSizeMap = {
    TINY: { width: 80, height: 20 },
    SMALL: { width: 100, height: 30 },
    MEDIUM: { width: 110, height: 40 },
    LARGE: { width: 130, height: 50 },
    HUGE: { width: 150, height: 60 },
  }

  signatureIconSizeMap = {
    TINY: 0.5,
    SMALL: 1,
    MEDIUM: 1.6,
    LARGE: 2.2,
    HUGE: 2.8,
  }

  state: SignatureState = {
    initialX: 0,
    initialY: 0,
    signatureControllerVisible: false,
    isDragging: false,
    hasInteracted: false,
  }

  componentDidMount() {
    this.setState({
      initialX: this.props.coordinates.x > 0 ? this.props.coordinates.x : 100,
      initialY: this.props.coordinates.y > 0 ? this.props.coordinates.y : 100,
    })
  }

  toggleSignatureController(signatureControllerVisible: boolean) {
    this.setState({ signatureControllerVisible, hasInteracted: true })
  }

  onDragStart(e: DraggableEvent): void | false {
    this.pageX = (e as React.MouseEvent).pageX
    this.pageY = (e as React.MouseEvent).pageY
  }

  onDragStop(e: DraggableEvent): void | false {
    const { pageX, pageY } = e as React.MouseEvent

    if (pageX !== this.pageX || pageY !== this.pageY) {
      this.props.onDrag({
        x: pageX - (this.pageX || 0),
        y: pageY - (this.pageY || 0),
      })
    }
    this.setState({ isDragging: false })
  }

  render() {
    const { signatureSizeName } = this.props.signatureStyle

    let signatureController = null
    if (this.state.signatureControllerVisible && !this.state.isDragging) {
      signatureController = (
        <div style={{ ...styles.signatureConfigControllerContainer }}>
          <SignatureConfigController signatureSizeName={signatureSizeName} onClick={this.props.onStyleButtonClicked} />
        </div>
      )
    }

    return (
      <Draggable
        bounds="parent"
        onStop={(e) => this.onDragStop(e)}
        onStart={(e) => this.onDragStart(e)}
        onDrag={() => this.setState({ isDragging: true })}
        disabled={this.props.disabled}>
        <div
          onMouseEnter={() => this.toggleSignatureController(true)}
          onMouseLeave={() => this.toggleSignatureController(false)}
          style={{
            ...styles.container,
            left: this.state.initialX,
            top: this.state.initialY,
            ...(this.signatureSizeMap as any)[signatureSizeName],
            backgroundColor: this.state.isDragging ? 'rgba(255,255,255,0.4)' : ColorPalette.CARD_WHITE,
            boxShadow: `0px 2px 2px ${this.state.hasInteracted ? 'rgba(0, 0, 100, 0.1)' : 'rgba(0, 200, 255, 0.8)'}`,
          }}>
          <Icon
            style={{ width: '18%' }}
            path={mdiDraw}
            size={(this.signatureIconSizeMap as any)[signatureSizeName]}
            color={ColorPalette.DARK_GREY}
          />
          <Icon style={{ width: '18%' }} path={mdiDragVertical} size={0.9} color={ColorPalette.DARK_GREY} />
          {signatureController}
        </div>
      </Draggable>
    )
  }
}

let styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: `0px 2px 2px rgba(0, 0, 100, 0.1)`,
    backgroundColor: ColorPalette.CARD_WHITE,
    borderRadius: 4,
    height: 60,
    paddingLeft: 10,
    paddingRight: 1,
    position: 'absolute',
  },
  signatureConfigControllerContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    position: 'absolute' as 'absolute',
    left: -20,
    top: 20,
    zIndex: 1000,
    height: 50,
  },
}

export default Radium(Signature)
