import React, { Component } from 'react'
import ScrollMenu from 'react-horizontal-scrolling-menu'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver'
import LocalPhoneIcon from '@material-ui/icons/LocalPhone'
import MenuBookIcon from '@material-ui/icons/Menu'
import AnnouncementIcon from '@material-ui/icons/Announcement'
import WorkIcon from '@material-ui/icons/Work'
import HistoryIcon from '@material-ui/icons/History'
import LocalHospitalIcon from '@material-ui/icons/LocalHospital'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import EmailIcon from '@material-ui/icons/Email'
import PanToolIcon from '@material-ui/icons/PanTool'
import HomeIcon from '@material-ui/icons/Home'
import SecurityIcon from '@material-ui/icons/Security'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import PageviewIcon from '@material-ui/icons/Pageview'
import EventIcon from '@material-ui/icons/Event'
import ErrorIcon from '@material-ui/icons/Error'
import DonutLargeIcon from '@material-ui/icons/DonutLarge'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import BallotIcon from '@material-ui/icons/Ballot'
import SettingsIcon from '@material-ui/icons/Settings'
import { GridSize } from '@material-ui/core'

import ButtonGeneric from '../BaseComponents/Buttons/ButtonGeneric'
import { ISectionConfigItem } from '../../interfaces'
import { ColorPalette } from '../../config/colors'

interface DataFlowNavigatorProps {
  currentSectionName: string
  sectionConfig: ISectionConfigItem[]
  buttonStyle?: React.CSSProperties | undefined
  tableWidth?: GridSize
  containerRef?: React.RefObject<HTMLDivElement>
  editable?: boolean
  scrollMenuStyle?: React.CSSProperties | undefined
  scrollWrapperStyle?: React.CSSProperties | undefined
  selectedRole?: string
  onSectionClick: (sectionName: string) => void
  onSectionEditClick?: (sectionLabel: string) => void
  onSectionAddClick?: () => void
  onSectionRemoveClick?: () => void
}

class DataFlowNavigator extends Component<DataFlowNavigatorProps> {
  scrollViewRef?: React.RefObject<ScrollMenu> = React.createRef()

  scrollLeft() {
    this.scrollViewRef?.current?.handleArrowClick()
  }

  scrollRight() {
    this.scrollViewRef?.current?.handleArrowClickRight()
  }

  generateButton = (configItem: any) => {
    const { sectionName, sectionLabel, iconName } = configItem
    return (
      <ButtonGeneric
        key={sectionName}
        style={{
          ...styles.buttonStyle,
          backgroundColor:
            this.props.currentSectionName === sectionName ? ColorPalette.CARD_WHITE : ColorPalette.BUTTON_HOVER_GREY,
          ...this.props.buttonStyle,
          width: 240,
        }}
        iconBefore={
          sectionIconConverter[sectionName]
            ? sectionIconConverter[sectionName]
            : iconName && sectionIconConverter.hasOwnProperty(iconName)
            ? sectionIconConverter[iconName]
            : sectionIconConverter.default
        }
        iconAfter={
          this.props.editable ? (
            <div
              style={styles.editButtonStyle}
              onClick={
                this.props.onSectionEditClick
                  ? // @ts-ignore (suppressing "Cannot invoke an object which is possibly 'undefined'")
                    () => this.props.onSectionEditClick(sectionLabel)
                  : undefined
              }>
              EDIT
            </div>
          ) : undefined
        }
        label={sectionLabel}
        labelStyle={styles.labelStyle}
        onClick={() => this.props.onSectionClick(sectionName)}
      />
    )
  }

  generateButtons(): JSX.Element[] {
    let buttonArray: JSX.Element[] = []
    if (this.props.selectedRole === 'AUDITOR') {
      const otherSection = this.props.sectionConfig?.find((configItem) => {
        return configItem.sectionName === 'OTHER'
      })
      if (!otherSection) {
        return []
      }
      buttonArray.push(this.generateButton(otherSection))
    } else {
      buttonArray = this.props.sectionConfig?.map(this.generateButton)
    }

    if (this.props.editable && this.props.onSectionAddClick) {
      buttonArray.push(
        <ButtonGeneric
          key="new_tab"
          style={{
            ...styles.buttonStyle,
            backgroundColor: true //this.props.currentSectionName === sectionName
              ? ColorPalette.CARD_WHITE
              : ColorPalette.BUTTON_HOVER_GREY,
            ...this.props.buttonStyle,
            paddingLeft: '2em',
            justifyContent: 'space-between',
            width: 'fit-content',
          }}
          iconBefore={<div style={{ height: '1.1rem', width: 0 }}></div>}
          iconAfter={this.props.editable ? <div style={styles.plus}>+</div> : undefined}
          label="NEW SECTION"
          onClick={this.props.onSectionAddClick}
        />,
      )
    }
    return buttonArray
  }

  render() {
    const tableWidth = this.props.tableWidth ? this.props.tableWidth : 9

    return (
      <div ref={this.props.containerRef} style={styles.container}>
        <ButtonGeneric
          key={'leftArrow'}
          style={{ ...styles.arrowButtonStyle }}
          iconBefore={<ArrowLeftIcon style={styles.arrowIcon} />}
          onClick={() => this.scrollLeft()}
        />
        <ScrollMenu
          ref={this.scrollViewRef}
          translate={0}
          wheel={false}
          dragging={true}
          alignCenter={false}
          data={this.generateButtons()}
          menuStyle={this.props.scrollMenuStyle}
          wrapperStyle={{
            width: (window.innerWidth * (tableWidth as number)) / 12 - 2 * arrowButtonWidth,
            ...this.props.scrollWrapperStyle,
          }}
        />
        <ButtonGeneric
          key={'rightArrow'}
          style={styles.arrowButtonStyle}
          iconBefore={<ArrowRightIcon style={styles.arrowIcon} />}
          onClick={() => this.scrollRight()}
        />
      </div>
    )
  }
}

const navBarHeight = 40
const arrowButtonWidth = 20
const styles = {
  container: {
    height: navBarHeight,
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    alignSelf: 'center',
    boxShadow: '0px 0px 2px rgba(120,120,130, 0.2)',
    // position: "-webkit-sticky",  // Safari
    position: 'sticky' as 'sticky',
    top: 0,
    zIndex: 2,
  },
  buttonStyle: {
    paddingLeft: 5,
    width: 175,
    height: navBarHeight,
    fontWeight: '550',
    fontSize: '0.75rem',
    color: ColorPalette.SECONDARY_TEXT,
    marginTop: 0,
    boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.2)',
    ':hover': {
      backgroundColor: ColorPalette.CARD_WHITE,
    },
    ':active': {
      filter: 'brightness(98%)',
      backgroundColor: ColorPalette.CARD_WHITE,
    },
  },
  arrowButtonStyle: {
    width: arrowButtonWidth,
    height: 40,
    fontWeight: '550',
    fontSize: '0.8rem',
    justifyContent: 'center',
    backgroundColor: ColorPalette.DARK_GREY,
    color: ColorPalette.PRIMARY_LIGHT_TEXT,
    marginTop: 0,
    boxShadow: '0px -1px 6px rgba(0, 0, 0, 0.15)',
    ':hover': {
      filter: 'brightness(65%)',
    },
    ':active': {
      filter: 'brightness(35%)',
    },
  },
  arrowIcon: {
    color: ColorPalette.PRIMARY_LIGHT_TEXT,
    width: '1.6rem',
    height: '1.6rem',
  },
  iconStyle: {
    color: ColorPalette.DARK_GREY,
    width: '1.1rem',
    height: '1.1rem',
    marginRight: 10,
    marginLeft: 10,
  },
  editButtonStyle: {
    marginInline: '1em',
    borderRadius: '100px',
    backgroundColor: ColorPalette.PRIMARY_BLUE,
    color: ColorPalette.CARD_WHITE,
    border: 'none',
    padding: '.25em .75em',
    fontSize: 'smaller',
    cursor: 'pointer',
    marginLeft: 'auto',
    marginRight: '1em',
  },
  labelStyle: {
    display: 'block',
    whiteSpace: 'nowrap' as 'nowrap',
    overflowX: 'hidden' as 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: '1em',
  },
  plus: {
    marginLeft: '4em',
    borderRadius: '100px',
    backgroundColor: ColorPalette.PRIMARY_BLUE,
    color: ColorPalette.CARD_WHITE,
    border: 'none',
    fontSize: '1.1em',
    padding: '.1em 1em',
    cursor: 'pointer',
  },
}

const sectionIconConverter: Record<string, JSX.Element> = {
  additionalInfo: <AddCircleIcon style={styles.iconStyle} />,
  communications: <RecordVoiceOverIcon style={styles.iconStyle} />,
  contactDetails: <LocalPhoneIcon style={styles.iconStyle} />,
  educationInfo: <MenuBookIcon style={styles.iconStyle} />,
  emergencyContactDetails: <AnnouncementIcon style={styles.iconStyle} />,
  employmentInfo: <WorkIcon style={{ ...styles.iconStyle }} />,
  experience: <MenuBookIcon style={styles.iconStyle} />,
  medicalCriminalInfo: <HistoryIcon style={styles.iconStyle} />,
  medicalInfo: <LocalHospitalIcon style={styles.iconStyle} />,
  paymentDetails: <CreditCardIcon style={styles.iconStyle} />,
  personalInfo: <AccountCircleIcon style={styles.iconStyle} />,
  programmeInfo: <BallotIcon style={styles.iconStyle} />,
  postalAddress: <EmailIcon style={styles.iconStyle} />,
  ppe: <PanToolIcon style={{ ...styles.iconStyle }} />,
  resAddress: <HomeIcon style={styles.iconStyle} />,
  securityInfo: <SecurityIcon style={styles.iconStyle} />,

  TIME_SENSITIVE_DOCS: <EventIcon style={styles.iconStyle} />,
  'RECRUITMENT_&_ONBOARDING': <PageviewIcon style={styles.iconStyle} />,
  DISCIPLINE: <ErrorIcon style={styles.iconStyle} />,
  PERFORMANCE_MANAGEMENT: <DonutLargeIcon style={{ ...styles.iconStyle }} />,
  'PAYROLL,_LEAVE,_HEALTH_&_SAFETY': <LocalHospitalIcon style={styles.iconStyle} />,
  TERMINATION: <CancelOutlinedIcon style={styles.iconStyle} />,
  OTHER: <BallotIcon style={styles.iconStyle} />,
  custom: <SettingsIcon style={styles.iconStyle} />,
  default: <CheckBoxOutlineBlankIcon style={styles.iconStyle} />,
}

export default DataFlowNavigator
