import { GroupCardContainer } from '../../screens/Groups/GroupCardContainer'
import { UserFeedback } from '../GeneralUI/Feedback/UserFeedback'
import { Group, GroupStatus } from '../../types'

type GroupsListProps = {
  groups: Group[]
  groupStatusFilters: GroupStatus[]
  initialDate: Date
  toggleDateRangeModal: (groupId?: string) => void
  onViewGroupClick: (groupId: string) => void
}

export const GroupsList = (props: GroupsListProps) => {
  const { groups, groupStatusFilters, initialDate, toggleDateRangeModal, onViewGroupClick } = props
  const numberOfGroups = groups.length

  let instruction = null
  if (groupStatusFilters.length === 0) {
    instruction = <UserFeedback message="Include status filter options of groups you want to see" />
  }

  let listing = null
  if (instruction === null && numberOfGroups > 0) {
    listing = groups.map((group: Group, index: number) => (
      <GroupCardContainer
        group={group}
        index={index}
        groupStatusFilters={groupStatusFilters}
        initialDate={initialDate}
        toggleDateRangeModal={toggleDateRangeModal}
        onViewGroupClick={() => onViewGroupClick(group.id)}
        key={`group-${group.id}`}
      />
    ))
  }

  if (instruction === null && listing === null) {
    listing = <UserFeedback message="No groups have been added yet" />
  }

  return (
    <div style={styles.groupList}>
      {instruction}
      {listing}
    </div>
  )
}

const styles = {
  groupList: {
    display: 'flex',
    flexWrap: 'wrap' as 'wrap',
    justifyContent: 'space-between',
    padding: '3em 3.5em',
    gap: '4em',
  },
}
