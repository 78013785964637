import { createBulkDisciplineActon, updateDisciplinaryData } from '../provider/routes/dataSync'
import { AuthCredentials } from '../types'
import { SessionService } from './sessionService'

export default class DisciplineService {
  static async updateBulkDisciplineList(
    idPassport: string,
    offence: any,
    action: any,
    selectedAssociation: string,
    selectedRoles: any,
    authCredentials: AuthCredentials,
  ): Promise<void> {
    try {
      const { username, password } = authCredentials
      const token = await SessionService.prepareAuthTokens(username, password)
      await createBulkDisciplineActon(idPassport, offence, action, selectedAssociation, selectedRoles, token)
    } catch (error) {
      throw error
    }
  }

  static async uploadDisciplinaryActions(
    selectedAssociation: string,
    disciplinaryActionsTracker: any,
    authCredentials: AuthCredentials,
  ): Promise<void> {
    try {
      const { username, password } = authCredentials
      const token = await SessionService.prepareAuthTokens(username, password)
      await updateDisciplinaryData(selectedAssociation, disciplinaryActionsTracker, token)
    } catch (error) {
      throw error
    }
  }
}
