import { memo, useState } from 'react'

import { ColorPalette } from '../../config/colors'
import TextInputOutlined from '../BaseComponents/Text/TextInputOutlined'
import ModalPortal from '../Modals/ModalPortal'
import LabelCollector from '../Modals/LabelCollector'
import CalendarRange from '../BaseComponents/Calendar/CalendarRange'

type GroupDetailsProps = {
  startDate?: Date
  endDate?: Date
  calendarOrientation?: 'horizontal' | 'vertical'
  onSetGroupName?: (groupName: string) => void
  onSetActiveDateRange: (activeDateRange: { startDate: Date; endDate: Date }) => void
}

const GroupDetails = memo((props: GroupDetailsProps) => {
  const [groupNameModalOpen, setGroupNameModalOpen] = useState(false)

  const [groupName, setGroupName] = useState('')
  const [activeDateRange, setActiveDateRange] = useState({
    startDate: props.startDate || new Date(),
    endDate: props.endDate || new Date(),
  })

  const toggleGroupNameModal = () => {
    setGroupNameModalOpen((groupNameModalOpen) => !groupNameModalOpen)
  }

  const handleDateChange = (date?: number, rangePoint?: string) => {
    if (!date || !rangePoint) {
      return
    }
    const newDateRange = { ...activeDateRange, [rangePoint]: new Date(date) }
    setActiveDateRange(newDateRange)
    props.onSetActiveDateRange(newDateRange)
  }

  /*
     @NICKI: When was the decision made to start using a ModalPortal and why was this done?

     @NICKI: Why use the TextInputOutlined in this way. It seems counterintuitive to click in a text area only to render another textbox popup...
     The correct behaviour is to use the textHandler prop and allow the user to enter the name directly into the TextInputOutlined.
  */

  return (
    <div>
      {props.onSetGroupName && (
        <TextInputOutlined
          style={styles.sectionInput}
          label="GROUP NAME"
          placeholder="Click to specify group name"
          value={groupName}
          onClick={toggleGroupNameModal}
          textHandler={() => ''}
          disabled={false}
          type="textarea"
        />
      )}
      <div
        style={{
          ...styles.sectionInput,
          ...styles.containerOutlined,
        }}>
        <p style={{ ...styles.label }}>ACTIVE DATE RANGE</p>
        <CalendarRange
          orientation={props.calendarOrientation || 'horizontal'}
          dateRange={activeDateRange}
          onDateChange={handleDateChange}
          inputStyle={{ ...styles.sectionInput, border: 'none', minWidth: 'unset' }}
          dialogStyle={{ zIndex: 30000 }}
        />
      </div>
      {/* <DateRangeModal
        open={open}
        title="Proposed Start & End Dates"
        initialStartDate={initialStartDate}
        initialExpiryDate={initialExpiryDate}
        onCancelClick={toggle}
        onSaveClick={handleDateChange}
        /> */}
      {props.onSetGroupName && groupNameModalOpen && (
        <ModalPortal>
          <LabelCollector
            open={true}
            warning="Enter group name"
            placeholder="Enter group name"
            buttonLabel="Save"
            iconName="setting"
            dismiss={() => {
              setGroupName('')
              toggleGroupNameModal()
            }}
            submit={(name: string) => {
              const groupName = name.toUpperCase()
              setGroupName(groupName)
              // @ts-ignore
              props.onSetGroupName(groupName)
              toggleGroupNameModal()
            }}
            style={styles.showOnTop}
          />
        </ModalPortal>
      )}
    </div>
  )
})

const styles = {
  sectionInput: {
    marginTop: 40,
    marginInline: '1.5em',
    padding: '0 1em 1em',
    minWidth: 400,
  },
  containerOutlined: {
    zIndex: 1,
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid rgba(195,205,225,0.6)`,
    borderRadius: 16,
  },
  label: {
    color: ColorPalette.LIGHT_GREY,
    backgroundColor: ColorPalette.CARD_WHITE,
    zIndex: 2,
    marginTop: -8,
    alignSelf: 'center',
    fontSize: '0.65rem',
    paddingLeft: 10,
    paddingRight: 10,
  },
  showOnTop: {
    zIndex: 10000,
  },
  showEvenMoreOnTop: {
    zIndex: 20000,
  },
}

export default GroupDetails
