import cloneDeep from 'lodash/cloneDeep'

export function copyObjectImmutibly<T>(data: T): T {
  try {
    const copy = cloneDeep(data)
    return copy
  } catch (error) {
    return { ...data }
  }
}

export function getEnumKeyForValue(enumData: any, value: any): any {
  let enumKey
  Object.keys(enumData).forEach((key) => (enumData[key] === value ? (enumKey = key) : null))
  return enumKey
}

/**
 *
 * @param obj - the object mapper
 * @returns - an array of all the fields with keys flattened into the individual
 * items in the array
 */
export function flattenEntries<T>(obj: Record<string, T>): ({ key: string } & T)[] {
  return Object.entries(obj).map((x: [string, T]) => ({
    key: x[0],
    ...x[1],
  }))
}

// next function can be seen as a slight variation of the one above;
// only difference being that the given object of objects is turned into an array of the same objects
export const toArrayOfObjects = (objectOfObjects: {}): {}[] =>
  Object.entries(objectOfObjects).map(([key, value]) => ({ [key]: value }))

// NOTE: function below intended for simple objects where values are strings/numbers
export const flipObjKeysAndValues = (obj: Record<string, string | number>): Record<string, string> => {
  // { x: 1, y: 2 }
  const flipped = Object.entries(obj).map(([key, value]) => [value, key])

  // { 1: x, 2: y }
  return Object.fromEntries(flipped)
}

export const mapToObject = (map: Map<string, any>, convertMapValues?: boolean): Record<string, any> => {
  const obj: Record<string, any> = {}
  for (const [key, value] of map) {
    obj[key] = convertMapValues && value instanceof Map ? mapToObject(value) : value
  }
  return obj
}

export const isObject = (value: any) => {
  return typeof value === 'object' && value !== null && !Array.isArray(value)
}

export const classToObject = (clss: any) => {
  // based on answer in https://stackoverflow.com/questions/34699529/convert-javascript-class-instance-to-plain-object-preserving-methods
  const keys = (obj: Record<string, any>) =>
    Object.getOwnPropertyNames(obj).concat(Object.getOwnPropertyNames(obj?.__proto__))

  return keys(clss ?? {}).reduce((object: Record<string, any>, key) => {
    const [val, arr, obj] = [clss[key], Array.isArray(clss[key]), isObject(clss[key])]
    object[key] = arr ? val.map(classToObject) : obj ? classToObject(val) : val
    return object
  }, {})
}

export const getObjectKeyByValue = (obj: Record<string, string>, value: string) => {
  return Object.keys(obj).find((key) => obj[key] === value)
}
