import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ButtonGeneric from './ButtonGeneric'
import { ColorPalette } from '../../../config/colors'

interface IBackButtonProps {
  label?: string
  disabled?: boolean
  style?: React.CSSProperties
  onClick?: () => void
}

export const BackButton = (props: IBackButtonProps) => (
  <ButtonGeneric
    style={{ ...styles.backButton, ...props.style }}
    iconBefore={<ChevronLeftIcon style={styles.buttonIconStyle} />}
    label={props.label || 'BACK'}
    disabled={props.disabled}
    onClick={
      props.onClick
        ? props.onClick
        : () => {
            window.history.back()
          }
    }
  />
)

const styles = {
  buttonIconStyle: {
    color: ColorPalette.PRIMARY_TEXT,
    width: '1.2rem',
    height: '1.2rem',
  },
  backButton: {
    fontSize: '0.8rem',
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    width: 40,
    ':hover': {
      color: ColorPalette.PRIMARY_BLUE,
    },
    ':active': {
      color: ColorPalette.DARK_GREY,
    },
  },
}
