export const config = {
    competencies: {
        label: "COMPETENCIES",
        subSections: [
            {
                configContext: "competencyConfig",
                appStateName: "requiredCompetencies",
                label: "REQUIRED COMPETENCIES",
            },
            {
                configContext: "dataCaptureConfig",
                appStateName: "dataFlowNames",
                label: "DATA CAPTURE FLOW TO USE",
            },
            {
                configContext: "useProcessFlow",
                appStateName: "useProcessFlow",
                label: "USE PROCESS FLOW TO CAPTURE DATA?",
            },
        ],
    },
    employ: {
        label: "EMPLOY",
        subSections: [
            {
                configContext: "docsAndData",
                appStateName: "",
                label: "Data and Document settings",
            },
            {
                configContext: "useProcessFlow",
                appStateName: "useProcessFlow",
                label: "USE PROCESS FLOW TO CAPTURE DATA?",
            },
        ],
    },
    promote: {
        label: "PROMOTE",
        subSections: [
            {
                configContext: "docsAndData",
                appStateName: "",
                label: "Data and Document settings",
            },
            {
                configContext: "useProcessFlow",
                appStateName: "useProcessFlow",
                label: "USE PROCESS FLOW TO CAPTURE DATA?",
            },
        ],
    },
    transfer: {
        label: "TRANSFER",
        subSections: [
            {
                configContext: "docsAndData",
                appStateName: "",
                label: "Data and Document settings",
            },
            {
                configContext: "useProcessFlow",
                appStateName: "useProcessFlow",
                label: "USE PROCESS FLOW TO CAPTURE DATA?",
            },
        ],
    },
    discipline: {
        label: "DISCIPLINE",
        subSections: [
            {
                configContext: "redirect",
                appStateName: "",
                label: "Go to main settings",
                route: "/configurator/disciplineconfig",
                // passJobType: true,
            },
            {
                configContext: "useProcessFlow",
                appStateName: "useProcessFlow",
                label: "USE PROCESS FLOW TO CAPTURE DATA?",
            },
        ],
    },
    terminate: {
        label: "TERMINATE",
        subSections: [
            {
                configContext: "redirect",
                appStateName: "",
                label: "Go to main settings",
                route: "/configurator/termination",
            },
            {
                configContext: "useProcessFlow",
                appStateName: "useProcessFlow",
                label: "USE PROCESS FLOW TO CAPTURE DATA?",
            },
        ],
    },
    generalCandidate: {
        label: "VIEW CANDIDATE",
        subSections: [
            {
                configContext: "docsAndData",
                appStateName: "",
                label: "Data and Document settings",
            },
            {
                configContext: "useProcessFlow",
                appStateName: "useProcessFlow",
                label: "USE PROCESS FLOW TO CAPTURE DATA?",
            },
        ],
    },
    generalEmployee: {
        label: "VIEW EMPLOYEE",
        subSections: [
            {
                configContext: "docsAndData",
                appStateName: "",
                label: "Data and Document settings",
            },
            {
                configContext: "useProcessFlow",
                appStateName: "useProcessFlow",
                label: "USE PROCESS FLOW TO CAPTURE DATA?",
            },
        ],
    },
    // NOTE: jobSubTypes is a special case in that the corresponding state variable gets initialised differently
    jobSubTypes: {
        label: "POSITION SUB TYPES",
        subSections: [
            {
                appStateName: "jobSubTypes",
                label: "DOES THIS POSITION HAVE SUB TYPES?",
                selectorType: "selector", // default picker will show if not specified
            },
        ],
    },
};
