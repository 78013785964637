import { useState } from 'react'
import { useSelector } from 'react-redux'
import { difference } from 'lodash'

import PickerContained from '../BaseComponents/Pickers/PickerContained'
import AlertModalOneButton from '../Modals/AlertModalOneButton'
import { PeopleFlowCombinedReducer } from '../../store'
import { Group } from '../../types'
import { PickerHandlerEvent } from '../BaseComponents/Pickers/Picker'
import { AssociationSettingsRepository } from '../../repositories'

type GroupMemberJobTypeModalProps = {
  group: Group
  initialJobType?: string
  division?: string
  excludeJobTypes?: string[]
  onDismiss?: () => void
  onClick: (jobType: string) => void
}

export const GroupMemberJobTypeModal = ({
  group,
  initialJobType,
  excludeJobTypes,
  onClick,
  onDismiss,
}: GroupMemberJobTypeModalProps) => {
  const [jobType, setJobType] = useState(initialJobType || '')

  const selectedAssociation = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedAssociation,
  )
  const associationRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.associationRepo,
  ) as AssociationSettingsRepository

  const organisationConfig = associationRepo.getOrganisationConfig(selectedAssociation)
  const jobTypesToExclude = excludeJobTypes || []
  const availableJobTypes = group
    ? difference(
        Object.keys(organisationConfig.divisionConfig.config[group.division || 'ADMIN'].jobTypes),
        jobTypesToExclude,
      )
    : []

  return (
    <AlertModalOneButton
      open={true}
      header="Job Type"
      body={
        <PickerContained
          value={jobType}
          placeholder="Select a job type"
          searchEnabled={true}
          items={availableJobTypes}
          onChange={(e: PickerHandlerEvent) => setJobType(e.target.value)}
          style={{ width: 'max-content', padding: '0.75em 3em 1.5em' }}
        />
      }
      buttonLabel="Ok"
      opaqueBackground={true}
      onClick={() => onClick(jobType)}
      dismiss={onDismiss}
    />
  )
}
