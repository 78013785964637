import { BillingDataSet, CountsBillingDataObject } from '../../types'
import { ColorPalette } from '../../config'
import BarChart from '../DataVisualisation/BarChart'

type BillingDetailsProps = {
  data: Record<string, any>
  dataSets: BillingDataSet[]
  maxCandidatesInPeriod?: number
  maxEmployeesInPeriod?: number
}

export const BillingDetails = (props: BillingDetailsProps) => {
  const { data, dataSets, maxCandidatesInPeriod, maxEmployeesInPeriod } = props
  const maxCandidatesHeader =
    maxCandidatesInPeriod !== undefined ? (
      <div style={{ textTransform: 'uppercase' }}>Max candidates for period:</div>
    ) : null
  const maxEmployeesHeader =
    maxEmployeesInPeriod !== undefined ? (
      <div style={{ textTransform: 'uppercase' }}>Max employees for period:</div>
    ) : null

  let details = null
  if (maxCandidatesHeader || maxEmployeesHeader) {
    details = (
      <div
        style={{
          borderBottom: `1px solid ${ColorPalette.PRIMARY_TEXT}`,
          color: ColorPalette.PRIMARY_TEXT,
          marginTop: '2.5em',
        }}>
        <div style={{ fontWeight: '550', margin: '1em 0', display: 'flex' }}>
          <div>
            {maxCandidatesHeader}
            {maxEmployeesHeader}
          </div>
          <div style={{ marginLeft: '1.5em', textAlign: 'right' }}>
            <div>{maxCandidatesInPeriod && maxCandidatesInPeriod > 0 ? maxCandidatesInPeriod : null}</div>
            <div>{maxEmployeesInPeriod && maxEmployeesInPeriod > 0 ? maxEmployeesInPeriod : null}</div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div style={styles.rightSide}>
      <div style={styles.rightSideContent}>
        <div style={styles.details}>{details}</div>
        <BarChart
          data={dataSets}
          labels={data.map((item: CountsBillingDataObject) => item.date.substring(0, 10))}
          showLegend={false}
          style={styles.barChart}
        />
      </div>
    </div>
  )
}

const styles = {
  rightSide: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    paddingInline: 'max(2em, 2%)',
    width: '100%',
    overflow: 'hidden',
  },
  rightSideContent: {
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    flex: 1,
    marginTop: '1%',
    overflow: 'auto',
    backgroundColor: ColorPalette.CARD_WHITE,
  },
  details: {
    width: '92%',
    margin: '0 auto',
  },
  barChart: {
    padding: '4% 6%',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
}
