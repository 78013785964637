import { PeopleFlowCombinedReducer, PersistedStateLoadHook } from '.'
import { ActionType, ReducerAction } from '../actions/actions'
import {
  IClientRequestInstance,
  ClientRequestsFactory,
  ClientRequestInstanceFactory,
  IClientRequests,
  TimeBasedDocFactory,
  IDocVerificationTracker,
  DocVerificationTrackerFactory,
  Profile,
  TimeBasedDocs,
} from '../../models'
import { DataCaptureMode, AllScreenNames, ProcessModes } from '../../config'
import { DisciplinaryItemFactory, IDisciplineHistoryItem } from '../../models'
import { ViewModes } from '../../components/SideMenus/DocValiditySideMenu'
import { DocDownloadQueueItem } from '../../screens/Exports/DocExport'
import { AssociationSettingsRepository, ProfileRepository, UserRepository } from '../../repositories'
import { AsyncTaskDocExport, ValidEmploymentStatus } from '../../types'

export interface PerformanceImportTracker {
  fileName: string
  code?: string
  formatErrors?: number
  timestamp: string
  incompleteData?: number
  actualColumns?: string[]
  missingColumns?: string[]
  itemCount?: number
  processing?: boolean
}

export interface ISessionState {
  activeDocumentPortalSection: string
  allClientNames: string[]
  allClientUsers: { name: string; surname: string; idPassport: string }[]
  allCohorts: string[]
  allPositions: string[]
  allRequests: IClientRequests
  allRoles: string[]
  allTimeBasedDocs: TimeBasedDocs
  comingFromDocValidity: boolean
  currentDisciplinaryItem: IDisciplineHistoryItem
  currentScreen: AllScreenNames
  currentSection: AllScreenNames
  dataCaptureMode: DataCaptureMode
  disciplineItemUidToLoad: string
  docImportList: any[]
  documentImportTracker: any[]
  docValidityViewMode: ViewModes
  docVerificationTracker: IDocVerificationTracker
  docValidityFilterState: Record<string, Record<string, boolean>>
  asyncTaskQueueDocExport: AsyncTaskDocExport[]
  env: string
  hasDataLogsAccess: boolean
  hasDeviceLogsAccess: boolean
  hasDocValidityAccess: boolean
  hasFormsAccess: boolean
  hasImportAccess: boolean
  hasRequestHandlerAccess: boolean
  hasTrashAccess: boolean
  hasUserManagementAccess: boolean
  hasWorkforceAccess: boolean
  hasDocMarkingAccess: boolean
  hasBulkTerminationAccess: boolean
  idPassport: string
  isReadOnly: boolean
  itemsDownloading: string[]
  itemsBeingGenerated: DocDownloadQueueItem[]
  loading: boolean
  newPassword?: string
  otp?: string
  password: string
  performanceData: string
  performanceImportTracker: PerformanceImportTracker[]
  previousScreen: AllScreenNames
  processMode: ProcessModes
  profile: Profile
  profilePic: string
  selectedAssociation: string
  selectedDocNames: string[]
  selectedEmploymentStatus: ValidEmploymentStatus
  selectedFormIndex: number
  selectedRequest: IClientRequestInstance
  selectedRole: string
  selectedCohort: string
  sourceData: Record<string, any>

  associationRepo?: AssociationSettingsRepository | null
  profileRepo?: ProfileRepository | null
  userRepo?: UserRepository | null
}

function defaultState(): ISessionState {
  return {
    activeDocumentPortalSection: '',
    allClientNames: [],
    allClientUsers: [],
    allCohorts: [],
    allPositions: ['Cleaner', 'Picker'],
    allRequests: ClientRequestsFactory.create([]),
    allRoles: [],
    allTimeBasedDocs: TimeBasedDocFactory.create([]),
    comingFromDocValidity: false,
    docValidityViewMode: ViewModes.ALL,
    currentDisciplinaryItem: DisciplinaryItemFactory.create({}),
    currentScreen: AllScreenNames.PROFILES,
    currentSection: AllScreenNames.EMPLOYEE,
    dataCaptureMode: DataCaptureMode.STANDARD,
    disciplineItemUidToLoad: '',
    docImportList: [],
    documentImportTracker: [],
    docVerificationTracker: DocVerificationTrackerFactory.create({}),
    docValidityFilterState: {},
    asyncTaskQueueDocExport: [],
    env: 'prod',
    hasDataLogsAccess: false,
    hasDeviceLogsAccess: false,
    hasDocValidityAccess: false,
    hasFormsAccess: false,
    hasImportAccess: false,
    hasRequestHandlerAccess: false,
    hasTrashAccess: false,
    hasUserManagementAccess: false,
    hasWorkforceAccess: false,
    hasDocMarkingAccess: false,
    hasBulkTerminationAccess: false,
    idPassport: '',
    isReadOnly: false,
    itemsDownloading: [],
    itemsBeingGenerated: [],
    loading: false,
    password: '',
    performanceData: '',
    performanceImportTracker: [],
    previousScreen: AllScreenNames.PROFILES,
    processMode: ProcessModes.GENERAL_EMPLOYEE,
    profile: new Profile({}),
    profilePic: '',
    selectedAssociation: '',
    selectedCohort: '',
    selectedDocNames: [],
    selectedEmploymentStatus: 'EMPLOYEE',
    selectedFormIndex: 0,
    selectedRequest: ClientRequestInstanceFactory.create({}),
    selectedRole: '',
    sourceData: {},

    associationRepo: null,
    profileRepo: null,
    userRepo: null,
  }
}

export default function reducer(state: ISessionState = defaultState(), action: ReducerAction): ISessionState {
  switch (action.type) {
    case ActionType.SET_ALL_ROLES:
      let { allRoles = [] } = action.data
      let selectedRole = state.selectedRole
      if (!selectedRole) {
        if (allRoles.includes('ADMIN')) {
          selectedRole = 'ADMIN'
        } else if (allRoles.length) {
          selectedRole = allRoles[0]
        }
      } else if (!allRoles.includes(selectedRole)) {
        selectedRole = allRoles[0]
      }
      return { ...state, allRoles, selectedRole }

    case ActionType.SET_ALL_COHORTS:
      let { allCohorts = [] } = action.data
      let selectedCohort = state.selectedCohort
      if (!selectedCohort) {
        if (allCohorts.includes('ADMIN')) {
          selectedCohort = 'ADMIN'
        } else if (allCohorts.length) {
          selectedCohort = allCohorts[0]
        }
      } else if (!allCohorts.includes(selectedCohort)) {
        selectedCohort = allCohorts[0]
      }
      return { ...state, allCohorts, selectedCohort }

    case ActionType.UPDATE_STATE:
      return { ...state, ...action.data }

    case ActionType.CHANGE_SCREEN:
      return { ...state, ...action.data }

    case ActionType.SET_SELECTED_ASSOCIATION:
      const sc = action.data.selectedAssociation.toLowerCase()
      if (!state.allClientNames.includes(sc)) {
        return { ...state }
      }
      return { ...state, selectedAssociation: sc }

    case ActionType.SET_ALL_COMPANIES:
      let allClientNames = action.data.allClientNames
      let selectedAssociation = ''
      const mustSelectNewClient =
        (allClientNames.length > 0 && state.selectedAssociation === '') ||
        !allClientNames.includes(state.selectedAssociation)

      if (mustSelectNewClient) {
        selectedAssociation = allClientNames[0]
      } else {
        selectedAssociation = state.selectedAssociation
      }

      return { ...state, allClientNames, selectedAssociation }

    case ActionType.SET_SELECTED_ROLE:
      let newSelectedRole = action.data.selectedRole
      if (typeof newSelectedRole !== 'string') {
        return { ...state }
      }
      if (!state.allRoles.includes(newSelectedRole)) {
        return { ...state }
      }
      return { ...state, selectedRole: newSelectedRole }

    case ActionType.SET_SELECTED_COHORT:
      let newSelectedCohort = action.data.selectedCohort
      if (typeof newSelectedCohort !== 'string') {
        return { ...state }
      }
      if (!state.allCohorts.includes(newSelectedCohort)) {
        return { ...state }
      }
      return { ...state, selectedCohort: newSelectedCohort }

    case ActionType.RESET_CREDENTIALS:
      return { ...state, password: '', newPassword: '', otp: '' }

    default:
      return { ...state }
  }
}

export const onSessionManagerLoad: PersistedStateLoadHook = (state: PeopleFlowCombinedReducer) => {
  if (!state.sessionManager) {
    return { ...state }
  }
  let sessionManager = { ...state.sessionManager }
  sessionManager.docVerificationTracker = DocVerificationTrackerFactory.create({})
  sessionManager.allTimeBasedDocs = TimeBasedDocFactory.create([])

  return { ...state, sessionManager }
}
