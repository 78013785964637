import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { ColorPalette } from '../../../config'

type SimpleTableHeaderProps = {
  columns: string[]
  stickyFirstColumn?: boolean
  headerCellWidth?: string | number
}

export const SimpleTableHeader = (props: SimpleTableHeaderProps) => {
  let containerStyle: React.CSSProperties = { ...styles.tableHeaderContainer }
  if (props.headerCellWidth) {
    containerStyle.width = props.headerCellWidth
  }
  return (
    <TableHead style={containerStyle}>
      <TableRow>
        {props.columns.map((column: any, index: number) => {
          if (index === 0) {
            const style: React.CSSProperties = { ...styles.tableHeaderCell }
            if (props.stickyFirstColumn) {
              style.position = 'sticky'
              style.left = 0
              style.zIndex = 9999
            }
            return (
              <TableCell style={style} key={`tableRow_cell-${index}`}>
                {column}
              </TableCell>
            )
          }
          return (
            <TableCell align="right" style={styles.tableHeaderCell} key={`tableRow_cell-${index}`}>
              {column}
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

const styles = {
  tableHeaderContainer: {
    height: 45,
    backgroundColor: ColorPalette.DARK_GREY,
    width: '100%',
  },
  tableHeaderCell: {
    padding: '0px 20px',
    backgroundColor: ColorPalette.DARK_GREY,
    fontSize: '0.8rem',
    textOverflow: 'ellipsis' as 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap' as 'nowrap',
    width: '25ch',
    color: ColorPalette.PRIMARY_LIGHT_TEXT,
    lineHeight: 1.25,
    textAlign: 'left' as 'left',
  },
}
