import Spinner from 'react-spinkit'
import { PickerHandlerEvent } from '../BaseComponents/Pickers/Picker'
import PickerContained from '../BaseComponents/Pickers/PickerContained'
import { ColorPalette } from '../../config'
import ButtonBlue from '../BaseComponents/Buttons/ButtonBlue'

type SettingsModalProps = {
  open: boolean
  allClientNames: string[]
  allRoles: string[]
  allCohorts: string[]
  selectedAssociation: string
  selectedRole: string
  selectedCohort: string
  fetchingNewClientSettings?: boolean
  onChange: (e: PickerHandlerEvent, selectedKeyName: string) => void
  onSave: () => void
}

export const SettingsModal = ({
  open,
  allClientNames,
  allRoles,
  allCohorts,
  selectedAssociation,
  selectedRole,
  selectedCohort,
  fetchingNewClientSettings,
  onChange,
  onSave,
}: SettingsModalProps) => {
  if (open) {
    const allRequiredInputsProvided = selectedAssociation && selectedRole && selectedCohort

    let cohortSelector = null
    let roleSelector = null
    if (fetchingNewClientSettings) {
      cohortSelector = <LoadingSpinner />
      roleSelector = <LoadingSpinner />
    } else {
      cohortSelector = (
        <PickerContained
          style={styles.picker}
          items={allCohorts.sort()}
          value={selectedCohort || ''}
          transformToUpperCase={true}
          searchEnabled={false}
          onChange={(e: PickerHandlerEvent) => onChange(e, 'selectedCohort')}
        />
      )
      roleSelector = (
        <PickerContained
          style={styles.picker}
          items={allRoles.sort()}
          value={selectedRole || ''}
          transformToUpperCase={true}
          searchEnabled={false}
          // disabled={fetchingNewClientSettings}
          onChange={(e: PickerHandlerEvent) => onChange(e, 'selectedRole')}
        />
      )
    }

    return (
      <div style={styles.screenContainer}>
        <div style={styles.cardContainer}>
          <h1 style={{ ...styles.header, marginTop: '0.25em' }}>Selected employer</h1>
          <PickerContained
            style={styles.picker}
            items={allClientNames.sort()}
            value={selectedAssociation || ''}
            transformToUpperCase={true}
            searchEnabled={false}
            onChange={(e: PickerHandlerEvent) => onChange(e, 'selectedAssociation')}
          />
          <h1 style={{ ...styles.header, marginTop: 30 }}>Selected role</h1>
          {roleSelector}
          <h1 style={{ ...styles.header, marginTop: 30 }}>Selected cohort</h1>
          {cohortSelector}
          <ButtonBlue
            style={styles.labelledButton}
            disabled={!allRequiredInputsProvided || fetchingNewClientSettings}
            onClick={onSave}>
            Done
          </ButtonBlue>
        </div>
      </div>
    )
  }
  return null
}

const styles = {
  screenContainer: {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    backgroundColor: ColorPalette.MODAL_BACKGROUND_OVERLAY_SOFT,
    width: window.innerWidth,
    height: window.innerHeight,
    zIndex: 1000,
    overflow: 'hidden',
  },
  cardContainer: {
    position: 'absolute' as 'absolute',
    top: 10,
    right: 5,
    zIndex: 100000,
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.2)',
    backgroundColor: ColorPalette.CARD_WHITE,
    marginTop: window.innerHeight * 0.03,
    marginRight: window.innerHeight * 0.05,
    width: window.innerWidth * 0.22,
    padding: '30px 30px 30px 30px',
    borderRadius: 8,
  },
  header: {
    color: ColorPalette.PRIMARY_TEXT,
    fontSize: '1rem',
    fontFamily: 'roboto',
    fontWeight: 'bold',
    marginTop: '1.5em',
    textAlign: 'center' as 'center',
  },
  labelledButton: { width: '100%', marginTop: '2em' },
  picker: { marginTop: 5, width: '100%' },
  spinnerContainer: {
    height: 57,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 120,
    marginTop: 5,
    paddingTop: 15,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 10,
    border: `1px solid rgba(195,205,225,0.6)`,
    borderRadius: 12,
    width: '100%',
  },
}

const LoadingSpinner = () => (
  <div style={styles.spinnerContainer}>
    <Spinner
      style={{ transform: 'scale(0.6)' }}
      fadeIn="quarter"
      name="three-bounce"
      color={ColorPalette.PRIMARY_BLUE}
    />
  </div>
)
