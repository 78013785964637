import { ColorPalette } from '../../../config/colors'

import React, { Component } from 'react'
import Radium from 'radium'
import Icon from '@mdi/react'
import { mdiCheckboxMarkedCircle, mdiCloseCircle } from '@mdi/js'

import ButtonBlue from '../../BaseComponents/Buttons/ButtonBlue'
import ButtonRed from '../../BaseComponents/Buttons/ButtonRed'

export interface IAuthorisationListProps {
  authListData: Record<string, any>[]
  authRole: string
  authsRequired: number
  style?: React.CSSProperties | undefined
  onAuthoriseClicked: () => void
  onDeclineClicked: () => void
  onUnAuthoriseClicked: (selectedAuthItem: Record<string, any>) => void
}

class AuthorisationList extends Component<IAuthorisationListProps> {
  generateAuthList = (): JSX.Element[] => {
    const { authListData, authsRequired } = this.props
    let component = [...authListData].map((authItem) => {
      if (authItem.choice === 'authed') {
        return (
          <ButtonBlue style={styles.authedButtonStyle} onClick={() => this.props.onUnAuthoriseClicked(authItem)}>
            {authItem.name}
            <Icon path={mdiCheckboxMarkedCircle} color={ColorPalette.PRIMARY_BLUE} style={{ ...styles.iconStyle }} />
          </ButtonBlue>
        )
      } else {
        return (
          <ButtonRed
            onClick={() => this.props.onUnAuthoriseClicked(authItem)}
            style={{
              ...styles.authedButtonStyle,
              color: ColorPalette.WARNING_RED,
              border: `1px solid ${ColorPalette.WARNING_RED}`,
            }}>
            {authItem.name}
            <Icon path={mdiCloseCircle} color={ColorPalette.WARNING_RED} style={{ ...styles.iconStyle }} />
          </ButtonRed>
        )
      }
    })
    if (authListData.length < authsRequired) {
      const authButtons = (
        <div style={styles.authButtonContainer}>
          <ButtonRed style={styles.actionButton} onClick={() => this.props.onDeclineClicked()}>
            Decline
          </ButtonRed>
          <ButtonBlue style={styles.actionButton} onClick={() => this.props.onAuthoriseClicked()}>
            Authorise
          </ButtonBlue>
        </div>
      )
      component.push(authButtons)
    }
    return component
  }

  render() {
    const authList = this.generateAuthList()
    return (
      <div
        style={{
          width: '73%',
          marginTop: 10,
          display: 'flex',
          flexDirection: 'column' as 'column',
          alignItems: 'center',
        }}>
        {authList}
      </div>
    )
  }
}

const styles = {
  authButtonContainer: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  authedButtonStyle: {
    textTransform: 'uppercase' as 'uppercase',
    marginTop: 15,
    fontSize: '0.85rem',
    color: ColorPalette.PRIMARY_BLUE,
    width: '94%',
    backgroundColor: 'transparent',
    backgroundImage: 'none',
    border: `1px solid ${ColorPalette.PRIMARY_BLUE}`,
    boxShadow: '0px 1px 1px rgba(0, 0, 30, 0.2)',
  },
  actionButton: {
    marginTop: 25,
    width: '49%',
    boxShadow: '0px 1px 1px rgba(0, 0, 30, 0.2)',
  },
  iconStyle: {
    width: 18,
    marginBottom: 2,
    marginLeft: 10,
    height: 'auto',
  },
}

export default Radium(AuthorisationList)
