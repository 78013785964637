import { ColorPalette } from '../../../config/colors'

import React, { Component } from 'react'
import Radium from 'radium'

interface PlainPickerProps {
  disabled?: boolean
  value?: string | undefined
  items: string[]
  style?: React.CSSProperties | undefined

  onChange: (e: string) => void
}

class PlainPicker extends Component<PlainPickerProps> {
  componentDidMount() {
    if (!this.props.value && this.props.items.length) {
      this.props.onChange(this.props.items[0])
    }
  }

  render() {
    let itemList = Array.isArray(this.props.items) ? this.props.items : []
    let optionsList = itemList.map((item, i) => (
      <option key={`${item}_${i}`} value={item}>
        {item}
      </option>
    ))
    return (
      <select
        style={{
          color: ColorPalette.PRIMARY_TEXT,
          fontSize: '0.9rem',
          border: 'none',
          overflow: 'hidden',
          // @ts-ignore
          WebkitAppearance: this.props.disabled ? 'none' : 'auto',
          ...this.props.style,
        }}
        value={this.props.value}
        onChange={(event) => this.props.onChange(event.target.value)}
        disabled={this.props.disabled}>
        {optionsList}
      </select>
    )
  }
}

export default Radium(PlainPicker)
