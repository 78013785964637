import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router'
import Radium from 'radium'
import { connect } from 'react-redux'
import { GridSize } from '@material-ui/core/Grid'
import { cloneDeep, pick } from 'lodash'
import { mdiPlus } from '@mdi/js'

import DataTable from '../../components/Tables/DataTable/DataTable'
import NavigationBar from '../../components/Navigation/NavigationBar'
import SectionHeader, { SectionHeaderComponent } from '../../components/Headings/SectionHeaderPrimary'
import LoadingModal from '../../components/Modals/LoadingModal'
import AlertModalOneButton from '../../components/Modals/AlertModalOneButton'
import SideMenu from '../../components/Navigation/SideMenu'
import FormSideMenu from '../../components/SideMenus/FormSideMenu'
import LabelCollector from '../../components/Modals/LabelCollector'
import OptionsButtonModal from '../../components/Modals/OptionsButtonModal'
import DocumentEditorModal from '../../components/Modals/DocumentEditorModal'
import DocDisplaySettingsModal from '../../components/Modals/DocDisplaySettingsModal'
import FileSelectorButton from '../../components/BaseComponents/Buttons/FileSelectorButton'
import { DocConfig, DocVersionConfig } from '../../models/forms'
import { SessionService, ConfigManagerService, DocServiceFactory } from '../../services'
import { PeopleFlowCombinedReducer } from '../../store'
import { ColorPalette } from '../../config/colors'
import { ActionType } from '../../store/actions/actions'
import { AllScreenNames } from '../../config'
import {
  filePicker,
  writeLocalFileToRemoteStorage,
  removeUnderscores,
  addUnderScores,
  toUpperCaseCustom,
  toLowerCaseCustom,
  getConfiguredProcessInfo,
} from '../../utils'
import { AssociationSettingsRepository, UserRepository } from '../../repositories'
import { FieldConfig, UpdateDocConfigParams } from '../../types'
import { getRemoteFile } from '../../provider/remoteData'
import { Toolbar } from '../../components/GeneralUI/Toolbar'

const columnConfig = [
  { id: 'docName', label: 'Name' },
  { id: 'lastEditedBy', label: 'Last Edited By' },
  { id: 'status', label: 'Status' },
  { id: 'versionStats', label: 'Version Summary' },
]

interface DocLibraryProps extends RouteComponentProps {
  associationRepo: AssociationSettingsRepository
  userRepo: UserRepository
  selectedAssociation: string
  idPassport: string
  password: string
  currentScreen: AllScreenNames
  currentSection: string
  classes: Record<string, string>
  hasWorkforceAccess: boolean

  updateState: (data: any) => void
  changeScreen: (screen: string) => void
}

interface DocLibraryState {
  loadingModalOpen: boolean
  warningModalOpen: boolean
  newFormModalOpen: boolean
  fileSelectionModalOpen: boolean
  documentEditorModalOpen: boolean
  docDisplaySettingsModalOpen: boolean
  updatePdfTemplateModalOpen: boolean
  loadingModalMessage: string
  warningModalHeader: string
  warningModalMessage: string
  sideMenuComponents: JSX.Element
  downloading: boolean
  sideMenuVisible: boolean
  editModeEnabled: boolean
  shouldCreateNewDoc: boolean
  selectedDocStatus: string
  selectedDocVersion: string
  docConfig: {}
  docListData: {
    displaySettings: any
    id: string
    docName: string
    drafts: Record<string, any>
    depublished?: Record<string, any>
    published?: Record<string, any>
  }[]
  authorisedItemIds: string[]
  selectedFormIndex: number
  tableWidth: GridSize
  selectedRowItemId: string
  searchString: string
  selectedDocName: string
  newDocVersion: string
  selectedDocUrl: string
  formatWarning: string
  selectedDocConfig: any
  formFieldConfig: any
  allFieldConfig: any
  processConfig: any
  docPortalConfig: any
  selectedDisplaySettings: any
  allClientUsers: any
  sideMenuRefreshTimestamp: number
  cloningDoc: boolean
}

class DocLibrary extends Component<DocLibraryProps, DocLibraryState> {
  sectionHeaderRef: React.RefObject<SectionHeaderComponent>
  primaryTableRef: React.RefObject<DataTable>

  constructor(props: DocLibraryProps) {
    super(props)
    this.sectionHeaderRef = React.createRef()
    this.primaryTableRef = React.createRef()
  }

  DocsService = DocServiceFactory.create()

  initialModalState = {
    loadingModalOpen: false,
    warningModalOpen: false,
    newFormModalOpen: false,
    fileSelectionModalOpen: false,
    documentEditorModalOpen: false,
    docDisplaySettingsModalOpen: false,
    updatePdfTemplateModalOpen: false,
  }

  state: DocLibraryState = {
    ...this.initialModalState,
    loadingModalMessage: '',
    warningModalHeader: '',
    warningModalMessage: '',
    sideMenuComponents: <div />,
    downloading: false,
    sideMenuVisible: false,
    editModeEnabled: false,
    shouldCreateNewDoc: false,
    selectedDocStatus: 'drafts',
    selectedDocVersion: '',
    docConfig: {},
    docListData: [],
    authorisedItemIds: [],
    selectedFormIndex: 0,
    tableWidth: 10,
    selectedRowItemId: '',
    searchString: '',
    selectedDocName: '',
    newDocVersion: '',
    selectedDocUrl: '',
    formatWarning: '',
    selectedDocConfig: {},
    formFieldConfig: {},
    allFieldConfig: {},
    processConfig: {},
    docPortalConfig: {},
    selectedDisplaySettings: {},
    allClientUsers: [],
    sideMenuRefreshTimestamp: 0,
    cloningDoc: false,
  }

  async componentDidMount() {
    try {
      this.props.updateState({ currentSection: AllScreenNames.FORMS, currentScreen: AllScreenNames.FORMS })
      let { associationRepo, selectedAssociation } = this.props

      const fieldConfig = associationRepo.getFieldConfig(selectedAssociation)
      const docConfig = associationRepo.getDocConfig(selectedAssociation)
      const processConfig = associationRepo.getProcessConfig(selectedAssociation)
      const allClientUsers = associationRepo.getAllAssociationUsers(selectedAssociation)

      this.setState({ allFieldConfig: fieldConfig, allClientUsers, processConfig })
      this.reloadTable(docConfig)
    } catch (error) {
      this.displayWarning(error)
    }
  }

  closeModals = () => {
    this.setState({ ...this.initialModalState })
  }

  searchHandler = (event: React.ChangeEvent<{ value: string }>) => {
    this.setState({ searchString: event.target.value })
    this.primaryTableRef?.current?.search(event.target.value)
  }

  updatePdfTemplate = () => {
    this.setState({ documentEditorModalOpen: false, updatePdfTemplateModalOpen: true })
  }

  clonePdfTemplate = () => {
    this.setState({
      documentEditorModalOpen: false,
      newFormModalOpen: true,
      cloningDoc: true,
    })
  }

  uploadNewPdfTemplate = async (event: any) => {
    this.setState({
      ...this.initialModalState,
      loadingModalOpen: true,
      loadingModalMessage: 'Uploading template...',
      formatWarning: '',
      documentEditorModalOpen: false,
    })
    const { selectedAssociation } = this.props
    const { selectedDocConfig, selectedDocName, selectedDocVersion } = this.state
    const filePath = `Companies/${selectedAssociation}/Documents/Templates/${selectedDocName}/${selectedDocVersion}___${selectedDocName}.pdf`
    const { file, type } = filePicker(event)[0]
    await writeLocalFileToRemoteStorage(filePath, file, type)

    this.reloadTable(selectedDocConfig)
    setTimeout(() => this.selectForm(removeUnderscores(selectedDocName)), 500)
    setTimeout(() => this.loadDocVersion('drafts', selectedDocVersion), 1000)
  }

  docNameHasSlash(docName: string) {
    return docName.includes('/')
  }

  addNewDoc(newDocName: string) {
    try {
      if (this.docNameHasSlash(newDocName)) {
        throw { code: 'InvalidDocName' }
      }

      newDocName = toUpperCaseCustom(addUnderScores(newDocName))
      if (newDocName in this.state.selectedDocConfig) {
        throw { code: 'AlreadyExists' }
      }

      this.setState({
        ...this.initialModalState,
        fileSelectionModalOpen: true,
        shouldCreateNewDoc: true,
        selectedDocName: newDocName,
      })
    } catch (error) {
      this.displayWarning(error)
    }
  }

  cloneDoc = async (newDocName: string) => {
    try {
      if (this.docNameHasSlash(newDocName)) {
        throw { code: 'InvalidDocName' }
      }
      this.setState({ newFormModalOpen: false, loadingModalOpen: true, loadingModalMessage: 'Cloning...' })
      let { selectedDocConfig, selectedDocName, selectedDocVersion } = this.state
      const { selectedAssociation, associationRepo, userRepo } = this.props
      const user = userRepo.getCurrentUserEntity()
      const author = user.getFullName()
      const newDocVersion = `${new Date().getTime()}`

      newDocName = toUpperCaseCustom(addUnderScores(newDocName))
      if (newDocName in this.state.selectedDocConfig) {
        throw { code: 'AlreadyExists' }
      }
      const { idPassport, password } = this.props
      const token = await SessionService.prepareAuthTokens(idPassport, password)
      const docConfig = await this.DocsService.updateDocConfig(
        {
          author,
          action: 'CLONE_DOC',
          selectedAssociation,
          docConfig: selectedDocConfig,
          docName: newDocName,
          newVersion: newDocVersion,
          sourceDocName: selectedDocName,
          sourceDocVersion: selectedDocVersion,
        } as UpdateDocConfigParams,
        token,
      )
      associationRepo.setDocConfig(selectedAssociation, docConfig)
      this.reloadTable(docConfig)

      setTimeout(() => {
        this.selectForm(removeUnderscores(newDocName))
        this.refreshSideMenu()
      }, 500)
      setTimeout(() => {
        this.loadDocVersion('drafts', newDocVersion)
      }, 1000)

      this.setState({ ...this.initialModalState, cloningDoc: false })
    } catch (error) {
      this.displayWarning(error)
    }
  }

  addDraft = async (event: any) => {
    try {
      let { selectedDocConfig, selectedDocName, shouldCreateNewDoc } = this.state
      const { idPassport, password, selectedAssociation, userRepo } = this.props
      const user = userRepo.getCurrentUserEntity()
      const author = user.getFullName()
      const { file, type } = filePicker(event)[0]
      const newDocVersion = `${new Date().getTime()}`

      if (type === 'application/pdf') {
        this.setState({
          ...this.initialModalState,
          loadingModalOpen: true,
          loadingModalMessage: 'Uploading template...',
          newDocVersion,
          formatWarning: '',
        })

        if (shouldCreateNewDoc) {
          selectedDocConfig[selectedDocName] = new DocConfig(selectedDocName, author, newDocVersion, 1)
        } else {
          selectedDocConfig[selectedDocName].drafts[newDocVersion] = new DocVersionConfig(newDocVersion, author, 1)
        }

        const filePath = `Companies/${selectedAssociation}/Documents/Templates/${selectedDocName}/${newDocVersion}___${selectedDocName}.pdf`
        await writeLocalFileToRemoteStorage(filePath, file, type)
        const token = await SessionService.prepareAuthTokens(idPassport, password)
        await this.DocsService.updateDocConfig(
          {
            action: shouldCreateNewDoc ? 'CREATE_NEW_DOC' : 'CREATE_NEW_VERSION',
            selectedAssociation,
            docConfig: selectedDocConfig,
            docName: selectedDocName,
            docVersion: `${newDocVersion}`,
          } as UpdateDocConfigParams,
          token,
        )

        this.setState({ shouldCreateNewDoc: false })
        this.reloadTable(selectedDocConfig)

        setTimeout(() => this.selectForm(removeUnderscores(selectedDocName)), 500)
        setTimeout(() => this.loadDocVersion('drafts', newDocVersion), 1000)
      } else {
        this.setState({ formatWarning: 'Make sure your file is in PDF format' })
      }
    } catch (error) {
      this.displayWarning(error)
    }
  }

  async selectForm(rowItemId: string) {
    this.sectionHeaderRef?.current?.disableSearch()
    const { docListData } = this.state
    let selectedFormIndex = 0
    for (let i = 0; i < docListData.length; i++) {
      if (docListData[i].id === rowItemId) {
        selectedFormIndex = i
        break
      }
    }
    const selectedDocName = addUnderScores(docListData[selectedFormIndex].docName)
    const sideMenuComponents = (
      <FormSideMenu
        docName={selectedDocName}
        onDocSettingsClicked={() => this.onDocSettingsClicked()}
        published={docListData[selectedFormIndex].published}
        drafts={docListData[selectedFormIndex].drafts}
        depublished={docListData[selectedFormIndex].depublished}
        onVersionClicked={(sds: string, sdv: string) => this.loadDocVersion(sds, sdv)}
        addDraft={() => this.setState({ fileSelectionModalOpen: true })}
      />
    )
    this.setState({
      sideMenuVisible: true,
      sideMenuComponents,
      tableWidth: 9,
      selectedRowItemId: rowItemId,
      selectedFormIndex,
      selectedDocName,
    })
  }

  async loadDocVersion(selectedDocStatus: string, selectedDocVersion: string) {
    try {
      this.setState({
        ...this.initialModalState,
        loadingModalOpen: true,
        loadingModalMessage: 'Fetching configuration...',
      })
      const { selectedAssociation } = this.props
      const { selectedDocConfig, selectedDocName } = this.state
      const { fieldConfig } = selectedDocConfig[selectedDocName][selectedDocStatus][selectedDocVersion]
      let filePath = `Companies/${selectedAssociation}/Documents/Templates/${selectedDocName}/${selectedDocVersion}___${selectedDocName}.pdf`
      let docUrl = await getRemoteFile(filePath, { download: true, cacheControl: 'no-cache' })
      docUrl = new Blob([docUrl.Body], { type: 'application/pdf' })
      docUrl = URL.createObjectURL(docUrl)

      if (fieldConfig) {
        this.setState({
          ...this.initialModalState,
          documentEditorModalOpen: true,
          editModeEnabled: toLowerCaseCustom(selectedDocStatus) === 'drafts' ? true : false,
          selectedDocStatus,
          selectedDocVersion,
          selectedDocUrl: docUrl,
          formFieldConfig: fieldConfig,
        })
      } else {
        this.displayWarning({ code: 'InvalidDocConfig' })
      }
    } catch (error) {
      this.displayWarning(error)
    }
  }

  onDocSettingsClicked() {
    const { docListData, selectedFormIndex } = this.state
    const selectedDisplaySettings = docListData[selectedFormIndex].displaySettings
    this.setState({
      ...this.initialModalState,
      docDisplaySettingsModalOpen: true,
      selectedDisplaySettings,
    })
  }

  async saveDisplaySettings(displaySettings: any, processConfig: any) {
    try {
      let { selectedDocConfig, selectedDocName } = this.state
      selectedDocConfig[selectedDocName].displaySettings = displaySettings

      const { idPassport, password, selectedAssociation } = this.props
      this.setState({
        ...this.initialModalState,
        loadingModalOpen: true,
        loadingModalMessage: 'Saving display settings...',
      })
      const token = await SessionService.prepareAuthTokens(idPassport, password)
      await this.DocsService.updateDocConfig(
        {
          action: 'UPDATE_DISPLAY_SETTINGS',
          selectedAssociation,
          docConfig: selectedDocConfig,
          docName: selectedDocName,
          processConfig,
        } as UpdateDocConfigParams,
        token,
      )
      this.reloadTable(selectedDocConfig)
      this.closeModals()
    } catch (error) {
      this.displayWarning(error)
    }
  }

  reloadTable = (selectedDocConfig: any = {}) => {
    if (this.primaryTableRef && this.primaryTableRef.current) {
      let authorisedItemIds: string[] = []
      const docListData = Object.keys(selectedDocConfig).map((docName) => {
        const listItem = this.packageFormItem(selectedDocConfig[docName])
        authorisedItemIds.push(listItem.id as string)
        return listItem
      })
      this.setState(
        {
          docListData,
          selectedDocConfig,
          authorisedItemIds,
        },
        () => this.primaryTableRef?.current?.reload(),
      )
    } else if (this.props.currentSection === 'Forms') {
      setTimeout(this.reloadTable, 250)
    }
  }

  packageFormItem(selectedDocConfig: any) {
    const publishedVersions = Object.keys(selectedDocConfig.published)
    const draftsVersions = Object.keys(selectedDocConfig.drafts)
    const depublishedVersions = Object.keys(selectedDocConfig.depublished)

    if (publishedVersions.length > 0) {
      selectedDocConfig.status = 'Published'
      selectedDocConfig.lastEditedBy = selectedDocConfig.published[publishedVersions[0]].lastEditedBy
        ? selectedDocConfig.published[publishedVersions[0]].lastEditedBy
        : selectedDocConfig.published[publishedVersions[0]].author
    } else if (depublishedVersions.length > 0) {
      selectedDocConfig.status = 'Depublished'
      selectedDocConfig.lastEditedBy = selectedDocConfig.depublished[depublishedVersions[0]].lastEditedBy
        ? selectedDocConfig.depublished[depublishedVersions[0]].lastEditedBy
        : selectedDocConfig.depublished[depublishedVersions[0]].author
    } else if (draftsVersions.length > 0) {
      selectedDocConfig.status = 'Draft'
      selectedDocConfig.lastEditedBy = selectedDocConfig.drafts[draftsVersions[0]].lastEditedBy
        ? selectedDocConfig.drafts[draftsVersions[0]].lastEditedBy
        : selectedDocConfig.drafts[draftsVersions[0]].author
    }

    selectedDocConfig.versionStats = `${draftsVersions.length} ${
      draftsVersions.length > 1 ? 'drafts' : 'draft'
    } \xa0 | \xa0 ${depublishedVersions.length} depublished`
    selectedDocConfig.docName = removeUnderscores(selectedDocConfig.docName)
    selectedDocConfig.id = selectedDocConfig.docName
    return selectedDocConfig
  }

  async updateFieldConfig(fieldConfig: FieldConfig) {
    const { idPassport, password, selectedAssociation, associationRepo } = this.props
    const changes = [
      {
        editedBy: idPassport,
        updatePath: [],
        updatedData: fieldConfig,
        updatedMs: +new Date(),
      },
    ]
    const response = await ConfigManagerService.updateConfig(this.props.selectedAssociation, 'fieldConfig', changes, {
      username: idPassport,
      password,
    })
    if (response.result === 'success') {
      associationRepo.setFieldConfig(selectedAssociation, fieldConfig)
      return
    }
    console.error('Failed to update field config')
  }

  async saveNewDocumentSettings(
    formFieldConfig: Record<string, any>,
    allFieldConfig: Record<string, any>,
    action: string,
  ): Promise<void> {
    try {
      this.setState({
        ...this.initialModalState,
        loadingModalOpen: true,
        loadingModalMessage: action === 'copyToDrafts' ? 'Copying to drafts...' : 'Saving field updates...',
      })
      let { idPassport, password, selectedAssociation, associationRepo } = this.props
      let { selectedDocConfig, selectedDocName, selectedDocStatus, selectedDocVersion } = this.state

      for (const fieldId of Object.keys(formFieldConfig)) {
        if (!formFieldConfig[fieldId].key && !formFieldConfig[fieldId].signatureStyle) {
          delete formFieldConfig[fieldId]
        }
      }
      selectedDocConfig[selectedDocName][selectedDocStatus][selectedDocVersion].fieldConfig = formFieldConfig

      // this.props.updateState({ associationRepo });
      const token = await SessionService.prepareAuthTokens(idPassport, password)
      const docConfig = await this.DocsService.updateDocConfig(
        {
          allFieldConfig,
          action: 'UPDATE_FIELD_CONFIG',
          selectedAssociation,
          docConfig: selectedDocConfig,
          docName: selectedDocName,
          docVersion: selectedDocVersion,
          docStatus: selectedDocStatus,
        } as UpdateDocConfigParams,
        token,
      )
      switch (action) {
        case 'toggleDocStatus':
          const targetDocStatus =
            selectedDocStatus === 'drafts' || selectedDocStatus === 'depublished' ? 'published' : 'depublished'

          await this.updateDocStatus(
            selectedDocConfig,
            selectedDocName,
            selectedDocVersion,
            selectedDocStatus,
            targetDocStatus,
          )
          this.setState({ sideMenuRefreshTimestamp: Date.now() })
          break
        case 'copyToDrafts':
          await this.copyToDrafts(selectedDocConfig, selectedDocName, selectedDocVersion, selectedDocStatus, 'drafts')
          this.setState({ sideMenuRefreshTimestamp: Date.now() })
          break
        default: // catches saveAndClose action too
          this.reloadTable(selectedDocConfig)
          this.closeModals()
      }
      const formFieldConfigKeys = Object.values(formFieldConfig).map((field: any) => field.key)
      const keysNotInAllFieldConfig = formFieldConfigKeys.filter((key: string) => {
        return !allFieldConfig.hasOwnProperty(key)
      })
      if (keysNotInAllFieldConfig.length > 0) {
        await this.updateFieldConfig(allFieldConfig)
      }
      associationRepo.setDocConfig(selectedAssociation, docConfig)
      this.props.updateState({ associationRepo })
      this.setState({ formFieldConfig, allFieldConfig })
    } catch (error) {
      console.error('saveNewDocumentSettings() error: ', error)
      this.displayWarning(error)
      return
    }
  }

  refreshSideMenu = () => {
    this.setState({ sideMenuRefreshTimestamp: Date.now() })
  }

  updateDocStatus = async (
    selectedDocConfig: Record<string, any>,
    docName: string,
    version: string,
    sourceDocStatus: string,
    targetDocStatus: string,
  ) => {
    this.setState({
      ...this.initialModalState,
      loadingModalOpen: true,
      loadingModalMessage: targetDocStatus === 'published' ? 'Publishing...' : 'Depublishing...',
    })
    const { idPassport, password, selectedAssociation } = this.props
    const token = await SessionService.prepareAuthTokens(idPassport, password)
    await this.DocsService.updateDocConfig(
      {
        action: 'UPDATE_DOC_STATUS',
        selectedAssociation,
        docName,
        docVersion: version,
        docStatus: sourceDocStatus,
        targetDocStatus,
      } as UpdateDocConfigParams,
      token,
    )
    if (targetDocStatus === 'published') {
      const currentPublishedVersions = Object.keys(selectedDocConfig[docName][targetDocStatus])
      if (currentPublishedVersions.length) {
        selectedDocConfig[docName].depublished[currentPublishedVersions[0]] =
          selectedDocConfig[docName].published[currentPublishedVersions[0]]
        delete selectedDocConfig[docName].published[currentPublishedVersions[0]]
      }
    }
    selectedDocConfig[docName][targetDocStatus][version] = selectedDocConfig[docName][sourceDocStatus][version]
    delete selectedDocConfig[docName][sourceDocStatus][version]
    if (targetDocStatus === 'depublished') {
      this.unlinkDepublishedDocNameFromProcesses(docName)
    }
    this.reloadTable(selectedDocConfig)
    this.closeModals()
  }

  saveUpdatedProcessConfig = (processConfig: any, processName: string, updatePath: string[], docNames: string[]) => {
    const { selectedAssociation, updateState, associationRepo } = this.props
    const updatedProcessObj = pick(processConfig, processName)
    associationRepo.setProcessConfigItem(selectedAssociation, updatedProcessObj)
    updateState({ associationRepo })
    return this.saveDocNamesToProcessConfig(updatePath, docNames)
  }

  async saveDocNamesToProcessConfig(updatePath: string[], docNames: string[]) {
    const { idPassport, password, selectedAssociation } = this.props
    const changes = [
      {
        editedBy: idPassport,
        updatePath,
        updatedData: docNames,
        updatedMs: +new Date(),
      },
    ]
    return ConfigManagerService.updateConfig(selectedAssociation, 'processConfig', changes, {
      username: idPassport,
      password,
    })
  }

  unlinkDocNameFromEventConfig = async (
    segmentInfo: Record<string, string>,
    docNamesNotMatchingOneToUnlink: (docName: string) => boolean,
  ) => {
    const { processConfig } = this.state
    const processName = 'eventConfig'
    const eventTypes = Object.keys(processConfig[processName].docCaptureConfig)
    for (const eventType of eventTypes) {
      segmentInfo.eventType = eventType
      const docNames = getConfiguredProcessInfo('docNames', processName, segmentInfo, processConfig)
      let updatedProcessConfig = cloneDeep(processConfig)
      const updatedDocNames = docNames.filter(docNamesNotMatchingOneToUnlink)
      updatedProcessConfig[processName].docCaptureConfig[eventType].docNames = updatedDocNames
      this.setState({ processConfig: updatedProcessConfig })
      await this.saveUpdatedProcessConfig(
        updatedProcessConfig,
        processName,
        [processName, 'docCaptureConfig', eventType, 'docNames'],
        updatedDocNames,
      )
    }
  }

  unlinkDocNameFromDiscipline = async (
    segmentInfo: Record<string, string>,
    docNamesNotMatchingOneToUnlink: (docName: string) => boolean,
  ) => {
    const { processConfig } = this.state
    const { division, jobType, jobSubType } = segmentInfo
    const processName = 'discipline'
    const offences = Object.keys(processConfig[processName].docCaptureConfig[division][jobType][jobSubType])
    for (const offence of offences) {
      segmentInfo.offence = offence
      const actions = Object.keys(processConfig[processName].docCaptureConfig[division][jobType][jobSubType][offence])
      for (const action of actions) {
        segmentInfo.action = action
        const docNames = getConfiguredProcessInfo('docNames', processName, segmentInfo, processConfig)
        let updatedProcessConfig = cloneDeep(processConfig)
        const updatedDocNames = docNames.filter(docNamesNotMatchingOneToUnlink)
        updatedProcessConfig[processName]['docCaptureConfig'][division][jobType][jobSubType][offence][action][
          'docNames'
        ] = updatedDocNames
        this.setState({ processConfig: updatedProcessConfig })
        await this.saveUpdatedProcessConfig(
          updatedProcessConfig,
          processName,
          [processName, 'docCaptureConfig', division, jobType, jobSubType, offence, action, 'docNames'],
          updatedDocNames,
        )
      }
    }
  }

  unlinkDocNameFromTermination = async (
    segmentInfo: Record<string, string>,
    docNamesNotMatchingOneToUnlink: (docName: string) => boolean,
  ) => {
    const { processConfig } = this.state
    const { division, jobType, jobSubType } = segmentInfo
    const processName = 'termination'
    const terminationReasons = Object.keys(processConfig[processName].docCaptureConfig[division][jobType][jobSubType])
    for (const terminationReason of terminationReasons) {
      segmentInfo.reasonForTermination = terminationReason
      const subReasons = Object.keys(
        processConfig[processName].docCaptureConfig[division][jobType][jobSubType][terminationReason],
      )
      for (const subReason of subReasons) {
        segmentInfo.subReasonForTermination = subReason
        const docNames = getConfiguredProcessInfo('docNames', processName, segmentInfo, processConfig)
        let updatedProcessConfig = cloneDeep(processConfig)
        const updatedDocNames = docNames.filter(docNamesNotMatchingOneToUnlink)
        updatedProcessConfig[processName]['docCaptureConfig'][division][jobType][jobSubType][terminationReason][
          subReason
        ]['docNames'] = updatedDocNames
        this.setState({ processConfig: updatedProcessConfig })
        await this.saveUpdatedProcessConfig(
          updatedProcessConfig,
          processName,
          [processName, 'docCaptureConfig', division, jobType, jobSubType, terminationReason, subReason, 'docNames'],
          updatedDocNames,
        )
      }
    }
  }

  unlinkDocNamFromProcess = async (
    processName: string,
    segmentInfo: Record<string, string>,
    docNamesNotMatchingOneToUnlink: (docName: string) => boolean,
  ) => {
    const { processConfig } = this.state
    const { division, jobType, jobSubType } = segmentInfo
    const docNames = getConfiguredProcessInfo('docNames', processName, segmentInfo, processConfig)
    let updatedProcessConfig = cloneDeep(processConfig)
    const updatedDocNames = docNames.filter(docNamesNotMatchingOneToUnlink)
    updatedProcessConfig[processName]['docCaptureConfig'][division][jobType][jobSubType]['docNames'] = updatedDocNames
    this.setState({ processConfig: updatedProcessConfig })
    await this.saveUpdatedProcessConfig(
      updatedProcessConfig,
      processName,
      [processName, 'docCaptureConfig', division, jobType, jobSubType, 'docNames'],
      updatedDocNames,
    )
  }

  unlinkDepublishedDocNameFromProcesses = (docNameToUnlink: string) => {
    const { processConfig } = this.state
    const docNamesNotMatchingOneToUnlink = (docName: string) => docName !== docNameToUnlink
    Object.keys(processConfig).forEach(async (processName: string) => {
      // iterate over processConfig keys (excluding competencies) and remove depublished doc from docNames
      if (!processName.includes('competencies')) {
        let segmentInfo = {
          division: '',
          jobType: '',
          jobSubType: '',
          reasonForTermination: '',
          subReasonForTermination: '',
          eventType: '',
          action: '',
          offence: '',
        }
        if (processName === 'eventConfig') {
          await this.unlinkDocNameFromEventConfig(segmentInfo, docNamesNotMatchingOneToUnlink)
        } else {
          const divisions = Object.keys(processConfig[processName].docCaptureConfig)
          for (const division of divisions) {
            segmentInfo.division = division
            const jobTypes = Object.keys(processConfig[processName].docCaptureConfig[division])
            for (const jobType of jobTypes) {
              segmentInfo.jobType = jobType
              const jobSubTypes = Object.keys(processConfig[processName].docCaptureConfig[division][jobType])
              for (const jobSubType of jobSubTypes) {
                segmentInfo.jobSubType = jobSubType
                if (processName === 'discipline') {
                  await this.unlinkDocNameFromDiscipline(segmentInfo, docNamesNotMatchingOneToUnlink)
                } else if (processName === 'termination') {
                  await this.unlinkDocNameFromTermination(segmentInfo, docNamesNotMatchingOneToUnlink)
                } else {
                  await this.unlinkDocNamFromProcess(processName, segmentInfo, docNamesNotMatchingOneToUnlink)
                }
              }
            }
          }
        }
      }
    })
  }

  async copyToDrafts(
    selectedDocConfig: Record<string, any>,
    docName: string,
    version: string,
    sourceDocStatus: string,
    targetDocStatus: string,
  ): Promise<void> {
    this.setState({
      ...this.initialModalState,
      loadingModalOpen: true,
      loadingModalMessage: 'Copying to drafts...',
    })
    const { idPassport, password, selectedAssociation } = this.props
    const newVersion = `${new Date().getTime()}`
    const token = await SessionService.prepareAuthTokens(idPassport, password)
    await this.DocsService.updateDocConfig(
      {
        action: 'COPY_TO_DRAFTS',
        selectedAssociation,
        docName,
        docVersion: version,
        docStatus: sourceDocStatus,
        targetDocStatus,
        newVersion,
      } as UpdateDocConfigParams,
      token,
    )
    selectedDocConfig[docName].drafts[newVersion] = selectedDocConfig[docName][sourceDocStatus][version]

    this.reloadTable(selectedDocConfig)
    this.closeModals()
  }

  displayWarning(error: any) {
    let warning = ''

    try {
      if (error.code === 'NetworkError') {
        warning = 'Seems like your internet connection is down. Reconnect to the network, then try again.'
      } else if (error.code === 'InvalidDocName') {
        warning = 'The document name you entered is invalid. Please try again. (Hint: no slashes allowed)'
      } else if (error.code === 'AlreadyExists') {
        warning = 'There is already a form with that name.'
      } else if (error.code === 'InvalidDocConfig') {
        warning = "This document isn't configured correctly. Reach out to PeopleFlow tech support for assistance."
      } else if (error.code === 'FetchDocConfigsError') {
        warning =
          'There was a problem fetching your document templates from the database. Reach out to PeopleFlow tech support for assistance.'
      } else if (error.code === 'UpdateDocConfigError') {
        warning = 'There was a problem updating your document template. Refresh that page and try again.'
      } else if ('message' in error) {
        warning =
          "The following error message was returned:\n\n'" +
          error.message +
          "'. \n\nRefresh the page and try again. If unsuccessful, then contact tech support"
      } else {
        warning = 'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
      }
    } catch (error) {
      warning = 'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
    }

    this.setState({
      ...this.initialModalState,
      warningModalOpen: true,
      warningModalHeader: 'Warning',
      warningModalMessage: warning,
    })
  }

  render() {
    let documentEditerModal = null
    if (this.state.documentEditorModalOpen) {
      documentEditerModal = (
        <DocumentEditorModal
          clonePdfTemplate={this.clonePdfTemplate}
          docName={this.state.selectedDocName}
          document={{ url: this.state.selectedDocUrl }}
          docStatus={this.state.selectedDocStatus}
          formFieldConfig={this.state.formFieldConfig}
          allFieldConfig={this.state.allFieldConfig}
          allClientUsers={this.state.allClientUsers}
          editModeEnabled={this.state.editModeEnabled}
          dismiss={() => this.closeModals()}
          save={(formFieldConfig: Record<string, any>, allFieldConfig: Record<string, string>, action: string) =>
            this.saveNewDocumentSettings(formFieldConfig, allFieldConfig, action)
          }
          updatePdfTemplate={this.updatePdfTemplate}
        />
      )
    }

    let docDisplaySettingsModal = null
    if (this.state.docDisplaySettingsModalOpen) {
      docDisplaySettingsModal = (
        <DocDisplaySettingsModal
          processConfig={this.state.processConfig}
          docName={this.state.selectedDocName}
          displaySettings={this.state.selectedDisplaySettings}
          dismiss={() => this.closeModals()}
          saveAndClose={(displaySettings, processConfig) => this.saveDisplaySettings(displaySettings, processConfig)}
        />
      )
    }

    return (
      <div style={styles.container}>
        <NavigationBar
          history={this.props.history}
          match={this.props.match}
          location={this.props.location}
          reloadPageData={() => {
            this.setState({ searchString: '' })
            this.reloadTable({})
          }}
        />
        <SectionHeader
          ref={this.sectionHeaderRef}
          style={styles.sectionHeader}
          downloading={this.state.downloading}
          searchString={this.state.searchString}
          textHandler={(e) => this.searchHandler(e)}
          onClick={() => this.setState({ sideMenuVisible: false, tableWidth: 10 })}>
          {this.props.currentScreen}
        </SectionHeader>

        <div style={styles.contentContainer}>
          <SideMenu
            visible={this.state.sideMenuVisible}
            menuComponents={this.state.sideMenuComponents}
            key={`sideMenu_${this.props.selectedAssociation}_${this.state.sideMenuRefreshTimestamp}`}
          />
          <div
            style={{
              ...styles.rightSide,
              paddingInline: this.state.sideMenuVisible ? 'max(2em, 2%)' : 'min(5em, 5%)',
            }}>
            <Toolbar
              actionButtons={[
                {
                  label: 'NEW FORM',
                  iconPath: mdiPlus,
                  onClick: () => this.setState({ newFormModalOpen: true, sideMenuVisible: false }),
                },
              ]}
            />
            <div style={styles.rightSideContent}>
              <DataTable
                ref={this.primaryTableRef}
                tableData={this.state.docListData}
                columnConfig={columnConfig}
                tableWidth={this.state.tableWidth}
                onRowClick={(selectedrow: Record<string, any>) => this.selectForm(selectedrow.id)}
                selectedRowItemId={this.state.selectedRowItemId}
                authorisedItemIds={this.state.authorisedItemIds}
                key={`dataTable_${this.props.selectedAssociation}`}
              />
            </div>
          </div>
        </div>

        {documentEditerModal}
        {docDisplaySettingsModal}

        <AlertModalOneButton
          open={this.state.warningModalOpen}
          header={this.state.warningModalHeader}
          body={this.state.warningModalMessage}
          buttonLabel={'Ok'}
          onClick={() => this.closeModals()}
        />
        <AlertModalOneButton
          open={!this.props.hasWorkforceAccess}
          header={'Not Authorised'}
          body={"You don't have permission to view employee/candidate info."}
          buttonLabel={'Ok'}
          opaqueBackground={true}
          onClick={() => this.props.history.goBack()}
        />
        <LabelCollector
          open={this.state.newFormModalOpen}
          warning={'Enter new form name'}
          placeholder={'Enter new form name'}
          buttonLabel={'Create'}
          iconName={'docs'}
          dismiss={() => this.closeModals()}
          submit={(newDocName: string) =>
            this.state.cloningDoc ? this.cloneDoc(newDocName) : this.addNewDoc(newDocName)
          }
        />
        <OptionsButtonModal
          open={this.state.fileSelectionModalOpen || this.state.updatePdfTemplateModalOpen}
          header={this.state.fileSelectionModalOpen ? 'Add template' : 'Upload new PDF'}
          body={
            this.state.fileSelectionModalOpen
              ? 'Choose a PDF document to be completed. It must portrait and A4 (210mm x 297mm).'
              : 'Choose a new PDF document. It must portrait and A4 (210mm x 297mm).'
          }
          buttonConfig={[
            <FileSelectorButton
              buttonStyle={{ width: window.innerWidth * 0.25, height: window.innerHeight * 0.2 }}
              id="AddFile"
              buttonLabel="Add"
              fileHandler={
                this.state.fileSelectionModalOpen ? (e) => this.addDraft(e) : (e) => this.uploadNewPdfTemplate(e)
              }
            />,
          ]}
          buttonLabel={'Cancel'}
          warning={this.state.formatWarning}
          onClick={() => this.closeModals()}
        />
        <LoadingModal open={this.state.loadingModalOpen}>{this.state.loadingModalMessage}</LoadingModal>
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255, 1), rgba(209,210,230, 1))',
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto 1.5%',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    width: '100%',
  },
  rightSideContent: {
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    display: 'flex',
    flex: 1,
    backgroundColor: ColorPalette.CARD_WHITE,
  },
  tableName: {
    marginTop: window.innerHeight * 0.055,
    height: window.innerHeight * 0.7,
    width: '94%',
  },
  newFormButton: {
    fontWeight: 'bolder',
    fontSize: '0.8rem',
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    ':hover': {
      color: ColorPalette.PRIMARY_BLUE,
    },
    ':active': {
      color: ColorPalette.DARK_GREY,
    },
  },
}

const mapStateToProps = (state: PeopleFlowCombinedReducer) => {
  return {
    idPassport: state.sessionManager.idPassport,
    password: state.sessionManager.password,
    associationRepo: state.sessionManager.associationRepo as AssociationSettingsRepository,
    userRepo: state.sessionManager.userRepo as UserRepository,
    selectedAssociation: state.sessionManager.selectedAssociation,
    currentSection: state.sessionManager.currentSection,
    currentScreen: state.sessionManager.currentScreen,
    selectedFormIndex: state.sessionManager.selectedFormIndex,
    hasWorkforceAccess: state.sessionManager.hasWorkforceAccess,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateState: (data: any) => dispatch({ type: ActionType.UPDATE_STATE, data }),
    changeScreen: (screen: string) => dispatch({ type: ActionType.CHANGE_SCREEN, data: { currentScreen: screen } }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Radium(DocLibrary))
