import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import Table from '@material-ui/core/Table'
import TableContainer from '@material-ui/core/TableContainer'

import TablePagination from '@material-ui/core/TablePagination'
import { ColorPalette } from '../../../config'
import LoadingModal from '../../Modals/LoadingModal'
import { SimpleTableHeader } from './SimpleTableHeader'
import { SimpleTableBody } from './SimpleTableBody'

import '../../../assets/css/scrollbar.css'

type SimpleTableProps = {
  columns: string[]
  rows: Record<string, string>[]
  stickyFirstColumn?: boolean
  onRowClick?: (rowData: Record<string, string>) => void
}

const rowsPerPage = 50

export const SimpleTable = (props: SimpleTableProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const tableHeightRef = useRef<number>(0)
  const [loadingModalOpen, setLoadingModalOpen] = useState(true)
  const [currentPage, setCurrentPage] = useState(0)
  const [pageData, setPageData] = useState<Record<string, string>[]>(props.rows)

  useEffect(() => {
    loadPage(currentPage)
  }, [])

  useLayoutEffect(() => {
    tableHeightRef.current = containerRef.current?.clientHeight ?? 0
    setLoadingModalOpen(false)
  }, [])

  const createPageBatch = (data: any[], pageNum: number): { pageData: any[]; numberOfPages: number } => {
    const numberOfPages = Math.ceil(data.length / rowsPerPage)
    const pageData = data.slice((pageNum - 1) * rowsPerPage, pageNum * rowsPerPage)
    return { pageData, numberOfPages }
  }

  const muiToPfPageNumber = (zeroBasedPageNumber: number) => zeroBasedPageNumber + 1

  const loadPage = (page: number): void => {
    page = muiToPfPageNumber(page)
    const pageResult = createPageBatch(props.rows, page)
    setPageData(pageResult.pageData)
    setCurrentPage(page)
  }

  let table = null
  const tableHeight = tableHeightRef.current
  if (tableHeight > 0) {
    table = (
      <>
        <TableContainer className="scrollbar" style={{ height: tableHeight - 55 }}>
          <Table aria-label="simple table" stickyHeader>
            <SimpleTableHeader columns={props.columns} stickyFirstColumn={props.stickyFirstColumn} />
            <SimpleTableBody
              rows={pageData}
              stickyFirstColumn={props.stickyFirstColumn}
              onRowClick={props.onRowClick}
            />
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={props.rows.length}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
          page={currentPage - 1}
          onChangePage={(e, page) => loadPage(page)}
          style={styles.pagination}
        />
      </>
    )
  }

  return (
    <div ref={containerRef} style={{ width: '100%', flex: 'none' }}>
      {table}
      <LoadingModal open={loadingModalOpen}>Loading table data...</LoadingModal>
    </div>
  )
}

const styles = {
  pagination: {
    backgroundColor: ColorPalette.CARD_WHITE,
  },
}
