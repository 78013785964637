import React from 'react'
import Radium from 'radium'
import Icon from '@mdi/react'
import { mdiFilePlusOutline } from '@mdi/js'
import { ColorPalette } from '../../../config/colors'

export enum IFileSelectorButtonSize {
  LARGE = 'large',
  SMALL = 'small',
}

interface IFileSelectorButtonProps {
  id?: string | undefined
  photo?: boolean
  style?: React.CSSProperties | undefined
  buttonStyle?: React.CSSProperties | undefined
  size?: IFileSelectorButtonSize | undefined
  buttonLabel: string
  iconPath?: string
  fileName?: string
  onCancelSelection?: () => void
  fileHandler: (e: React.ChangeEvent<{ value: string }>) => void
}

const fileSelectorButton: React.FC<IFileSelectorButtonProps> = (props: IFileSelectorButtonProps) => {
  const handleFileSelection = (e: React.ChangeEvent<{ value: string }>) => {
    if (props.id) {
      const fileInput = document.getElementById(props.id)
      // @ts-expect-error - suppresses "Property 'value' does not exist on type 'HTMLElement' on next line
      if (fileInput.value.length > 0) {
        props.fileHandler(e)
        return
      }
      // this line will be reached if file selection was cancelled by the user
      if (props.onCancelSelection) {
        props.onCancelSelection()
      }
    } else {
      props.fileHandler(e)
    }
  }

  switch (props.size) {
    case IFileSelectorButtonSize.SMALL:
      return (
        <button style={{ ...styles.buttonContainerSmall, ...props.style }}>
          <label style={styles.labelContainer} htmlFor={props.id}>
            <Icon
              path={props.iconPath || mdiFilePlusOutline}
              color={ColorPalette.PRIMARY_TEXT}
              style={{ width: 24, height: 'auto' }}
            />
            <input type="file" style={styles.addFile} id={props.id} onChange={handleFileSelection} />
          </label>
        </button>
      )

    default:
      return (
        <button style={{ ...styles.buttonContainerLarge, ...props.style }}>
          <label style={styles.labelContainer} htmlFor={props.id}>
            <Icon
              path={props.iconPath || mdiFilePlusOutline}
              color={ColorPalette.PRIMARY_TEXT}
              style={styles.iconContainerLarge}
            />
            <h2 style={styles.instruction}>{props.buttonLabel}</h2>
            <h2 style={styles.subHeader}>{props.fileName}</h2>
            <input type="file" style={styles.addFile} id={props.id} onChange={handleFileSelection} />
          </label>
        </button>
      )
  }
}

const buttonContainer = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column' as 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  borderRadius: 12,
  backgroundColor: ColorPalette.CARD_WHITE,
  border: 'none',
  boxShadow: '0rem 0.1rem 0.4rem rgba(0, 0, 30, 0.2)',
  ':hover': { backgroundColor: ColorPalette.BUTTON_HOVER_GREY },
  ':focus': { outline: 0 },
  ':active': { backgroundColor: ColorPalette.BUTTON_ACTIVE_GREY },
}
const iconContainer = {
  color: ColorPalette.PRIMARY_TEXT,
  margin: 0,
}

let styles = {
  buttonContainerLarge: {
    ...buttonContainer,
    padding: 7,
    height: window.innerHeight * 0.55,
  },
  buttonContainerSmall: {
    ...buttonContainer,
    boxShadow: '0rem 0.1rem 0.3rem rgba(0, 0, 30, 0.2)',
    padding: 3,
    height: 60,
  },
  labelContainer: {
    display: 'flex',
    border: `1px dashed ${ColorPalette.SECONDARY_TEXT}`,
    borderRadius: 8,
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  iconContainerLarge: {
    ...iconContainer,
    height: 30,
    width: 30,
  },
  iconContainerSmall: {
    ...iconContainer,
    height: 20,
    width: 20,
  },
  addIcon: {
    color: 'rgba(60, 40, 150,1)',
    marginRight: 5,
    width: 18,
    height: 18,
  },
  addFile: {
    width: '0.1px',
    height: '0.1px',
    opacity: 0,
    position: 'absolute' as 'absolute',
    pointEvents: 'none',
  },
  instruction: {
    fontFamily: 'roboto',
    color: ColorPalette.PRIMARY_TEXT,
    fontSize: '0.8rem',
    fontWeight: 'normal',
  },
  subHeader: {
    fontFamily: 'roboto',
    color: ColorPalette.PRIMARY_TEXT,
    fontSize: '0.8rem',
    fontWeight: 'bold',
    margin: 0,
  },
}

export default Radium(fileSelectorButton)
