export function extractDocumentComponents(documentName: string): {
    timestamp: number;
    docFamily: string;
    docName: string;
} {
    const arr = documentName.split("___");
    let timestamp = 0;
    let docFamily = "";
    let docName = "";
    if (arr.length >= 1) {
        timestamp = parseInt(arr[0]);
    }
    if (arr.length >= 2) {
        docFamily = arr[1];
    }
    if (arr.length >= 3) {
        docName = arr[2].split(".")[0];
    }
    return {
        timestamp,
        docFamily,
        docName,
    };
}
