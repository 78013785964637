import React, { PureComponent, createRef, forwardRef } from 'react'
import Radium from 'radium'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import Icon from '@mdi/react'
import { mdiContentSave, mdiInformationOutline, mdiTrashCan } from '@mdi/js'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import { difference, isEmpty, pick, pickBy } from 'lodash'
import equal from 'deep-equal'

import NavigationBar from '../../components/Navigation/NavigationBar'
import SectionHeaderPrimary from '../../components/Headings/SectionHeaderPrimary'
import SideMenu from '../../components/Navigation/SideMenu'
import JobPositionsSideMenu from '../../components/SideMenus/JobPositionsSideMenu'
import InfoCollectorModal from '../../components/Modals/InfoCollector'
import RouteLeavingGuard from '../../components/Navigation/RouteLeavingGuard'
import AlertModalTwoButton from '../../components/Modals/AlertModalTwoButton'
import SaveFailedModal from '../../components/Modals/AlertModalOneButton'
import { ColorPalette } from '../../config/colors'
import { getObjectKeyPath, removeUnderscores, toUpperCaseCustom } from '../../utils'
import { PeopleFlowCombinedReducer } from '../../store'
import { ActionType } from '../../store/actions/actions'
import LoadingModal from '../../components/Modals/LoadingModal'
import { Sections } from '../../components/Configurator/Processes/Sections'
import { config } from '../../components/Configurator/Processes/config'

import { getSnapshotFromConfig } from '../../utils/config'
import { SessionService, ConfigManagerService } from '../../services'
import { Toolbar } from '../../components/GeneralUI/Toolbar'
import { AssociationSettingsRepository, UserRepository } from '../../repositories'
import { DocConfigItem } from '../../models'

dayjs.extend(relativeTime)

interface IJobPositionsProps extends RouteComponentProps {
  selectedAssociation: string
  password: string
  associationRepo: AssociationSettingsRepository
  userRepo: UserRepository
  updateState: (obj: any) => void
}

interface IJobPositionsState {
  unsavedChangesModalOpen: boolean
  saveFailedModalOpen: boolean
  deleteConfirmModalOpen: boolean
  savingModalOpen: boolean
  selectedDivision: string
  selectedJob: string
  selectorIdentifier: string
  selectorItemCollectorModalOpen: string[]
  selectorItems: string[]
  selectorItemsDisplay: string
  selectorType?: string
  saveFailedSections: string[]
  [index: string]: any
}

class JobPositions extends PureComponent<IJobPositionsProps, IJobPositionsState> {
  private sectionHeaderRef: React.RefObject<any>
  private sectionsRef: React.RefObject<any>

  constructor(props: IJobPositionsProps) {
    super(props)
    this.sectionHeaderRef = createRef()
    this.sectionsRef = createRef()
  }

  initialModalState = {
    unsavedChangesModalOpen: false,
    saveFailedModalOpen: false,
    deleteConfirmModalOpen: false,
    savingModalOpen: false,
    selectorItemCollectorModalOpen: [],
  }

  state: IJobPositionsState = {
    ...this.initialModalState,
    // ...this.initialSectionState,
    selectedDivision: '',
    selectedJob: '',
    selectorIdentifier: '',
    selectorItems: [],
    selectorItemsDisplay: '',
    selectorType: undefined,
    saveFailedSections: [],
  }

  componentDidUpdate(
    prevProps: Readonly<IJobPositionsProps>,
    prevState: Readonly<IJobPositionsState>,
    snapshot?: any,
  ): void {
    if (
      prevState.selectedDivision !== this.state.selectedDivision ||
      prevState.selectedJob !== this.state.selectedJob
    ) {
      this.initialiseFromConfig()
      this.sectionsRef.current?.scrollIntoView({ behavior: 'auto' })
    }
    if (!equal(prevProps.selectedAssociation, this.props.selectedAssociation)) {
      this.initialiseFromConfig()
    }
  }

  initialiseFromConfig = () => {
    Object.keys(config).forEach((sectionName: string) => {
      this.initSection(sectionName)
    })
  }

  initSection = (sectionName: string) => {
    const { selectedAssociation, associationRepo } = this.props
    const configSnapshot = getSnapshotFromConfig(
      sectionName,
      selectedAssociation,
      this.state.selectedDivision,
      this.state.selectedJob,
      associationRepo,
    )
    // @ts-ignore (No index signature with a parameter of type 'string' was found on type)
    const configStateFields = config[sectionName].subSections.map((subSection: any) => subSection.appStateName)
    this.setState({
      [sectionName]: pick(configSnapshot, configStateFields),
    })
  }

  closeModals = () => {
    this.setState(this.initialModalState)
  }

  resetSectionState = () => {
    this.initialiseFromConfig()
  }

  openSelectorModal = (selectorIdentifier: string, selectorItems: string[], selectorType?: string) => {
    let newState: any = { selectorIdentifier, selectorItems }
    if (selectorType) {
      newState.selectorType = selectorType
    }
    this.setState({ selectorIdentifier, selectorItems, selectorType })
  }

  closeSelectorModal = () => this.setState({ selectorIdentifier: '', selectorItems: [] })

  toggleDeleteConfirmModal = () =>
    this.setState((prevState) => ({ deleteConfirmModalOpen: !prevState.deleteConfirmModalOpen }))

  toggleSavingModal = () => this.setState((prevState) => ({ savingModalOpen: !prevState.savingModalOpen }))
  toggleSaveFailedModal = () => this.setState((prevState) => ({ saveFailedModalOpen: !prevState.saveFailedModalOpen }))

  setUseProcessFlow = (stateObjName: string, useProcessFlow: boolean) => {
    const newObjValue = { ...this.state[stateObjName] }
    newObjValue.useProcessFlow = useProcessFlow
    this.setState({ [stateObjName]: newObjValue })
  }

  anyChangesMade = (sectionName?: string, subSection?: string) => {
    const { selectedDivision, selectedJob } = this.state
    if (selectedDivision && selectedJob) {
      const { selectedAssociation, associationRepo } = this.props

      if (sectionName) {
        const snapshotBeforeChanges: Record<string, any> = getSnapshotFromConfig(
          sectionName,
          selectedAssociation,
          selectedDivision,
          selectedJob,
          associationRepo,
        )
        if (subSection) {
          return !equal(snapshotBeforeChanges[subSection], this.state[sectionName][subSection])
        }
        return !equal(snapshotBeforeChanges, this.state[sectionName])
      }

      const sections = Object.keys(config) // now use config instead
      let changesMade = false

      sections.every((sectionName: string) => {
        const snapshotBeforeChanges: Record<string, any> = getSnapshotFromConfig(
          sectionName,
          selectedAssociation,
          selectedDivision,
          selectedJob,
          associationRepo,
          true,
        )
        if (!equal(snapshotBeforeChanges, this.state[sectionName])) {
          changesMade = true
          return false
        }
        return true
      })
      return changesMade
    }
    return false
  }

  triggerSaveAndChangePrompt = (stateChangeObj: {}) => {
    const unsavedChanges = this.anyChangesMade()
    if (unsavedChanges) {
      this.setState({ unsavedChangesModalOpen: true })
      return
    }
    this.setState(stateChangeObj)
  }

  saveSectionChanges = async (sectionName: string, userIdPassport: string) => {
    let updatedProcessConfig = {}
    if (sectionName === 'jobSubTypes' && this.anyChangesMade(sectionName, 'jobSubTypes')) {
      this.saveJobSubTypeChanges(userIdPassport, (updatedConfig: any) => {
        const { selectedAssociation, associationRepo } = this.props
        associationRepo.setDivisionConfig(selectedAssociation, updatedConfig.divisionConfig)
      })
    }

    if (sectionName === 'competencies' && this.anyChangesMade(sectionName, 'requiredCompetencies')) {
      const response: { result: any; updatedConfig: any } | { result: any; updatedConfig?: undefined } =
        await this.saveCompetenciesRequired(userIdPassport)

      if (response.result === 'success') {
        updatedProcessConfig = response.updatedConfig
      } else {
        this.setState({ saveFailedSections: [...this.state.saveFailedSections, 'competencies (required)'] })
      }
    }

    if (this.anyChangesMade(sectionName, 'useProcessFlow')) {
      const response: { result: any; updatedConfig: any } | { result: any; updatedConfig?: undefined } =
        await this.saveUseProcessFlow(sectionName, userIdPassport)

      if (response.result === 'success') {
        updatedProcessConfig = response.updatedConfig
      } else {
        this.setState({
          saveFailedSections: [...this.state.saveFailedSections, `${sectionName} (use process flow)`],
        })
      }
    }

    const { selectedAssociation, associationRepo } = this.props
    if (!isEmpty(updatedProcessConfig)) {
      associationRepo.setProcessConfigItem(
        selectedAssociation,
        pick(updatedProcessConfig, sectionName === 'competencies' ? 'competenciesv2' : sectionName),
      )
      return associationRepo
    }
    return null
  }

  saveChanges = async (userIdPassport: string) => {
    const sections = Object.keys(config)
    this.toggleSavingModal()

    Promise.all(
      sections.map(async (section: string) => {
        if (this.anyChangesMade(section)) {
          return await this.saveSectionChanges(section, userIdPassport)
        }
        return undefined
      }),
    )
      .then((results) => {
        const lastUserProfileUpdate = results.reverse().find((result) => result !== null && result !== undefined)
        this.props.updateState({ userProfile: lastUserProfileUpdate })
        this.sectionsRef.current?.scrollIntoView({ behavior: 'auto' })
        this.toggleSavingModal()
        if (this.state.saveFailedSections.length > 0) {
          this.toggleSaveFailedModal()
        }
      })
      .catch((err) => console.error(err))
  }

  saveJobSubTypeChanges = async (userIdPassport: string, onSuccess: (updatedConfig: any) => void) => {
    const { password, selectedAssociation } = this.props
    const { jobSubTypes, saveFailedSections, selectedDivision, selectedJob } = this.state

    let updatedData: Record<string, {}> = {}
    jobSubTypes.jobSubTypes.forEach((subType: string) => (updatedData[subType] = {}))
    const changes = [
      {
        editedBy: userIdPassport,
        updatePath: ['divisionConfig', 'config', selectedDivision, 'jobTypes', selectedJob, 'jobSubTypes'],
        updatedData,
        updatedMs: +new Date(),
      },
    ]
    const response: { result: any; updatedConfig: any } | { result: any; updatedConfig?: undefined } =
      await ConfigManagerService.updateConfig(selectedAssociation, 'organisationConfig', changes, {
        username: userIdPassport,
        password,
      })

    if (response.result === 'success') {
      if (onSuccess) {
        onSuccess(response.updatedConfig)
      }
    } else {
      this.setState({ saveFailedSections: [...saveFailedSections, 'jobSubTypes'] })
    }
  }

  saveCompetenciesRequired = async (userIdPassport: string) => {
    const division = this.state.selectedDivision || 'allDivisions'
    const changes = [
      {
        editedBy: userIdPassport,
        updatePath: [
          'competenciesv2',
          'competencyConfig',
          division,
          this.state.selectedJob,
          'allJobSubTypes',
          'requiredCompetencies',
        ],
        updatedData: this.state.competencies.requiredCompetencies,
        updatedMs: +new Date(),
      },
    ]
    return ConfigManagerService.updateConfig(this.props.selectedAssociation, 'processConfig', changes, {
      username: userIdPassport,
      password: this.props.password,
    })
  }

  saveDataFlows = async (sectionName: string, processConfig: any, userIdPassport: string) => {
    const dataCaptureConfig = processConfig[sectionName].dataCaptureConfig
    const divisionPath = dataCaptureConfig.hasOwnProperty(this.state.selectedDivision)
      ? this.state.selectedDivision
      : 'allDivisions'
    const jobTypePath = dataCaptureConfig[divisionPath].hasOwnProperty(this.state.selectedJob)
      ? this.state.selectedJob
      : 'allJobTypes'

    let updatePath = [sectionName, 'dataCaptureConfig', divisionPath, jobTypePath, 'allJobSubTypes']
    if (dataCaptureConfig[divisionPath][jobTypePath].allJobSubTypes.hasOwnProperty('dataFlowNames')) {
      updatePath.push('dataFlowNames')
    } else {
      let docNamePath = getObjectKeyPath(dataCaptureConfig[divisionPath][jobTypePath].allJobSubTypes, 'dataFlowNames')
      updatePath = updatePath.concat(docNamePath)
    }

    const changes = [
      {
        editedBy: userIdPassport,
        updatePath,
        updatedData: this.state[sectionName].dataFlowNames,
        updatedMs: +new Date(),
      },
    ]
    return ConfigManagerService.updateConfig(this.props.selectedAssociation, 'processConfig', changes, {
      username: userIdPassport,
      password: this.props.password,
    })
  }

  saveTerminateReasons = async (processConfig: any, userIdPassport: string) => {
    // const docCaptureConfig = processConfig.terminate.docCaptureConfig;
    // const divisionPath = docCaptureConfig.hasOwnProperty(this.state.selectedDivision)
    //     ? this.state.selectedDivision
    //     : "allDivisions";

    let updatedData: Record<string, {}> = {}
    this.state.terminate.reasons.forEach((reason: string) => (updatedData[reason] = {}))

    const changes = [
      {
        editedBy: userIdPassport,
        updatePath: ['terminate', 'terminationReasonConfig'],
        updatedData,
        updatedMs: +new Date(),
      },
    ]
    return ConfigManagerService.updateConfig(this.props.selectedAssociation, 'processConfig', changes, {
      username: userIdPassport,
      password: this.props.password,
    })
  }

  saveUseProcessFlow = async (sectionName: string, userIdPassport: string) => {
    const changes = [
      {
        editedBy: userIdPassport,
        updatePath: [sectionName, 'useProcessFlow'],
        updatedData: this.state[sectionName].useProcessFlow,
        updatedMs: +new Date(),
      },
    ]
    return ConfigManagerService.updateConfig(this.props.selectedAssociation, 'processConfig', changes, {
      username: userIdPassport,
      password: this.props.password,
    })
  }

  removeJobPosition = async (userIdPassport: string, organisationConfig: any) => {
    const { selectedAssociation, associationRepo, password } = this.props
    this.toggleSavingModal()
    // first remove job type details from competencies.competencyConfig
    const processConfig = associationRepo.getProcessConfig(selectedAssociation)
    const division = this.state.selectedDivision || 'allDivisions'
    const competencyConfig = processConfig.competenciesv2.competencyConfig
    let newCompetencyConfig = { ...competencyConfig }
    delete newCompetencyConfig[division][this.state.selectedJob]

    let changes = [
      {
        editedBy: userIdPassport,
        updatePath: ['competenciesv2', 'competencyConfig'],
        updatedData: newCompetencyConfig,
        updatedMs: +new Date(),
      },
    ]
    let response: { result: any; updatedConfig: any } | { result: any; updatedConfig?: undefined } =
      await ConfigManagerService.updateConfig(this.props.selectedAssociation, 'processConfig', changes, {
        username: userIdPassport,
        password,
      })

    if (response.result === 'success') {
      const { selectedAssociation, associationRepo } = this.props
      associationRepo.setProcessConfigItem(selectedAssociation, pick(response.updatedConfig, 'competenciesv2'))
    }

    const { selectedDivision, selectedJob } = this.state
    const jobTypes = organisationConfig.divisionConfig.config[selectedDivision].jobTypes

    const updatedJobPositions = { ...jobTypes }
    // now finally remove job from organisationConfig.divisionConfig
    delete updatedJobPositions[selectedJob]

    changes = [
      {
        editedBy: userIdPassport,
        updatePath: ['divisionConfig', 'config', selectedDivision, 'jobTypes'],
        updatedData: updatedJobPositions,
        updatedMs: +new Date(),
      },
    ]
    response = await ConfigManagerService.updateConfig(this.props.selectedAssociation, 'organisationConfig', changes, {
      username: userIdPassport,
      password,
    })

    if (response.result === 'success') {
      const { selectedAssociation, associationRepo, updateState } = this.props
      associationRepo.setDivisionConfig(selectedAssociation, response.updatedConfig.divisionConfig)
      this.setState({ selectedJob: '' })
      this.closeModals()
    } else {
      this.toggleSaveFailedModal()
    }
  }

  setSelectorOptions = (selectorItems: string[]) => {
    const { selectorIdentifier } = this.state
    if (selectorIdentifier.includes('.')) {
      const [objName, key] = selectorIdentifier.split('.')
      const newObj = { ...this.state[objName] }
      newObj[key] = selectorItems
      this.setState({ [objName]: newObj })
    } else {
      // this conditional specific to job subtype changes
      this.setState({ [selectorIdentifier]: selectorItems })
    }
    this.closeSelectorModal()
  }

  docIsPublished = (docObj: DocConfigItem) => !isEmpty(docObj.published)

  render() {
    const { associationRepo, userRepo, selectedAssociation } = this.props
    const {
      selectedDivision,
      selectedJob,
      selectorIdentifier,
      selectorItems,
      selectorType,
      unsavedChangesModalOpen,
      deleteConfirmModalOpen,
      savingModalOpen,
      saveFailedModalOpen,
      saveFailedSections,
    } = this.state

    const changesMade = this.anyChangesMade()

    const docConfig = associationRepo.getDocConfig(selectedAssociation)
    const organisationConfig = associationRepo.getOrganisationConfig(selectedAssociation)
    const user = userRepo.getCurrentUserEntity()
    const userIdPassport = user.getUsername()

    const availableCompetencies = associationRepo.getAllPossibleCompetencies(selectedAssociation)
    const availableDocsToChooseFrom = pickBy(docConfig, this.docIsPublished)
    const availableDataFlows = associationRepo.getAllDataFlows(selectedAssociation)

    let pickerItems: string[] = []
    if (selectorIdentifier && selectorIdentifier.includes('.') && selectorType === 'picker') {
      const currentSectionWithOpenSelector = selectorIdentifier.split('.')[0]
      const currentSelectorItemGrouping = selectorIdentifier.split('.')[1]

      if (currentSelectorItemGrouping === 'requiredCompetencies') {
        pickerItems = availableCompetencies
      }
      if (currentSelectorItemGrouping === 'docNames') {
        pickerItems = Object.keys(availableDocsToChooseFrom)
      }
      if (currentSelectorItemGrouping === 'dataFlowNames') {
        pickerItems = Object.keys(availableDataFlows)
      }
      if (this.state[currentSectionWithOpenSelector][currentSelectorItemGrouping].length > 0) {
        pickerItems = difference(pickerItems, this.state[currentSectionWithOpenSelector][currentSelectorItemGrouping])
      }
    }

    const getSubSectionRenderObject = (sectionName: string, subSection: any) => ({
      name: subSection.appStateName,
      label: subSection.label,
      value: this.state[sectionName][subSection.appStateName],
      configContext: subSection.configContext,
      route: subSection.route || undefined,
      onSelect: (selectorIdentifier: string, selectorItems: string[]) =>
        this.openSelectorModal(selectorIdentifier, selectorItems, subSection.selectorType || 'picker'),
      onChange: (value: any) => {
        this.setUseProcessFlow(sectionName, value)
      },
    })

    return (
      <div style={styles.container}>
        <NavigationBar match={this.props.match} location={this.props.location} history={this.props.history} />
        <SectionHeaderPrimary
          ref={this.sectionHeaderRef}
          style={styles.sectionHeader}
          disabled={true}
          searchString={''}
          onClick={() => ({})}>
          Processes
        </SectionHeaderPrimary>

        <div style={styles.contentContainer}>
          <SideMenu
            visible={true}
            menuComponents={
              <JobPositionsSideMenu
                selectedDivision={selectedDivision}
                selectedJob={selectedJob}
                selectedAssociation={selectedAssociation}
                userIdPassport={userIdPassport}
                organisationConfig={organisationConfig}
                onDivisionChange={(division: string) => this.triggerSaveAndChangePrompt({ selectedDivision: division })}
                onJobClick={(job: string) =>
                  this.triggerSaveAndChangePrompt({
                    selectedJob: job === 'ALL JOB POSITIONS' ? 'allJobTypes' : job,
                  })
                }
              />
            }
          />
          <JobSectionsContainer
            ref={this.sectionsRef}
            selectedJob={selectedJob}
            changesMade={changesMade}
            onSaveClick={() => this.saveChanges(userIdPassport)}
            onDeleteClick={() => this.setState({ deleteConfirmModalOpen: true })}>
            {this.state.selectedJob ? (
              <Sections
                selectedDivision={selectedDivision}
                selectedJob={selectedJob}
                sections={Object.keys(config)}
                getSubSectionRenderObject={getSubSectionRenderObject}
                key={`sideMenu_${selectedDivision}_${selectedJob}`}
              />
            ) : (
              <SelectJobInstruction />
            )}
          </JobSectionsContainer>
        </div>
        <InfoCollectorModal
          open={selectorIdentifier !== ''}
          defaultItems={selectorItems}
          pickerItems={pickerItems}
          header="SELECTOR OPTIONS"
          subHeader="Field selector options"
          warningMessage="Add at least one item"
          validateInput={() => true}
          transformInput={selectorType === 'picker' ? undefined : toUpperCaseCustom}
          placeholder={selectorType === 'picker' ? 'Choose option' : 'Enter option'}
          successLabel="Update"
          minimumItems={1} // this could be changed to 0 if necessary to remove a last item, allowing a empty list
          dismiss={this.closeSelectorModal}
          onSuccess={this.setSelectorOptions}
          onReject={this.closeSelectorModal}
          type={selectorType === 'picker' ? 'picker' : undefined}
        />
        <AlertModalTwoButton
          open={unsavedChangesModalOpen}
          dismiss={this.closeModals}
          onClick1={() => {
            this.resetSectionState()
            this.closeModals()
          }}
          onClick2={() => {
            this.closeModals()
            this.saveChanges(userIdPassport)
          }}
          buttonLabel1="Undo"
          buttonLabel2="Save"
          header="UNSAVED CHANGES"
          body={
            <div>
              Cannot switch context without first undoing or saving changes.
              <br />
              What do you want to do?
            </div>
          }
        />
        <AlertModalTwoButton
          open={deleteConfirmModalOpen}
          dismiss={this.closeModals}
          onClick1={() => {
            this.closeModals()
          }}
          onClick2={() => {
            this.removeJobPosition(userIdPassport, organisationConfig)
            this.closeModals()
          }}
          buttonLabel1="No"
          buttonLabel2="Yes"
          header="Confirm"
          buttonStyle={{ marginTop: 30 }}
          body={
            <div>{`Are you sure you want to delete the ${removeUnderscores(
              this.state.selectedJob,
            )} job position?`}</div>
          }
        />
        <SaveFailedModal
          open={saveFailedModalOpen}
          header="Failed to save changes"
          body={<SaveFailedMessage saveFailedSections={saveFailedSections} />}
          buttonLabel="Ok"
          onClick={() => {
            this.setState({ saveFailedSections: [] })
            this.toggleSaveFailedModal()
          }}
        />
        <LoadingModal open={savingModalOpen}>Saving changes...</LoadingModal>
        <RouteLeavingGuard
          when={changesMade} // should have true value if there are any changes unsaved
          navigate={(path) => this.props.history.push(path)}
          shouldBlockNavigation={(location) => true}
          alertHeader="Discard changes"
          alertBody={
            <div>
              You have unsaved changes.
              <br />
              Are you sure you want to leave this page without saving?
            </div>
          }
        />
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255, 1), rgba(209,210,230, 1))',
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto 1%',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    paddingInline: 'max(2em, 2%)',
    width: '100%',
    overflow: 'hidden',
  },
  rightSideContent: {
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    display: 'flex',
    flex: 1,
    overflow: 'auto',
    backgroundColor: ColorPalette.CARD_WHITE,
  },
  button: {
    fontWeight: 'bolder',
    fontSize: '0.8rem',
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    ':hover': {
      color: ColorPalette.PRIMARY_BLUE,
    },
    ':active': {
      color: ColorPalette.DARK_GREY,
    },
  },
  jobSections: {
    width: '91%',
    margin: '0 auto',
  },
}

const mapStateToProps = (state: PeopleFlowCombinedReducer) => {
  return {
    idPassport: state.sessionManager.idPassport,
    password: state.sessionManager.password,
    associationRepo: state.sessionManager.associationRepo as AssociationSettingsRepository,
    userRepo: state.sessionManager.userRepo as UserRepository,
    selectedAssociation: state.sessionManager.selectedAssociation,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateState: (data: any) => dispatch({ type: ActionType.UPDATE_STATE, data }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Radium(JobPositions))

const SelectJobInstruction = () => (
  <div
    style={{
      paddingTop: '25%',
      fontSize: '0.85rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: ColorPalette.SECONDARY_TEXT,
    }}>
    <Icon path={mdiInformationOutline} size={1} color={ColorPalette.PRIMARY_BLUE} style={{ marginRight: '.75em' }} />
    Select a job on the left to manage process settings
  </div>
)

const SaveFailedMessage = (props: any) => (
  <div>
    {props.saveFailedSections.length > 0 ? (
      <>
        Changes won't be permanently applied for the following sections:
        <div>
          <ul>
            {props.saveFailedSections.map((section: string) => (
              <li
                style={{
                  listStyleType: 'disc',
                  textAlign: 'left',
                  paddingLeft: '1em',
                }}>
                {section}
              </li>
            ))}
          </ul>
        </div>
      </>
    ) : (
      <>{"Changes won't be permanently applied. "}</>
    )}
    <p>
      Please try again.
      <div>If problem persists, please contact customer support.</div>
    </p>
  </div>
)

const JobSectionsContainer = forwardRef((props: any, ref: any) => {
  const toolbarActionButtons = []

  if (props.selectedJob) {
    toolbarActionButtons.push({
      iconPath: mdiTrashCan,
      onClick: props.onDeleteClick,
      label: 'DELETE POSITION',
      title: 'Delete currently selected job position',
    })
  }

  if (props.changesMade) {
    toolbarActionButtons.unshift({
      iconPath: mdiContentSave,
      onClick: props.onSaveClick,
      label: 'SAVE CHANGES',
      title: 'Save changes to currently selected job position',
    })
  }

  return (
    <div style={styles.rightSide}>
      <Toolbar actionButtons={toolbarActionButtons} navButtons={{ left: { label: 'BACK' } }} />
      <div style={styles.rightSideContent}>
        <div style={styles.jobSections} ref={ref}>
          {props.children}
        </div>
      </div>
    </div>
  )
})
