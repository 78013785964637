export enum CommonUnixMsEnum {
  ONE_MINUTE = 60000,
  ONE_HOUR = 3600000,
  ONE_DAY = 86400000,
  ONE_WEEK = 604800000,
  ONE_MONTH = 2592000000,
}

export enum TimeBasedDocStatusEnum {
  NO_PERIOD_SET = 'NO PERIOD SET',
  NO_EXPIRY_SET = 'NO EXPIRY SET',
  VALID = 'VALID',
  "VALID_(CAN'T_EXPIRE)" = "VALID (CAN'T EXPIRE)",
  EXPIRES_IN_45_DAYS = 'EXPIRES IN 45 DAYS',
  EXPIRED = 'EXPIRED',
  NO_DOC = 'NO DOC',
  DOCS_OUTSTANDING = 'DOCS OUTSTANDING',
}
