import { ColorPalette } from '../../../config/colors'

import React from 'react'
import Radium from 'radium'

interface IButtonRoundProps {
  diameter?: number
  disabled?: boolean
  icon?: JSX.Element
  label?: string
  textStyle?: React.CSSProperties | undefined
  style?: React.CSSProperties | undefined
  onClick: (e: React.MouseEvent) => void
}

const buttonRound: React.FC<IButtonRoundProps> = (props: IButtonRoundProps) => {
  const diameter = props.diameter ? props.diameter : 20
  let styles = {
    buttonStyle: {
      display: 'flex',
      flexDirection: 'column' as 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: ColorPalette.CARD_WHITE,
      height: diameter,
      width: diameter,
      borderRadius: diameter / 2,
      outline: 0,
      padding: 0,
      boxShadow: '0px 1px 3px rgba(0, 0, 40, 0.15)',
      border: `0px solid rgba(0,0,0,0)`,
      ':hover': {
        filter: 'brightness(94%)',
      },
      ':active': {
        filter: 'brightness(88%)',
      },
      cursor: 'pointer',
    },
    textStyle: {
      marginTop: 10,
      fontSize: '0.9rem',
      fontFamily: 'Roboto',
      fontWeight: '500',
      color: ColorPalette.PRIMARY_TEXT,
    },
  }

  let label = null
  if (props.label) {
    label = <p style={{ ...styles.textStyle, ...props.textStyle }}>{props.label}</p>
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <button style={{ ...styles.buttonStyle, ...props.style }} onClick={props.onClick} disabled={props.disabled}>
        {props.icon}
      </button>
      {label}
    </div>
  )
}

export default Radium(buttonRound)
