import { ColorPalette } from '../../../config/colors'
import { toUpperCaseCustom } from '../../../utils'
import Checkbox from '@material-ui/core/Checkbox'
import Radium from 'radium'

export interface ICheckboxProps {
  id?: string
  style?: React.CSSProperties | undefined
  checkboxStyle?: React.CSSProperties
  disabled?: boolean | undefined
  onClick: (e: React.ChangeEvent) => void
  clickableLabelEnabled?: boolean | undefined
  label?: string
  onLabelClick?: () => void
  checked: boolean
  labelStyle?: React.CSSProperties
  title?: string
}

const checkbox: React.FC<ICheckboxProps> = (props: ICheckboxProps) => {
  return (
    <div style={{ ...styles.container, ...props.style }} title={props.title}>
      <Checkbox
        id={props.id}
        style={{
          color: props.disabled ? ColorPalette.VERY_LIGHT_GREY : ColorPalette.PRIMARY_BLUE,
          ...props.checkboxStyle,
        }}
        checked={props.checked || false}
        onChange={props.onClick}
        disabled={props.disabled}
      />
      {props.label && (
        <button
          style={{ ...styles.label, width: window.innerWidth * 0.045, ...props.labelStyle }}
          disabled={!props.clickableLabelEnabled}
          onClick={props.onLabelClick}>
          {toUpperCaseCustom(props.label)}
        </button>
      )}
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center',
  },
  label: {
    fontWeight: '400',
    fontSize: '0.7rem',
    backgroundColor: 'transparent',
    color: ColorPalette.PRIMARY_TEXT,
    outline: 0,
    border: 0,
    padding: '10px 5px',
    borderRadius: 8,
    margin: 0,
    ':hover': {
      backgroundColor: 'rgba(220,220,230,0.3)',
    },
    ':active': {
      backgroundColor: 'rgba(220,220,230,0.5)',
    },
  },
}

export default Radium(checkbox)
