import { forwardRef } from 'react'
import { useSelector } from 'react-redux'

import DataTable from '../../components/Tables/DataTable/DataTable'
import { GroupService } from '../../services'
import { GroupMemberPpe, GroupMemberPpeProps } from '../../components/Groups/GroupMemberPpe'
import { ColorPalette } from '../../config'
import { UserFeedback } from '../../components/GeneralUI/Feedback/UserFeedback'
import { PeopleFlowCombinedReducer } from '../../store/reducers'
import { AssociationSettingsRepository } from '../../repositories'
import { PpeForProfile, PpeInstance } from '../../types'

const columnConfig = [
  { id: 'idPassport', label: 'ID / Passport', sizeFactor: 1 },
  { id: 'name', label: 'Name', sizeFactor: 1 },
  { id: 'surname', label: 'Surname', sizeFactor: 1 },
  { id: 'jobType', label: 'Job type', sizeFactor: 1 },
  { id: 'startDate', label: 'Scheduled start', sizeFactor: 1 },
  { id: 'terminationDate', label: 'Scheduled end', sizeFactor: 1 },
]

type GroupMemberDetailsProps = {
  ppeSelectionModeEnabled: boolean
  memberProfiles: any[]
  selectedGroup: any
  selectedSubGroup: string
  selectedGroupCategory: string
  groupMemberPpeSelection: GroupMemberPpeProps | {}
  subGroupSelected: boolean
  groupCompleted: boolean
  loadingModalOpen: boolean
  allPpeInstances?: PpeInstance[]
  ppeSelections?: PpeForProfile
  setPpeSelections?: (selections: PpeForProfile) => void
  getNonExpiredGroupsBelongingTo: (idPassport: string) => string[]
  onRowSelect: (selectedRows: any) => void
  onRowClick: (selectedRowData: Record<string, any>) => void
}

export const GroupMemberDetails = forwardRef((props: GroupMemberDetailsProps, ref) => {
  const selectedAssociation = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedAssociation,
  )
  const associationRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.associationRepo,
  ) as AssociationSettingsRepository

  const {
    memberProfiles,
    selectedGroup,
    selectedSubGroup,
    selectedGroupCategory,
    groupMemberPpeSelection,
    subGroupSelected,
    groupCompleted,
    loadingModalOpen,
    ppeSelectionModeEnabled,
    ppeSelections,
    setPpeSelections,
    getNonExpiredGroupsBelongingTo,
    onRowSelect,
    onRowClick,
  } = props

  const getColumnConfig = () => {
    if (subGroupSelected) {
      return [...columnConfig, { id: 'ppeStatus', label: 'PPE Status', sizeFactor: 1 }]
    }
    return columnConfig
  }

  const getCustomComponentConfig = () => {
    if (!subGroupSelected) {
      return {
        rightHeaderComponent: <div style={styles.rightHeaderComponent}>Status</div>,
        rightComponentWidth: 80,
      }
    }
    return {}
  }

  const getCustomRowStyle = (rowId: string) => {
    if (!subGroupSelected) {
      const memberJobType = GroupService.getMemberJobType(rowId, selectedGroup)
      if (memberJobType) {
        // @ts-ignore
        const docRequirementsNotSatisfied = GroupService.getDocRequirementsNotSatisfied(
          rowId,
          memberJobType,
          associationRepo,
          selectedAssociation,
          selectedGroup,
        )
        if (docRequirementsNotSatisfied.length > 0) {
          return {
            backgroundColor: ColorPalette.WARNING_RED_TRANSPARENT,
            // @ts-ignore
            ':hover': {
              filter: 'brightness(0.9)',
            },
          }
        }
        if (getNonExpiredGroupsBelongingTo(rowId).length > 0) {
          return {
            backgroundColor: ColorPalette.DARK_MEDIUM_GREY_TRANSPARENT,
            // @ts-ignore
            ':hover': {
              filter: 'brightness(0.9)',
            },
          }
        }
      }
    }
    return {}
  }

  if (ppeSelectionModeEnabled) {
    return (
      <GroupMemberPpe
        {...groupMemberPpeSelection}
        allPpeInstances={props.allPpeInstances}
        existingSelections={ppeSelections}
        // @ts-expect-error
        onSelectionChange={setPpeSelections}
      />
    )
  }
  let detailView = null
  if (memberProfiles.length > 0) {
    let tableData = memberProfiles
    const groupKey = GroupService.getInitiationGroupKeyByDisplayName(selectedSubGroup, selectedGroup)
    if (groupKey !== 'All' && subGroupSelected && selectedGroup?.subGroups) {
      tableData = memberProfiles.filter((profile) => {
        // @ts-ignore
        return selectedGroup?.subGroups[selectedGroupCategory][groupKey].memberPks.includes(profile.id)
      })
    }
    detailView = (
      <DataTable
        ref={ref as React.RefObject<DataTable>}
        tableData={tableData}
        columnConfig={getColumnConfig()}
        tableWidth={subGroupSelected ? 9 : 10}
        rowsOnSelect={onRowSelect}
        selectedRowItemId={''}
        authorisedItemIds={[]}
        isSelectionEnabled={!groupCompleted && !subGroupSelected}
        applyCustomRowStyle={getCustomRowStyle}
        customComponentConfig={getCustomComponentConfig()}
        rowClickAsSelect={!subGroupSelected}
        onRowClick={onRowClick}
        disabled={!subGroupSelected}
        key={`dataTable_${selectedAssociation}-${subGroupSelected}-${groupKey}`}
      />
    )
  } else if (!loadingModalOpen) {
    detailView = <UserFeedback message="No group members added." />
  }
  return detailView
})

const styles = {
  rightHeaderComponent: {
    fontSize: '0.8rem',
    fontWeight: 500,
    textAlign: 'right' as 'right',
    color: ColorPalette.PRIMARY_LIGHT_TEXT,
    width: '100%',
    paddingRight: 'calc(80px - (6ch / 0.8))',
  },
}
