import Radium from 'radium'
import { ColorPalette } from '../../../config/colors'
import FormControl from '@material-ui/core/FormControl'
import CalendarRange, { CalendarRangeProps } from './CalendarRange'

const CalenderContained = (props: CalendarRangeProps) => (
  <FormControl style={{ ...styles.formControl, ...props.style }}>
    <p style={styles.label}>{props.label?.toUpperCase()}</p>
    <CalendarRange {...props} />
  </FormControl>
)

const styles = {
  formControl: {
    minWidth: 120,
    width: '80%',
    marginTop: 5,
    paddingTop: 15,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 10,
    fontSize: '1rem',
    border: `1px solid ${ColorPalette.VERY_LIGHT_GREY}`,
    borderRadius: 12,
    display: 'flex',
    justifyContent: 'center',
  },
  label: {
    display: 'absolute',
    zIndex: 2,
    marginTop: -22,
    alignSelf: 'center',
    fontSize: '0.65rem',
    color: ColorPalette.LIGHT_GREY,
    backgroundColor: ColorPalette.CARD_WHITE,
    paddingLeft: 10,
    paddingRight: 10,
  },
}

export default Radium(CalenderContained)
