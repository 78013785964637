import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import React, { PureComponent, createRef } from 'react'
import Radium from 'radium'
import Icon from '@mdi/react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import equal from 'deep-equal'
import { mdiInformationOutline, mdiContentDuplicate, mdiTrashCan } from '@mdi/js'
import { camelCase, cloneDeep, isEmpty, pick } from 'lodash'

import { ColorPalette } from '../../config/colors'
import ButtonBlue from '../../components/BaseComponents/Buttons/ButtonBlue'
import NavigationBar from '../../components/Navigation/NavigationBar'
import SectionHeaderPrimary from '../../components/Headings/SectionHeaderPrimary'
import SideMenu from '../../components/Navigation/SideMenu'
import DataFieldsSideMenu from '../../components/SideMenus/DataFieldsSideMenu'
import InfoCollectorModal from '../../components/Modals/InfoCollector'
import RouteLeavingGuard from '../../components/Navigation/RouteLeavingGuard'
import AlertModalTwoButton from '../../components/Modals/AlertModalTwoButton'
import SaveFailedModal from '../../components/Modals/AlertModalOneButton'
import LoadingModal from '../../components/Modals/LoadingModal'
import DataFieldDetails from '../../components/Configurator/DataFields/DataFieldDetails'
import LabelCollector from '../../components/Modals/LabelCollector'
import { ConfigManagerService } from '../../services'
import { getCurrentFieldConfigSnapshot, getFieldConfigExclPpeItems, toUpperCaseCustom } from '../../utils'
import { ActionType } from '../../store/actions/actions'
import { PeopleFlowCombinedReducer } from '../../store'
import { IDataFieldDetails } from '../../config/dataFieldConfig'
import { ActionButtonType, Toolbar } from '../../components/GeneralUI/Toolbar'
import { AssociationSettingsRepository, UserRepository } from '../../repositories'
import { ConditionalSelectorItems } from '../../types'
import { IFieldConfigItem } from '../../interfaces'

dayjs.extend(relativeTime)

interface IDataFieldsProps extends RouteComponentProps {
  idPassport: string
  password: string
  selectedAssociation: string
  associationRepo: AssociationSettingsRepository
  userRepo: UserRepository
  updateState: (obj: any) => void
}

interface IDataFieldsState {
  deleteConfirmModalOpen: boolean
  duplicateModalOpen: boolean
  savingModalOpen: boolean
  saveFailedModalOpen: boolean
  unsavedChangesModalOpen: boolean
  selectedField: string
  selectorModalOpen: boolean
  selectorItems: string[]
  selectorItemsDisplay: string
  conditionalSelectorRelatedFieldValue: string
  fieldDetails: IDataFieldDetails
  detailsRefreshTimestamp: number
}

class DataFields extends PureComponent<IDataFieldsProps, IDataFieldsState> {
  private sectionsRef: React.RefObject<any>

  constructor(props: IDataFieldsProps) {
    super(props)
    this.sectionsRef = createRef()
  }

  UNSAFE_componentWillReceiveProps(nextProps: Readonly<IDataFieldsProps>, nextContext: any): void {
    if (this.props.selectedAssociation !== nextProps.selectedAssociation) {
      this.setState({
        ...this.initialModalState,
        ...this.initialFieldDetailsState,
        selectedField: '',
        selectorItems: [''],
        selectorItemsDisplay: '',
        conditionalSelectorRelatedFieldValue: '',
        detailsRefreshTimestamp: Date.now(),
      })
    }
  }

  componentDidUpdate(
    prevProps: Readonly<IDataFieldsProps>,
    prevState: Readonly<IDataFieldsState>,
    snapshot?: any,
  ): void {
    if (prevState.selectedField !== this.state.selectedField) {
      this.initialiseFromConfig(this.state.selectedField)
      this.sectionsRef.current?.scrollTo(0, 0)
      this.setState({ detailsRefreshTimestamp: Date.now() })
    }

    if (!equal(prevProps.associationRepo, this.props.associationRepo)) {
      this.initialiseFromConfig(this.state.selectedField)
      this.setState({ detailsRefreshTimestamp: Date.now() })
    }
  }

  initialiseFromConfig = (selectedField: string) => {
    const fieldDetailsSnapshot = getCurrentFieldConfigSnapshot(
      this.props.associationRepo,
      this.props.selectedAssociation,
      selectedField,
    )

    if (!isEmpty(fieldDetailsSnapshot)) {
      const details = cloneDeep(fieldDetailsSnapshot)
      // @ts-ignore
      this.setState({ fieldDetails: details })
    }
  }

  initialModalState = {
    duplicateModalOpen: false,
    deleteConfirmModalOpen: false,
    savingModalOpen: false,
    unsavedChangesModalOpen: false,
    saveFailedModalOpen: false,
    selectorModalOpen: false,
  }

  initialFieldDetailsState = {
    fieldDetails: {
      key: '',
      compType: '',
      compulsory: false,
      allowCustomOption: false,
      dateFormat: '',
      multiLine: false,
      suggestionsEnabled: false,
      selectorItems: [''],
      useDefaultValue: false,
      defaultValue: '',
      setDefaultValueFromHistory: false,
      label: '',
      disabled: false,
      maxItems: undefined,
      conditionalSelectorItems: undefined,
      autoPopulate: undefined,
    },
  }

  state: IDataFieldsState = {
    ...this.initialModalState,
    ...this.initialFieldDetailsState,
    selectedField: '',
    selectorItems: [''],
    selectorItemsDisplay: '',
    conditionalSelectorRelatedFieldValue: '',
    detailsRefreshTimestamp: 0,
  }

  closeModals = () => {
    this.setState(this.initialModalState)
  }

  resetFieldDetailsState = () => {
    this.setState(this.initialFieldDetailsState, () => this.initialiseFromConfig(this.state.selectedField))
  }

  updateFieldDetail = (
    detailName: keyof IDataFieldDetails,
    newValue: string | string[] | number | boolean | {},
    callback?: () => void, // currently used to allow two sequential async calls (necessary for dateFormat setting); this function could be changed to instead receive an array/obj of field details
  ) => {
    const updatedFieldDetails = cloneDeep(this.state.fieldDetails)
    if (detailName === 'dateFormat') {
      if (newValue === '' && updatedFieldDetails.dateFormat === undefined) {
        if (callback) {
          callback()
        }
        return
      }
      updatedFieldDetails[detailName] = (newValue as string).toUpperCase()
      return
    } else {
      // @ts-ignore
      updatedFieldDetails[detailName] = newValue
    }
    this.setState({ fieldDetails: updatedFieldDetails }, callback)
  }

  openSelectorModal = () => this.setState({ selectorModalOpen: true })
  closeSelectorModal = () => this.setState({ selectorModalOpen: false })

  toggleSaveFailedModal = () => this.setState((prevState) => ({ saveFailedModalOpen: !prevState.saveFailedModalOpen }))
  toggleDeleteConfirmModal = () =>
    this.setState((prevState) => ({ deleteConfirmModalOpen: !prevState.deleteConfirmModalOpen }))
  toggleDuplicateModal = () => this.setState((prevState) => ({ duplicateModalOpen: !prevState.duplicateModalOpen }))

  openConditionalSelectorModal = (
    conditionalSelectorRelatedFieldValue: string,
    conditionalSelectorItems: ConditionalSelectorItems,
  ) => {
    let fieldDetails = { ...this.state.fieldDetails }
    fieldDetails.conditionalSelectorItems = conditionalSelectorItems
    this.setState({ conditionalSelectorRelatedFieldValue, fieldDetails })
  }
  closeConditionalSelectorModal = () =>
    this.setState({
      conditionalSelectorRelatedFieldValue: '',
      // conditionalSelectorItems: {
      //     priFieldKey: "",
      //     values: {},
      // },
    })

  toggleSavingModal = (onToggled?: () => void) =>
    this.setState((prevState) => ({ savingModalOpen: !prevState.savingModalOpen }), onToggled)

  anyChangesMade = () => {
    if (this.state.selectedField) {
      const snapshotBeforeChanges = getCurrentFieldConfigSnapshot(
        this.props.associationRepo,
        this.props.selectedAssociation,
        this.state.selectedField,
      )

      const newFieldBeingCreated = isEmpty(snapshotBeforeChanges)
      const changesToExistingField = !equal(snapshotBeforeChanges, this.state.fieldDetails)

      return newFieldBeingCreated || changesToExistingField
    }
    return false
  }

  saveAndChange = (stateChangeObj: {}, newFieldConfigItem?: IFieldConfigItem) => {
    if (newFieldConfigItem) {
      this.setState(stateChangeObj, () => {
        this.saveChanges(newFieldConfigItem)
      })
      return
    }

    const unsavedChanges = this.anyChangesMade()
    if (unsavedChanges) {
      this.setState({ unsavedChangesModalOpen: true })
      return
    }
    this.setState(stateChangeObj)
  }

  saveChanges = async (newFieldConfigItem?: IFieldConfigItem) => {
    this.toggleSavingModal()
    const { idPassport, password, selectedAssociation, associationRepo, userRepo, updateState } = this.props

    const user = userRepo.getCurrentUserEntity()
    const username = user.getUsername()

    let updatedData: any = newFieldConfigItem || this.state.fieldDetails
    for (const key in updatedData) {
      if (
        updatedData[key] === null ||
        updatedData[key] === undefined ||
        (['selector', 'picker'].indexOf(updatedData.compType) === -1 && key === 'selectorItems') ||
        (updatedData.compType !== 'conditionalSelector' && key === 'conditionalSelectorItems')
      ) {
        delete updatedData[key]
      }
      if (
        updatedData[key] !== null &&
        updatedData[key] !== undefined &&
        key === 'dateFormat' &&
        (typeof updatedData[key] !== 'string' ||
          // string that contains one or more 'D', one or more 'M', and two or more 'Y' characters, with any combination of blank spaces, forward slashes or hyphens as separators
          updatedData[key].match(/^[DMY\s\/-]+[DMY\s\/-]+[DMY\s\/-]+[DMY\s\/-]*(?!\s\d{2}:\d{2}:\d{2})$/) === null)
      ) {
        delete updatedData[key]
      }
    }

    const changes = [
      {
        editedBy: username,
        updatePath: [this.state.selectedField],
        updatedData,
        updatedMs: +new Date(),
      },
    ]
    const response: { result: any; updatedConfig: any } | { result: any; updatedConfig?: undefined } =
      await ConfigManagerService.updateConfig(this.props.selectedAssociation, 'fieldConfig', changes, {
        username: idPassport,
        password,
      })

    if (response.result === 'success') {
      associationRepo.setFieldConfigItem(selectedAssociation, pick(response.updatedConfig, this.state.selectedField))
      this.initialiseFromConfig(this.state.selectedField)
    } else {
      this.toggleSaveFailedModal()
    }

    this.toggleSavingModal()
  }

  copySettingsAndSaveNewField = async (newFieldName: string) => {
    this.toggleDuplicateModal()
    this.toggleSavingModal()

    const key = camelCase(newFieldName)
    const { idPassport, password, selectedAssociation, associationRepo } = this.props
    const fieldConfig = getFieldConfigExclPpeItems(associationRepo, selectedAssociation)
    const currentSelectedFieldConfig = fieldConfig[this.state.selectedField]
    // add new field to local config with settings of current selected field
    fieldConfig[key] = { ...currentSelectedFieldConfig }
    // reset the original properties to ensure they are not duplicated
    fieldConfig[key].key = key
    fieldConfig[key].label = newFieldName.toUpperCase()

    const changes = [
      {
        editedBy: idPassport,
        updatePath: [key],
        updatedData: fieldConfig[key],
        updatedMs: +new Date(),
      },
    ]
    const response = await ConfigManagerService.updateConfig(selectedAssociation, 'fieldConfig', changes, {
      username: idPassport,
      password,
    })

    if (response.result === 'success') {
      associationRepo.setFieldConfig(selectedAssociation, response.updatedConfig)
      this.setState({ selectedField: key })
    }

    this.toggleSavingModal()
  }

  deleteSelectedField = () => {
    this.toggleDeleteConfirmModal()
    this.toggleSavingModal(async () => {
      const { idPassport, password, selectedAssociation, associationRepo } = this.props
      const fieldConfig = getFieldConfigExclPpeItems(associationRepo, selectedAssociation)
      delete fieldConfig[this.state.selectedField]

      // TODO: for selectors fields, should selectorItems, autoPopulate be reset?
      const changes = [
        {
          editedBy: idPassport,
          updatePath: [],
          updatedData: fieldConfig,
          updatedMs: +new Date(),
        },
      ]
      const response = await ConfigManagerService.updateConfig(selectedAssociation, 'fieldConfig', changes, {
        username: idPassport,
        password,
      })

      if (response.result === 'success') {
        associationRepo.setFieldConfig(selectedAssociation, response.updatedConfig)
        this.setState({ selectedField: '' })
      }

      this.closeModals()
    })
  }

  handleFieldSelection = (field: string, newFieldConfigItem?: IFieldConfigItem) => {
    this.saveAndChange({ selectedField: field }, newFieldConfigItem)
  }

  handleChangesUndo = () => {
    this.resetFieldDetailsState()
    this.closeModals()
  }

  handleChangesSave = () => {
    this.closeModals()
    this.saveChanges()
  }

  render() {
    const {
      selectedField,
      unsavedChangesModalOpen,
      deleteConfirmModalOpen,
      duplicateModalOpen,
      savingModalOpen,
      saveFailedModalOpen,
      conditionalSelectorRelatedFieldValue,
      detailsRefreshTimestamp,
      fieldDetails: {
        key,
        compType,
        compulsory,
        allowCustomOption,
        dateFormat,
        multiLine,
        suggestionsEnabled,
        useDefaultValue,
        defaultValue,
        setDefaultValueFromHistory,
        label,
        disabled,
        maxItems,
        selectorItems,
        conditionalSelectorItems,
        autoPopulate,
      },
    } = this.state

    const { associationRepo, selectedAssociation } = this.props

    const fieldConfig = getFieldConfigExclPpeItems(associationRepo, selectedAssociation)
    const fieldDetails: any = selectedField ? fieldConfig[selectedField] : {}
    const changesMade = this.anyChangesMade()

    const existingSelectorTypeFields: IFieldConfigItem[] = []
    Object.entries(fieldConfig).forEach(([key, value]) => {
      if (value.compType === 'selector') {
        existingSelectorTypeFields.push(value)
      }
    })

    const fieldParameters = {
      key,
      compType,
      selectorItems,
      compulsory,
      allowCustomOption,
      dateFormat,
      multiLine,
      suggestionsEnabled,
      useDefaultValue,
      defaultValue,
      setDefaultValueFromHistory,
      label,
      disabled,
      maxItems,
      conditionalSelectorItems,
      autoPopulate,
    }

    let toolbarActionButtons: ActionButtonType[] = []
    if (selectedField) {
      toolbarActionButtons = [
        {
          iconPath: mdiTrashCan,
          onClick: this.toggleDeleteConfirmModal,
          label: 'DELETE FIELD',
          title: 'Delete currently selected field',
        },
        {
          iconPath: mdiContentDuplicate,
          onClick: this.toggleDuplicateModal,
          label: 'DUPLICATE CONFIG',
          title: 'Duplicate config of currently selected field for new field',
        },
      ]
    }

    return (
      <div style={styles.container}>
        <NavigationBar match={this.props.match} location={this.props.location} history={this.props.history} />
        <SectionHeaderPrimary style={styles.sectionHeader} disabled={true} searchString={''} onClick={() => ({})}>
          Data Fields
        </SectionHeaderPrimary>

        <div style={styles.contentContainer}>
          <SideMenu
            visible={true}
            menuComponents={
              <DataFieldsSideMenu
                selectedField={selectedField}
                fieldConfig={fieldConfig}
                onFieldClick={this.handleFieldSelection}
              />
            }
          />
          <div style={styles.rightSide}>
            <Toolbar actionButtons={toolbarActionButtons} navButtons={{ left: { label: 'BACK' } }} />
            <div style={styles.rightSideContent} ref={this.sectionsRef}>
              <div style={styles.fieldDetails}>
                {isEmpty(fieldDetails) ? (
                  <SelectFieldInstruction />
                ) : (
                  <>
                    <DataFieldDetails
                      selectedAssociation={this.props.selectedAssociation}
                      fieldParameters={fieldParameters}
                      fieldConfig={fieldConfig}
                      updateFieldDetail={this.updateFieldDetail}
                      existingSelectorTypeFields={existingSelectorTypeFields}
                      openSelectorModal={this.openSelectorModal}
                      openConditionalSelectorModal={this.openConditionalSelectorModal}
                      key={`dataFieldDetails_${selectedField}_${fieldParameters.compType}_${detailsRefreshTimestamp}`}
                    />
                    <div style={{ paddingBottom: '3em' }}>
                      <ButtonBlue disabled={!changesMade} onClick={(e) => this.saveChanges()}>
                        Save changes
                      </ButtonBlue>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <InfoCollectorModal
          open={this.state.selectorModalOpen}
          defaultItems={selectorItems || []}
          header="SELECTOR OPTIONS"
          subHeader="Field selector options"
          warningMessage="Add at least one item"
          validateInput={() => true}
          transformInput={toUpperCaseCustom}
          placeholder="Enter selector option"
          successLabel="Update"
          minimumItems={1}
          dismiss={this.closeSelectorModal}
          onSuccess={(selectorItems) => {
            this.updateFieldDetail('selectorItems', selectorItems)
            this.closeSelectorModal()
          }}
          onReject={this.closeSelectorModal}
        />
        <InfoCollectorModal
          open={conditionalSelectorRelatedFieldValue !== ''}
          defaultItems={
            conditionalSelectorRelatedFieldValue && conditionalSelectorItems
              ? conditionalSelectorItems.values[conditionalSelectorRelatedFieldValue]
              : []
          }
          header="CONDITIONAL SELECTOR OPTIONS"
          subHeader="Field selector options"
          warningMessage="Add at least one item for conditional selector to show"
          validateInput={() => true}
          transformInput={toUpperCaseCustom}
          placeholder="Enter selector option"
          successLabel="Update"
          minimumItems={1}
          dismiss={this.closeConditionalSelectorModal}
          onSuccess={(selectorItems) => {
            if (conditionalSelectorItems) {
              this.updateFieldDetail('conditionalSelectorItems', {
                ...conditionalSelectorItems,
                values: {
                  ...conditionalSelectorItems.values,
                  [conditionalSelectorRelatedFieldValue]: selectorItems,
                },
              })
            }
            this.closeConditionalSelectorModal()
          }}
          onReject={this.closeConditionalSelectorModal}
        />
        <AlertModalTwoButton
          open={unsavedChangesModalOpen}
          dismiss={this.closeModals}
          onClick1={this.handleChangesUndo}
          onClick2={this.handleChangesSave}
          buttonLabel1="Undo"
          buttonLabel2="Save"
          header="UNSAVED CHANGES"
          body={
            <div>
              Cannot switch context without first undoing or saving changes.
              <br />
              What do you want to do?
            </div>
          }
        />
        <AlertModalTwoButton
          open={deleteConfirmModalOpen}
          dismiss={this.closeModals}
          onClick1={this.closeModals}
          onClick2={this.deleteSelectedField}
          buttonLabel1="No"
          buttonLabel2="Yes"
          header="Confirm"
          buttonStyle={{ marginTop: 30 }}
          body={<div>{`Are you sure you want to delete field ${fieldConfig[selectedField]?.label}?`}</div>}
        />
        <SaveFailedModal
          open={saveFailedModalOpen}
          header="Failed to save changes"
          body={
            <div>
              Changes won't be permanently applied. Please try again.
              <p>If problem persists, please contact customer support.</p>
            </div>
          }
          buttonLabel={'Ok'}
          onClick={this.toggleSaveFailedModal}
        />
        <LoadingModal open={savingModalOpen}>Saving changes...</LoadingModal>
        <LabelCollector
          open={duplicateModalOpen}
          warning="Enter field name"
          placeholder="Enter field name"
          buttonLabel="Add"
          iconName="setting"
          dismiss={this.toggleDuplicateModal}
          submit={this.copySettingsAndSaveNewField}
        />
        <RouteLeavingGuard
          when={changesMade} // should have true value if there are any changes unsaved
          navigate={(path) => this.props.history.push(path)}
          shouldBlockNavigation={(location) => true}
          alertHeader="Discard changes"
          alertBody={
            <div>
              You have unsaved changes.
              <br />
              Are you sure you want to leave this page without saving?
            </div>
          }
        />
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255, 1), rgba(209,210,230, 1))',
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto 1%',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    paddingInline: 'max(2em, 2%)',
    width: '100%',
    overflow: 'hidden',
  },
  rightSideContent: {
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    // borderRadius: "15px 15px 0 0",
    display: 'flex',
    flex: 1,
    overflow: 'auto',
    backgroundColor: ColorPalette.CARD_WHITE,
  },
  button: {
    fontWeight: 'bolder',
    fontSize: '0.8rem',
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    ':hover': {
      color: ColorPalette.PRIMARY_BLUE,
    },
    ':active': {
      color: ColorPalette.DARK_GREY,
    },
  },
  fieldDetails: {
    width: '91%',
    margin: '0 auto',
  },
}

const mapStateToProps = (state: PeopleFlowCombinedReducer) => {
  return {
    idPassport: state.sessionManager.idPassport,
    password: state.sessionManager.password,
    associationRepo: state.sessionManager.associationRepo as AssociationSettingsRepository,
    userRepo: state.sessionManager.userRepo as UserRepository,
    selectedAssociation: state.sessionManager.selectedAssociation,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateState: (data: any) => dispatch({ type: ActionType.UPDATE_STATE, data }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Radium(DataFields))

const SelectFieldInstruction = () => (
  <div
    style={{
      paddingTop: '25%',
      fontSize: '0.85rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: ColorPalette.SECONDARY_TEXT,
    }}>
    <Icon path={mdiInformationOutline} size={1} color={ColorPalette.PRIMARY_BLUE} style={{ marginRight: '.75em' }} />
    Select a field on the left to manage settings
  </div>
)
