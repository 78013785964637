export enum AllScreenNames {
    BILLING = "Billing",
    BULK_ACTIONS = "Bulk Actions",
    BULK_DISCIPLINE_FORMS = "Forms: Bulk Discipline",
    CLOSED_REQUESTS = "Closed Requests",
    CANDIDATE = "Candidates",
    COMING_SOON = "Coming soon",
    DOCUMENT_VALIDITY = "Document Validity",
    CONFIGURATOR = "Configurator",
    CONFIGURATOR_CONFIG = "Config",
    DATA_FIELDS = "Data Fields",
    DATA_FLOWS = "Data Flows",
    DATA_LOGS = "Data logs",
    DATA_QUERIES = "Data Queries",
    DATA_MANAGER = "Data Manager",
    DEVICE_LOGS = "Device logs",
    DISCIPLINE = "Discipline",
    DISCIPLINE_CONFIG = "Discipline Config",
    DOCUMENTS = "Documents",
    EMPLOYEE = "Employees",
    EXPORT = "Export",
    EXPORT_PROFILES = "Export Profiles",
    EXPORT_DOC_VALIDITY_DATA = "Export doc validity data",
    EXPORT_DOCS = "Export Docs",
    FACE_SHOTS = "Faceshots",
    FORMS = "Forms",
    GLOBAL_LOGS = "Global Logs",
    GROUPS = "Groups",
    GROUPMEMBERS = "Group Members",
    IMPORT = "Import",
    LOGIN = "Login",
    LOGS = "Logs",
    OPEN_REQUESTS = "Open Requests",
    PEOPLE = "People",
    PERFORMANCE = "Performance",
    PPE_CONFIG = "PPE",
    PPE_STOCK = "PPE Stock",
    PROCESSES = "Processes",
    PROFILES = "Profiles",
    REQUESTS = "Requests",
    REQUESTS_PPE = "PPE Requests",
    REQUEST_HANDLER = "Requests",
    REPORTING = "Reporting",
    TERMINATE = "Terminate",
    TERMINATION_REASONS = "Termination",
    TRANSFER = "Transfer",
    TRASH = "Trash",
    USERS = "Users",
}

export function isPeopleFlowScreen(screen: string): boolean {
    const screenvalues = Object.values(AllScreenNames);
    return (screenvalues as string[]).includes(screen);
}
