export const datadogConfig = {
  applicationId: '298615c4-c775-47ce-ad94-38770a66ed1d',
  clientToken: 'pubcd3d47b94db5c621f5d47c189c3945af',
  site: 'datadoghq.eu',
  service: 'web-(test)',
  env: process.env.NODE_ENV,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  // defaultPrivacyLevel: "mask-user-input",
  trackFrustrations: true,
}
