import { isEmpty } from 'lodash'

import { AssociationSettings } from '../models/associationSettings/associationSettings'
import { DocConfig, IDisciplineConfig, IRequestInstanceConfig, AssociationSettingsSchema } from '../models'
import { coalesce, getDivisionJobTypeJobSubType, toLowerCaseCustom } from '../utils'
import { AllScreenNames } from '../config'
import { LocalStorageProvider } from '../provider'
import { DisciplineConfig } from '../models/discipline/idiscipline.config'
import {
  AllAssociationSettings,
  AssociationName,
  ColumnConfig,
  FieldConfigKeyLabelPair,
  FieldName,
  PpeSupplierKeyValuePairs,
  RoleName,
  SignatureConfig,
} from '../types'
import {
  IClientSettings,
  IDataFlow,
  IFieldConfigItem,
  IHiggsGosonPolicy,
  IPermissionHandlerResult,
  IUserAccessResult,
} from '../interfaces'
import { DEFAULT_PROFILE_TABLE_COLUMN_CONFIG } from '../config/tableColumnConfig'
import { DEFAULT_ACTION_EXPIRY, DEFAULT_ACTION_FLOW } from '../constants/disciplineConstants'

export class AssociationSettingsRepository {
  private associationSettings: AllAssociationSettings = {}

  async initialise(allAssociationSettings: AllAssociationSettings, excludeAssociations?: string[]) {
    const associations = Object.keys(allAssociationSettings)
    for (const association of associations) {
      if (excludeAssociations && excludeAssociations.includes(association)) {
        continue
      }
      await this.saveAssociationSettings(association, allAssociationSettings[association])
    }
  }

  async initHydratedCachedSettings(associations?: string[]) {
    function hasCoreSettings(cachedSettings: AllAssociationSettings) {
      return (
        cachedSettings &&
        cachedSettings.iam &&
        cachedSettings.organisationConfig &&
        cachedSettings.fieldConfig &&
        cachedSettings.processConfig
      )
    }

    let assocs: string[] = []
    if (associations?.length) {
      assocs = associations
    } else {
      assocs = Object.keys(this.associationSettings)
    }

    let associationWithHydratedSettings = [] as string[]
    for (const association of assocs) {
      const cachedData = await this.getLocalSettings(association)
      if (hasCoreSettings(cachedData)) {
        associationWithHydratedSettings.push(association)
        this.associationSettings[association] = new AssociationSettings(cachedData)
      }
    }

    return associationWithHydratedSettings
  }

  async saveAssociationSettings(association: string, associationSettings: AssociationSettingsSchema = {}) {
    const initialise = async () => {
      this.associationSettings[association] = new AssociationSettings({} as IClientSettings)
      await this.initialiseLocalSettings(association)
    }

    const getUpdatedData = async () => {
      const cachedData = await this.getLocalSettings(association)
      let updatedData = {
        ...associationSettings,
      }
      if (cachedData) {
        updatedData = {
          ...cachedData,
          ...updatedData,
        }
      }
      return updatedData
    }

    if (!associationSettings) {
      initialise()
      return
    }

    const updatedData = await getUpdatedData()
    await this.putLocal(association, updatedData)
    this.associationSettings[association] = new AssociationSettings(updatedData as IClientSettings)
  }

  async getLocalSettings(association: string) {
    const storageKey = this.getLocalStorageKey(association)
    const cachedData = await LocalStorageProvider.getData(storageKey)
    return cachedData
  }

  async initialiseLocalSettings(association: string) {
    const storageKey = this.getLocalStorageKey(association)
    await LocalStorageProvider.setData(storageKey, {})
  }

  async putLocal(association: string, updatedData: Record<string, any>) {
    const storageKey = this.getLocalStorageKey(association)
    await LocalStorageProvider.setData(storageKey, updatedData)
  }

  getLocalStorageKey(association: string) {
    return `${association}#associationSettings`
  }

  getAssociation(association: string): AssociationSettings {
    // TODO: Need an Association model to be returned here.
    return this.associationSettings[association]
  }

  getAssociationNames(): string[] {
    return Object.keys(this.associationSettings)
  }

  getOtherAssociations(association: string): string[] {
    return this.getAssociationNames().filter((assoc) => assoc !== association)
  }

  getAllDataFlows(association: string): Record<string, IDataFlow> {
    const associationSettingsEntity = this.getAssociation(association)
    return associationSettingsEntity.allDataFlows
  }

  getAllAssociationRoles(association: string): RoleName[] {
    if (!this.associationSettings[association]) {
      return []
    }
    return this.getAssociation(association).getAllRoleNames()
  }

  getUserRoles(association: string): string[] {
    //TODO: Move to UserRepo.
    const associationSettingsEntity = this.getAssociation(association)
    let roleNames = [] as string[]
    const iam = associationSettingsEntity.getIam()

    if (!iam?.permissions) {
      return roleNames
    }
    iam.permissions.forEach((permission: Record<string, any>) => {
      const { role } = permission
      roleNames.push(role)
    })
    return roleNames
  }

  getAllCohorts(association: string): string[] {
    const associationSettingsEngtity = this.getAssociation(association)
    const iam = associationSettingsEngtity.getIam()
    if (iam === undefined || iam.permissions === null) {
      return []
    }
    const cohorts = associationSettingsEngtity.getProfileAccessCohorts()
    return cohorts
  }

  getValidDisplay(currentScreen: AllScreenNames, targetScreen: AllScreenNames) {
    if (currentScreen === AllScreenNames.COMING_SOON) {
      return targetScreen
    }
    return currentScreen
  }

  getPermissions(association: string, selectedRole?: string): IPermissionHandlerResult {
    /**
     * [RS: 15-07-2022] This will be cleaned up (broken into separate parsing utilities) once iam is more mature.
     */
    let result: IPermissionHandlerResult = {
      hasUserManagementAccess: false,
      currentScreen: AllScreenNames.COMING_SOON,
      currentSection: AllScreenNames.COMING_SOON,
      hasDataLogsAccess: true,
      hasDeviceLogsAccess: true,
      hasDocValidityAccess: false,
      hasFormsAccess: false,
      hasImportAccess: false,
      hasRequestHandlerAccess: false,
      hasTrashAccess: false,
      hasWorkforceAccess: false,
      hasDocMarkingAccess: false,
      hasBulkTerminationAccess: false,
      hasConfigEditAccess: false,
      hasBillingAccess: false,
      hasGroupManageAccess: false,
      hasPpeSuperuserAccess: false,
      hasPpeStockManagementAccess: false,
      hasPpeIssueRequestCreationAccess: false,
      path: '',
    }

    const legacyAccess = {
      currentScreen: AllScreenNames.PEOPLE,
      currentSection: AllScreenNames.EMPLOYEE,
      hasDataLogsAccess: true,
      hasDeviceLogsAccess: true,
      hasDocValidityAccess: true,
      hasFormsAccess: true,
      hasImportAccess: true,
      hasRequestHandlerAccess: true,
      hasTrashAccess: true,
      hasUserManagementAccess: true,
      hasWorkforceAccess: true,
      hasDocMarkingAccess: true,
      hasBulkTerminationAccess: true,
      hasConfigEditAccess: true,
      hasBillingAccess: true,
      hasGroupManageAccess: true,
      hasPpeSuperuserAccess: true,
      hasPpeStockManagementAccess: true,
      hasPpeIssueRequestCreationAccess: true,
      path: '/people/employees',
    }
    try {
      const associationSettingsEntity = this.getAssociation(association)
      const iam = associationSettingsEntity.getIam()

      if (iam === undefined) {
        return legacyAccess
      }
      if (!iam.permissions) {
        return result
      }

      const permissions = selectedRole
        ? iam.permissions.filter((permission: Record<string, any>) => permission.role === selectedRole)
        : iam.permissions

      for (const permission of permissions) {
        permission.policies.forEach((policy: IHiggsGosonPolicy) => {
          let { feature, action = '', operations } = policy
          const featureNameLowerCase = toLowerCaseCustom(feature)
          if (toLowerCaseCustom(action) !== 'allow') {
            return
          }
          if (Array.isArray(feature)) {
            feature = feature.length ? feature[0] : ''
          }
          if (featureNameLowerCase.includes('workforce')) {
            result.path = '/people/employees'
            result.hasWorkforceAccess = true
            result.currentScreen = this.getValidDisplay(result.currentScreen, AllScreenNames.PEOPLE)
            result.currentSection = this.getValidDisplay(result.currentSection, AllScreenNames.EMPLOYEE)
          }
          if (featureNameLowerCase.includes('requests')) {
            result.hasRequestHandlerAccess = true
            result.path = coalesce(result.path, '/requests/openrequests')
            result.currentScreen = this.getValidDisplay(result.currentScreen, AllScreenNames.REQUEST_HANDLER)
            result.currentSection = this.getValidDisplay(result.currentSection, AllScreenNames.OPEN_REQUESTS)
          }
          if (featureNameLowerCase.includes('users')) {
            result.hasUserManagementAccess = true
            result.path = coalesce(result.path, '/configurator/users')
            result.currentScreen = this.getValidDisplay(result.currentScreen, AllScreenNames.CONFIGURATOR)
            result.currentSection = this.getValidDisplay(result.currentSection, AllScreenNames.USERS)
          }
          if (featureNameLowerCase.includes('competenc')) {
            result.hasDocValidityAccess = true
            result.path = coalesce(result.path, '/reporting/documentvalidity')
            result.currentScreen = this.getValidDisplay(result.currentScreen, AllScreenNames.REPORTING)
            result.currentSection = this.getValidDisplay(result.currentSection, AllScreenNames.DOCUMENT_VALIDITY)
          }
          if (featureNameLowerCase.includes('form')) {
            result.hasFormsAccess = true
            result.path = coalesce(result.path, '/configurator/forms')
            result.currentScreen = this.getValidDisplay(result.currentScreen, AllScreenNames.CONFIGURATOR)
            result.currentSection = this.getValidDisplay(result.currentSection, AllScreenNames.FORMS)
          }
          if (featureNameLowerCase.includes('trash')) {
            result.hasTrashAccess = true
            result.path = coalesce(result.path, '/people/trash')
            result.currentScreen = this.getValidDisplay(result.currentScreen, AllScreenNames.CONFIGURATOR)
            result.currentSection = this.getValidDisplay(result.currentSection, AllScreenNames.TRASH)
          }
          if (featureNameLowerCase.includes('import')) {
            result.hasImportAccess = true
            result.path = coalesce(result.path, '/import')
            result.currentScreen = this.getValidDisplay(result.currentScreen, AllScreenNames.IMPORT)
            result.currentSection = this.getValidDisplay(result.currentSection, AllScreenNames.IMPORT)
          }
          if (featureNameLowerCase.includes('logs')) {
            if (featureNameLowerCase.includes('viewDataLogs') || operations?.includes('viewDataLogs')) {
              result.hasDataLogsAccess = true
              result.path = coalesce(result.path, '/globallogs/datalogs')
              result.currentScreen = this.getValidDisplay(result.currentScreen, AllScreenNames.DATA_LOGS)
              result.currentSection = this.getValidDisplay(result.currentSection, AllScreenNames.DATA_LOGS)
            }
            if (featureNameLowerCase.includes('viewDeviceLogs') || operations?.includes('viewDeviceLogs')) {
              result.hasDeviceLogsAccess = true
              result.path = coalesce(result.path, '/globallogs/devicelogs')
              result.currentScreen = this.getValidDisplay(result.currentScreen, AllScreenNames.DEVICE_LOGS)
              result.currentSection = this.getValidDisplay(result.currentSection, AllScreenNames.DEVICE_LOGS)
            }
          }
          if (featureNameLowerCase.includes('docs') && operations?.includes('mark')) {
            result.hasDocMarkingAccess = true
          }
          if (featureNameLowerCase.includes('bulk') && operations?.includes('termination')) {
            result.hasBulkTerminationAccess = true
          }
          if (featureNameLowerCase.includes('config')) {
            if (operations?.includes('All')) {
              result.hasConfigEditAccess = true
            }
            if (operations?.includes('manageGroups')) {
              result.hasGroupManageAccess = true
            }
          }
          if (featureNameLowerCase.includes('billing') && operations?.includes('All')) {
            result.hasBillingAccess = true
          }
          if (featureNameLowerCase.includes('ppe')) {
            if (operations?.includes('All')) {
              result.hasPpeSuperuserAccess = true
              result.hasPpeStockManagementAccess = true
              result.hasPpeIssueRequestCreationAccess = true
            }
            if (operations?.includes('manageStock')) {
              result.hasPpeSuperuserAccess = false
              result.hasPpeStockManagementAccess = true
            }
            if (operations?.includes('createIssueRequest')) {
              result.hasPpeSuperuserAccess = false
              result.hasPpeIssueRequestCreationAccess = true
            }
          }
        })
      }
    } catch (error) {
      console.error('error: ', error)
    }
    return result
  }

  getUserAccess(selectedAssociation: string, selectedRole?: string): IUserAccessResult {
    let validPermissionResults = [] as IUserAccessResult[]
    const associations = this.getAssociationNames()
    for (const association of associations) {
      const permissionResult = this.getPermissions(association, selectedRole)

      const {
        hasDataLogsAccess,
        hasDeviceLogsAccess,
        hasDocValidityAccess,
        hasFormsAccess,
        hasImportAccess,
        hasRequestHandlerAccess,
        hasTrashAccess,
        hasUserManagementAccess,
        hasWorkforceAccess,
        hasBulkTerminationAccess,
        hasConfigEditAccess,
        hasBillingAccess,
        hasGroupManageAccess,
        hasPpeSuperuserAccess,
        hasPpeStockManagementAccess,
        hasPpeIssueRequestCreationAccess,
      } = permissionResult

      const hasAccess =
        hasDataLogsAccess ||
        hasDeviceLogsAccess ||
        hasDocValidityAccess ||
        hasFormsAccess ||
        hasImportAccess ||
        hasRequestHandlerAccess ||
        hasTrashAccess ||
        hasUserManagementAccess ||
        hasWorkforceAccess ||
        hasBulkTerminationAccess ||
        hasConfigEditAccess ||
        hasBillingAccess ||
        hasGroupManageAccess ||
        hasPpeSuperuserAccess ||
        hasPpeStockManagementAccess ||
        hasPpeIssueRequestCreationAccess

      if (hasAccess) {
        const userAccessResult = { association, permissionResult, hasAccess: true } as IUserAccessResult
        if (association === selectedAssociation) {
          return userAccessResult
        }
        if (hasWorkforceAccess && hasRequestHandlerAccess && hasUserManagementAccess && hasDocValidityAccess) {
          validPermissionResults.unshift(userAccessResult)
        } else {
          validPermissionResults.push(userAccessResult)
        }
      }
    }

    if (validPermissionResults.length) {
      return validPermissionResults[0]
    }
    return { hasAccess: false }
  }

  getAuthorisedPks(association: string, selectedCohort: string): string[] {
    if (selectedCohort === '') {
      console.error('selectedCohort is not defined for the user')
      return []
    }
    const profileAccess = this.getAssociation(association).getProfileAccess()
    if (selectedCohort) {
      return profileAccess[selectedCohort] || []
    }
    return []
  }

  getDocConfig(association: string): Record<string, any> {
    const associationSettingsEntity = this.getAssociation(association)
    return associationSettingsEntity.getDocConfig()
  }

  getPublishedDocTemplates(association: string): Record<string, any> {
    const docConfig = this.getDocConfig(association)
    const isPublished = (docObj: DocConfig) => !isEmpty(docObj.published)

    return Object.keys(docConfig).reduce((acc: Record<string, any>, key) => {
      if (isPublished(docConfig[key])) {
        acc[key] = docConfig[key]
      }
      return acc
    }, {})
  }

  getFieldConfig(association: string): Record<string, IFieldConfigItem> {
    const associationSettingsEntity = this.getAssociation(association)
    return associationSettingsEntity.getFieldConfig()
  }

  getProcessConfig(association: string): Record<string, any> {
    const associationSettingsEntity = this.getAssociation(association)
    return associationSettingsEntity.getProcessConfig()
  }

  getAllPossibleCompetencies(association: string): string[] {
    const processConfig = this.getProcessConfig(association)
    let allCompetencies: string[] = []

    Object.keys(processConfig.competenciesv2.competencyConfig).forEach((division) => {
      Object.keys(processConfig.competenciesv2.competencyConfig[division]).forEach((jobType) => {
        Object.keys(processConfig.competenciesv2.competencyConfig[division][jobType]).forEach((jobSubType) => {
          const requiredCompetencies: string[] =
            processConfig.competenciesv2.competencyConfig[division][jobType][jobSubType].requiredCompetencies
          allCompetencies = [...new Set([...requiredCompetencies, ...allCompetencies])]
        })
      })
    })

    return allCompetencies
  }

  getDisciplinaryConfig(association: string): IDisciplineConfig {
    const processConfig = this.getProcessConfig(association)
    return new DisciplineConfig(processConfig)
  }

  getActionFlowConfig(
    association: string,
    division: string,
    jobType: string,
    jobSubType: string,
    offence: string,
    action: string,
  ): any {
    const disciplinaryConfig = this.getDisciplinaryConfig(association)
    if (disciplinaryConfig === null) {
      return {}
    }
    return disciplinaryConfig.extractActionFlowConfig(division, jobType, jobSubType, offence, action)
  }

  getPossibleOffences(association: string): string[] {
    const disciplinaryConfig = this.getDisciplinaryConfig(association)
    return disciplinaryConfig.extractPossibleOffences()
  }

  getPoorPerformanceName(association: string): string {
    const disciplinaryConfig = this.getDisciplinaryConfig(association)
    return disciplinaryConfig.extractPoorPerformanceName()
  }

  getAvailableExportConfigs(association: string): string[] {
    const associationSettingsEntity = this.getAssociation(association)
    const availableExportConfigs = associationSettingsEntity.getAvailableExportConfigs()
    return availableExportConfigs
  }

  getOrganisationConfig(association: string): Record<string, any> {
    const associationSettings = this.associationSettings[association]
    if (associationSettings === undefined) {
      return {}
    }
    return associationSettings['organisationConfig'] || {}
  }

  getOrganisationConfigPPESuppliers(association: string): PpeSupplierKeyValuePairs {
    const organisationConfig = this.getOrganisationConfig(association)
    return organisationConfig['ppeSuppliers'] || {}
  }

  populateFilterConfig(
    filterConfig: { key: string; label: string }[],
    fieldConfig: Record<FieldName, IFieldConfigItem>,
  ): { key: string; label: string }[] {
    filterConfig = [...filterConfig].filter((configItem) => configItem.key in fieldConfig)
    filterConfig = [...filterConfig].map((configItem) => {
      configItem.label = fieldConfig[configItem.key].label
      return configItem
    })
    return filterConfig
  }

  getProfileFilterConfig(association: string): { key: string; label: string }[] {
    const associationSettingsEntity = this.getAssociation(association)
    const profileFilterConfig = associationSettingsEntity.getOrganisationConfigItem('profileFilterConfigWeb') as {
      key: string
      label: string
    }[]
    if (profileFilterConfig === undefined) {
      return []
    }
    const fieldConfig = this.getFieldConfig(association)
    return this.populateFilterConfig(profileFilterConfig, fieldConfig)
  }

  getProfileTableColumnConfig(association: string): ColumnConfig[] {
    const defaultColumnConfig: ColumnConfig[] = DEFAULT_PROFILE_TABLE_COLUMN_CONFIG
    const associationSettingsEntity = this.getAssociation(association)
    const profileTableColumnConfig = associationSettingsEntity.getOrganisationConfigItem(
      'profileTableColumnConfigWeb',
    ) as {
      key: string
      label: string
      sizeFactor: number
    }[]
    if (profileTableColumnConfig === undefined) {
      return defaultColumnConfig
    }
    return profileTableColumnConfig
  }

  getSignatureConfig(association: string): SignatureConfig {
    const defaultSignatureConfig = {
      signatoryTypes: ['EMPLOYEE', 'MANAGER', 'WITNESS'],
    }
    const associationSettingsEntity = this.getAssociation(association)
    const signatureConfig = associationSettingsEntity.getOrganisationConfigItem('signatureConfig')
    if (signatureConfig === undefined) {
      return defaultSignatureConfig
    }
    return signatureConfig
  }

  getAllAssociationUsers(association: string) {
    const associationSettingsEntity = this.getAssociation(association)
    return associationSettingsEntity.getAllUsers()
  }

  getRequestConfig(association: string): Record<string, IRequestInstanceConfig> {
    const associationSettingsEntity = this.getAssociation(association)
    return associationSettingsEntity.getRequestConfig() || {}
  }

  getSelectedRequestConfig(association: string, requestLabel: string): IRequestInstanceConfig | undefined {
    const allRequestConfig = this.getRequestConfig(association)
    if (allRequestConfig === undefined) {
      return
    }
    for (const requestKey of Object.keys(allRequestConfig)) {
      if (allRequestConfig[requestKey].getRequestName() === requestLabel) {
        return allRequestConfig[requestKey]
      }
    }
    return
  }

  setAllAssocationRoles(association: AssociationName, rolesNames: RoleName[]) {
    this.getAssociation(association).setRoleNames(rolesNames)
  }

  setRequestConfig(association: string, requestConfig: Record<string, IRequestInstanceConfig>): void {
    this.getAssociation(association).setRequestConfig(requestConfig)
  }

  setDocConfig(association: string, docConfig: Record<string, DocConfig>): void {
    this.getAssociation(association).setDocConfig(docConfig)
  }

  setOrganisationConfigPPESuppliers(association: string, suppliers: PpeSupplierKeyValuePairs): void {
    this.getAssociation(association).getOrganisationConfig().ppeSuppliers = suppliers
  }

  setDataFlowConfig(association: string, dataFlowName: string, config: IDataFlow) {
    const associationSettingsEntity = this.getAssociation(association)
    associationSettingsEntity.allDataFlows[dataFlowName] = config
  }

  setDivisionConfig(association: string, divisionConfig: Record<string, any>): void {
    const associationSettingsEntity = this.getAssociation(association)
    associationSettingsEntity.setOrganisationConfigItem('divisionConfig', divisionConfig)
  }

  setFieldConfig(association: string, config: Record<string, IFieldConfigItem>): void {
    const associationSettingsEntity = this.getAssociation(association)
    associationSettingsEntity.setFieldConfig(config)
  }

  setFieldConfigItem(association: string, item: Record<string, any>): void {
    const itemName = Object.keys(item)[0]
    const associationSettingsEntity = this.getAssociation(association)
    associationSettingsEntity.setFieldConfigItem(itemName, item[itemName])
  }

  setProcessConfigItem(association: string, item: Record<string, any>): void {
    const associationSettingsEntity = this.getAssociation(association)
    associationSettingsEntity.setProcessConfigItem(item)
  }

  initOffenceConfigDataPath(offenceSpecificConfig: any, division: string, jobType: string, jobSubType: string) {
    const { divisionNode, jobTypeNode, jobSubTypeNode } = getDivisionJobTypeJobSubType(
      offenceSpecificConfig,
      division,
      jobType,
      jobSubType,
      true,
    )

    if (!offenceSpecificConfig.hasOwnProperty(divisionNode)) {
      offenceSpecificConfig[divisionNode] = {
        allJobTypes: {
          allJobSubTypes: {
            actionExpiry: DEFAULT_ACTION_EXPIRY,
            actionFlow: offenceSpecificConfig.allJobTypes.allJobSubTypes.actionFlow,
          },
        },
      }
    }
    if (!offenceSpecificConfig[divisionNode].hasOwnProperty(jobTypeNode)) {
      offenceSpecificConfig[divisionNode][jobTypeNode] = {
        allJobSubTypes: {
          actionExpiry: DEFAULT_ACTION_EXPIRY,
          actionFlow: offenceSpecificConfig[divisionNode].allJobTypes.allJobSubTypes.actionFlow,
        },
      }
    }
    if (!offenceSpecificConfig[divisionNode][jobTypeNode].hasOwnProperty(jobSubTypeNode)) {
      offenceSpecificConfig[divisionNode][jobTypeNode][jobSubTypeNode] = {
        actionExpiry: DEFAULT_ACTION_EXPIRY,
        actionFlow: offenceSpecificConfig[divisionNode][jobTypeNode].allJobSubTypes.actionFlow,
      }
    }

    return offenceSpecificConfig[divisionNode][jobTypeNode][jobSubTypeNode]
  }

  setOffenceConfigActionExpiry(
    association: string,
    division: string,
    jobType: string,
    jobSubType: string,
    offence: string,
    action: string,
    expiryInMilliseconds: number,
  ): void {
    const existingOffenceConfig = this.getAssociation(association).getProcessConfig().discipline.offenceConfig
    if (existingOffenceConfig && existingOffenceConfig.hasOwnProperty(offence)) {
      const path = this.initOffenceConfigDataPath(existingOffenceConfig[offence], division, jobType, jobSubType)
      // @ts-ignoreact
      path.actionExpiry[action] = expiryInMilliseconds
    }
  }

  setOffenceConfigActionFlowItems(
    association: string,
    division: string,
    jobType: string,
    jobSubType: string,
    offence: string,
    actionFlowItems: string[],
  ) {
    const existingOffenceConfig = this.getAssociation(association).getProcessConfig().discipline.offenceConfig

    if (existingOffenceConfig && existingOffenceConfig.hasOwnProperty(offence)) {
      const path = this.initOffenceConfigDataPath(existingOffenceConfig[offence], division, jobType, jobSubType)
      // @ts-ignore
      path.actionFlow = [...actionFlowItems]
    }
  }

  addOffenceConfigActionFlowItem(
    association: string,
    division: string,
    jobType: string,
    jobSubType: string,
    offence: string,
    actionFlowName: string,
  ): void {
    const existingOffenceConfig = this.getAssociation(association).getProcessConfig().discipline.offenceConfig

    if (existingOffenceConfig && existingOffenceConfig.hasOwnProperty(offence)) {
      const { divisionNode, jobTypeNode, jobSubTypeNode } = getDivisionJobTypeJobSubType(
        existingOffenceConfig[offence],
        division,
        jobType,
        jobSubType,
        true,
      )

      if (
        // @ts-ignore
        !this.getAssociation(association)
          .getProcessConfig()
          .discipline.offenceConfig[offence][divisionNode].hasOwnProperty(jobTypeNode)
      ) {
        // @ts-ignore
        this.getAssociation(association).getProcessConfig().discipline.offenceConfig[offence][divisionNode][
          jobTypeNode
        ] = {
          allJobSubTypes: {
            actionExpiry: DEFAULT_ACTION_EXPIRY,
            actionFlow: DEFAULT_ACTION_FLOW,
          },
        }
      }

      const existingActionFlowItems =
        // @ts-ignore
        this.getAssociation(association).getProcessConfig().discipline.offenceConfig[offence][divisionNode][
          jobTypeNode
        ][jobSubTypeNode].actionFlow

      if (!existingActionFlowItems.includes(actionFlowName)) {
        const actionFlow =
          // @ts-ignore
          this.getAssociation(association).getProcessConfig().discipline.offenceConfig[offence][divisionNode][
            jobTypeNode
          ][jobSubTypeNode].actionFlow
        const terminateActionIndex = actionFlow.findIndex((action: string) => action === 'TERMINATE')

        if (terminateActionIndex !== -1) {
          // @ts-ignore
          this.getAssociation(association)
            .getProcessConfig()
            .discipline.offenceConfig[offence][divisionNode][jobTypeNode][jobSubTypeNode].actionFlow.splice(
              terminateActionIndex,
              0,
              actionFlowName,
            )
        } else {
          // @ts-ignore
          this.getAssociation(association)
            .getProcessConfig()
            .discipline.offenceConfig[offence][divisionNode][jobTypeNode][jobSubTypeNode].actionFlow.push(
              actionFlowName,
            )
        }
      }
    }
  }

  addOffenceConfigItem(
    association: string,
    division: string,
    jobType: string,
    jobSubType: string,
    offence: string,
  ): void {
    const existingOffenceConfig = this.getAssociation(association).getProcessConfig().discipline.offenceConfig
    if (existingOffenceConfig && !existingOffenceConfig.hasOwnProperty(offence)) {
      const { divisionNode, jobTypeNode, jobSubTypeNode } = getDivisionJobTypeJobSubType(
        existingOffenceConfig[offence],
        division,
        jobType,
        jobSubType,
        true,
      )

      // @ts-ignore (Object possibly undefined)
      this.getAssociation(association).getProcessConfig().discipline.offenceConfig[offence] = {
        [divisionNode]: {
          [jobTypeNode]: {
            [jobSubTypeNode]: {
              actionFlow: DEFAULT_ACTION_FLOW,
              actionExpiry: DEFAULT_ACTION_EXPIRY,
            },
          },
        },
      }
    }
  }

  removeOffenceConfigActionFlowItem(
    association: string,
    division: string,
    jobType: string,
    jobSubType: string,
    offence: string,
    actionFlowItemIndex: number,
  ): void {
    const existingOffenceConfig = this.getAssociation(association).getProcessConfig().discipline.offenceConfig

    if (existingOffenceConfig && existingOffenceConfig.hasOwnProperty(offence)) {
      const { divisionNode, jobTypeNode, jobSubTypeNode } = getDivisionJobTypeJobSubType(
        existingOffenceConfig[offence],
        division,
        jobType,
        jobSubType,
      )

      const existingActionFlowItems =
        // @ts-ignore
        this.getAssociation(association).getProcessConfig().discipline.offenceConfig[offence][divisionNode][
          jobTypeNode
        ][jobSubTypeNode].actionFlow

      if (existingActionFlowItems.length > actionFlowItemIndex) {
        // @ts-ignore
        this.getAssociation(association)
          .getProcessConfig()
          .discipline.offenceConfig[offence][divisionNode][jobTypeNode][jobSubTypeNode].actionFlow.splice(
            actionFlowItemIndex,
            1,
          )
      }
    }
  }

  setTerminationReasonConfigItem(association: string, item: Record<string, any>): void {
    const itemName = Object.keys(item)[0]
    const existingTerminationReasonConfig =
      this.getAssociation(association).getProcessConfig().terminate.terminationReasonConfig

    if (existingTerminationReasonConfig && existingTerminationReasonConfig.hasOwnProperty(itemName)) {
      // @ts-ignore (Object is possibly 'undefined')
      this.getAssociation(association).getProcessConfig().terminate.terminationReasonConfig[itemName] = item[itemName]
    }
  }
}
