import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat.js'
import { CsvDateFormatStandard, CsvDateFormatStandardType } from '../services'
import { checkDigitsOnly } from './regexUtils'
dayjs.extend(customParseFormat)

interface IDateParts {
  year: number
  month: string
  monthNumber: string
  day: string
  hour: string
  minutes: string
}

export function getMonthName(date: Date): string {
  return numberMonthMap[date.getMonth().toString()]
}

function leftPadInteger(num: string | number): string {
  if (typeof num === 'string') {
    num = parseInt(num)
  }
  if (num < 10) {
    return `0${num}`
  }

  return `${num}`
}

export function formatDateAndTime(date: Date): IDateParts {
  let year = date.getFullYear()
  let month = getMonthName(date)
  let monthNumber = leftPadInteger(date.getMonth() + 1)
  let day = leftPadInteger(date.getDate())
  let hour = leftPadInteger(date.getHours())
  let minutes = leftPadInteger(date.getMinutes())

  return { year, month, monthNumber, day, hour, minutes }
}

export const numberMonthMap: Record<string, string> = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'Jun',
  6: 'Jul',
  7: 'Aug',
  8: 'Sep',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec',
}

export const monthNumberMap: Record<string, string> = {
  Jan: '0',
  Feb: '1',
  Mar: '2',
  Apr: '3',
  May: '4',
  Jun: '5',
  Jul: '6',
  Aug: '7',
  Sep: '8',
  Oct: '9',
  Nov: '10',
  Dec: '11',
}

export function extractBirthdayFromId(saId: string): string {
  const dayDigits = saId.substring(4, 6)
  const monthDigits = saId.substring(2, 4)
  const yearDigits = parseInt(saId.substring(0, 2))

  const month = parseInt(monthDigits) + 1
  const year = yearDigits < 20 ? `20${yearDigits}` : `19${yearDigits}`

  if (dayDigits && month && year) {
    return `${year}/${month}/${dayDigits}`
  }

  return ''
}

export function isSameDate(date1: Date, date2: Date): boolean {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  )
}

export const parseDateString = (
  inputDateString: string,
  dateFormatStandard: CsvDateFormatStandardType = CsvDateFormatStandard.UK,
  outputFormat: string = 'YYYY/MM/DD',
) => {
  // array of accepted date formats
  const dateFormatVariations = [
    /\d{1,2}[/-]\d{1,2}[/-]\d{4}/, // DD/MM/YYYY (UK) or MM/DD/YYYY (US)
    /\d{4}[/-]\d{1,2}[/-]\d{1,2}/, // YYYY/MM/DD or YYYY/DD/MM
  ]

  let matched = false
  let yearPart = ''
  let monthPart = ''
  let dayPart = ''
  let delimiter = ''

  // set delimiter based on input string
  if (inputDateString.indexOf('-') > -1) {
    delimiter = '-'
  }
  if (inputDateString.indexOf('/') > -1) {
    delimiter = '/'
  }
  if (!delimiter) return null

  // loop through the date formats and check for a match
  for (const format of dateFormatVariations) {
    const match = inputDateString.match(format)

    if (match) {
      const matchedFormatString = format.source
      const yearFirst = matchedFormatString.indexOf('\\d{4}') === 0

      const parts = inputDateString.split(delimiter)
      if (yearFirst) {
        yearPart = parts[0]
        if (dateFormatStandard === CsvDateFormatStandard.UK) {
          monthPart = parts[1].padStart(2, '0')
          dayPart = parts[2].padStart(2, '0')
        }
        if (dateFormatStandard === CsvDateFormatStandard.US) {
          dayPart = parts[1].padStart(2, '0')
          monthPart = parts[2].padStart(2, '0')
        }
      } else {
        // YYYY comes last
        yearPart = parts[2]
        if (dateFormatStandard === CsvDateFormatStandard.UK) {
          monthPart = parts[1].padStart(2, '0')
          dayPart = parts[0].padStart(2, '0')
        }
        if (dateFormatStandard === CsvDateFormatStandard.US) {
          dayPart = parts[1].padStart(2, '0')
          monthPart = parts[0].padStart(2, '0')
        }
      }
      matched = true
      break
    }
  }

  if (matched) {
    // parse the date according to the matched format
    const parsedDate = dayjs(`${yearPart}${monthPart}${dayPart}`, 'YYYYMMDD')
    return parsedDate.format(outputFormat)
  }

  return null
}

export const timestampToDateString = (timestamp: string | number, format: string = 'YYYY/MM/DD') => {
  if (typeof timestamp === 'string') {
    timestamp = parseInt(timestamp)
  }
  return dayjs.unix(timestamp / 1000).format(format)
}

export function getDateString(date: Date) {
  const { year, monthNumber: month, day } = formatDateAndTime(date)
  return `${year}/${month}/${day}`
}

export function toValidPfDate(date: any, targetFormat: string = 'YYYY/MM/DD') {
  try {
    if (!date) {
      return ''
    }
    function getSourceFormat(dateString: string) {
      const formatOptions = {
        YEAR_FIRST: [
          'YYYY/MM/DD',
          'YYYY/MM/D',
          'YYYY/M/D',
          'YYYY/M/DD',
          'YYYY-MM-DD',
          'YYYY-MM-D',
          'YYYY-M-D',
          'YYYY-M-DD',
          'YYYYMMDD',
          'YYYYMDD',
          'YYYYMMD',
          'YYYYMD',
        ],
        DAY_FIRST: [
          'DD/MM/YYYY',
          'D/MM/YYYY',
          'D/M/YYYY',
          'DD/M/YYYY',
          'DD-MM-YYYY',
          'D-MM-YYYY',
          'D-M-YYYY',
          'DD-M-YYYY',
          'DDMMYYYY',
          'DDMYYYY',
          'DMMYYYY',
          'DMYYYY',
          'DD MM YYYY',
          'DD M YYYY',
          'D MM YYYY',
          'D M YYYY',
        ],
      } as Record<string, string[]>
      const dateOrders = Object.keys(formatOptions)
      for (const dateOrder of dateOrders) {
        for (const format of formatOptions[dateOrder]) {
          if (dayjs(dateString, format).format(format) === dateString) {
            return format
          }
        }
      }
      return ''
    }
    function getDateString(date: string, sourceFormat: string, targetFormat: string) {
      const targetDate = dayjs(date, sourceFormat).format(targetFormat)
      if (targetDate.toLowerCase().includes('invalid')) {
        return date
      }
      return targetDate
    }
    if (Array.isArray(date)) {
      if (date.length) {
        date = date[0]
      }
    }
    if (checkDigitsOnly(date) && `${date}`.length >= 10) {
      const unixMsString = `${date}`.substring(0, 10)
      return dayjs.unix(parseInt(unixMsString)).format(targetFormat)
    }
    const sourceFormat = getSourceFormat(date)
    if (!sourceFormat) {
      return date
    }
    return getDateString(date, sourceFormat, targetFormat)
  } catch (error) {
    return ''
  }
}
