import { ColorPalette } from '../../../config/colors'
import { filePicker, writeRemoteFilesToLocalStorage } from '../../../utils'

import React, { Component } from 'react'
import Radium from 'radium'
import Grid from '@material-ui/core/Grid'
import Icon from '@mdi/react'
import { mdiDelete, mdiDownload, mdiExport } from '@mdi/js'
import { createStyles } from '@material-ui/core/styles'
import { GridSize } from '@material-ui/core'

import ButtonGeneric from '../../BaseComponents/Buttons/ButtonGeneric'
import FileTypeButton from '../../BaseComponents/Buttons/FileTypeButton'
import FileSelectorButton, { IFileSelectorButtonSize } from '../../BaseComponents/Buttons/FileSelectorButton'
import LoadingModal from '../../../components/Modals/LoadingModal'
import AlertModalOneButton from '../../../components/Modals/AlertModalOneButton'

interface FileSelectorProps {
  style?: React.CSSProperties | undefined
  controlBarContainerStyle?: React.CSSProperties
  gridSize?: GridSize | undefined
  initialFiles: Record<string, any>[]
  maxFiles?: number
  defaultMode?: string
  addFilesEnabled?: boolean
  toggleEnabled?: boolean
  deletionEnabled?: boolean
  downloadEnabled?: boolean
  exportEnabled?: boolean
  onDeleteClick?: (fileName: string[]) => void
  onDownloadClick?: (fileName: string[]) => void
  onExportClick?: (fileName: string[]) => void
  viewPdf?: (filePath: string) => void
  exportFiles?: (files: Record<string, any>[]) => void
}

interface FileSelectorState {
  fileToView: string
  allFiles: Record<string, any>[]
  selectedFiles: string[]
  selectionModeEnabled: boolean
  controlButtonOrder: string[]
  warning: string
  loadingModalOpen: boolean
  warningModalOpen: boolean
  loadingModalMessage: string
  warningModalHeader: string
  warningModalMessage: string
}

class FileSelector extends Component<FileSelectorProps, FileSelectorState> {
  selectionButtonOrder = ['modeSelection', 'deletion', 'download', 'export']
  viewButtonOrder = ['modeSelection']
  initialModalState = {
    loadingModalOpen: false,
    warningModalOpen: false,
  }
  state: FileSelectorState = {
    ...this.initialModalState,
    fileToView: '',
    loadingModalMessage: '',
    warningModalHeader: '',
    warningModalMessage: '',
    allFiles: [],
    selectedFiles: [],
    selectionModeEnabled: true,
    controlButtonOrder: this.selectionButtonOrder,
    warning: '',
  }

  componentDidMount() {
    if (this.props.defaultMode === 'view') {
      this.setState(
        {
          allFiles: this.props.initialFiles,
          selectionModeEnabled: false,
          controlButtonOrder: this.viewButtonOrder,
        },
        () => this.generateControlButtons(),
      )
    } else {
      this.setState({ allFiles: this.props.initialFiles }, () => this.generateControlButtons())
    }
  }

  closeModals = () => {
    this.setState({ ...this.initialModalState })
  }

  getAllFiles() {
    return this.state.allFiles
  }

  onAddFile = (event: any) => {
    const { maxFiles = 30 } = this.props
    let { allFiles } = this.state
    let warning = ''
    filePicker(event).forEach((fileData) => {
      if (allFiles.length >= maxFiles) {
        warning = `Only ${maxFiles} allowed`
        return
      }
      if (!allFiles.includes(fileData.name)) {
        allFiles.push(fileData)
      }
    })
    this.setState({ allFiles, warning })
  }

  onFileSelect = (fileName: string) => {
    let { selectedFiles, selectionModeEnabled, allFiles } = this.state

    if (selectionModeEnabled) {
      if (selectedFiles.includes(fileName)) {
        selectedFiles = selectedFiles.filter((selectedFileName) => selectedFileName !== fileName)
      } else {
        selectedFiles.push(fileName)
      }
      this.setState({ selectedFiles })
    } else {
      let warningModalOpen = false
      let warningModalHeader = ''
      let warningModalMessage = ''

      allFiles.forEach((fileData) => {
        if (fileData.name === fileName) {
          if (fileData.type === 'application/pdf') {
            if (this.props.viewPdf === undefined) {
              return
            }
            this.props.viewPdf(fileData.url)
          } else {
            warningModalOpen = true
            warningModalHeader = 'Coming soon'
            warningModalMessage =
              'Only PDF files can be viewed here at the moment. Support for other file types will be coming soon.'
          }
        }
      })
      this.setState({
        warningModalOpen,
        warningModalHeader,
        warningModalMessage,
        fileToView: fileName,
        warning: '',
      })
    }
  }

  toggleMode = () => {
    const selectionModeEnabled = !this.state.selectionModeEnabled
    let controlButtonOrder = selectionModeEnabled ? this.selectionButtonOrder : this.viewButtonOrder
    this.setState({
      warning: '',
      fileToView: '',
      selectedFiles: [],
      selectionModeEnabled,
      controlButtonOrder,
    })
  }

  deleteFiles = () => {
    let { selectedFiles, allFiles } = this.state
    if (!selectedFiles.length) {
      this.setState({ warning: allFiles.length ? 'No files selected' : 'No files added yet' })
      return
    }
    allFiles = allFiles.filter((fileData) => !selectedFiles.includes(fileData.name))
    this.setState({ allFiles, selectedFiles: [], warning: '' })
  }

  downloadFiles = async () => {
    let { selectedFiles, allFiles } = this.state
    if (!selectedFiles.length) {
      this.setState({ warning: allFiles.length ? 'No files selected' : 'No files added yet' })
      return
    }

    let containsLocalFiles = false
    let exportArray: { docPath: string; filename: string }[] = []
    allFiles.forEach((fileData) => {
      if (selectedFiles.includes(fileData.name) && !fileData.url.includes('blob:http')) {
        exportArray.push({ filename: fileData.name, docPath: fileData.url })
      } else if (fileData.url.includes('blob:http')) {
        containsLocalFiles = true
      }
    })
    this.setState({
      ...this.initialModalState,
      warningModalOpen: true,
      warningModalHeader: 'Download Started',
      warningModalMessage: `Your selected files have begun downloading. ${
        containsLocalFiles
          ? "(Please note that some of your selected files are already on your device and won't be downloaded)."
          : ''
      } `,
    })
    await writeRemoteFilesToLocalStorage(exportArray)
  }

  exportFiles = () => {
    let { selectedFiles, allFiles } = this.state
    if (!selectedFiles.length) {
      this.setState({ warning: allFiles.length ? 'No files selected' : 'No files added yet' })
      return
    }
    this.setState({ warning: '' })
    if (this.props.exportFiles !== undefined) {
      let exportArray: Record<string, any>[] = []
      selectedFiles.forEach((fileName) => {
        allFiles.forEach((fileData) => (fileData.name === fileName ? exportArray.push(fileData) : null))
      })
      this.props.exportFiles(exportArray)
    }
  }

  generateControlButtons(): JSX.Element {
    let buttonArray: JSX.Element[] = []
    const { selectionModeEnabled, controlButtonOrder } = this.state
    const { toggleEnabled } = this.props

    controlButtonOrder.forEach((buttonType) => {
      if (buttonType === 'modeSelection') {
        buttonArray.push(
          <ButtonGeneric
            key={buttonType}
            style={{
              ...getStyle('controlButtonStyle'),
              fontStyle: 'italic',
              borderLeft: 'none',
              filter: toggleEnabled ? 'brightness(100%)' : 'brightness(90%)',
            }}
            disabled={!this.props.toggleEnabled}
            label={selectionModeEnabled ? 'SELECTION' : 'VIEW MODE'}
            onClick={this.toggleMode}
          />,
        )
      } else if (buttonType === 'deletion' && this.props.deletionEnabled) {
        const icon = <Icon path={mdiDelete} color={ColorPalette.CARD_WHITE} style={getStyle('buttonIconStyle')} />
        buttonArray.push(
          <ButtonGeneric
            key={buttonType}
            style={{ ...getStyle('controlButtonStyle') }}
            iconBefore={icon}
            label={'REMOVE'}
            onClick={this.deleteFiles}
          />,
        )
      } else if (buttonType === 'download' && this.props.downloadEnabled) {
        const icon = <Icon path={mdiDownload} color={ColorPalette.CARD_WHITE} style={getStyle('buttonIconStyle')} />
        buttonArray.push(
          <ButtonGeneric
            key={buttonType}
            style={{ ...getStyle('controlButtonStyle') }}
            iconBefore={icon}
            label={'DOWNLOAD'}
            onClick={this.downloadFiles}
          />,
        )
      } else if (buttonType === 'export' && this.props.exportEnabled) {
        const icon = <Icon path={mdiExport} color={ColorPalette.CARD_WHITE} style={getStyle('buttonIconStyle')} />
        buttonArray.push(
          <ButtonGeneric
            key={buttonType}
            style={{ ...getStyle('controlButtonStyle') }}
            iconBefore={icon}
            label={'EXPORT'}
            onClick={this.exportFiles}
          />,
        )
      }
    })
    const controlBar = (
      <div style={{ ...getStyle('controlBarContainer'), ...this.props.controlBarContainerStyle }}>{buttonArray}</div>
    )
    return controlBar
  }

  generateNoFilesWarning = () => {
    return (
      <Grid
        style={{ ...getStyle('buttonContainer'), marginTop: 25 }}
        item
        container
        xs={12}
        direction="column"
        justify="flex-start"
        alignItems="center">
        <p style={getStyle('promptText')}>No files to view</p>
      </Grid>
    )
  }

  generateAddFileButton = () => {
    const largeButtonStyle = {
      alignSelf: 'center',
      width: '90%',
      height: 170,
    }
    const smallButtonStyle = {
      width: 65,
      height: 65,
    }
    return (
      <Grid
        style={{ ...getStyle('buttonContainer') }}
        item
        container
        xs={this.state.allFiles.length ? 4 : 12}
        direction="column"
        justify="flex-start"
        alignItems="center">
        <FileSelectorButton
          style={this.state.allFiles.length ? smallButtonStyle : largeButtonStyle}
          id="AddFileFileSelector"
          buttonLabel="Add"
          size={this.state.allFiles.length ? IFileSelectorButtonSize.SMALL : IFileSelectorButtonSize.LARGE}
          fileHandler={(event) => this.onAddFile(event)}
        />
      </Grid>
    )
  }

  generateFileButtons(): JSX.Element[] {
    const { allFiles, selectionModeEnabled, fileToView, selectedFiles } = this.state
    let fileButtons = allFiles.map((fileData) => {
      return (
        <Grid
          style={{ ...getStyle('buttonContainer') }}
          item
          container
          xs={4}
          direction="column"
          justify="flex-start"
          alignItems="center">
          <FileTypeButton
            fileType={fileData.type}
            fileName={fileData.name}
            active={fileToView === fileData.name}
            selected={selectedFiles.includes(fileData.name)}
            onClick={() => this.onFileSelect(fileData.name)}
          />
        </Grid>
      )
    })
    if (!selectionModeEnabled && !allFiles.length) {
      fileButtons.push(this.generateNoFilesWarning())
      return fileButtons
    }
    if (this.props.addFilesEnabled) {
      fileButtons.push(this.generateAddFileButton())
    }
    return fileButtons
  }

  render() {
    const controlButtons = this.generateControlButtons()
    const fileButtons = this.generateFileButtons()
    const { gridSize = 12 } = this.props
    const {
      warning,
      warningModalOpen,
      warningModalHeader,
      warningModalMessage,
      loadingModalMessage,
      loadingModalOpen,
    } = this.state

    let warningComponent = null
    if (warning) {
      warningComponent = <p style={getStyle('warningText')}>{warning}</p>
    }

    return (
      <div style={{ ...getStyle('container'), ...this.props.style }}>
        <Grid container direction="column" justify="flex-start" alignItems="center" xs={gridSize}>
          {controlButtons}
          {warningComponent}
          <Grid
            style={{ paddingBottom: 30, paddingLeft: 20, paddingRight: 20 }}
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            xs={12}>
            {fileButtons}
          </Grid>
        </Grid>

        <AlertModalOneButton
          open={warningModalOpen}
          header={warningModalHeader}
          body={warningModalMessage}
          buttonLabel={'Ok'}
          onClick={this.closeModals}
        />
        <LoadingModal open={loadingModalOpen}>{loadingModalMessage}</LoadingModal>
      </div>
    )
  }
}

const styles = createStyles({
  container: {
    border: `1px solid rgba(195,205,225,0.6)`,
    borderRadius: 12,
    width: '86%',
  },
  controlBarContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 35,
    paddingLeft: 0,
    paddingRight: 10,
    backgroundColor: ColorPalette.DARK_GREY,
    borderTopLeftRadius: 11,
    borderTopRightRadius: 11,
  },
  controlButtonStyle: {
    paddingLeft: 5,
    paddingRight: 5,
    borderLeft: `1px solid ${ColorPalette.CARD_WHITE}`,
    height: 30,
    fontSize: '0.75rem',
    justifyContent: 'center',
    color: ColorPalette.PRIMARY_LIGHT_TEXT,
    ':hover': {
      filter: 'brightness(90%)',
    },
    ':active': {
      filter: 'brightness(75%)',
    },
  },
  buttonContainer: {
    marginTop: 30,
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonIconStyle: {
    marginRight: 5,
    width: 15,
    height: 'auto',
  },
  warningText: {
    marginTop: 20,
    marginBottom: -10,
    alignSelf: 'center',
    fontFamily: 'roboto',
    fontSize: '0.8rem',
    textAlign: 'center' as 'center',
    color: ColorPalette.WARNING_RED,
  },
  promptText: {
    margin: 0,
    marginBottom: -5,
    alignSelf: 'center',
    fontFamily: 'roboto',
    fontSize: '0.8rem',
    textAlign: 'center' as 'center',
    color: ColorPalette.DISABLED_TEXT,
  },
})

function getStyle(
  k:
    | 'container'
    | 'buttonContainer'
    | 'controlButtonStyle'
    | 'controlBarContainer'
    | 'buttonIconStyle'
    | 'warningText'
    | 'promptText',
): React.CSSProperties | undefined {
  const x = styles[k]
  if (!x) {
    return undefined
  }
  return x as React.CSSProperties
}

export default Radium(FileSelector)
