import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory, RouteComponentProps } from 'react-router'
import { mdiInformationOutline } from '@mdi/js'
import { Icon } from '@mdi/react'

import { ColorPalette, AllScreenNames } from '../../config'
import { removeUnderscores, toLowerCaseCustom, checkEmailFormat, getEnumKeyForValue } from '../../utils'
import { ActionType } from '../../store/actions/actions'
import NavButton from '../BaseComponents/Buttons/NavButton'
import logoHeader from '../../assets/logoHeader2.png'
import AccountManagementButton from '../BaseComponents/Buttons/AccountManagementButton'
import AccountManagement from '../Modals/AccountManagement'
import ForgotPasswordModal from '../Modals/ForgotPassword'
import OptionsSelectorModal from '../Modals/OptionsSelectorModal'
import TerminationDataUploadModal from '../Modals/BulkTermination/TerminationDataUpload'
import AlertModalOneButton from '../Modals/AlertModalOneButton'
import LoadingModal from '../Modals/LoadingModal'
import InfoCollectorModal from '../Modals/InfoCollector'
import WithRouterHistory from '../Routing/WithRouterHistory'
import { PeopleFlowCombinedReducer } from '../../store'
import { PickerHandlerEvent } from '../BaseComponents/Pickers/Picker'
import { IPermissionHandlerResult, IUserAccessResult } from '../../interfaces'
import { CommonTs } from '../../models/common-ts/commonts'
import { IMenuLabel } from '../../models'
import { SettingsModal } from '../Modals/SettingsModal'
import { buttonsWithCustomActions, rootPaths } from '../../services/navbar'
import { SessionService, ImportExportService } from '../../services'
import { AssociationSettingsRepository, ProfileRepository, UserRepository } from '../../repositories'
import { NavigationContent } from './navbar.utils'
import { ErrorCodeTypes } from '../../enums/errorCodeTypes'
import { isValidEmploymentStatus } from '../../utils/common'

/**
TODO: Use one OptionSelectorModal instance along with the required function handlers. i.e. Don't repeat OptionSelectorModal.
*/

type MenuActions = 'exportTemplate'

interface NavigationBarProps extends RouteComponentProps {
  primaryTableRef?: React.RefObject<any>
  reloadPageData?: () => void
}

const importExportService = new ImportExportService()

const NavigationBar = (props: NavigationBarProps) => {
  const location = useLocation()
  const history = useHistory()

  const initialModalState = {
    loadingModalOpen: false,
    warningModalOpen: false,
    accountModalOpen: false,
    forgotPasswordModalOpen: false,
    settingsModalOpen: false,
    dataOptionsSelectorModalOpen: false,
    emailCollectorModalOpen: false,
    docAuditReportEmailCollectorModalOpen: false,
    bulkDisciplineEmailCollectorModalOpen: false,
    bulkDisciplineDateModalOpen: false,
    templateOptionsSelectorModalOpen: false,
    terminationModalOpen: false,
    docAuditReportExportSuccessModalOpen: false,
    messageModalHeader: '',
    messageModalMessage: '' as string | JSX.Element,
    loadingModalMessage: '',
  }

  const [modalState, setModalState] = useState(initialModalState)
  const [disciplineWarningMessage, setDisciplineWarningMessage] = useState('')
  const [accountButtonWidth, setAccountButtonWidth] = useState(0)
  const [menuStructure, setMenuStructure] = useState([])
  const [exportTemplate, setExportTemplate] = useState('')
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [availableExportConfigs, setAvailableExportConfigs] = useState<string[]>([])
  const [fetchingNewClientSettings, setFetchingNewClientSettings] = useState(false)
  const [profileDetailSwitchTimestamp, setProfileDetailSwitchTimestamp] = useState<Date | undefined>(undefined)
  const [refreshTimestamp, setRefreshTimestamp] = useState<Date>(new Date())
  const [mounted, setMounted] = useState(false)

  const idPassport = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.idPassport)
  const password = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.password)
  const associationRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.associationRepo,
  ) as AssociationSettingsRepository
  const userRepo = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.userRepo) as UserRepository
  const allRoles = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.allRoles)
  const allCohorts = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.allCohorts)
  const selectedAssociation = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedAssociation,
  )
  const selectedRole = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.selectedRole)
  const selectedCohort = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.selectedCohort)
  const allClientNames = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.allClientNames)
  const currentScreen = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.currentScreen)
  const currentSection = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.currentSection)

  const selectedEmploymentStatus = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedEmploymentStatus,
  )
  const env = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.env)

  const dispatch = useDispatch()
  const updateState = (data: any) => dispatch({ type: ActionType.UPDATE_STATE, data })
  const setSelectedAssociation = (selectedAssociation: string) =>
    dispatch({ type: ActionType.SET_SELECTED_ASSOCIATION, data: { selectedAssociation } })
  const setSelectedRole = (selectedRole: string) =>
    dispatch({ type: ActionType.SET_SELECTED_ROLE, data: { selectedRole } })
  const setSelectedCohort = (selectedCohort: string) =>
    dispatch({ type: ActionType.SET_SELECTED_COHORT, data: { selectedCohort } })
  const setAllRoles = (allRoles: string[]) => dispatch({ type: ActionType.SET_ALL_ROLES, data: { allRoles } })
  const setAllCohorts = (allCohorts: string[]) => dispatch({ type: ActionType.SET_ALL_COHORTS, data: { allCohorts } })

  useEffect(() => {
    initialise()
    if (
      props.history.location.pathname === '/people/employees' &&
      // @ts-ignore
      props.history.location.state?.hasOwnProperty('openTerminationModal') &&
      // @ts-ignore
      props.history.location.state.openTerminationModal
    ) {
      setModalState({ ...initialModalState, terminationModalOpen: true })
    }
    setMounted(true)
  }, [])

  useEffect(() => {
    if (profileDetailSwitchTimestamp !== undefined) {
      initialise()
    }
  }, [profileDetailSwitchTimestamp])

  useEffect(() => {
    if (!mounted && selectedRole) {
      initialise()
    }
  }, [mounted, selectedRole])

  const initialise = () => {
    const { accountButtonWidth, menuStructure } = setMenuDimensions(selectedAssociation, selectedRole)
    const availableExportConfigs = associationRepo.getAvailableExportConfigs(selectedAssociation)
    const exportTemplate = availableExportConfigs.length ? availableExportConfigs[0] : ''
    setAccountButtonWidth(accountButtonWidth)
    setMenuStructure(menuStructure)
    setExportTemplate(exportTemplate)
    setAvailableExportConfigs(availableExportConfigs)
  }

  const closeModals = () => {
    setModalState(initialModalState)
  }

  const forgotPassword = () => {
    closeModals()
    setTimeout(() => setModalState((modalState) => ({ ...modalState, forgotPasswordModalOpen: true })), 80)
  }

  const openSettings = () => {
    if (currentScreen !== 'Data Manager') {
      setModalState({ ...initialModalState, settingsModalOpen: true })
      return
    }
    const messageModalHeader = 'Disabled'
    const messageModalMessage = "Setting changes are disabled while you're busy on this screen."
    setModalState({
      ...initialModalState,
      warningModalOpen: true,
      messageModalHeader,
      messageModalMessage,
    })
  }

  const settingsChangedHandler = async () => {
    setModalState({
      ...initialModalState,
      loadingModalOpen: true,
      loadingModalMessage: 'Refreshing...',
    })
    setTimeout(async () => {
      // using current screen and section from props and eventually updating state with them instead of
      // using these values from permissionResult ensures that current screen/section values actually
      // reflect correctly what the user sees
      const allAssociationSettings = await SessionService.fetchAllAssociationSettings(selectedAssociation, {
        username: idPassport,
        password,
      })
      await associationRepo.saveAssociationSettings(selectedAssociation, allAssociationSettings[selectedAssociation])
      const userAccessResult: IUserAccessResult = associationRepo.getUserAccess(selectedAssociation, selectedRole)
      const { hasAccess, clientName = '', permissionResult } = userAccessResult

      if (clientName !== selectedAssociation) {
        setModalState({
          ...initialModalState,
          messageModalHeader: 'Switched access',
          messageModalMessage: `No access to ${removeUnderscores(
            selectedAssociation,
          )}. Switching to ${removeUnderscores(clientName)}.`,
        })
      }

      const newProfileRepo = new ProfileRepository(selectedAssociation)
      await newProfileRepo.hydrateProfileEntitiesFromCache(selectedAssociation)

      if (!hasAccess) {
        displayWarning({ code: 'NoWebAccess' })
        return
      }

      const {
        hasDocValidityAccess,
        hasRequestHandlerAccess,
        hasUserManagementAccess,
        hasWorkforceAccess,
        hasDataLogsAccess,
        hasDeviceLogsAccess,
        hasFormsAccess,
        hasImportAccess,
        hasTrashAccess,
        hasBulkTerminationAccess,
        hasConfigEditAccess,
        hasBillingAccess,
        hasGroupManageAccess,
        hasDocMarkingAccess,
      } = permissionResult as IPermissionHandlerResult

      updateState({
        profileRepo: newProfileRepo,
        hasDocValidityAccess,
        hasRequestHandlerAccess,
        hasUserManagementAccess,
        hasWorkforceAccess,
        hasDataLogsAccess,
        hasDeviceLogsAccess,
        hasFormsAccess,
        hasImportAccess,
        hasTrashAccess,
        hasBulkTerminationAccess,
        hasConfigEditAccess,
        hasBillingAccess,
        hasGroupManageAccess,
        hasDocMarkingAccess,
        currentScreen,
        currentSection,
      })

      const onPeopleListingPage =
        location.pathname === '/people/employees' || location.pathname === '/people/candidates'
      if (!onPeopleListingPage) {
        history.push('/people/employees')
      }

      if (props.reloadPageData) {
        props.reloadPageData()
      }

      closeModals()
    }, 200)
  }

  const exportPicker = (event: PickerHandlerEvent, keyName: MenuActions) => {
    setExportTemplate(event.target.value)
  }

  const exportHandler = (type: string) => {
    if (type === 'profileData') {
      setModalState({ ...initialModalState, emailCollectorModalOpen: true })
    } else if (type === 'bulkDiscipline') {
      setModalState({ ...initialModalState, bulkDisciplineEmailCollectorModalOpen: true })
    }
  }

  const initialiseSelectedAssociation = (
    selectedAssociation: string,
    associationRepo: AssociationSettingsRepository,
  ) => {
    setFetchingNewClientSettings(true)
    setTimeout(() => {
      const userAssociationRoles = associationRepo.getUserRoles(selectedAssociation)
      const userAssociationCohorts = associationRepo.getAllCohorts(selectedAssociation)
      setAllRoles(userAssociationRoles)
      setAllCohorts(userAssociationCohorts)
      setSelectedRole(userAssociationRoles.find(() => true) as string)
      setSelectedCohort(userAssociationCohorts.find(() => true) as string)
      setFetchingNewClientSettings(false)
      setProfileDetailSwitchTimestamp(new Date())
    }, 200)
  }

  const settingsPicker = async (event: PickerHandlerEvent, keyName: string) => {
    const val = event.target.value
    switch (keyName) {
      case 'selectedAssociation':
        const selectedAssociation = val
        setSelectedAssociation(selectedAssociation)
        initialiseSelectedAssociation(selectedAssociation, associationRepo)
        break

      case 'selectedRole':
        setSelectedRole(val)
        setProfileDetailSwitchTimestamp(new Date())
        break

      case 'selectedCohort':
        setSelectedCohort(val)
        setProfileDetailSwitchTimestamp(new Date())
        break
    }
  }

  const dateHandler = (selectedDate: Date) => {
    const currentDate = new Date()
    if (selectedDate.getTime() > currentDate.getTime() && selectedDate.getDay() !== currentDate.getDay()) {
      setDisciplineWarningMessage("That's in the future")
    } else {
      setSelectedDate(selectedDate)
      setDisciplineWarningMessage('')
    }
  }

  const getSelectedProfileIdPassports = (): string[] | undefined => {
    let selected = [] as string[]
    if (!props.primaryTableRef) {
      return undefined // indicates that we are not on the Profiles pages
    }
    if (props.primaryTableRef.current) {
      selected = Object.keys(props.primaryTableRef.current.getSelectedRows())
    }
    return selected
  }

  const planTerminationImport = async (
    csvFileName: string,
    defaultTerminationDate: string,
    removeImportRecords: boolean,
  ): Promise<CommonTs.Import.IImportPlan[] | void> => {
    try {
      setModalState({ ...initialModalState, loadingModalOpen: true, loadingModalMessage: 'Processing...' })
      const options: CommonTs.Import.ITerminateProfileOptions = {
        defaultTerminationDate: '',
        beforeAfterOptions: {
          profileKeysToKeep: ['idNumber'],
          generalDataKeysToKeep: ['idNumber', 'name', 'surname', 'terminationDate'],
        },
      }
      const results = await importExportService.planTerminationImport(
        selectedAssociation,
        csvFileName,
        { username: idPassport, password },
        options,
        defaultTerminationDate,
        removeImportRecords,
        selectedCohort,
      )
      setModalState((modalState) => ({ ...modalState, loadingModalOpen: false, loadingModalMessage: '' }))
      return results || []
    } catch (error) {
      console.error(error)
      displayWarning(error)
    }
  }

  const exportProfiles = async (emailAddresses: string[]): Promise<void> => {
    try {
      setModalState({
        ...initialModalState,
        loadingModalOpen: true,
        loadingModalMessage: 'Exporting...',
      })
      const selectedProfileIdPassports = getSelectedProfileIdPassports()

      if (selectedProfileIdPassports === undefined) {
        throw { code: ErrorCodeTypes.NO_EXPORT_PROFILES_SELECTED_FROM_PROFILES_PAGE }
      }

      if (!selectedProfileIdPassports?.length) {
        throw { code: ErrorCodeTypes.NO_EXPORT_PROFILES_SELECTED }
      }
      importExportService.exportRemoteData(
        selectedAssociation,
        selectedProfileIdPassports,
        exportTemplate,
        selectedRole,
        emailAddresses,
        { username: idPassport, password },
      )
      setTimeout(() => {
        /**
         * Added to enhance the UX as it is disconcerting when an immediate result is given. (Makes the user feel as though nothing happened).
         */
        displayWarning({ code: 'ExportTriggered' })
      }, 500)
    } catch (error) {
      displayWarning(error)
    }
  }

  const exportDocAuditReports = async (emailAddresses: string[]): Promise<void> => {
    setModalState({
      ...initialModalState,
      loadingModalOpen: true,
      loadingModalMessage: 'Generating reports...',
    })
    importExportService.exportDocAuditReports(selectedAssociation, emailAddresses, allCohorts, {
      username: idPassport,
      password,
    })
    setModalState({
      ...initialModalState,
      docAuditReportExportSuccessModalOpen: true,
      messageModalHeader: 'Success',
      messageModalMessage: (
        <p>
          Your report is being generated and will be sent out shortly.
          <br />
          Check your inbox in a few minutes.
        </p>
      ),
    })
  }

  const generateBulkDisciplineForms = async (emailAddresses: string[]): Promise<void> => {
    try {
      setModalState({
        ...initialModalState,
        loadingModalOpen: true,
        loadingModalMessage: 'Generating forms...',
      })
      await importExportService.generateBulkDisciplineForms(selectedAssociation, selectedDate, emailAddresses, {
        username: idPassport,
        password,
      })

      displayWarning({ code: 'GenerateBulkDisciplineSuccess' })
    } catch (error) {
      displayWarning(error)
    }
  }

  const emailSuccessHandler = (addresses: string[]) => {
    const { emailCollectorModalOpen, docAuditReportEmailCollectorModalOpen, bulkDisciplineEmailCollectorModalOpen } =
      modalState

    if (emailCollectorModalOpen) {
      exportProfiles(addresses)
    } else if (docAuditReportEmailCollectorModalOpen) {
      exportDocAuditReports(addresses)
    } else if (bulkDisciplineEmailCollectorModalOpen) {
      generateBulkDisciplineForms(addresses)
    }
  }

  const onMenuClick = (menuName: string, subMenuName?: string, subSubMenuName?: string) => {
    if (subSubMenuName !== undefined) {
      if (buttonsWithCustomActions.includes(subSubMenuName)) {
        if (subSubMenuName === AllScreenNames.EXPORT_PROFILES) {
          setModalState({ ...initialModalState, templateOptionsSelectorModalOpen: true })
        }
        if (subSubMenuName === AllScreenNames.EXPORT_DOC_VALIDITY_DATA) {
          setModalState({ ...initialModalState, docAuditReportEmailCollectorModalOpen: true })
        }
        if (subSubMenuName === AllScreenNames.EXPORT_DOCS) {
          props.history.replace('/exportdocs')
        }
        if (subSubMenuName === AllScreenNames.BULK_ACTIONS) {
          setModalState({ ...initialModalState, bulkDisciplineDateModalOpen: true })
        }
      }
      return
    }

    if (subMenuName !== undefined && buttonsWithCustomActions.includes(subMenuName)) {
      if (subMenuName === AllScreenNames.TERMINATE) {
        updateState({ currentSection: subMenuName, currentScreen: menuName })
        setModalState({ ...initialModalState, terminationModalOpen: true })
      }
      return
    }

    const screenTag = getEnumKeyForValue(AllScreenNames, subMenuName)
    updateState({
      selectedEmploymentStatus: isValidEmploymentStatus(screenTag) ? screenTag : selectedEmploymentStatus,
      currentScreen: menuName,
      currentSection: subMenuName,
    })
  }

  const setMenuDimensions = (selectedAssociation: string, selectedRole: string): any => {
    let accountButtonWidth = window.innerWidth * 0.07
    if (!selectedRole || !selectedAssociation) {
      accountButtonWidth += selectedAssociation
        ? selectedAssociation.length * 8
        : selectedRole
        ? selectedRole.length * 8
        : 0
    } else if (selectedAssociation.length > selectedRole.length) {
      accountButtonWidth += selectedAssociation.length * 8
    } else {
      accountButtonWidth += selectedRole.length * 8
    }
    const menuStructure = NavigationContent.getMenuLabels(
      userRepo,
      associationRepo,
      selectedAssociation,
      selectedRole,
    ).map((menuitem) => {
      menuitem.positionRight += accountButtonWidth
      return menuitem
    })
    return { accountButtonWidth, menuStructure }
  }

  const generateNavButtons = (): JSX.Element[] => {
    return menuStructure.map((menuItem: IMenuLabel, i) => {
      return (
        <NavButton
          key={`${i}_${menuItem.label}`}
          label={menuItem.label}
          subMenus={menuItem.subMenus}
          subSubMenus={menuItem.subSubMenus}
          positionRight={menuItem.positionRight}
          disabled={!menuItem.hasAccess}
          rootPaths={rootPaths}
          buttonsWithCustomActions={buttonsWithCustomActions}
          primaryTableRef={props.primaryTableRef}
          onMenuClick={(menuName: string, subMenuName?: string, subSubMenuName?: string) =>
            onMenuClick(menuName, subMenuName, subSubMenuName)
          }
        />
      )
    })
  }

  const generateWarning = (issues: string[]) => {
    return (
      <div style={{ marginTop: '0.75em' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
          }}>
          <Icon path={mdiInformationOutline} color={ColorPalette.PRIMARY_BLUE} size={1.8} />
          <p style={{ fontWeight: 500, fontSize: '1.1em', width: '100%' }}>The following issues were found:</p>
        </div>
        <ul
          style={{
            listStyleType: 'circle',
            textAlign: 'left',
            width: '86%',
            fontStyle: 'italic',
          }}>
          {issues.map((issue, index) => (
            <li style={{ marginBlock: '0.75em', marginTop: 5 }} key={`csvFileIssues_${index}`}>
              {issue}
            </li>
          ))}
        </ul>
        <div style={{ marginTop: '3em', fontWeight: 500 }}>Correct them and try again.</div>
      </div>
    )
  }

  const logout = async () => {
    props.history.replace('/')
  }

  const displayWarning = (error: any) => {
    let header = 'Warning'
    let warning = ''
    try {
      if (error.code === 'NetworkError') {
        warning = 'Seems like your internet connection is down. Reconnect to the network, then try again.'
      } else if (error.code === 'NoWebAccess') {
        header = 'Not authorised'
        warning = 'You are not authorised to access the PeopleFlow admin portal.'
      } else if ('message' in error) {
        warning =
          "The following error message was returned:\n\n'" +
          error.message +
          "'. \n\nRefresh the page and try again. If unsuccessful, then contact tech support"
      } else if (error.code === 'NotAuthorisedExportPeople') {
        header = 'Not authorised'
        warning = "You don't have permission to export employee/candidate info."
      } else if (error.code === 'NotAuthorisedBulkDiscipline') {
        header = 'Not authorised'
        warning = "You don't have permission to generate discipline forms."
      } else if (error.code === 'ExportDocAuditReportError') {
        warning = `We ran into a problem while generating your report. Try logging out and in, then retry. If still unsuccessful, then reach out to tech support for assistance.`
      } else if (error.code === 'ExportTriggered') {
        header = 'Triggered export'
        warning = `Your export has been triggered. (It will take a few minutes before it reaches your inbox).`
      } else if (error.code === 'NoExportProfilesSelected') {
        header = 'Make a selection'
        warning = `No CANDIDATE/EMPLOYEES selected`
      } else if (error.code === 'NoExportProfilesSelectedFromProfilesPage') {
        header =
          'Visit the "CANDIDATES" or "EMPLOYEES" screens, contained under the "PEOPLE" menu, to select profiles for export.'
        warning =
          "You haven't selected any items to export from the employee or canditates pages.  You'll be redirected there."
      } else if (error.code === 'GenerateBulkDisciplineSuccess') {
        header = 'Success'
        warning = `Your forms are being generated.`
      } else if (error.code === 'NoRecords') {
        header = 'Bulk termination'
        warning = 'No records found after file processing'
      } else {
        warning = 'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
      }
    } catch (error) {
      warning = 'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
    }
    setModalState({
      ...initialModalState,
      warningModalOpen: true,
      messageModalHeader: header,
      messageModalMessage: warning,
    })
  }

  const triggerInvalidFileContentWarning = (issues: string[]) => {
    setModalState({
      ...initialModalState,
      terminationModalOpen: true,
      warningModalOpen: true,
      messageModalHeader: 'Invalid CSV file content',
      // @ts-ignore
      messageModalMessage: generateWarning(issues),
    })
  }

  const handleMessageModalClick = () => {
    if (modalState.terminationModalOpen) {
      setModalState({
        ...initialModalState,
        terminationModalOpen: true,
      })
      setRefreshTimestamp(new Date())
      return
    }
    closeModals()
  }

  const initiateBulkTermination = async (
    fileName: string | null,
    defaultTerminationDate: string,
    idPassportsNotFound: string[],
    invertRecordsToTerminate: boolean,
  ) => {
    const defaultDate = defaultTerminationDate.substring(0, 10)
    if (fileName && defaultTerminationDate) {
      const records = await planTerminationImport(fileName, defaultDate, invertRecordsToTerminate)
      if (records && records.length > 0) {
        history.push('/bulkterminations', {
          records,
          csvFileName: fileName,
          idPassportsNotFound,
          via: 'bulkActions',
        })
      } else {
        displayWarning({ code: 'NoRecords' })
      }
    }
  }

  let backButton = null
  const {
    accountModalOpen,
    settingsModalOpen,
    bulkDisciplineDateModalOpen,
    bulkDisciplineEmailCollectorModalOpen,
    docAuditReportEmailCollectorModalOpen,
    emailCollectorModalOpen,
    forgotPasswordModalOpen,
    loadingModalOpen,
    loadingModalMessage,
    templateOptionsSelectorModalOpen,
    terminationModalOpen,
    warningModalOpen,
    docAuditReportExportSuccessModalOpen,
  } = modalState
  const navButtons = generateNavButtons()
  const user = userRepo?.getCurrentUserEntity()

  if (!user) {
    return null
  }

  const { name = '', surname = '', mobile = '', email = '' } = user.getPersonalUserInfo()
  const anyEmailCollectorModalOpen =
    emailCollectorModalOpen || docAuditReportEmailCollectorModalOpen || bulkDisciplineEmailCollectorModalOpen
  return (
    <div style={styles.container}>
      {backButton}

      <img src={logoHeader} alt="PeopleFlow" style={styles.logoStyle} />

      <div style={styles.navButtonContainer}>
        {navButtons}
        <AccountManagementButton
          env={env}
          style={{ width: accountButtonWidth }}
          name={name}
          surname={surname}
          selectedAssociation={selectedAssociation}
          selectedCohort={selectedCohort}
          onClick={() =>
            setModalState((modalState) => ({ ...modalState, accountModalOpen: !modalState.accountModalOpen }))
          }
        />
      </div>

      <OptionsSelectorModal
        open={templateOptionsSelectorModalOpen}
        onSuccess={() => exportHandler('profileData')}
        onReject={() => closeModals()}
        successLabel={'Export'}
        cancelLabel={'Cancel'}
        label1={'Export template'}
        options1={availableExportConfigs}
        optionsName1={'exportTemplate'} // TODO: Check - should this be set to something else?
        selectedOption1={exportTemplate}
        onChange={(e, name) => exportPicker(e, name as MenuActions)}
      />
      <AccountManagement
        open={accountModalOpen}
        name={name}
        surname={surname}
        email={email}
        mobile={mobile}
        idPassport={idPassport}
        dismiss={() => settingsChangedHandler()}
        onSettingsClicked={() => openSettings()}
        onLogoutClicked={() => logout()}
        onForgotPasswordClicked={() => forgotPassword()}
      />
      <SettingsModal
        open={settingsModalOpen}
        selectedAssociation={selectedAssociation}
        selectedRole={selectedRole}
        selectedCohort={selectedCohort}
        allClientNames={allClientNames}
        allRoles={allRoles}
        allCohorts={allCohorts}
        fetchingNewClientSettings={fetchingNewClientSettings}
        onChange={(e: PickerHandlerEvent, selectedKeyName: string) => settingsPicker(e, selectedKeyName)}
        onSave={settingsChangedHandler}
        key={`settingsModal_${JSON.stringify(allRoles)}_${JSON.stringify(allCohorts)}`}
      />
      <OptionsSelectorModal
        open={templateOptionsSelectorModalOpen}
        onSuccess={() => exportHandler('profileData')}
        onReject={closeModals}
        successLabel="Export"
        cancelLabel="Cancel"
        label1="Export template"
        options1={availableExportConfigs}
        optionsName1="exportTemplate" // TODO: Check - should this be set to something else?
        selectedOption1={exportTemplate}
        onChange={(e, name) => exportPicker(e, name as MenuActions)}
      />
      <OptionsSelectorModal
        open={bulkDisciplineDateModalOpen}
        onSuccess={() => exportHandler('bulkDiscipline')}
        onReject={closeModals}
        warningMessage={disciplineWarningMessage}
        successLabel="Next"
        cancelLabel="Cancel"
        dateLabel="Date of underperformance"
        showCalendar={true}
        selectedDate={selectedDate}
        handleDateChange={(selectedDate) => dateHandler(selectedDate)}
      />
      <InfoCollectorModal
        open={anyEmailCollectorModalOpen}
        defaultItems={[email]}
        header="EMAIL TO"
        subHeader="Which email addresses should we send to?"
        warningMessage="Add at least one email address"
        validateInput={checkEmailFormat}
        transformInput={toLowerCaseCustom}
        placeholder="Email address"
        minimumItems={1}
        dismiss={closeModals}
        onSuccess={(addresses) => emailSuccessHandler(addresses)}
        onReject={closeModals}
      />
      <ForgotPasswordModal open={forgotPasswordModalOpen} idPassport={idPassport} dismiss={closeModals} />
      <WithRouterHistory>
        {(history: RouteComponentProps['history']) => (
          <TerminationDataUploadModal
            open={terminationModalOpen}
            onNextClick={initiateBulkTermination}
            onCancelClick={closeModals}
            onInvalidFileContentFound={triggerInvalidFileContentWarning}
            key={`terminationDataUploadModal_${refreshTimestamp}`}
          />
        )}
      </WithRouterHistory>

      <AlertModalOneButton
        open={warningModalOpen || docAuditReportExportSuccessModalOpen}
        header={modalState.messageModalHeader}
        body={modalState.messageModalMessage}
        buttonLabel="Ok"
        onClick={handleMessageModalClick}
      />
      <LoadingModal open={loadingModalOpen}>{loadingModalMessage}</LoadingModal>
    </div>
  )
}

const styles = {
  container: {
    height: 85,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '1.5%',
  },
  logoStyle: {
    marginLeft: 10,
    marginTop: 40,
    height: 60,
  },
  navButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    marginRight: '2%',
  },
}

export default NavigationBar
