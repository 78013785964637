import { Profile, ProfilePk } from '../models'
import { profileIdentifier } from '../config/localStorage'
import { LocalStorageProvider } from '../provider'

export class ProfileRepository {
  private profileEntities: Record<ProfilePk, Profile> = {}
  private association: string = ''
  private hydrated: boolean = false

  constructor(association: string) {
    this.association = association
  }

  async hydrateProfileEntitiesFromCache(association: string) {
    const associationProfilesStorageIdentifier = `${association}#${profileIdentifier}`
    const profileStorageKeys = await LocalStorageProvider.listDataKeys(associationProfilesStorageIdentifier)
    for (const key of profileStorageKeys) {
      const profileData = await LocalStorageProvider.getData(key)
      if (!profileData) {
        continue
      }
      const pk = key.split(profileIdentifier)[1]
      this.profileEntities[pk] = new Profile(profileData)
    }
    this.hydrated = true
  }

  getAssociation() {
    return this.association
  }

  getAssociationProfileIdentifier() {
    return `${this.association}#${profileIdentifier}`
  }

  isHydratedFromProfileCache() {
    return this.hydrated
  }

  async clearProfiles() {
    this.profileEntities = {}
    this.hydrated = false
  }

  async deleteProfilesFromCache() {
    const associationProfilesStorageIdentifier = this.getAssociationProfileIdentifier()
    const profileStorageKeys = await LocalStorageProvider.listDataKeys(associationProfilesStorageIdentifier)
    await LocalStorageProvider.deleteData(profileStorageKeys)
    this.hydrated = false
  }

  getProfile(pk: string) {
    if (!this.profileEntities[pk]) {
      console.error("Profile doesn't exist", this.association, pk)
      // throw { code: 'ProfileDoesntExist', pk }
      return
    }
    return this.profileEntities[pk]
  }

  getProfileByIdPassport(idPassport: string) {
    const profileEntityList = Object.values(this.profileEntities)
    for (const profileEntity of profileEntityList) {
      if (profileEntity.getIdPassport() === idPassport) {
        return profileEntity
      }
    }
    console.error("Profile doesn't exist", this.association, 'idPassport', idPassport)
    // throw { code: 'ProfileDoesntExist' }
    return
  }

  getAllProfiles(): Record<ProfilePk, Profile> {
    if (!this.profileEntities) {
      throw { code: 'ProfileRepoNotInitialised' }
    }
    return this.profileEntities
  }

  getProfilePks(filter: (profile: Profile) => boolean) {
    let profilePks = Object.keys(this.profileEntities)
    return profilePks.filter((pk) => filter(this.profileEntities[pk]))
  }

  getAllProfilePks() {
    let profilePks = Object.keys(this.profileEntities)
    return profilePks
  }

  getAllProfileIdPassports() {
    let profileIdPassports: string[] = []
    Object.values(this.profileEntities).forEach((profileEntity: Profile) => {
      profileIdPassports.push(profileEntity.getIdPassport())
    })
    return profileIdPassports
  }

  getTargetProfiles(
    cohortsToGet: string[] | null,
    statusesToGet: string[] | null,
    trashStatusesToGet: string[] = ['NONE'],
  ) {
    let targetProfiles: Record<string, Profile> = {}
    // loggerService.info("Getting target profiles")
    Object.values(this.profileEntities).forEach((profileEntity: Profile) => {
      const pk = profileEntity.getPk()
      const cohorts = profileEntity.getCohorts()
      const employmentStatus = profileEntity.getEmploymentStatus()
      const trashStatus = profileEntity.getTrashStatus()
      let hasCohortMatch = false
      if (cohortsToGet) {
        cohorts.forEach((cohort) => (cohortsToGet.includes(cohort) ? (hasCohortMatch = true) : null))
      }
      if (
        (!cohortsToGet || hasCohortMatch) &&
        (!statusesToGet || statusesToGet.includes(employmentStatus)) &&
        (!trashStatusesToGet || trashStatusesToGet.includes(trashStatus))
      ) {
        targetProfiles[pk] = profileEntity
      }
    })
    // loggerService.info("Finished getting target profiles")
    return targetProfiles
  }

  async listProfilePksLocalStorage() {
    // loggerService.info("Start listProfilePksLocalStorage")
    const allKeys = await LocalStorageProvider.listDataKeys(this.association)
    let profilePks: string[] = []
    allKeys.forEach((key) =>
      key.includes(profileIdentifier) ? profilePks.push(key.split(profileIdentifier)[1]) : null,
    )
    // loggerService.info("Complete listProfilePksLocalStorage")
    return profilePks
  }

  putLocal(pk: string, profile: any) {
    // TODO: replace 'any' type on profile
    const storageKey = this.getProfileStorageKey(pk)
    return LocalStorageProvider.setData(storageKey, profile)
  }

  setProfile(pk: string, profileEntity: any) {
    this.profileEntities[pk] = profileEntity
  }

  getProfileStorageKey(pk: string) {
    return `${this.association}#${profileIdentifier}${pk}`
  }

  async updateProfiles(profiles: Record<ProfilePk, Profile>) {
    for (const profilePk of Object.keys(profiles)) {
      const profile = new Profile(profiles[profilePk])
      this.profileEntities[profilePk] = profile
      await this.putLocal(profilePk, profile)
    }
    return this.profileEntities
  }
}
