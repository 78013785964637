import { useState } from 'react'
import { FormControl } from '@material-ui/core'
import { mdiCog } from '@mdi/js'
import Icon from '@mdi/react'

import { ColorPalette } from '../../../config/colors'
import Picker, { PickerHandlerEvent } from '../../BaseComponents/Pickers/Picker'
import { toUpperCaseCustom } from '../../../utils/stringUtils'
import InfoCollectorModal from '../../Modals/InfoCollector'
import TwoColumnSelector from '../TwoColumnSelector/TwoColumnSelector'

type SizingProps = {
  currentSizingTypes: string[]
  sizeOptions: string[]
  selectedSizingType: string
  additionMode: boolean
  onSizingTypeChange: (value: string) => void
  onSizesChange: (value: string[]) => void
}

export const Sizing = (props: SizingProps) => {
  const { currentSizingTypes, sizeOptions, selectedSizingType, additionMode, onSizingTypeChange, onSizesChange } = props

  const [selectorItemCollectorModalOpen, setSelectorItemCollectorModalOpen] = useState(false)

  const toggleSelectorItemCollectorModal = () => {
    setSelectorItemCollectorModalOpen((currentState) => !currentState)
  }

  const handleSizingTypeChange = (event: PickerHandlerEvent) => {
    const selection = event.target.value
    if (selection === 'Choose type') {
      onSizingTypeChange('')
      return
    }
    onSizingTypeChange(selection)
  }

  const renderSizeOptions = () => {
    return sizeOptions.map((size, index) => {
      // const minWidth = index < sizeOptions.length - 1 ? '15%' : `${size.length}ch`
      // const maxWidth = index < sizeOptions.length - 1 ? '50%' : `${size.length}ch`
      return (
        <div key={index} style={{ minWidth: '10%', display: 'flex', justifyContent: 'center' }}>
          <div style={{ alignSelf: 'center' }}>{size}</div>
        </div>
      )
    })
  }

  let types = ['Choose type', ...currentSizingTypes] // NOTE: removed "+ Add type" since PPE item and sizing option have one-to-one relation

  let content = null
  let sizingSelectors = null
  let options = renderSizeOptions()
  if (selectedSizingType) {
    sizingSelectors = (
      <div style={{ marginTop: '1em' }}>
        <div style={styles.buttonContainer} onClick={toggleSelectorItemCollectorModal}>
          <Icon path={mdiCog} color={ColorPalette.PRIMARY_BLUE} style={styles.buttonIcon} />
        </div>
        <div style={styles.optionContainer}>{options}</div>
      </div>
    )
  }
  if (additionMode) {
    const existingSizingTemplates = currentSizingTypes.length > 0

    let sizeTemplateSelector = null
    if (existingSizingTemplates) {
      sizeTemplateSelector = (
        <Picker
          label="SIZING TEMPLATE"
          value={selectedSizingType}
          placeholder=""
          items={types}
          onChange={handleSizingTypeChange}
          autoItemSelectOnMount={true}
          style={styles.sizingTemplate}
          labelStyle={{ color: ColorPalette.LIGHT_GREY, fontSize: '0.9rem' }}
        />
      )
    }

    let sizeOptionSelector = null
    if (selectedSizingType || !existingSizingTemplates) {
      sizeOptionSelector = (
        <TwoColumnSelector
          values={sizeOptions}
          showAddIcon={true}
          outlined={false}
          onClick={toggleSelectorItemCollectorModal}
          style={{ marginLeft: '2.5%' }}
        />
      )
    }

    content = (
      <FormControl style={styles.inputContainer}>
        <p style={styles.inputContainerLabel}>PPE SIZING</p>
        <div style={{ width: '100%', marginTop: 5 }}>
          {sizeTemplateSelector}
          {sizeOptionSelector}
        </div>
      </FormControl>
    )
  } else {
    content = (
      <FormControl style={styles.inputContainer}>
        <p style={styles.inputContainerLabel}>PPE SIZE OPTIONS</p>
        {sizingSelectors}
      </FormControl>
    )
  }

  const saveInputValues = (selectorItems: string[]) => {
    onSizesChange(selectorItems)
    toggleSelectorItemCollectorModal()
  }

  return (
    <>
      {content}
      <InfoCollectorModal
        open={selectorItemCollectorModalOpen}
        defaultItems={sizeOptions}
        header="PPE SIZING"
        subHeader="Size options for PPE item"
        warningMessage="Add at least one item"
        validateInput={() => true}
        transformInput={toUpperCaseCustom}
        placeholder="Enter option and click the icon to add it"
        successLabel="Update"
        minimumItems={1}
        // sorts={sortSizeValues}
        dismiss={toggleSelectorItemCollectorModal}
        onSuccess={saveInputValues}
        onReject={toggleSelectorItemCollectorModal}
      />
    </>
  )
}

const styles = {
  inputContainer: {
    minWidth: 120,
    marginTop: 5,
    paddingTop: 15,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 40,
    border: `1px solid rgba(195,205,225,0.6)`,
    borderRadius: 12,
    display: 'flex',
    justifyContent: 'center',
  },
  inputContainerLabel: {
    zIndex: 2,
    marginTop: -22,
    alignSelf: 'center',
    fontSize: '0.65rem',
    color: ColorPalette.LIGHT_GREY,
    backgroundColor: ColorPalette.CARD_WHITE,
    paddingLeft: 10,
    paddingRight: 10,
  },
  buttonContainer: {
    float: 'right' as 'right',
  },
  buttonIcon: {
    width: '1.25rem',
    height: '1.25rem',
    marginRight: 15,
    cursor: 'pointer',
    color: ColorPalette.PRIMARY_BLUE,
  },
  optionContainer: {
    display: 'flex',
    // alignItems: 'stretch',
    // alignContent: 'stretch',
    justifyContent: 'space-around',
    flexWrap: 'wrap' as 'wrap',
    columnGap: 'max(7.5em, 7.5%)',
    rowGap: 'max(2.5em, 2.5%)',
    marginTop: '3.25em',
    paddingTop: 5,
    padding: '5px 4em 0',
    color: ColorPalette.PRIMARY_TEXT,
  },
  sizingTemplate: { border: 'none', margin: 0, width: '45%', marginLeft: '2.5%' },
}
