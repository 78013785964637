import { ColorPalette } from '../../../config/colors'
import { toUpperCaseCustom } from '../../../utils'

import React, { Component } from 'react'

import profileAvatar from '../../../assets/profileAvatar.png'

interface IFaceShotProps {
  profilePic: string
  angle: string
  style?: React.CSSProperties | undefined
}

interface IFacePropsState {
  profilePic: string
  profilePicValid: boolean
}

class FaceShot extends Component<IFaceShotProps, IFacePropsState> {
  state: IFacePropsState = {
    profilePic: '',
    profilePicValid: true,
  }

  componentDidMount() {
    this.reloadProfilePic()
  }

  reloadProfilePic() {
    this.setState({ profilePicValid: true, profilePic: this.props.profilePic })
  }

  render() {
    return (
      <div style={{ ...styles.container, ...this.props.style }}>
        <div style={styles.imageContainer}>
          <div style={styles.profilePicDimensionContainer}>
            <img
              style={styles.profilePicStyle}
              src={this.state.profilePicValid ? this.state.profilePic : profileAvatar}
              onError={() => this.setState({ profilePicValid: false })}
              alt={'FaceShot'}
            />
          </div>
        </div>
        <p style={styles.nameStyle}>{toUpperCaseCustom(this.props.angle)}</p>
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30%',
    height: '100%',
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: ColorPalette.DARK_GREY,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.45)',
    marginTop: 25,
    width: '18vw',
    height: '18vw',
    borderRadius: '100%',
  },
  profilePicDimensionContainer: {
    overflow: 'hidden',
    borderRadius: '100%',
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '18vw',
    height: '18vw',
  },
  profilePicStyle: {
    height: 'auto',
    width: '100%',
  },
  nameStyle: {
    marginTop: 25,
    fontSize: '0.9rem',
    fontWeight: 'bolder',
    fontFamily: 'Roboto',
    color: ColorPalette.PRIMARY_TEXT,
    width: '100%',
    textAlign: 'center' as 'center',
  },
}

export default FaceShot
