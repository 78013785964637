export const docCategories: Record<string, string> = {
    0: "RECRUITMENT_&_ONBOARDING",
    1: "DISCIPLINE",
    2: "PERFORMANCE_MANAGEMENT",
    3: "PAYROLL,_LEAVE,_HEALTH_&_SAFETY",
    4: "TERMINATION",
    5: "OTHER",
};

export enum DocumentCategories {
    RECRUITMENT_AND_ONBOARDING = "RECRUITMENT_&_ONBOARDING",
    DISCIPLINE = "DISCIPLINE",
    PERFORMANCE_MANAGEMENT = "PERFORMANCE_MANAGEMENT",
    PAYROLL_LEAVE_HEALTH_SAFETY = "PAYROLL,_LEAVE,_HEALTH_&_SAFETY",
    TERMINATION = "TERMINATION",
    OTHER = "OTHER",
}

export const docSubCategories: Record<string, string[]> = {
    "RECRUITMENT_&_ONBOARDING": [],
    DISCIPLINE: ["COUNSEL", "VERBAL", "WRITTEN_(1)", "NOTICE_(1)", "WRITTEN_(FINAL)", "NOTICE_(FINAL)"],
    PERFORMANCE_MANAGEMENT: [],
    "PAYROLL,_LEAVE,_HEALTH_&_SAFETY": [],
    TERMINATION: [],
    OTHER: [],
};
