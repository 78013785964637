import { useEffect, useState, createRef } from 'react'
import { useSelector } from 'react-redux'
import { RouteComponentProps, useHistory, useLocation, useParams, useRouteMatch } from 'react-router'

import { mdiContentPaste, mdiPlus } from '@mdi/js'
import { difference } from 'lodash'

import DataTable from '../../components/Tables/DataTable/DataTable'
import NavigationBar from '../../components/Navigation/NavigationBar'
import SectionHeader from '../../components/Headings/SectionHeaderPrimary'
import LoadingModal from '../../components/Modals/LoadingModal'
import AlertModalOneButton from '../../components/Modals/AlertModalOneButton'
import { ColorPalette } from '../../config/colors'

import { PeopleFlowCombinedReducer } from '../../store'
import { SessionService } from '../../services'
import PickerContained from '../../components/BaseComponents/Pickers/PickerContained'
import { PickerHandlerEvent } from '../../components/BaseComponents/Pickers/Picker'
import { Toolbar } from '../../components/GeneralUI/Toolbar'
import { GroupService } from '../../services'
import { IdPasteModal } from './IdPasteModal'
import { AssociationSettingsRepository, ProfileRepository } from '../../repositories'
import { Profile, ProfilePk } from '../../models'
import { Group, GroupMembers } from '../../types'

type RouteParams = { groupId: string }

type GroupMemberProfile = {
  id: string
  name: string
  surname: string
  idPassport: string
  startDate: string
  terminationDate: string
}

type GroupMembersAddProps = {
  // idPassports = string[]
} & RouteComponentProps

const columnConfig = [
  { id: 'idPassport', label: 'ID / Passport', sizeFactor: 1 },
  { id: 'name', label: 'Name', sizeFactor: 1 },
  { id: 'surname', label: 'Surname', sizeFactor: 1 },
]

// NOTE: pass group name as route param
const GroupMembersAdd = (props: GroupMembersAddProps) => {
  const primaryTableRef = createRef<DataTable>()
  const { groupId } = useParams() as RouteParams
  const history = useHistory()
  const match = useRouteMatch()
  const location = useLocation()

  const userIdPassport = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.idPassport)
  const password = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.password)
  const selectedAssociation = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedAssociation,
  )
  const selectedCohort = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.selectedCohort)
  const profileRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.profileRepo,
  ) as ProfileRepository
  const selectedEmploymentStatus = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedEmploymentStatus,
  )
  const hasWorkforceAccess = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.hasWorkforceAccess)
  const associationRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.associationRepo,
  ) as AssociationSettingsRepository

  const [profileData, setProfileData] = useState<GroupMemberProfile[]>([])
  const [searchString, setSearchString] = useState('')
  const [customProfileFilterConfig, setCustomProfileFilterConfig] = useState<{ key: string; label: string }[]>([])
  const [filterState, setFilterState] = useState<Record<string, Record<string, boolean>>>({})
  const [loadingModalOpen, setLoadingModalOpen] = useState(false)
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [idListModalOpen, setIdListModalOpen] = useState(false)
  const [jobTypeModalOpen, setJobTypeModalOpen] = useState(false)
  const [jobType, setJobType] = useState('')
  const [idPassportsPasted, setIdPassportsPasted] = useState<string[]>([])
  const [group, setGroup] = useState<Group>()

  useEffect(() => {
    const customProfileFilterConfig = associationRepo.getProfileFilterConfig(selectedAssociation)
    setCustomProfileFilterConfig(customProfileFilterConfig)
    initGroupService()
  }, [groupId, selectedAssociation])

  useEffect(() => {
    if (primaryTableRef.current) {
      primaryTableRef.current.reload()
    }
  }, [profileData])

  useEffect(() => {
    if (group) {
      loadProfileData()
    }
  }, [group])

  useEffect(() => {
    if (idPassportsPasted.length > 0) {
      showPasteProfileDataAsSelected()
    }
  }, [idPassportsPasted.length])

  const initGroupService = async () => {
    const token = await SessionService.prepareAuthTokens(userIdPassport, password)
    const group = await GroupService.fetchGroup(selectedAssociation, selectedCohort, groupId, token)
    setGroup(group)
  }

  const saveNewMembersToGroup = async () => {
    if (group) {
      const newMembers = selectedIds.reduce((acc: GroupMembers, pk: string) => {
        const profile = profileData.find((profile) => profile.id === pk)
        if (profile) {
          const memberDetails = {
            jobType,
            proposedStartDate: group.startDate,
            proposedTerminationDate: group.endDate,
            validTimeBasedDocs: [],
          }
          acc[pk] = memberDetails
        }
        return acc
      }, {})
      const members = { ...group.members, ...newMembers }
      const updatedGroup = { ...group, members }
      const token = await SessionService.prepareAuthTokens(userIdPassport, password)
      await GroupService.postGroup(selectedAssociation, updatedGroup, token)
      history.push(`/people/groups/${groupId}`)
    }
  }

  const showPasteProfileDataAsSelected = async () => {
    const allProfiles = profileRepo.getAllProfiles()
    if (allProfiles) {
      const profilePks = Object.keys(allProfiles)
      if (idPassportsPasted && idPassportsPasted.length > 0) {
        profilePks.forEach((pk: ProfilePk) => {
          const value = allProfiles[pk]
          const profileIdPassport = value.getIdPassport()
          if (!idPassportsPasted.includes(profileIdPassport)) {
            delete allProfiles[pk]
          }
        })
      }
      const profileData: GroupMemberProfile[] = prepareProfileDataForDisplay(
        allProfiles,
        selectedEmploymentStatus,
        customProfileFilterConfig || [],
      )
      const idPassportsUnknown = difference(idPassportsPasted, Object.keys(allProfiles))
      if (idPassportsUnknown.length > 0) {
        setProfileData(
          profileData.concat(
            idPassportsUnknown.map((idPassport: string) => ({
              idPassport,
              name: '?',
              surname: '?',
            })) as GroupMemberProfile[],
          ),
        )
      } else {
        setProfileData(profileData)
      }
      setSelectedIds(idPassportsPasted)
    }

    return new Promise<void>((res) => res())
  }

  const loadProfileData = async () => {
    const allProfiles = profileRepo.getAllProfiles()
    if (group && allProfiles) {
      Object.keys(group.members).forEach((pk: string) => {
        //@ts-ignore
        delete allProfiles[pk] // remove existing group members from all profiles
      })

      const profileData: GroupMemberProfile[] = prepareProfileDataForDisplay(
        allProfiles,
        selectedEmploymentStatus,
        customProfileFilterConfig || [],
      )

      setProfileData(profileData)
    }
    return new Promise<void>((res) => res())
  }

  const prepareProfileDataForDisplay = (
    profiles: Record<ProfilePk, Profile> | undefined,
    selectedEmploymentStatus: string,
    customProfileFilterConfig: { key: string; label: string }[],
  ): GroupMemberProfile[] => {
    if (profiles === undefined) {
      return []
    }

    let allProfilesArray: Profile[] = Object.values(profiles)
    const allProfilesToRender = allProfilesArray.filter((profile: Profile) => {
      let generalData: any = profile.getGeneralData()
      if (generalData === undefined) {
        generalData = { employmentStatus: 'NONE' }
      }
      if (!profile.isPartOfCohort(selectedCohort)) {
        return false
      }

      const { employmentStatus, name, surname, idPassport } = generalData
      if (name && surname && idPassport && ['CANDIDATE', 'EMPLOYEE'].indexOf(employmentStatus) > -1) {
        return true
      } else {
        return false
      }
    })
    return allProfilesToRender.map((profile) => {
      const pk = profile.getPk()
      const generalData = profile.getGeneralData()
      let filterFields = getFilterFields(generalData, customProfileFilterConfig)
      let { name, surname, idPassport } = profile.getPersonalInfo()
      let { startDate, terminationDate } = generalData

      // Resorting to this ugly brute force approach after struggling to get types working in a forEach loop
      if (Array.isArray(name) && name.length) {
        name = name[0]
      }
      if (Array.isArray(surname) && surname.length) {
        surname = surname[0]
      }
      if (Array.isArray(idPassport) && idPassport.length) {
        idPassport = idPassport[0]
      }
      if (Array.isArray(startDate) && startDate.length) {
        startDate = startDate[0]
      }
      if (Array.isArray(terminationDate) && terminationDate.length) {
        terminationDate = terminationDate[0]
      }
      // // //
      let returnData = {
        id: pk,
        name: name,
        surname: surname,
        idPassport,
        startDate,
        terminationDate,
        ...filterFields,
      }
      return returnData
    })
  }

  const getFilterFields = (
    generalData: Record<string, any>,
    customProfileFilterConfig: { key: string; label: string }[],
  ): Record<string, any> => {
    let filterFields = {}
    customProfileFilterConfig.forEach((configItem) => {
      if (configItem.key in generalData) {
        filterFields = { ...filterFields, [configItem.key]: generalData[configItem.key] }
      }
    })
    return filterFields
  }

  const searchHandler = (event: React.ChangeEvent<{ value: string }>) => {
    setSearchString(event.target.value)
    if (!primaryTableRef?.current) {
      return
    }
    primaryTableRef.current.search(event.target.value)
  }

  const toggleJobTypeModal = () => {
    setJobTypeModalOpen((jobTypeModalOpen) => !jobTypeModalOpen)
  }
  const toggleIdListModal = () => {
    setIdListModalOpen((idListModalOpen) => !idListModalOpen)
  }

  const getCustomRowStyle = (rowId: string) => {
    if (profileData.find((profile) => profile.id === rowId && profile.name === '?')) {
      // @ts-ignore (Argument of type 'string | undefined' is not assignable to parameter of type 'string')
      return {
        backgroundColor: ColorPalette.WARNING_RED_TRANSPARENT,
        // @ts-ignore
        ':hover': {
          filter: 'brightness(0.9)',
        },
      }
    }

    return {}
  }

  const anySelectedIds = selectedIds.length > 0
  const organisationConfig = associationRepo.getOrganisationConfig(selectedAssociation)
  const availableJobTypes = group
    ? Object.keys(organisationConfig.divisionConfig.config[group.division || 'ADMIN'].jobTypes)
    : []

  const toolbarActionButtons = [
    {
      iconPath: mdiContentPaste,
      onClick: toggleIdListModal,
      label: 'IMPORT LIST',
      title: 'Paste copied list of ID / Passport numbers',
    },
    {
      iconPath: mdiPlus,
      onClick: toggleJobTypeModal,
      label: 'ADD TO GROUP',
      title: 'Add individuals to group',
      disabled: !anySelectedIds,
      iconColor: anySelectedIds ? ColorPalette.PRIMARY_BLUE : ColorPalette.MEDIUM_GREY,
    },
  ]

  return (
    <div style={{ ...styles.container }}>
      <NavigationBar
        match={match}
        location={location}
        history={history}
        reloadPageData={() => {
          setSearchString('')
          loadProfileData()
        }}
      />
      <SectionHeader
        style={styles.sectionHeader}
        searchString={searchString}
        textHandler={(e) => searchHandler(e)}
        onClick={() => null}
        key={`sectionHeader_${selectedAssociation}`}>
        {`${group?.name} | Add Group Member(s)`}
      </SectionHeader>

      <div style={styles.contentContainer}>
        <div style={styles.rightSide}>
          <Toolbar actionButtons={toolbarActionButtons} navButtons={{ left: { label: 'BACK' } }} />
          <div style={styles.rightSideContent}>
            <DataTable
              ref={primaryTableRef}
              tableData={profileData}
              columnConfig={columnConfig}
              tableWidth={10}
              filterState={filterState}
              rowsOnSelect={(selectedRows) => setSelectedIds(Object.keys(selectedRows))}
              selectedRowItemId={''}
              authorisedItemIds={[]}
              customFilterConfig={customProfileFilterConfig}
              isSelectionEnabled={true}
              rowClickAsSelect={true}
              initialSelectedRows={idPassportsPasted}
              applyCustomRowStyle={getCustomRowStyle}
              key={`dataTable_${selectedAssociation}_${idPassportsPasted.length}`}
            />
          </div>
        </div>
      </div>
      <AlertModalOneButton
        open={!hasWorkforceAccess}
        header="Not Authorised"
        body="You don't have permission to view employee/candidate info."
        buttonLabel="Ok"
        opaqueBackground={true}
        onClick={() => history.goBack()}
      />
      <LoadingModal open={loadingModalOpen}>Refreshing data...</LoadingModal>
      <AlertModalOneButton
        open={jobTypeModalOpen}
        header="Job Type"
        body={
          <PickerContained
            value={jobType}
            placeholder="Select a job type"
            searchEnabled={true}
            items={availableJobTypes}
            onChange={(e: PickerHandlerEvent) => setJobType(e.target.value)}
            style={{ width: 'max-content', padding: '0.75em 3em 1.5em' }}
          />
        }
        buttonLabel="Ok"
        opaqueBackground={true}
        onClick={() => {
          saveNewMembersToGroup()
          toggleJobTypeModal()
        }}
        dismiss={toggleJobTypeModal}
      />
      <IdPasteModal open={idListModalOpen} close={toggleIdListModal} setIdPassportsPasted={setIdPassportsPasted} />
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255, 1), rgba(209,210,230, 1))',
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto 1%',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    paddingInline: 'min(3em, 3%)',
    width: '100%',
    overflow: 'hidden',
  },
  rightSideContent: {
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    display: 'flex',
    flex: 1,
    backgroundColor: ColorPalette.CARD_WHITE,
  },
  tableNavButtonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  toolbarButton: {
    width: 180,
    fontWeight: 'bolder' as 'bolder',
    fontSize: '0.8rem',
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    ':hover': {
      color: ColorPalette.PRIMARY_BLUE,
    },
    ':active': {
      color: ColorPalette.DARK_GREY,
    },
  },
  pasteListModal: {
    textAlign: 'center' as 'center',
    alignSelf: 'center',
    fontFamily: 'roboto',
    color: ColorPalette.PRIMARY_TEXT,
    padding: '0 1.5em .75em',
    fontSize: '1.1em',
  },
}

export default GroupMembersAdd
