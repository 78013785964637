import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import Icon from '@mdi/react'
import { mdiAlertCircleOutline } from '@mdi/js'
import { JsxElement } from 'typescript'

import { ColorPalette } from '../../../config'
import ButtonGrey from '../../BaseComponents/Buttons/ButtonGrey'
import ButtonBlue from '../../BaseComponents/Buttons/ButtonBlue'
import { CsvFileSelectInstructions } from './CsvFileSelectInstructions'
import { CsvService } from '../../../services'
import LoadingModal from '../LoadingModal'
import { CsvFileSelector } from './CsvFileSelector'
import { PeopleFlowCombinedReducer } from '../../../store'
import { CsvProcess, getCsvFieldHeaderLabel } from '../../../utils'
import { AssociationSettingsRepository } from '../../../repositories'

export enum ProcessingStep {
  FILE_SELECT = 'FILE_SELECT',
  FILE_TO_JSON = 'FILE_TO_JSON',
  VALIDATE = 'VALIDATE',
  ACTION_PENDING = 'ACTION_PENDING',
}
type ProcessingStepType =
  | ProcessingStep.FILE_SELECT
  | ProcessingStep.FILE_TO_JSON
  | ProcessingStep.VALIDATE
  | ProcessingStep.ACTION_PENDING

export type CsvFileColumnValuePairs = Record<string, string>

type CsvFileProcessingModalProps = {
  open: boolean
  title: string
  expectedHeaderColumns: string[]
  csvProcess: CsvProcess
  instructions?: string | React.ReactNode
  insertSection?: Partial<Record<ProcessingStepType, React.ReactNode | JsxElement>>
  getDefaultHeaderLabel?: (key: string) => string | undefined
  transformValue?: (value: string, header?: string | number) => any
  transformHeader?: (header: string, index: number) => string
  onCancelClick: () => void
  onSubmitClick: (jsonFileContent: CsvFileColumnValuePairs[]) => void
}

const processToActionButtonLabelMapping: Record<ProcessingStepType, string> = {
  [ProcessingStep.FILE_SELECT]: '', // blank string will result in no button rendered
  [ProcessingStep.FILE_TO_JSON]: 'Next',
  [ProcessingStep.VALIDATE]: '',
  [ProcessingStep.ACTION_PENDING]: 'Proceed',
}

export const CsvFileProcessingModal = (props: CsvFileProcessingModalProps) => {
  const {
    open,
    title,
    instructions,
    expectedHeaderColumns,
    csvProcess,
    getDefaultHeaderLabel,
    transformValue,
    transformHeader,
    onCancelClick,
    onSubmitClick,
  } = props

  const fileContentsInValidRef = useRef(false)

  const associationRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.associationRepo,
  ) as AssociationSettingsRepository
  const selectedAssociation = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedAssociation,
  )

  const [currentProcess, setCurrentProcess] = useState<ProcessingStepType>(ProcessingStep.FILE_SELECT)
  const [selectedFile, setSelectedFile] = useState<File>()
  const [jsonFileContent, setJsonFileContent] = useState<CsvFileColumnValuePairs[]>([])
  const [progressModalOpen, setProgressModalOpen] = useState(false)

  useEffect(() => {
    if (currentProcess === ProcessingStep.FILE_TO_JSON && jsonFileContent.length) {
      validate()
    }
  }, [currentProcess, jsonFileContent])

  useEffect(() => {
    if (currentProcess === ProcessingStep.ACTION_PENDING && !open) {
      resetState()
    }
  }, [currentProcess, open])

  const initiateProcess = async () => {
    setProgressModalOpen(true)
    const json = await parse(selectedFile as File)
    if (json) {
      setJsonFileContent(json as Record<string, string>[])
    }
    setProgressModalOpen(false)
  }

  const parse = async (file: File) => {
    try {
      return await CsvService.csvToJson(file, { transformValue, transformHeader })
    } catch (error) {
      console.error('CSV to JSON error', error)
    }
    return Promise.reject("Couldn't parse CSV file")
  }

  const validate = () => {
    setCurrentProcess(ProcessingStep.VALIDATE)
    const firstRowContent = jsonFileContent[0]
    const firstRowColumnKeys = Object.keys(firstRowContent)
    const expectedHeaderColumnNames = Object.values(expectedHeaderColumns)
    if (firstRowColumnKeys.length !== expectedHeaderColumnNames.length) {
      console.error('VALIDATION ERROR: expected header column count does not match file content')
      fileContentsInValidRef.current = true
      return
    }
    const orgConfig = associationRepo.getOrganisationConfig(selectedAssociation)
    const firstRowColumnLabels = firstRowColumnKeys.map((key) => {
      const defaultLabel = getDefaultHeaderLabel ? getDefaultHeaderLabel(key) : ''
      const label = getCsvFieldHeaderLabel(orgConfig, csvProcess, key, defaultLabel)
      const labelNoSpaces = label.replace(/\s/g, '')
      return labelNoSpaces.toLowerCase()
    })
    const columnLabelsMatch = expectedHeaderColumnNames.every((expectedLabel) => {
      const labelNoSpaces = expectedLabel.replace(/\s/g, '')
      const labelLowercased = labelNoSpaces.toLowerCase()
      return firstRowColumnLabels.includes(labelLowercased)
    })
    if (!columnLabelsMatch) {
      console.error('VALIDATION ERROR: expected header column names do not match')
      fileContentsInValidRef.current = true
      return
    }
    // if no validation errors
    setCurrentProcess(ProcessingStep.ACTION_PENDING)
  }

  const handleFileSelection = async (file: File) => {
    setSelectedFile(file)
    setCurrentProcess(ProcessingStep.FILE_TO_JSON)
  }

  const getCurrentProcessStateContent = () => {
    if (fileContentsInValidRef.current) {
      return (
        <div style={{ ...styles.textStyle, fontWeight: 'normal', marginTop: '1em' }}>
          <Icon path={mdiAlertCircleOutline} size={1.5} color={ColorPalette.WARNING_RED_50PERCENT} />
          <br />
          File contents are invalid.
          <br />
          Please correct and try again.
        </div>
      )
    }

    const columnLabels = Object.values(expectedHeaderColumns)
    let fileSelectInstructions = null
    let insertSection = null
    if (props.insertSection && props.insertSection[currentProcess]) {
      insertSection = props.insertSection[currentProcess]
    }

    if (currentProcess === ProcessingStep.FILE_SELECT) {
      fileSelectInstructions = (
        <CsvFileSelectInstructions
          instructions={instructions}
          expectedHeaderColumnNames={columnLabels}
          showTemplateDownloadButton={true}
        />
      )
    }

    let content = (
      <CsvFileSelector
        instructions={fileSelectInstructions}
        fileSelected={!!selectedFile}
        insertSection={insertSection}
        onFileSelection={handleFileSelection}
        onCancel={() => setSelectedFile(undefined)}
      />
    )

    if (currentProcess === ProcessingStep.ACTION_PENDING) {
      content = (
        <div style={{ ...styles.textStyle, fontWeight: 'normal', marginTop: '1.5em' }}>
          {`${jsonFileContent.length} records to process`}
          {insertSection}
        </div>
      )
    }

    return content
  }

  const onActionButtonClick = () => {
    if (fileContentsInValidRef.current) {
      resetState()
      return
    }

    if (currentProcess === ProcessingStep.FILE_SELECT) {
      setCurrentProcess(ProcessingStep.FILE_TO_JSON)
    }

    if (currentProcess === ProcessingStep.FILE_TO_JSON) {
      initiateProcess()
    }

    if (currentProcess === ProcessingStep.VALIDATE) {
      setCurrentProcess(ProcessingStep.ACTION_PENDING)
    }

    if (currentProcess === ProcessingStep.ACTION_PENDING) {
      onSubmitClick(jsonFileContent)
    }
  }

  const renderActionButton = () => {
    let buttonLabel = processToActionButtonLabelMapping[currentProcess]

    if (fileContentsInValidRef.current) {
      buttonLabel = 'Retry'
    }

    if (buttonLabel) {
      return <ButtonBlue onClick={onActionButtonClick}>{buttonLabel}</ButtonBlue>
    }
    return null
  }

  const getProgressModalMessage = () => {
    if (currentProcess === ProcessingStep.FILE_TO_JSON) {
      return 'Parsing CSV file contents...'
    }

    if (currentProcess === ProcessingStep.VALIDATE) {
      return 'Validating CSV file contents...'
    }

    return ''
  }

  const handleCancelClick = () => {
    resetState()
    onCancelClick()
  }

  const resetState = () => {
    setCurrentProcess(ProcessingStep.FILE_SELECT)
    setSelectedFile(undefined)
    setJsonFileContent([])
    fileContentsInValidRef.current = false
  }

  let modal = null
  if (open) {
    const content = getCurrentProcessStateContent()
    const actionButton = renderActionButton()
    modal = (
      <div style={styles.screenContainer}>
        <div style={styles.cardContainer}>
          <h1 style={{ ...styles.textStyle }}>{title}</h1>
          {content}
          <div style={styles.actionButtons}>
            <ButtonGrey onClick={handleCancelClick} disabled={false}>
              Cancel
            </ButtonGrey>
            {actionButton}
          </div>
        </div>
        <LoadingModal open={progressModalOpen}>{getProgressModalMessage()}</LoadingModal>
      </div>
    )
  }

  return modal
}

const styles = {
  screenContainer: {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    backgroundColor: ColorPalette.MODAL_BACKGROUND_OVERLAY,
    width: window.innerWidth,
    height: window.innerHeight,
    zIndex: 1000,
    overflow: 'hidden',
  },
  cardContainer: {
    position: 'absolute' as 'absolute',
    top: window.innerHeight * 0.05,
    right: window.innerWidth * 0.34,
    zIndex: 100000,
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'flex-start',
    alignItems: 'center' as 'center',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.2)',
    backgroundColor: ColorPalette.CARD_WHITE,
    marginTop: window.innerHeight * 0.03,
    width: window.innerWidth * 0.32,
    padding: '30px 30px 30px 30px',
    borderRadius: 8,
  },
  textStyle: {
    textAlign: 'center' as 'center',
    alignSelf: 'center',
    fontFamily: 'roboto',
    fontWeight: 'bold',
    color: ColorPalette.PRIMARY_TEXT,
    fontSize: '1rem',
  },
  actionButtons: {
    display: 'flex',
    alignContent: 'center',
    marginTop: 40,
    gap: 20,
    width: '100%',
  },
}
