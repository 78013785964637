import { useState, useEffect } from 'react'
import Icon from '@mdi/react'
import { mdiInformationOutline } from '@mdi/js'

import { ColorPalette } from '../../config/colors'
import Dialog from '@material-ui/core/Dialog'
import ButtonBlue from '../../components/BaseComponents/Buttons/ButtonBlue'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import InfoCollectorModal from '../../components/Modals/InfoCollector'

type IdPasteModalProps = {
  open: boolean
  close: () => void
  setIdPassportsPasted: (idPassports: string[]) => void
}

export const IdPasteModal = (props: IdPasteModalProps) => {
  const [clipboardCopyConfirmed, setClipboardCopyConfirmed] = useState(false)
  const [uniqueIdPassports, setUniqueIdPassports] = useState<string[]>([])

  useEffect(() => {
    if (clipboardCopyConfirmed) {
      getUniqueIdsFromClipboard()
    }
  }, [clipboardCopyConfirmed])

  const reset = () => {
    setClipboardCopyConfirmed(false)
  }

  const getUniqueIdsFromClipboard = async () => {
    const clipboardContents = await navigator.clipboard.readText()
    const idPassportsFromClipboard = [
      ...new Set(
        clipboardContents
          .replace(/,/g, '') // remove any commas
          .replace(/ /g, '') // remove any spaces
          .replace(/\r/g, '') // remove any carriage returns (introduce by copies from Excel)
          .split('\n'), // split on newlines
      ),
    ]
    const validIdPassports = idPassportsFromClipboard.filter((idPassport: string) => {
      // valid IDs aren't empty
      if (idPassport.length === 0) {
        return false
      }
      // valid IDs only contain digits
      // valid passports include the letters A to Z and the numbers 0 to 9
      if (/^[A-Z0-9]+$/.test(idPassport)) {
        return true
      }
      return false
    })
    /*
        @NICKI: If there are no valid validIdPassports, then the modal just remains onscreen and the user gets frustrated/confused about what to do next.
        There needs to be some sort of warning message shown.
    */
    if (validIdPassports.length > 0) {
      setUniqueIdPassports(validIdPassports)
      setClipboardCopyConfirmed(true)
    }
  }

  const applyAndClose = (ids: string[]) => {
    setIdPassportsPasted([...ids])
    close()
  }

  const idAlreadyInList = (id: string) => {
    return !uniqueIdPassports.includes(id)
  }

  const { open, close, setIdPassportsPasted } = props

  if (open && clipboardCopyConfirmed) {
    return (
      <InfoCollectorModal
        open={true}
        defaultItems={uniqueIdPassports}
        header="IMPORT LIST"
        subHeader="Review numbers to add to the group"
        warningMessage="Add at least one ID or passport number"
        validateInput={idAlreadyInList}
        placeholder="ID or passport number"
        minimumItems={1}
        dismiss={reset}
        onSuccess={applyAndClose}
        onReject={reset}
        successLabel="Confirm"
      />
    )
  }
  // TODO: use /components/Modals/Modal.tsx after web factor lands
  return (
    <Dialog
      open={open}
      onBackdropClick={() => {
        close()
        setClipboardCopyConfirmed(false)
      }}
      BackdropProps={{ invisible: true }}
      PaperProps={{ style: { borderRadius: 8 } }}
      style={{ backgroundColor: ColorPalette.MODAL_BACKGROUND_OVERLAY }}>
      <div style={styles.pasteListModal}>
        <DialogTitle style={{ color: ColorPalette.PRIMARY_TEXT }}>IMPORT LIST</DialogTitle>
        <DialogContent>
          <div>
            <Icon path={mdiInformationOutline} color={ColorPalette.PRIMARY_TEXT} size={3} />
            <div style={{ width: '40ch', marginTop: '.75em' }}>
              Copy the list of ID / passport numbers then click 'Proceed'.
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <ButtonBlue onClick={getUniqueIdsFromClipboard} style={{ margin: '1.25em 0 1em' }}>
            Proceed
          </ButtonBlue>
        </DialogActions>
      </div>
    </Dialog>
  )
}

const styles = {
  pasteListModal: {
    textAlign: 'center' as 'center',
    alignSelf: 'center',
    fontFamily: 'roboto',
    color: ColorPalette.PRIMARY_TEXT,
    padding: '.5em 1.5em .75em',
    fontSize: '1.1em',
  },
}
