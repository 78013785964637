import React, { Component } from 'react'
import Icon from '@mdi/react'
import { mdiPlus, mdiMinus } from '@mdi/js'

import { ColorPalette } from '../../../config/colors'
import { toLowerCaseCustom } from '../../../utils'
import ButtonBlue from '../../BaseComponents/Buttons/ButtonBlue'
import ButtonGrey from '../../BaseComponents/Buttons/ButtonGrey'
import TextCollector from '../../GeneralUI/TextCollector/TextCollector'
import ButtonRound from '../../BaseComponents/Buttons/ButtonRound'
import LabelCollector from '../../Modals/LabelCollector'
import ModalPortal from '../../Modals/ModalPortal'

interface InfoCollectorProps {
  defaultItems: string[]
  pickerItems?: (string | number)[]
  placeholder: string
  minimumItems: number
  warningMessage: string
  hideActionButtons?: boolean
  successLabel?: string | undefined
  header?: string | React.ReactChild[]
  subHeader?: string
  containerStyle?: React.CSSProperties | undefined
  type?: string
  addOnFirstItemClick?: boolean
  uppercasePickerItems?: boolean
  sortInputValues?: (values: string[]) => string[]
  itemAddTransform?: (item: string) => string | undefined
  onSuccess?: (items: string[]) => void
  onReject?: () => void
  transformInput?: ((data: string) => string) | undefined
  transformOutput?: ((data: string) => string) | undefined
  validateInput?: ((data: string) => boolean) | undefined
  onItemNavigateClick?: ((item: string, items: string[]) => void) | undefined
}

interface InfoCollectorState {
  items: string[]
  pickerItems: (string | number)[]
  warning: string
  showNewItemModal: boolean
}

class InfoCollector extends Component<InfoCollectorProps, InfoCollectorState> {
  state: InfoCollectorState = {
    items: [],
    pickerItems: [],
    warning: '',
    showNewItemModal: false,
  }

  componentDidMount() {
    let { defaultItems, pickerItems, placeholder } = this.props
    pickerItems = pickerItems ? pickerItems : []
    if (placeholder) {
      pickerItems = [placeholder, ...pickerItems]
    }
    this.setState({ items: defaultItems ? defaultItems : [], pickerItems })
  }

  submit() {
    let minimumItems = 0
    if (this.props.minimumItems) {
      minimumItems = this.props.minimumItems
    }
    if (this.state.items.length < minimumItems) {
      this.setState({ warning: this.props.warningMessage })
    } else if (this.props.onSuccess) {
      let items = [...this.state.items]
      if (this.props.transformOutput !== undefined) {
        //@ts-ignore
        items = items.map((item) => this.props.transformOutput(item))
      }
      this.props.onSuccess(items)
    }
  }

  reject() {
    if (this.props.onReject) {
      this.props.onReject()
    }
  }

  addItem(dataItem: string) {
    if (!this.props.placeholder || this.props.placeholder !== dataItem) {
      if (dataItem) {
        if (this.props.transformInput !== undefined) {
          dataItem = this.props.transformInput(dataItem)
        }

        let alreadyExists = false
        let valid: boolean = true
        if (typeof this.props?.validateInput !== 'undefined') {
          valid = this.props.validateInput(dataItem)
        }

        let items = this.state.items

        items.forEach((existingItem) =>
          toLowerCaseCustom(existingItem) === toLowerCaseCustom(dataItem) ? (alreadyExists = true) : null,
        )

        if (alreadyExists || !valid) {
          this.setState({ warning: alreadyExists ? 'Already exists' : 'Invalid option' })
        } else {
          this.setState({ items: [...items, dataItem], warning: '' })
        }
      }
    }
  }

  removeItem(dataItem: string) {
    let items = this.state.items
    items = items.filter((existingItem) => toLowerCaseCustom(existingItem) !== toLowerCaseCustom(dataItem))
    this.setState({ items })
  }

  toggleNewItemModal() {
    this.setState((prevState) => ({ showNewItemModal: !prevState.showNewItemModal }))
  }

  render() {
    let items = [...this.state.items]
    if (this.props.sortInputValues) {
      items = this.props.sortInputValues(items)
    }
    let itemList = [...items].map((dataItem, i) => {
      if (this.props.onItemNavigateClick) {
        return (
          <div
            style={{
              display: 'flex',
              gap: 20,
              width: '90%',
              padding: '1.5%',
              alignItems: 'center',
              justifyItems: 'space-between',
            }}
            key={`${dataItem}_${i}`}>
            <ButtonBlue
              onClick={() => {
                // @ts-ignore
                this.props.onItemNavigateClick(dataItem, this.state.items)
              }}
              style={{ textTransform: 'uppercase' }}>
              {dataItem}
            </ButtonBlue>
            <ButtonRound
              onClick={() => this.removeItem(dataItem)}
              icon={<Icon size={0.9} path={mdiMinus} color={ColorPalette.PRIMARY_BLUE} />}
              style={{
                backgroundColor: ColorPalette.CARD_WHITE,
                border: `1px solid ${ColorPalette.PRIMARY_BLUE}`,
              }}
            />
          </div>
        )
      }

      return (
        <TextCollector
          key={`${dataItem}_${i}`}
          type={'textBox'}
          value={dataItem}
          disabled={true}
          textStyle={{ borderBottom: `1px solid ${ColorPalette.TERTIARY_TEXT}` }}
          buttonStyle={{
            backgroundColor: ColorPalette.CARD_WHITE,
            border: `1px solid ${ColorPalette.PRIMARY_BLUE}`,
          }}
          icon={<Icon size={0.9} path={mdiMinus} color={ColorPalette.PRIMARY_BLUE} />}
          onClick={(value: string) => this.removeItem(value)}
        />
      )
    })
    let header = null
    if (this.props.header) {
      header = (
        <h1 style={{ ...styles.textStyle, fontWeight: 'bold', fontSize: '1rem', marginTop: 5 }}>{this.props.header}</h1>
      )
    }

    let subHeader = null
    if (this.props.subHeader || this.state.warning) {
      subHeader = (
        <h1
          style={{
            ...styles.textStyle,
            marginBottom: 25,
            color: this.state.warning ? ColorPalette.WARNING_RED : ColorPalette.PRIMARY_TEXT,
          }}>
          {this.state.warning || this.props.subHeader}
        </h1>
      )
    }

    let actionButtons = null
    if (!this.props.hideActionButtons) {
      actionButtons = (
        <div style={{ ...styles.buttonContainer }}>
          <ButtonGrey style={{ width: '48%' }} onClick={() => this.reject()}>
            Cancel
          </ButtonGrey>
          <ButtonBlue style={{ width: '48%' }} onClick={() => this.submit()}>
            {this.props.successLabel ? this.props.successLabel : 'Send'}
          </ButtonBlue>
        </div>
      )
    }

    return (
      <div style={{ ...styles.container, ...this.props.containerStyle }}>
        {header}
        {subHeader}
        <TextCollector
          type={this.props.type || 'textBox'}
          pickerItems={this.state.pickerItems}
          placeholder={this.props.placeholder}
          buttonStyle={{ backgroundColor: ColorPalette.PRIMARY_BLUE }}
          icon={<Icon size={1.02} path={mdiPlus} color={ColorPalette.CARD_WHITE} />}
          onClick={(value: string) => this.addItem(value)}
          shouldReset={this.props.type === 'textBox' || !this.props.type ? true : false}
          addOnFirstItemClick={this.props.addOnFirstItemClick ? () => this.toggleNewItemModal() : undefined}
          uppercasePickerItems={this.props.uppercasePickerItems}
        />
        {this.state.showNewItemModal && (
          <ModalPortal>
            <LabelCollector
              open={true}
              warning="Enter data flow name"
              placeholder="Enter data flow name"
              buttonLabel="Add"
              iconName="setting"
              dismiss={() => this.toggleNewItemModal()}
              submit={(newItem: string) => {
                if (this.props.itemAddTransform) {
                  // @ts-ignore
                  newItem = this.props.itemAddTransform(newItem)
                }
                this.addItem(newItem)
                this.toggleNewItemModal()
              }}
              style={{ zIndex: 20000 }}
            />
          </ModalPortal>
        )}

        {itemList}
        {actionButtons}
      </div>
    )
  }
}

let styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
  },
  buttonContainer: {
    marginTop: '10%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '.25em',
  },
  textStyle: {
    marginTop: 5,
    textAlign: 'center' as 'center',
    alignSelf: 'center',
    fontFamily: 'roboto',
    fontWeight: 'normal',
    color: ColorPalette.PRIMARY_TEXT,
    fontSize: '0.9rem',
    paddingLeft: 10,
    paddingRight: 10,
  },
}

export default InfoCollector
