import TableBody from '@material-ui/core/TableBody'
import { SimpleTableRow } from './SimpleTableRow'

type SimpleTableBodyProps = {
  rows: Record<string, string>[]
  stickyFirstColumn?: boolean
  onRowClick?: (rowData: Record<string, string>) => void
}

export const SimpleTableBody = (props: SimpleTableBodyProps) => {
  const renderRows = () =>
    props.rows.map((row: any, index: number) => (
      <SimpleTableRow
        row={row}
        stickyFirstColumn={props.stickyFirstColumn}
        onRowClick={props.onRowClick}
        key={`tableRow_${index}`}
      />
    ))

  const rows = renderRows()
  return <TableBody>{rows}</TableBody>
}
