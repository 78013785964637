import { ColorPalette } from '../../../config/colors'
import { Component } from 'react'
import ButtonRound from '../../BaseComponents/Buttons/ButtonRound'
import TextInputBasic from '../../BaseComponents/Text/TextInputBasic'
import Picker from '../../BaseComponents/Pickers/Picker'

interface ITextCollectorProps {
  value?: string
  placeholder?: string
  disabled?: boolean
  shouldReset?: boolean
  type?: string
  icon?: JSX.Element
  buttonStyle: React.CSSProperties | undefined
  textStyle?: React.CSSProperties | undefined
  pickerItems?: (string | number)[] | undefined
  uppercasePickerItems?: boolean
  addOnFirstItemClick?: () => void | undefined
  onClick: (value: string) => void
}

interface ITextCollectorState {
  value: string
}

class TextCollector extends Component<ITextCollectorProps, ITextCollectorState> {
  state = {
    value: '',
  }

  componentDidMount() {
    if (this.props.value) {
      this.setState({ value: this.props.value })
    }
  }

  textHandler(event: any) {
    const value = event.target.value
    const { addOnFirstItemClick, pickerItems } = this.props
    if (addOnFirstItemClick && pickerItems && value === pickerItems[1]) {
      addOnFirstItemClick()
      return
    }
    this.setState({ value: event.target.value })
  }

  onClick() {
    if (this.props.onClick) {
      this.props.onClick(this.state.value)
      if (this.props.shouldReset) {
        this.setState({ value: '' })
      }
    }
  }

  render() {
    let inputComponent: JSX.Element | null = null
    if (this.props.type === 'textBox') {
      inputComponent = (
        <TextInputBasic
          style={{ ...styles.textStyle, ...this.props.textStyle }}
          value={this.state.value}
          placeholder={this.props.placeholder}
          disabled={this.props.disabled}
          onChange={(e: any) => this.textHandler(e)}
        />
      )
    } else {
      inputComponent = (
        <Picker
          style={{ marginTop: 5, width: '90%' }}
          items={this.props.pickerItems ? this.props.pickerItems : []}
          value={this.state.value}
          disabled={false}
          onChange={(e) => this.textHandler(e)}
          uppercaseItems={this.props.uppercasePickerItems}
        />
      )
    }
    return (
      <div style={{ ...styles.container }}>
        {inputComponent}
        <ButtonRound
          style={{ marginLeft: 20, ...this.props.buttonStyle }}
          icon={this.props.icon}
          onClick={() => this.onClick()}
        />
      </div>
    )
  }
}

let styles = {
  container: {
    paddingLeft: '1.5%',
    paddingRight: '1.5%',
    width: '90%',
    alignSelf: 'center',
    height: 60,
    display: 'flex',
    alignItems: 'center',
  },
  textStyle: {
    color: ColorPalette.SECONDARY_TEXT,
    paddingBottom: 8,
    borderBottomWeight: 1,
    borderBottomStyle: 'solid' as 'solid',
    borderBottomColor: ColorPalette.PRIMARY_TEXT,
  },
}

export default TextCollector
