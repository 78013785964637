import { useState, useEffect, useRef } from 'react'
import { difference } from 'lodash'

import { ColorPalette } from '../../../config/colors'
import { FieldValueInput } from './FieldValueInput'
import { getFieldKeyFromLabel, getFieldLabelFromKey } from '../../../utils'
import { FieldConfig } from '../../../types'

interface IFieldValueChooserProps {
  fieldConfig: FieldConfig
  title: string | React.ReactElement
  fields: string[]
  fieldValuePairs: Record<string, string>
  style?: React.CSSProperties
  setFieldValuePair: (field: string, value: string, previousField?: string) => void
}

export const FieldValueChooser = (props: IFieldValueChooserProps) => {
  const { title, fieldConfig, fields, fieldValuePairs, setFieldValuePair } = props
  const [newFieldSelection, setNewFieldSelection] = useState('')
  const [inputRowInFocus, setInputRowInFocus] = useState('')

  useEffect(() => {
    if (inputRowInFocus && valueInputRef?.current) {
      valueInputRef.current.focus()
    }
  }, [inputRowInFocus])

  const valueInputRef = useRef<HTMLInputElement>(null)
  const fieldValuePairKeys = Object.keys(fieldValuePairs)
  const numberOfExistingFieldsValuePairs = fieldValuePairKeys.length
  const allExistingFieldSelectionsHaveValues =
    numberOfExistingFieldsValuePairs > 0 &&
    fieldValuePairKeys.filter((key) => fieldValuePairs[key] && fieldValuePairs[key] !== '').length ===
      numberOfExistingFieldsValuePairs
  const fieldsNotYetSelected = difference(fields, Object.keys(fieldValuePairs))
  return (
    <div style={{ ...styles.container, ...styles.sectionInput, ...props.style }}>
      <p style={styles.label}>{title}</p>
      {fieldValuePairKeys.map((field: string) => (
        <FieldValueInput
          ref={inputRowInFocus === field ? valueInputRef : null}
          currentFieldValue={getFieldLabelFromKey(fieldConfig, field, true)}
          value={fieldValuePairs[field]}
          fieldOptions={fields.map((field) => fieldConfig[field].label)}
          onFieldValuePairChange={(selectedField: string, value: string) => {
            setFieldValuePair(getFieldKeyFromLabel(fieldConfig, selectedField), value, field)
          }}
          onValueInputClick={() => setInputRowInFocus(field)}
          key={`fieldValueInput_${field}`}
        />
      ))}
      {(allExistingFieldSelectionsHaveValues || numberOfExistingFieldsValuePairs === 0) && (
        <FieldValueInput
          currentFieldValue={newFieldSelection}
          value={fieldValuePairs[newFieldSelection] || ''}
          fieldOptions={fieldsNotYetSelected.map((field) => fieldConfig[field].label)}
          onFieldValuePairChange={(selectedField: string, value: string) => {
            setFieldValuePair(getFieldKeyFromLabel(fieldConfig, selectedField), value)
          }}
          key={`fieldValueInput_NEW`}
        />
      )}
    </div>
  )
}

const styles = {
  container: {
    zIndex: 1,
    marginTop: 20,
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    border: `1px solid rgba(195,205,225,0.6)`,
    borderRadius: 16,
    paddingBottom: 15,
    paddingInline: 20,
  },
  label: {
    color: ColorPalette.LIGHT_GREY,
    backgroundColor: ColorPalette.CARD_WHITE,
    zIndex: 2,
    marginTop: -8,
    alignSelf: 'center',
    fontSize: '0.65rem',
    paddingLeft: 10,
    paddingRight: 10,
  },
  sectionInput: {
    margin: '40px 1.5em 0px 1.5em',
  },
}
