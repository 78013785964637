import React, { Component, createRef } from 'react'
import Radium from 'radium'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'

import { GridSize } from '@material-ui/core/Grid'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'

import { ColorPalette } from '../../config/colors'
import { ActionType } from '../../store/actions/actions'
import { toLowerCaseCustom } from '../../utils'
import { AllScreenNames } from '../../config'
import completedScreens from '../completedScreens'
import NavigationBar from '../../components/Navigation/NavigationBar'
import SectionHeader from '../../components/Headings/SectionHeaderPrimary'
import LoadingModal from '../../components/Modals/LoadingModal'
import AlertModalOneButton from '../../components/Modals/AlertModalOneButton'
import SideMenu from '../../components/Navigation/SideMenu'
import ProfileViewingOptions from '../../components/GeneralUI/ProfileViewingOptions/ProfileViewingOptions'
import FaceShot from '../../components/GeneralUI/FaceShot/FaceShot'
import ButtonGeneric from '../../components/BaseComponents/Buttons/ButtonGeneric'
import { PeopleFlowCombinedReducer } from '../../store'
import { Profile } from '../../models'
import { getRemoteFile } from '../../provider/remoteData'
import { PeopleRouteState } from '../../types'

interface IFaceShotsProps extends RouteComponentProps {
  updateState: (arg: { currentSection?: AllScreenNames; currentScreen: AllScreenNames }) => void
  changeScreen: (screen: AllScreenNames) => void

  profile: Profile
  profilePic: string
  currentScreen: AllScreenNames
  hasWorkforceAccess: boolean
}

interface IFaceShotsState {
  loadingModalOpen: boolean
  warningModalOpen: boolean
  accountModalOpen: boolean
  forgotPasswordModalOpen: boolean
  settingsModalOpen: boolean
  loadingModalMessage: string
  warningModalHeader: string
  warningModalMessage: string
  sideMenuComponents: JSX.Element
  sideMenuVisible: boolean
  tableWidth: GridSize
  side: string
  raised: string
}

class FaceShots extends Component<IFaceShotsProps, IFaceShotsState> {
  sidePicRef?: React.RefObject<any>
  raisedPicRef?: React.RefObject<any>

  initialModalState = {
    loadingModalOpen: false,
    warningModalOpen: false,
    accountModalOpen: false,
    forgotPasswordModalOpen: false,
    settingsModalOpen: false,
  }

  state: IFaceShotsState = {
    ...this.initialModalState,
    loadingModalMessage: '',
    warningModalHeader: '',
    warningModalMessage: '',
    sideMenuComponents: <div />,
    sideMenuVisible: true,
    tableWidth: 9,
    side: '',
    raised: '',
  }

  componentDidMount() {
    try {
      this.sidePicRef = createRef()
      this.raisedPicRef = createRef()
      this.props.updateState({
        currentSection: AllScreenNames.PEOPLE,
        currentScreen: AllScreenNames.FACE_SHOTS,
      })
      this.initialiseSideMenu()
      this.fetchProfilePics()
    } catch (error) {}
  }

  closeModals = () => {
    this.setState({ ...this.initialModalState })
  }

  initialiseSideMenu(): void {
    const { name, surname, idPassport } = this.props.profile.getPersonalInfo()
    const sideMenuComponents = (
      <ProfileViewingOptions
        profilePic={this.props.profilePic}
        name={`${name} ${surname}`}
        idPassport={idPassport}
        onClick={(sectionName: AllScreenNames) => this.sideMenuHandler(sectionName)}
        currentScreen={this.props.currentScreen}
      />
    )
    this.setState({ sideMenuComponents })
  }

  sideMenuHandler(screen: AllScreenNames): void {
    if (completedScreens.includes(screen)) {
      this.props.updateState({ currentScreen: screen })
      this.props.history.push(`/people/${toLowerCaseCustom(screen.split(' ').join(''))}`)
    } else {
      this.props.history.push('/comingsoon')
    }
  }

  async fetchProfilePics(): Promise<void> {
    const { idPassport } = this.props.profile.getPersonalInfo()
    const side = await getRemoteFile(`People/${idPassport}/FaceShots/side`)
    const raised = await getRemoteFile(`People/${idPassport}/FaceShots/raised`)

    this.setState({ side, raised }, () => {
      if (typeof this.sidePicRef === 'undefined' || typeof this.raisedPicRef === 'undefined') {
        return
      }
      this.sidePicRef.current.reloadProfilePic()
      this.raisedPicRef.current.reloadProfilePic()
    })
    // console.log("side: ", side)
  }

  goToProfiles(): void {
    this.closeModals()
    window.scrollTo(0, 0)
    setTimeout(
      () =>
        this.props.history.push('/people', {
          customFilterState: (this.props.location.state as PeopleRouteState).prevCustomFilterState,
        }),
      30,
    )
  }

  displayWarning(error: any): void {
    let warning = ''

    try {
      if (error.code === 'NetworkError') {
        warning = 'Seems like your internet connection is down. Reconnect to the network, then try again.'
      } else if ('message' in error) {
        warning =
          "The following error message was returned when logging in:\n\n'" +
          error.message +
          "'. \n\nRefresh the page and try again. If unsuccessful, then contact tech support"
      } else {
        warning = 'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
      }
    } catch (error) {
      warning = 'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
    }

    this.setState({
      ...this.initialModalState,
      warningModalOpen: true,
      warningModalHeader: 'Warning',
      warningModalMessage: warning,
    })
  }

  render() {
    return (
      <div style={styles.container}>
        <NavigationBar match={this.props.match} location={this.props.location} history={this.props.history} />

        <SectionHeader
          style={styles.sectionHeader}
          disabled={true}
          onClick={() => this.setState({ sideMenuVisible: false, tableWidth: 10 })}>
          {this.props.currentScreen}
        </SectionHeader>

        <div style={styles.contentContainer}>
          <SideMenu visible={this.state.sideMenuVisible} menuComponents={this.state.sideMenuComponents} />
          <div style={styles.rightSide}>
            <ButtonGeneric
              style={{
                ...styles.backButton,
                width: (window.innerWidth * (this.state.tableWidth as number)) / 12,
              }}
              iconBefore={<ChevronLeftIcon style={styles.buttonIconStyle} />}
              label={'BACK'}
              onClick={() => this.goToProfiles()}
            />
            <div style={styles.rightSideContent}>
              <FaceShot angle={'Center'} profilePic={this.props.profilePic} />
              <FaceShot ref={this.sidePicRef} angle={'Side'} profilePic={this.state.side} />
              <FaceShot ref={this.raisedPicRef} angle={'Raised'} profilePic={this.state.raised} />
            </div>
          </div>
        </div>

        <AlertModalOneButton
          open={this.state.warningModalOpen}
          header={this.state.warningModalHeader}
          body={this.state.warningModalMessage}
          buttonLabel={'Ok'}
          onClick={() => this.closeModals()}
        />
        <LoadingModal open={this.state.loadingModalOpen}>{this.state.loadingModalMessage}</LoadingModal>
        <AlertModalOneButton
          open={!this.props.hasWorkforceAccess}
          header={'Not Authorised'}
          body={"You don't have permission to view profile pics."}
          buttonLabel={'Ok'}
          opaqueBackground={true}
          onClick={() => this.props.history.goBack()}
        />
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255, 1), rgba(209,210,230, 1))',
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto 1.5%',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    paddingInline: 'max(2em, 2%)',
    overflow: 'hidden',
  },
  rightSideContent: {
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: ColorPalette.CARD_WHITE,
  },
  buttonIconStyle: {
    color: ColorPalette.PRIMARY_TEXT,
    width: '1.2rem',
    height: '1.2rem',
  },
  backButton: {
    fontWeight: '550',
    fontSize: '0.8rem',
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    ':hover': {
      color: ColorPalette.PRIMARY_BLUE,
    },
    ':active': {
      color: ColorPalette.DARK_GREY,
    },
  },
}

const mapStateToProps = (state: PeopleFlowCombinedReducer) => {
  return {
    idPassport: state.sessionManager.idPassport,
    selectedAssociation: state.sessionManager.selectedAssociation,
    currentScreen: state.sessionManager.currentScreen,
    profile: state.sessionManager.profile,
    profilePic: state.sessionManager.profilePic,
    hasWorkforceAccess: state.sessionManager.hasWorkforceAccess,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateState: (data: any) => dispatch({ type: ActionType.UPDATE_STATE, data }),
    changeScreen: (screen: string) => dispatch({ type: ActionType.CHANGE_SCREEN, data: { currentScreen: screen } }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Radium(FaceShots))
