const completedscreens = [
  'Data Manager',
  'Documents',
  'Logs',
  'Faceshots',
  'Forms',
  'Performance',
  'Discipline',
  'Requests',
  'Users',
]

export default completedscreens
