import { ColorPalette } from '../../config/colors'
import { toUpperCaseCustom, getInitials, formatMobileNumber } from '../../utils'

import { Component } from 'react'
import { Property } from 'csstype'
import Radium from 'radium'
import ButtonBlue from '../BaseComponents/Buttons/ButtonBlue'
import ButtonGrey from '../BaseComponents/Buttons/ButtonGrey'
import ButtonGeneric from '../BaseComponents/Buttons/ButtonGeneric'
import { SessionService } from '../../services'

interface AccountManagementProps {
  open: boolean
  idPassport: string
  name: string
  surname: string
  email: string
  mobile: string

  dismiss: () => void
  onForgotPasswordClicked: () => void
  onSettingsClicked: () => void
  onLogoutClicked: () => void
}

interface AccountManagementState {
  open: boolean
  mounted: boolean
}

class AccountManagement extends Component<AccountManagementProps, AccountManagementState> {
  state: AccountManagementState = {
    open: false,
    mounted: false,
  }

  componentDidMount() {
    this.setState({ open: this.props.open })
  }

  componentDidUpdate(prevProps: AccountManagementProps) {
    if (prevProps.open !== this.props.open) {
      this.setState({ open: this.props.open }, () =>
        setTimeout(
          () => {
            this.setState({ mounted: this.props.open })
          },
          this.props.open ? 0 : 50,
        ),
      )
    }
  }

  forgotPassword(): Promise<void> {
    this.props.onForgotPasswordClicked()
    return SessionService.forgotPassword(this.props.idPassport)
  }

  render() {
    styles.screenContainer = {
      ...styles.screenContainer,
      animation: this.state.open ? 'x 0.05s ease-in' : 'x 0.1s ease-out',
      animationName: this.state.open ? fadeInBackground : fadeOutBackground,
    }

    styles.cardContainer = {
      ...styles.cardContainer,
      animation: this.state.open ? 'x 0.05s ease-in' : 'x 0.1s ease-out',
      animationName: this.state.open ? fadeInCard : fadeOutCard,
    }

    if (!this.state.mounted) {
      return null
    }

    return (
      <div style={styles.screenContainer}>
        <button style={styles.backgroundOverlay} onClick={() => this.props.dismiss()} />

        <div style={styles.cardContainer}>
          <div style={styles.imageContainer}>
            <p style={styles.initialsText}>{getInitials(this.props.name, this.props.surname)}</p>
          </div>
          <p style={styles.nameStyle}> {toUpperCaseCustom(`${this.props.name} ${this.props.surname}`)}</p>
          <p style={{ ...styles.subHeaderStyle, fontWeight: '300' }}> {this.props.email} </p>
          <p style={{ ...styles.subHeaderStyle, fontSize: '0.8rem', marginTop: -10 }}>
            {' '}
            {formatMobileNumber(this.props.mobile)}{' '}
          </p>
          <div style={styles.dividerLine} />
          <ButtonBlue style={{ marginTop: '2%', width: '100%' }} onClick={() => this.props.onSettingsClicked()}>
            {'Settings'}
          </ButtonBlue>
          <ButtonGrey style={{ marginTop: '5%', width: '100%' }} onClick={() => this.props.onLogoutClicked()}>
            {'Logout'}
          </ButtonGrey>
          <ButtonGeneric
            style={styles.forgotPasswordButton}
            label={'Forgot password?'}
            onClick={() => this.forgotPassword()}
          />
        </div>
      </div>
    )
  }
}

const fadeInBackground = Radium.keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
}) as Property.AnimationName

const fadeOutBackground = Radium.keyframes({
  '0%': { opacity: 1 },
  '100%': { opacity: 0 },
}) as Property.AnimationName

const fadeInCard = Radium.keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1, marginRight: window.innerHeight * 0.05 },
}) as Property.AnimationName

const fadeOutCard = Radium.keyframes({
  '0%': { marginRight: window.innerHeight * 0.05 },
  '50%': { opacity: 0 },
  '100%': { marginRight: -window.innerHeight * 0.13, opacity: 0 },
}) as Property.AnimationName

let styles: Record<string, React.CSSProperties> = {
  screenContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: ColorPalette.MODAL_BACKGROUND_OVERLAY_SOFT,
    width: window.innerWidth,
    height: window.innerHeight,
    zIndex: 1000,
    overflow: 'hidden',
  },
  backgroundOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: window.innerWidth,
    height: window.innerHeight,
    backgroundColor: 'transparent',
    zIndex: 10000,
    outline: 0,
    border: 'none',
  },
  cardContainer: {
    position: 'absolute',
    top: 10,
    right: 5,
    zIndex: 100000,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.2)',
    backgroundColor: ColorPalette.CARD_WHITE,
    marginTop: window.innerHeight * 0.03,
    marginRight: window.innerHeight * 0.05,
    width: window.innerWidth * 0.22,
    padding: 30,
    paddingBottom: 10,
    borderRadius: 8,
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `linear-gradient(to bottom, ${ColorPalette.PRIMARY_BLUE}, ${ColorPalette.SECONDARY_BLUE})`,
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.35)',
    marginTop: 10,
    width: window.innerWidth * 0.07,
    height: window.innerWidth * 0.07,
    borderRadius: window.innerWidth * 0.05,
  },
  initialsText: {
    textAlign: 'center',
    alignSelf: 'center',
    fontFamily: 'roboto',
    fontWeight: '50',
    fontSize: '3rem',
    color: ColorPalette.PRIMARY_LIGHT_TEXT,
  },
  nameStyle: {
    marginTop: 25,
    fontSize: '1rem',
    fontWeight: '550',
    fontFamily: 'Roboto',
    color: ColorPalette.PRIMARY_TEXT,
    width: '100%',
    textAlign: 'center',
  },
  subHeaderStyle: {
    marginTop: -5,
    fontSize: '0.85rem',
    fontWeight: '300',
    fontFamily: 'Roboto',
    color: ColorPalette.PRIMARY_TEXT,
    width: '100%',
    textAlign: 'center',
  },
  dividerLine: {
    width: '86%',
    marginTop: 10,
    marginLeft: 20,
    marginBottom: 20,
    borderBottom: `1px solid ${ColorPalette.LIGHT_GREY}`,
  },
  forgotPasswordButton: {
    color: ColorPalette.SECONDARY_TEXT,
    justifyContent: 'center',
    marginTop: 20,
    height: 30,
  },
  envSwitchButton: {
    width: '100%',
    height: 30,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: ColorPalette.CARD_WHITE,
    backgroundColor: ColorPalette.CARD_WHITE,
    outline: 0,
    border: 'none',
  },
}

export default Radium(AccountManagement)
