import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { ColorPalette } from '../../config/colors'

type ModalProps = {
  open: boolean
  title: string
  children: React.ReactNode
  actionButtons?: React.ReactNode[]
  style?: React.CSSProperties
  borderRadius?: number
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  fullScreen?: boolean
  fullWidth?: boolean
  titleStyle?: React.CSSProperties
  onBackdropClick?: () => void
}

const Modal = (props: ModalProps) => {
  const { open, title, children, actionButtons, fullScreen, fullWidth, maxWidth, titleStyle, style, onBackdropClick } =
    props
  let dialogActions = null
  if (actionButtons && actionButtons.length > 0) {
    dialogActions = <DialogActions style={{ margin: '1.25em 1em .25em', gap: '0.5em' }}>{actionButtons}</DialogActions>
  }

  return (
    <Dialog
      open={open}
      onBackdropClick={onBackdropClick}
      fullWidth={fullWidth}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      BackdropProps={{ invisible: true }}
      PaperProps={{ style: { borderRadius: 8 } }}
      style={{ ...styles.modal, ...style }}>
      <div style={{ padding: '1em 3em 2em', color: ColorPalette.PRIMARY_TEXT }}>
        <DialogTitle disableTypography={true} style={{ ...styles.dialogTitle, ...titleStyle }}>
          {title}
        </DialogTitle>
        <DialogContent style={{ padding: '8px 34px' }}>{children}</DialogContent>
        {dialogActions}
      </div>
    </Dialog>
  )
}

export default Modal

const styles = {
  modal: {
    backgroundColor: ColorPalette.MODAL_BACKGROUND_OVERLAY_SOFT,
  },
  dialogTitle: {
    textAlign: 'center' as 'center',
    alignSelf: 'center' as 'center',
    fontFamily: 'roboto',
    fontWeight: 'bold',
    color: 'rgb(35, 20, 140)',
    fontSize: '1rem',
  },
}
