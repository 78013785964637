interface DocChange {
    action: string;
    timestamp: number;
    docFamily: string;
    docName: string;
    fileName: string;
    name: any;
    surname: any;
    userIdPassport: string;
    appVersion: string;
}

export default class UpdateTrackerItemDocs {
    deviceUUID: string;
    idPassport: string;
    uid: string;
    name: string;
    surname: string;
    changes: { docs: DocChange[] };
    constructor(idPassport: string, uid: string, name: string, surname: string, deviceUUID: string) {
        this.deviceUUID = deviceUUID;
        this.idPassport = idPassport;
        this.uid = uid;
        this.name = name;
        this.surname = surname;
        this.changes = {
            docs: [],
        };
    }
}
