import { Section } from "./Section";
import { config } from "../../../components/Configurator/Processes/config";

interface Props {
    selectedDivision: string;
    selectedJob: string;
    sections: string[];
    getSubSectionRenderObject: (sectionName: string, subSection: any) => any;
}

export const Sections = (props: Props) => {
    const { selectedDivision, selectedJob, sections, getSubSectionRenderObject } = props;
    return (
        <>
            <div
                style={{
                    columnCount: "2 400px",
                    WebkitColumns: "2 400px",
                    gap: "max(3em, 3%)",
                    margin: "max(3em, 3%) 0 max(2em, 2%)",
                }}>
                {sections.map((sectionName: string) => (
                    <Section
                        selectedDivision={selectedDivision}
                        selectedJob={selectedJob}
                        // @ts-ignore (No index signature with a parameter of type 'string' was found on type '{ jobSubTypes: { label: string; subSections: { appStateName: string; label: string; }[]; }; competencies: { label: string; subSections: { configContext: string; appStateName: string; label: string; }[]; }; ... 4 more ...; terminate: { ...; }; }'.)
                        title={config[sectionName].label}
                        sectionName={sectionName}
                        // @ts-ignore
                        subSections={config[sectionName].subSections.map((subSection: any) =>
                            getSubSectionRenderObject(sectionName, subSection),
                        )}
                        style={{ breakInside: "avoid", marginBottom: "max(3em, 4%)" }}
                        key={`section_${sectionName}}`}
                    />
                ))}
            </div>
        </>
    );
};
