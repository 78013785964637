import { useState } from 'react'

import { ColorPalette } from '../../../config/colors'
import TwoColumnSelector from '../TwoColumnSelector/TwoColumnSelector'

interface IPrimaryTerminationOptionsProps {
  primaryTerminationReasons: string[]
  openSelectorModal: (selectorIdentifier: string, selectorItems: string[]) => void
}

export const PrimaryTerminationOptions = (props: IPrimaryTerminationOptionsProps) => {
  const { primaryTerminationReasons, openSelectorModal } = props

  return (
    <div style={styles.section}>
      <h1 style={styles.sectionHeader}>PRIMARY TERMINATION OPTIONS</h1>
      <TwoColumnSelector
        label="REASONS FOR TERMINATING"
        values={primaryTerminationReasons}
        onClick={() => openSelectorModal('primaryTerminationReasons', primaryTerminationReasons)}
      />
    </div>
  )
}

const styles = {
  section: {
    width: '100%',
    margin: '4% 0',
    paddingBottom: '20px',
  },
  sectionHeader: {
    marginTop: 0,
    fontSize: '1.15rem',
    fontWeight: 'normal',
    fontFamily: 'Roboto',
    color: ColorPalette.PRIMARY_TEXT,
    textAlign: 'left' as 'left',
    borderBottomWidth: 2,
    borderBottomStyle: 'solid' as 'solid',
    borderBottomColor: ColorPalette.PRIMARY_BLUE,
    padding: '0 .5em',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}
