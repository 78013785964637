import { ColorPalette } from '../../config'
import { ISectionConfigItem } from '../../interfaces'
import DataFlowNavigator from '../Navigation/DataFlowNavigator'
import DocumentTable, { DocTableColumnConfig, DocViewerConfig } from '../Tables/DocumentTable/DocumentTable'

type DocSelectionProps = {
  columnConfig: DocTableColumnConfig
  docTableConfig: DocViewerConfig
  sectionConfig: ISectionConfigItem[]
  selectedCategoryName: string
  selectedFolders: string[]
  onSectionClick: (section: string) => void
  onFolderSelection: (folder: string) => void
  onSelectAllDocs: (selectAllState: boolean) => void
  onToolbarClicked: (buttonid: string) => void
}

export const DocSelection = (props: DocSelectionProps) => {
  const {
    columnConfig,
    docTableConfig,
    sectionConfig,
    selectedCategoryName,
    selectedFolders,
    onSectionClick,
    onFolderSelection,
    onSelectAllDocs,
    onToolbarClicked,
  } = props
  return (
    <div style={styles.container} key="docSelectionComponent">
      <DataFlowNavigator
        buttonStyle={{ width: 260 }}
        tableWidth={10}
        sectionConfig={sectionConfig}
        currentSectionName={selectedCategoryName}
        onSectionClick={onSectionClick}
      />
      <DocumentTable
        style={{ borderTop: `4px solid ${ColorPalette.LIGHT_GREY}` }}
        scrollContainerStyle={{ height: window.innerHeight * 0.68 }}
        tableWidth={10}
        columnConfig={columnConfig}
        data={docTableConfig[selectedCategoryName] || {}}
        selectedFolders={selectedFolders}
        folderSelectionEnabled={true}
        folderSelectionHandler={onFolderSelection}
        onSelectAll={onSelectAllDocs}
        onToolbarClicked={onToolbarClicked}
        onDocClicked={(docname: string) => ({})}
        expandedFolders={[]}
        expandCollapseFolder={() => ({})}
        isSelectionEnabled={true}
        hideMetaData={true}
        emptyFolderIconColor={ColorPalette.DARK_GREY}
      />
    </div>
  )
}

const styles = {
  container: { display: 'flex', flexDirection: 'column' as 'column', justifyContent: 'center' },
}
