import Radium from 'radium'
import { mdiChartBoxPlusOutline } from '@mdi/js'
import Icon from '@mdi/react'

import { ColorPalette } from '../../../config'
import ButtonBlue from '../../BaseComponents/Buttons/ButtonBlue'

type NewGraphButtonProps = {
  onClick: () => void
}

const NewGraphButton = (props: NewGraphButtonProps) => (
  <ButtonBlue style={styles.graphAddItem} onClick={props.onClick}>
    {/* <Icon path={mdiChartBoxPlusOutline} color={ColorPalette.BUTTON_WHITE} style={styles.graphItemIcon} /> */}
    <span style={styles.graphItemText}>{`+ \u00A0 Add`}</span>
  </ButtonBlue>
)

export default Radium(NewGraphButton)

const styles = {
  graphAddItem: {
    width: 120,
  },
  graphItemText: {
    fontWeight: 550,
  },
  graphItemIcon: {
    width: 16,
    marginRight: '0.75em',
  },
}
