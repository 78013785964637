import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Icon from '@mdi/react'
import { mdiCheckCircle, mdiLoading } from '@mdi/js'

import DataTable from '../../components/Tables/DataTable/DataTable'
import { CommonTs } from '../../models/common-ts/commonts'
import { PeopleFlowCombinedReducer } from '../../store'
import { ColorPalette } from '../../config/colors'
import { SessionService, ImportExportService } from '../../services'
import { persistCurrentProfileData } from '../../utils/idbProfiles'
import { ActionType } from '../../store/actions/actions'
import { Profile } from '../../models'
import { ProfileRepository } from '../../repositories'
import { fetchProfileData } from '../../provider/remoteData'

type TableRowData = {
  id?: string
  idPassport: string
  firstName: string
  surname: string
  intendedChange: string
  terminationDate: string
  rightComponent?: React.ReactNode
}

type ProcessTerminationsProps = {
  terminationChangesToApply: TableRowData[] | CommonTs.Import.ProfileChange[]
  onChangesApplied: () => void
}

const importExportService = new ImportExportService()

const columnConfig = [
  { id: 'idPassport', label: 'ID / Passport', sizeFactor: 1 },
  { id: 'firstName', label: 'Name', sizeFactor: 1 },
  { id: 'surname', label: 'Surname', sizeFactor: 1 },
  { id: 'intendedChange', label: 'Intended Change', sizeFactor: 1 },
  { id: 'terminationDate', label: 'Date', sizeFactor: 1 },
]

const ProcessTerminations = (props: ProcessTerminationsProps) => {
  // const [applyResults, setApplyResults] = useState<any[]>([])
  const [terminationsProcessed, setTerminationsProcessed] = useState<string[]>([])

  const userIdPassport = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.idPassport)
  const password = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.password)
  const selectedAssociation = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedAssociation,
  )

  useEffect(() => {
    executeTerminations()
  }, [])

  const profileRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.profileRepo,
  ) as ProfileRepository
  const dispatch = useDispatch()

  const executeTerminations = async () => {
    await Promise.all(
      props.terminationChangesToApply.map(async (change) => {
        const result = await importExportService.applyProfileChange(
          selectedAssociation,
          change as CommonTs.Import.ProfileChange,
          { username: userIdPassport, password },
        )
        setTerminationsProcessed((terminationsProcessed) => [
          ...terminationsProcessed,
          (change as CommonTs.Import.ProfileChange).idPassport,
        ])
        return result
      }),
    )
    // at this stage, fetch terminated profiles again to update cache
    await fetchAndUpdateProfiles(
      selectedAssociation,
      props.terminationChangesToApply.map((change) => change.id || ''),
    )
    // setApplyResults(results)
    props.onChangesApplied()
  }

  const fetchAndUpdateProfiles = (selectedAssociation: string, idPassportsToFetch: string[]): Promise<void> => {
    return SessionService.prepareAuthTokens(userIdPassport, password)
      .then((token: string) => fetchProfileData(selectedAssociation, idPassportsToFetch, token))
      .catch((err) => {
        console.error(err)
        return {}
      })
      .then((newProfiles: Record<string, Profile>) => {
        const updatedProfiles = profileRepo.updateProfiles(newProfiles)
        // setAllProfiles(updatedProfiles)
        dispatch({ profileRepo: updatedProfiles, type: ActionType.UPDATE_STATE })
        persistCurrentProfileData(newProfiles, selectedAssociation)
      })
  }

  // const tableData = props.terminationChangesToApply.map((change, i) => {
  //   const processed = terminationsProcessed.includes((change as CommonTs.Import.ProfileChange).idPassport)
  //   ;(change as TableRowData).rightComponent = (
  //     <div
  //       style={{
  //         backgroundColor: ColorPalette.PRIMARY_BLUE,
  //         display: 'flex',
  //         alignItems: 'center',
  //         justifyContent: 'center',
  //         width: 40,
  //         height: '100%',
  //       }}
  //       key={`rightComponent_${(change as TableRowData).id}`}>
  //       <Icon
  //         path={processed ? mdiCheckCircle : mdiLoading}
  //         size={0.75}
  //         color={ColorPalette.OFF_WHITE}
  //         spin={!processed}
  //       />
  //     </div>
  //   )
  // })

  return (
    <DataTable
      tableData={props.terminationChangesToApply}
      columnConfig={columnConfig}
      tableWidth={10}
      filterState={undefined}
      onRowClick={() => null}
      authorisedItemIds={[]}
      customFilterConfig={[]}
      filterModeEnabled={false}
      isSelectionEnabled={false}
      selectedRowItemId=""
      customComponentConfig={{
        rightComponentWidth: 40,
      }}
      applyCustomRowStyle={(rowId: string) => ({
        backgroundColor: ColorPalette.PRIMARY_BLUE_TRANSPARENT,
      })}
      disabled={true}
    />
  )
}

export default ProcessTerminations
