import { CommonTs } from '../../models/common-ts/commonts'
import { ImportRecordQuery } from '../../types/dataExport'
import { httpPost } from '../remoteData'

export async function exportProfileDataTemplate(payload: any, token: string) {
  const { data } = await httpPost('people_flow_import_export', '/data-export/profile-data-template', payload, token)
  if (data.result !== 'success') {
    throw { code: 'UnknownExportError' }
  }
  return data
}

export async function submitProfileImportPlan(
  association: string,
  importFileName: string,
  bulkActionConfig: CommonTs.Import.IPlanConfig,
  token: string,
  inverseSetQuery?: ImportRecordQuery,
) {
  const inputPathPrefix = `s3://peopleflow-service-prod-general-storage/public/Companies/${association}/Imports`
  const payload: any = {
    selectedEmployer: association,
    inputPathPrefix,
    importFileName,
    bulkActionConfig,
    inverseSetQuery,
  }
  const { data } = await httpPost('people_flow_import_export', '/data-import/plan-profile-import', payload, token)
  if (data.result !== 'success') {
    throw { code: 'UnnownError' }
  }
  return data
}
