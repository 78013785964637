import { ColorPalette } from '../../../config/colors'
import React from 'react'

import Icon from '@mdi/react'
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js'
import ButtonRound from '../../BaseComponents/Buttons/ButtonRound'

interface SimplePaginationProps {
  isPrevious: boolean
  previousLabel: string
  nextLabel: string
  isNext: boolean
  previousButtonDisabled?: boolean
  nextButtonDisabled?: boolean
  loadPrevious: () => void
  loadNext: () => void
  buttonStyle?: React.CSSProperties | undefined
  style?: React.CSSProperties | undefined
}

const simplePagination: React.FC<SimplePaginationProps> = (props: SimplePaginationProps) => {
  let previousButton: JSX.Element | null = (
    <div style={styles.buttonContainer}>
      <ButtonRound
        style={{ ...styles.buttonStyle, ...props.buttonStyle }}
        diameter={50}
        icon={
          <Icon
            size={1}
            path={mdiChevronLeft}
            color={props.isPrevious ? ColorPalette.PRIMARY_TEXT : ColorPalette.DISABLED_TEXT}
          />
        }
        onClick={props.loadPrevious}
        disabled={!props.isPrevious}
      />
      <p
        style={{
          ...styles.textStyle,
          color: props.isPrevious ? ColorPalette.SECONDARY_TEXT : ColorPalette.DISABLED_TEXT,
        }}>
        {props.previousLabel}
      </p>
    </div>
  )
  if (props.previousButtonDisabled) {
    previousButton = null
  }

  let nextButton: JSX.Element | null = (
    <div style={styles.buttonContainer}>
      <p
        style={{
          ...styles.textStyle,
          color: props.isNext ? ColorPalette.SECONDARY_TEXT : ColorPalette.DISABLED_TEXT,
        }}>
        {props.nextLabel}
      </p>
      <ButtonRound
        style={{ ...styles.buttonStyle, ...props.buttonStyle }}
        diameter={50}
        icon={
          <Icon
            size={1}
            path={mdiChevronRight}
            color={props.isNext ? ColorPalette.PRIMARY_TEXT : ColorPalette.DISABLED_TEXT}
          />
        }
        onClick={props.loadNext}
        disabled={!props.isNext}
      />
    </div>
  )
  if (props.nextButtonDisabled) {
    nextButton = null
  }

  return (
    <div style={{ ...styles.container, ...props.style }}>
      {previousButton}
      <div style={{ width: 30 }} />
      {nextButton}
    </div>
  )
}

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'centre',
    justifyContent: 'flex-end',
    paddingRight: 70,
    height: 50,
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'centre',
  },
  buttonStyle: {
    boxShadow: 'none',
    backgroundColor: ColorPalette.CARD_WHITE,
  },
  textStyle: {
    fontSize: '0.9rem',
    textTransform: 'uppercase' as 'uppercase',
    fontWeight: 'normal',
  },
}

export default simplePagination
