export interface IDisciplineHistoryItem {
    getUid(): string;
    getRawData(): Record<string, any>;
    getDocs(): string[];
    getPhotos(): string[];
    getRawDataField: (fieldName: string) => any;
}

// interface DisciplineRawData {
//     action: string;
//     company: string;
//     description: string;
//     employeeResponse: string;
//     offence: string;
//     userName: string;
//     userSurname: string;
//     witnessName: string;
//     date: number;
//     expiry: number;
// }

class DisciplineHistoryItem implements IDisciplineHistoryItem {
    uid: string;
    rawData: Record<string, any>;
    docs: string[];
    photos: string[];

    constructor(originalData: Record<string, any>) {
        if (!originalData) {
            originalData = {};
        }
        this.uid = originalData.uid || "";
        this.rawData = {
            action: originalData.rawData?.action ? originalData.rawData.action : "",
            company: originalData.rawData?.company ? originalData.rawData.company : "",
            description: originalData.rawData?.description ? originalData.rawData.description : "",
            employeeResponse: originalData.rawData?.employeeResponse ? originalData.rawData.employeeResponse : "",
            offence: originalData.rawData?.offence ? originalData.rawData.offence : "",
            userName: originalData.rawData?.userName ? originalData.rawData.userName : "",
            userSurname: originalData.rawData?.userSurname ? originalData.rawData.userSurname : "",
            witnessName: originalData.rawData?.action ? originalData.rawData.action : "",
            date: originalData.rawData?.action ? originalData.rawData.action : 0,
            expiry: originalData.rawData?.action ? originalData.rawData.action : 0,
        };
        this.photos = originalData.photos || [];
        this.docs = originalData.docs || [];
    }

    getUid(): string {
        return this.uid;
    }

    getRawData(): Record<string, any> {
        return { ...this.rawData };
    }

    getDocs(): string[] {
        return [...this.docs] || [];
    }

    getPhotos(): string[] {
        return [...this.photos];
    }

    getRawDataField(fieldName: string): any {
        const value = this.getRawData()[fieldName];
        if (value === undefined) {
            return "";
        }
        return value;
    }
}

export class DisciplinaryItemFactory {
    static create(originalData: Record<string, any>): DisciplineHistoryItem {
        return new DisciplineHistoryItem(originalData);
    }
}
