import { ColorPalette } from '../../config/colors'
import { toLowerCaseCustom, checkEmailFormat, titleCase, toUpperCaseCustom } from '../../utils'
import { ActionType } from '../../store/actions/actions'
import { ClientRequestStepTypes, IClientRequestInstance, IClientRequests, RequestStatuses } from '../../models'
import { PeopleFlowCombinedReducer } from '../../store'
import { RequestServiceFactory } from '../../services'

import React, { Component } from 'react'
import Radium from 'radium'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'

import Grid, { GridSize } from '@material-ui/core/Grid'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import { ScrollView } from '@cantonjs/react-scroll-view'
import Icon from '@mdi/react'
import { mdiCheckboxMarkedCircle, mdiLock, mdiCheckboxBlankCircleOutline } from '@mdi/js'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import ButtonBlue from '../../components/BaseComponents/Buttons/ButtonBlue'
import NavigationBar from '../../components/Navigation/NavigationBar'
import SectionHeaderPrimary from '../../components/Headings/SectionHeaderPrimary'
import SectionHeaderSecondary from '../../components/Headings/SectionHeaderSecondary'
import SectionHeaderTertiary from '../../components/Headings/SectionHeaderTertiary'
import LoadingModal from '../../components/Modals/LoadingModal'
import AlertModalOneButton from '../../components/Modals/AlertModalOneButton'
import AlertModalTwoButton from '../../components/Modals/AlertModalTwoButton'
import SideMenu from '../../components/Navigation/SideMenu'
import RequestManagerSideMenu from '../../components/SideMenus/RequestManagerSideMenu'
import ButtonGeneric from '../../components/BaseComponents/Buttons/ButtonGeneric'
import FileSelector from '../../components/GeneralUI/FileSelector/FileSelector'
import AuthorisationList from '../../components/GeneralUI/AuthorisationList/AuthorisationList'
import InfoCollector from '../../components/GeneralUI/InfoCollector/InfoCollector'
import DocumentViewerModal from '../../components/Modals/DocumentViewerModal'
import InfoCollectorModal from '../../components/Modals/InfoCollector'
import { AssociationSettingsRepository, UserRepository } from '../../repositories'
import { getRemoteFile } from '../../provider/remoteData'

dayjs.extend(relativeTime)

interface RequestManagerProps extends RouteComponentProps {
  allRequests: IClientRequests
  currentScreen: string
  selectedAssociation: string
  selectedRequest: IClientRequestInstance
  associationRepo: AssociationSettingsRepository
  userRepo: UserRepository
  updateState: (obj: any) => void
}

interface RequestManagerState {
  loadingModalOpen: boolean
  warningModalOpen: boolean
  authModalOpen: boolean
  declineModalOpen: boolean
  withdrawAuthModalOpen: boolean
  sideMenuVisible: boolean
  fileViewerModalOpen: boolean
  emailCollectorModalOpen: boolean
  creationUnix: number
  permittedRole: string
  choiceModalHeader: string
  choiceModalMessage: string
  createdBy: string
  loadingModalMessage: string
  warningModalHeader: string
  warningModalMessage: string
  selectedRequestName: string
  selectedRequestStatus: string
  selectedStepLabel: string
  selectedFileUrl: string
  selectedAuthItem: Record<string, any>
  currentAuths: Record<string, any>[]
  requestStepOrder: ClientRequestStepTypes[]
  selectedStepName: ClientRequestStepTypes | undefined
  selectedRequestState: Record<string, any>

  tableWidth: GridSize
}

class RequestManager extends Component<RequestManagerProps, RequestManagerState> {
  private sectionHeaderRef: React.RefObject<any>
  private fileSelectorRef: React.RefObject<any>
  private emailCollectorRef: React.RefObject<any>

  constructor(props: RequestManagerProps) {
    super(props)
    this.sectionHeaderRef = React.createRef()
    this.fileSelectorRef = React.createRef()
    this.emailCollectorRef = React.createRef()
  }

  RequestService = RequestServiceFactory.create()

  initialModalState = {
    loadingModalOpen: false,
    warningModalOpen: false,
    authModalOpen: false,
    declineModalOpen: false,
    withdrawAuthModalOpen: false,
    fileViewerModalOpen: false,
    emailCollectorModalOpen: false,
  }
  state: RequestManagerState = {
    ...this.initialModalState,
    warningModalHeader: '',
    warningModalMessage: '',
    loadingModalMessage: '',
    choiceModalHeader: '',
    choiceModalMessage: '',
    selectedRequestName: '',
    selectedRequestStatus: '',
    permittedRole: '',
    selectedFileUrl: '',
    selectedAuthItem: {},
    currentAuths: [],
    selectedStepName: undefined,
    requestStepOrder: [],
    selectedStepLabel: '',
    selectedRequestState: {},
    creationUnix: 0,
    createdBy: '',
    sideMenuVisible: true,
    tableWidth: 9,
  }

  componentDidMount() {
    this.reloadUI()
  }

  closeModals = () => {
    this.setState({ ...this.initialModalState }, this.forceUpdate)
  }

  promptChoiceHandler = () => {
    const { authModalOpen, declineModalOpen, withdrawAuthModalOpen, permittedRole, currentAuths, selectedAuthItem } =
      this.state
    if (authModalOpen || declineModalOpen) {
      this.authorisationHandler(permittedRole, currentAuths, true, authModalOpen ? 'authed' : 'declined')
    } else if (withdrawAuthModalOpen) {
      this.withdrawAuthorisation(selectedAuthItem, true)
    }
  }

  searchHandler = (text: string) => {}

  reloadUI = () => {
    const { selectedRequest } = this.props
    const selectedRequestName = selectedRequest.getRequestName()
    const selectedRequestStatus = selectedRequest.getRequestStatus()
    const selectedRequestState = selectedRequest.getSteps()
    const createdBy = selectedRequest.getRequestCreator()
    const creationUnix = selectedRequest.getCreationTime()
    const selectedStepName = selectedRequest.getCurrentStepName()
    const requestStepOrder = selectedRequest.getOrderedStepNameArray()

    this.setState({
      requestStepOrder,
      selectedRequestName,
      selectedRequestStatus,
      createdBy,
      creationUnix,
      selectedRequestState,
      selectedStepName,
      sideMenuVisible: true,
    })
  }

  onStepClicked = (selectedStepName: ClientRequestStepTypes) => {
    this.setState({ selectedStepName })
  }

  goBack = () => {
    // this.closeModals();
    window.scrollTo(0, 0)
    setTimeout(() => this.props.history.replace('openrequests'), 30)
  }

  submitForms = async () => {
    if (this.fileSelectorRef.current) {
      const files = this.fileSelectorRef.current.getAllFiles()
      if (!files.length) {
        this.setState({
          ...this.initialModalState,
          warningModalOpen: true,
          warningModalHeader: 'Add a form',
          warningModalMessage: 'You need to add a form to continue',
        })
        return
      }
      this.setState({
        ...this.initialModalState,
        loadingModalMessage: 'Uploading forms',
        loadingModalOpen: true,
      })
      const { selectedAssociation, selectedRequest, userRepo, allRequests } = this.props
      const { selectedRequestName } = this.state
      const currentStepName = selectedRequest.getCurrentStepName()
      const user = userRepo.getCurrentUserEntity()
      const userInfo = user.getPersonalUserInfo()
      if (currentStepName === undefined) {
        throw { code: 'SaveFormsError' }
      }
      const fileData = await this.RequestService.uploadForms(selectedAssociation, files, selectedRequestName)
      selectedRequest.addFormsToCurrentStep(fileData, userInfo)
      allRequests.updateRequestInstance(selectedRequest)
      this.closeModals()
      this.reloadUI()
    }
  }

  submitEmailAssignment = (emails: string[]) => {
    const { selectedRequest, allRequests } = this.props
    selectedRequest.addEmailsToCurrentStep(emails)
    allRequests.updateRequestInstance(selectedRequest)
    this.closeModals()
    this.reloadUI()
  }

  viewPdf = async (remoteFilePath: string) => {
    const url = await getRemoteFile(remoteFilePath)
    this.setState({
      selectedFileUrl: url,
      fileViewerModalOpen: true,
    })
  }

  exportFiles = (files: Record<string, any>[]) => {
    this.setState({ emailCollectorModalOpen: true })
  }

  proceedPastAuth = () => {
    const { selectedRequest, allRequests } = this.props
    const requiredAuths = selectedRequest.getRequiredAuthQty()
    let { auths } = selectedRequest.getStepState(ClientRequestStepTypes.AUTH)
    auths = [...auths].filter((authItem) => authItem.choice === 'authed')
    const authsOutstanding = requiredAuths - auths.length
    if (authsOutstanding) {
      this.setState({
        ...this.initialModalState,
        warningModalOpen: true,
        warningModalHeader: 'Incomplete',
        warningModalMessage: `${authsOutstanding} ${
          authsOutstanding === 1 ? 'authorisation' : 'authorisations'
        } outstanding.`,
      })
      return
    }
    selectedRequest.loadNextStep()
    allRequests.updateRequestInstance(selectedRequest)
    this.reloadUI()
  }

  authorisationHandler = (
    permittedRole: string,
    currentAuths: Record<string, any>[],
    alreadyConfirmed?: boolean | undefined,
    authType?: string | undefined,
  ) => {
    const { associationRepo, userRepo, selectedRequest, selectedAssociation } = this.props
    const hasRole = associationRepo.getUserRoles(selectedAssociation).includes(permittedRole)

    if (!hasRole) {
      this.setState({
        ...this.initialModalState,
        warningModalOpen: true,
        warningModalHeader: 'Blocked',
        warningModalMessage: "You don't have the role/permission necessary to take action here.",
      })
      return
    }
    let alreadyResponded = false
    const user = userRepo.getCurrentUserEntity()
    currentAuths.forEach((authItem) =>
      authItem.idPassport === user.getUsername() && authItem.choice === authType ? (alreadyResponded = true) : null,
    )

    const requiredAuths = selectedRequest.getRequiredAuthQty()
    const { auths } = selectedRequest.getStepState(ClientRequestStepTypes.AUTH)

    if (requiredAuths - auths.length === 0) {
      this.setState({
        ...this.initialModalState,
        warningModalOpen: true,
        warningModalHeader: 'All response slots taken',
        warningModalMessage: `${requiredAuths} response slots are available and all have been occupeied. If you wish to proceed, then anyone who has declined authorisation must withdraw their submission.`,
      })
      return
    }

    if (alreadyResponded) {
      this.setState({
        ...this.initialModalState,
        warningModalOpen: true,
        warningModalHeader: 'Already Resonded',
        warningModalMessage: `You have already ${
          authType === 'authed' ? 'authorised' : 'declined'
        }. If you wish to withdraw your decision, then click on your name in the list and follow the prompts.`,
      })
      return
    }

    if (!alreadyConfirmed) {
      this.setState({
        permittedRole,
        currentAuths,
        declineModalOpen: authType !== 'authed',
        authModalOpen: authType === 'authed',
        choiceModalHeader: authType === 'authed' ? 'Authorise' : 'Decline',
        choiceModalMessage: 'Submit your choice?',
      })
      return
    }
    this.setState({
      ...this.initialModalState,
      loadingModalOpen: true,
      loadingModalMessage: `${authType === 'authed' ? 'Authorising...' : 'Declining...'}`,
    })

    const userInfo = user.getPersonalUserInfo()
    if (authType === 'authed') {
      selectedRequest.authorise(userInfo)
    } else {
      selectedRequest.declineAuth(userInfo)
    }
    setTimeout(() => {
      this.props.updateState({ selectedRequest })
      this.closeModals()
      this.reloadUI()
    }, 1000)
  }

  withdrawAuthorisation = (selectedAuthItem: Record<string, any>, alreadyConfirmed?: boolean | undefined) => {
    const { userRepo, selectedRequest } = this.props
    const user = userRepo.getCurrentUserEntity()
    if (selectedAuthItem.idPassport !== user.getUsername()) {
      this.setState({
        ...this.initialModalState,
        warningModalOpen: true,
        warningModalHeader: 'Whoops',
        warningModalMessage:
          "Looks like you clicked on the wrong user's name. To withdraw your choice, you should click on your own name (assuming you have already provided responded).",
      })
      return
    }
    if (!alreadyConfirmed) {
      this.setState({
        selectedAuthItem,
        withdrawAuthModalOpen: true,
        choiceModalHeader: 'Confirm withdrawal',
        choiceModalMessage: 'Are you sure you would like to withdraw your decision?',
      })
      return
    }

    this.setState({
      ...this.initialModalState,
      loadingModalOpen: true,
      loadingModalMessage: 'Withdrawing decision...',
    })

    const userInfo = user.getPersonalUserInfo()
    selectedRequest.withdrawAuthorisation(userInfo)

    setTimeout(() => {
      this.props.updateState({ selectedRequest })
      this.closeModals()
      this.reloadUI()
    }, 1000)

    // Unauth
  }

  generateWorkAreaContents(stepName: ClientRequestStepTypes | undefined): JSX.Element | null {
    // TODO: Move into its own component
    const stepState = this.props.selectedRequest.getStepState(stepName)
    const stepConfig = this.props.selectedRequest.getStepConfig(stepName)

    let disabledOverlay = null
    if (!stepState.active) {
      disabledOverlay = <div style={{ ...getStyle('disabledOverlay') }} />
    }
    switch (stepName) {
      case ClientRequestStepTypes.FORM_UPLOAD:
        let fileSelector = null
        if (!stepState.completed) {
          fileSelector = (
            <>
              <FileSelector
                ref={this.fileSelectorRef}
                style={{ marginTop: 20, width: '81%' }}
                defaultMode={'select'}
                maxFiles={stepConfig.requiredForms}
                toggleEnabled={false}
                addFilesEnabled={true}
                deletionEnabled={true}
                downloadEnabled={false}
                exportEnabled={false}
                initialFiles={[]}
              />
              <ButtonBlue style={styles.nextButton} onClick={this.submitForms}>
                Next
              </ButtonBlue>
            </>
          )
        }
        return (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <p style={styles.promptText}>
              {stepState.completed ? 'File upload complete.' : 'Add labour requistion form'}
            </p>
            {fileSelector}
            {disabledOverlay}
          </div>
        )

      case ClientRequestStepTypes.AUTH:
        const authsComplete = stepState.auths.length >= stepConfig.requiredAuths
        const outstandingAuths = stepConfig.requiredAuths - stepState.auths.length

        let authNextButton = null
        if (!stepState.completed && stepState.active) {
          authNextButton = (
            <ButtonBlue style={styles.nextButton} onClick={this.proceedPastAuth}>
              Next
            </ButtonBlue>
          )
        }
        return (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <p style={styles.promptText}>
              {authsComplete
                ? `All response slots have are occupied.`
                : `This requires authorisation by ${stepConfig.requiredAuths} ${
                    stepConfig.requiredAuths === 1 ? 'user' : 'users'
                  } with the ${stepConfig.permittedRole} role.\n ${outstandingAuths} response ${
                    outstandingAuths === 1 ? 'slot remains' : 'slots remain'
                  } . `}
            </p>
            <AuthorisationList
              authsRequired={this.props.selectedRequest.getRequiredAuthQty()}
              authRole={stepConfig.permittedRole}
              authListData={stepState.auths}
              onAuthoriseClicked={() =>
                this.authorisationHandler(stepConfig.permittedRole, stepState.auths, false, 'authed')
              }
              onDeclineClicked={() =>
                this.authorisationHandler(stepConfig.permittedRole, stepState.auths, false, 'declined')
              }
              onUnAuthoriseClicked={(selectedAuthItem) => this.withdrawAuthorisation(selectedAuthItem)}
            />
            {authNextButton}
            {disabledOverlay}
          </div>
        )

      case ClientRequestStepTypes.EMAIL:
        let emailNextButton = null
        if (!stepState.completed && stepState.active) {
          emailNextButton = (
            <ButtonBlue style={styles.nextButton} onClick={() => this.emailCollectorRef.current.submit()}>
              Send
            </ButtonBlue>
          )
        }

        return (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <p style={styles.promptText}>Who should this be assigned to?</p>
            <InfoCollector
              ref={this.emailCollectorRef}
              containerStyle={{ width: '86%', marginTop: 20 }}
              defaultItems={[...new Set([...stepState.defaultEmails, ...stepState.sentTo])]}
              placeholder={'Enter email address'}
              minimumItems={1}
              warningMessage={'Add at least one email address'}
              hideActionButtons={true}
              validateInput={checkEmailFormat}
              transformInput={toLowerCaseCustom}
              onSuccess={this.submitEmailAssignment}
            />
            {emailNextButton}
            {disabledOverlay}
          </div>
        )
      default:
        return null
    }
  }

  generateContextAreaContent = (): JSX.Element[] => {
    // TODO: Move into its own component
    const { requestStepOrder } = this.state
    const { selectedRequest } = this.props
    const logData = selectedRequest.getLogs()

    let components: JSX.Element[] = [<SectionHeaderSecondary containerStyle={{ width: '86%' }} header={'ALL FILES'} />]
    requestStepOrder.forEach((stepName) => {
      const stepState = this.props.selectedRequest.getStepState(stepName)
      const stepLabel = this.props.selectedRequest.getStepLabel(stepName)

      if (stepState.completed && stepState.forms) {
        components.push(
          <>
            <SectionHeaderTertiary
              containerStyle={{
                marginTop: 25,
                height: 35,
                width: '80%',
                borderTopRightRadius: 10,
                borderTopLeftRadius: 10,
              }}
              textStyle={{ paddingLeft: 20, fontWeight: '550' }}
              header={stepLabel}
            />
            <FileSelector
              style={{ marginTop: 2, width: '80.4%' }}
              controlBarContainerStyle={{ borderRadius: 0 }}
              defaultMode={'view'}
              toggleEnabled={true}
              addFilesEnabled={false}
              deletionEnabled={false}
              downloadEnabled={true}
              exportEnabled={true}
              initialFiles={stepState.forms}
              viewPdf={(url) => this.viewPdf(url)}
              exportFiles={(files: Record<string, any>[]) => this.exportFiles(files)}
            />
          </>,
        )
      }
    })
    if (components.length < 2) {
      components.push(<p style={{ ...styles.promptText, marginTop: 30 }}>{'No files yet'}</p>)
    }
    let logs = [<p style={{ ...styles.promptText, marginTop: 30 }}>{'No logs yet'}</p>]
    if (logData.length) {
      logs = logData.map((logItem: any) => {
        const parsedDate = `${dayjs.unix(parseInt(logItem.unixMs) / 1000).format('HH:mm, YYYY-MM-DD')}`
        return (
          <div style={getStyle('logContainer')}>
            <p style={{ ...styles.defaultFont, fontWeight: 'bold' }}> {`${parsedDate}`}</p>
            <p style={{ ...styles.defaultFont, marginTop: 5, paddingLeft: 5, fontStyle: 'italic' }}>
              {`${logItem.message}`}{' '}
            </p>
          </div>
        )
      })
    }
    components.push(
      <>
        <SectionHeaderSecondary containerStyle={{ width: '86%', marginTop: 50 }} header={'LOGS'} />
        {logs}
        <div style={{ height: 50 }} />
      </>,
    )
    return components
  }

  displayWarning(error: any) {
    let warning = ''
    try {
      if (error.code === 'NetworkError') {
        warning = 'Seems like your internet connection is down. Reconnect to the network, then try again.'
      } else if ('message' in error) {
        warning =
          "The following error message was returned when logging in:\n\n'" +
          error.message +
          "'. \n\nRefresh the page and try again. If unsuccessful, then contact tech support"
      } else {
        warning = 'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
      }
    } catch (error) {
      warning = 'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
    }

    this.setState({
      ...this.initialModalState,
      warningModalOpen: true,
      warningModalHeader: 'Warning',
      warningModalMessage: warning,
    })
  }

  render() {
    const { selectedRequest } = this.props
    const {
      selectedStepName,
      warningModalOpen,
      warningModalHeader,
      warningModalMessage,
      authModalOpen,
      declineModalOpen,
      emailCollectorModalOpen,
      withdrawAuthModalOpen,
      choiceModalHeader,
      choiceModalMessage,
      loadingModalOpen,
      loadingModalMessage,
    } = this.state
    const { active, completed } = selectedRequest.getStepState(selectedStepName)
    const headerIconPath = completed ? mdiCheckboxMarkedCircle : active ? mdiCheckboxBlankCircleOutline : mdiLock
    const headerIcon = (
      <Icon path={headerIconPath} color={ColorPalette.PRIMARY_BLUE} style={{ ...styles.headerIconStyle }} />
    )
    const workAreaContents = this.generateWorkAreaContents(selectedStepName)
    const contextAreaContents = this.generateContextAreaContent()

    let documentViewerModal = null
    if (this.state.fileViewerModalOpen) {
      documentViewerModal = (
        <DocumentViewerModal
          document={{ url: this.state.selectedFileUrl }}
          onClick1={() => this.closeModals()}
          buttonLabel1={'Close'}
        />
      )
    }

    return (
      <div style={getStyle('container')}>
        <NavigationBar match={this.props.match} location={this.props.location} history={this.props.history} />

        <SectionHeaderPrimary
          ref={this.sectionHeaderRef}
          style={{ marginTop: 25 }}
          disabled={true}
          searchString={''}
          onClick={() => ({})}>
          {`${titleCase(this.state.selectedRequestStatus)} Request`}
        </SectionHeaderPrimary>

        <SideMenu
          visible={this.state.sideMenuVisible}
          menuComponents={
            <RequestManagerSideMenu
              selectedRequestName={this.state.selectedRequestName}
              createdBy={this.state.createdBy}
              creationUnix={this.state.creationUnix}
              selectedStepName={this.state.selectedStepName}
              requestStepOrder={this.state.requestStepOrder}
              requestConfig={this.props.selectedRequest.getRequestConfig()}
              currentStepName={selectedRequest.getCurrentStepName()}
              completedStepNames={selectedRequest.getCompletedStepNames()}
              onStepClicked={(stepName) => this.onStepClicked(stepName)}
              isRequestClosed={this.props.selectedRequest.getRequestStatus() === RequestStatuses.CLOSED}
            />
          }
        />

        <div
          style={{
            width: '100%',
            alignSelf: 'center',
            marginLeft: window.innerWidth * 0.46,
          }}>
          <ButtonGeneric
            style={{
              ...getStyle('backButton'),
              width: (window.innerWidth * (this.state.tableWidth as number)) / 12,
            }}
            iconBefore={<ChevronLeftIcon style={getStyle('buttonIconStyle')} />}
            label={'BACK'}
            onClick={this.goBack}
          />
          <Grid
            style={styles.outerCard}
            item
            container
            direction="row"
            justify="center"
            alignItems="center"
            xs={this.state.tableWidth}>
            <div
              style={{
                ...styles.workAreaContainer,
                backgroundColor: ColorPalette.CARD_WHITE,
                boxShadow: '0px 2px 6px rgba(0, 0, 30, 0.15)',
              }}>
              <SectionHeaderSecondary
                containerStyle={{ width: '86%' }}
                header={toUpperCaseCustom(selectedRequest.getStepLabel(selectedStepName))}
                icon={headerIcon}
              />
              <ScrollView style={styles.scrollStyle} contentContainerStyle={styles.scrollContainerPreview}>
                {workAreaContents}
              </ScrollView>
            </div>

            <div style={{ ...styles.workAreaContainer }}>
              <ScrollView style={styles.scrollStyle} contentContainerStyle={styles.scrollContainerPreview}>
                {contextAreaContents}
              </ScrollView>
            </div>
          </Grid>
        </div>

        {documentViewerModal}

        <InfoCollectorModal
          open={emailCollectorModalOpen}
          defaultItems={[]}
          header={'EMAIL TO'}
          subHeader={'Which email addresses should we send to?'}
          warningMessage={'Add at least one email address'}
          validateInput={checkEmailFormat}
          transformInput={toLowerCaseCustom}
          placeholder={'Email address'}
          minimumItems={1}
          dismiss={() => this.closeModals()}
          onSuccess={(addresses) =>
            this.setState({
              ...this.initialModalState,
              warningModalOpen: true,
              warningModalHeader: 'Coming soon',
              warningModalMessage: 'The ability to export files will be available in the near future.',
            })
          }
          onReject={() => this.closeModals()}
        />

        <AlertModalOneButton
          open={warningModalOpen}
          header={warningModalHeader}
          body={warningModalMessage}
          buttonLabel={'Ok'}
          onClick={this.closeModals}
        />
        <AlertModalTwoButton
          open={authModalOpen || declineModalOpen || withdrawAuthModalOpen}
          header={choiceModalHeader}
          body={choiceModalMessage}
          buttonLabel1={'No'}
          buttonLabel2={'Yes'}
          onClick1={this.closeModals}
          onClick2={this.promptChoiceHandler}
          dismiss={this.closeModals}
        />
        <LoadingModal open={loadingModalOpen}>{loadingModalMessage}</LoadingModal>
      </div>
    )
  }
}

const styles = {
  container: {
    minHeight: window.innerHeight,
    maxHeight: window.innerHeight,
    display: 'flex',
    flexDirection: 'column',
    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255, 1), rgba(209,210,230, 1))',
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  disabledOverlay: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    backgroundImage: 'linear-gradient(to top, rgba(220,220,240, 0.8), rgba(220,220,240, 0.3)',
  },
  outerCard: {
    zIndex: 1,
    alignSelf: 'center',
    alignItems: 'flex-start',
    backgroundColor: ColorPalette.BUTTON_HOVER_GREY,
    minHeight: window.innerHeight * 0.76,
    paddingBottom: 100,
    borderRadius: 10,
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    overflow: 'hidden',
  },
  headerIconStyle: {
    width: 18,
    marginBottom: 2,
    height: 'auto',
  },
  workAreaContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    width: '50%',
    height: '100%',
    paddingTop: 20,
  },
  scrollStyle: {
    width: '100%',
    height: window.innerHeight * 0.75,
  },
  scrollContainerPreview: {
    paddingBottom: 150,
    minHeight: window.innerHeight * 0.75,
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
  },
  dividerLine: {
    height: window.innerHeight * 0.7,
    marginTop: 20,
    marginBottom: 10,
    borderRight: `1px solid ${ColorPalette.VERY_LIGHT_GREY}`,
  },
  formPreviewContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '49.9%',
    height: '100%',
  },
  saveChangesButton: {
    marginTop: window.innerHeight * 0.045,
    fontWeight: '550',
    fontSize: '0.8rem',
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    ':hover': {
      color: ColorPalette.PRIMARY_BLUE,
    },
    ':active': {
      color: ColorPalette.DARK_GREY,
    },
  },
  backButton: {
    marginTop: window.innerHeight * 0.045,
    fontWeight: 'bolder' as 'bolder',
    fontSize: '0.8rem',
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    ':hover': {
      color: ColorPalette.PRIMARY_BLUE,
    },
    ':active': {
      color: ColorPalette.DARK_GREY,
    },
  },
  buttonIconStyle: {
    color: ColorPalette.PRIMARY_TEXT,
    width: '1.2rem',
    height: '1.2rem',
  },
  promptText: {
    width: '80%',
    marginTop: 25,
    marginBottom: 5,
    textAlign: 'center' as 'center',
    alignSelf: 'center' as 'center',
    fontFamily: 'roboto',
    fontSize: '0.85rem',
    color: ColorPalette.PRIMARY_TEXT,
  },
  logContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '80%',
    marginTop: 25,
  },
  defaultFont: {
    fontSize: 12,
    width: '90%',
    fontFamily: 'Roboto',
    color: ColorPalette.PRIMARY_TEXT,
    padding: 0,
    margin: 0,
  },
  nextButton: {
    marginTop: 40,
    width: '84%',
  },
}

function getStyle(
  styleName:
    | 'container'
    | 'disabledOverlay'
    | 'outerCard'
    | 'workAreaContainer'
    | 'scrollContainerPreview'
    | 'dividerLine'
    | 'formPreviewContainer'
    | 'saveChangesButton'
    | 'backButton'
    | 'buttonIconStyle'
    | 'logContainer',
): React.CSSProperties | undefined {
  const x = styles[styleName]
  if (!x) {
    return undefined
  }
  return x as React.CSSProperties
}

const mapStateToProps = (state: PeopleFlowCombinedReducer) => {
  return {
    idPassport: state.sessionManager.idPassport,
    password: state.sessionManager.password,
    associationRepo: state.sessionManager.associationRepo as AssociationSettingsRepository,
    userRepo: state.sessionManager.userRepo as UserRepository,
    allRequests: state.sessionManager.allRequests,
    selectedAssociation: state.sessionManager.selectedAssociation,
    currentScreen: state.sessionManager.currentScreen,
    selectedRequest: state.sessionManager.selectedRequest,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateState: (data: any) => dispatch({ type: ActionType.UPDATE_STATE, data }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Radium(RequestManager))
