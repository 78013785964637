import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from 'react-spinkit'

import DataTable from '../../Tables/DataTable/DataTable'
import { IColumnConfig } from '../../Tables/DataTable/DataTableHeader'
import { IClientProfileCache, PeopleFlowCombinedReducer } from '../../../store'
import { ActionType } from '../../../store/actions/actions'
import LoadingModal from '../../Modals/LoadingModal'
import { QueryResult, QueryResults } from '../../../types'
import { timestampToDateString } from '../../../utils'
import { ColorPalette } from '../../../config'

type DataQueryFilteredResultsProps = {
  queryResults: QueryResults
  fetchingResults?: boolean
  showLoader?: boolean
  loaderMessage?: string
}

export const DataQueryFilteredResults = (props: DataQueryFilteredResultsProps) => {
  const [tableData, setTableData] = useState<Record<string, string>[]>([])
  const [refreshingModalOpen, setRefreshingModalOpen] = useState<boolean>(false)
  const [refreshTimestamp, setRefreshTimestamp] = useState<number>(Date.now())

  const selectedAssociation = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedAssociation,
  )
  const selectedCohort = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.selectedCohort)
  // const allDataQueryProfiles = useSelector(
  //   (state: PeopleFlowCombinedReducer) => state.sessionManager.allDataQueryProfiles,
  // )

  const dispatch = useDispatch()
  const setAllDataQueryProfiles = (allDataQueryProfiles: IClientProfileCache) =>
    dispatch({ type: ActionType.SET_ALL_DQ_PROFILES, data: { allDataQueryProfiles } })

  useEffect(() => {
    if (!props.queryResults?.results) {
      return
    }
    setRefreshingModalOpen(true)
    refreshProfilesFromCache(props.queryResults.results)
  }, [])

  useEffect(() => {
    prepareTableData()
  }, [refreshTimestamp === 1])

  const refreshProfilesFromCache = async (results: QueryResult[]) => {
    // const profilePks = results.map((result) => result.profilePk)
    // await refreshProfilesFromIndexedDb(
    //   undefined,
    //   selectedAssociation,
    //   selectedCohort,
    //   setAllDataQueryProfiles,
    //   profilePks,
    // )
    setRefreshingModalOpen(false)
    setRefreshTimestamp(1)
  }

  const generateRowData = (result: QueryResult) => {
    // const profile = allDataQueryProfiles.getProfile(selectedAssociation, result.profilePk)
    // if (profile) {
    //   const { name, surname, idPassport } = profile?.getPersonaInfo()
    //   return { name, surname, idPassport, date: timestampToDateString(result.dateActioned) }
    // }
    // return { name: '-', surname: '-', idPassport: '-', date: '-' }

    return {
      name: result.name ?? '-',
      surname: result.surname ?? '-',
      idPassport: result.idPassport ?? '-',
      date: timestampToDateString(result.dateActioned),
      // TODO: make provision for other filter-related fields
    }
  }

  const prepareTableData = async () => {
    if (!props.queryResults?.results) {
      return
    }

    const data = props.queryResults?.results.map(generateRowData)
    setTableData(data)
    setRefreshTimestamp(Date.now())
  }

  const tableColumnConfig: IColumnConfig[] = [
    { id: 'name', label: 'Name', sizeFactor: 0.75 },
    { id: 'surname', label: 'Surname', sizeFactor: 0.75 },
    { id: 'idPassport', label: 'ID / Passport', sizeFactor: 0.75 },
    { id: 'date', label: 'Date', sizeFactor: 0.75 },
    // TODO: make provision for other filter-related fields
  ]

  let refreshInProgressModal = null
  if (refreshingModalOpen) {
    refreshInProgressModal = <LoadingModal open={true}>Refreshing profiles...</LoadingModal>
  }

  let tableContent = (
    <DataTable
      tableData={tableData || []}
      columnConfig={tableColumnConfig}
      tableWidth={9}
      filterState={{}}
      onRowClick={(rowData: Record<string, any>) => null}
      selectedRowItemId={''}
      authorisedItemIds={[]}
      isSelectionEnabled={false}
      style={{ flexGrow: 1 }}
      key={`dataQueryFilteredResults_dataTable_${refreshTimestamp}`}
    />
  )
  if (props.showLoader) {
    tableContent = (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Spinner
          style={{ transform: 'scale(0.6)' }}
          fadeIn="quarter"
          name="three-bounce"
          color={ColorPalette.PRIMARY_BLUE}
        />
        <h1 style={{ ...textStyle }}>{props.loaderMessage || 'Fetching results'}</h1>
      </div>
    )
  }
  return (
    <>
      {tableContent}
      {refreshInProgressModal}
    </>
  )
}

const textStyle = {
  textAlign: 'center' as 'center',
  alignSelf: 'center',
  fontFamily: 'roboto',
  fontWeight: 'normal',
  color: ColorPalette.SECONDARY_TEXT,
  fontSize: '0.85rem',
  paddingLeft: 10,
  paddingRight: 10,
  marginTop: 25,
}
