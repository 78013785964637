import { useState } from 'react'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'

import ButtonGeneric from '../../BaseComponents/Buttons/ButtonGeneric'
import { removeUnderscores, toUpperCaseCustom } from '../../../utils'
import { ColorPalette } from '../../../config/colors'

export const DataTableRow = (props: any) => {
  const [hoverMessage, setHoverMessage] = useState('')

  const showHoverMessage = () => {
    if (hoverMessage === '') {
      setHoverMessage(props.rowData.onHoverMessage)
    }
  }
  const collapseHoverMessage = () => {
    if (hoverMessage !== '') {
      setHoverMessage('')
    }
  }

  const {
    buttonWidth,
    checkbox,
    columnConfig,
    combinedSizeFactor,
    customFilter,
    disabled,
    leftComponent,
    onClick,
    rightComponent,
    rowData,
    rowStyle,
    style,
  } = props

  const hoverMessageBorderColour =
    rowData.hoverMessageType === 'info' ? ColorPalette.DARK_MEDIUM_GREY : 'rgba(220, 20, 60, 0.25)'

  return (
    <div
      style={
        hoverMessage
          ? {
              border: `3px solid ${hoverMessageBorderColour}`,
              borderBottomLeftRadius: '10px',
              borderBottomRightRadius: '10px',
              boxShadow: '0px 3px 4px rgba(140,140,150, 0.5)',
            }
          : {}
      }>
      <div
        onMouseOver={rowData.onHoverMessage ? showHoverMessage : () => null}
        onMouseLeave={rowData.onHoverMessage ? collapseHoverMessage : () => null}
        style={style}>
        {checkbox && rowData.disableSelect ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(220, 20, 60, 0.25)',
              width: 45,
            }}>
            <ErrorIcon style={{ color: ColorPalette.OFF_WHITE }} />
          </div>
        ) : (
          <div style={{ backgroundColor: rowStyle.backgroundColor }}>{checkbox}</div>
        )}
        {customFilter && <div style={{ backgroundColor: rowStyle.backgroundColor }}>{customFilter}</div>}
        {leftComponent}
        <ButtonGeneric
          key={`dataTable_rowItem_${rowData.id}`}
          style={rowStyle}
          disabled={disabled}
          onClick={onClick ? () => onClick(rowData) : () => null}
          label={generateCells(rowData, columnConfig, combinedSizeFactor, buttonWidth, props.trimText)}
        />
        <div style={{ backgroundColor: rowStyle.backgroundColor }}>{rightComponent}</div>
      </div>
      {hoverMessage ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 'smaller',
            padding: '0.75em 2em',
            backgroundColor: ColorPalette.BUTTON_HOVER_GREY,
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
          }}>
          {props.rowData?.hoverMessageType === 'info' ? (
            <InfoIcon style={{ color: ColorPalette.DARK_MEDIUM_GREY, marginRight: '0.5em' }} />
          ) : (
            <ErrorIcon fontSize="small" style={{ color: 'rgba(220, 20, 60, 0.25)', marginRight: '0.5em' }} />
          )}
          {hoverMessage}
        </div>
      ) : null}
    </div>
  )
}

function generateCells(
  rowData: any,
  columnConfig: any[],
  combinedSizeFactor: number,
  buttonWidth: number,
  trimText?: boolean,
): JSX.Element[] {
  return columnConfig.map((configItem, i) => {
    const item = rowData[configItem.id]
    let cell = null
    if (typeof item === 'string') {
      let str = ''
      if (!!trimText) {
        const lengthRatioLimit = 140
        const lengthRatio = buttonWidth / item.length
        const label =
          lengthRatio < lengthRatioLimit ? `${item.slice(0, Math.round(buttonWidth / lengthRatioLimit))}...` : item
        str = removeUnderscores(toUpperCaseCustom(label))
      } else {
        str = item
      }

      cell = <p style={{ textAlign: 'left' }}>{str}</p>
    } else {
      cell = item
    }
    return (
      <div
        key={`${i}_${configItem.id}`}
        style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '0.8rem',
          padding: '0px 20px',
          width: (buttonWidth * (configItem.sizeFactor ? configItem.sizeFactor : 1)) / combinedSizeFactor,
        }}>
        {cell}
      </div>
    )
  })
}
