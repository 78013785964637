import { useEffect, useState } from 'react'
import { CommonUnixMsEnum, PeriodTypeEnum } from '../../../enums'
import { ColorPalette } from '../../../config'
import { CustomTimePeriod, DateRange, TimePeriod } from '../../../types'
import CalendarRange from '../../BaseComponents/Calendar/CalendarRange'
import CheckboxTabLabelled from '../../BaseComponents/Checkboxes/CheckboxTabLabelled'
import { isEmpty, set } from 'lodash'

type DataQueryTimePeriodSelectorProps = {
  timePeriod: TimePeriod | {}
  customTimeRange: CustomTimePeriod | {}
  setTimePeriod: (timePeriod: TimePeriod | undefined) => void
  setCustomDateRange: (timeRange: CustomTimePeriod) => Promise<void>
}

export const DataQueryTimePeriodSelector = (props: DataQueryTimePeriodSelectorProps) => {
  const [selectedOption, setSelectedOption] = useState('')
  const [dateRange, setDateRange] = useState<DateRange | {}>({})
  const [calendarRangeVisible, setCalendarRangeVisibility] = useState(false)
  const [maxDateRange, setMaxDateRange] = useState(CommonUnixMsEnum.ONE_MONTH * 6)

  useEffect(() => {
    if (props.timePeriod && !isEmpty(props.timePeriod)) {
      const option = getTimePeriodOption()
      setSelectedOption(option)
      return // assuming that only timePeriod or customTimeRange will be set
    }
    if (props.customTimeRange && !isEmpty(props.customTimeRange)) {
      const timeRange = props.customTimeRange as CustomTimePeriod
      setDateRange({
        startDate: new Date(timeRange.startUnixMs),
        endDate: new Date(timeRange.endUnixMs),
      })
      setSelectedOption('CUSTOM RANGE')
      setCalendarRangeVisibility(true)
    }
  }, [])

  const getTimePeriodOption = () => {
    if (props.timePeriod && !isEmpty(props.timePeriod)) {
      const timePeriod = props.timePeriod as TimePeriod
      switch (timePeriod.periodType) {
        case PeriodTypeEnum.WEEK:
          return 'LAST WEEK'
        case PeriodTypeEnum.MONTH:
          switch (timePeriod.periodInteger) {
            case 1:
              return 'LAST MONTH'
            case 3:
              return 'LAST 3 MONTHS'
            case 6:
              return 'LAST 6 MONTHS'
            case 12:
              return 'LAST 12 MONTHS'
          }
      }
    }
    return ''
  }

  const handleTimePeriodSelection = async (values: string[], isMount: boolean) => {
    if (!values || !values.length) {
      return
    }
    const selectedOption = values[0]
    let timePeriod: TimePeriod | undefined
    switch (selectedOption) {
      case 'LAST WEEK':
        timePeriod = { periodType: PeriodTypeEnum.WEEK, periodInteger: 1 }
        break
      case 'LAST MONTH':
        timePeriod = { periodType: PeriodTypeEnum.MONTH, periodInteger: 1 }
        break
      case 'LAST 3 MONTHS':
        timePeriod = { periodType: PeriodTypeEnum.MONTH, periodInteger: 3 }
        break
      case 'LAST 6 MONTHS':
        timePeriod = { periodType: PeriodTypeEnum.MONTH, periodInteger: 6 }
        break
      case 'LAST 12 MONTHS':
        timePeriod = { periodType: PeriodTypeEnum.MONTH, periodInteger: 12 }
        break
      case 'CUSTOM RANGE':
        timePeriod = undefined
        break
      default:
        throw new Error('Invalid time period selection')
    }

    const isCustomOption = selectedOption === 'CUSTOM RANGE'
    if (isCustomOption) {
      const now = new Date()
      const dateRange = {
        startDate: new Date(now.getTime() - CommonUnixMsEnum.ONE_MONTH),
        endDate: now,
      }
      setDateRange(dateRange)
      await props.setCustomDateRange({
        startUnixMs: dateRange.startDate.getTime(),
        endUnixMs: dateRange.endDate.getTime(),
      })
    }
    setSelectedOption(selectedOption)
    setCalendarRangeVisibility(isCustomOption)
    props.setTimePeriod(timePeriod)
  }

  const handleCustomDateRangeSelection = (date?: number, rangePoint?: string) => {
    if (!date || !rangePoint) {
      return
    }
    let newDateRange = {
      ...dateRange,
      [rangePoint]: new Date(date),
    }
    setDateRange(newDateRange)
  }

  const highlightCustomRangeSelection = (optionLabel: string) => {
    if (optionLabel === 'CUSTOM RANGE' && selectedOption === 'CUSTOM RANGE') {
      return { fontWeight: 550, letterSpacing: 1.1 }
    }
    return
  }

  const saveOnCustomRangeSelection = async () => {
    const selectedDateRange = dateRange as DateRange
    const unixMs1 = selectedDateRange.startDate.getTime()
    const unixMs2 = selectedDateRange.endDate.getTime()
    const timeRange: CustomTimePeriod = {
      startUnixMs: unixMs1 > unixMs2 ? unixMs2 : unixMs1,
      endUnixMs: unixMs1 > unixMs2 ? unixMs1 : unixMs2,
    }
    await props.setCustomDateRange(timeRange)
    setDateRange(dateRange)
    setCalendarRangeVisibility(true)
  }

  let customDateRangeSelector = null
  if (calendarRangeVisible) {
    customDateRangeSelector = (
      <CalendarRange
        orientation="horizontal"
        dateRange={dateRange}
        onDateChange={handleCustomDateRangeSelection}
        inputStyle={{ border: 'none', width: 225 }}
        dialogStyle={{ zIndex: 30000 }}
        onClose={() => setCalendarRangeVisibility(false)} // CHECK: make sure simply closing the calendar range via 'cancel' doesn't save the selection
        onSave={saveOnCustomRangeSelection}
      />
    )
  }

  return (
    <CheckboxTabLabelled
      style={styles.sectionInput}
      itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
      label="SELECT A TIME PERIOD"
      values={selectedOption ? [selectedOption] : []}
      selectorItems={['LAST WEEK', 'LAST MONTH', 'LAST 3 MONTHS', 'LAST 6 MONTHS', 'CUSTOM RANGE']}
      selectionHandler={handleTimePeriodSelection}
      selectedLabelStyleAdjustment={highlightCustomRangeSelection}>
      {customDateRangeSelector}
    </CheckboxTabLabelled>
  )
}

const styles = {
  sectionInput: {
    margin: '40px 1.5em 0px 1.5em',
  },
}
