import { mdiCog } from '@mdi/js'
import Icon from '@mdi/react'

import { ColorPalette } from '../../../config'
import BarChart from '../../DataVisualisation/BarChart'
import ButtonGeneric from '../../BaseComponents/Buttons/ButtonGeneric'

type TrendGraphProps = {
  selectedTrendId: string
  selectedTrendName: string
  xValues: any[]
  yValues: any[]
  toggleTrendConfigurator: () => void
}

export const TrendGraph = (props: TrendGraphProps) => {
  const { selectedTrendId, xValues, yValues, toggleTrendConfigurator } = props

  let content = null
  if (selectedTrendId && yValues.length > 0) {
    const barData = {
      label: 'COUNT',
      data: yValues,
      backgroundColor: ColorPalette.PRIMARY_BLUE,
    }

    const graphDataSets = [barData]
    const title = (
      <ButtonGeneric
        style={{ ...styles.sectionHeader }}
        iconBefore={
          <Icon path={mdiCog} size={0.8} style={{ color: ColorPalette.PRIMARY_BLUE, marginRight: '0.75em' }} />
        }
        label={`EDIT SETTINGS \u00A0 | \u00A0 ${props.selectedTrendName}`}
        onClick={toggleTrendConfigurator}
      />
    )

    content = (
      <div style={styles.section}>
        {title}
        <BarChart
          data={graphDataSets}
          labels={xValues.map((item: any) => item)}
          showLegend={false}
          style={styles.barChart}
          key={yValues.join('')}
        />
      </div>
    )
  }

  return content
}

const styles = {
  barChart: {
    padding: '1em 0 0',
    width: '100%',
  },
  section: {
    width: '100%',
    margin: '1em 0',
    paddingBottom: '20px',
    cursor: 'pointer',
  },
  sectionHeader: {
    fontWeight: 550,
    marginTop: '10px',
    fontSize: '1rem',
    fontFamily: 'Roboto',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid' as 'solid',
    borderBottomColor: ColorPalette.PRIMARY_BLUE,
    color: ColorPalette.PRIMARY_TEXT,
    height: 40,
    ':hover': {
      color: ColorPalette.PRIMARY_BLUE,
    },
    ':active': {
      color: ColorPalette.DARK_GREY,
    },
  },
}
