import React, { ReactNode, useState } from 'react'
import Radium from 'radium'
import Icon from '@mdi/react'
import { mdiCalendar, mdiClose } from '@mdi/js'

import { ColorPalette } from '../../../config'
import ButtonBlue from '../../BaseComponents/Buttons/ButtonBlue'
import ButtonRound from '../../BaseComponents/Buttons/ButtonRound'
import OptionsSelectorModal from '../../Modals/OptionsSelectorModal'
import { DateRange } from '../../Modals/DateRanges/DateRangesModal'
import { getDateString } from '../../../utils'

interface DocumentFilterButtonProps {
  selectedDocument: string
  dateRange?: DateRange
  documents: string[]
  warning: boolean
  style?: React.CSSProperties

  onDateChange: (dateRange: Record<string, Date>) => void
  toggleFilterType: () => void
  deleteFilter: () => void
  setFilterField: (field: string) => void
  onClick?: () => void
}

const DocumentFilterButton = (props: DocumentFilterButtonProps) => {
  const [documentSelectorModalOpen, setDocumentSelectorModalOpen] = useState(false)

  const closeModals = () => {
    setDocumentSelectorModalOpen(false)
  }

  const handleClick = () => {
    if (props.onClick) {
      props.onClick()
    }
  }

  let documentSelector = null
  if (documentSelectorModalOpen) {
    documentSelector = (
      <OptionsSelectorModal
        style={{ top: -window.innerHeight * 0.3, left: -window.innerWidth * 0.13 }}
        open={true}
        onSuccess={closeModals}
        onReject={closeModals}
        successLabel="Done"
        label1="Filter document"
        options1={props.documents}
        selectedOption1={props.selectedDocument}
        searchEnabled1={true}
        onChange={(event) => props.setFilterField(event.target.value)}
      />
    )
  }

  let dateRangeRender: string | ReactNode = 'NONE'
  if (props.dateRange) {
    const dateRange = props.dateRange
    const startDatePart = dateRange && dateRange.startDate ? getDateString(dateRange.startDate) : '-'
    const endDatePart = dateRange && dateRange.endDate ? getDateString(dateRange.endDate) : getDateString(new Date())
    dateRangeRender = (
      <span style={{ whiteSpace: 'pre' }}>
        {`Captured:    `}
        <span>{`${startDatePart}  to  ${endDatePart}`}</span>
      </span>
    )
  }

  const backgroundColor = props.warning ? ColorPalette.WARNING_RED : ColorPalette.DARK_GREY
  return (
    <div style={{ ...styles.container, ...props.style }}>
      <div style={{ ...styles.buttonAreaContainer }}>
        <ButtonBlue style={{ ...styles.docNameButton, backgroundColor }} onClick={handleClick}>
          {props.selectedDocument || 'Choose a field'}
        </ButtonBlue>

        <ButtonBlue style={{ ...styles.docFilterButton, backgroundColor }} onClick={handleClick}>
          <Icon path={mdiCalendar} size={0.8} style={{ marginLeft: '1em', marginRight: '1em' }} />
          {dateRangeRender}
        </ButtonBlue>

        <ButtonRound
          style={{
            marginLeft: 20,
            width: 25,
            height: 25,
            backgroundColor: ColorPalette.LIGHT_GREY,
            borderRadius: 40,
          }}
          icon={<Icon size={0.8} path={mdiClose} color={ColorPalette.CARD_WHITE} />}
          onClick={() => props.deleteFilter()}
        />
      </div>
      {documentSelector}
    </div>
  )
}

const styles = {
  container: {
    height: 35,
    marginTop: 20,
  },
  textStyle: {
    color: ColorPalette.PRIMARY_TEXT,
    height: 35,
    paddingLeft: 10,
    border: `1px solid ${ColorPalette.DARK_GREY}`,
    borderTopRightRadius: 100,
    borderBottomRightRadius: 100,
    width: '40%',
  },
  buttonAreaContainer: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  docNameButton: {
    width: window.innerWidth * 0.25,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    margin: 0,
    marginRight: 1,
    backgroundImage: 'none',
  },
  docFilterButton: {
    width: window.innerWidth * 0.25,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    margin: 0,
    marginLeft: 1,
    backgroundImage: 'none',
  },
}

export default Radium(DocumentFilterButton)
