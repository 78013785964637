import { ColorPalette } from '../../config/colors'

interface HeadingProps {
  header: string
  icon?: JSX.Element
  containerStyle?: React.CSSProperties
  textStyle?: React.CSSProperties
}

const SectionHeaderTertiary: React.FC<HeadingProps> = (props: HeadingProps) => {
  return (
    <div style={{ ...styles.containerStyle, ...props.containerStyle }}>
      <p style={{ ...styles.sectionHeaderText, ...props.textStyle }}>{props.header}</p>
      {props.icon}
    </div>
  )
}

const styles = {
  containerStyle: {
    height: 30,
    backgroundColor: ColorPalette.DARK_GREY,
    width: '100%',
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center',
  },
  sectionHeaderText: {
    fontSize: '0.8rem',
    fontWeight: '500',
    fontFamily: 'Roboto',
    paddingLeft: 10,
    paddingRight: 10,
    margin: 0,
    color: ColorPalette.PRIMARY_LIGHT_TEXT,
  },
}
export default SectionHeaderTertiary
