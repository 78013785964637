import React, { useState } from 'react'
import Icon from '@mdi/react'
import { mdiPencil } from '@mdi/js'

import { ColorPalette } from '../../../config/colors'
import { GraphParameter } from './GraphParameter'
import CheckboxTabLabelled from '../../BaseComponents/Checkboxes/CheckboxTabLabelled'
import TextInputLabelled from '../../BaseComponents/Text/TextInputLabelled'
import CalendarRangeContained from '../../BaseComponents/Calendar/CalendarRangeContained'

interface DataQueryTrendsProps {
  profileOptionsLoading: boolean
  graphName: string
  dateRange: Record<string, Date>
  numberOfAggregationOptions: number
  numberOfSelectedProfiles: number
  selectedAssociation?: string
  style?: React.CSSProperties
  onGraphNameChange: (value: string) => void
  onDateRangeChange: (value: Record<string, Date>) => void
  toggleFieldSelection: () => void
  toggleProfileSelection: () => void
}

const aggregateByOptions = ['COUNT', 'VALUE']

const DataQueryGraphConfig = (props: DataQueryTrendsProps) => {
  const [aggregateBy, setAggregateBy] = useState('COUNT')

  const handleAggregateByChange = (value: string) => {
    setAggregateBy(value)
  }

  const {
    graphName,
    dateRange,
    numberOfAggregationOptions,
    numberOfSelectedProfiles,
    onGraphNameChange,
    onDateRangeChange,
    toggleFieldSelection,
    toggleProfileSelection,
    style,
  } = props

  return (
    <div style={style}>
      <div style={styles.section}>
        {/* <h1 style={styles.sectionHeader}>GRAPH</h1> */}
        <div style={styles.graphParameters}>
          <TextInputLabelled
            inputProps={{
              color: ColorPalette.PRIMARY_TEXT,
              fontSize: '0.9rem',
            }}
            key="graphName"
            label="GRAPH NAME"
            // icon={item.iconName}
            value={graphName}
            defaultValue={'Enter graph name'}
            maxLength={100}
            textHandler={(value: string, isMount?: boolean) => {
              if (!isMount) {
                onGraphNameChange(value)
              }
            }}
            containerStyle={{ marginBottom: '1em' }}
            primaryStyle={{ width: '100%' }}
            focusOnMount={true}
            endAdornmentIcon={(style) => (
              <Icon
                path={mdiPencil}
                size={graphName ? 0.7 : 0.9}
                style={{ color: ColorPalette.PRIMARY_BLUE, marginRight: '0.15em' }}
                title={graphName ? '' : 'Required'}
              />
            )}
          />
          <GraphParameter
            isLoading={props.profileOptionsLoading}
            label="PROFILES TO INCLUDE"
            placeholder={`${numberOfSelectedProfiles} PROFILES`}
            onClick={toggleProfileSelection}
            iconPath={mdiPencil}
            key="profilesToInclude"
          />
          <GraphParameter
            label="FIELDS TO AGGREGATE"
            placeholder={`${numberOfAggregationOptions} FIELDS`}
            onClick={toggleFieldSelection}
            iconPath={mdiPencil}
            key="fieldsToAggregate"
          />
          <CheckboxTabLabelled
            style={{ width: '100%', marginTop: 0 }}
            itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
            label={'AGGREGATE BY'}
            values={[aggregateBy]}
            selectorItems={aggregateByOptions}
            selectionHandler={(values: string[], isMount) => {
              if (!isMount) {
                handleAggregateByChange(values[0])
              }
            }}
          />
          <CalendarRangeContained
            label="SHOW RESULTS IN RANGE"
            dateRange={dateRange}
            onDateChange={(date?: number, rangePoint?: string) => {
              if (!date || !rangePoint) {
                return
              }
              const newDateRange = { ...dateRange, [rangePoint]: new Date(date) }
              onDateRangeChange(newDateRange)
            }}
            minDate={new Date(2023, 0, 1)}
            maxDate={new Date()}
            orientation="horizontal" // TODO: make horisontal when screen is wide enough
            inputStyle={styles.dateRangeInput}
            textStyle={styles.dateRangeText}
            style={{ width: '100%', marginTop: 0 }}
          />
        </div>
      </div>
    </div>
  )
}

const styles = {
  section: {
    width: '100%',
    paddingBottom: '20px',
  },
  sectionHeader: {
    marginTop: 0,
    fontSize: '1rem',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    color: ColorPalette.PRIMARY_TEXT,
    textAlign: 'left' as 'left',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid' as 'solid',
    borderBottomColor: ColorPalette.PRIMARY_BLUE,
    padding: '0em 0.5em 0.5em 0.5em',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  graphParameters: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    gap: '2.25em',
    marginTop: '1em',
  },
  dateRangeInput: {
    border: 'none',
    transform: 'scale(0.9)',
    padding: 0,
  },
  dateRangeText: {
    marginTop: '0.75rem',
    marginBottom: 0,
    padding: 0,
    fontSize: '0.85em',
  },
}

export default DataQueryGraphConfig
