import { ColorPalette } from '../../../config/colors'
import { ScrollView } from '@cantonjs/react-scroll-view'
import React, { Component } from 'react'
import SimplePagination from '../Pagination/SimplePagination'

interface ITimelineProps {
  logs: any[]
  shouldPaginate?: boolean
  key?: string
  isFirstPage?: boolean
  isLastPage?: boolean
  loadPrevious?: () => void
  loadNext?: () => void
  containerStyle?: React.CSSProperties | undefined
}

interface ITimelineState {
  warnings: any
  maxItemsPerRow: number
}

class Timeline extends Component<ITimelineProps, ITimelineState> {
  state = {
    warnings: {},
    maxItemsPerRow: 2,
  }

  private loadNext() {
    if (typeof this.props.loadNext === 'undefined') {
      return
    }
    this.props.loadNext()
  }

  private loadPrevious() {
    if (typeof this.props.loadPrevious === 'undefined') {
      return
    }
    this.props.loadPrevious()
  }

  render() {
    let timelineBlocks: any = (
      <div style={{ ...styles.promptContainer }}>
        <p style={{ ...styles.text, color: ColorPalette.DARK_MEDIUM_GREY }}>No logs to show</p>
      </div>
    )

    if (this.props.logs && this.props.logs.length) {
      timelineBlocks = this.props.logs
    }

    let paginationController = null
    if (this.props.shouldPaginate) {
      paginationController = (
        <SimplePagination
          style={{ marginTop: 20 }}
          previousLabel={'PREVIOUS'}
          nextLabel={'NEXT'}
          key={this.props.key}
          isPrevious={!this.props.isFirstPage}
          isNext={!this.props.isLastPage}
          loadPrevious={() => this.loadPrevious()}
          loadNext={() => this.loadNext()}
        />
      )
    }
    return (
      <ScrollView
        style={{ ...styles.container, ...this.props.containerStyle }}
        contentContainerStyle={{ ...styles.timelineStyle }}>
        {timelineBlocks}
        {paginationController}
      </ScrollView>
    )
  }
}

const styles = {
  container: {
    width: '100%',
    height: window.innerHeight * 0.75,
  },
  timelineStyle: {
    paddingTop: 10,
    paddingBottom: 100,
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
  },
  promptContainer: {
    marginTop: window.innerHeight * 0.3,
    width: '100%',
    height: '78%',
    alignItems: 'center',
    textAlign: 'center' as 'center',
    shadowOpacity: 0.16,
    shadowRadius: 4,
    shadowOffset: {
      width: 0,
      height: 6,
    },
  },
  text: {
    color: ColorPalette.PRIMARY_LIGHT_TEXT,
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    fontSize: 15,
  },
}

export default Timeline
