import { isEmpty, pick } from 'lodash'

import { SessionService } from '../../services'
import {
  AnalyticsConfigCreateResponse,
  AnalyticsConfigsResponse,
  AnalyticsConfigUpdateResponse,
  Query,
  ResultExportTargetEmails,
} from '../../types'
import { httpGet, httpPost } from '../remoteData'
import { FilterTypeEnum } from '../../enums'

export async function getAnalyticsConfigs(association: string, username: string, password: string) {
  const token = await SessionService.prepareAuthTokens(username, password)
  const response: AnalyticsConfigsResponse = await httpGet(
    'people_flow_core',
    `/analytics/config/${association}`,
    token,
  )
  return response
}

export async function updateAnalyticsConfig(association: string, username: string, password: string, query: Query) {
  function addDataPath(filters: any) {
    return filterParams.filters.map((orFilter: any) => {
      orFilter = orFilter.map((filter: any) => {
        filter.dataPath = filter.filterType === FilterTypeEnum.DOCUMENT ? ['docs'] : ['rawData']
        return filter
      })
      return orFilter
    })
  }

  function nullEmptyTimeRelatedObjects(filterParams: any) {
    if (filterParams.timeRange && isEmpty(filterParams.timeRange)) {
      filterParams.timeRange = null
    }
    if (filterParams.period && isEmpty(filterParams.period)) {
      filterParams.period = null
    }
    return filterParams
  }

  function addTimeRangePath(filterParams: any) {
    if (filterParams.timeRange) {
      filterParams.timeRange.timeRangePath = ['rawData', 'dateActioned']
    }
    return filterParams
  }

  const token = await SessionService.prepareAuthTokens(username, password)
  let queryData = pick(query, ['name', 'trends', 'status'])
  let filterParams = {
    ...query.filterParams,
    timeRangePath: ['rawData', 'dateActioned'], // not sure if this will instead be set as default on the backend?
  }
  filterParams.filters = addDataPath(filterParams.filters)

  filterParams = nullEmptyTimeRelatedObjects(filterParams)
  filterParams = addTimeRangePath(filterParams)

  const payload = {
    config: {
      pk: association,
      sk: query.id,
      ...queryData,
      filterParams,
    },
  }
  const { data } = (await httpPost(
    'people_flow_core',
    `/analytics/config/${association}`,
    payload,
    token,
  )) as AnalyticsConfigUpdateResponse
  return data
}

export async function createAnalyticsConfig(
  association: string,
  queryName: string,
  username: string,
  password: string,
) {
  const token = await SessionService.prepareAuthTokens(username, password)
  const payload = {
    config: {
      name: queryName,
      filterParams: { filters: [] },
      trends: [],
      status: 'active',
    },
  }
  const response: AnalyticsConfigCreateResponse = await httpPost(
    'people_flow_core',
    `/analytics/config/${association}`,
    payload,
    token,
  )
  return response
}

export async function getAnalyticsResults(
  association: string,
  queryId: string,
  unixMs: number,
  username: string,
  password: string,
) {
  const token = await SessionService.prepareAuthTokens(username, password)
  const response = await httpGet('people_flow_core', `/analytics/results/${association}/${queryId}/${unixMs}`, token)
  return response
}

export async function triggerAnalyticsResultsGeneration(
  association: string,
  queryId: string,
  username: string,
  password: string,
  targetEmails?: ResultExportTargetEmails,
) {
  const token = await SessionService.prepareAuthTokens(username, password)
  const payload: { targetEmails?: ResultExportTargetEmails } = {}
  if (targetEmails && targetEmails.length) {
    payload.targetEmails = targetEmails
  }
  await httpPost('people_flow_core', `/analytics/trigger/${association}/${queryId}`, payload, token)
}
