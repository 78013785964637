import React, { Component } from 'react'
import Spinner from 'react-spinkit'
import { Property } from 'csstype'
import Radium from 'radium'

import { SessionService } from '../../services'
import { ColorPalette } from '../../config/colors'
import TextInputLabelled from '../BaseComponents/Text/TextInputLabelled'
import ButtonBlue from '../BaseComponents/Buttons/ButtonBlue'

interface ForgotPasswordProps {
  open: boolean
  idPassport: string

  dismiss: () => void
}

interface ForgotPasswordState {
  open: boolean
  mounted: boolean
  capturePasswordActive: boolean
  loading: boolean
  otp: string
  warning: string
  password?: string
}

class ForgotPassword extends Component<ForgotPasswordProps, ForgotPasswordState> {
  state: ForgotPasswordState = {
    open: false,
    mounted: false,
    capturePasswordActive: false,
    loading: false,
    otp: '',
    warning: '',
  }

  componentDidMount() {
    this.setState({ open: this.props.open, mounted: this.props.open })
  }

  componentDidUpdate(prevProps: ForgotPasswordProps) {
    if (prevProps.open !== this.props.open) {
      this.setState({ open: this.props.open }, () =>
        setTimeout(
          () => {
            this.setState({ mounted: this.props.open })
          },
          this.props.open ? 0 : 50,
        ),
      )
    }
  }

  submit = async () => {
    try {
      this.setState({ warning: '', loading: true })
      await SessionService.submitChangedPassword(this.props.idPassport, this.state.otp, this.state.password)
      this.props.dismiss()
    } catch (error) {
      this.displayWarning(error)
    }
  }

  displayWarning(error: any) {
    let warning = ''
    if (error.code === 'InvalidOtp') {
      warning = 'Your OTP must be 6 digits'
    } else if (error.code === 'InvalidPassword') {
      warning = 'Your new password is invalid'
    } else if (error.code === 'CodeMismatchException') {
      warning = 'The OTP you entered is invalid'
    } else if (error.code === 'LimitExceededException') {
      warning = 'Retry limit reached. Try again in a while.'
    } else {
      warning = 'We encountered a problem. Refresh the page and try again.'
    }
    this.setState({ loading: false, warning })
  }

  render() {
    styles.screenContainer = {
      ...styles.screenContainer,
      animation: this.state.open ? 'x 0.05s ease-in' : 'x 0.1s ease-out',
      animationName: this.state.open ? fadeInBackground : fadeOutBackground,
    }

    styles.cardContainer = {
      ...styles.cardContainer,
      animation: this.state.open ? 'x 0.05s ease-in' : 'x 0.1s ease-out',
      animationName: this.state.open ? fadeInCard : fadeOutCard,
    }

    let modalContents = null
    if (this.state.mounted) {
      let actionButton = (
        <ButtonBlue style={{ marginTop: 20, width: '100%' }} onClick={this.submit}>
          {'Submit'}
        </ButtonBlue>
      )

      if (this.state.loading) {
        actionButton = (
          <Spinner
            style={{ transform: 'scale(0.7)', alignSelf: 'center', marginTop: 25 }}
            fadeIn="quarter"
            name="three-bounce"
            color={ColorPalette.PRIMARY_TEXT}
          />
        )
      }

      let warning = null
      if (this.state.warning) {
        warning = <p style={styles.warningTextStyle}>{this.state.warning}</p>
      }

      modalContents = (
        <>
          <button style={styles.screenContainer} onClick={() => this.props.dismiss()} />
          <div style={styles.cardContainer}>
            <TextInputLabelled
              containerStyle={{ marginTop: 0 }}
              primaryStyle={{ width: '75%', height: 50 }}
              inputLabelProps={{ color: 'secondary' }}
              label="Enter OTP (SMS'd to you)"
              // placeholderColor={ColorPalette.SECONDARY_TEXT}
              value={this.state.otp}
              textHandler={(value) => this.setState({ otp: value })}
              type="text"
              multiline={false}
              maxLength={6}
              icon="otp"
            />

            <TextInputLabelled
              containerStyle={{ marginTop: 20 }}
              primaryStyle={{ width: '75%', height: 50 }}
              inputLabelProps={{ color: 'secondary' }}
              label="New password"
              // placeholderColor={ColorPalette.SECONDARY_TEXT}
              value={this.state.password || ''}
              textHandler={(value) => this.setState({ password: value })}
              type="password"
              multiline={false}
              maxLength={32}
              icon="password"
            />

            <div style={{ display: 'flex', flexDirection: 'column' as 'column' }}>
              <p style={{ ...styles.textStyle, marginTop: 20 }}>- 8 characters long</p>
              <p style={styles.textStyle}>- Contain upper and lowercase letters</p>
              <p style={styles.textStyle}>- Contain numbers</p>

              <p style={{ ...styles.textStyle, marginTop: 20 }}>(OTP may take a few minutes to arrive)</p>
            </div>
            {actionButton}
            {warning}
          </div>
        </>
      )
    }
    return modalContents
  }
}

const fadeInBackground = Radium.keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
}) as Property.AnimationName

const fadeOutBackground = Radium.keyframes({
  '0%': { opacity: 1 },
  '100%': { opacity: 0 },
}) as Property.AnimationName

const fadeInCard = Radium.keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1, marginRight: window.innerHeight * 0.05 },
}) as Property.AnimationName

const fadeOutCard = Radium.keyframes({
  '0%': { marginRight: window.innerHeight * 0.05 },
  '50%': { opacity: 0 },
  '100%': { marginRight: -window.innerHeight * 0.13, opacity: 0 },
}) as Property.AnimationName

let styles: Record<string, React.CSSProperties> = {
  screenContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: window.innerWidth,
    height: window.innerHeight,
    backgroundColor: ColorPalette.MODAL_BACKGROUND_OVERLAY,
    zIndex: 10000,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 0,
    border: 'none',
    overflow: 'hidden',
  },
  cardContainer: {
    zIndex: 10000,
    position: 'absolute',
    top: window.innerHeight * 0.08,
    left: window.innerWidth * 0.4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
    backgroundColor: ColorPalette.CARD_WHITE,
    marginTop: window.innerHeight * 0.03,
    marginRight: window.innerHeight * 0.05,
    width: window.innerWidth * 0.22,
    padding: 30,
    paddingBottom: 30,
    borderRadius: 8,
  },
  textStyle: {
    fontSize: '0.8rem',
    fontWeight: '300',
    marginTop: -10,
    color: ColorPalette.PRIMARY_TEXT,
  },
  warningTextStyle: {
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: '0.9rem',
    fontWeight: '300',
    marginTop: 30,
    color: ColorPalette.WARNING_RED,
  },
}

export default Radium(ForgotPassword)
