import TableCell from '@material-ui/core/TableCell'
import { ColorPalette } from '../../../config'
import { CSSProperties } from 'react'

type SimpleTableCellProps = {
  index: number
  data: string | React.ReactNode
  stickyFirstColumn?: boolean
}

export const SimpleTableCell = (props: SimpleTableCellProps) => {
  if (props.index === 0) {
    const style: CSSProperties = { ...styles.tableCell }
    if (props.stickyFirstColumn) {
      style.position = 'sticky'
      style.left = 0
      style.background = ColorPalette.CARD_WHITE
    }
    return (
      <TableCell component="th" scope="row" padding="none" style={style}>
        {props.data}
      </TableCell>
    )
  }
  return (
    <TableCell align="left" padding="none" style={styles.tableCell}>
      {props.data}
    </TableCell>
  )
}

const styles = {
  tableCell: {
    padding: '9.75px 16px',
    color: ColorPalette.SECONDARY_TEXT,
    justifySelf: 'flex-start',
  },
}
