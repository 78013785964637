import { updateConfig } from '../provider'
import { SessionService } from './sessionService'
import { AuthCredentials, ConfigChanges, ConfigType } from '../types'

export class ConfigManagerService {
  static updateConfig = async (
    selectedAssociation: string,
    configType: ConfigType,
    changesToApply: ConfigChanges,
    authCredentials: AuthCredentials,
    dataFlowName?: string,
  ): Promise<{ result: any; updatedConfig: any } | { result: any; updatedConfig?: undefined }> => {
    const { username, password } = authCredentials
    const token = await SessionService.prepareAuthTokens(username, password)
    let payload = {
      selectedAssociation: selectedAssociation,
      configType,
      changes: changesToApply,
      dataFlowName: configType === 'dataFlow' ? dataFlowName : undefined,
    }

    const response = await updateConfig(payload, token)
    return response
  }
}
