import React from 'react'
import Radium from 'radium'
import { ColorPalette } from '../../../config/colors'
import FormControl from '@material-ui/core/FormControl'
import Calendar, { CalendarProps } from './Calendar'

const CalendarContained: React.FC<CalendarProps> = (props: CalendarProps) => {
  const { style, ...otherProps } = props
  return (
    <FormControl style={{ ...formControl, ...style }}>
      <Calendar {...otherProps} />
    </FormControl>
  )
}

const formControl = {
  minWidth: 120,
  width: '80%',
  marginTop: 5,
  paddingTop: 15,
  paddingLeft: 20,
  paddingRight: 20,
  paddingBottom: 10,
  fontSize: '1rem',
  border: `1px solid ${ColorPalette.VERY_LIGHT_GREY}`,
  borderRadius: 12,
  display: 'flex',
  justifyContent: 'center',
}

export default Radium(CalendarContained)
