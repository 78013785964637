import React from 'react'
import { ScrollView } from '@cantonjs/react-scroll-view'

import { toUpperCaseCustom, toLowerCaseCustom } from '../../utils'
import ButtonBlue from '../BaseComponents/Buttons/ButtonBlue'
import ButtonGrey from '../BaseComponents/Buttons/ButtonGrey'

import { ColorPalette } from '../../config/colors'

interface ConfirmChangesModalProps {
  changes: any[]
  open: boolean
  screenContainerStyle?: Record<string, any>
  cardContainerStyle?: Record<string, any>

  onClose: () => void
  save: () => void
  dontSave: () => void
}

const confirmChangesModal: React.FC<ConfirmChangesModalProps> = (props: ConfirmChangesModalProps) => {
  let listOfChanges = [...props.changes].map((item, index) => {
    if (!item) {
      return null
    }

    if (item.type === 'heading') {
      return (
        <div key={`${item.key}_${index}`} style={{ marginTop: index === 0 ? 15 : 25 }}>
          <p style={{ ...styles.defaultFont, fontWeight: 'bold', marginLeft: 15 }}>{toUpperCaseCustom(item.key)}</p>
          <div style={{ ...styles.line, marginBottom: 15, marginTop: 15 }} />
        </div>
      )
    }

    if (['center', 'side', 'raised'].includes(toLowerCaseCustom(item.key))) {
      return (
        <div
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 20 }}
          key={`${item.key}_${index}`}>
          <div style={{ ...styles.bulletPoint }} />
          <p style={{ ...styles.defaultFont, alignSelf: 'flex-start' }}>
            {'Updated face image: ' + toUpperCaseCustom(item.key)}
          </p>
        </div>
      )
    }

    let changeItem = ''
    if (item.change.old && item.change.new) {
      changeItem = item.key + ':  ' + toUpperCaseCustom(item.change.old) + '  =>  ' + toUpperCaseCustom(item.change.new)
    } else if (item.change.old && !item.change.new) {
      changeItem = item.key + ':  ' + toUpperCaseCustom(item.change.old) + '  =>  DELETED INFO'
    } else {
      changeItem = item.key + ':  =>  ' + toUpperCaseCustom(item.change.new)
    }

    return (
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 20 }}
        key={`${item.key}_${index}`}>
        <div style={{ ...styles.bulletPoint }} />
        <p
          style={{
            ...styles.defaultFont,
            fontSize: 12,
            color: ColorPalette.SECONDARY_TEXT,
            alignSelf: 'flex-start',
          }}>
          {changeItem}
        </p>
      </div>
    )
  })

  return (
    <div style={{ ...styles.screenContainer, ...props.screenContainerStyle }}>
      <button style={styles.backgroundOverlay} onClick={() => props.onClose()} />

      <div style={{ ...styles.cardContainer, ...props.cardContainerStyle }}>
        <h1 style={{ ...styles.textStyle, fontWeight: 'bold', fontSize: '1rem', marginTop: 5 }}>CHANGE SUMMARY</h1>
        <h1 style={{ ...styles.textStyle, marginBottom: 15, fontWeight: '100' }}>
          Would you like to save the following changes?
        </h1>

        <ScrollView style={{ width: window.innerWidth * 0.4 }} contentContainerStyle={{ ...styles.scrollContainer }}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '82%' }}>{listOfChanges}</div>
        </ScrollView>
        <div style={styles.buttonContainer}>
          <ButtonGrey style={{ width: '48%' }} onClick={props.dontSave}>
            Don't save
          </ButtonGrey>
          <ButtonBlue style={{ width: '48%' }} onClick={props.save}>
            Save
          </ButtonBlue>
        </div>
      </div>
    </div>
  )
}

const styles = {
  screenContainer: {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    backgroundColor: ColorPalette.MODAL_BACKGROUND_OVERLAY,
    width: window.innerWidth,
    height: window.innerHeight,
    zIndex: 100000,
    overflow: 'hidden',
  },
  backgroundOverlay: {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    width: window.innerWidth,
    height: window.innerHeight,
    backgroundColor: 'transparent',
    zIndex: 10000,
    outline: 0,
    border: 'none',
  },
  cardContainer: {
    position: 'absolute' as 'absolute',
    top: 100,
    left: window.innerWidth * 0.3,
    zIndex: 100000,
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    boxShadow: '0px 2px 8px rgba(0, 0, 30, 0.2)',
    backgroundColor: ColorPalette.CARD_WHITE,
    width: window.innerWidth * 0.4,
    padding: 30,
    borderRadius: 8,
  },
  // cardContainer: {
  //     display: "flex",
  //     flexDirection: "column" as "column",
  //     alignItems: "center",
  //     backgroundColor: ColorPalette.CARD_WHITE,
  //     padding: "50px 30px 40px 30px",
  // },
  buttonContainer: {
    width: '92%',
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 40,
    paddingBottom: 10,
  },
  textStyle: {
    marginTop: 5,
    textAlign: 'center' as 'center',
    alignSelf: 'center',
    fontFamily: 'roboto',
    fontWeight: 'normal',
    color: ColorPalette.PRIMARY_TEXT,
    fontSize: '0.95rem',
    paddingLeft: 10,
    paddingRight: 10,
  },
  line: {
    border: `0.6px solid ${ColorPalette.VERY_LIGHT_GREY}`,
    width: '100%',
    alignSelf: 'center',
    marginTop: 10,
  },
  defaultFont: {
    fontSize: 14,
    fontFamily: 'Roboto',
    color: ColorPalette.PRIMARY_TEXT,
    padding: 0,
    margin: 2,
  },
  bulletPoint: {
    backgroundColor: ColorPalette.PRIMARY_TEXT,
    width: 6,
    height: 6,
    borderRadius: 3,
    marginRight: 15,
  },
  scrollContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    maxHeight: window.innerHeight * 0.5,
  },
}

export default confirmChangesModal
