import Icon from '@mdi/react'
import Radium from 'radium'

import { ColorPalette } from '../../../config/colors'

type Item = {
  label: string
  labelIsIconPath?: boolean
  title?: string
  onClick: () => void
  style?: React.CSSProperties
}

type MultiButtonProps = {
  clickableItems: Item[]
  style?: React.CSSProperties
}

const MultiButton = (props: MultiButtonProps) => {
  let containerStyle = { ...styles.container }
  if (props.style) {
    containerStyle = { ...containerStyle, ...props.style }
  }
  return (
    <div style={containerStyle}>
      {props.clickableItems.map((item: Item, index: number) => {
        const buttonPosition =
          index === 0 ? 'leftButton' : index === props.clickableItems.length - 1 ? 'rightButton' : 'middleButton'
        const key = `button_${item.label}_${index}`
        let buttonStyle: React.CSSProperties = { ...styles.button, ...styles[buttonPosition] }
        if (item.style) {
          buttonStyle = { ...buttonStyle, ...item.style }
        }

        return (
          <button onClick={item.onClick} style={buttonStyle} title={item.title} key={key}>
            {item.labelIsIconPath ? <Icon path={item.label} /> : item.label}
          </button>
        )
      })}
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    height: 35,
    backgroundImage: `linear-gradient(to bottom, ${ColorPalette.PRIMARY_BLUE}, ${ColorPalette.SECONDARY_BLUE})`,
    fontSize: '0.9rem',
    fontFamily: 'roboto',
    color: 'rgba(250, 250, 250, 1)',
    border: 'none',
    outline: 0,
    boxShadow: '0px 2px 6px rgba(0, 0, 30, 0.3)',
    ':hover': {
      backgroundImage: `linear-gradient(to bottom, ${ColorPalette.PRIMARY_BLUE}, ${ColorPalette.PRIMARY_BLUE_GRADIENT_LIGHT})`,
    },
    ':active': {
      filter: 'brightness(50%)',
    },
    cursor: 'pointer',
    paddingInline: '1.25em',
  },
  leftButton: {
    borderTopLeftRadius: 30,
    borderBottomLeftRadius: 30,
    paddingLeft: '1.75em',
  },
  middleButton: {},
  rightButton: {
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    paddingRight: '1.75em',
  },
}

export default Radium(MultiButton)
