import { ColorPalette } from '../../config/colors'
import { toUpperCaseCustom } from '../../utils'

import React, { Component } from 'react'
import Radium from 'radium'
import Icon from '@mdi/react'
import {
  mdiCog,
  mdiFormatSize,
  mdiFormatBold,
  mdiFormatItalic,
  mdiFormatUnderline,
  mdiChevronDown,
  mdiDelete,
} from '@mdi/js'

import ButtonGeneric from '../BaseComponents/Buttons/ButtonGeneric'

interface FieldConfigControllerProps {
  style?: React.CSSProperties | undefined
  componentType: string
  fontSizeName?: string

  onClick: (functionType: string, type?: string) => void
}

interface FieldConfigControllerState {
  subMenuOpen: string
}

class FieldConfigController extends Component<FieldConfigControllerProps, FieldConfigControllerState> {
  iconMap: Record<string, string> = {
    delete: mdiDelete,
    settings: mdiCog,
    fontSizeName: mdiFormatSize,
    fontWeight: mdiFormatBold,
    fontStyle: mdiFormatItalic,
    underline: mdiFormatUnderline,
  }

  activeStateMap: Record<string, string> = {
    fontWeight: 'bold',
    fontStyle: 'italic',
    delete: 'deleteButton',
  }

  inactiveStateMap: Record<string, string> = {
    fontWeight: 'normal',
    fontStyle: 'normal',
  }

  styleSettings = [
    {
      functionType: 'settings',
      label: this.props.componentType,
    },
    {
      functionType: 'fontSizeName',
      subMenuItems: ['TINY', 'SMALL', 'MEDIUM', 'LARGE', 'HUGE'],
    },
    {
      functionType: 'fontWeight',
    },
    {
      functionType: 'fontStyle',
    },
    {
      functionType: 'delete',
    },
  ]

  state: FieldConfigControllerState = {
    subMenuOpen: '',
  }

  toggleSubMenu(subMenuName: string, shouldShow: boolean) {
    this.setState({ subMenuOpen: shouldShow ? subMenuName : '' })
  }

  render() {
    return (
      <div style={{ ...styles.container, ...this.props.style }}>
        {this.styleSettings.map((buttonConfig, index) => {
          const { functionType, subMenuItems, label } = buttonConfig
          const borderRadiusStyle = {
            borderTopLeftRadius: index === 0 ? 20 : 0,
            borderBottomLeftRadius: index === 0 ? 20 : 0,
            borderTopRightRadius: index === this.styleSettings.length - 1 ? 20 : 0,
            borderBottomRightRadius: index === this.styleSettings.length - 1 ? 20 : 0,
          }

          let arrow = null
          let subMenu: JSX.Element | null = null
          if (subMenuItems) {
            arrow = <Icon path={mdiChevronDown} size={0.5} color={ColorPalette.PRIMARY_TEXT} />
            if (this.state.subMenuOpen === functionType) {
              subMenu = (
                <div style={styles.subMenuContainer}>
                  {subMenuItems.map((item) => {
                    return (
                      <ButtonGeneric
                        style={styles.subMenuButton}
                        label={toUpperCaseCustom(item)}
                        onClick={() => this.props.onClick(functionType, item)}
                      />
                    )
                  })}
                </div>
              )
            }
          }

          return (
            <div
              key={`fieldoutermapkey_${index}`}
              style={{
                ...borderRadiusStyle,
                backgroundColor:
                  functionType === 'settings'
                    ? ColorPalette.PRIMARY_BLUE
                    : (this.props as any)[functionType] === this.activeStateMap[functionType] && !subMenuItems
                    ? ColorPalette.MEDIUM_GREY
                    : ColorPalette.LIGHT_GREY,
              }}
              onMouseEnter={() => this.toggleSubMenu(functionType, true)}
              onMouseLeave={() => this.toggleSubMenu(functionType, false)}>
              <button
                key={`${functionType}_${index}`}
                disabled={subMenuItems ? true : false}
                onClick={() =>
                  this.props.onClick(
                    functionType,
                    (this.props as any)[functionType] === this.activeStateMap[functionType]
                      ? this.inactiveStateMap[functionType]
                      : this.activeStateMap[functionType],
                  )
                }
                style={{
                  ...styles.styleButton,
                  ...borderRadiusStyle,
                  backgroundColor:
                    functionType === 'settings'
                      ? ColorPalette.PRIMARY_BLUE
                      : (this.props as any)[functionType] === this.activeStateMap[functionType] && !subMenuItems
                      ? ColorPalette.BUTTON_ACTIVE_GREY
                      : ColorPalette.BUTTON_WHITE,
                  paddingRight: index === this.styleSettings.length - 1 ? 10 : 7,
                }}>
                <Icon
                  path={this.iconMap[functionType]}
                  size={0.7}
                  color={functionType === 'settings' ? ColorPalette.CARD_WHITE : ColorPalette.PRIMARY_TEXT}
                />
                <h1
                  style={{
                    ...styles.textStyle,
                    color: functionType === 'settings' ? ColorPalette.CARD_WHITE : ColorPalette.PRIMARY_TEXT,
                  }}>
                  {label}
                </h1>
                {arrow}
              </button>

              {subMenu}
            </div>
          )
        })}
      </div>
    )
  }
}

let styles = {
  container: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000000,
  },
  styleButton: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 1,
    marginRight: 0,
    height: 30,
    paddingLeft: 7,
    paddingRight: 7,
    border: `2px solid rgba(255,255,255,0)`,
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
    ':hover': {
      filter: 'brightness(96%)',
    },
    ':active': {
      filter: 'brightness(90%)',
    },
  },
  subMenuContainer: {
    position: 'absolute' as 'absolute',
    width: 100,
    marginTop: 2,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    backgroundColor: ColorPalette.BUTTON_HOVER_GREY,
    padding: 5,
  },
  subMenuButton: {
    height: 30,
    fontSize: '0.6rem',
    fontWeight: 'normal',
    color: ColorPalette.PRIMARY_TEXT,
    backgroundColor: ColorPalette.BUTTON_HOVER_GREY,
    ':hover': {
      filter: 'brightness(96%)',
    },
    ':active': {
      filter: 'brightness(90%)',
    },
  },
  textStyle: {
    fontFamily: 'roboto',
    fontWeight: 'normal',
    fontSize: '0.6rem',
    marginLeft: 3,
  },
}

export default Radium(FieldConfigController)
