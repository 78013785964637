import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import ButtonGeneric from '../../BaseComponents/Buttons/ButtonGeneric'
import { BackButton } from '../../BaseComponents/Buttons/BackButton'
import { ColorPalette } from '../../../config'

export type NavButtonType = {
  label?: string
  direction?: 'left' | 'right'
  disabled?: boolean
  style?: React.CSSProperties
  onClick?: () => void | Promise<void>
}

export const NavButton = (props: NavButtonType) => {
  const { direction = 'left', disabled, label, style, onClick } = props

  if (direction === 'left') {
    return <BackButton label={label} disabled={disabled} style={style} onClick={onClick} />
  }

  return (
    <ButtonGeneric
      style={{ ...styles.nextButton, ...props.style }}
      iconAfter={<ChevronRightIcon style={styles.buttonIconStyle} />}
      label={props.label || 'NEXT'}
      disabled={props.disabled}
      // @ts-ignore (direction 'right' needs onClick prop specified)
      onClick={props.onClick}
    />
  )
}

const styles = {
  buttonIconStyle: {
    color: ColorPalette.PRIMARY_TEXT,
    width: '1.2rem',
    height: '1.2rem',
  },
  nextButton: {
    fontSize: '0.8rem',
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    width: 40,
    ':hover': {
      color: ColorPalette.PRIMARY_BLUE,
    },
    ':active': {
      color: ColorPalette.DARK_GREY,
    },
  },
}
