import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { RouteComponentProps, useHistory, useLocation, useParams, useRouteMatch } from 'react-router'
import { mdiPlus, mdiDelete, mdiPencil, mdiTimerSand, mdiNotePlusOutline } from '@mdi/js'
import { isEmpty, cloneDeep, isEqual } from 'lodash'

import DataTable from '../../components/Tables/DataTable/DataTable'
import { ColorPalette } from '../../config/colors'
import { PeopleFlowCombinedReducer } from '../../store'
import { SessionService } from '../../services'
import GroupMemberExpiryDateModal from '../../components/Groups/GroupMemberExpiryDateModal'
import { Toolbar, ActionButtonType } from '../../components/GeneralUI/Toolbar'
import { createActionRecord, getPpeInPossession, removeUnderscores } from '../../utils'
import { GroupMemberJobTypeModal } from '../../components/Groups/GroupMemberJobTypeModal'
import { generateMemberStatusIndicator } from '../../utils/groups'
import SideMenu from '../../components/Navigation/SideMenu'
import GroupMembersSideMenu from '../../components/SideMenus/GroupMembersSideMenu'
import { persistCurrentProfileData } from '../../utils/idbProfiles'
import { PPEService } from '../../services/ppeService'
import { GroupMemberPpeProps } from '../../components/Groups/GroupMemberPpe'
import AlertModalOneButton from '../../components/Modals/AlertModalOneButton'
import LoadingModal from '../../components/Modals/LoadingModal'
import NavigationBar from '../../components/Navigation/NavigationBar'
import SectionHeader from '../../components/Headings/SectionHeaderPrimary'
import { GroupService } from '../../services'
import { dateObjToString } from '../../utils/date'
import { JobRequirementsNotSatisfied } from './JobRequirementsNotSatisfied'
import { AssignedTo } from './AssignedTo'
import { GroupMemberDetails } from './GroupMemberDetails'
import { RequestedPpeConfirmation } from '../../components/Ppe/RequestedPpeConfirmation'
import AlertModalTwoButton from '../../components/Modals/AlertModalTwoButton'
import { AssociationSettingsRepository, ProfileRepository } from '../../repositories'
import { Profile, ProfilePk, TimeBasedDocInstance } from '../../models'
import {
  FieldConfig,
  Group,
  GroupMemberProfile,
  PpeActionRecordType,
  PpeForProfile,
  PpeInstance,
  PpeSelections,
  SubGroups,
} from '../../types'
import { ErrorCodeTypes, PpeActions } from '../../enums'
import { GroupMembers as GroupMembersType } from '../../types'
import { fetchProfileData } from '../../provider/remoteData'

/*
  @NICKI: We really need to break up this screen/component into various services/repos/subcomponents. There is way too much happening. Let's discuss in standup.
*/

type RouteParams = { groupId: string }
type GroupMembersProps = RouteComponentProps

const ppeService = new PPEService()

const GroupMembers = (props: GroupMembersProps) => {
  const allGroupsRef = useRef<Group[]>([])
  const primaryTableRef = useRef<DataTable>()
  const allPpeInstancesRef = useRef<PpeInstance[]>([])

  const { groupId } = useParams() as RouteParams // group name passed as route param
  const history = useHistory()
  const match = useRouteMatch()
  const location = useLocation()

  const userIdPassport = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.idPassport)
  const userPassword = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.password)
  const selectedAssociation = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedAssociation,
  )
  const selectedRole = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.selectedRole)
  const selectedCohort = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.selectedCohort)
  const profileRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.profileRepo,
  ) as ProfileRepository
  const hasWorkforceAccess = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.hasWorkforceAccess)
  const associationRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.associationRepo,
  ) as AssociationSettingsRepository
  const userRepo = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.userRepo)

  const [groupMemberProfiles, setGroupMemberProfiles] = useState<GroupMemberProfile[]>([])
  const [searchString, setSearchString] = useState('')
  const [loadingModalOpen, setLoadingModalOpen] = useState(true)
  const [expiryDateModalOpen, setExpiryDateModalOpen] = useState(false)
  const [jobTypeModalOpen, setJobTypeModalOpen] = useState(false)
  const [unavailableItemsWarningModalOpen, setUnavailableItemsWarningModalOpen] = useState(false)
  const [confirmStockRequestModalOpen, setConfirmStockRequestModalOpen] = useState(false)
  const [confirmPpeSelectionModalOpen, setConfirmPpeSelectionModalOpen] = useState(false)
  const [unavailableItemsList, setUnavailableItemsList] = useState<Record<string, any>>({})
  const [loadingModalMessage, setLoadingModalMessage] = useState('Loading data...')
  const [selectedMemberIds, setSelectedMemberIds] = useState<string[]>([])
  const [selectedSubGroup, setSelectedSubGroup] = useState('All')
  const [groupMemberPpeSelection, setGroupMemberPpeSelection] = useState<GroupMemberPpeProps | {}>({})
  const [ppeSelectionModeEnabled, setPpeSelectionModeEnabled] = useState(false)
  const [requestedPpe, setRequestedPpe] = useState<PpeSelections>({})
  const [selectedPpe, setSelectedPpe] = useState<PpeForProfile>({})
  const [selectedGroup, setGroup] = useState<Group>()
  const [detailsRefreshTimestamp, setDetailsRefreshTimestamp] = useState(Date.now())

  useEffect(() => {
    getPpeItems()
    initialise()
  }, [groupId, selectedAssociation])

  useEffect(() => {
    if (primaryTableRef.current) {
      primaryTableRef.current.reload()
    }
  }, [groupMemberProfiles])

  useEffect(() => {
    if (selectedGroup) {
      loadProfileData()
    }
  }, [selectedGroup, GroupService.getNumberOfRequestedPpe(requestedPpe) /*, allProfiles*/])

  useEffect(() => {
    if (ppeSelectionModeEnabled && isEmpty(groupMemberPpeSelection)) {
      setPpeSelectionModeEnabled(false)
    }
  }, [ppeSelectionModeEnabled, groupMemberPpeSelection])

  useEffect(() => {
    if (loadingModalOpen === false && loadingModalMessage !== '') {
      setLoadingModalMessage('')
    }
  }, [loadingModalOpen])

  const subGroups = selectedGroup?.subGroups as SubGroups

  const getPpeItems = async () => {
    try {
      const response = await ppeService.getItems(selectedAssociation, {
        username: userIdPassport,
        password: userPassword,
      })
      const allPpeInstances = Object.values(response)
      allPpeInstancesRef.current = allPpeInstances
    } catch (error) {
      console.error('PPE instances not loaded', error)
    }
  }

  const toggleExpiryDateModal = () => {
    setExpiryDateModalOpen((expiryDateModalOpen) => !expiryDateModalOpen)
  }

  const toggleJobTypeModal = () => {
    setJobTypeModalOpen((jobTypeModalOpen) => !jobTypeModalOpen)
  }

  const initialise = async () => {
    const groupData = await initGroupData()
    const groupMemberIds = Object.keys(groupData.members)
    await updateCachedMemberProfileData(selectedAssociation, groupMemberIds)
    await initGroupMemberValidCompentencies(groupData)
    setGroup(groupData)
  }

  const initGroupData = async (): Promise<Group> => {
    const token = await SessionService.prepareAuthTokens(userIdPassport, userPassword)
    const groupData = await GroupService.fetchGroup(selectedAssociation, selectedCohort, groupId, token)
    return groupData
  }

  const refreshGroup = async () => {
    const token = await SessionService.prepareAuthTokens(userIdPassport, userPassword)
    const groupData = await GroupService?.fetchGroup(selectedAssociation, selectedCohort, groupId, token)
    setGroup(groupData)
    return groupData
  }

  const toggleConfirmStockRequestModal = () => {
    setConfirmStockRequestModalOpen((confirmStockRequestModalOpen) => !confirmStockRequestModalOpen)
  }

  const initGroupMemberValidCompentencies = async (groupData: Group) => {
    if (groupData && !isEmpty(groupData.members)) {
      let updatedGroup = cloneDeep(groupData)
      const token = await SessionService.prepareAuthTokens(userIdPassport, userPassword)
      await Promise.all(
        Object.keys(updatedGroup.members).map(async (memberPk: string) => {
          const validDocs: TimeBasedDocInstance[] = await GroupService.getAllValidCompetencyDocsPerMember(
            memberPk,
            selectedAssociation,
            selectedCohort,
            token,
          )
          updatedGroup.members[memberPk].validTimeBasedDocs = validDocs.map((validDoc) => validDoc.getCompetency())
          return updatedGroup.name
        }),
      )
      await saveGroup(updatedGroup)
    }
  }

  const saveGroup = async (updatedGroup: Group) => {
    if (GroupService) {
      const token = await SessionService.prepareAuthTokens(userIdPassport, userPassword)
      await GroupService.postGroup(selectedAssociation, updatedGroup, token)
    }
    return
  }

  const saveNewJobTypesToSelectedMembers = async (jobType: string) => {
    if (selectedGroup) {
      let updatedGroup = cloneDeep(selectedGroup)
      selectedMemberIds.forEach((idPassport) => {
        const member = updatedGroup.members[idPassport]
        if (member) {
          member.jobType = jobType
        }
      })
      await saveGroup(updatedGroup)
      setGroup(updatedGroup)
    }
    setSelectedMemberIds([])
    toggleJobTypeModal()
    setDetailsRefreshTimestamp(Date.now())
  }

  const updateCachedMemberProfileData = (selectedAssociation: string, pksToFetch: string[]): Promise<void> => {
    return SessionService.prepareAuthTokens(userIdPassport, userPassword)
      .then((token: string) => fetchProfileData(selectedAssociation, pksToFetch, token))
      .catch((err) => {
        console.error(err)
        return {}
      })
      .then((newProfiles: Record<string, Profile>) => {
        const updatedProfiles = profileRepo.updateProfiles(newProfiles)
        // setAllProfiles(updatedProfiles)
        persistCurrentProfileData(newProfiles, selectedAssociation)
      })
  }

  const getHoverDetails = (rowData: GroupMemberProfile, memberJobType: string) => {
    const docsRequired = GroupService.getDocRequirementsNotSatisfied(
      rowData.id,
      memberJobType,
      associationRepo,
      selectedAssociation,
      selectedGroup,
    )
    const groupsBelongingTo = getNonExpiredGroupsBelongingTo(rowData.id)
    const belongsToMultipleGroups = groupsBelongingTo.length > 0

    let hoverDetails = {}
    if (docsRequired.length > 0) {
      hoverDetails = {
        ...hoverDetails,
        onHoverMessage: (
          <JobRequirementsNotSatisfied
            docsRequired={docsRequired}
            belongsToMultipleGroups={belongsToMultipleGroups}
            groupsBelongingTo={groupsBelongingTo}
          />
        ),
        hoverMessageType: 'error',
      }
    } else if (belongsToMultipleGroups) {
      hoverDetails = {
        ...hoverDetails,
        onHoverMessage: <AssignedTo groupsBelongingTo={groupsBelongingTo} />,
        hoverMessageType: 'info',
      }
    }

    return hoverDetails
  }

  const getProfileDataWithHoverMessagesAndStatusIndicators = (rowData: GroupMemberProfile) => {
    let data: GroupMemberProfile & {
      onHoverMessage?: string | React.ReactElement
      hoverMessageType?: 'info' | 'error'
      rightComponent?: React.ReactElement
    } = {
      ...rowData,
    }
    const memberJobType = GroupService.getMemberJobType(rowData.id, selectedGroup) // id equals member pk
    if (memberJobType) {
      const hoverDetails = getHoverDetails(rowData, memberJobType)
      data = {
        ...data,
        ...hoverDetails,
        rightComponent: generateMemberStatusIndicator(data.startDate, data.terminationDate),
      }
    }
    return data
  }

  const loadProfileData = async () => {
    setLoadingModalOpen(true)
    if (selectedGroup && selectedGroup.members) {
      if (selectedAssociation !== profileRepo.getAssociation()) {
        throw new Error(ErrorCodeTypes.ASSOCIATION_MISMATCH)
      }

      const profiles = profileRepo.getAllProfiles()
      const profilesNotLoaded = /*clientProfileCache === undefined ||*/ Object.keys(profiles).length === 0
      // if (profilesNotLoaded) {
      // clientProfileCache = await refreshProfilesFromIndexedDb(
      //   clientProfileCache,
      //   selectedAssociation,
      //   selectedCohort,
      //   associationRepo,
      //   setAllProfiles,
      // )
      // }
      const groupMemberProfiles = new Map<string, Profile>()
      Object.keys(selectedGroup.members).forEach((pk) => {
        const profile = profileRepo.getProfile(pk)
        if (profile) {
          groupMemberProfiles.set(pk, profile)
        }
      })
      updateProfileToDisplay(groupMemberProfiles)
    }
    setLoadingModalOpen(false)
    setDetailsRefreshTimestamp(Date.now())
    // return new Promise<void>((res) => res())
  }

  const updateProfileToDisplay = (groupMemberProfiles: any) => {
    const profileData: GroupMemberProfile[] = prepareProfileDataForDisplay(groupMemberProfiles)
    const profilesToDisplay = profileData.map(getProfileDataWithHoverMessagesAndStatusIndicators)
    setGroupMemberProfiles(profilesToDisplay)
  }

  // const getSelectedPpeCount = (ppeKeys: string[], profilePk: string): number => {
  //   const profile = allProfiles.getProfile(selectedAssociation, profilePk) as IProfile
  //   return GroupService.getSelectedPpeCountFromGeneralData(profile, ppeKeys)
  // }

  const getPpeSelectedCount = (profilePk: ProfilePk) => {
    // const allDataFlows = userProfile.getAllDataFlows(selectedAssociation)

    if (requestedPpe[profilePk]) {
      const requestedPpeValues = Object.values(requestedPpe[profilePk])
      const nonEmptyPpeValues = requestedPpeValues.filter((value) => value && value !== '')
      return nonEmptyPpeValues.length
    }

    return 0
    // const ppeIssueDataFlow = GroupService.getPpeSizeSelectionDataFlow(selectedAssociation, selectedCohort, userProfile)
    // const requiredPpeItems = allDataFlows[ppeIssueDataFlow]?.dataFlow.ppeIssue.map((item: any) => item.key)
    // return getSelectedPpeCount(requiredPpeItems, profilePk)
  }

  const renderRow = (profile: any) => {
    const pk = profile[0]
    const value = profile[1]
    let { name, surname, idPassport } = value.getPersonalInfo()

    // Resorting to this ugly brute force approach after struggling to get types working in a forEach loop
    if (Array.isArray(name) && name.length) {
      name = name[0]
    }
    if (Array.isArray(surname) && surname.length) {
      surname = surname[0]
    }
    if (Array.isArray(idPassport) && idPassport.length) {
      idPassport = idPassport[0]
    }

    const groupMember = selectedGroup?.members[pk]
    const groupMemberJobType = groupMember ? groupMember.jobType : undefined
    const groupMemberProposedStartDate = groupMember ? dateObjToString(groupMember.proposedStartDate) : undefined
    const groupMemberProposedTerminationDate = groupMember
      ? dateObjToString(groupMember.proposedTerminationDate)
      : undefined

    const ppeSelectedCount = getPpeSelectedCount(pk)
    const ppeIssuedAndNotReturned = getPpeInPossession(profileRepo, pk)
    const ppeIssuedCount = Object.keys(ppeIssuedAndNotReturned).length

    let returnData = {
      id: pk,
      name: name,
      surname: surname,
      idPassport,
      jobType: removeUnderscores(groupMemberJobType) || '-',
      startDate: groupMemberProposedStartDate || '-',
      terminationDate: groupMemberProposedTerminationDate || '-',
      ppeStatus: (
        <div style={{ textAlign: 'left' }}>
          {`Issued (${ppeIssuedCount})`}
          <br />
          {`Selected (${ppeSelectedCount})`}
        </div>
      ),
    }
    return returnData
  }

  const prepareProfileDataForDisplay = (allProfiles: Map<string, Profile> | undefined): GroupMemberProfile[] => {
    if (allProfiles === undefined) {
      return []
    }
    let allProfilesArray = [...allProfiles]
    const allProfilesToRender = allProfilesArray.filter((profile) => {
      const value = profile[1]
      if (value) {
        let generalData: any = value.getGeneralData()
        if (generalData === undefined) {
          generalData = { employmentStatus: 'NONE' }
        }
        if (!value.isPartOfCohort(selectedCohort)) {
          return false
        }

        const { employmentStatus, name, surname, idPassport } = generalData
        if (name && surname && idPassport && ['CANDIDATE', 'EMPLOYEE'].indexOf(employmentStatus) > -1) {
          return true
        }
      }
      return false
    })

    return allProfilesToRender.map(renderRow)
  }

  const searchHandler = (event: React.ChangeEvent<{ value: string }>) => {
    setSearchString(event.target.value)
    if (!primaryTableRef?.current) {
      return
    }
    primaryTableRef.current.search(event.target.value)
  }

  const removeSelectedGroupMembers = async () => {
    setLoadingModalMessage('Refreshing member data...')
    setLoadingModalOpen(true)
    if (selectedGroup) {
      const remainingMembers = Object.keys(selectedGroup.members).reduce(
        (acc: GroupMembersType, idPassport: string) => {
          if (!selectedMemberIds.includes(idPassport)) {
            acc[idPassport] = selectedGroup.members[idPassport]
          }
          return acc
        },
        {},
      )
      let updatedGroup = { ...selectedGroup, members: cloneDeep(remainingMembers) }
      await saveGroup(updatedGroup)
      setGroup(updatedGroup)
    }
    setLoadingModalOpen(false)
    setSelectedMemberIds([])
  }

  const anySelectedMemberIds = selectedMemberIds.length > 0

  const getNonExpiredGroupsBelongingTo = (idPassport: string) => {
    if (selectedGroup && allGroupsRef && allGroupsRef.current) {
      const allGroups = allGroupsRef.current
      const allNonExpiredGroups = allGroups.filter((selectedGroup: Group) => selectedGroup.endDate >= new Date())
      const otherGroups = allNonExpiredGroups.filter((otherGroup: Group) => selectedGroup.id !== otherGroup?.id)
      let groups = []
      for (const otherGroup of otherGroups) {
        if (otherGroup.members) {
          for (const memberIdPassport of Object.keys(otherGroup.members)) {
            if (memberIdPassport === idPassport) {
              groups.push(otherGroup.name)
            }
          }
        }
      }
      return groups
    }
    return []
  }

  const checkIfSubGroupSelected = () => selectedSubGroup !== 'All'

  const getSelectedMembersMinStartDate = () => {
    const minStartDate: Date = GroupService.getGroupMembersMinStartDate(selectedMemberIds, selectedGroup)
    return minStartDate
  }

  const getSelectedMembersMaxExpiryDate = (expiryDate?: Date) => {
    if (selectedGroup) {
      const groupMembers = selectedGroup.members || []
      const numberOfSelectedGroupMembers = selectedMemberIds.length
      let groupStartDate = selectedGroup.startDate || new Date()
      let groupEndDate = selectedGroup.endDate || new Date()
      let minGroupMemberEndDate = new Date(groupEndDate)

      if (numberOfSelectedGroupMembers === 1) {
        const selectedIdPassport = selectedMemberIds[0]
        const selectedGroupMember = groupMembers[selectedIdPassport]
        if (selectedGroupMember) {
          const groupMemberEndDate = expiryDate || selectedGroupMember.proposedTerminationDate
          if (
            groupMemberEndDate &&
            groupMemberEndDate > groupStartDate &&
            groupMemberEndDate < minGroupMemberEndDate &&
            groupMemberEndDate < groupEndDate
          ) {
            minGroupMemberEndDate = groupMemberEndDate
          }
          return minGroupMemberEndDate
        }
      }
      return groupEndDate
    }
    return new Date()
  }

  const handleDateSelection = async (startDate?: Date, expiryDate?: Date) => {
    setLoadingModalMessage('Saving changes..')
    setLoadingModalOpen(true)

    const newStartDate = dateObjToString(startDate)
    const newTerminationDate = dateObjToString(expiryDate)
    const updatedGroup = cloneDeep(selectedGroup)
    await Promise.all(
      selectedMemberIds.map((idPassport) => {
        // const profile = allProfiles.getProfile(selectedAssociation, idPassport)
        // const userInfo = userProfile.getPersonalUserInfo()
        // const oldStartDate = profile?.getGeneralData().startDate;
        // const oldTerminationDate = profile?.getGeneralData().terminationDate;
        let changes = {}
        if (selectedGroup) {
          const groupMember = updatedGroup?.members[idPassport]
          if (groupMember) {
            const oldStartDate = dateObjToString(groupMember.proposedStartDate)
            const oldTerminationDate = dateObjToString(groupMember.proposedTerminationDate)
            if (newStartDate && oldStartDate !== newStartDate) {
              // changes = { ...changes, startDate: { old: oldStartDate, new: newStartDate } };
              // profile?.updateGeneralData({ startDate: newStartDate });
              if (groupMember) {
                groupMember.proposedStartDate = new Date(newStartDate)
              }
            }
            if (newTerminationDate && oldTerminationDate !== newTerminationDate) {
              // changes = { ...changes, terminationDate: { old: oldTerminationDate, new: newTerminationDate } };
              // profile?.updateGeneralData({ terminationDate: newTerminationDate });
              if (groupMember) {
                groupMember.proposedTerminationDate = new Date(newTerminationDate)
              }
            }
            //@ts-ignore
            return saveGroup(updatedGroup)
          }
        }
        // NOTE: temp commented out to prevent persisting changes to candidate/employee records
        // if (!isEmpty(changes) && profile) {
        //     return profileService.saveProfileChanges(selectedAssociation, changes, profile, userInfo);
        // }
        console.warn('UNEXPECTED; how did we get here?')
        return
      }),
    )
    setLoadingModalOpen(false)
    await handleDateSelectionSaved()
  }

  const handleDateSelectionSaved = async () => {
    setSelectedMemberIds([])
    await refreshGroup()
    toggleExpiryDateModal()
    setDetailsRefreshTimestamp(Date.now())
  }

  const getSelectedMemberJobType = () => {
    if (selectedGroup) {
      const groupMembers = selectedGroup.members || []
      const numberOfSelectedGroupMembers = selectedMemberIds.length
      if (numberOfSelectedGroupMembers === 1) {
        const selectedIdPassport = selectedMemberIds[0]
        const selectedGroupMember = groupMembers[selectedIdPassport]
        if (selectedGroupMember) {
          return selectedGroupMember.jobType
        }
      }
    }
    return ''
  }

  const getActionButtons = () => {
    let actionButtons: ActionButtonType[] = []
    const subGroupSelected = checkIfSubGroupSelected()
    if (!groupCompleted) {
      if (!isEmpty(groupMemberPpeSelection)) {
        return actionButtons
      } else if (subGroupSelected) {
        actionButtons = [
          {
            iconPath: mdiNotePlusOutline,
            iconColor: ColorPalette.PRIMARY_BLUE,
            onClick: toggleConfirmStockRequestModal,
            label: 'CREATE REQUEST',
            title: 'Create request for PPE',
          },
        ]
      } else {
        const iconColour = anySelectedMemberIds ? ColorPalette.PRIMARY_BLUE : ColorPalette.MEDIUM_GREY
        const iconStyle = anySelectedMemberIds ? {} : { cursor: 'not-allowed' }

        actionButtons = [
          {
            iconPath: mdiPlus,
            iconColor: ColorPalette.PRIMARY_BLUE,
            onClick: () => history.push(`/people/groups/${groupId}/add`),
            label: 'ADD',
            title: 'Add member(s)',
          },
          {
            iconPath: mdiDelete,
            iconColor: iconColour,
            onClick: removeSelectedGroupMembers,
            label: 'REMOVE',
            disabled: !anySelectedMemberIds,
            style: iconStyle,
          },
          {
            iconPath: mdiPencil,
            iconColor: iconColour,
            onClick: toggleJobTypeModal,
            label: 'CHANGE JOB TYPE',
            title: 'Set job type on selected records',
            disabled: !anySelectedMemberIds,
            style: iconStyle,
          },
          {
            iconPath: mdiTimerSand,
            iconColor: iconColour,
            onClick: toggleExpiryDateModal,
            label: 'SET DATE RANGE',
            title: 'Set date range on selected records',
            disabled: !anySelectedMemberIds,
            style: iconStyle,
          },
        ]
      }
    }
    return actionButtons
  }

  const handlePpeSelections = (selectionsPending: PpeForProfile) => {
    setSelectedPpe(selectionsPending)
  }

  const resetSelections = () => {
    setSelectedPpe({})
    setSelectedMemberIds([])
    setGroupMemberPpeSelection({})
    setPpeSelectionModeEnabled(false)
  }

  const handlePpeSelectionDiscard = () => {
    resetSelections()
    setConfirmPpeSelectionModalOpen(false)
  }

  const handlePpeSelectionSave = () => {
    setConfirmPpeSelectionModalOpen(false)
    let profilePk = (groupMemberPpeSelection as GroupMemberPpeProps).profilePk
    let updatedRequestedPpe = cloneDeep(requestedPpe)
    updatedRequestedPpe[profilePk] = selectedPpe
    resetSelections()
    setRequestedPpe(updatedRequestedPpe)
  }

  const createPpeRequest = async () => {
    const user = userRepo?.getCurrentUserEntity()
    if (!user) {
      return
    }
    const record: Partial<PpeActionRecordType> = createActionRecord({
      ppeAction: PpeActions.SELECT_SIZE,
      association: selectedAssociation,
      division: selectedGroup?.division,
      dateActioned: Date.now(),
      requestedPpeItems: GroupService.extractRequestedPpeItems(requestedPpe),
      sourceName: user.getName(),
      sourceSurname: user.getSurname(),
      sourcePk: userIdPassport,
      seedGroupId: GroupService.getSubGroupIdByDisplayName(subGroups, 'ppe', selectedSubGroup),
    })
    // const actionRecord = new PpeActionRecord(data)
    // const actionRecordObj = classToObject(actionRecord)
    // console.log('newActionRecord: ', actionRecordObj)
    return ppeService.uploadActionRecord(selectedAssociation, record, {
      username: userIdPassport,
      password: userPassword,
    })
  }

  const generateAndDisplayUnavailableItemsWarning = (unavailablePpeItems: Record<ProfilePk, any>) => {
    const uniqueUnavailablePpeItems: Record<string, number> = {}
    for (const [profilePk, itemsObj] of Object.entries(unavailablePpeItems)) {
      for (const [ppeItemKey, sizeObj] of Object.entries(itemsObj)) {
        // @ts-ignore
        const selectedSizes = sizeObj.selectedSizes
        let itemId = ''
        selectedSizes.forEach((record: { ppeSize: string; qty: number }) => {
          itemId = `${ppeItemKey} - ${record.ppeSize}`
          if (uniqueUnavailablePpeItems[itemId]) {
            uniqueUnavailablePpeItems[itemId] += record.qty
          } else {
            uniqueUnavailablePpeItems[itemId] = record.qty
          }
        })
      }
    }
    setUnavailableItemsList(uniqueUnavailablePpeItems)
    setUnavailableItemsWarningModalOpen(true)
  }

  const handleNewPpeRequest = async () => {
    toggleConfirmStockRequestModal()
    setLoadingModalMessage('Creating new PPE request...')
    setLoadingModalOpen(true)
    try {
      const response = await createPpeRequest()
      if (response.unavailablePpeItems) {
        const unavailablePpeItems: Record<string, any> = response.unavailablePpeItems
        generateAndDisplayUnavailableItemsWarning(unavailablePpeItems)
      } else {
        history.push('/requests/pperequests')
      }
    } catch (error) {
      console.error('handleNewPpeRequest', error)
    }
    setLoadingModalOpen(false)
  }

  const getPageTitle = (subGroupTabSelected: boolean, ppeSelectionModeEnabled: boolean) => {
    let title = selectedGroup ? selectedGroup.name : 'Manage Group Members'
    if (subGroupTabSelected) {
      title = ppeSelectionModeEnabled ? 'Select PPE' : 'Request PPE'
    }
    return title
  }

  const reloadPage = () => {
    setSearchString('')
    loadProfileData()
  }

  const handleBackButtonClick = () => {
    function hasPpeSelection(selectedPpe: PpeForProfile = {}): boolean {
      return Object.values(selectedPpe).some((value) => value.length > 0)
    }

    function requirePpeSelectionConfirmation(
      profilePk: string,
      requestedPpe: PpeSelections,
      selectedPpe: PpeForProfile,
    ): boolean {
      const profileRequestedPpe = requestedPpe[profilePk]
      const newSelection = profileRequestedPpe === undefined
      if (newSelection) {
        return hasPpeSelection(selectedPpe)
      }
      return !isEqual(profileRequestedPpe, selectedPpe)
    }

    let profilePk = (groupMemberPpeSelection as GroupMemberPpeProps).profilePk
    if (requirePpeSelectionConfirmation(profilePk, requestedPpe, selectedPpe)) {
      setConfirmPpeSelectionModalOpen(true)
      return
    }
    if (ppeSelectionModeEnabled) {
      setGroupMemberPpeSelection({})
      setPpeSelectionModeEnabled(false)
    } else {
      history.replace('/people/groups')
    }
  }

  const handleDetailsRowSelect = (selectedRows: any) => setSelectedMemberIds(Object.keys(selectedRows))

  const handleDetailsRowClick = (selectedRowData: Record<string, any>) => {
    const { id, jobType, startDate, location, name, surname, idPassport } = selectedRowData
    setGroupMemberPpeSelection({
      profilePk: id,
      jobType,
      scheduledStartDate: startDate,
      location,
      name,
      surname,
      idPassport,
    })
    setPpeSelectionModeEnabled(true)
  }

  const handleSideMenuItemSelection = (option: string) => {
    setPpeSelectionModeEnabled(false)
    setSelectedSubGroup(option)
  }

  const groupCompleted = selectedGroup && new Date() > selectedGroup.endDate
  let sideMenu = null
  if (!groupCompleted) {
    sideMenu = (
      <SideMenu
        visible={true}
        menuComponents={
          <GroupMembersSideMenu
            itemNames={GroupService.getSubGroupNames(subGroups, 'ppe')}
            selectedItemName={selectedSubGroup}
            onItemSelection={handleSideMenuItemSelection}
          />
        }
      />
    )
  }

  let jobTypeModal = null
  if (selectedGroup && jobTypeModalOpen) {
    jobTypeModal = (
      <GroupMemberJobTypeModal
        group={selectedGroup}
        initialJobType={getSelectedMemberJobType()}
        onClick={saveNewJobTypesToSelectedMembers}
        onDismiss={toggleJobTypeModal}
      />
    )
  }

  const getPageDetails = (
    isSubGroupSelected: boolean,
    ppeSelectionModeEnabled: boolean,
    selectedGroupCategory = 'ppe',
  ) => {
    const profilePk = (groupMemberPpeSelection as GroupMemberPpeProps).profilePk
    return (
      <GroupMemberDetails
        memberProfiles={groupMemberProfiles}
        selectedGroup={selectedGroup}
        selectedSubGroup={selectedSubGroup}
        subGroupSelected={isSubGroupSelected}
        selectedGroupCategory={selectedGroupCategory}
        groupMemberPpeSelection={groupMemberPpeSelection}
        groupCompleted={!!groupCompleted}
        loadingModalOpen={loadingModalOpen}
        ppeSelectionModeEnabled={ppeSelectionModeEnabled}
        allPpeInstances={allPpeInstancesRef.current}
        ppeSelections={requestedPpe[profilePk]}
        setPpeSelections={(selectionsPending: Record<string, string>) => handlePpeSelections(selectionsPending)}
        getNonExpiredGroupsBelongingTo={getNonExpiredGroupsBelongingTo}
        onRowSelect={handleDetailsRowSelect}
        onRowClick={handleDetailsRowClick}
        // onPpeSelectionSave={handlePpeSelectionSave}
        key={`groupMemberDetails-${profilePk}_${detailsRefreshTimestamp}`}
      />
    )
  }

  // const anyChangesMade = (): boolean => {
  //   const ppeSelectionModeProfileId = (groupMemberPpeSelection as GroupMemberPpeProps).profilePk
  //   if (ppeSelectionModeEnabled) {
  //     const selectedSizesObj = requestedPpe[ppeSelectionModeProfileId]
  //     // return Object.values(selectedSizesObj).some((value) => value.length > 0)
  //   }
  //   return false
  // }

  const isSubGroupSelected = checkIfSubGroupSelected()
  const title = getPageTitle(isSubGroupSelected, ppeSelectionModeEnabled)
  const pageContent = getPageDetails(isSubGroupSelected, ppeSelectionModeEnabled)
  const initialStartDate = getSelectedMembersMinStartDate()
  const initialExpiryDate = getSelectedMembersMaxExpiryDate()
  const numberOfRequestedPpe = GroupService.getNumberOfRequestedPpe(requestedPpe)
  const numberOfPeopleWithRequestedPpe = Object.keys(requestedPpe).length
  const actionButtons = getActionButtons()

  let warningModal = null
  if (unavailableItemsWarningModalOpen) {
    const fieldConfig = associationRepo.getFieldConfig(selectedAssociation)
    warningModal = (
      <AlertModalOneButton
        open={true}
        header="Unavailable Items"
        body={<UnavailableItemListMessage unavailableItemsList={unavailableItemsList} fieldConfig={fieldConfig} />}
        buttonLabel="Ok"
        onClick={() => setUnavailableItemsWarningModalOpen(false)}
      />
    )
  }

  let confirmStockRequestModal = null
  if (confirmStockRequestModalOpen) {
    if (numberOfRequestedPpe === 0) {
      confirmStockRequestModal = (
        <AlertModalOneButton
          open={true}
          header="No PPE selected"
          body="Please select PPE items for one or more members to create request."
          buttonLabel="Ok"
          onClick={toggleConfirmStockRequestModal}
        />
      )
    } else {
      confirmStockRequestModal = (
        <AlertModalTwoButton
          open={true}
          dismiss={toggleConfirmStockRequestModal}
          onClick1={toggleConfirmStockRequestModal}
          onClick2={handleNewPpeRequest}
          header="Are you sure?"
          buttonLabel1="Back"
          buttonLabel2="Yes, Continue"
          buttonDisabled2={false} // no stock items selected?
          buttonStyle={{ marginTop: 30 }}
          body={
            <RequestedPpeConfirmation
              numberOfRequestedPpe={numberOfRequestedPpe}
              numberOfPeopleWithRequestedPpe={numberOfPeopleWithRequestedPpe}
            />
          }
        />
      )
    }
  }

  const { permissionResult } = associationRepo.getUserAccess(selectedAssociation, selectedRole)
  const hasPpeAccess =
    permissionResult?.hasPpeIssueRequestCreationAccess || permissionResult?.hasPpeStockManagementAccess
  return (
    <div style={{ ...styles.container }}>
      <NavigationBar match={match} location={location} history={history} reloadPageData={reloadPage} />
      <SectionHeader
        style={styles.sectionHeader}
        searchString={searchString}
        textHandler={(e) => searchHandler(e)}
        onClick={() => null}
        key={`sectionHeader_${selectedAssociation}`}>
        {title}
      </SectionHeader>

      <div style={styles.contentContainer}>
        {hasPpeAccess ? sideMenu : null}
        <div style={styles.rightSide}>
          <Toolbar
            actionButtons={actionButtons}
            navButtons={{ left: { onClick: handleBackButtonClick } }}
            key={selectedSubGroup}
          />
          <div style={styles.rightSideContent}>{pageContent}</div>
        </div>
      </div>
      <AlertModalOneButton
        open={!hasWorkforceAccess}
        header="Not Authorised"
        body="You don't have permission to view employee/candidate info."
        buttonLabel="Ok"
        opaqueBackground={true}
        onClick={() => history.goBack()}
      />
      <GroupMemberExpiryDateModal
        open={!!(selectedGroup && expiryDateModalOpen)}
        group={selectedGroup}
        initialStartDate={initialStartDate}
        initialExpiryDate={initialExpiryDate}
        toggle={toggleExpiryDateModal}
        onDateSelection={handleDateSelection}
        key={`groupMemberExpiryDateModal_${initialStartDate}-${initialExpiryDate}`}
      />
      <LoadingModal open={loadingModalOpen}>{loadingModalMessage}</LoadingModal>
      {jobTypeModal}
      {warningModal}
      {confirmStockRequestModal}
      <AlertModalTwoButton
        open={confirmPpeSelectionModalOpen}
        dismiss={() => setConfirmPpeSelectionModalOpen(false)}
        onClick1={handlePpeSelectionDiscard}
        onClick2={handlePpeSelectionSave}
        header="Save selection(s)?"
        buttonLabel1="Discard"
        buttonLabel2="Save"
        buttonDisabled2={false}
        buttonStyle={{ marginTop: 30 }}
        body="PPE selection changes have been made"
      />
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255, 1), rgba(209,210,230, 1))',
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto 1%',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    paddingInline: 'min(2em, 2%)',
    width: '100%',
    overflow: 'hidden',
  },
  rightSideContent: {
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    display: 'flex',
    flex: 1,
    backgroundColor: ColorPalette.CARD_WHITE,
  },
}

export default GroupMembers

type UnavailableItemListMessageProps = {
  unavailableItemsList: Record<string, any>
  fieldConfig: FieldConfig
}

const UnavailableItemListMessage = (props: UnavailableItemListMessageProps) => {
  const { fieldConfig, unavailableItemsList } = props
  return (
    <div style={{ textAlign: 'center' }}>
      The following items are not in stock:
      <div style={{ marginTop: '1em', marginBottom: '1.5em' }}>
        {Object.keys(unavailableItemsList).map((item, index) => {
          const itemKey = item.split(' - ')[0]
          const itemName = fieldConfig?.[itemKey]?.label || itemKey
          const itemQty = unavailableItemsList[item]
          return <div>{`${itemQty} x ${item.replace(itemKey, itemName)}`}</div>
        })}
      </div>
      Update stock and try again.
    </div>
  )
}
