import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import Icon from '@mdi/react'
import { mdiInformationOutline, mdiContentDuplicate, mdiTrashCan, mdiRefresh, mdiContentSave, mdiExport } from '@mdi/js'
import { cloneDeep, flatten, set } from 'lodash'
import Radium from 'radium'

import NavigationBar from '../../components/Navigation/NavigationBar'
import SectionHeaderPrimary from '../../components/Headings/SectionHeaderPrimary'
import SideMenu from '../../components/Navigation/SideMenu'
import { ActionButtonType, Toolbar } from '../../components/GeneralUI/Toolbar'
import LoadingModal from '../../components/Modals/LoadingModal'
import LabelCollector from '../../components/Modals/LabelCollector'
import AlertModalTwoButton from '../../components/Modals/AlertModalTwoButton'
import AlertModalOneButton from '../../components/Modals/AlertModalOneButton'
import { ColorPalette } from '../../config/colors'
import { PeopleFlowCombinedReducer } from '../../store'
import { AssociationSettingsRepository } from '../../repositories'
import DataQueriesSideMenu from '../../components/SideMenus/DataQueriesSideMenu'
import TabNavigator from '../../components/Navigation/TabNavigator'
import { DataQueryFilteredResults, DataQuerySettings } from '../../components/Configurator/DataQueries'
import { CsvService, QueryService } from '../../services'
import { GraphConfigurator } from '../../components/Modals/DataQuery'
import { DataQueryTrends } from '../../components/Configurator/DataQueries/DataQueryTrends'
import { IColumnConfig } from '../../components/Tables/DataTable/DataTableHeader'

import {
  AllQueryResults,
  CustomTimePeriod,
  DocFilterParams,
  Query,
  QueryFilter,
  QueryId,
  QueryResult,
  QueryResults,
  QueryTrend,
  ResultExportTargetEmails,
  TimePeriod,
  TrendConfig,
} from '../../types'
import { CommonUnixMsEnum, DataQueryErrorCodesEnum } from '../../enums/index'
import { FilterTypeEnum } from '../../enums'
import InfoCollectorModal from '../../components/Modals/InfoCollector'
import { checkEmailFormat, normaliseStringForComparison, toLowerCaseCustom } from '../../utils'
import { UserRepository } from '../../repositories'
import { getRemoteFile } from '../../provider/remoteData'

type ModalState = {
  [key: string]: boolean
}

const initialModalState: ModalState = {
  deleteConfirmModalOpen: false,
  addModalOpen: false,
  renameModalOpen: false,
  loadingModalOpen: false,
  savingModalOpen: false,
  alertOneButtonModalOpen: false,
  graphConfiguratorModalOpen: false,
  emailCollectorModalOpen: false,
}

type DataQueriesProps = {
  match: any
  location: any
  history: any
}

const TABS = {
  SETTINGS: 'SETTINGS',
  RESULTS: 'RESULTS',
  TRENDS: 'TRENDS',
}

const DataQueries = (props: DataQueriesProps) => {
  const tabbedNavigatorRef = useRef()
  const containerRef = useRef<HTMLDivElement>()
  const resultsIntervalRef = useRef<Record<QueryId, number>>({})

  const [selectedQuery, setSelectedQuery] = useState<Query | undefined>(undefined)
  const [currentTabName, setCurrentTabName] = useState(TABS.SETTINGS)
  const [modalState, setModalState] = useState<ModalState>(initialModalState)
  const [queries, setQueries] = useState<Query[]>([])
  const [allQueryResults, setAllQueryResults] = useState<AllQueryResults>({})
  const [triggerUnixMs, setTriggerUnixMs] = useState<number>(0)
  const [refreshSyncIndicatorUnixMs, setRefreshSyncIndicatorUnixMs] = useState<number>(0) // NOTE: this state variable is used nowhere explicitly but the setting of it alongside the updating of resultsIntervalRef helps to refresh page timeously
  const [selectedTrendId, setSelectedTrendId] = useState<string>('')
  const [xValues, setXValues] = useState<string[]>([])
  const [yValues, setYValues] = useState<number[]>([])
  const [refreshTriggerIconSpin, setRefreshTriggerIconSpin] = useState(false)
  const [maxAllowedTimeRange, setMaxAllowedTimeRange] = useState<number>(CommonUnixMsEnum.ONE_MONTH * 6)
  const [warningModalContent, setWarningModalContent] = useState({ warningModalHeader: '', warningModalBody: '' })
  const [spinnerVisibility, setSpinnerVisibility] = useState(false)
  const [profileFilterConfig, setProfileFilterConfig] = useState<{ key: string; label: string }[]>([])
  const [profileTableColumnConfig, setProfileTableColumnConfig] = useState<IColumnConfig[]>([])

  const associationRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.associationRepo,
  ) as AssociationSettingsRepository
  const selectedAssociation = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedAssociation,
  )
  const username = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.idPassport)
  const password = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.password)
  const userRepo = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.userRepo) as UserRepository

  useEffect(() => {
    initialise()
  }, [])

  useEffect(() => {
    if (triggerUnixMs) {
      const selectedQueryId = selectedQuery?.id || ''
      if (resultsIntervalRef.current[selectedQueryId]) {
        window.clearInterval(resultsIntervalRef.current[selectedQueryId])
      }
      setTimeout(() => {
        const newInterval = window.setInterval(() => refreshQueryResults(selectedQueryId, triggerUnixMs), 5000)
        resultsIntervalRef.current[selectedQueryId] = newInterval
        setRefreshSyncIndicatorUnixMs(Date.now())
      }, 250)
    }
  }, [triggerUnixMs])

  useEffect(() => {
    const selectedQueryId = selectedQuery?.id || ''
    if (selectedTrendId && selectedQueryId && allQueryResults[selectedQueryId]) {
      const queryTrends = allQueryResults[selectedQueryId]?.trends ?? []
      const trend: QueryTrend | undefined = queryTrends.find((trend) => trend.id === selectedTrendId)
      if (trend) {
        const trendData = trend.trendData
        const xAxisValues = trendData.map((item: any) => item.x)
        const yAxisValues = trendData.map((item: any) => item.y)
        setYValues(yAxisValues)
        setXValues(xAxisValues)
      }
    }
  }, [selectedTrendId, allQueryResults, selectedQuery])

  const changeModalState = (newState: any) => setModalState((modalState) => ({ ...modalState, ...newState }))
  const closeModals = () => setModalState(initialModalState)
  const toggleModal = (identifier: string) => {
    const stateVariable = `${identifier}ModalOpen`
    changeModalState({ [stateVariable]: !modalState[stateVariable] })
  }

  const initialise = async () => {
    initialiseGraphConfigurator()
    await loadQueries()
    resetIntervals()
  }

  const resetIntervals = (queryId?: string) => {
    if (queryId && resultsIntervalRef.current[queryId]) {
      window.clearInterval(resultsIntervalRef.current[queryId])
      setTimeout(() => {
        delete resultsIntervalRef.current[queryId]
        setRefreshSyncIndicatorUnixMs(Date.now())
      }, 250)
      return
    }
    Object.keys(resultsIntervalRef.current).forEach((queryId) => {
      window.clearInterval(resultsIntervalRef.current[queryId])
    })
    resultsIntervalRef.current = {}
    setRefreshSyncIndicatorUnixMs(Date.now())
  }

  const getQueryById = (queryId: string, queryList: Query[]) => {
    return queryList.find((query) => query.id === queryId)
  }

  const initialiseGraphConfigurator = () => {
    const filterConfig = associationRepo.getProfileFilterConfig(selectedAssociation)
    if (filterConfig.find((item) => item.key === 'employmentStatus') === undefined) {
      filterConfig.push({ key: 'employmentStatus', label: 'EMPLOYMENT STATUS' })
    }
    const profileTableColumnConfig = associationRepo.getProfileTableColumnConfig(selectedAssociation)
    let columnConfigClone = cloneDeep(profileTableColumnConfig)
    let columnConfig = [...columnConfigClone].map((config: any) => {
      config.id = config.key
      delete config.key
      return config
    })
    // @ts-ignore
    columnConfig = [...columnConfig.slice(0, 5)]
    setProfileFilterConfig(filterConfig)
    setProfileTableColumnConfig(columnConfig)
  }

  const loadQueries = async (silentLoadIndicator: boolean = false) => {
    if (!silentLoadIndicator) {
      setModalState({ ...initialModalState, loadingModalOpen: true })
    }
    const queries = (await QueryService.getQueries(selectedAssociation, username, password)) as Query[]
    const activeQueries = queries.filter((query) => query.status === 'active')
    setQueries(activeQueries)
    if (!silentLoadIndicator) {
      setModalState({ ...initialModalState, loadingModalOpen: false })
    }
  }

  const refreshQueryResults = async (queryId: string, returnLaterThanUnixMs: number): Promise<void> => {
    const queryResults: QueryResults = await QueryService.getQueryResults(
      selectedAssociation,
      queryId,
      returnLaterThanUnixMs,
      username,
      password,
    )
    if (queryResults.hasUpdates) {
      const { resultsFileLocation } = queryResults
      const results = await getResultsFromFile(resultsFileLocation)
      queryResults.results = results
      resetIntervals(queryId)
      setAllQueryResults((results: AllQueryResults) => {
        results[queryId] = queryResults
        return results
      })
      const currentTrendId = selectedTrendId
      setSelectedTrendId('')
      setSelectedTrendId(currentTrendId) // Forcing re-render of currently selected trend
    }
  }

  const triggerResultsRecalculation = async (queryId: string) => {
    try {
      setWarningModalContent({
        warningModalHeader: 'Refresh Started',
        warningModalBody:
          'Your query results are being refreshed and will display once loaded. It may take a short while.',
      })
      setModalState({
        ...initialModalState,
        alertOneButtonModalOpen: true,
      })
      await QueryService.triggerResults(selectedAssociation, queryId, username, password)
      setTriggerUnixMs(Date.now())
    } catch (err) {
      console.error('!!!', err)
    }
  }

  const deleteSelectedQuery = async () => {
    let queryToDelete: Query | undefined
    const filteredQueries = [...queries].filter((query) => {
      if (query.id === selectedQuery?.id) {
        queryToDelete = query
        return false
      }
      return true
    })
    if (!queryToDelete) {
      return
    }
    queryToDelete.status = 'deactivated'
    if (queryToDelete.hasOwnProperty('blobStoreDir')) {
      delete queryToDelete.blobStoreDir
    }
    setQueries(filteredQueries)
    await QueryService.updateQuery(selectedAssociation, username, password, queryToDelete)
    closeModals()
  }

  const renameSelectedQuery = async (newName: string) => {
    const indexOfQueryToRename = queries.findIndex((query) => query.id === selectedQuery?.id)
    const queryId = queries[indexOfQueryToRename].id
    if (indexOfQueryToRename > -1 && queryId) {
      const queryToRename = queries[indexOfQueryToRename]
      queryToRename.name = newName
      await QueryService.updateQuery(selectedAssociation, username, password, queryToRename as Query)
      const updatedQueries = [...queries]
      updatedQueries[indexOfQueryToRename].name = newName
      setQueries(updatedQueries)
    }
    closeModals()
  }

  const getResultsFromFile = async (resultsFileLocation: any): Promise<QueryResult[] | undefined> => {
    const { fileDir, fileName, fileFormat } = resultsFileLocation
    const filePath = `${fileDir.replace(/public\//, '')}/${fileName}.${fileFormat}`
    const data = await getRemoteFile(filePath, { download: true })

    if (data?.Body) {
      const blob = data.Body
      const file = new File([blob], 'results_export.csv')
      const results = await CsvService.csvToJson(file)
      return results as QueryResult[]
    }
    return undefined
  }

  const saveQueryFilterSettings = async (
    filters: QueryFilter[][],
    timePeriod?: TimePeriod | {},
    customTimeRange?: CustomTimePeriod | {},
  ) => {
    if (!selectedQuery) {
      displayWarning({ code: DataQueryErrorCodesEnum.MissingQueryId })
      return
    }
    setModalState({ ...initialModalState, savingModalOpen: true })
    let query = cloneDeep(selectedQuery)
    query.filterParams = { ...query.filterParams, filters }
    if (timePeriod) {
      query.filterParams.period = timePeriod
    }
    if (customTimeRange) {
      const { startUnixMs, endUnixMs } = customTimeRange as CustomTimePeriod
      if (Math.abs(endUnixMs - startUnixMs) > maxAllowedTimeRange) {
        displayWarning({ code: DataQueryErrorCodesEnum.MaxTimeRangeExceeded })
        return
      }
      query.filterParams.timeRange = customTimeRange
    }
    const updatedQueries = [...queries].map((existingQuery) => (existingQuery.id === query.id ? query : existingQuery))
    setQueries(updatedQueries)
    setSelectedQuery(query)
    await QueryService.updateQuery(selectedAssociation, username, password, query)
    setModalState({ ...initialModalState, savingModalOpen: false })
  }

  const handleQueryAddition = async (newQueryName: string) => {
    setModalState({ ...initialModalState, loadingModalOpen: true })
    const query = await QueryService.createQuery(selectedAssociation, newQueryName, username, password)
    const newQueries = [...queries, query]
    setQueries(newQueries)
    setSelectedQuery(query)
    if (query.id) {
      await triggerResultsRecalculation(query.id)
    }
    setModalState({ ...initialModalState, loadingModalOpen: false })
  }

  const handleQuerySelection = async (queryIdentifier: string) => {
    const [queryId, queryName] = queryIdentifier.split('#')
    const targetQuery = getQueryById(queryId, queries)
    if (!targetQuery) {
      console.log('No query found')
      return
    }
    setSelectedTrendId('')
    setSelectedQuery(targetQuery)
    setSpinnerVisibility(true)
    await refreshQueryResults(queryId, 0)
    setSpinnerVisibility(false)
  }

  const exportResultsToEmail = (emailAddresses: string[]) => {
    try {
      QueryService.exportResults(
        selectedAssociation,
        selectedQuery?.id || '',
        username,
        password,
        emailAddresses as ResultExportTargetEmails,
      )
      setTriggerUnixMs(Date.now())
    } catch (err) {
      console.error('!!!', err)
    }
    setModalState({ ...initialModalState, exportConfirmationModalOpen: true })
  }

  const saveTrendConfig = async (trendConfig: TrendConfig, queryToUpdate: Query) => {
    if (!queryToUpdate.id) {
      displayWarning({ code: DataQueryErrorCodesEnum.MissingQueryId })
      return
    }
    const isNewTrend = !trendConfig.id
    if (isNewTrend) {
      for (const trend of queryToUpdate.trends) {
        if (normaliseStringForComparison(trend.trendName) === normaliseStringForComparison(trendConfig.trendName)) {
          displayWarning({ code: DataQueryErrorCodesEnum.DuplicateTrendName })
          return
        }
      }
      queryToUpdate.trends.push(trendConfig)
    } else {
      const trendToUpdate = queryToUpdate.trends.find((trend) => trend.id === trendConfig.id)
      if (trendToUpdate) {
        const trendIndex = queryToUpdate.trends.indexOf(trendToUpdate)
        queryToUpdate.trends[trendIndex] = trendConfig
      }
    }
    setModalState({ ...initialModalState, savingModalOpen: true })
    const updatedQuery = await QueryService.updateQuery(selectedAssociation, username, password, queryToUpdate)
    const updatedQueries = [...queries].map((existingQuery) =>
      existingQuery.id === updatedQuery.id ? updatedQuery : existingQuery,
    )
    setQueries(updatedQueries)
    setSelectedQuery(updatedQuery)
    closeModals()
    await triggerResultsRecalculation(updatedQuery.id || '')
  }

  const displayWarning = (error: { code: string }) => {
    let warningModalHeader = 'Problem'
    let warningModalBody = 'We ran into a problem. Refresh the page and try again.'
    let modalsToOpen = { alertOneButtonModalOpen: true } as Record<string, boolean>
    if (error.code === DataQueryErrorCodesEnum.MaxTimeRangeExceeded) {
      warningModalHeader = 'Max range exceeded'
      warningModalBody = 'The maximum allowed time range is 6 months'
    } else if (error.code === DataQueryErrorCodesEnum.MissingQueryId) {
      warningModalHeader = 'Missing query ID'
      warningModalBody =
        'The query ID is missing. Refresh the page and try again, and if the problem persists, contact tech support.'
    } else if (error.code === DataQueryErrorCodesEnum.DuplicateTrendName) {
      warningModalHeader = 'Already Exists'
      warningModalBody = 'There is already a trend with that name.'
      modalsToOpen.graphConfiguratorModalOpen = true
    }
    setWarningModalContent({ warningModalHeader, warningModalBody })
    setModalState(modalsToOpen)
  }

  const getTabRelatedActionButtons = (tabName: string, targetQuery: Query | undefined) => {
    const queryId = targetQuery?.id || ''
    if (!queryId) {
      return []
    }
    let actionButtons: ActionButtonType[] = []
    if (tabName === TABS.SETTINGS) {
      actionButtons = [
        {
          iconPath: mdiTrashCan,
          onClick: () => toggleModal('deleteConfirm'),
          label: 'DELETE',
          title: 'Delete currently selected query',
        },
        {
          iconPath: mdiContentDuplicate,
          onClick: () => toggleModal('rename'),
          label: 'RENAME',
          title: 'Rename currently selected query',
        },
      ]
    }
    const queryResults = allQueryResults[queryId]
    const queryHasResults = queryResults?.results && queryResults.results.length > 0
    if (tabName === TABS.RESULTS && queryHasResults) {
      actionButtons = [
        {
          iconPath: mdiExport,
          onClick: () => toggleModal('emailCollector'),
          label: 'EXPORT',
          title: 'Export query results',
        },
      ]
    }

    if ([TABS.RESULTS, TABS.TRENDS].includes(tabName)) {
      actionButtons.push({
        iconPath: mdiRefresh,
        iconSpin: refreshTriggerIconSpin,
        onClick: () => triggerResultsRecalculation(queryId),
        label: 'REFRESH RESULTS',
        title: 'Refresh results matching query settings',
      })
    }
    return actionButtons
  }

  const generateWorkAreaContent = (
    tabName: string,
    targetQuery: Query | undefined,
    targetTrendId: string,
    queryResults: QueryResults | undefined,
  ) => {
    if (!targetQuery) {
      return (
        <div style={styles.selectedFieldInstruction}>
          <Icon
            path={mdiInformationOutline}
            size={1}
            color={ColorPalette.PRIMARY_BLUE}
            style={{ marginRight: '.75em' }}
          />
          Select a query to manage
        </div>
      )
    }

    const selectedQueryId = targetQuery?.id || ''
    if (tabName === TABS.SETTINGS) {
      const { filterParams } = targetQuery
      return (
        <DataQuerySettings
          filterParams={filterParams || {}}
          saveChanges={saveQueryFilterSettings}
          key={`queryFilterSettings_${selectedQueryId}`}
        />
      )
    }
    if (tabName === TABS.RESULTS) {
      return (
        <DataQueryFilteredResults
          showLoader={spinnerVisibility}
          queryResults={queryResults || { trends: [] }}
          key={`filteredResults_${selectedQueryId}_${triggerUnixMs}_${refreshSyncIndicatorUnixMs}`}
        />
      )
    }
    if (tabName === TABS.TRENDS) {
      return (
        <DataQueryTrends
          ref={containerRef}
          selectedTrendId={targetTrendId}
          selectedTrendName={targetQuery.name}
          trendConfig={targetQuery?.trends || []}
          trendData={queryResults?.trends || []}
          xValues={xValues}
          yValues={yValues}
          setSelectedTrendId={(trendId) => setSelectedTrendId(trendId)}
          toggleTrendConfigurator={() => toggleModal('graphConfigurator')}
          key={`dataQueryTrends_${selectedQueryId}_${refreshSyncIndicatorUnixMs}`}
        />
      )
    }
    return null
  }

  const generateWorkArea = (
    targetQuery: Query | undefined,
    targetTrendId: string,
    tabName: string,
    queryResults: AllQueryResults,
  ) => {
    const queryId = targetQuery?.id || ''
    const fitleredResultsIconName = resultsIntervalRef.current[queryId] ? 'sync' : 'table-account'
    const showSyncIcon = !!resultsIntervalRef.current[queryId]
    return (
      <div style={styles.workAreaContainer}>
        <Toolbar actionButtons={getTabRelatedActionButtons(tabName, targetQuery)} />
        <TabNavigator
          ref={tabbedNavigatorRef}
          tabs={[
            { tabName: TABS.SETTINGS, iconName: 'cog' },
            {
              tabName: TABS.RESULTS,
              iconName: fitleredResultsIconName,
              iconSpin: showSyncIcon,
              iconColour: showSyncIcon ? ColorPalette.PRIMARY_BLUE : undefined,
            },
            { tabName: TABS.TRENDS, iconName: 'chart-bar' },
          ]}
          currentTabName={tabName}
          onTabClick={(tabName) => {
            setCurrentTabName(tabName)
            setSelectedTrendId('')
          }}
          key={`tabbedNavigator_${Object.keys(resultsIntervalRef.current).join(',')}`}
        />
        <div style={styles.workAreaContent}>
          {generateWorkAreaContent(tabName, targetQuery, targetTrendId, queryResults[queryId])}
        </div>
      </div>
    )
  }

  const generateGraphConfigurator = (targetQuery: Query | undefined, targetGraphId: string = '') => {
    if (!targetQuery) {
      return
    }
    const queryId = targetQuery?.id || ''
    const queryFilters = targetQuery.filterParams.filters || []
    const aggregationOptions = queryFilters.flatMap((filter) =>
      filter.map((f) => {
        if (f.filterType === FilterTypeEnum.FIELD) {
          // @ts-ignore
          return (f.filterParams as FieldFilterParams).targetPath
        }
        return (f.filterParams as DocFilterParams).targetDoc
      }),
    )
    const uniqueAggregationOptions = Array.from(new Set(flatten(aggregationOptions)))
    const selectedTrendConfig = targetQuery.trends.find((trend: TrendConfig) => trend.id === targetGraphId)
    return (
      <GraphConfigurator
        profileTableColumnConfig={profileTableColumnConfig}
        profileFilterConfig={profileFilterConfig}
        aggregationOptions={uniqueAggregationOptions}
        trendConfig={selectedTrendConfig}
        queryResults={allQueryResults[queryId]}
        onCancelClick={() => toggleModal('graphConfigurator')}
        onDoneClick={(trendConfig) => saveTrendConfig(trendConfig, targetQuery)}
        key={`graphConfig_${targetGraphId}`}
      />
    )
  }

  let graphConfigurator = null
  if (modalState.graphConfiguratorModalOpen) {
    graphConfigurator = generateGraphConfigurator(selectedQuery, selectedTrendId)
  }

  let resultsExportEmailCollector = null
  if (modalState.emailCollectorModalOpen) {
    const user = userRepo.getCurrentUserEntity()
    const userEmailAddress = user.getEmail()
    resultsExportEmailCollector = (
      <InfoCollectorModal
        open={true}
        defaultItems={[userEmailAddress]}
        header="EMAIL TO"
        subHeader="Which email addresses should we send to?"
        warningMessage="Add at least one email address"
        validateInput={checkEmailFormat}
        transformInput={toLowerCaseCustom}
        placeholder="Email address"
        minimumItems={1}
        dismiss={closeModals}
        onSuccess={exportResultsToEmail}
        onReject={closeModals}
      />
    )
  }

  let exportConfirmModal = null
  if (modalState.exportConfirmationModalOpen) {
    exportConfirmModal = (
      <AlertModalOneButton
        open={true}
        header="Export Initiated"
        body={
          <p>
            Your export is being generated and will be sent out shortly.
            <br />
            Check your inbox in a few minutes.
          </p>
        }
        buttonLabel="Ok"
        onClick={closeModals}
      />
    )
  }

  let actionInProgressModal = null
  if (modalState.loadingModalOpen || modalState.savingModalOpen) {
    const message = modalState.loadingModalOpen ? 'Loading...' : 'Saving...'
    actionInProgressModal = <LoadingModal open={true}>{message}</LoadingModal>
  }

  return (
    <div style={styles.container}>
      <NavigationBar match={props.match} location={props.location} reloadPageData={() => {}} history={props.history} />
      <SectionHeaderPrimary
        style={styles.sectionHeader}
        disabled={true}
        searchString={''}
        textHandler={() => ({})}
        onClick={() => ({})}>
        Data Queries
      </SectionHeaderPrimary>

      <div style={styles.contentContainer}>
        <SideMenu
          visible={true}
          menuComponents={
            <DataQueriesSideMenu
              queries={queries}
              selectedQueryId={selectedQuery?.id || ''}
              onQueryAddition={handleQueryAddition}
              onQueryClick={handleQuerySelection}
              syncingQueryIds={Object.keys(resultsIntervalRef.current)}
              key={`sideMenu_${triggerUnixMs}_${Object.keys(resultsIntervalRef.current).join(',')}`}
            />
          }
        />
        {generateWorkArea(selectedQuery, selectedTrendId, currentTabName, allQueryResults)}
      </div>
      {graphConfigurator}
      {resultsExportEmailCollector}
      {exportConfirmModal}

      <AlertModalTwoButton
        open={modalState.deleteConfirmModalOpen}
        dismiss={closeModals}
        onClick1={closeModals}
        onClick2={deleteSelectedQuery}
        buttonLabel1="No"
        buttonLabel2="Yes"
        header="Confirm"
        buttonStyle={{ marginTop: 30 }}
        body={<div>{`Are you sure you want to delete query?`}</div>}
      />
      <AlertModalOneButton
        open={modalState.alertOneButtonModalOpen}
        header={warningModalContent.warningModalHeader}
        body={warningModalContent.warningModalBody}
        buttonLabel={'Ok'}
        containerStyle={{ top: window.innerHeight * 0.3 }}
        onClick={() => toggleModal('alertOneButton')}
      />
      {actionInProgressModal}
      <LabelCollector
        open={modalState.addModalOpen}
        warning="Enter query name"
        placeholder="Enter query name"
        buttonLabel="Add"
        iconName="setting"
        dismiss={() => toggleModal('add')}
        submit={() => null}
      />
      <LabelCollector
        open={modalState.renameModalOpen}
        warning={`Rename "${selectedQuery?.name || '?'}"`}
        placeholder="Rename"
        buttonLabel="Rename"
        initialValue={selectedQuery?.name}
        iconName="setting"
        dismiss={() => toggleModal('rename')}
        submit={renameSelectedQuery}
      />
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255, 1), rgba(209,210,230, 1))',
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto 1%',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    overflowY: 'scroll' as 'scroll',
  },
  workAreaContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    paddingInline: 'max(2em, 2%)',
    width: '100%',
    overflow: 'hidden',
  },
  workAreaContent: {
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    display: 'flex',
    flex: 1,
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    overflowY: 'scroll' as 'scroll',
    backgroundColor: ColorPalette.CARD_WHITE,
  },
  queryDetails: {
    width: '94%',
    margin: '0 auto',
  },
  section: {
    width: '100%',
    margin: '1em 0',
    paddingBottom: '20px',
    // overflowY: 'scroll' as 'scroll',
  },
  graphView: {
    display: 'flex',
    alignItems: 'center',
    gap: '1em',
    margin: '2.5em 0 0.75em',
  },
  selectedFieldInstruction: {
    paddingTop: '25%',
    fontSize: '0.85rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: ColorPalette.SECONDARY_TEXT,
  },
}

export default Radium(DataQueries)
