import React from "react";
import ReactDOM from "react-dom";

interface Props {
    children: React.ReactElement;
}

const modalRoot = document.getElementById("modalRoot");

const ModalPortal = (props: Props) => ReactDOM.createPortal(props.children, modalRoot as Element);

export default ModalPortal;
