import { PpeActionRecord } from '../../models/ppe'
import { PpeInstancePayload } from '../../types'
import { httpGet, httpPost } from '../remoteData'

export async function getPpeInstances(association: string, token: string) {
  const { data } = await httpGet('people_flow_core', `/inventory/ppe/instances/association/${association}`, token)
  return data
}

export async function updatePpeInstance(association: string, payload: PpeInstancePayload, token: string) {
  const { data } = await httpPost(
    'people_flow_core',
    `/inventory/ppe/import/instances/association/${association}`,
    payload,
    token,
  )
  return data
}

export async function getPpeRequests(association: string, token: string) {
  const { data } = await httpGet('people_flow_core', `/inventory/ppe/requests/association/${association}`, token)
  return data
}

export async function updatePpeActionRecord(
  association: string,
  actionRecord: Partial<PpeActionRecord>,
  token: string,
) {
  const { data } = await httpPost(
    'people_flow_core',
    `/inventory/ppe/actionrecords/association/${association}`,
    [actionRecord],
    token,
  )
  return data
}
