import { ColorPalette } from '../../config/colors'
import React, { Component, createRef } from 'react'
import Radium from 'radium'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import equal from 'deep-equal'
import Icon from '@mdi/react'

import ButtonBlue from '../../components/BaseComponents/Buttons/ButtonBlue'
import NavigationBar from '../../components/Navigation/NavigationBar'
import SectionHeaderPrimary from '../../components/Headings/SectionHeaderPrimary'
import SideMenu from '../../components/Navigation/SideMenu'
import TerminationReasonsSideMenu from '../../components/SideMenus/TerminationReasonsSideMenu'
import InfoCollectorModal from '../../components/Modals/InfoCollector'
import SaveFailedModal from '../../components/Modals/AlertModalOneButton'
import RouteLeavingGuard from '../../components/Navigation/RouteLeavingGuard'
import { BackButton } from '../../components/BaseComponents/Buttons/BackButton'

import { toUpperCaseCustom } from '../../utils'
import { ActionType } from '../../store/actions/actions'
import { PeopleFlowCombinedReducer } from '../../store'
import { mdiInformationOutline } from '@mdi/js'
import { PrimaryTerminationOptions, SecondaryTerminationOptions } from '../../components/Configurator/Termination'
import { SessionService, ConfigManagerService } from '../../services'
import { AssociationSettingsRepository } from '../../repositories'

dayjs.extend(relativeTime)

interface ITerminationReasonsProps extends RouteComponentProps {
  selectedAssociation: string
  idPassport: string
  password: string
  associationRepo: AssociationSettingsRepository
  updateState: (obj: any) => void
}

interface ITerminationReasonsState {
  loadingModalOpen: boolean
  saveFailedModalOpen: boolean
  selectedDivision: string
  selectedVersion: string
  selectorModalOpen: boolean
  selectorIdentifier: string
  selectorItems: string[]
  terminationReasonConfig: Record<string, {}>
  secondaryTerminationReasons: string[]
  selectedPrimaryTerminationReason: string
}

class TerminationReasons extends Component<ITerminationReasonsProps, ITerminationReasonsState> {
  private sectionHeaderRef: React.RefObject<any>
  private sectionsRef: React.RefObject<any>

  constructor(props: ITerminationReasonsProps) {
    super(props)
    this.sectionHeaderRef = createRef()
    this.sectionsRef = createRef()
  }

  initialModalState = {
    loadingModalOpen: false,
    saveFailedModalOpen: false,
    selectorModalOpen: false,
  }

  state: ITerminationReasonsState = {
    ...this.initialModalState,
    selectedDivision: '',
    selectedVersion: '',
    selectorIdentifier: '',
    selectorItems: [],
    terminationReasonConfig: {},
    secondaryTerminationReasons: [],
    selectedPrimaryTerminationReason: '',
  }

  componentDidMount() {
    const terminationReasonConfig = this.props.associationRepo.getProcessConfig(this.props.selectedAssociation)
      .terminate.terminationReasonConfig

    if (terminationReasonConfig) {
      const primaryTerminationReasons = Object.keys(terminationReasonConfig)
      this.setState({
        terminationReasonConfig,
        selectedPrimaryTerminationReason: primaryTerminationReasons[0],
        secondaryTerminationReasons: Object.keys(terminationReasonConfig[primaryTerminationReasons[0]]),
      })
    }
  }

  safeToOpenInfoCollector = true

  closeModals = () => {
    this.setState({ ...this.state }, this.forceUpdate)
  }

  openSelectorModal = (selectorIdentifier: string, selectorItems: string[]) =>
    this.setState({ selectorIdentifier, selectorItems })
  closeSelectorModal = () => this.setState({ selectorIdentifier: '', selectorItems: [] })

  toggleSaveFailedModal = () => this.setState((prevState) => ({ saveFailedModalOpen: !prevState.saveFailedModalOpen }))

  saveChanges = async () => {
    const { selectedAssociation, idPassport, password, associationRepo, updateState } = this.props

    if (this.anyChangesToReasonConfig()) {
      const changes = [
        {
          editedBy: idPassport,
          updatePath: ['terminate', 'terminationReasonConfig'],
          updatedData: this.state.terminationReasonConfig,
          updatedMs: +new Date(),
        },
      ]
      const response = await ConfigManagerService.updateConfig(selectedAssociation, 'processConfig', changes, {
        username: idPassport,
        password,
      })

      if (response.result === 'success') {
        this.props.associationRepo.setTerminationReasonConfigItem(
          selectedAssociation,
          response.updatedConfig.terminate.terminationReasonConfig,
        )
        updateState({ associationRepo, refreshTimestamp: +new Date() })
      } else {
        this.toggleSaveFailedModal()
      }
    }
  }

  updateTerminationReasonConfig = (selectorItems: string[]) => {
    let terminationReasonConfig: Record<string, {}> = { ...this.state.terminationReasonConfig }
    // add any new primary termination reasons
    selectorItems.forEach((item: string) => {
      if (!terminationReasonConfig.hasOwnProperty(item)) {
        terminationReasonConfig[item] = {}
      }
    })
    // remove any deleted primary termination reasons
    Object.keys(terminationReasonConfig).forEach((item: string) => {
      if (!selectorItems.includes(item)) {
        delete terminationReasonConfig[item]
      }
    })

    this.setState({ terminationReasonConfig })
    this.closeSelectorModal()
  }

  updateSecondaryTerminationReasons = (selectorItems: string[]) => {
    let terminationReasonConfig = { ...this.state.terminationReasonConfig }
    let secondaryReasons: Record<string, {}> = {}
    selectorItems.forEach((reason: string) => {
      secondaryReasons[reason] = {}
    })
    terminationReasonConfig[this.state.selectedPrimaryTerminationReason] = secondaryReasons
    this.setState(
      {
        secondaryTerminationReasons: selectorItems,
        terminationReasonConfig,
      },
      this.closeSelectorModal,
    )
  }

  anyChangesToReasonConfig = () => {
    const terminationReasonConfig = this.props.associationRepo.getProcessConfig(this.props.selectedAssociation)
      .terminate.terminationReasonConfig

    if (terminationReasonConfig) {
      return !equal(terminationReasonConfig, this.state.terminationReasonConfig)
    }

    return false
  }

  render() {
    const {
      selectedDivision,
      selectedVersion,
      selectorIdentifier,
      selectorItems,
      terminationReasonConfig,
      secondaryTerminationReasons,
      selectedPrimaryTerminationReason,
      saveFailedModalOpen,
    } = this.state

    const organisationConfig = this.props.associationRepo.getOrganisationConfig(this.props.selectedAssociation)
    // const terminationReasonConfig = this.props.associationRepo.getProcessConfig(this.props.selectedAssociation).terminate
    //     .terminationReasonConfig;
    const primaryTerminationReasons = Object.keys(terminationReasonConfig)

    const anyChanges = this.anyChangesToReasonConfig()

    return (
      <div style={styles.container}>
        <NavigationBar match={this.props.match} location={this.props.location} history={this.props.history} />

        <SectionHeaderPrimary
          ref={this.sectionHeaderRef}
          style={styles.sectionHeader}
          disabled={true}
          searchString={''}
          onClick={() => ({})}>
          Termination
        </SectionHeaderPrimary>

        <div style={styles.contentContainer}>
          <SideMenu
            visible={true}
            menuComponents={
              <TerminationReasonsSideMenu
                selectedDivision={selectedDivision}
                selectedVersion={selectedVersion}
                organisationConfig={organisationConfig}
                // terminationReasonConfig={terminationReasonConfig}
                onDivisionChange={(division: string) => this.setState({ selectedDivision: division })}
                onVersionClick={(field: string) => this.setState({ selectedVersion: field })}
              />
            }
          />
          <div style={styles.rightSide}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <BackButton />
            </div>
            <div style={styles.rightSideContent}>
              <div style={styles.jobSections} ref={this.sectionsRef}>
                {false ? ( // isEmpty(fieldDetails)
                  <div
                    style={{
                      paddingTop: '10%',
                      fontSize: '0.85rem',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: ColorPalette.SECONDARY_TEXT,
                    }}>
                    <Icon
                      path={mdiInformationOutline}
                      size={1}
                      color={ColorPalette.PRIMARY_BLUE}
                      style={{ marginRight: '.75em' }}
                    />
                    Select a data field on the left to manage settings
                  </div>
                ) : (
                  <>
                    <PrimaryTerminationOptions
                      primaryTerminationReasons={primaryTerminationReasons}
                      openSelectorModal={this.openSelectorModal}
                    />
                    <SecondaryTerminationOptions
                      primaryTerminationReasons={primaryTerminationReasons}
                      secondaryTerminationReasons={secondaryTerminationReasons}
                      selectedPrimaryTerminationReason={selectedPrimaryTerminationReason}
                      onPrimaryReasonChange={(reason: string) =>
                        this.setState({
                          selectedPrimaryTerminationReason: reason,
                          secondaryTerminationReasons: Object.keys(terminationReasonConfig[reason]),
                        })
                      }
                      openSelectorModal={this.openSelectorModal}
                    />
                    <div style={{ paddingBottom: '3em' }}>
                      <ButtonBlue onClick={this.saveChanges} disabled={!anyChanges}>
                        Save changes
                      </ButtonBlue>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <InfoCollectorModal
          open={selectorIdentifier === 'primaryTerminationReasons'}
          defaultItems={selectorItems}
          header="SELECTOR OPTIONS"
          subHeader="Field selector options"
          warningMessage="Add at least one item"
          validateInput={() => true}
          transformInput={toUpperCaseCustom}
          placeholder="Enter selector option"
          successLabel="Update"
          minimumItems={1}
          dismiss={this.closeSelectorModal}
          onSuccess={this.updateTerminationReasonConfig}
          onReject={this.closeSelectorModal}
        />
        <InfoCollectorModal
          open={selectorIdentifier === 'secondaryTerminationReasons'}
          defaultItems={secondaryTerminationReasons}
          header="SELECTOR OPTIONS"
          subHeader="Field selector options"
          warningMessage="Add at least one item"
          validateInput={() => true}
          transformInput={toUpperCaseCustom}
          placeholder="Enter selector option"
          successLabel="Update"
          minimumItems={1}
          dismiss={this.closeSelectorModal}
          onSuccess={this.updateSecondaryTerminationReasons}
          onReject={this.closeSelectorModal}
        />
        <SaveFailedModal
          open={saveFailedModalOpen}
          header="Failed to save changes"
          body={
            <div>
              Changes won't be permanently applied. Please try again.
              <p>If problem persists, please contact customer support.</p>
            </div>
          }
          buttonLabel={'Ok'}
          onClick={this.toggleSaveFailedModal}
        />
        <RouteLeavingGuard
          when={anyChanges}
          navigate={(path) => this.props.history.push(path)}
          shouldBlockNavigation={(location) => true}
          alertHeader="Discard changes"
          alertBody={
            <div>
              You have unsaved changes.
              <br />
              Are you sure you want to leave this page without saving?
            </div>
          }
        />
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255, 1), rgba(209,210,230, 1))',
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto 1.5%',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    paddingInline: 'max(2em, 2%)',
    width: '100%',
    overflow: 'hidden',
  },
  rightSideContent: {
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    // borderRadius: "15px 15px 0 0",
    display: 'flex',
    flex: 1,
    overflow: 'auto',
    backgroundColor: ColorPalette.CARD_WHITE,
  },
  button: {
    fontWeight: 'bolder',
    fontSize: '0.8rem',
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    ':hover': {
      color: ColorPalette.PRIMARY_BLUE,
    },
    ':active': {
      color: ColorPalette.DARK_GREY,
    },
  },
  jobSections: {
    width: '91%',
    margin: '0 auto',
  },
}

const mapStateToProps = (state: PeopleFlowCombinedReducer) => {
  return {
    idPassport: state.sessionManager.idPassport,
    password: state.sessionManager.password,
    associationRepo: state.sessionManager.associationRepo as AssociationSettingsRepository,
    selectedAssociation: state.sessionManager.selectedAssociation,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateState: (data: any) => dispatch({ type: ActionType.UPDATE_STATE, data }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Radium(TerminationReasons))
