export class SignatureFieldConfig {
    signatoryType: string;
    compulsory: boolean;
    pinProtected: boolean;
    authedSignatories: any[];
    allSignatoryTypes: string[];

    constructor(signatureConfig?: any) {
        this.signatoryType = signatureConfig ? signatureConfig.signatoryTypes[0] : "EMPLOYEE";
        this.compulsory = false;
        this.pinProtected = false;
        this.authedSignatories = [];
        this.allSignatoryTypes = signatureConfig.signatoryTypes || [];
    }
}
