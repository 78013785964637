/**
 * The purpose of this module is to "duplicate" the interfaces from
 * lambda-services/common-ts We don't want to yet commit to extracting all the
 * interfaces into and NPM-installable module so we're playing it safe and
 * creating the copies we need here.
 *
 * This is also good practice in general for a service to create it's own
 * interfaces rather than use common one's. For instance, what if we wanted to
 * re-write the back-end in Rust? But the duplications has it's trade-offs.  One
 * day when we are all grown up, then we will use code generators to make
 * interfaces reusable
 */

export namespace CommonTs {
    export namespace Profile {
        export interface DdbRecordIndex {
            idPassport: string;
            uid: string;
        }

        // "any" but guaranteed to have idPassport and uid
        export interface DdbRecord extends DdbRecordIndex {
            [key: string]: any;
        }

        export enum EmploymentStatus {
            EMPLOYEE = "EMPLOYEE",
            CANDIDATE = "CANDIDATE",
        }

        export enum TrashStatus {
            NONE = "NONE",
            TRASHED = "TRASHED",
            PERMANENT = "PERMANENT",
        }

        export interface BaseGeneralData {
            employmentStatus: EmploymentStatus | undefined;
        }

        export type GeneralDataValue = any | any[];

        export interface GeneralData extends BaseGeneralData {
            [key: string]: GeneralDataValue;
        }

        export interface IDdbProfileDocument extends DdbRecordIndex {
            generalData: GeneralData;
            cohorts: string[];
            trashStatus: TrashStatus;
            pfid: string;
            [key: string]: any;
        }
    }

    export namespace Import {
        export interface IImportRecordEntry {
            idPassport: string;
            generalData: Profile.GeneralData;
            cohorts?: string[];
            trashStatus?: Profile.TrashStatus;
        }
        export enum IImportAction {
            NEW_PROFILE = "NEW_PROFILE",
            ADD_FIELDS = "ADD_FIELDS",
            REPLACE_FIELDS = "REPLACE_FIELDS",
            REMOVE_FIELDS = "REMOVE_FIELDS",
            TERMINATE = "TERMINATE",
            EMPLOY = "EMPLOY",
        }
        export enum InvalidationReason {
            MISSING_ID_PASSPORT = "MISSING_ID_PASSPORT",
            MISSING_REQUIRED_KEY = "MISSING_REQUIRED_KEY",
            UNKNOWN_KEY = "UNKNOWN_KEY",
            INVALID_SELECTION = "INVALID_SELECTION",
            NULL_VALUE = "NULL_VALUE",
        }

        export enum PlanProblemReason {
            DIRECT_MODIFICATION = "DIRECT_MODIFICATION",
            ID_PASSPORT_MISMATCH = "ID_PASSPORT_MISMATCH",
            MISSING_EMPLOYMENT_STATUS = "MISSING_EMPLOYMENT_STATUS",
            MISSING_COHORTS = "MISSING_COHORTS",
            MISSING_TRASH_STATUS = "MISSING_TRASH_STATUS",
            PROFILE_ALREADY_EXISTS = "PROFILE_ALREADY_EXISTS",
            PROFILE_DOES_NOT_EXIST = "PROFILE_DOES_NOT_EXIST",
            NOTHING_TO_DO = "NOTHING_TO_DO",
            CORRUPT_EXISTING_TERMINATION_STATE = "CORRUPT_EXISTING_TERMINATION_STATE",
            ALREADY_TERMINATED = "ALREADY_TERMINATED",
            ALREADY_EMPLOYED = "ALREADY_EMPLOYED",
            UNEXPECTED_ERROR = "UNEXPECTED_ERROR",
            INSUFFICIENT_INPUT = "INSUFFICIENT_INPUT",
        }

        export type Reason = InvalidationReason | PlanProblemReason;

        export interface IInvalidation {
            key: string;
            reason: Reason;
            message?: string;
        }

        export interface IOperation {
            op: string;
            path: string;
            value?: any;
        }
        export type OperationWithBefore = IOperation & { before?: any };

        interface INewProfileOptions {
            employmentStatus?: Profile.EmploymentStatus;
            cohorts?: string[];
            trashStatus?: Profile.TrashStatus;
        }

        interface IUpdateProfileOptions {
            onlyKeys: string[];
        }

        export interface ITerminateProfileOptions {
            defaultTerminationDate?: string,
            beforeAfterOptions?: {
                profileKeysToKeep: string[],
                generalDataKeysToKeep: string[]
            },
        }

        export type IPlanConfigOptions = INewProfileOptions | IUpdateProfileOptions | ITerminateProfileOptions;

        interface IPlanFilter {
            operation?: IOperation;
            validation?: Reason[];
            ignoreValidations?: Reason[];
        }

        export interface IPlanConfig {
            action: IImportAction;
            options: IPlanConfigOptions;
            filter: IPlanFilter;
            allowablePlanWarningReasons: Reason[];
            caseInsensitive?: boolean;
        }

        export interface ProfileChange {
            id: string;
            idPassport: string;
            uid: string;
            patchWithBefore: OperationWithBefore[];
        }

        export interface IImportPlan {
            record: IImportRecordEntry;
            action: IImportAction;
            current?: Profile.IDdbProfileDocument;
            after?: Profile.IDdbProfileDocument;
            patchWithBefore?: OperationWithBefore[];
            problems: IInvalidation[];
        }

        export interface IPlanReport {
            config: IPlanConfig;
            filterResultsLength: number;
            patchPivot: Record<string, string | number>;
            invalidationPivot: Record<string, string | number>;
            validActionPivot: Record<string, string | number>;
            recordsReceived: number;
        }

        export interface IPlanSummary {
            report: IPlanReport;
        }

        export interface IPlanResponse {
            filterResults: IImportPlan[];
            report: IPlanReport;
        }
    }
}
