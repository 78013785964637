import { useState } from 'react'
import Icon from '@mdi/react'
import { mdiChevronUpCircle, mdiChevronDown } from '@mdi/js'

import ButtonGeneric from '../../BaseComponents/Buttons/ButtonGeneric'

import { ColorPalette } from '../../../config/colors'

interface Props {
  title: string
  initialCollapsed?: boolean
  children: React.ReactNode
  style?: React.CSSProperties
  titleStyle?: React.CSSProperties
  buttonStyle?: React.CSSProperties
}

export const ExpandableSection = (props: Props) => {
  const [expanded, setExpanded] = useState(props.initialCollapsed ? false : true)
  const toggleExpand = () => setExpanded((currentValue) => !currentValue)

  let borderBottomAdjust: React.CSSProperties = {}
  if (!expanded) {
    borderBottomAdjust.borderBottomColor = ColorPalette.MEDIUM_GREY
  }

  return (
    <div style={{ ...styles.section, ...props.style }}>
      <div
        style={{
          ...styles.titleContainer,
          ...borderBottomAdjust,
          ...props.titleStyle,
        }}>
        <h1 style={{ ...styles.sectionHeader }}>{props.title}</h1>
        <ButtonGeneric
          style={{ ...styles.button, ...props.buttonStyle }}
          iconAfter={
            <Icon
              style={styles.buttonIcon}
              path={expanded ? mdiChevronUpCircle : mdiChevronDown}
              color={ColorPalette.PRIMARY_TEXT}
            />
          }
          // label={expanded ? "Collapse details" : "Expand details"}
          onClick={toggleExpand}
        />
      </div>

      {expanded && props.children}
    </div>
  )
}

const styles = {
  section: {
    width: '100%',
  },
  sectionHeader: {
    fontSize: '0.85rem',
    fontWeight: '550',
    fontFamily: 'Roboto',
    color: ColorPalette.PRIMARY_TEXT,
    padding: '0 0.5em',
    textTransform: 'uppercase' as 'uppercase',
    width: '100%',
  },
  button: {
    fontWeight: '550',
    fontSize: '0.8rem',
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    marginTop: 0,
    justifyContent: 'flex-end',
    ':hover': {
      backgroundColor: 'inherit',
    },
    ':active': {
      backgroundColor: 'inherit',
    },
    cursor: 'pointer',
  },
  buttonIcon: {
    width: '1.4rem',
    height: '1.4rem',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    borderBottomWidth: 0.5,
    borderBottomStyle: 'solid' as 'solid',
    borderBottomColor: ColorPalette.PRIMARY_TEXT,
  },
}
