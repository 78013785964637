export const componentTypes = ["Text box (single-line)", "Text box (multi-line)", "Selector", "Date"];

export const validationOptions = ["none", "mobile", "email", "ID", "passport"];

export const bool = ["NO", "YES"];

export const defaultValueOptions = ["I'll choose a value", "Last value entered"];

export const componentTypeMap = {
    "Text box (single-line)": "text",
    "Text box (multi-line)": "text",
    Selector: "selector",
    Date: "date",
};

export const validationTypeMap = {
    mobile: "mobile",
    email: "email",
    ID: "saId",
    passport: "passport",
};
