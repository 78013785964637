import { removeUnderscores } from '../../utils'
import { TimeBasedDocStatus, ValidEmploymentStatus } from '../../types'

export class TimeBasedDocInstance {
  private idPassport = ''
  private competency = ''
  private status = '' as TimeBasedDocStatus
  private expiryDate = ''
  private name = ''
  private surname = ''
  private employmentStatus = 'EMPLOYEE' as ValidEmploymentStatus
  private workSite = ''
  private filename = ''

  constructor(json: any) {
    if (json === undefined) {
      return
    }
    this.idPassport = json.idPassport ? json.idPassport : '-'
    this.competency = json.competency ? removeUnderscores(json.competency) : 'NONE'
    this.status = json.status as TimeBasedDocStatus
    this.expiryDate = json.expiryDate ? json.expiryDate : '-'
    this.name = json.name ? json.name : '-'
    this.surname = json.surname ? json.surname : '-'
    this.employmentStatus = json.employmentStatus ? json.employmentStatus : '-'
    this.workSite = json.workSite ? removeUnderscores(json.workSite) : '-'
    this.filename = json.filename ? json.filename : ''
  }

  getIdPassport(): string {
    return this.idPassport
  }

  getStatus() {
    return this.status
  }

  getCompetency() {
    return this.competency
  }

  getExpiryDate() {
    return this.expiryDate
  }

  getMetaData() {
    return {
      idPassport: this.idPassport,
      competency: this.competency,
      status: this.status,
      expiryDate: this.expiryDate,
      name: this.name,
      surname: this.surname,
      employmentStatus: this.employmentStatus,
      workSite: this.workSite,
      filename: this.filename,
    }
  }
}

export class TimeBasedDocInstanceFactory {
  static create(json: any): TimeBasedDocInstance {
    if (json === undefined) {
      json = []
    }
    return new TimeBasedDocInstance(json)
  }
}
