export enum ErrorCodeTypes {
  ALREADY_EXISTS = 'AlreadyExists',
  ASSOCIATION_MISMATCH = 'AssociationMismatch',
  NO_WEB_ACCESS = 'NoWebAccess',
  LOGIN_FAILED = 'LoginFailed',
  INVALID_REQUEST_INSTANCE_TIMESTAMP = 'InvalidRequestInstanceTimestamp',
  NO_DOCS = 'NoDocs',
  NO_EXPORT_PROFILES_SELECTED = 'NoExportProfilesSelected',
  NO_EXPORT_PROFILES_SELECTED_FROM_PROFILES_PAGE = 'NoExportProfilesSelectedFromProfilesPage',
  NO_INPUT = 'NoInput',
  NO_TOKEN = 'NoToken',
  PASSWORD_MISMATCH = 'PasswordMismatch',
  INVALID_PASSWORD = 'InvalidPassword',
  MISSING_INFO = 'MissingInfo',
  NEW_PASSWORD_REQUIRED = 'NewPasswordRequired',
  NETWORK_TIMEOUT = 'NetworkTimeout',
}

export enum DataQueryErrorCodesEnum {
  MaxTimeRangeExceeded = 'MaxTimeRangeExceeded',
  MissingQueryId = 'MissingQueryId',
  DuplicateTrendName = 'DuplicateTrendName',
}
