import { useState } from 'react'
import { Dialog } from '@material-ui/core'

import { ProfileService } from '../../services'
import { ColorPalette } from '../../config/colors'
import ButtonBlue from '../BaseComponents/Buttons/ButtonBlue'
import { ActiveDateRange } from '../../types'
import GroupDetails from './GroupDetails'
import ButtonGrey from '../BaseComponents/Buttons/ButtonGrey'

const profileService = new ProfileService()

type GroupAddModalProps = {
  toggle: () => void
  onSave: (newGroupName: string, startDate: Date, endDate: Date) => void
}

const initialDate = new Date()

export const GroupAddModal = ({ toggle, onSave }: GroupAddModalProps) => {
  const [name, setName] = useState('')
  const [activeDateRange, setActiveDateRange] = useState<ActiveDateRange>({
    startDate: initialDate,
    endDate: initialDate,
  })
  // const [startDate, setStartDate] = useState<Date>(initialDate);
  // const [expiryDate, setExpiryDate] = useState<Date>(initialDate);

  // const resetNewGroupInputValues = () => {
  //     setDivision("");
  //     setName("");
  //     setActiveDateRange({ startDate: initialDate, endDate: initialDate });
  // };

  const requiredInputsProvided =
    !!name.length &&
    (activeDateRange.startDate.getMilliseconds() !== initialDate.getMilliseconds() ||
      activeDateRange.endDate.getMilliseconds() !== initialDate.getMilliseconds())

  return (
    // TODO: use /components/Modals/Modal.tsx after web factor lands
    <Dialog
      open={true}
      onBackdropClick={toggle}
      BackdropProps={{ invisible: true }}
      PaperProps={{ style: { borderRadius: 8 } }}
      disableEnforceFocus={true} // necessary to allow keyboard inputn on the group name modal to work [MUI does not allow keyboard input on modals (via Portal) over Dialog]
      style={{ backgroundColor: ColorPalette.MODAL_BACKGROUND_OVERLAY }}>
      <div
        style={{
          boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
          padding: '2.5em',
          borderRadius: 8,
        }}>
        <h1 style={styles.title}>Add New Group</h1>
        <GroupDetails onSetGroupName={setName} onSetActiveDateRange={setActiveDateRange} />
        <div style={{ marginTop: '2.5em', display: 'flex', gap: '1.5em' }}>
          <ButtonGrey onClick={toggle}>Cancel</ButtonGrey>
          <ButtonBlue
            onClick={() => {
              onSave(name, activeDateRange.startDate, activeDateRange.endDate)
              toggle()
            }}
            disabled={!requiredInputsProvided}>
            Save
          </ButtonBlue>
        </div>
      </div>
    </Dialog>
  )
}

const styles = {
  title: {
    marginTop: 0,
    marginBottom: '1.5em',
    textAlign: 'center' as 'center',
    alignSelf: 'center',
    fontFamily: 'roboto',
    color: ColorPalette.PRIMARY_TEXT,
    paddingLeft: 10,
    paddingRight: 10,
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
}
