import { Component } from 'react'
import Radium from 'radium'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { RouteComponentProps } from 'react-router'

class TermsAndConditions extends Component<RouteComponentProps> {
  goBack() {
    this.props.history.goBack()
  }

  render() {
    return (
      <Grid
        style={{ backgroundColor: 'rgba(250,250,250,1)' }}
        container
        direction="column"
        justify="flex-start"
        alignItems="center">
        <Button
          onClick={() => this.goBack()}
          style={{ width: '100%', height: 40, backgroundColor: 'rgba(230,230,230,1)' }}
          color="primary">
          {' '}
          Done{' '}
        </Button>

        <h1 style={heading}>1 INTRODUCTION</h1>
        <h1 style={body}>
          {' '}
          1.1 These terms and conditions (“terms and conditions”) apply in respect of the PeopleFlow services, which may
          be accessed on the website and the application (collectively, “IT platforms” and a reference to IT platform
          shall be a reference to any one of them as the context may require). Please read these terms and conditions
          carefully prior to accessing and using the PeopleFlow services as defined herein.{' '}
        </h1>
        <h1 style={body}>
          {' '}
          1.2 Please browse or use the IT platform/s for your own personal and non-commercial use only, and for no other
          purpose. By using the IT platform/s you indemnify PeopleFlow against all loss, damage, expense, harm, claim
          and any other cost whatever that you may incur as a result of your use of the IT platform/s and/or its/their
          contents contrary to these terms and conditions.{' '}
        </h1>
        <h1 style={body}>
          {' '}
          1.3 By using the IT platform/s, you confirm that you have read and understood the terms and conditions and
          agree to be bound by them. These terms and conditions constitute the whole of the agreement between PeopleFlow
          and you relating to the matters dealt with herein and save to the extent otherwise provided herein, no
          undertaking, representation, term or condition relating to the subject matter of these terms and conditions
          not incorporated in these terms and conditions shall be binding on PeopleFlow and yourself.{' '}
        </h1>
        <h1 style={body}>
          {' '}
          1.4 PeopleFlow reserves the right to suspend the IT platform/s or any parts thereof at any time, at its sole
          discretion.{' '}
        </h1>

        <h1 style={heading}>2 INTERPRETATION AND DEFINITIONS</h1>
        <h1 style={body}>
          {' '}
          2.1 “applicable laws” means all laws, statutes, ordinances, by-laws or other legislative measures relevant and
          applicable to the implementation of these terms and conditions;
        </h1>
        <h1 style={body}>
          {' '}
          2.2 “application” means the software application developed by PeopleFlow specifically in respect of the
          PeopleFlow services, to be downloaded and used on a mobile device;
        </h1>
        <h1 style={body}>
          {' '}
          2.3 “business day” means any day other than a Saturday, Sunday or public holiday in the Republic of South
          Africa;{' '}
        </h1>
        <h1 style={body}>
          {' '}
          2.4 “commencement date” means the date of registration by you on one of the IT platforms (as contemplated in
          terms of clause 3);{' '}
        </h1>
        <h1 style={body}>
          {' '}
          2.5 "PeopleFlow" means PeopleFlow Proprietary Limited (registration number 2018/495077/07);
        </h1>
        <h1 style={body}>
          {' '}
          2.6 "PeopleFlow services” means the provision of automated high-end access control systems and functions by
          PeopleFlow through the IT platform/s;
        </h1>
        <h1 style={body}>
          {' '}
          2.7 “mobile device” means any portable computing device capable of accessing the internet, including without
          limitation a smartphone or tablet computer;
        </h1>
        <h1 style={body}>
          {' '}
          2.8 “personal information” shall have the same meaning as is ascribed to it in section 1 of the Protection of
          Personal Information Act 4 of 2013, and which includes without limitation, information in respect of –
        </h1>
        <h1 style={body2}>
          {' '}
          2.8.1 an individual, such individual’s name, identity number, age, email addresses, telephone/mobile number/s,
          physical addresses and credit or debit card details; and
        </h1>
        <h1 style={body2}>
          {' '}
          2.8.2 an entity, such entity’s name, registration number, VAT number, email addresses, telephone/mobile
          number/s, physical addresses and credit or debit card details and. If necessary, details of the directors and
          employees of such entity as well as the banking details thereof;
        </h1>
        <h1 style={body}>2.9 “website” means www.helixsa.co.za </h1>
        <h1 style={body}>
          {' '}
          2.10 Where any term is defined within any clause other than this clause 2, the term so defined shall, for all
          purposes, bear the meaning ascribed to it in that clause unless it is clear from the context that such meaning
          is to be restricted exclusively to the use of that term within that clause.
        </h1>
        <h1 style={body}>
          {' '}
          2.11 The use of a specific example (whether or not after the word “including” or “such as”) shall not be
          construed as limiting the meaning of the general wording preceding it and the eiusdem generis rule shall not
          be applied in the interpretation of such general wording or such specific example/s. Accordingly, without
          limiting the generality of the aforegoing, wherever the words “includes or including” are used in these terms
          and conditions, the words “without limitation” shall be deemed to follow them.
        </h1>
        <h1 style={body}>
          {' '}
          2.12 The rule of construction that the contract shall be interpreted against the party responsible for the
          drafting or preparation of the agreement (the contra proferentem rule) shall not apply.
        </h1>

        <h1 style={heading}>3 REGISTRATION </h1>
        <h1 style={body}>
          {' '}
          3.1 The IT platforms are supplied by PeopleFlow. PeopleFlow reserves the right to suspend the IT platform/s or
          any parts thereof at any time, at its sole discretion.
        </h1>
        <h1 style={body}>
          {' '}
          3.2 In order to register and create a personal account ("PeopleFlow account”) with PeopleFlow for the
          provision by PeopleFlow of the PeopleFlow services, you shall be required –
        </h1>
        <h1 style={body2}> 3.2.1 as an individual or entity, to – </h1>
        <h1 style={body3}> 3.2.1.1 be over the age of 16 years;</h1>
        <h1 style={body3}>
          {' '}
          3.2.1.2 download the application onto a mobile device. Please ensure that you download the correct application
          and that the application is compatible with the mobile device. For the purposes of clarity, the application
          will only apply in respect of the single mobile device onto which the application is downloaded; or{' '}
        </h1>
        <h1 style={body3}> 3.2.1.3 access the website directly; and </h1>
        <h1 style={body3}>
          {' '}
          3.2.1.4 furnish PeopleFlow with an email address and mobile number, subsequent to which a confirmation text
          shall then be sent to your mobile device for verification purposes; and{' '}
        </h1>
        <h1 style={body3}>
          {' '}
          3.2.1.5 create a username and password which will enable you to access your PeopleFlow account in order to
          have access to the application and the PeopleFlow services; and{' '}
        </h1>
        <h1 style={body3}>
          {' '}
          3.2.1.6 provide PeopleFlow with your/the entity’s personal information and any other information which
          PeopleFlow may deem to be necessary in order to render the PeopleFlow services.
        </h1>
        <h1 style={body}>
          {' '}
          3.3 Upon registration with PeopleFlow, whether through the application and/or the website, you shall be
          provided with a PeopleFlow account and a password of your choice which will enable you to access your
          PeopleFlow account in order to utilise the PeopleFlow services.
        </h1>
        <h1 style={body}>
          {' '}
          3.4 It is specifically recorded that PeopleFlow reserves the right to refuse and/or reject, for any reason
          whatever, your application for registration and the use of the PeopleFlow services.{' '}
        </h1>

        <h1 style={heading}>4 DURATION AND CANCELLATION</h1>
        <h1 style={body}>
          {' '}
          4.1 These terms and conditions shall commence on the commencement date, and unless terminated earlier by
          PeopleFlow or you in terms of these terms and conditions, shall continue in force indefinitely.
        </h1>
        <h1 style={body}>
          {' '}
          4.2 You or PeopleFlow are entitled to cancel these terms and conditions immediately by sending an email to
          hello@helixsa.co.za and requesting that your account be deregistred.
        </h1>

        <h1 style={heading}>5 USE OF HELIXSA’s LOGOS, CONTENT AND IMAGES </h1>
        <h1 style={body}>
          {' '}
          5.1 You are prohibited from using the content of the IT platforms, PeopleFlow logos or any other images that
          appear on the IT platforms without the prior written consent of PeopleFlow. A failure to comply with this will
          constitute an unlawful infringement of the intellectual property rights of PeopleFlow and its licensors.
        </h1>

        <h1 style={heading}>6 ADVERTISING AND HYPERLINKS </h1>
        <h1 style={body}>
          {' '}
          6.1 External hyperlinks and advertising may appear on the IT platforms. These hyperlinks do not constitute any
          relationship between PeopleFlow and any linked third party or any endorsement by PeopleFlow of such third
          party. PeopleFlow does not necessarily endorse such third parties or their products and/or services. Your
          reliance on any information via the external hyperlinks, advertising or otherwise contained in such material
          is entirely at your own risk.
        </h1>

        <h1 style={heading}>7 FORCE MAJEURE </h1>
        <h1 style={body}>
          {' '}
          7.1 PeopleFlow shall not be liable to you for any breach, hindrance or delay in the performance of any
          obligation attributable to any cause beyond the reasonable control of PeopleFlow, including without limitation
          any natural disaster and unavoidable incident, actions of third parties (including without limitation hackers,
          suppliers, governments, quasi-governmental, supra national or local authorities), insurrection, riot, civil
          commotion, war, hostilities, warlike operations, national emergencies, terrorism, piracy, arrests, restraints
          or detainments of any competent authority, strikes or combinations or lock out of workmen, epidemic, fire,
          explosion, storm, flood, drought, weather conditions, earthquake, natural disaster, accident, mechanical
          breakdown, third party software, failure or problems with public utility supplies (including electrical,
          telecoms or Internet failure), shortage of or inability to obtain supplies, materials, equipment or
          transportation (“event of force majeure”), regardless of whether the circumstances in question could have been
          foreseen.
        </h1>
        <h1 style={body}>
          {' '}
          7.2 Either you or PeopleFlow may terminate any contract forthwith by written notice to the other in the event
          that the event of force majeure lasts for a period of 90 business days or more, in which event neither you nor
          PeopleFlow shall be liable to the other by reason of such termination.{' '}
        </h1>

        <h1 style={heading}>8 WARRANTIES</h1>
        <h1 style={body}> 8.1 You hereby warrant that -</h1>
        <h1 style={body2}>
          {' '}
          8.1.1 all information, including without limitation, all information in respect of (i) registration
          contemplated in clause 3; and (ii) your use of the PeopleFlow services; provided by you to PeopleFlow are true
          and correct in all respects. PeopleFlow reserves the right to verify, at any time without your prior consent,
          all information provided by you to PeopleFlow as aforesaid;
        </h1>
        <h1 style={body2}>
          {' '}
          8.1.2 your use of the IT platform/s shall be solely in respect of your own personal use or for the use
          required by an entity and not for a commercial purpose;
        </h1>
        <h1 style={body2}>
          {' '}
          8.1.3 your PeopleFlow account shall be accessed and used solely by you as an individual or entity, as the case
          may be, and no other person shall be entitled, in any manner whatever and whether with or without your
          consent, to access and/or use your PeopleFlow account;
        </h1>
        <h1 style={body2}>
          {' '}
          8.1.4 you shall not, in any manner whatever, delegate any obligations or cede any rights to any other person
          in respect of your PeopleFlow account;
        </h1>
        <h1 style={body2}>
          {' '}
          8.1.5 your use of the (i) IT platform/s; and/or (ii) PeopleFlow services; shall at all times be lawful and
          shall not be used, whether directly and/or indirectly, for illegal and/or fraudulent purposes;
        </h1>
        <h1 style={body2}>
          {' '}
          8.1.6 the IT platform/s shall not be used in a manner which may cause, whether directly and/or indirectly,
          disruption and/or damage to any third party;
        </h1>
        <h1 style={body2}>
          {' '}
          8.1.7 you, nor any other person/s acting under your direction and/or control, shall not in any manner whatever
          (i) impair, obstruct and/or harm the operation of the network and/or the software used in respect of the IT
          platform/s; and/or (ii) damage or destroy the IT platform/s;
        </h1>
        <h1 style={body2}>
          {' '}
          8.1.8 you shall not reproduce or distribute, in any manner or form whatever, inter alia, the content and/or
          software of the IT platform/s.
        </h1>

        <h1 style={heading}>9 PROTECTION OF PERSONAL INFORMATION</h1>
        <h1 style={body2}>
          {' '}
          9.1.1 agree and acknowledge that in order for PeopleFlow to render the PeopleFlow services, PeopleFlow shall
          require access to and use of your personal information; and{' '}
        </h1>
        <h1 style={body2}>
          {' '}
          9.1.2 consent to PeopleFlow accessing and using such personal information in order to render the PeopleFlow
          services.
        </h1>
        <h1 style={body}> 9.2 PeopleFlow agrees that it shall –</h1>
        <h1 style={body2}>
          {' '}
          9.2.1 safeguard and protect your personal information in its possession and/or under its control;
        </h1>
        <h1 style={body2}> 9.2.2 use your personal information only for –</h1>
        <h1 style={body3}>
          {' '}
          9.2.2.1 the purposes for which such information was received (ie in order for PeopleFlow to render the
          PeopleFlow services); and
        </h1>
        <h1 style={body3}>
          {' '}
          9.2.2.2 marketing special offers in respect of the PeopleFlow services, unless indicated otherwise by you in
          writing;{' '}
        </h1>
        <h1 style={body2}>
          {' '}
          9.2.3 not use your personal information for any purposes other than those contained in clause 9.2.2;
        </h1>
        <h1 style={body2}>
          {' '}
          9.2.4 not disclose, sell or rent your personal information to third parties without your consent unless
          PeopleFlow is compelled to do so by law. PeopleFlow may do so if you have granted consent thereto;{' '}
        </h1>
        <h1 style={body2}>
          {' '}
          9.2.5 update your personal information as and when required by you, in writing. For the purposes of clarity,
          it is recorded that PeopleFlow shall not update and/or verify your personal information without having
          acquired your prior written instructions and PeopleFlow shall not, save for gross negligence on its part, be
          liable for, inter alia, any errors, omissions and/or unauthorised access to your personal information; and
        </h1>
        <h1 style={body2}> 9.2.6 allow you access to your personal information as and when required by you.</h1>
        <h1 style={body}>
          {' '}
          9.3 PeopleFlow will only share your personal information with parties that help it to provide the PeopleFlow
          services to you and which have agreed to keep your personal information secure and confidential as set out
          herein.
        </h1>
        <h1 style={body}>
          {' '}
          9.4 PeopleFlow will ensure that all of its employees and third party service providers having access to your
          personal information are bound by appropriate and legally binding confidentiality obligations in relation to
          your personal information.
        </h1>
        <h1 style={body}>
          {' '}
          9.5 PeopleFlow undertakes not to retain your personal information for longer than the period for which it was
          originally needed, unless it is required by law to do so, or you consent to PeopleFlow retaining such
          information for a longer period.
        </h1>
        <h1 style={body}>
          {' '}
          9.6 Upon request, PeopleFlow will provide you with a summary of any personal information collected and
          retained by PeopleFlow regarding you. You may modify, correct, or update your personal information or request
          to have your personal information removed from HelixSA’s database.{' '}
        </h1>
        <h1 style={body}>
          {' '}
          9.7 You hereby acknowledge and agree that in order for PeopleFlow to render the PeopleFlow services,
          PeopleFlow shall require access to and use your personal information such as, inter alia, your facial
          dimensions and photographs of your person, recordings of your voice as well as your motor vehicle details for
          its authentication process and in order to improve its algorithm.{' '}
        </h1>
        <h1 style={body}>
          {' '}
          9.8 Notwithstanding the provisions of this clause 9, PeopleFlow cannot guarantee the absolute security of any
          information you exchange with it. PeopleFlow will however take reasonable steps in an attempt to protect and
          safeguard your personal information.
        </h1>

        <h1 style={heading}>10 LIMITATION OF LIABILITY </h1>
        <h1 style={body}>
          {' '}
          10.1 Your use of the IT platform/s is entirely at your own risk. PeopleFlow makes no representations or
          warranties of any kind, whether express or implied. PeopleFlow accepts no liability, to the extent permitted
          by law, for any damages, however arising, whether direct, indirect, incidental, special or consequential loss
          from the access or use of the IT platform/s or the provision of any of the PeopleFlow services.
        </h1>
        <h1 style={body}>
          {' '}
          10.2 PeopleFlow shall not be liable for any damages arising out of the use (or inability to use) the IT
          platform/s, including without limitation, damages resulting from any (i) failure and/or delay in the
          transmission of electronic correspondence and/or communication; and/or (ii) unauthorised access and/or
          manipulation of such correspondence by third parties and/or computer program/s.
        </h1>

        <h1 style={heading}>11 INDEMNITIES</h1>
        <h1 style={body}>
          {' '}
          11.1 PeopleFlow owns all rights, title and interest, including without limitation, all related intellectual
          property rights in and to the IT platform/s and/or other material on the IT platform/s. You are only permitted
          to view, print or store electronically a copy of any information on the IT platform/s, including these terms
          and conditions, solely for your personal, lawful, non commercial use and a failure to do so will constitute an
          unlawful infringement of the intellectual property rights of PeopleFlow or its licensors.{' '}
        </h1>

        <h1 style={heading}>12 INTELLECTUAL PROPERTY</h1>
        <h1 style={body}>
          {' '}
          12.1 PeopleFlow owns all rights, title and interest, including without limitation, all related intellectual
          property rights in and to the IT platform/s and/or other material on the IT platform/s. You are only permitted
          to view, print or store electronically a copy of any information on the IT platform/s, including these terms
          and conditions, solely for your personal, lawful, non commercial use and a failure to do so will constitute an
          unlawful infringement of the intellectual property rights of PeopleFlow or its licensors.{' '}
        </h1>

        <h1 style={heading}>13 NOTICES</h1>
        <h1 style={body}>
          13.1 Any notice under these terms and conditions shall be in writing and may be served by personal delivery or
          by email to the relevant party at any physical and/or postal and/or email address of the relevant party last
          known to the other. PeopleFlow chooses: PO Box 1855, Bromhof, 2154, as its physical address for receipt of
          legal service.
        </h1>

        <h1 style={heading}>14 ACCURACY OF WEBSITE CONTENT</h1>
        <h1 style={body}>
          14.1 PeopleFlow does not warrant that the content or information displayed on the IT platforms is always
          accurate, complete and/or current.
        </h1>
        <h1 style={body}>
          14.2 PeopleFlow reserves the right at any time to change or discontinue without notice, any aspect or feature
          of the IT platforms. No Information contained on the IT platforms shall be construed as advice and same is
          offered for information purposes only.
        </h1>

        <h1 style={heading}>15 AMENDED OR UPDATED TERMS</h1>
        <h1 style={body}>
          15.1 PeopleFlow may periodically update or change these terms and conditions without notice. Please ensure
          that you check them from time to time, as your continued use of the IT platform/s shall mean that you accept
          any updated or revised terms and conditions.
        </h1>
        <h1 style={body}>
          15.2 Accordingly you agree to review these terms and conditions periodically, and your continued access or use
          of the IT platform/s shall be deemed to be your acceptance, from time to time, of the terms and conditions as
          amended.
        </h1>

        <h1 style={heading}>16 GOVERNING LAW AND JURISDICTION</h1>
        <h1 style={body}>
          16.1 These terms and conditions are governed by and construed under the laws of the Republic of South Africa
          and you hereby consent to the non-exclusive jurisdiction of the High Court of South Africa, Gauteng Local
          Division, South Africa.
        </h1>

        <h1 style={heading}>17 GENERAL</h1>
        <h1 style={body}>
          17.1 These terms and conditions shall commence from the date on which they are published on the IT platform/s
          and continue indefinitely, as amended by PeopleFlow from time to time, for so long as the IT platform/s exist
          and are operational. PeopleFlow shall be entitled to terminate these terms and conditions and/or shut down the
          IT platform/s at any time.
        </h1>
        <h1 style={body}>
          17.2 No failure or delay by PeopleFlow in exercising any right under these terms and conditions shall operate
          as a waiver of such right or extend to or affect any other or subsequent event or impair any rights or
          remedies in respect of it or in any way modify or diminish the rights of PeopleFlow under these terms and
          conditions.
        </h1>
        <h1 style={body}>
          17.3 Any and all copyright subsisting in the IT platform/s vests in PeopleFlow or its licensors, as the case
          may be, and all rights not expressly granted are reserved.
        </h1>
      </Grid>
    )
  }
}
const heading = {
  color: 'rgba(30,30,30, 1)',
  fontSize: '1rem',
  marginTop: 40,
  fontFamily: 'roboto',
  fontWeight: 'bold',
  width: '90%',
  display: 'flex',
  flexDirection: 'row' as 'row',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'justify' as 'justify',
}

const body = {
  color: 'rgba(30,30,30, 1)',
  fontSize: '0.9rem',
  marginTop: 20,
  fontFamily: 'roboto',
  fontWeight: 'normal',
  width: '90%',
  display: 'flex',
  flexDirection: 'row' as 'row',
  alignItems: 'center',
  justifyContent: 'left',
  textAlign: 'justify' as 'justify',
}

const body2 = {
  color: 'rgba(30,30,30, 1)',
  fontSize: '0.9rem',
  marginTop: 20,
  marginLeft: 40,
  fontFamily: 'roboto',
  fontWeight: 'normal',
  width: '90%',
  display: 'flex',
  flexDirection: 'row' as 'row',
  alignItems: 'center',
  justifyContent: 'left',
  textAlign: 'justify' as 'justify',
}

const body3 = {
  color: 'rgba(30,30,30, 1)',
  fontSize: '0.9rem',
  marginTop: 20,
  marginLeft: 100,
  fontFamily: 'roboto',
  fontWeight: 'normal',
  width: '90%',
  display: 'flex',
  flexDirection: 'row' as 'row',
  alignItems: 'center',
  justifyContent: 'left',
  textAlign: 'justify' as 'center',
}

export default Radium(TermsAndConditions)
