import { ColorPalette } from '../../../config/colors'
import { toLowerCaseCustom, addUnderScores, removeUnderscores } from '../../../utils'

import React, { useState } from 'react'
import Radium from 'radium'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import Icon from '@mdi/react'
import {
  mdiAccountMultiple,
  mdiAccountVoice,
  mdiFileDocumentEdit,
  mdiCloseCircleOutline,
  mdiAccountAlert,
  mdiDragHorizontal,
  mdiTrashCan,
} from '@mdi/js'
import ButtonBlue from '../../BaseComponents/Buttons/ButtonBlue'
import OptionsSelectorModal from '../../Modals/OptionsSelectorModal'
import ModalPortal from '../../Modals/ModalPortal'

dayjs.extend(relativeTime)

interface IDisciplineActionTileProps {
  action: string
  expiry?: number
  draggable?: boolean
  onDeleteClick?: () => void
  onExpiryChange?: (expiry: number) => void
}

const DisciplineActionTile: React.FunctionComponent<IDisciplineActionTileProps> = (
  props: IDisciplineActionTileProps,
) => {
  const [optionsSelectorModalOpen, setOptionsSelectorModalOpen] = useState(false)
  const [expiryInMilliseconds, setExpiryInMilliseconds] = useState(props.expiry || 2592000000) // 30 days in milliseconds

  const iconForAction: Record<string, string> = {
    counsel: mdiAccountMultiple,
    verbal: mdiAccountVoice,
    'written_(1)': mdiFileDocumentEdit,
    'written_(2)': mdiFileDocumentEdit,
    'written_(final)': mdiFileDocumentEdit,
    'notice_(1)': mdiAccountAlert,
    'notice_(2)': mdiAccountAlert,
    terminate: mdiCloseCircleOutline,
  }

  const toggleOptionsSelectorModal = () => setOptionsSelectorModalOpen((currentValue) => !currentValue)

  return (
    <div style={styles.container}>
      <div
        style={{
          fontSize: '3em',
          position: 'absolute' as 'absolute',
          top: '-4%',
          left: '8%',
          cursor: 'pointer',
        }}
        onClick={props.onDeleteClick}>
        <Icon size={0.9} path={mdiTrashCan} color={ColorPalette.WARNING_RED} />
      </div>
      {props.draggable && (
        <div
          style={{
            fontSize: '3em',
            // color: ColorPalette.PRIMARY_BLUE,
            position: 'absolute' as 'absolute',
            top: '-2%',
            right: '8%',
          }}>
          <Icon size={1.3} path={mdiDragHorizontal} color={ColorPalette.MEDIUM_GREY} />
        </div>
      )}
      <button style={{ ...styles.card }}>
        <Icon
          size={1.4}
          path={iconForAction[addUnderScores(toLowerCaseCustom(props.action))]}
          color={ColorPalette.LIGHT_GREY}
        />

        <div
          style={{
            ...styles.dividerLine,
            borderTop: `1px solid ${ColorPalette.LIGHT_GREY}`,
          }}
        />
        <p style={{ ...styles.headerTabText }}>{removeUnderscores(props.action)}</p>
        <div style={{ ...styles.tabTimeText, marginTop: 10 }}>
          {props.expiry ? (
            <div style={{ fontSize: '0.8rem' }}>
              Expires:
              <ButtonBlue
                style={{
                  marginTop: '0.7em',
                  marginBottom: '1.5em',
                  fontSize: '0.75rem',
                  padding: '0.4em 1.95em',
                  height: 'auto',
                }}
                onClick={toggleOptionsSelectorModal}>
                {Math.round(expiryInMilliseconds / 86400000)} DAYS
              </ButtonBlue>
            </div>
          ) : (
            <div style={{ height: '4.25em' }}>
              <b>No Expiry</b>
            </div>
          )}
        </div>
      </button>
      <ModalPortal>
        <OptionsSelectorModal
          open={optionsSelectorModalOpen}
          onSuccess={() => {
            if (props.onExpiryChange) {
              props.onExpiryChange(expiryInMilliseconds)
            }
            toggleOptionsSelectorModal()
          }}
          onReject={() => {
            setExpiryInMilliseconds(props.expiry || 2592000000)
            toggleOptionsSelectorModal()
          }}
          cancelLabel="Cancel"
          successLabel="Save"
          label1="Expiration"
          options1={['30 days', '60 days', '90 days', '180 days', '360 days']}
          selectedOption1={`${expiryInMilliseconds / 86400000} days`}
          onChange={(e) => {
            const expiryInDays = parseInt(e.target.value.split(' ')[0])
            const expiryInMs = expiryInDays * 86400000
            setExpiryInMilliseconds(expiryInMs)
          }}
        />
      </ModalPortal>
    </div>
  )
}

const styles = {
  card: {
    margin: 2,
    width: window.innerWidth * 0.12,
    height: window.innerWidth * 0.11,
    backgroundImage: `linear-gradient(to bottom, ${ColorPalette.CARD_WHITE}, ${ColorPalette.OFF_WHITE})`,
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 2px 6px rgba(0, 0, 60, 0.16)',
    outline: 0,
    border: 'none',
    paddingTop: '2em',
  },
  dividerLine: {
    width: '60%',
    marginTop: '4%',
  },
  tabTimeText: {
    margin: 0,
    marginTop: 10,
    fontSize: '0.65rem',
    color: ColorPalette.LIGHT_GREY,
  },
  headerTabText: {
    margin: 0,
    marginTop: 10,
    fontSize: '0.8rem',
    textTransform: 'uppercase' as 'uppercase',
    color: ColorPalette.PRIMARY_TEXT,
  },
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative' as 'relative',
    userSelect: 'none' as 'none',
  },
}

export default Radium(DisciplineActionTile)
