import { Regex } from './regexUtils'
import { checkDigitsOnly, checkPasswordFormat } from './regexUtils'
import { IResult } from './resultUtils'

export function cleanupOneWordString(oneWordString: string): string {
  oneWordString = oneWordString.toUpperCase().replace(Regex.REMOVE_FORWARD_SLASH, '')
  oneWordString = oneWordString.replace(Regex.REMOVE_SPACES, ' ')
  oneWordString = oneWordString.trim()
  return oneWordString
}

export function validateLoginInputs(idPassport: string, password: string): IResult {
  if (!idPassport.length || !password.length) {
    return { result: '', code: 'MissingInfo' }
  }
  if (!checkPasswordFormat(password)) {
    return { result: '', code: 'NotAuthorizedException' }
  }

  return { result: 'success' }
}

export function validateForgotPasswordInputs(otp: string, newPassword: string): IResult {
  if (!otp || otp.length < 6 || !checkDigitsOnly(otp)) {
    return { result: '', code: 'InvalidOtp' }
  }

  if (!checkPasswordFormat(newPassword)) {
    return { result: '', code: 'InvalidPassword' }
  }

  return { result: 'success', code: '' }
}

export function validateResetPasswordInputs(password: string, newPassword: string): IResult {
  if (!checkPasswordFormat(password)) {
    return { result: '', code: 'InvalidPassword' }
  }

  if (!checkPasswordFormat(newPassword)) {
    return { result: '', code: 'InvalidNewPassword' }
  }

  return { result: 'success', code: '' }
}

export function validateOtpInputs(otp: string): IResult {
  if (otp.length < 6) {
    return { result: '', code: 'InvalidOtp' }
  }

  return { result: 'success', code: '' }
}
