import { CSSProperties } from 'react'
import Radium from 'radium'
import Icon from '@mdi/react'
import { mdiChartBar } from '@mdi/js'

import { ColorPalette } from '../../../config'
import ButtonGrey from '../../BaseComponents/Buttons/ButtonGrey'
import { toUpperCaseCustom } from '../../../utils'

type TrendButtonProps = {
  isFirstButton?: boolean
  isLastbutton?: boolean
  label: string
  selected: boolean
  style?: CSSProperties
  onClick: () => void
}

const TrendButton = (props: TrendButtonProps) => {
  const { label = '', selected, style, onClick } = props
  let containerStyle = { ...styles.graphItem, ...style }
  if (selected) {
    containerStyle = {
      ...containerStyle,
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.45)',
      filter: 'brightness(85%)',
    }
  }
  if (props.isFirstButton) {
    containerStyle.borderTopLeftRadius = 20
    containerStyle.borderBottomLeftRadius = 20
  }
  if (props.isLastbutton) {
    containerStyle.borderTopRightRadius = 20
    containerStyle.borderBottomRightRadius = 20
  }
  return (
    <ButtonGrey style={{ ...containerStyle }} onClick={onClick} title={label} disabled={selected}>
      <Icon path={mdiChartBar} color={ColorPalette.BUTTON_WHITE} style={styles.graphItemIcon} />
      <span style={styles.graphItemText}>{label}</span>
    </ButtonGrey>
  )
}

export default Radium(TrendButton)

const styles = {
  graphItem: {
    borderRadius: 0,
    minWidth: 200,
  },
  graphItemIcon: {
    width: 16,
    marginRight: '0.75em',
    color: ColorPalette.BUTTON_WHITE,
  },
  graphItemText: {
    fontWeight: 550,
    overflowX: 'hidden' as 'hidden',
    textOverflow: 'ellipsis',
  },
}
