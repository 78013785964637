import { Dot } from '../components/GeneralUI/Dot/Dot'
import { GroupStatus } from '../types'
import { ColorPalette } from '../config'

export const getStatusDotColor = (memberStatus: string) => {
  const statusDotColorMap = {
    Upcoming: ColorPalette.PRIMARY_BLUE,
    Active: ColorPalette.DARK_GREY,
    Complete: ColorPalette.WARNING_RED,
  } as Record<string, string>
  if (memberStatus in statusDotColorMap) {
    return statusDotColorMap[memberStatus]
  }
  return ColorPalette.VERY_LIGHT_GREY
}

export const generateMemberStatusIndicator = (startDate: string, terminationDate: string) => {
  let memberStatus: GroupStatus = 'Upcoming'
  const today = new Date()
  const startDateObj = new Date(startDate)
  const terminationDateObj = new Date(terminationDate)
  if (startDateObj < today) {
    memberStatus = 'Active'
  }
  if (today > terminationDateObj) {
    memberStatus = 'Closed'
  }
  // const daysToExpiry = Math.ceil((terminationDateObj.getTime() - today.getTime()) / (1000 * 3600 * 24));
  // if (daysToExpiry <= 45 && daysToExpiry > 0) {
  //     memberStatus = "EXPIRES WITHIN 45 DAYS";
  // }

  return <StatusDot status={memberStatus} />
}

export const StatusDot = (props: { status: GroupStatus }) => (
  <Dot title={props.status} colour={getStatusDotColor(props.status)} />
)
