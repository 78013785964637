import { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Radium from 'radium'
import { Property } from 'csstype'
import { AllScreenNames, ColorPalette } from '../../config'

import { default as SubSubMenuComponent } from './SubSubMenu'

import { SubSubMenu as SubSubMenuType, SubMenu as SubMenuType } from '../../models'
import { toUpperCaseCustom } from '../../utils'
import { ImportExportService } from '../../services'
import { PeopleFlowCombinedReducer } from '../../store'

interface Props {
  subMenus: SubMenuType[]
  label: string
  subSubMenus?: SubSubMenuType
  selectedAssociation: string
  selectedRole: string
  primaryTableRef?: React.RefObject<any>
  rootPaths: AllScreenNames[]
  buttonsWithCustomActions: string[]
  onSubMenuClick: (subMenuLabel: AllScreenNames) => void
  onSubSubMenuClick: (subMenuLabel: AllScreenNames, subSubMenuName: AllScreenNames) => void
}

interface State {
  loadingModalMessage: string
  warningModalHeader: string
  warningModalMessage: string
  disciplineWarningMessage: string
  showItemSubMenu: boolean
  selectedDate: Date
  exportTemplate: string
}

class SubMenu extends Component<Props, State> {
  importExportService = new ImportExportService()

  state: State = {
    loadingModalMessage: '',
    warningModalHeader: '',
    warningModalMessage: '',
    disciplineWarningMessage: '',
    showItemSubMenu: true,
    selectedDate: new Date(),
    exportTemplate: 'MASTER',
  }

  getRoute(subMenuLabel: AllScreenNames) {
    let route = '/comingsoon'
    if (Object.values(AllScreenNames).includes(subMenuLabel)) {
      route = this.props.rootPaths.includes(subMenuLabel)
        ? `/${subMenuLabel.toLowerCase().replace(/\s/g, '')}`
        : `/${this.props.label.toLowerCase().replace(/\s/g, '')}/${subMenuLabel.toLowerCase().replace(/\s/g, '')}`
    }
    return route
  }

  render() {
    const subMenuButtons = [...this.props.subMenus].map((subMenu, i) => {
      const { screenName, label } = subMenu
      return (
        <div key={`div_${i}_${label}`}>
          <Link
            key={`${i}_${label}`}
            style={{ width: '100%', textDecoration: 'none' }}
            to={this.getRoute(screenName)}
            onClick={
              this.props.buttonsWithCustomActions.includes(screenName) ? (event) => event.preventDefault() : undefined
            }>
            <button
              key={`${i}_${label}`}
              style={styles.subMenuButton}
              onClick={() => this.props.onSubMenuClick(screenName)}>
              {toUpperCaseCustom(label)}
            </button>
          </Link>
          {this.state.showItemSubMenu && this.props.subSubMenus?.hasOwnProperty(screenName) ? (
            <SubSubMenuComponent
              parentScreen={screenName}
              menuOptions={this.props.subSubMenus}
              onSubSubMenuClick={(subSubMenuName: AllScreenNames) =>
                this.props.onSubSubMenuClick(screenName, subSubMenuName)
              }
            />
          ) : null}
        </div>
      )
    })

    return <div style={styles.subMenuContainer}>{subMenuButtons}</div>
  }
}

const hidden = {
  opacity: 0,
}

const visible = {
  opacity: 1,
}

const fadeIn = Radium.keyframes({
  '0%': hidden,
  '100%': visible,
}) as Property.AnimationName

let styles = {
  subMenuContainer: {
    zIndex: 100,
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'flex-start',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    boxShadow: '0px 3px 4px rgba(140,140,150, 0.5)',
    borderRadius: '0px 0px 10px 10px',
    padding: '20px 30px 20px 20px',
    marginRight: -window.innerWidth * 0.04,
  },
  subMenuButton: {
    fontSize: '0.8rem',
    fontWeight: '500',
    fontFamily: 'Roboto',
    color: ColorPalette.PRIMARY_TEXT,
    backgroundColor: 'rgba(255,255,255,0)',
    marginTop: 5,
    marginBottom: 5,
    height: 26,
    paddingLeft: 5,
    paddingRight: 20,
    paddingBottom: 10,
    outline: 'none',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: 'rgba(255,255,255,0)',
    borderBottomWidth: 2,
    borderBottomStyle: 'solid' as 'solid',
    borderBottomColor: 'rgba(255,255,255,0)',
    animation: 'x 0.3s ease-in',
    animationName: fadeIn,
    ':hover': {
      borderBottomColor: ColorPalette.PRIMARY_BLUE,
    },
    ':active': {
      filter: 'brightness(30%)',
    },
  },
}

const mapStateToProps = (state: PeopleFlowCombinedReducer) => {
  return {
    selectedAssociation: state.sessionManager.selectedAssociation,
    selectedRole: state.sessionManager.selectedRole,
  }
}

export default connect(mapStateToProps)(Radium(SubMenu))
