import React, { CSSProperties, Component } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import { GridSize } from '@material-ui/core/Grid'
import Radium from 'radium'
import get from 'lodash/get'
import equal from 'deep-equal'

import { DataTableRow } from './DataTableRow'

import { ColorPalette } from '../../../config/colors'
import { isEmpty } from 'lodash'

interface PrimaryTableProps {
  data: any[]
  disableRowClick?: boolean
  isSelectionEnabled?: boolean
  customComponentConfig: any
  tableWidth: GridSize
  authorisedItemIds: string[] | null
  selectedRows: any
  selectedRowItemId: string
  combinedSizeFactor: number
  columnConfig: any
  customFilterConfig?: { key: string; label: string }[]
  trimText?: boolean
  disabled?: boolean
  style?: React.CSSProperties
  applyCustomRowStyle?: (rowId: string) => React.CSSProperties | { ':hover': React.CSSProperties }
  onRowSelect: (rowData: Record<string, any>) => void
  onClick: (selectedrow: Record<string, any>) => void
}

class PrimaryTableBody extends Component<PrimaryTableProps> {
  shouldComponentUpdate(nextProps: Readonly<PrimaryTableProps>, nextState: Readonly<{}>, nextContext: any): boolean {
    return (
      !equal(this.props.data, nextProps.data) ||
      this.props.disableRowClick !== nextProps.disableRowClick ||
      this.props.isSelectionEnabled !== nextProps.isSelectionEnabled ||
      this.props.customComponentConfig !== nextProps.customComponentConfig ||
      this.props.tableWidth !== nextProps.tableWidth ||
      !equal(this.props.authorisedItemIds, nextProps.authorisedItemIds) ||
      !equal(this.props.selectedRows, nextProps.selectedRows) ||
      this.props.selectedRowItemId !== nextProps.selectedRowItemId ||
      this.props.combinedSizeFactor !== nextProps.combinedSizeFactor ||
      !equal(this.props.columnConfig, nextProps.columnConfig) ||
      !equal(this.props.customFilterConfig, nextProps.customFilterConfig) ||
      this.props.trimText !== nextProps.trimText ||
      this.props.disabled !== nextProps.disabled ||
      this.props.applyCustomRowStyle !== nextProps.applyCustomRowStyle ||
      this.props.onRowSelect !== nextProps.onRowSelect ||
      this.props.onClick !== nextProps.onClick
    )
  }

  render() {
    return (
      // height value below is arbitrary small value; makes the flexbox wrapper work, to always show the pagination buttons with the table rows
      <div style={{ height: 80, ...this.props.style }}>
        {this.props.data.map((rowData, i) => {
          const checkboxWidth = this.props.isSelectionEnabled ? 45 : 0
          const leftContainerWidth = get(this.props.customComponentConfig, 'leftComponentWidth', 0)
          const rightContainerWidth = get(this.props.customComponentConfig, 'rightComponentWidth', 0)
          const customFilterWidth = this.props.customFilterConfig && this.props.customFilterConfig.length ? 40 : 0
          const buttonWidth =
            (window.innerWidth * (this.props.tableWidth as number)) / 12 -
            checkboxWidth -
            customFilterWidth -
            leftContainerWidth -
            rightContainerWidth

          let disabled: boolean = !!this.props.disabled
          if (
            !this.props.disableRowClick &&
            this.props.authorisedItemIds !== null &&
            this.props.authorisedItemIds.length > 0
          ) {
            disabled =
              this.props.disabled || !(!this.props.disableRowClick && this.props.authorisedItemIds.includes(rowData.id))
          }
          let checkbox = null
          if (this.props.isSelectionEnabled) {
            const selectedRow = this.props.selectedRows.hasOwnProperty(rowData.id)
            checkbox = (
              <div
                key={`dataTable_checkbox_filter_${{ i }}`}
                style={{ ...styles.addonContainer, width: checkboxWidth, flexShrink: 0 }}>
                <Checkbox
                  style={{
                    color: selectedRow ? ColorPalette.PRIMARY_BLUE : ColorPalette.TERTIARY_TEXT,
                  }}
                  checked={selectedRow}
                  onChange={() => this.props.onRowSelect(rowData)}
                  inputProps={{ 'aria-label': 'Select all' }}
                  key={rowData.id}
                />
              </div>
            )
          }

          let customFilter = null
          if (this.props.customFilterConfig && this.props.customFilterConfig.length) {
            customFilter = <div key={'customFilter'} style={{ ...styles.addonContainer, width: customFilterWidth }} />
          }

          let rowStyle = {
            ...styles.rowStyle,
            backgroundColor: rowData.onHoverMessage ? 'rgb(220, 20, 60, 0.125)' : ColorPalette.CARD_WHITE,
          }
          if (!this.props.disabled) {
            rowStyle = {
              ...rowStyle,
              backgroundColor: rowData.onHoverMessage
                ? 'rgb(220, 20, 60, 0.125)'
                : rowData.id === this.props.selectedRowItemId
                ? 'rgba(232,232,240,1)'
                : ColorPalette.CARD_WHITE,
              color: !disabled ? ColorPalette.SECONDARY_TEXT : 'rgba(200,200,210,0.5)',
              // @ts-ignore
              ':hover': {
                backgroundColor: rowData.onHoverMessage ? 'rgb(220, 20, 60, 0.125)' : ColorPalette.BUTTON_HOVER_GREY,
              },
            }
          }
          if (this.props.applyCustomRowStyle) {
            const customRowStyle = this.props.applyCustomRowStyle(rowData.id)
            if (!isEmpty(customRowStyle)) {
              // @ts-ignore
              rowStyle = {
                ...rowStyle,
                ...customRowStyle,
              }
            }
          }
          let rowContainerStyle: CSSProperties = {
            ...styles.rowContainer,
          }
          if (this.props.isSelectionEnabled) {
            rowContainerStyle.borderBottom = `1px solid ${ColorPalette.VERY_LIGHT_GREY}`
          } else {
            rowContainerStyle.borderBottom = rowStyle.borderBottom
          }
          return (
            <DataTableRow
              buttonWidth={buttonWidth}
              checkbox={checkbox}
              columnConfig={this.props.columnConfig}
              combinedSizeFactor={this.props.combinedSizeFactor}
              customFilter={customFilter}
              disabled={disabled}
              leftComponent={
                <div key={'leftComponent'} style={{ ...styles.addonContainer, width: leftContainerWidth }}>
                  {get(rowData, 'leftComponent', null)}
                </div>
              }
              rightComponent={
                <div
                  key={'rightComponent'}
                  style={{
                    ...styles.addonContainer,
                    width: rightContainerWidth,
                  }}>
                  {get(rowData, 'rightComponent', null)}
                </div>
              }
              rowData={rowData}
              onClick={this.props.onClick}
              rowStyle={rowStyle}
              style={rowContainerStyle}
              key={`${i}_${rowData.id}`}
            />
          )
        })}
      </div>
    )
  }
}

const rowheight = 42
const styles = {
  rowContainer: {
    display: 'flex',
    height: rowheight,
    backgroundColor: ColorPalette.CARD_WHITE,
  },
  rowStyle: {
    height: rowheight,
    padding: 0,
    backgroundColor: ColorPalette.CARD_WHITE,
    color: ColorPalette.SECONDARY_TEXT,
    borderBottom: `1px solid ${ColorPalette.VERY_LIGHT_GREY}`,
    ':active': {
      backgroundColor: ColorPalette.BUTTON_ACTIVE_GREY,
    },
  },
  innerRowContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  addonContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

export default Radium(PrimaryTableBody)
