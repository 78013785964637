import React, { Component } from 'react'
import AlertModalOneButton from '../Modals/AlertModalOneButton'

export class ErrorBoundary extends Component {
  state: { hasError?: boolean; errorMessage?: string; errorInfoSummary?: string } = {}

  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true, errorMessage: error.message }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ errorInfoSummary: errorInfo.componentStack.split(')\n')[0] + ')' })
  }

  render() {
    if (this.state.hasError) {
      return (
        <AlertModalOneButton
          open={true}
          header="Application Error"
          body={
            <div>
              <p title={`${this.state.errorMessage} ${this.state.errorInfoSummary}`} style={{ cursor: 'help' }}>
                Something unexpected went wrong.
              </p>
              <p>
                You'll be redirected to the login page.
                <br />
                If problem persists, please contact customer support.
              </p>
            </div>
          }
          buttonLabel="Ok"
          onClick={() => (window.location.pathname = '/login')}
        />
      )
    }

    return this.props.children
  }
}
