import React, { Component } from 'react'
import { connect } from 'react-redux'
import { GridSize } from '@material-ui/core/Grid'
import { ScrollView } from '@cantonjs/react-scroll-view'
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess'
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore'
import GetAppIcon from '@material-ui/icons/GetApp'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

import DocumentTableHeader from './DocumentTableHeader'
import DocumentTableBody from './DocumentTableBody'
import { PeopleFlowCombinedReducer } from '../../../store'
import { ColorPalette } from '../../../config/colors'
import { IFileMeta } from '../../../interfaces'

import '../../../assets/css/scrollbar.css'

export type DocumentCategoryToFileMetadata = Record<string, IFileMeta[]>
export type DocViewerConfig = Record<string, DocumentCategoryToFileMetadata>
export type DocTableColumnConfig = {
  id: string
  label: string
  iconComponent: JSX.Element
}[]

interface DocumentTableState {
  allSelected: boolean
  height: number
}

interface DocumentTableProps {
  style?: React.CSSProperties | undefined
  scrollContainerStyle?: React.CSSProperties | undefined
  tableWidth: GridSize
  columnConfig?: DocTableColumnConfig
  folderSelectionEnabled?: boolean
  selectedFolders?: string[] | []
  expandedFolders: string[]
  data: DocumentCategoryToFileMetadata
  emptyFolderIconColor?: string
  hideMetaData?: boolean
  isSelectionEnabled?: boolean
  selectedRole: string

  folderSelectionHandler?: (folder: string) => void
  onToolbarClicked: (id: string) => void
  onDocClicked?: (docname: string) => void
  expandCollapseFolder: (folder: string) => void
  onSelectAll?: (selectAllState: boolean) => void
}

class DocumentTable extends Component<DocumentTableProps, DocumentTableState> {
  state: DocumentTableState = {
    allSelected: false,
    height: window.innerHeight,
  }

  onDocClicked(docname: string) {
    if (this.props.onDocClicked === undefined) {
      return
    }
    this.props.onDocClicked(docname)
  }

  onSelectAll() {
    if (!this.props.onSelectAll) {
      return
    }
    const { allSelected } = this.state
    this.props.onSelectAll(!allSelected)
    this.setState({ allSelected: !allSelected })
  }

  render() {
    if (this.props.selectedRole === 'AUDITOR') {
      // 1. prevent doc download through button (right click and save as still possible as well as taking screenshots)
      // 2. prevent doc deletion
      columnConfig.splice(2, 1)
    }
    return (
      <div style={{ ...styles.tableContainer, ...this.props.style, display: 'flex', flexDirection: 'column' }}>
        <DocumentTableHeader
          tableWidth={this.props.tableWidth}
          columnConfig={this.props.columnConfig ? this.props.columnConfig : columnConfig}
          isSelectionEnabled={this.props.isSelectionEnabled}
          onClick={(id: string) => this.props.onToolbarClicked(id)}
          onSelectAll={() => this.onSelectAll()}
        />
        <div className="scrollbar">
          <div
            style={{
              height: this.state.height * 0.58,
              maxHeight: this.state.height * 0.58,
            }}>
            <DocumentTableBody
              tableWidth={this.props.tableWidth}
              folderContents={this.props.data}
              expandedFolders={this.props.expandedFolders}
              folderSelectionEnabled={this.props.folderSelectionEnabled}
              selectedFolders={this.props.selectedFolders}
              folderSelectionHandler={this.props.folderSelectionHandler}
              expandCollapseFolder={this.props.expandCollapseFolder}
              onDocClicked={(docname) => this.onDocClicked(docname)}
              emptyFolderIconColor={this.props.emptyFolderIconColor}
              hideMetaData={this.props.hideMetaData}
            />
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  tableContainer: {
    width: '100%',
  },
  iconStyle: {
    color: ColorPalette.CARD_WHITE,
    width: '1.1rem',
    height: '1.1rem',
    marginRight: 10,
    marginLeft: 10,
  },
}

const columnConfig = [
  { id: 'expand', label: 'Expand', iconComponent: <UnfoldMoreIcon style={styles.iconStyle} /> },
  { id: 'collapse', label: 'Collapse', iconComponent: <UnfoldLessIcon style={styles.iconStyle} /> },
  { id: 'download', label: 'Download', iconComponent: <GetAppIcon style={styles.iconStyle} /> },
  { id: 'delete', label: 'Delete', iconComponent: <DeleteForeverIcon style={styles.iconStyle} /> },
]

const mapStateToProps = (state: PeopleFlowCombinedReducer) => {
  return {
    selectedRole: state.sessionManager.selectedRole,
  }
}

export default connect(mapStateToProps)(DocumentTable)
