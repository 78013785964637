import React, { useState, useEffect } from "react";
import { Prompt } from "react-router-dom";

import AlertModalTwoButton from "../../components/Modals/AlertModalTwoButton";

interface IRouteLeavingGuardProps {
    when?: boolean | undefined;
    navigate: (path: string) => void;
    shouldBlockNavigation: (location: Location) => boolean;
    alertHeader: string;
    alertBody: string | React.ReactElement;
}

// https://michaelchan-13570.medium.com/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
const RouteLeavingGuard = ({
    when,
    navigate,
    shouldBlockNavigation,
    alertHeader,
    alertBody,
}: IRouteLeavingGuardProps) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [lastLocation, setLastLocation] = useState<Location | null>(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);

    const closeModal = () => {
        setModalVisible(false);
    };

    const handleBlockedNavigation = (nextLocation: Location): boolean => {
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            setModalVisible(true);
            setLastLocation(nextLocation);
            return false;
        }
        return true;
    };

    const handleConfirmNavigationClick = () => {
        setModalVisible(false);
        setConfirmedNavigation(true);
    };

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            // navigate to the previous blocked location with your navigate function
            navigate(lastLocation.pathname);
        }
    }, [confirmedNavigation, lastLocation]);

    return (
        <>
            {/* @ts-ignore */}
            <Prompt when={when} message={handleBlockedNavigation} />
            <AlertModalTwoButton
                open={modalVisible}
                header={alertHeader}
                body={alertBody}
                buttonLabel1="No"
                buttonLabel2="Yes"
                onClick1={closeModal}
                onClick2={handleConfirmNavigationClick}
                dismiss={closeModal}
            />
        </>
    );
};

export default RouteLeavingGuard;
