import { ColorPalette } from '../../../config/colors'
import { toUpperCaseCustom, removeUnderscores } from '../../../utils'

import React from 'react'
import Radium from 'radium'
import Checkbox from '@material-ui/core/Checkbox'
import Icon from '@mdi/react'
import {
  mdiAlphaACircle,
  mdiAlphaBCircle,
  mdiAlphaCCircle,
  mdiAlphaDCircle,
  mdiAlphaECircle,
  mdiAlphaFCircle,
  mdiAlphaGCircle,
  mdiAlphaHCircle,
  mdiAlphaICircle,
  mdiAlphaJCircle,
  mdiAlphaKCircle,
  mdiAlphaLCircle,
  mdiAlphaMCircle,
  mdiAlphaNCircle,
  mdiAlphaOCircle,
  mdiAlphaPCircle,
  mdiAlphaQCircle,
  mdiAlphaRCircle,
  mdiAlphaSCircle,
  mdiAlphaTCircle,
  mdiAlphaUCircle,
  mdiAlphaVCircle,
  mdiAlphaWCircle,
  mdiAlphaXCircle,
  mdiAlphaYCircle,
  mdiAlphaZCircle,
} from '@mdi/js'
import { GridSize } from '@material-ui/core'
import { IFileMeta } from '../../../interfaces'

interface DocumentRowItemProps {
  expandedFolders: string[]
  folder: string
  docConfig: IFileMeta[]
  folderSelectionEnabled?: boolean
  folderSelected?: boolean
  tableWidth: GridSize
  metaData: string
  emptyFolderIconColor?: string
  onDocClicked: ((docname: string) => void) | undefined
  style?: React.CSSProperties | undefined
  folderSelectionHandler: () => void
  expandCollapseFolder: () => void
}

const documentRowItem: React.FC<DocumentRowItemProps> = (props: DocumentRowItemProps) => {
  const onDocClicked = (docname: string) => {
    if (typeof props?.onDocClicked === 'undefined') {
      return
    }
    props.onDocClicked(docname)
  }

  const getIconColor = (hasSubfolders: boolean) => {
    if (props.emptyFolderIconColor) {
      return props.emptyFolderIconColor
    }
    if (!hasSubfolders) {
      return ColorPalette.LIGHT_GREY
    }
    return ColorPalette.PRIMARY_BLUE
  }

  let checkbox = null
  if (props.folderSelectionEnabled) {
    checkbox = (
      <Checkbox
        style={{
          color: props.folderSelected ? ColorPalette.PRIMARY_BLUE : ColorPalette.TERTIARY_TEXT,
          marginRight: 35,
        }}
        checked={props.folderSelected}
        onChange={() => props.folderSelectionHandler()}
      />
    )
  }

  let documentsInCategory = null
  if (props.expandedFolders.includes(props.folder)) {
    documentsInCategory = [...props.docConfig].map((configItem, i) => {
      return (
        <div
          key={`${configItem.docNameDisplay}_${i}`}
          style={{
            ...styles.docContainer,
            width: '100%',
          }}>
          <div style={styles.line} />
          <div style={{ ...styles.docInnerContainer }}>
            {configItem.leftComponent}
            <button
              disabled={typeof props.onDocClicked === undefined}
              key={`${configItem.docNameDisplay}_${i}_button`}
              style={styles.docItemButton}
              onClick={() => onDocClicked(configItem?.fileInfo?.fileName || '')}>
              <p style={{ ...styles.textField3 }}>{configItem.docNameDisplay}</p>
            </button>
            {configItem.rightComponent}
          </div>
        </div>
      )
    })
  }

  return (
    <div>
      <button
        style={{
          ...styles.container,
          padding: props.folderSelectionEnabled ? '0px 8px' : '0px 25px',
          width: '100%',
          ...props.style,
        }}
        onClick={props.expandCollapseFolder}
        disabled={toUpperCaseCustom(props.metaData).includes('NONE') ? true : false}>
        {checkbox}
        <Icon
          path={iconMap[toUpperCaseCustom(props.folder[0])]}
          size={1.1}
          color={getIconColor(props.docConfig.length ? true : false)}
        />
        <p style={{ ...styles.textField1 }}>{props.folder ? removeUnderscores(props.folder) : 'empty'}</p>
        <p style={{ ...styles.textField2 }}>{props.metaData}</p>
      </button>

      <div style={{ paddingTop: props.expandedFolders.includes(props.folder) ? 3 : 0 }}>{documentsInCategory}</div>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0px 25px',
    height: 42,
    backgroundColor: ColorPalette.CARD_WHITE,
    boxShadow: '0px 2px 4px rgba(0, 0, 30, 0.18)',
    outline: 0,
    border: 'none',
    borderBottom: `1px solid rgba(225,225,240,0.2)`,
    marginRight: '0px',
    ':hover': {
      backgroundColor: ColorPalette.BUTTON_HOVER_GREY,
    },
    ':active': {
      backgroundColor: ColorPalette.BUTTON_ACTIVE_GREY,
    },
  },
  docContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 0,
    border: 'none',
    backgroundColor: ColorPalette.BUTTON_HOVER_GREY,
  },
  docItemButton: {
    outline: 0,
    border: 'none',
    backgroundColor: ColorPalette.BUTTON_HOVER_GREY,
    marginLeft: 20,
    width: '90%',
    ':hover': {
      filter: 'brightness(96%)',
    },
    ':active': {
      filter: 'brightness(90%)',
    },
  },
  docInnerContainer: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    width: '100%',
    paddingLeft: 50,
  },
  textField1: {
    color: ColorPalette.SECONDARY_TEXT,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: '0.7rem',
    marginLeft: 20,
    width: '55%',
    textAlign: 'left' as 'left',
    textTransform: 'uppercase' as 'uppercase',
  },
  textField2: {
    color: ColorPalette.TERTIARY_TEXT,
    fontFamily: 'Roboto',
    fontSize: '0.75rem',
    fontWeight: 'normal',
    textAlign: 'right' as 'right',
    width: '38%',
  },
  textField3: {
    color: ColorPalette.SECONDARY_TEXT,
    fontWeight: '450',
    width: '89.5%',
    fontSize: '0.75rem',
    textAlign: 'left' as 'left',
    marginLeft: 20,
  },
  line: {
    borderBottom: `1px solid ${ColorPalette.VERY_LIGHT_GREY}`,
    width: '97%',
    alignSelf: 'flex-end',
  },
}

const iconMap: Record<string, string> = {
  A: mdiAlphaACircle,
  B: mdiAlphaBCircle,
  C: mdiAlphaCCircle,
  D: mdiAlphaDCircle,
  E: mdiAlphaECircle,
  F: mdiAlphaFCircle,
  G: mdiAlphaGCircle,
  H: mdiAlphaHCircle,
  I: mdiAlphaICircle,
  J: mdiAlphaJCircle,
  K: mdiAlphaKCircle,
  L: mdiAlphaLCircle,
  M: mdiAlphaMCircle,
  N: mdiAlphaNCircle,
  O: mdiAlphaOCircle,
  P: mdiAlphaPCircle,
  Q: mdiAlphaQCircle,
  R: mdiAlphaRCircle,
  S: mdiAlphaSCircle,
  T: mdiAlphaTCircle,
  U: mdiAlphaUCircle,
  V: mdiAlphaVCircle,
  W: mdiAlphaWCircle,
  X: mdiAlphaXCircle,
  Y: mdiAlphaYCircle,
  Z: mdiAlphaZCircle,
}

export default Radium(documentRowItem)
