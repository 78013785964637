import { DocVerificationItemFactory, IDocVerificationItem } from "./doc.verification.item";

export interface IDocVerificationTracker {
    addTrackers: (json: Record<string, any>) => void;
    addVerifier: (idPassport: string, docName: string, userDetails: Record<string, any>) => void;
    getVerificationTrackerItem: (idPassport: string) => IDocVerificationItem;
    getVerificationStatus: (idPassport: string, docName: string) => boolean;
}

class DocVerificationTracker implements IDocVerificationTracker {
    private verificationTracker = {} as Record<string, IDocVerificationItem>;
    constructor(json: any) {
        if (!json) {
            return;
        }
        this.verificationTracker = this.generateTracker(json);
    }

    addTrackers(json: Record<string, any>) {
        Object.keys(json).forEach(idPassport => {
            if (this.verificationTracker[idPassport]) {
                this.verificationTracker[idPassport].updateVerifiers(json[idPassport]);
            } else {
                this.verificationTracker[idPassport] = DocVerificationItemFactory.create(json[idPassport]);
            }
        });
    }

    addVerifier(idPassport: string, docName: string, userDetails: Record<string, any>) {
        if (!(idPassport in this.verificationTracker)) {
            return false;
        }
        return this.verificationTracker[idPassport].addVerifier(docName, userDetails);
    }

    generateTracker(json: Record<string, any>) {
        let verificationTracker = {} as Record<string, IDocVerificationItem>;
        Object.keys(json).forEach(
            idPassport => (verificationTracker[idPassport] = DocVerificationItemFactory.create(json[idPassport])),
        );
        return verificationTracker;
    }

    getVerificationStatus(idPassport: string, docName: string): boolean {
        if (!(idPassport in this.verificationTracker)) {
            return false;
        }
        return this.verificationTracker[idPassport].getVerificationStatus(docName);
    }

    getVerificationTrackerItem(idPassport: string): IDocVerificationItem {
        if (!(idPassport in this.verificationTracker)) {
            return DocVerificationItemFactory.create({});
        }
        return this.verificationTracker[idPassport];
    }

    removeVerifier(idPassport: string, docName: string, userIdPassport: string) {
        if (!(idPassport in this.verificationTracker)) {
            return;
        }
        return this.verificationTracker[idPassport].removeVerifier(docName, userIdPassport);
    }
}

export class DocVerificationTrackerFactory {
    static create(json: any): IDocVerificationTracker {
        return new DocVerificationTracker(json);
    }
}
