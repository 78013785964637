import React from 'react'
import Radium from 'radium'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { ColorPalette } from '../../../config'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'
import { toValidPfDate } from '../../../utils'

export interface CalendarProps {
  value?: string | Date | undefined
  format: string
  label: string
  minDate?: string | Date | undefined
  maxDate?: string | Date | undefined
  dialogStyle?: React.CSSProperties | undefined
  variant?: 'dialog' | 'inline'
  open?: boolean
  labelStyle?: React.CSSProperties
  inputStyle?: React.CSSProperties
  style?: React.CSSProperties | undefined
  onChange: (date: MaterialUiPickersDate, value?: string | null) => void
  onClose?: () => void
  onSave?: () => void
  onOpen?: () => void
}

const Calendar = (props: CalendarProps) => {
  let inputProps = undefined
  if (props.inputStyle) {
    inputProps = {
      style: props.inputStyle,
    }
  }

  let labelProps = undefined
  if (props.labelStyle) {
    labelProps = {
      style: props.labelStyle,
    }
  }

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: ColorPalette.PRIMARY_BLUE,
      },
    },
  })

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <MuiThemeProvider theme={theme}>
        <KeyboardDatePicker
          disableToolbar
          format={props.format}
          label={props.label ? props.label : 'Select a date'}
          value={toValidPfDate(props.value, 'YYYY/MM/DD')}
          onChange={props.onChange}
          onFocus={() => {}}
          onBlur={() => {}}
          onOpen={props.onOpen}
          onClose={props.onClose}
          onAccept={props.onSave}
          variant={props.variant}
          minDate={props.minDate}
          maxDate={props.maxDate}
          minDateMessage="" // NOTE: we could add component props if we ever want to use these min/maxDateMessage props
          maxDateMessage=""
          DialogProps={props.dialogStyle ? { style: props.dialogStyle } : undefined}
          InputProps={inputProps}
          InputLabelProps={labelProps}
          KeyboardButtonProps={{ 'aria-label': 'change date', color: 'primary' }}
          invalidDateMessage={props.value ? 'Invalid date format' : ''}
          style={props.style}
        />
      </MuiThemeProvider>
    </MuiPickersUtilsProvider>
  )
}

export default Radium(Calendar)
