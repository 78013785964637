export interface IDocVerificationItem {
    addVerifier: (docName: string, userDetails: Record<string, any>) => void;
    getVerificationStatus: (docName: string) => boolean;
    removeVerifier: (docName: string, userIdPassport: string) => void;
    updateVerifiers: (json: Record<string, { verified: any[] }>) => void;
}

class DocVerificationItem implements IDocVerificationItem {
    private verified = {} as Record<string, Record<string, any>[]>;
    constructor(json: any) {
        if (!json) {
            return;
        }
        this.verified = this.generateTrackerItem(json);
    }

    addVerifier(docName: string, userDetails: Record<string, any>) {
        if (!(docName in this.verified)) {
            this.verified[docName] = [] as Record<string, any>[];
        }
        this.verified[docName].push(userDetails);
    }

    generateTrackerItem(json: Record<string, { verified: any[] }>): Record<string, Record<string, any>[]> {
        let verified = {} as Record<string, Record<string, any>[]>;
        Object.keys(json).forEach(docName => {
            verified[docName] = json[docName].verified as Record<string, any>[];
        });
        return verified;
    }

    getVerificationStatus(docName: string) {
        let isVerified = false;
        if (docName in this.verified && this.verified[docName].length) {
            isVerified = true;
        }
        return isVerified;
    }

    removeVerifier(docName: string, userIdPassport: string) {
        if (!(docName in this.verified)) {
            return;
        }
        this.verified[docName] = this.verified[docName].filter(userDetails => !userDetails[userIdPassport]);
    }

    updateVerifiers(json: Record<string, { verified: any[] }>) {
        Object.keys(json).forEach(docName => {
            this.verified[docName] = json[docName].verified as Record<string, any>[];
        });
    }
}

export class DocVerificationItemFactory {
    static create(json: any): IDocVerificationItem {
        return new DocVerificationItem(json);
    }
}
