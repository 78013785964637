import { ColorPalette } from '../../config/colors'

import { ActionType } from '../../store/actions/actions'
import { PeopleFlowCombinedReducer } from '../../store'
import { RequestServiceFactory } from '../../services'

import React, { Component, createRef } from 'react'
import Radium from 'radium'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import NavigationBar from '../../components/Navigation/NavigationBar'
import SectionHeaderPrimary from '../../components/Headings/SectionHeaderPrimary'
import SideMenu from '../../components/Navigation/SideMenu'
import { getReactJSONLocale } from '../../utils'

import ConfigSideMenu, { ConfigScreenOption } from '../../components/SideMenus/ConfigSideMenu'
import JSONInput from 'react-json-editor-ajrm'
import {
  mdiAccountConvert,
  mdiCertificateOutline,
  mdiDataMatrixEdit,
  mdiDownloadBox,
  mdiFileDocumentEdit,
  mdiOctagramOutline,
  mdiPoliceBadge,
  mdiProgressCheck,
  mdiTextBoxMultipleOutline,
} from '@mdi/js'
import DownloadAsFileButton from '../../components/BaseComponents/Buttons/DownloadAsFileButton'
import { AssociationSettingsRepository } from '../../repositories'

dayjs.extend(relativeTime)

function getConfigOptionsFunc(associationRepo: AssociationSettingsRepository): Record<string, ConfigScreenOption> {
  return {
    docConfig: {
      title: 'DOCUMENT CONFIGURATION',
      icon: mdiFileDocumentEdit,
      getConfig: (selectedAssociation: string) => {
        return associationRepo.getDocConfig(selectedAssociation)
      },
    },
    fieldConfig: {
      title: 'FIELD CONFIGURATION',
      icon: mdiTextBoxMultipleOutline,
      getConfig: (selectedAssociation: string) => {
        return associationRepo.getFieldConfig(selectedAssociation)
      },
    },
    allPossibleCompetencies: {
      title: 'ALL POSSIBLE COMPETENCIES',
      icon: mdiCertificateOutline,
      getConfig: (selectedAssociation: string) => {
        return associationRepo.getAllPossibleCompetencies(selectedAssociation)
      },
    },
    allPossibleOffences: {
      title: 'ALL POSSIBLE OFFENCES',
      icon: mdiAccountConvert,
      getConfig: (selectedAssociation: string) => {
        return associationRepo.getPossibleOffences(selectedAssociation)
      },
    },
    allDataFlows: {
      title: 'ALL DATA FLOWS',
      icon: mdiDataMatrixEdit,
      getConfig: (selectedAssociation: string) => {
        return associationRepo.getAllDataFlows(selectedAssociation)
      },
    },
    disciplinaryConfig: {
      title: 'DISCIPLINE CONFIGURATION',
      icon: mdiPoliceBadge,
      getConfig: (selectedAssociation: string) => {
        return associationRepo.getDisciplinaryConfig(selectedAssociation)
      },
    },
    exportConfigurationm: {
      title: 'EXPORT CONFIGURATION',
      icon: mdiDownloadBox,
      getConfig: (selectedAssociation: string) => {
        return associationRepo.getAvailableExportConfigs(selectedAssociation)
      },
    },
    processConfig: {
      title: 'PROCESS CONFIG',
      icon: mdiProgressCheck,
      getConfig: (selectedAssociation: string) => {
        return associationRepo.getProcessConfig(selectedAssociation)
      },
    },
    organisationConfig: {
      title: 'ORGANISATION CONFIG',
      icon: mdiOctagramOutline,
      getConfig: (selectedAssociation: string) => {
        return associationRepo.getOrganisationConfig(selectedAssociation)
      },
    },
  }
}

interface ConfigProps extends RouteComponentProps {
  selectedAssociation: string
  associationRepo: AssociationSettingsRepository
  updateState: (obj: any) => void
}

interface ConfigState {
  loadingModalOpen: boolean
  warningModalOpen: boolean
  permittedRole: string
  warning?: string
  selectedConfigScreen: string
}

class ConfigViewerScreen extends Component<ConfigProps, ConfigState> {
  private sectionHeaderRef: React.RefObject<any>

  constructor(props: ConfigProps) {
    super(props)
    this.sectionHeaderRef = createRef()
  }

  RequestService = RequestServiceFactory.create()

  initialModalState = {
    loadingModalOpen: false,
    warningModalOpen: false,
    authModalOpen: false,
    permittedRole: '',
    selectedConfigScreen: '',
  }

  state: ConfigState = {
    ...this.initialModalState,
  }

  closeModals = () => {
    this.setState({ ...this.state }, this.forceUpdate)
  }

  displayWarning(error: any) {
    let warning = ''
    try {
      if (error.code === 'NetworkError') {
        warning = 'Seems like your internet connection is down. Reconnect to the network, then try again.'
      } else if ('message' in error) {
        warning =
          "The following error message was returned when logging in:\n\n'" +
          error.message +
          "'. \n\nRefresh the page and try again. If unsuccessful, then contact tech support"
      } else {
        warning = 'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
      }
    } catch (error) {
      warning = 'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
    }

    this.setState({
      ...this.initialModalState,
      warningModalOpen: true,
      warning,
    })
  }

  render() {
    const json = getConfigOptionsFunc(this.props.associationRepo)
    const file = `${this.props.selectedAssociation}_${this.state.selectedConfigScreen}`

    let obj = null
    if (this.state.selectedConfigScreen in json) {
      obj = json[this.state.selectedConfigScreen].getConfig(this.props.selectedAssociation)
    }

    return (
      <div style={styles.container}>
        <NavigationBar match={this.props.match} location={this.props.location} history={this.props.history} />

        <SectionHeaderPrimary
          ref={this.sectionHeaderRef}
          style={styles.sectionHeader}
          disabled={true}
          searchString={''}
          onClick={() => ({})}>
          {`Configuration`}
        </SectionHeaderPrimary>

        <div style={styles.contentContainer}>
          <SideMenu
            visible={true}
            menuComponents={
              <ConfigSideMenu
                selectedViewMode={this.state.selectedConfigScreen}
                configViewOptions={getConfigOptionsFunc(this.props.associationRepo)}
                onClick={(configKey: string) => {
                  this.setState({ selectedConfigScreen: configKey })
                }}
              />
            }
          />
          <div style={styles.rightSide}>
            <DownloadAsFileButton json={obj} filename={file} style={styles.button}></DownloadAsFileButton>
            <div style={styles.rightSideContent}>
              <JSONInput
                viewOnly={true}
                placeholder={obj}
                id="a_unique_id"
                locale={getReactJSONLocale()}
                height="100%"
                width="100%"
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255, 1), rgba(209,210,230, 1))',
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto 1.5%',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    paddingInline: 'max(2em, 2%)',
    width: '100%',
    overflow: 'hidden',
  },
  rightSideContent: {
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
  button: {
    fontWeight: 'bolder',
    fontSize: '0.8rem',
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    ':hover': {
      color: ColorPalette.PRIMARY_BLUE,
    },
    ':active': {
      color: ColorPalette.DARK_GREY,
    },
  },
}

const mapStateToProps = (state: PeopleFlowCombinedReducer) => {
  return {
    idPassport: state.sessionManager.idPassport,
    associationRepo: state.sessionManager.associationRepo as AssociationSettingsRepository,
    selectedAssociation: state.sessionManager.selectedAssociation,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateState: (data: any) => dispatch({ type: ActionType.UPDATE_STATE, data }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Radium(ConfigViewerScreen))
