import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { RouteComponentProps, useHistory } from 'react-router'

import { mdiPlus } from '@mdi/js'
import { nanoid } from 'nanoid'

import { SessionService, GroupService } from '../../services'
import { PeopleFlowCombinedReducer } from '../../store'
import { ColorPalette } from '../../config/colors'
import NavigationBar from '../../components/Navigation/NavigationBar'
import SectionHeaderPrimary from '../../components/Headings/SectionHeaderPrimary'
import { ActionButtonType, Toolbar } from '../../components/GeneralUI/Toolbar'
import LoadingModal from '../../components/Modals/LoadingModal'
import { GroupAddModal } from '../../components/Groups/GroupAddModal'
import { DateRangeModal } from '../../components/Modals/DateRangeModal'
import { GroupStatusFilter } from '../../components/Groups/GroupStatusFilter'
import { GroupsList } from '../../components/Groups/GroupsList'
import { ActiveDateRange, Group, GroupStatus } from '../../types'

type GroupsProps = RouteComponentProps

const GROUP_ID_LENGTH = 6
const initialDate = new Date()

const Groups = (props: GroupsProps) => {
  const sectionsRef = useRef(null)
  const history = useHistory()
  const [groupStatusFilters, setGroupStatusFilters] = useState<GroupStatus[]>(['Upcoming', 'Active'])
  const [addNewGroupModalOpen, setAddNewGroupModalOpen] = useState(false)
  const [dateRangeModalOpen, setDateRangeModalOpen] = useState('')
  // const [newGroupDivision, setNewGroupDivision] = useState("");
  // const [newGroupName, setNewGroupName] = useState("");
  // const [newGroupActiveDateRange, setNewGroupActiveDateRange] = useState<ActiveDateRange>({
  //     startDate: initialDate,
  //     endDate: initialDate,
  // });
  const [existingGroupActiveDateRange, setExistingGroupActiveDateRange] = useState<ActiveDateRange>({
    startDate: initialDate,
    endDate: initialDate,
  })
  const [loadingModalOpen, setLoadingModalOpen] = useState(true)
  // temp
  const [groups, setGroups] = useState<Group[]>([])
  const [refreshTimestamp, setRefreshTimestamp] = useState(0)

  const userIdPassport = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.idPassport)
  const password = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.password)
  const selectedAssociation = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedAssociation,
  )
  const selectedCohort = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.selectedCohort)

  useEffect(() => {
    initGroupService()
  }, [])

  const initGroupService = async () => {
    const token = await SessionService.prepareAuthTokens(userIdPassport, password)
    const groups = await GroupService.fetchGroups(selectedAssociation, selectedCohort, token)
    setGroups(groups)
    toggleLoadingModal()
  }

  const toggleLoadingModal = () => setLoadingModalOpen((loadingModalOpen) => !loadingModalOpen)

  const saveNewGroup = async (name: string, startDate: Date, endDate: Date) => {
    if (GroupService) {
      /**
       * Note we're erring on the side of the frontend being a fat client
       * so we assign the ID here (even though this will likely never be
       * used on the app)
       */
      const group: Group = {
        id: nanoid(GROUP_ID_LENGTH),
        division: selectedCohort, // <---- a critical assumption at this point
        name,
        startDate,
        endDate,
        members: {},
      }
      const token = await SessionService.prepareAuthTokens(userIdPassport, password)
      await GroupService.postGroup(selectedAssociation, group, token)
      setGroups((groups) => [...groups, group])
    }
  }

  const saveExistingGroup = async (dateRange: ActiveDateRange) => {
    if (GroupService) {
      const groupId = dateRangeModalOpen
      const token = await SessionService.prepareAuthTokens(userIdPassport, password)
      const group = await GroupService.fetchGroup(selectedAssociation, selectedCohort, groupId, token)
      group.startDate = dateRange.startDate
      group.endDate = dateRange.endDate
      await GroupService.postGroup(selectedAssociation, group, token)

      // Logic to maintain order of groups
      const groupIndex = groups.findIndex((group) => group.id === groupId)
      const otherGroups = groups.filter((group) => group.id !== groupId)
      const newGroups = [...otherGroups]
      newGroups.splice(groupIndex, 0, group)
      setGroups(newGroups)
    }
  }

  const toggleDateRangeModal = (groupId?: string) => {
    if (groupId) {
      const group = groups.find((group) => group.id === groupId)
      if (group) {
        setExistingGroupActiveDateRange({
          startDate: group.startDate,
          endDate: group.endDate,
        })
      }
      setDateRangeModalOpen(groupId)
      return
    }
    setDateRangeModalOpen('')
  }
  const toggleAddNewGroupModal = () => {
    setAddNewGroupModalOpen((setAddNewGroupModalOpen) => !addNewGroupModalOpen)
  }

  // const resetNewGroupInputValues = () => {
  //     setNewGroupDivision("");
  //     setNewGroupName("");
  //     setNewGroupActiveDateRange({ startDate: initialDate, endDate: initialDate });
  // };

  const toggleStatusFilter = (e: React.ChangeEvent) => {
    const id: GroupStatus = e.target.id as GroupStatus
    if (groupStatusFilters.includes(id)) {
      setGroupStatusFilters(groupStatusFilters.filter((filter) => filter !== id))
    } else {
      setGroupStatusFilters([...groupStatusFilters, id])
    }
  }

  const handleDateRangeSave = async (startDate: Date, endDate: Date) => {
    const dateRange = {
      startDate,
      endDate,
    }
    setExistingGroupActiveDateRange(dateRange)
    toggleDateRangeModal()
    await saveExistingGroup(dateRange)
    setRefreshTimestamp(Date.now())
  }

  const allGroupStatuses: GroupStatus[] = ['Upcoming', 'Active', 'Closed']
  const toolbarActionButtons: ActionButtonType[] = [
    {
      iconPath: mdiPlus,
      onClick: toggleAddNewGroupModal,
      label: 'ADD GROUP',
    },
  ]

  return (
    <div style={styles.container}>
      <NavigationBar match={props.match} location={props.location} history={props.history} />
      <SectionHeaderPrimary style={styles.sectionHeader} disabled={true} searchString={''} onClick={() => ({})}>
        Groups
      </SectionHeaderPrimary>

      <div style={styles.contentContainer}>
        <div style={styles.rightSide}>
          <Toolbar actionButtons={toolbarActionButtons} />
          <div style={styles.rightSideContent} ref={sectionsRef}>
            <GroupStatusFilter
              groupStatusOptions={allGroupStatuses}
              selectedFilterOptions={groupStatusFilters}
              onFilterOptionClick={toggleStatusFilter}
            />
            <GroupsList
              groups={groups}
              groupStatusFilters={groupStatusFilters}
              initialDate={initialDate}
              toggleDateRangeModal={toggleDateRangeModal}
              onViewGroupClick={(groupId: string) => history.push(`/people/groups/${groupId}`)}
              key={refreshTimestamp}
            />
          </div>
        </div>
      </div>
      {addNewGroupModalOpen && <GroupAddModal toggle={toggleAddNewGroupModal} onSave={saveNewGroup} />}
      <DateRangeModal
        open={!!dateRangeModalOpen}
        title="Edit Group Date Range"
        initialStartDate={existingGroupActiveDateRange.startDate}
        initialExpiryDate={existingGroupActiveDateRange.endDate}
        onCancelClick={toggleDateRangeModal}
        onSaveClick={handleDateRangeSave}
      />
      <LoadingModal open={loadingModalOpen}>Fetching group data...</LoadingModal>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255, 1), rgba(209,210,230, 1))',
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto 1%',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    paddingInline: 'min(3em, 3%)',
    width: '100%',
    overflow: 'hidden',
  },
  rightSideContent: {
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    backgroundColor: ColorPalette.CARD_WHITE,
    overflow: 'auto',
  },
}

export default Groups
