import { DocVersionConfig } from "./doc.version.config";

export type DocConfigItem = {
    docName: string;
    published: Record<string, {}>;
    depublished: Record<string, {}>;
    drafts: Record<string, DocVersionConfig>;
    displaySettings: Record<string, string[]>;
    lastEditedBy: string;
};

export class DocConfig {
    docName: string;
    published: any;
    depublished: any;
    drafts: Record<string, DocVersionConfig>;
    displaySettings: Record<string, string[]>;
    lastEditedBy: string;

    constructor(docName: string, author: string, creationDate: string, page: any) {
        this.docName = docName;
        this.published = {};
        this.depublished = {};
        this.drafts = {
            [creationDate]: new DocVersionConfig(creationDate, author, page),
        };
        this.displaySettings = {
            employmentStatus: ["CANDIDATE"],
            category: ["RECRUITMENT_&_ONBOARDING"],
            subCategory: [],
        };
        this.lastEditedBy = author;
    }
}
