export const DEFAULT_ACTION_FLOW = ['NOTICE_(1)', 'TERMINATE']

export const DEFAULT_ACTION_EXPIRY = {
  TERMINATE: 31104000000,
  'ADD_CATCH-ALL': 31104000000,
  COUNSEL: 7776000000,
  VERBAL: 7776000000,
  'WRITTEN_(FINAL)': 31104000000,
  'NOTICE_(1)': 2592000000,
  'NOTICE_(2)': 2592000000,
  'WRITTEN_(1)': 15552000000,
}