import { IRequestInstanceConfig } from '../clientRequests'
import { DocConfig } from '../forms'
import { ProfileAccess, RoleName } from '../../types'
import { IClientSettings, IDataFlow, IFieldConfigItem, IProcessConfig } from '../../interfaces'

export type AssociationSettingsSchema = Record<string, any>
export class AssociationSettings {
  allDataFlows: Record<string, IDataFlow>
  private availableExportConfigs: string[]
  private docConfig: Record<string, DocConfig>
  private fieldConfig: Record<string, IFieldConfigItem>
  private organisationConfig: Record<string, any>
  private processConfig: Record<string, IProcessConfig>
  private profileAccess: ProfileAccess
  private deletedProfileTracker: string[]
  private iam: Record<string, any>
  private allCompanyUsers: Record<string, string>[]
  private requestConfig: Record<string, any>
  private allRoleNames: string[] = []

  constructor(obj: IClientSettings) {
    this.allDataFlows = obj.allDataFlows || {}
    this.availableExportConfigs = obj.availableExportConfigs || []
    this.docConfig = obj.docConfig || {}
    this.fieldConfig = obj.fieldConfig || {}
    this.organisationConfig = obj.organisationConfig || {}
    this.processConfig = obj.processConfig || {}
    this.profileAccess = obj.profileAccess || {}
    this.deletedProfileTracker = obj.deletedProfileTracker || []
    this.iam = obj.iam || {} //TODO: Move into UserRepo
    this.allCompanyUsers = obj.allCompanyUsers || []
    this.requestConfig = obj.requestConfig || {}
  }

  getAllRoleNames() {
    return this.allRoleNames
  }

  getAllDataFlows(): Record<string, IDataFlow> {
    return this.allDataFlows
  }

  getAvailableExportConfigs(): string[] {
    return this.availableExportConfigs
  }

  getDocConfig(): Record<string, any> {
    return this.docConfig
  }

  getFieldConfig(): Record<string, IFieldConfigItem> {
    return this.fieldConfig
  }

  getOrganisationConfig(): Record<string, any> {
    return this.organisationConfig
  }

  getOrganisationConfigItem(itemName: string) {
    return this.organisationConfig[itemName]
  }

  getProcessConfig(): Record<string, any> {
    return this.processConfig
  }

  getProcessConfigItem(itemName: string): Record<string, any> {
    return this.processConfig[itemName]
  }

  getProfileAccessCohorts(): string[] {
    return Object.keys(this.profileAccess)
  }

  getProfileAccess(): Record<string, any> {
    return this.profileAccess
  }

  getDeletedProfileTracker(): string[] {
    return this.deletedProfileTracker
  }

  getIam(): Record<string, any> {
    return this.iam
  }

  getAllUsers(): Record<string, string>[] {
    return this.allCompanyUsers || []
  }

  getDocValidityEnabledStatus() {
    return !!this.getOrganisationConfig().docValidityEnabled
  }

  getRequestConfig(): Record<string, IRequestInstanceConfig> | undefined {
    if (!this.requestConfig) {
      return undefined
    }
    return this.requestConfig
  }

  setAllDataFlow(dataFlowName: string, config: IDataFlow): void {
    this.allDataFlows[dataFlowName] = config
  }

  setAvailableExportConfigs(availableExportConfigs: string[]): void {
    this.availableExportConfigs = availableExportConfigs
  }

  setDocConfig(docConfig: Record<string, DocConfig>): void {
    this.docConfig = docConfig
  }

  setFieldConfig(fieldConfig: Record<string, IFieldConfigItem>): void {
    this.fieldConfig = fieldConfig
  }

  setFieldConfigItem(fieldName: string, config: IFieldConfigItem): void {
    this.fieldConfig[fieldName] = config
  }

  setRoleNames(rolesNames: RoleName[]): void {
    this.allRoleNames = rolesNames
  }

  setOrganisationConfig(organisationConfig: Record<string, any>): void {
    this.organisationConfig = organisationConfig
  }

  setOrganisationConfigItem(itemName: string, config: any): void {
    this.organisationConfig[itemName] = config
  }

  setProcessConfigItem(item: Record<string, any>): void {
    const itemName = Object.keys(item)[0]
    const existingProcessConfig = this.processConfig
    if (existingProcessConfig.hasOwnProperty(itemName)) {
      this.processConfig[itemName] = item[itemName]
    }
  }

  setRequestConfig(requestConfig: Record<string, IRequestInstanceConfig>) {
    this.requestConfig = requestConfig
  }
}
