export enum DocVerificationActions {
  ADD_VERIFIER = 'ADD_VERIFIER',
  REMOVE_VERIFIER = 'REMOVE_VERIFIER',
  FETCH_TRACKER = 'FETCH_TRACKER',
}

export enum DocStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  DEPUBLISHED = 'depublished',
}