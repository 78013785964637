import { clear, del, keys, getMany, delMany, get, set } from 'idb-keyval'

export class LocalStorageProvider {
  static async resetDb() {
    return clear()
  }

  static async setData(storageKey: string, value: any) {
    return set(storageKey, value)
  }

  static async getData(storageKeyOrKeys: string | string[]) {
    if (storageKeyOrKeys instanceof Array) {
      return getMany(storageKeyOrKeys)
    }
    return get(storageKeyOrKeys)
  }

  static listDataKeys = async (storageIdentifier?: string) => {
    let storageKeys = await keys()
    if (storageIdentifier) {
      storageKeys = storageKeys.filter((key) => (key as string).includes(storageIdentifier))
    }
    return storageKeys as string[]
  }

  static deleteData = async (storageKeyOrKeys: string | string[]) => {
    if (storageKeyOrKeys instanceof Array) {
      return delMany(storageKeyOrKeys)
    }
    return del(storageKeyOrKeys)
  }

  static async hasStorageKey(storageKey: string) {
    const existingKeys = await keys()
    return existingKeys.includes(storageKey)
  }
}
