import React, { LegacyRef, createRef, useEffect, useState, useRef, MutableRefObject } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { GridSize } from '@material-ui/core/Grid'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { mdiRefresh, mdiArchiveRemove } from '@mdi/js'
import { cloneDeep, isEmpty } from 'lodash'
import Radium from 'radium'

import { ColorPalette } from '../../config/colors'
import { ActionType } from '../../store/actions/actions'
import { titleCase, toLowerCaseCustom, writeDataToCsvFile } from '../../utils'
import { AllScreenNames, DataCaptureMode, DocumentCategories } from '../../config'
import completedScreens from '../completedScreens'
import { ProcessModes } from '../../config/processModes'
import DataTable, { CustomFilterState } from '../../components/Tables/DataTable/DataTable'
import NavigationBar from '../../components/Navigation/NavigationBar'
import SectionHeader from '../../components/Headings/SectionHeaderPrimary'
import LoadingModal from '../../components/Modals/LoadingModal'
import AlertModalOneButton from '../../components/Modals/AlertModalOneButton'
import SideMenu from '../../components/Navigation/SideMenu'
import ProfileViewingOptions, {
  ProfileViewingOptionsComponent,
} from '../../components/GeneralUI/ProfileViewingOptions/ProfileViewingOptions'
import { PeopleFlowCombinedReducer } from '../../store'
import { SessionService, ImportExportService } from '../../services'
import { persistCurrentProfileData } from '../../utils/idbProfiles'
import { ActionButtonType, Toolbar } from '../../components/GeneralUI/Toolbar'
import CalendarContained from '../../components/BaseComponents/Calendar/CalendarContained'
import ButtonGrey from '../../components/BaseComponents/Buttons/ButtonGrey'
import ButtonBlue from '../../components/BaseComponents/Buttons/ButtonBlue'
import Modal from '../../components/Modals/Modal'
import { dateObjToString } from '../../utils/date'
import { AssociationSettingsRepository, ProfileRepository } from '../../repositories'
import { Profile, ProfilePk } from '../../models/profile/profile'
import { ErrorCodeTypes } from '../../enums'
import { fetchProfileData, getRemoteFile } from '../../provider/remoteData'

interface ProfilesProps extends RouteComponentProps {}

type ProfileCore = { id: string; name: string; surname: string; idPassport: string }
type ColumnConfig = { id: string; label: string; sizeFactor: number }

type RouteState = {
  customFilterState: CustomFilterState[]
}

const importExportService = new ImportExportService()

const profileViewingOptionsRef: LegacyRef<ProfileViewingOptionsComponent> = createRef()
const sectionHeaderRef: LegacyRef<any> = createRef()
const primaryTableRef: LegacyRef<DataTable> = createRef()

const Profiles = (props: ProfilesProps) => {
  const initialModalState = {
    loadingModalOpen: false,
    accountModalOpen: false,
    forgotPasswordModalOpen: false,
    settingsModalOpen: false,
    loadingModalMessage: '',
    terminationDateModalOpen: false,
    bulkTerminationWarningModalOpen: false,
  }

  const [modalState, setModalState] = useState(initialModalState)
  const [sideMenuComponents, setSideMenuComponents] = useState(<div />)
  const [sideMenuVisible, setSideMenuVisible] = useState(false)
  const [initialDownloading, setInitialDownloading] = useState(true)
  const [profileData, setProfileData] = useState<any[]>([])
  const [tableWidth, setTableWith] = useState<GridSize>(10)
  const [selectedRowItemId, setSelectedRowItemId] = useState('')
  const [searchString, setSearchString] = useState('')
  const [processed, setProcessed] = useState<string[]>([])
  const [customProfileFilterConfig, setCustomProfileFilterConfig] = useState<{ key: string; label: string }[]>()
  const [profilesFetched, setProfilesFetched] = useState(0)
  const [fetchingProfiles, setFetchingProfiles] = useState(false)
  const [retrievingProfiles, setRetrievingProfiles] = useState(false)
  const [terminationDate, setTerminationDate] = useState(new Date())

  const idPassport = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.idPassport)
  const password = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.password)
  const associationRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.associationRepo,
  ) as AssociationSettingsRepository
  const profileRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.profileRepo,
  ) as ProfileRepository
  const selectedAssociation = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedAssociation,
  )
  const selectedRole = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.selectedRole)
  const selectedCohort = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.selectedCohort)
  const selectedEmploymentStatus = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedEmploymentStatus,
  )
  const currentSection = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.currentSection)
  const currentScreen = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.currentScreen)
  // const allProfiles = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.allProfiles)
  const hasWorkforceAccess = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager.hasWorkforceAccess)
  const sessionManager = useSelector((state: PeopleFlowCombinedReducer) => state.sessionManager)

  const timeoutId: MutableRefObject<NodeJS.Timeout | undefined> = useRef()
  const tableColumnConfig = useRef<ColumnConfig[]>([])

  let toolbarActionButtons: ActionButtonType[] = [
    // TODO:
    // {
    //     iconPath: mdiTrashCan,
    //     iconColor: ColorPalette.PRIMARY_BLUE,
    //     onClick: () => null,
    //     label: "TRASH",
    //     title: "Move selected profiles to trash ",
    // },
    {
      iconPath: mdiRefresh,
      iconColor: ColorPalette.PRIMARY_BLUE,
      onClick: () => clearProfilesFromCacheAndReFetch(),
      label: 'RELOAD',
      title: 'Clear cache and refetch data',
    },
  ]

  useEffect(() => {
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
        timeoutId.current = undefined
      }
    }
  }, [])

  useEffect(() => {
    const association = profileRepo.getAssociation()
    if (associationRepo && association !== '') {
      resetProfileFetchState()
      initialiseCustomTableColumnConfig()
      updateState({
        profilePic: '',
        currentSection: AllScreenNames.PEOPLE,
        currentScreen: selectedEmploymentStatus as AllScreenNames,
      })
      loadTable()
    }
  }, [associationRepo, profileRepo.getAssociation(), profileRepo.isHydratedFromProfileCache()])

  useEffect(() => {
    if (!primaryTableRef?.current) {
      return
    }
    primaryTableRef.current.reload()
  }, [profileData.length])

  useEffect(() => {
    if (!initialDownloading) {
      loadProfileData()
    }
  }, [processed.length, initialDownloading])

  useEffect(() => {
    if (selectedRowItemId) {
      profileViewingOptionsRef?.current?.reloadProfilePic()
    }
  }, [selectedRowItemId])

  useEffect(() => {
    if (fetchingProfiles === false && timeoutId.current) {
      timeoutId.current = undefined
    }
  }, [fetchingProfiles])

  useEffect(() => {
    if (fetchingProfiles) {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current)
        timeoutId.current = undefined
      }
      timeoutId.current = setTimeout(() => {
        setFetchingProfiles(false)
      }, 7500)
    }
  }, [profilesFetched, fetchingProfiles])

  const dispatch = useDispatch()
  const updateState = (data: any) => dispatch({ type: ActionType.UPDATE_STATE, data })

  const closeModals = () => {
    setModalState(initialModalState)
  }
  const toggleTerminationDateModal = () => {
    setModalState((modalState) => ({
      ...initialModalState,
      terminationDateModalOpen: !modalState.terminationDateModalOpen,
    }))
  }
  const toggleTerminationWarningModal = () => {
    setModalState((modalState) => ({
      ...initialModalState,
      bulkTerminationWarningModalOpen: !modalState.bulkTerminationWarningModalOpen,
    }))
  }

  const initialiseCustomProfileFilterConfig = () => {
    const customProfileFilterConfig = associationRepo.getProfileFilterConfig(selectedAssociation)
    setCustomProfileFilterConfig(customProfileFilterConfig)
  }

  const initialiseCustomTableColumnConfig = () => {
    const customProfileTableColumnConfig = associationRepo.getProfileTableColumnConfig(selectedAssociation)
    const columnConfig = cloneDeep(customProfileTableColumnConfig)
    columnConfig.forEach((config: any) => {
      config.id = config.key
      delete config.key
    })
    // @ts-ignore
    tableColumnConfig.current = [...columnConfig.slice(0, 5)]
  }

  const showRefreshingData = async () => {
    setModalState({
      ...initialModalState,
      loadingModalOpen: true,
      loadingModalMessage: 'Refreshing data...',
    })
  }

  const loadTable = (): Promise<void> => {
    setInitialDownloading(true)
    return loadTableInternal().finally(() => {
      setInitialDownloading(false)
    })
  }

  const loadTableInternal = async (): Promise<void> => {
    return new Promise<void>((res, rej) => {
      if (primaryTableRef && primaryTableRef.current) {
        primaryTableRef.current.reload()
        loadProfileData()
          .catch((err) => rej(err))
          .finally(() => res())
      } else if (currentSection === AllScreenNames.PROFILES) {
        setTimeout(() => {
          loadTableInternal()
            .catch((err) => rej(err))
            .finally(() => {
              res()
            })
        }, 250)
      }
    })
  }

  const loadProfileData = async (refetch?: boolean) => {
    if (selectedAssociation !== profileRepo.getAssociation()) {
      throw new Error(ErrorCodeTypes.ASSOCIATION_MISMATCH)
    }

    let numberOfProfilesFetched = profilesFetched
    if (refetch) {
      numberOfProfilesFetched = 0
      await profileRepo.clearProfiles()
      const otherAssociations = associationRepo.getOtherAssociations(selectedAssociation)
      const { userRepo, associationRepo: assocRepo } = await SessionService.fetchSettings(
        sessionManager,
        otherAssociations,
      )
      await SessionService.updateSettings(sessionManager, userRepo, assocRepo)
    }
    const profiles: Record<ProfilePk, Profile> = profileRepo.getAllProfiles()
    const profileData = prepareProfileDataForDisplay(
      profiles,
      selectedEmploymentStatus,
      customProfileFilterConfig || [],
    )
    setProfileData(profileData)

    let missingIdPassports: string[] = []
    const downloadedIdPassports = Object.keys(profiles)
    const numberOfDownloadedProfiles = downloadedIdPassports.length

    if (numberOfDownloadedProfiles > numberOfProfilesFetched) {
      setProfilesFetched(numberOfDownloadedProfiles)
    }
    const authorisedPks = associationRepo.getAuthorisedPks(selectedAssociation, selectedCohort)
    const downloadProfilesMatchingAuthorisedPks = downloadedIdPassports.filter((idPassport) => {
      return authorisedPks.includes(idPassport)
    })

    if (downloadProfilesMatchingAuthorisedPks.length === authorisedPks.length) {
      setFetchingProfiles(false)
    } else {
      setFetchingProfiles(true)
    }

    for (const authedIdPassport of authorisedPks || []) {
      if (!downloadedIdPassports.includes(authedIdPassport) && !processed.includes(authedIdPassport)) {
        missingIdPassports.push(authedIdPassport)
      }
    }
    if (missingIdPassports.length) {
      await updateProfileData(selectedAssociation, selectedEmploymentStatus, missingIdPassports)
    }

    return new Promise<void>((res) => res())
  }

  const updateProfileData = async (
    selectedAssociation: string,
    selectedEmploymentStatus: string,
    pksToFetch: string[],
  ) => {
    if (selectedAssociation !== profileRepo.getAssociation()) {
      throw new Error(ErrorCodeTypes.ASSOCIATION_MISMATCH)
    }

    if (pksToFetch.length > 50) {
      pksToFetch = pksToFetch.slice(0, 50)
    }
    try {
      const token = await SessionService.prepareAuthTokens(idPassport, password)
      const newProfiles = await fetchProfileData(selectedAssociation, pksToFetch, token)
      await profileRepo.updateProfiles(newProfiles)
      await persistCurrentProfileData(newProfiles, selectedAssociation)
      const allEmployerProfiles = profileRepo.getAllProfiles()
      const profileData = prepareProfileDataForDisplay(
        allEmployerProfiles,
        selectedEmploymentStatus,
        customProfileFilterConfig || [],
      )
      setProfileData(profileData)
      setProcessed((processed) => [...processed, ...Object.values(pksToFetch)])
    } catch (error) {
      console.error('updateProfileData() error: ', error)
    }
  }

  const getFilterFields = (
    generalData: Record<string, any>,
    customProfileFilterConfig: { key: string; label: string }[],
  ): Record<string, any> => {
    let filterFields = {}
    customProfileFilterConfig.forEach((configItem) => {
      if (configItem.key in generalData) {
        filterFields = { ...filterFields, [configItem.key]: generalData[configItem.key] }
      }
    })
    return filterFields
  }

  const prepareProfileDataForDisplay = (
    allProfiles: Record<ProfilePk, Profile> | undefined,
    selectedEmploymentStatus: string,
    customProfileFilterConfig: { key: string; label: string }[],
  ): any[] => {
    const compileProfileData = () => {
      let allProfilesArray: Profile[] = allProfiles ? Object.values(allProfiles) : []
      const allProfilesToRender = allProfilesArray.filter((profile: Profile) => {
        let generalData: any = profile.getGeneralData()
        if (generalData === undefined) {
          generalData = { employmentStatus: 'NONE' }
        }
        if (!profile.isPartOfCohort(selectedCohort)) {
          return false
        }
        const { employmentStatus } = generalData
        const trashStatus = profile.getTrashStatus()
        if (
          tableColumnConfig.current.some((item) => generalData.hasOwnProperty(item.id)) &&
          employmentStatus === selectedEmploymentStatus &&
          (trashStatus === undefined || trashStatus === 'NONE')
        ) {
          return true
        } else {
          return false
        }
      })
      const profileData = allProfilesToRender.map((profileData: any) => {
        const profile = new Profile(profileData)
        const key = profile.getPk()
        let generalData = profile.getGeneralData()
        let filterFields = getFilterFields(generalData, customProfileFilterConfig)
        let columnFieldKeys = tableColumnConfig.current.map((columnConfig) => columnConfig.id)

        let returnData = { id: key, ...filterFields }
        columnFieldKeys.forEach((fieldKey) => {
          // @ts-ignore
          returnData[fieldKey] = generalData.hasOwnProperty(fieldKey)
            ? Array.isArray(generalData[fieldKey])
              ? generalData[fieldKey][0]
              : generalData[fieldKey]
            : '-'
        })

        return returnData
      })

      return profileData
    }

    if (allProfiles === undefined) {
      return []
    }
    const profileData = compileProfileData()
    return profileData
  }

  const searchHandler = (event: React.ChangeEvent<{ value: string }>) => {
    setSearchString(event.target.value)
    if (!primaryTableRef?.current) {
      return
    }
    primaryTableRef.current.search(event.target.value)
  }

  const selectProfile = async (rowItem: Record<string, any>) => {
    const getProfilePicFunction: () => Promise<string> = () => {
      return getRemoteFile(`People/${id}/FaceShots/center`).then((profilePic) => {
        const sourceData = cloneDeep(profile?.getGeneralData())
        updateState({
          profilePic,
          profile,
          isReadOnly: false,
          dataCaptureMode: DataCaptureMode.STANDARD,
          activeDocumentPortalSection: DocumentCategories.RECRUITMENT_AND_ONBOARDING,
          processMode:
            selectedEmploymentStatus === 'EMPLOYEE' ? ProcessModes.GENERAL_EMPLOYEE : ProcessModes.GENERAL_CANDIDATE,
          sourceData,
        })
        return profilePic
      })
    }

    showRefreshingData()
    sectionHeaderRef.current.disableSearch()
    let profileCore = profileData.find((x: ProfileCore) => x.id === rowItem.id)
    if (profileCore === undefined) {
      return
    }
    const { id, name, surname, idPassport } = profileCore
    await updateProfileData(selectedAssociation, selectedEmploymentStatus, [id])
    let profile = profileRepo.getProfile(id) as Profile
    closeModals()

    const sideMenuComponents = (
      <ProfileViewingOptions
        ref={profileViewingOptionsRef}
        profilePic={getProfilePicFunction}
        name={`${name} ${surname}`}
        idPassport={idPassport}
        onClick={(screen) => sideMenuHandler(screen)}
        currentScreen={currentScreen}
        restrictedAuditorRoleAccess={selectedRole === 'AUDITOR'}
      />
    )

    setSideMenuVisible(true)
    setSideMenuComponents(sideMenuComponents)
    setTableWith(9)
    setSelectedRowItemId(id)
  }

  const sideMenuHandler = (screen: AllScreenNames) => {
    if (completedScreens.includes(screen)) {
      updateState({ currentScreen: screen, previousScreen: AllScreenNames.PROFILES })
      // pass current custom filter state to the next screen
      props.history.push(`/people/${toLowerCaseCustom(screen.split(' ').join(''))}`, {
        prevCustomFilterState: primaryTableRef.current?.state.customFilterState,
      })
    } else {
      props.history.push('/comingsoon')
    }
  }

  const resetProfileFetchState = () => {
    setSideMenuVisible(false)
    setSearchString('')
    setProfileData([])
    setProcessed([])
    setProfilesFetched(0)
    initialiseCustomProfileFilterConfig()
  }

  const resetAndReload = async (refetchProfiles?: boolean) => {
    resetProfileFetchState()
    await loadProfileData(refetchProfiles)
  }

  const clearProfilesFromCacheAndReFetch = async () => {
    await profileRepo.deleteProfilesFromCache()
    resetAndReload(true)
  }

  const handleRowSelect = (selectedRows: {}) => {
    if (selectedEmploymentStatus === 'EMPLOYEE') {
      const buttons = [...toolbarActionButtons]
      const terminateIndex = toolbarActionButtons.findIndex((button) => button.label === 'TERMINATE')
      if (isEmpty(selectedRows)) {
        if (terminateIndex > -1) {
          buttons.splice(terminateIndex, 1)
          toolbarActionButtons = buttons
        }
        return
      }
      if (!isEmpty(selectedRows) && terminateIndex === -1) {
        const { permissionResult } = associationRepo.getUserAccess(selectedAssociation, selectedRole)
        if (permissionResult?.hasBulkTerminationAccess) {
          buttons.unshift({
            iconPath: mdiArchiveRemove,
            iconColor: ColorPalette.PRIMARY_BLUE,
            onClick: toggleTerminationDateModal,
            label: 'TERMINATE',
            title: 'Terminate selected employees',
          })
          toolbarActionButtons = buttons
        }
      }
    }
  }

  const submitForTermination = async () => {
    const selectedRows = Object.keys(primaryTableRef.current?.getSelectedRows())
    const terminationDateString = dateObjToString(terminationDate).replace(/-/g, '/')
    const fileName = `bulktermination_import_${+new Date()}.csv`
    const filePath = `Companies/${selectedAssociation}/Imports/${fileName}`
    const csvHeaderColumns = ['idPassport', 'terminationDate']
    const csvRowData = selectedRows.map((row: string) => {
      return [row, terminationDateString]
    })
    await writeDataToCsvFile(filePath, csvHeaderColumns, csvRowData)
    try {
      // TODO: Add option to invert using the "removeImportRecords" parameter. Remember to add "cohort" too.
      const records = await importExportService.planTerminationImport(selectedAssociation, fileName, {
        username: idPassport,
        password,
      })
      if (records && records.length > 0) {
        props.history.push('/bulkterminations', { records, csvFileName: fileName })
      }
    } catch (error) {
      toggleTerminationWarningModal()
    }
  }

  const isLoadingProfiles = () => fetchingProfiles && profilesFetched < numberOfAuthorisedPks && !sideMenuVisible

  const { loadingModalOpen, loadingModalMessage } = modalState

  if (!(associationRepo instanceof AssociationSettingsRepository)) {
    throw new Error('AssociationSettingsRepository not properly initialised')
  }

  const authorisedPks = associationRepo.getAuthorisedPks(selectedAssociation, selectedCohort)
  const numberOfAuthorisedPks = authorisedPks.length

  const loadingProfiles = isLoadingProfiles()
  let loadingProfilesModal = null
  if (loadingProfiles) {
    loadingProfilesModal = (
      <LoadingModal open={true}>
        <div>
          Loading profiles:
          <div style={{ fontSize: 'smaller', fontWeight: 'lighter' }}>
            {`${profilesFetched} of ${numberOfAuthorisedPks}`}
          </div>
        </div>
      </LoadingModal>
    )
  }

  const initialTableCustomFilterState = (props.location.state as RouteState)?.customFilterState
  return (
    <div style={{ ...styles.container }}>
      <NavigationBar
        match={props.match}
        location={props.location}
        history={props.history}
        primaryTableRef={primaryTableRef} // destined for SubMenu via prop drilling
      />
      <SectionHeader
        ref={sectionHeaderRef}
        style={styles.sectionHeader}
        downloading={initialDownloading}
        searchString={searchString}
        textHandler={(e) => searchHandler(e)}
        onClick={() => {
          setSideMenuVisible(false)
          setTableWith(10)
        }}
        key={`sectionHeader_${selectedAssociation}`}>
        {titleCase(`${currentScreen}`)}
      </SectionHeader>
      <div style={styles.contentContainer}>
        <SideMenu visible={sideMenuVisible} menuComponents={sideMenuComponents} />
        <div
          style={{
            ...styles.rightSide,
            paddingInline: sideMenuVisible ? 'max(2em, 2%)' : 'min(3em, 3%)',
          }}>
          <Toolbar
            actionButtons={toolbarActionButtons}
            key={`actionToolbar_${toolbarActionButtons.length}`}
          />
          <div style={styles.rightSideContent}>
            <DataTable
              ref={primaryTableRef}
              tableData={profileData}
              columnConfig={tableColumnConfig.current}
              tableWidth={tableWidth}
              filterState={{}}
              onRowClick={(rowData: Record<string, any>) => selectProfile(rowData)}
              selectedRowItemId={selectedRowItemId}
              authorisedItemIds={[]}
              customFilterConfig={customProfileFilterConfig}
              customFilterState={initialTableCustomFilterState}
              isSelectionEnabled={true}
              rowsOnSelect={handleRowSelect}
              key={`dataTable_${selectedAssociation}_${tableColumnConfig.current.length}`}
            />
          </div>
        </div>
      </div>
      <AlertModalOneButton
        open={!hasWorkforceAccess}
        header="Not Authorised"
        body="You don't have permission to view employee/candidate info."
        buttonLabel="Ok"
        opaqueBackground={true}
        onClick={() => props.history.goBack()}
      />
      <LoadingModal open={loadingModalOpen}>{loadingModalMessage}</LoadingModal>
      {loadingProfilesModal}
      <LoadingModal open={retrievingProfiles}>Retrieving profiles...</LoadingModal>
      <Modal
        open={modalState.terminationDateModalOpen}
        title="SELECT A TERMINATION DATE"
        actionButtons={[
          <ButtonGrey onClick={toggleTerminationDateModal} key="terminationDAteModal_cancel">
            Cancel
          </ButtonGrey>,
          <ButtonBlue onClick={async () => await submitForTermination()} key="terminationDAteModal_next">
            Next
          </ButtonBlue>,
        ]}>
        <CalendarContained
          format="dd/MM/yyyy"
          label="Selected Date"
          value={terminationDate}
          onChange={(date: MaterialUiPickersDate) => setTerminationDate(date as Date)}
          onOpen={() => null}
          onClose={() => null}
          style={{ width: '100%' }}
        />
      </Modal>
      <Modal
        open={modalState.bulkTerminationWarningModalOpen}
        title="TERMINATION PROCESSING FAILED"
        actionButtons={[
          <ButtonBlue onClick={toggleTerminationWarningModal} key="bulkTerminationWarningModal_ok">
            OK
          </ButtonBlue>,
        ]}>
        <p style={{ textAlign: 'center', margin: 0 }}>
          An unexpected error occurred.
          <br />
          No terminations were initiated.
          <br />
          Please try again.
        </p>
      </Modal>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255, 1), rgba(209,210,230, 1))',
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto 0.5%',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    width: '100%',
    overflow: 'hidden',
  },
  rightSideContent: {
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    display: 'flex',
    flex: 1,
    backgroundColor: ColorPalette.CARD_WHITE,
  },
}

export default Radium(Profiles)
