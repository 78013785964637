import { useEffect, useState } from 'react'
import TextInputBasic from '../../BaseComponents/Text/TextInputBasic'
import CheckboxTab from '../../BaseComponents/Checkboxes/CheckboxTab'
import { ColorPalette } from '../../../config'

type StockConfirmationModalBodyProps = {
  selectedReason: string
  otherReason: string
  setSelectedReason: (reason: string) => void
  setOtherReason: (otherReason: string) => void
}

export const StockConfirmationModalBody = (props: StockConfirmationModalBodyProps) => {
  const [specifyOtherReason, setSpecifyOtherReason] = useState(false)

  useEffect(() => {
    if (props.selectedReason === 'OTHER' || specifyOtherReason) {
      toggleOtherInput()
    }
  }, [props.selectedReason])

  const toggleOtherInput = () => setSpecifyOtherReason((currentValue) => !currentValue)

  const { selectedReason, otherReason, setSelectedReason, setOtherReason } = props

  let otherInput = null
  if (specifyOtherReason) {
    otherInput = (
      <TextInputBasic
        style={styles.textStyle}
        value={otherReason}
        placeholder="Specify the reason"
        disabled={false}
        onChange={(e: any) => setOtherReason(e.target.value)}
      />
    )
  }

  return (
    <div>
      Items will be removed from stock.
      <br />
      Please select a reason for removal:
      <CheckboxTab
        selectorItems={['Damaged from supplier', 'Damaged', 'Other']}
        values={selectedReason ? [selectedReason] : []}
        defaultValues={[]}
        selectionHandler={(values: string[]) => {
          setSelectedReason(values[0])
        }}
        maxItemsPerRow={1}
        maxItems={1}
        labelStyle={{ width: '100%', whiteSpace: 'nowrap', textAlign: 'left' }}
        lineContainerStyle={{ justifyContent: 'flex-start', paddingBottom: 0 }}
      />
      {otherInput}
    </div>
  )
}

const styles = {
  textStyle: {
    color: ColorPalette.SECONDARY_TEXT,
    paddingBottom: 8,
    borderBottomWeight: 1,
    borderBottomStyle: 'solid' as 'solid',
    borderBottomColor: ColorPalette.PRIMARY_TEXT,
    width: 'unset',
    marginBottom: '0.5em',
    transform: 'translateY(-1em)',
  },
}
