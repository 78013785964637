import { httpGet } from '../remoteData'

export async function getBillingVerifications(selectedAssociation: string, token: string) {
  try {
    const response = await httpGet(
      'people_flow_core',
      `/billing/association/${selectedAssociation}/verifications`,
      token,
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export async function getBillingProfiles(selectedAssociation: string, token: string) {
  try {
    const response = await httpGet('people_flow_core', `/billing/association/${selectedAssociation}/profiles`, token)
    return response.data
  } catch (error) {
    throw error
  }
}
