import { ColorPalette } from '../../../config/colors'
import { toUpperCaseCustom, removeUnderscores, getInitials } from '../../../utils'

import React from 'react'
import Radium from 'radium'

interface IAccountManagementButtonProps {
  style?: React.CSSProperties | undefined
  selectedAssociation: string
  selectedCohort: string
  onClick: () => void
  name: string
  surname: string
  env?: string
}

const accountManagementButton: React.FC<IAccountManagementButtonProps> = (props: IAccountManagementButtonProps) => {
  let intialsContainer = {
    ...styles.intialsContainer,
    backgroundImage: `linear-gradient(to bottom, ${ColorPalette.PRIMARY_BLUE}, ${ColorPalette.SECONDARY_BLUE})`,
  }
  if (props.env === 'staging') {
    intialsContainer.backgroundImage = `linear-gradient(to bottom, ${ColorPalette.WARNING_RED}, ${ColorPalette.WARNING_RED})`
  }
  return (
    <div style={{ ...styles.mainContainer, ...props.style }}>
      <div style={styles.detailsContainer}>
        <p style={styles.detailsText}>{removeUnderscores(toUpperCaseCustom(props.selectedAssociation))}</p>
        <p style={{ ...styles.detailsText, fontWeight: '300' }}>{props.selectedCohort}</p>
      </div>

      <button style={intialsContainer} onClick={() => props.onClick()}>
        <p style={styles.initialsText}>{getInitials(props.name, props.surname)}</p>
      </button>
    </div>
  )
}

const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    overflow: 'hidden',
    width: window.innerWidth * 0.15,
    height: window.innerWidth * 0.07,
    paddingBottom: 10,
    position: 'absolute' as 'absolute',
    right: 35,
  },
  intialsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
    width: 45,
    height: 45,
    borderRadius: window.innerWidth * 0.05,
    outline: 0,
    border: 'none',
    ':hover': {
      filter: 'brightness(85%)',
    },
    ':active': {
      filter: 'brightness(50%)',
    },
  },
  initialsText: {
    textAlign: 'center' as 'center',
    alignSelf: 'center',
    fontFamily: 'roboto',
    fontWeight: '100',
    fontSize: '1.3rem',
    color: ColorPalette.PRIMARY_LIGHT_TEXT,
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
    height: window.innerWidth * 0.03,
    marginRight: 15,
  },
  detailsText: {
    margin: 0,
    fontFamily: 'roboto',
    fontWeight: '600',
    fontSize: '0.7rem',
    color: ColorPalette.PRIMARY_TEXT,
  },
  buttonStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255,255,255,0)',
    width: 40,
    height: 40,
    borderRadius: 30,
    outline: 0,
    border: 'none',
    ':hover': {
      backgroundColor: 'rgba(220,220,230,0.3)',
    },
    ':active': {
      backgroundColor: 'rgba(220,220,230,0.5)',
    },
  },
  dropDownArrow: {
    width: '1.4rem',
    height: '1.4rem',
  },
}

export default Radium(accountManagementButton)
