import { ColorPalette } from '../../config/colors'

import React, { Component } from 'react'
import Radium from 'radium'
import ButtonBlue from '../BaseComponents/Buttons/ButtonBlue'

import { ScrollView } from '@cantonjs/react-scroll-view'
import PDFViewer from 'pdf-viewer-reactjs'
import SimplePagination from '../GeneralUI/Pagination/SimplePagination'

interface DocumentViewerModalProps {
  document: any
  buttonLabel1: string

  dismiss?: () => void
  onClick1: () => void
}

interface DocumentViewerModalState {
  isFirstPage: boolean
  isLastPage: boolean
  numberOfPages: number
  currentPage: number
}

class DocumentViewerModal extends Component<DocumentViewerModalProps, DocumentViewerModalState> {
  state = {
    isFirstPage: true,
    isLastPage: false,
    numberOfPages: 1,
    currentPage: 1,
  }

  loadNext = () => {
    const nextPage = this.state.currentPage + 1
    if (nextPage < this.state.numberOfPages) {
      this.setState({ currentPage: nextPage, isFirstPage: false, isLastPage: false })
    } else if (nextPage === this.state.numberOfPages) {
      this.setState({ currentPage: nextPage, isLastPage: true, isFirstPage: false })
    }
  }

  loadPrevious = () => {
    const previousPage = this.state.currentPage - 1
    if (previousPage > 1) {
      this.setState({ currentPage: previousPage, isFirstPage: false, isLastPage: false })
    } else if (previousPage === 1) {
      this.setState({ currentPage: previousPage, isFirstPage: true, isLastPage: false })
    }
  }

  getMaxPageCount = (numberOfPages: number) => {
    let isLastPage = false
    if (numberOfPages === 1) {
      isLastPage = true
    }
    this.setState({ numberOfPages, isLastPage })
  }

  dismiss() {
    if (this.props.dismiss === undefined) {
      return
    }
    this.props.dismiss()
  }

  render() {
    let modalContents = (
      <>
        <button style={styles.screenContainer} onClick={() => this.dismiss()} />
        <div style={styles.cardContainer}>
          <div style={styles.buttonContainer}>
            <ButtonBlue style={{ width: '90%' }} onClick={() => this.props.onClick1()}>
              {this.props.buttonLabel1}
            </ButtonBlue>
          </div>

          <ScrollView
            style={{ width: window.innerWidth * 0.6, maxHeight: window.innerHeight * 0.8 }}
            contentContainerStyle={{ ...styles.scrollContainer }}>
            <div
              style={{
                display: 'flex',
                // width: "78.5%",
                // marginLeft: "11.25%",
                backgroundColor: 'white',
                boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)',
              }}>
              <PDFViewer
                scale={0.98}
                externalInput={true}
                page={this.state.currentPage}
                hideNavbar={false}
                document={this.props.document}
                getMaxPageCount={this.getMaxPageCount}
              />
            </div>
            <SimplePagination
              style={{ marginTop: 30 }}
              buttonStyle={{ backgroundColor: ColorPalette.BUTTON_HOVER_GREY }}
              previousLabel={'PREVIOUS'}
              nextLabel={'NEXT'}
              isPrevious={!this.state.isFirstPage}
              isNext={!this.state.isLastPage}
              loadPrevious={this.loadPrevious}
              loadNext={this.loadNext}
            />
          </ScrollView>
        </div>
      </>
    )

    return modalContents
  }
}

let styles = {
  screenContainer: {
    zIndex: 1000,
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    width: window.innerWidth,
    height: window.innerHeight,
    backgroundColor: 'rgba(120,120,130,0.7)',
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 0,
    border: 'none',
    overflow: 'hidden',
  },
  cardContainer: {
    zIndex: 10000,
    position: 'absolute' as 'absolute',
    top: 80,
    left: window.innerWidth * 0.2,
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
    backgroundColor: ColorPalette.BUTTON_HOVER_GREY,
    width: window.innerWidth * 0.6,
    height: window.innerHeight * 0.9,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    overflow: 'hidden',
  },
  scrollContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingBottom: 100,
    paddingTop: 50,
  },
  buttonContainer: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 80,
    backgroundColor: ColorPalette.CARD_WHITE,
  },
  textStyle: {
    fontSize: '0.9rem',
    fontWeight: '300',
    textAlign: 'center',
    color: ColorPalette.PRIMARY_TEXT,
    padding: '0px 10px',
    marginTop: 0,
  },
}

export default Radium(DocumentViewerModal)
