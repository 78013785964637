import { AssociationName } from '../types/index'
import { UserProfileSchema } from '../models'
import { UserRepository } from '../repositories'
import { getUsersForAssociation, createUser, updateUser } from '../provider/index'
import { addUnderScores, toLowerCaseCustom, toUpperCaseCustom } from '../utils'

export class UserService {
  async refreshAssociationUsers(
    selectedAssociation: AssociationName,
    userRepo: UserRepository,
    token: string,
  ): Promise<{ allAccountRoleNames: string[] }> {
    const { users, allAccountRoleNames } = await getUsersForAssociation(selectedAssociation, token)
    userRepo.setAssociationUsers(users)
    return { allAccountRoleNames }
  }

  async upsertUser(
    userRepo: UserRepository,
    association: AssociationName,
    userData: UserProfileSchema,
    token: string,
    isNewUser: boolean,
  ) {
    const userForm = this.toUserProfileForm(userData)
    let newUser
    if (isNewUser) {
      newUser = await createUser(association, userForm, token)
    } else {
      newUser = await updateUser(association, userForm, token)
    }
    userRepo.setAssociationUser(newUser)
  }

  private toUserProfileForm(user: UserProfileSchema) {
    if (!user.roleNames?.length) {
      throw { code: 'EmptyRoleNames' }
    }
    return {
      username: toUpperCaseCustom(user.username),
      name: toUpperCaseCustom(user.name),
      surname: toUpperCaseCustom(user.surname),
      email: toLowerCaseCustom(user.email || ''),
      mobile: user.mobile,
      roleNames: user.roleNames?.map((role) => addUnderScores(role)) || [],
    }
  }

  static createEmptyUser(): UserProfileSchema {
    return {
      email: '',
      username: '',
      name: '',
      mobile: '',
      surname: '',
      roleNames: [],
    }
  }
}
