import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import DataTable, {
  CustomFilterConfig,
  CustomFilterState,
  DataTableFilterState,
  applyFilters,
  generateFilterState,
  shouldCalculateFilterState,
} from '../../Tables/DataTable/DataTable'
import { IColumnConfig } from '../../Tables/DataTable/DataTableHeader'
import { PeopleFlowCombinedReducer } from '../../../store'
import {
  getActionsRelatedToRequests,
  getInstancesRelatedToRequests,
  getLatestAction,
  timestampToDateString,
} from '../../../utils'
import { AssociationSettingsRepository } from '../../../repositories'
import { PpeActionRecordType, PpeInstance, PpeRecordSk, PpeRequest, PpeStatusType } from '../../../types'
import { PpeActions, PpeStatus } from '../../../enums'

dayjs.extend(relativeTime)

type DataRow = {
  requestId: string
  requestDate: string
  latestAction: PpeActionRecordType
  numberOfRelatedInstances: number
  numberOfRelatedActions: number
}

export type PpeRequestListingData = {
  actions: Record<PpeRecordSk, PpeActionRecordType>
  instances: Record<PpeRecordSk, PpeInstance>
  requests: Record<PpeRecordSk, PpeRequest>
}

interface PpeRequestListingProps {
  data: PpeRequestListingData
  selectedStatus: PpeStatusType
  onRowClick: (requestId: string) => void
  style?: React.CSSProperties
}

const PpeRequestListing = (props: PpeRequestListingProps) => {
  const associationRepo = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.associationRepo,
  ) as AssociationSettingsRepository
  const selectedAssociation = useSelector(
    (state: PeopleFlowCombinedReducer) => state.sessionManager.selectedAssociation,
  )

  const [allFilterState, setAllFilterState] = useState<DataTableFilterState>({})
  const [allSortedData, setAllSortedData] = useState<Record<string, any>[]>([])
  const [customFilterConfig, setCustomFilterConfig] = useState<CustomFilterConfig[]>([])
  const [customFilterState, setCustomFilterState] = useState<CustomFilterState[]>([])
  const [customFilterOpen, setCustomFilterOpen] = useState(false)
  const [headerRefreshTimestamp, setHeaderRefreshTimestamp] = useState<number>(Date.now())
  const [allRequests, setAllRequests] = useState<PpeRequest[]>([])

  // useEffect(() => {
  //   try {
  //     const filterState = {}
  //     if (filterState && Object.keys(filterState).length) {
  //       updateFilterState(filterState)
  //     } else {
  //       let allFilterState = generateFilterState(tableColumnConfig, tableData, {})
  //       const data = applyFilters(tableData, allFilterState)
  //       setAllFilterState(allFilterState)
  //       setAllSortedData(data)
  //     }
  //     initialiseCustomFilterConfig()
  //   } catch (error) {
  //     console.log('error:', error)
  //   }
  // }, [])

  const getTableColumnConfigBySelectedStatus = (): IColumnConfig[] => {
    let tableConfig: IColumnConfig[] = []

    if (props.selectedStatus === PpeStatus.READY_FOR_ISSUE) {
      tableConfig = [
        { id: 'requestDate', label: 'Request date', sizeFactor: 1 },
        { id: 'requestedBy', label: 'Requested by', sizeFactor: 1 },
        { id: 'numberOfItems', label: 'Number of items', sizeFactor: 1 },
      ]
    }

    if (props.selectedStatus === PpeStatus.ISSUING) {
      tableConfig = [
        { id: 'requestDate', label: 'Request date', sizeFactor: 1 },
        { id: 'requestedBy', label: 'Requested by', sizeFactor: 1 },
        { id: 'issuedTo', label: 'Issued to', sizeFactor: 1 },
        { id: 'numberOfItems', label: 'Number of items', sizeFactor: 1 },
      ]
    }

    if (props.selectedStatus === PpeStatus.RETURN_IN_PROGRESS) {
      tableConfig = [
        { id: 'requestDate', label: 'Request date', sizeFactor: 1 },
        { id: 'returnedBy', label: 'Returned by', sizeFactor: 1 },
        { id: 'returnedTo', label: 'Returned to', sizeFactor: 1 },
        { id: 'numberOfItems', label: 'Number of items', sizeFactor: 1 },
      ]
    }

    if (props.selectedStatus === PpeStatus.COMPLETE) {
      tableConfig = [
        { id: 'requestDate', label: 'Request date', sizeFactor: 1 },
        { id: 'requestedBy', label: 'Requested by', sizeFactor: 1 },
        { id: 'numberOfItems', label: 'Number of items', sizeFactor: 1 },
      ]
    }

    return tableConfig
  }

  const getDataRowsToDisplay = (): DataRow[] => {
    const dataRows: DataRow[] = []
    const { actions, instances, requests } = props.data
    const requestSks = Object.keys(requests)
    requestSks.forEach((sk) => {
      const requestRelatedActions: Record<PpeRecordSk, PpeActionRecordType> = getActionsRelatedToRequests([sk], actions)
      const latestAction = getLatestAction(requestRelatedActions)
      const dateAndTimeFormat = 'YYYY/MM/DD HH:mm'
      const requestDate = timestampToDateString(requests[sk].dateActioned, dateAndTimeFormat)
      const requestRelatedInstances = getInstancesRelatedToRequests([sk], instances)
      dataRows.push({
        requestId: sk,
        requestDate,
        latestAction,
        numberOfRelatedInstances: Object.keys(requestRelatedInstances).length,
        numberOfRelatedActions: Object.values(requestRelatedActions).filter(
          (action: PpeActionRecordType) => action.rawData.ppeAction === PpeActions.INITIATE_RETURN_INSTANCE,
        ).length,
      })
    })
    return dataRows
  }

  const sortByRequestDate = (a: Record<string, any>, b: Record<string, any>) => {
    return dayjs(b.requestDate).unix() - dayjs(a.requestDate).unix()
  }

  const prepareReadyForIssueDataDisplay = (dataRows: DataRow[]) => {
    const displayData: Record<string, any>[] = []
    dataRows.forEach((row: DataRow) => {
      const requestedBy =
        `${row.latestAction.rawData.sourceName} ${row.latestAction.rawData.sourceSurname}`.toUpperCase()

      displayData.push({
        requestId: row.requestId,
        requestDate: row.requestDate,
        requestedBy,
        numberOfItems: row.numberOfRelatedInstances,
      })
    })
    displayData.sort(sortByRequestDate)
    return displayData
  }

  const prepareIssuingDataDisplay = (dataRows: DataRow[]) => {
    const displayData: Record<string, any>[] = []
    dataRows.forEach((row: DataRow) => {
      const requestedBy =
        `${row.latestAction.rawData.sourceName} ${row.latestAction.rawData.sourceSurname}`.toUpperCase()
      const issuedTo = `${row.latestAction.rawData.targetName} ${row.latestAction.rawData.targetSurname}`.toUpperCase()

      displayData.push({
        requestId: row.requestId,
        requestDate: row.requestDate,
        requestedBy,
        issuedTo,
        numberOfItems: row.numberOfRelatedInstances,
      })
    })
    displayData.sort(sortByRequestDate)
    return displayData
  }

  const prepareReturnInProgressDataDisplay = (dataRows: DataRow[]) => {
    const displayData: Record<string, any>[] = []
    dataRows.forEach((row: DataRow) => {
      const returnedBy =
        `${row.latestAction.rawData.sourceName} ${row.latestAction.rawData.sourceSurname}`.toUpperCase()
      const returnedTo =
        `${row.latestAction.rawData.targetName} ${row.latestAction.rawData.targetSurname}`.toUpperCase()

      displayData.push({
        requestId: row.requestId,
        requestDate: row.requestDate,
        returnedBy,
        returnedTo,
        numberOfItems: row.numberOfRelatedActions,
      })
    })
    displayData.sort(sortByRequestDate)
    return displayData
  }

  const prepareCompleteDataForDisplay = (dataRows: DataRow[]) => {
    const displayData: Record<string, any>[] = []
    dataRows.forEach((row: DataRow) => {
      const requestedBy =
        `${row.latestAction.rawData.sourceName} ${row.latestAction.rawData.sourceSurname}`.toUpperCase()

      displayData.push({
        requestId: row.requestId,
        requestDate: row.requestDate,
        requestedBy,
        numberOfItems: row.numberOfRelatedInstances,
      })
    })
    displayData.sort(sortByRequestDate)
    return displayData
  }

  const prepareDataForDisplay = () => {
    const data = getDataRowsToDisplay()

    if (props.selectedStatus === PpeStatus.READY_FOR_ISSUE) {
      return prepareReadyForIssueDataDisplay(data)
    }
    if (props.selectedStatus === PpeStatus.ISSUING) {
      return prepareIssuingDataDisplay(data)
    }
    if (props.selectedStatus === PpeStatus.RETURN_IN_PROGRESS) {
      return prepareReturnInProgressDataDisplay(data)
    }
    if (props.selectedStatus === PpeStatus.COMPLETE) {
      return prepareCompleteDataForDisplay(data)
    }

    return []
  }

  const initialiseCustomFilterConfig = () => {
    const customFilterConfig = getFilterConfig()
    setCustomFilterConfig(customFilterConfig)
  }

  const getFilterConfig = (): CustomFilterConfig[] => {
    const config: CustomFilterConfig[] = tableColumnConfig.map((configItem) => {
      return {
        key: configItem.id,
        label: configItem.label,
      }
    })
    const fieldConfig = associationRepo.getFieldConfig(selectedAssociation)
    // NOTE: function belows relies on filterable fields existing in fieldConfig
    return associationRepo.populateFilterConfig(config, fieldConfig)
  }

  const updateFilterState = (updatedFilterState: DataTableFilterState, callback?: () => void) => {
    if (shouldCalculateFilterState(updatedFilterState)) {
      updatedFilterState = generateFilterState(tableColumnConfig, tableData, updatedFilterState)
    }
    const data = applyFilters(tableData, updatedFilterState)
    setAllFilterState(updatedFilterState)
    setAllSortedData(data)
    if (callback) {
      callback()
    }
  }

  const handleRowClick = (rowData: Record<string, any>) => {
    props.onRowClick(rowData.requestId)
  }

  const tableData = prepareDataForDisplay()
  const tableColumnConfig = getTableColumnConfigBySelectedStatus()

  return (
    <DataTable
      tableData={tableData as Record<string, any>[]}
      columnConfig={tableColumnConfig}
      tableWidth={10}
      filterState={{}}
      onRowClick={handleRowClick}
      selectedRowItemId={''}
      authorisedItemIds={[]}
      rowsOnSelect={() => null}
      style={{ height: '100%' }}
      key={`ppeRequests_${tableData.length}_${props.selectedStatus}`}
    />
  )
}

export default PpeRequestListing
