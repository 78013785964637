import React, { Component } from 'react'
import Radium from 'radium'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Property } from 'csstype'
import { GridSize } from '@material-ui/core/Grid'
import _ from 'lodash'
import { mdiPlus } from '@mdi/js'

import { ColorPalette } from '../../config/colors'
import { ActionType } from '../../store/actions/actions'
import { isPeopleFlowEmployee, removeUnderscores, toLowerCaseCustom, toUpperCaseCustom } from '../../utils'
import completedScreens from '../completedScreens'
import { SessionService, UserService } from '../../services'
import DataTable from '../../components/Tables/DataTable/DataTable'
import NavigationBar from '../../components/Navigation/NavigationBar'
import SectionHeader from '../../components/Headings/SectionHeaderPrimary'
import LoadingModal from '../../components/Modals/LoadingModal'
import AlertModalOneButton from '../../components/Modals/AlertModalOneButton'
import { PeopleFlowCombinedReducer } from '../../store'
import UserDetailsModal from '../../components/Modals/UsersRolesModal'
import { AllScreenNames } from '../../config'
import { UserRepository } from '../../repositories'
import { User, UserProfileSchema } from '../../models'
import { AssociationSettingsRepository } from '../../repositories'
import { Toolbar } from '../../components/GeneralUI/Toolbar'

type UsersRowContent = {
  id: string
  idPassport: string
  username: string
  fullName: string
  roleString: string
  cognitoStatus: string
  userEntity: User
}

type ValidKeys = 'id' | 'username' | 'fullName' | 'roleString' | 'cognitoStatus'

const columnConfig: { id: ValidKeys; label: string; sizeFactor: number }[] = [
  { id: 'fullName', label: 'Name', sizeFactor: 1.2 },
  { id: 'username', label: 'Username', sizeFactor: 1.2 },
  { id: 'roleString', label: 'Roles', sizeFactor: 1.2 },
  { id: 'cognitoStatus', label: 'Password Status', sizeFactor: 1 },
]

interface UsersProps extends RouteComponentProps {
  selectedAssociation: string
  idPassport: string
  password: string
  currentSection: AllScreenNames
  userRepo: UserRepository
  associationRepo: AssociationSettingsRepository
  currentScreen: AllScreenNames
  selectedRole: string
  RequestPic: string
  hasUserManagementAccess: boolean

  updateState: (data: any) => void
  setAllEmployers: (allCompanies: string[]) => void
  changeScreen: (screen: string) => void
}

interface UsersState {
  loadingModalOpen: boolean
  warningModalOpen: boolean
  accountModalOpen: boolean
  forgotPasswordModalOpen: boolean
  settingsModalOpen: boolean
  loadingModalMessage: string
  warningModalHeader: string
  warningModalMessage: string
  downloading: boolean
  tableData: UsersRowContent[]
  selectedRecord?: UsersRowContent
  authorisedItemIds: string[] | null
  tableWidth: GridSize
  selectedRowItemId: string
  searchString: string
  userManagerModalOpen: boolean
  allAccountRoleNames: string[]
  selectedUserData: UserProfileSchema | null
  isUserCreation: boolean
}

class Users extends Component<UsersProps, UsersState> {
  constructor(props: UsersProps) {
    super(props)
    this.sectionHeaderRef = React.createRef()
    this.primaryTableRef = React.createRef()
  }

  sectionHeaderRef: React.RefObject<any>
  primaryTableRef: React.RefObject<DataTable>

  initialModalState = {
    loadingModalOpen: false,
    warningModalOpen: false,
    accountModalOpen: false,
    forgotPasswordModalOpen: false,
    settingsModalOpen: false,
    userManagerModalOpen: false,
  }

  state: UsersState = {
    ...this.initialModalState,
    loadingModalMessage: '',
    warningModalHeader: '',
    warningModalMessage: '',
    downloading: true,
    tableData: [],
    authorisedItemIds: null,
    tableWidth: 10,
    selectedRowItemId: '',
    searchString: '',
    userManagerModalOpen: false,
    selectedUserData: null,
    allAccountRoleNames: [],
    isUserCreation: false,
  }

  componentDidMount() {
    const userEntity = this.props.userRepo.getCurrentUserEntity()
    const personalInfo = userEntity.getPersonalUserInfo()
    if (!isPeopleFlowEmployee(personalInfo)) {
      this.props.history.push('/comingsoon')
      return
    }
    this.props.updateState({
      RequestPic: '',
      currentSection: AllScreenNames.USERS,
      currentScreen: AllScreenNames.USERS,
    })
    this.loadTable()
  }

  componentDidUpdate(prevProps: UsersProps) {
    const screenChanged = prevProps.currentScreen !== this.props.currentScreen
    if (screenChanged) {
      this.loadRequestData()
    }
  }

  async loadTable() {
    if (this.primaryTableRef && this.primaryTableRef.current) {
      this.primaryTableRef.current.reload()
      await this.loadRequestData()
    } else if (this.props.currentSection === AllScreenNames.USERS) {
      setTimeout(() => this.loadTable(), 250)
    }
  }

  async loadRequestData() {
    try {
      this.setState({ loadingModalOpen: true })
      await this.updateAllUsers()
      this.displayRowDataFromState()
      this.setState({ loadingModalOpen: false })
    } catch (error) {
      this.displayWarning(error)
    }
  }

  async updateAllUsers(): Promise<void> {
    const { idPassport, password, selectedAssociation, userRepo, associationRepo } = this.props
    const userService = new UserService()
    const token = await SessionService.prepareAuthTokens(idPassport, password)
    const { allAccountRoleNames } = await userService.refreshAssociationUsers(selectedAssociation, userRepo, token)
    associationRepo.setAllAssocationRoles(selectedAssociation, allAccountRoleNames)
  }

  private displayRowDataFromState(): void {
    const userEntities = Object.values(this.props.userRepo.getAssocationUserEntities())
    const tableData = this.prepareDisplayData(userEntities)
    this.setPrimaryDataAndReload(tableData)
  }

  closeModals() {
    this.setState({ ...this.initialModalState })
  }

  closeRolesModal() {
    this.setState({ ...this.initialModalState, selectedUserData: null })
  }

  async refreshData() {
    this.setState({
      ...this.initialModalState,
      loadingModalOpen: true,
      loadingModalMessage: 'Refreshing data...',
    })
    return this.loadRequestData()
  }

  private setPrimaryDataAndReload(tableData: UsersRowContent[]) {
    this.setState({ tableData, downloading: false }, () => {
      if (!this.primaryTableRef?.current) {
        return
      }
      this.primaryTableRef.current.reload()
    })
  }

  async saveUpdates(userData: UserProfileSchema) {
    try {
      this.setState({ loadingModalOpen: true, userManagerModalOpen: false })
      const { selectedAssociation, idPassport, password, userRepo } = this.props
      const { isUserCreation } = this.state
      const userService = new UserService()
      const token = await SessionService.prepareAuthTokens(idPassport, password)
      await userService.upsertUser(userRepo, selectedAssociation, userData, token, isUserCreation)

      this.displayRowDataFromState()
      this.setState({ loadingModalOpen: false, selectedUserData: null })
    } catch (error) {
      this.displayWarning(error)
    }
  }

  prepareDisplayData(userEntities: User[]): UsersRowContent[] {
    return userEntities.map((userEntity) => {
      const { username, name, surname } = userEntity.getPersonalUserInfo()
      const roleNames = userEntity.getRoleNames()
      return {
        id: username,
        idPassport: username,
        username: username,
        fullName: `${name} ${surname}`,
        roleString:
          roleNames
            .sort()
            .map((roleName) => toUpperCaseCustom(removeUnderscores(roleName)))
            .join('; ') || '',
        cognitoStatus:
          userEntity.getCognitoConfirmationStatus() === 'CONFIRMED' ? 'VALID' : userEntity.getPasswordExpiry(),
        userEntity,
      }
    })
  }

  selectUser(rowItemId: string) {
    this.sectionHeaderRef.current.disableSearch()
    const item = this.state.tableData.find((row) => row.id === rowItemId)
    if (item === undefined) {
      return
    }
    const personalInfo = item.userEntity.getPersonalUserInfo()
    const roleNames = item.userEntity.getRoleNames().map((role) => removeUnderscores(role))
    this.setState({
      selectedRowItemId: rowItemId,
      selectedUserData: { ...personalInfo, roleNames },
      isUserCreation: false,
      userManagerModalOpen: true,
    })
  }

  searchHandler(searchString: string) {
    this.setState({ searchString })
    if (!this.primaryTableRef?.current) {
      return
    }
    this.primaryTableRef.current.search(searchString)
  }

  sideMenuHandler(screen: string) {
    if (completedScreens.includes(screen)) {
      this.props.updateState({ currentScreen: screen })
      this.props.history.push(`/people/${toLowerCaseCustom(screen.split(' ').join(''))}`)
    } else {
      this.props.history.push('/comingsoon')
    }
  }

  displayWarning(error: any) {
    let warning = ''

    if (error instanceof Error) {
      warning = error.message
    } else {
      try {
        if (error.code === 'NetworkError') {
          warning = 'Seems like your internet connection is down. Reconnect to the network, then try again.'
        } else if (error.code === 'InvalidMobile') {
          warning = 'Invalid mobile number'
        } else if (error.code === 'InvalidEmail') {
          warning = 'Invalid email address'
        } else if (error.code === 'InvalidUsername') {
          warning = 'Invalid username'
        } else if (error.code === 'UserAlreadyExists') {
          warning = 'A user with that username already exists'
        } else if (error.code === 'EmptyRoleNames') {
          warning = 'At least one role must be selected'
        } else if ('message' in error) {
          warning =
            "The following error message was returned when logging in:\n\n'" +
            error.message +
            "'. \n\nRefresh the page and try again. If unsuccessful, then contact tech support"
        } else {
          warning =
            'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
        }
      } catch (error) {
        warning = 'We encountered a problem. Refresh the page and try again. If unsuccessful, then contact tech support'
      }
    }

    this.setState({
      ...this.initialModalState,
      warningModalOpen: true,
      warningModalHeader: 'Warning',
      warningModalMessage: warning,
    })
  }

  render() {
    const { associationRepo, selectedAssociation, match, location, history, hasUserManagementAccess, currentScreen } =
      this.props
    const {
      userManagerModalOpen,
      selectedUserData,
      isUserCreation,
      loadingModalOpen,
      loadingModalMessage,
      warningModalOpen,
      warningModalHeader,
      warningModalMessage,
      tableData,
      tableWidth,
      selectedRowItemId,
      authorisedItemIds,
      downloading,
      searchString,
    } = this.state

    let userCreationModal = null
    if (userManagerModalOpen) {
      userCreationModal = (
        <UserDetailsModal
          userData={selectedUserData}
          allAccountRoleNames={associationRepo.getAllAssociationRoles(selectedAssociation)}
          isUserCreation={isUserCreation}
          onSave={(userData: UserProfileSchema) => this.saveUpdates(userData)}
          dismiss={() => this.closeRolesModal()}
        />
      )
    }

    return (
      <div style={styles.container}>
        <NavigationBar
          match={match}
          location={location}
          history={history}
          reloadPageData={() => this.loadRequestData()}
        />

        <SectionHeader
          ref={this.sectionHeaderRef}
          style={styles.sectionHeader}
          downloading={downloading}
          searchString={searchString}
          textHandler={(e) => this.searchHandler(e.target.value)}
          onClick={() => {}}>
          {currentScreen}
        </SectionHeader>

        {userCreationModal}

        <div
          style={{
            ...styles.contentContainer,
            animation: 'x 0.15s ease-out',
            animationName: fadeIn,
          }}>
          <div style={styles.content}>
            <Toolbar
              actionButtons={[
                {
                  iconPath: mdiPlus,
                  onClick: () => this.setState({ userManagerModalOpen: true, isUserCreation: true }),
                  label: 'ADD USER',
                },
              ]}
            />
            <div style={styles.tableContainer}>
              <DataTable
                ref={this.primaryTableRef}
                tableData={tableData}
                columnConfig={columnConfig}
                tableWidth={tableWidth}
                onRowClick={(rowData: Record<string, any>) => this.selectUser(rowData.id)}
                selectedRowItemId={selectedRowItemId}
                authorisedItemIds={authorisedItemIds}
              />
            </div>
          </div>
        </div>

        <AlertModalOneButton
          open={warningModalOpen}
          header={warningModalHeader}
          body={warningModalMessage}
          buttonLabel={'Ok'}
          onClick={() => this.closeModals()}
        />
        <AlertModalOneButton
          open={!hasUserManagementAccess}
          header={'Not Authorised'}
          body={"You don't have permission to manage users."}
          buttonLabel={'Ok'}
          opaqueBackground={true}
          onClick={() => history.goBack()}
        />
        <LoadingModal open={loadingModalOpen}>{loadingModalMessage}</LoadingModal>
      </div>
    )
  }
}

const hidden = {
  marginLeft: window.innerWidth * 0.16,
}

const visible = {
  marginLeft: window.innerWidth * 0.47,
}

const fadeIn = Radium.keyframes({
  '0%': hidden,
  '100%': visible,
}) as Property.AnimationName

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flex: 1,
    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255, 1), rgba(209,210,230, 1))',
    height: '100vh',
  },
  sectionHeader: {
    margin: '3.5% auto 1.5%',
  },
  contentContainer: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
  content: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    paddingInline: 'min(5em, 5%)',
    width: '100%',
    overflow: 'hidden',
  },
  tableContainer: {
    boxShadow: '0px -1px 8px rgba(60,60,60, 0.1)',
    display: 'flex',
    flex: 1,
    backgroundColor: ColorPalette.CARD_WHITE,
  },
  tableName: {
    marginTop: window.innerHeight * 0.055,
    height: window.innerHeight * 0.7,
    width: '94%',
  },
  newRequestButton: {
    fontWeight: 'bolder',
    fontSize: '0.8rem',
    color: ColorPalette.SECONDARY_TEXT,
    height: 40,
    ':hover': {
      color: ColorPalette.PRIMARY_BLUE,
    },
    ':active': {
      color: ColorPalette.DARK_GREY,
    },
  },
}

const mapStateToProps = (state: PeopleFlowCombinedReducer) => {
  return {
    idPassport: state.sessionManager.idPassport,
    password: state.sessionManager.password,
    selectedAssociation: state.sessionManager.selectedAssociation,
    selectedRole: state.sessionManager.selectedRole,
    currentSection: state.sessionManager.currentSection,
    currentScreen: state.sessionManager.currentScreen,
    hasUserManagementAccess: state.sessionManager.hasUserManagementAccess,
    userRepo: state.sessionManager.userRepo as UserRepository,
    associationRepo: state.sessionManager.associationRepo as AssociationSettingsRepository,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateState: (data: any) => dispatch({ type: ActionType.UPDATE_STATE, data }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Radium(Users))
