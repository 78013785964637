import { ISectionConfigItem } from '../interfaces'

export const disciplineSectionConfig: ISectionConfigItem[] = [
  { sectionLabel: 'HISTORY', sectionName: 'history' },
  { sectionLabel: 'ACTIVE CASES', sectionName: 'activeCases' },
]

export const docsSectionConfig: ISectionConfigItem[] = [
  { sectionLabel: 'RECRUITMENT & ONBOARDING', sectionName: 'RECRUITMENT_&_ONBOARDING' },
  { sectionLabel: 'DISCIPLINE', sectionName: 'DISCIPLINE' },
  { sectionLabel: 'PERFORMANCE MANAGEMENT', sectionName: 'PERFORMANCE_MANAGEMENT' },
  { sectionLabel: 'PAYROLL, LEAVE, HEALTH & SAFETY', sectionName: 'PAYROLL,_LEAVE,_HEALTH_&_SAFETY' },
  { sectionLabel: 'TERMINATION', sectionName: 'TERMINATION' },
  { sectionLabel: 'OTHER', sectionName: 'OTHER' },
]

export const importsSectionConfig: ISectionConfigItem[] = [
  { sectionLabel: 'DOCUMENTS', sectionName: 'documents' },
  { sectionLabel: 'PERFORMANCE DATA', sectionName: 'performanceData' },
]
