import React, { Component } from 'react'

import { ColorPalette } from '../../config/colors'
import InfoCollector from '../GeneralUI/InfoCollector/InfoCollector'

interface InfoCollectorProps {
  defaultItems: string[]
  pickerItems?: (string | number)[]
  placeholder: string
  minimumItems: number
  warningMessage: string
  successLabel?: string | undefined
  open: boolean
  screenContainerStyle?: React.CSSProperties | undefined
  header: string | React.ReactChild[]
  cardContainerStyle?: React.CSSProperties | undefined
  subHeader: string
  type?: string
  addOnFirstItemClick?: boolean
  uppercasePickerItems?: boolean
  sort?: (inputValues: string[]) => string[]
  itemAddTransform?: ((data: string) => string) | undefined
  onSuccess: (items: string[]) => void
  onDismiss?: (() => void) | undefined
  onReject: () => void
  dismiss: () => void
  transformInput?: ((data: string) => string) | undefined
  transformOutput?: ((data: string) => string) | undefined
  validateInput?: ((data: string) => boolean) | undefined
  onItemNavigateClick?: ((item: string, items: string[]) => void) | undefined
}

class InfoCollectorModal extends Component<InfoCollectorProps> {
  render() {
    let modalContents = null
    if (this.props.open) {
      modalContents = (
        <div style={{ ...styles.screenContainer, ...this.props.screenContainerStyle }}>
          <button style={styles.backgroundOverlay} onClick={() => this.props.dismiss()} />

          <div style={{ ...styles.cardContainer, ...this.props.cardContainerStyle }}>
            <div style={{ width: '100%', maxHeight: '75vh', overflowY: 'auto' }}>
              <InfoCollector
                defaultItems={this.props.defaultItems}
                pickerItems={this.props.pickerItems}
                placeholder={this.props.placeholder}
                minimumItems={this.props.minimumItems}
                warningMessage={this.props.warningMessage}
                successLabel={this.props.successLabel}
                header={this.props.header}
                subHeader={this.props.subHeader}
                type={this.props.type}
                onSuccess={this.props.onSuccess}
                onReject={this.props.onReject}
                transformInput={this.props.transformInput}
                transformOutput={this.props.transformOutput}
                validateInput={this.props.validateInput}
                onItemNavigateClick={this.props.onItemNavigateClick}
                addOnFirstItemClick={this.props.addOnFirstItemClick}
                itemAddTransform={this.props.itemAddTransform}
                uppercasePickerItems={this.props.uppercasePickerItems}
                sortInputValues={this.props.sort}
              />
            </div>
          </div>
        </div>
      )
    }
    return modalContents
  }
}

let styles = {
  screenContainer: {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    backgroundColor: ColorPalette.MODAL_BACKGROUND_OVERLAY,
    width: window.innerWidth,
    height: window.innerHeight,
    zIndex: 10000,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backgroundOverlay: {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    width: window.innerWidth,
    height: window.innerHeight,
    backgroundColor: 'transparent',
    zIndex: 10000,
    outline: 0,
    border: 'none',
  },
  cardContainer: {
    position: 'absolute' as 'absolute',
    top: 100,
    left: window.innerWidth * 0.36,
    zIndex: 10000,
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    boxShadow: '0px 2px 8px rgba(0, 0, 30, 0.2)',
    backgroundColor: ColorPalette.CARD_WHITE,
    width: window.innerWidth * 0.28,
    padding: 30,
    paddingBottom: 40,
    borderRadius: 8,
  },
}

export default InfoCollectorModal
