export enum AggregationTypesEnum {
  COUNT = 'COUNT',
  VALUE = 'VALUE',
}

export enum OperationsEnum {
  BEGINSWITH = 'beginsWith',
  CONTAINS = 'contains',
  EQUALS = 'equals',
  EXISTS = 'exists',
  DOESNTEXIST = 'doesntExist',
  GREATTHANEQUALTO = 'greatThanEqualTo',
  HASCHANGED = 'hasChanged',
  LESSTHANEQUALTO = 'lessThanEqualTo',
}

export enum FilterTypeEnum {
  FIELD = 'field',
  DOCUMENT = 'document',
}

export enum PeriodTypeEnum {
  WEEK = 'week',
  MONTH = 'month',
}
