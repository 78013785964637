import { toLowerCaseCustom, toUpperCaseCustom } from '../../utils'
import { ColorPalette } from '../../config/colors'
import {
  componentTypes,
  validationOptions,
  bool,
  defaultValueOptions,
  validationTypeMap,
} from '../../config/formFieldSettingsConfig'

import React, { Component } from 'react'
import Radium from 'radium'
import { ScrollView } from '@cantonjs/react-scroll-view'
import { invert } from 'lodash'

import ButtonBlue from '../BaseComponents/Buttons/ButtonBlue'
import CheckboxTabLabelled from '../BaseComponents/Checkboxes/CheckboxTabLabelled'
import PickerContained from '../BaseComponents/Pickers/PickerContained'
import TextInputOutlined from '../BaseComponents/Text/TextInputOutlined'
import InfoCollectorModal from './InfoCollector'
import { PickerHandlerEvent } from '../BaseComponents/Pickers/Picker'

interface DocFieldSettingsModalProps {
  open: boolean
  settings: any
  settingsLocked: boolean

  saveAndClose: (x: any) => void
  dismiss: () => void
}

interface DocFieldSettingsModalState {
  selectorItemCollectorModalOpen: boolean
  open: boolean
  mounted: boolean

  componentType: string
  validationType: string[]
  suggestionsEnabled: string[]
  useDefaultValue: string[]
  defaultValueType: string[]
  maxItems: string
  allowCustomOption: string[]
  compulsory: string[]
  selectorItems: any[]
  selectorItemsDisplay: string
  defaultValue: string
  warning: string
  key: string
  [index: string]: any
}

class DocFieldSettingsModal extends Component<DocFieldSettingsModalProps, DocFieldSettingsModalState> {
  intialModalState = {
    selectorItemCollectorModalOpen: false,
  }

  state = {
    ...this.intialModalState,
    open: false,
    mounted: false,
    componentType: 'Text box (single-line)',
    validationType: ['NONE'],
    suggestionsEnabled: ['NO'],
    useDefaultValue: ['NO'],
    defaultValueType: ["I'll choose a value"],
    maxItems: '',
    allowCustomOption: ['NO'],
    compulsory: ['NO'],
    selectorItems: [],
    selectorItemsDisplay: '',
    defaultValue: '',
    warning: '',
    key: '',
  }

  safeToOpenInfoCollector = false

  componentDidMount() {
    this.setState({
      open: this.props.open,
      mounted: this.props.open,
      componentType: this.settingsMapper(this.props.settings, 'componentType').componentType,
      validationType:
        this.props.settings.validationType in invert(validationTypeMap)
          ? [invert(validationTypeMap)[this.props.settings.validationType]]
          : ['NONE'],
      suggestionsEnabled: this.props.settings.suggestionsEnabled ? ['YES'] : ['NO'],
      useDefaultValue: this.props.settings.useDefaultValue ? ['YES'] : ['NO'],
      defaultValueType: this.props.settings.setDefaultValueFromHistory
        ? ['Last value entered']
        : ["I'll choose a value"],
      defaultValue: this.props.settings.defaultValue ? this.props.settings.defaultValue : '',
      maxItems: this.props.settings.maxItems ? `${this.props.settings.maxItems}` : '',
      allowCustomOption: this.props.settings.allowCustomOption ? ['YES'] : ['NO'],
      compulsory: this.props.settings.compulsory ? ['YES'] : ['NO'],
      selectorItems: this.props.settings.selectorItems ? this.props.settings.selectorItems : [],
      selectorItemsDisplay: this.props.settings.selectorItems ? this.props.settings.selectorItems.join('; \xa0 ') : '',
      key: this.props.settings.key,
      warning: '',
    })
    setTimeout(() => (this.safeToOpenInfoCollector = true), 500)
  }

  componentDidUpdate(prevProps: DocFieldSettingsModalProps) {
    if (prevProps.open !== this.props.open) {
      this.setState({ open: this.props.open }, () =>
        setTimeout(
          () => {
            this.setState({ mounted: this.props.open })
          },
          this.props.open ? 0 : 50,
        ),
      )
    }
  }

  settingsMapper = (mappingData: any, targetVariableName: string): any => {
    try {
      if (targetVariableName === 'compType') {
        if (mappingData.componentType === 'Text box (single-line)') {
          return {
            compType: 'text',
            dateFormat: '',
            multiline: false,
          }
        } else if (mappingData.componentType === 'Text box (multi-line)') {
          return {
            compType: 'text',
            dateFormat: '',
            multiline: true,
          }
        } else if (mappingData.componentType === 'Date') {
          return {
            compType: 'text',
            dateFormat: 'YYYY/MM/DD',
            multiline: false,
          }
        } else {
          return {
            compType: 'selector',
            dateFormat: '',
            multiline: false,
          }
        }
      } else if (targetVariableName === 'componentType') {
        if (mappingData.compType === 'text' && mappingData.dateFormat) {
          return { componentType: 'Date' }
        } else if (mappingData.compType === 'text' && !mappingData.multiline) {
          return { componentType: 'Text box (single-line)' }
        } else if (mappingData.compType === 'text' && mappingData.multiline) {
          return { componentType: 'Text box (multi-line)' }
        } else {
          return { componentType: 'Selector' }
        }
      }
    } catch (error) {}
    return {
      componentType: 'Selector',
      compType: 'selector',
      dateFormat: '',
      multiline: false,
    }
  }

  closeModals = () => {
    this.setState({ ...this.intialModalState })
  }

  onChange(event: PickerHandlerEvent, key: string) {
    this.safeToOpenInfoCollector = false
    this.setState({ [key]: event.target.value })
    setTimeout(() => (this.safeToOpenInfoCollector = true), 500)
  }

  selectionHandler(value: string[], key: string) {
    this.setState({ [key]: value })
  }

  openSelectorItemCollector = () => {
    if (this.safeToOpenInfoCollector) {
      this.setState({ selectorItemCollectorModalOpen: true })
    }
  }

  saveAndClose = () => {
    const settings = {
      ...this.settingsMapper({ componentType: this.state.componentType }, 'compType'),
      validationType: this.state.validationType[0] in validationTypeMap ? this.state.validationType[0] : '',
      suggestionsEnabled: this.state.suggestionsEnabled[0] === 'YES' ? true : false,
      useDefaultValue: this.state.useDefaultValue[0] === 'YES' ? true : false,
      setDefaultValueFromHistory: this.state.defaultValueType[0] === 'Last value entered' ? true : false,
      defaultValue: this.state.defaultValue,
      maxItems: this.state.maxItems ? parseInt(this.state.maxItems) : 1,
      allowCustomOption: this.state.allowCustomOption[0] === 'YES' ? true : false,
      compulsory: this.state.compulsory[0] === 'YES' ? true : false,
      selectorItems: this.state.selectorItems,
      key: this.state.key,
    }
    this.props.saveAndClose(settings)
  }

  render() {
    let textValidation = null
    let suggestionsEnabled = null
    let defaultSelection = null
    let defaultSelectionType = null
    let defaultValue = null

    if (toLowerCaseCustom(this.state.componentType).includes('text') || this.state.componentType === 'Date') {
      if (this.state.componentType !== 'Date') {
        textValidation = (
          <CheckboxTabLabelled
            label={'What type of text validation should be applied to the input?'}
            values={this.state.validationType}
            selectorItems={validationOptions}
            selectionHandler={(values) => this.selectionHandler(values, 'validationType')}
            style={{ width: '82%', marginTop: 40 }}
            itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
            disabled={this.props.settingsLocked}
            maxItemsPerRow={5}
          />
        )
      }
      suggestionsEnabled = (
        <CheckboxTabLabelled
          label={'Is this a smart component? (Show suggestions based on history)'}
          values={this.state.suggestionsEnabled}
          selectorItems={bool}
          selectionHandler={(values) => this.selectionHandler(values, 'suggestionsEnabled')}
          style={{ width: '82%', marginTop: 40 }}
          itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
          disabled={this.props.settingsLocked}
          maxItemsPerRow={5}
        />
      )
      defaultSelection = (
        <CheckboxTabLabelled
          label={'On loading, should this be populated with a default value?'}
          values={this.state.useDefaultValue}
          selectorItems={bool}
          selectionHandler={(values) => this.selectionHandler(values, 'useDefaultValue')}
          style={{ width: '82%', marginTop: 40 }}
          itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
          disabled={this.props.settingsLocked}
          maxItemsPerRow={5}
        />
      )
      if (this.state.useDefaultValue[0] === 'YES') {
        defaultSelectionType = (
          <CheckboxTabLabelled
            label={'What default value should be added?'}
            values={this.state.defaultValueType}
            selectorItems={defaultValueOptions}
            selectionHandler={(values) => this.selectionHandler(values, 'defaultValueType')}
            style={{ width: '82%', marginTop: 40 }}
            itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
            disabled={this.props.settingsLocked}
            maxItemsPerRow={5}
          />
        )
        if (this.state.defaultValueType[0] === "I'll choose a value") {
          defaultValue = (
            <TextInputOutlined
              style={{ width: '82%', marginTop: 40 }}
              label={'Choose a default value'}
              placeholder={'Enter default value'}
              value={this.state.defaultValue}
              textHandler={(event) => this.selectionHandler([event.target.value], 'defaultValue')}
              disabled={this.props.settingsLocked}
            />
          )
        }
      }
    }

    let selectorItems = null
    let maxItems = null
    let allowCustomOption = null
    let warning = null

    if (this.state.componentType === 'Selector') {
      selectorItems = (
        <TextInputOutlined
          style={{ width: '82%', marginTop: 40 }}
          label={'The following options will be presented to the user'}
          placeholder={'Click to add options'}
          value={this.state.selectorItemsDisplay}
          onFocus={this.openSelectorItemCollector}
          textHandler={() => ''}
          type={'textarea'}
          disabled={this.props.settingsLocked}
        />
      )
      maxItems = (
        <TextInputOutlined
          style={{ width: '82%', marginTop: 40 }}
          label={'What is the maximum number of options that can be selected?'}
          placeholder={'Enter a number between 1 and 11'}
          value={this.state.maxItems}
          textHandler={(event) => this.selectionHandler([event.target.value], 'maxItems')}
          disabled={this.props.settingsLocked}
        />
      )
      allowCustomOption = (
        <CheckboxTabLabelled
          label={'Should users be able to add their own option to be selected?'}
          values={this.state.allowCustomOption}
          selectorItems={bool}
          selectionHandler={(values) => this.selectionHandler(values, 'allowCustomOption')}
          style={{ width: '82%', marginTop: 40 }}
          itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
          disabled={this.props.settingsLocked}
          maxItemsPerRow={5}
        />
      )
    }

    if (this.state.warning) {
      warning = <p style={styles.warningTextStyle}>{this.state.warning}</p>
    }

    let compulsory = (
      <CheckboxTabLabelled
        label={'Is it compuslory for users to enter a value?'}
        values={this.state.compulsory}
        selectorItems={bool}
        selectionHandler={(values) => this.selectionHandler(values, 'compulsory')}
        style={{ width: '82%', marginTop: 40 }}
        itemLabelStyle={{ color: ColorPalette.PRIMARY_TEXT }}
        disabled={this.props.settingsLocked}
        maxItemsPerRow={5}
      />
    )

    let modalContents = null
    if (this.state.mounted) {
      modalContents = (
        <>
          <button style={styles.screenContainer} onClick={() => this.props.dismiss()} />
          <div style={styles.cardContainer}>
            <ScrollView style={{ width: '100%' }} contentContainerStyle={styles.scrollStyle}>
              <h1 style={styles.textStyle}>Field settings</h1>
              <p style={styles.sectionHeader}>Data capture settings</p>
              {warning}
              <PickerContained
                style={{ marginTop: 20, alignSelf: 'center', width: '82%' }}
                label={'What type of component should be used to capture the data?'}
                items={componentTypes}
                value={this.state.componentType}
                onChange={(event) => this.onChange(event, 'componentType')}
                disabled={this.props.settingsLocked}
              />
              {suggestionsEnabled}
              {defaultSelection}
              {defaultSelectionType}
              {defaultValue}
              {textValidation}
              {selectorItems}
              {maxItems}
              {allowCustomOption}
              {compulsory}

              <ButtonBlue
                style={{ marginTop: 40, padding: '10px 0px', width: '84%', alignSelf: 'center' }}
                onClick={this.saveAndClose}>
                {'Update and close'}
              </ButtonBlue>
              <div style={{ ...styles.spacer }}></div>
            </ScrollView>
          </div>

          <InfoCollectorModal
            screenContainerStyle={{}}
            cardContainerStyle={{ top: window.innerHeight * 0.1, left: window.innerWidth * 0.13 }}
            open={this.state.selectorItemCollectorModalOpen}
            defaultItems={this.state.selectorItems}
            header={'SELECTOR OPTIONS'}
            subHeader={'Field selector options'}
            warningMessage={'Add at least one item'}
            validateInput={() => true}
            transformInput={toUpperCaseCustom}
            placeholder={'Enter selector option'}
            successLabel={'Update'}
            minimumItems={1}
            dismiss={() => this.closeModals()}
            onSuccess={(selectorItems) =>
              this.setState({
                selectorItems,
                selectorItemsDisplay: selectorItems.join('; \xa0 '),
                selectorItemCollectorModalOpen: false,
              })
            }
            onReject={this.closeModals}
          />
        </>
      )
    }
    return modalContents
  }
}

let styles: Record<string, React.CSSProperties> = {
  screenContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: ColorPalette.MODAL_BACKGROUND_OVERLAY,
    zIndex: 10000,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 0,
    border: 'none',
    overflow: 'hidden',
  },
  cardContainer: {
    zIndex: 10000,
    position: 'absolute',
    top: 10,
    left: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
    backgroundColor: ColorPalette.CARD_WHITE,
    width: 785,
    padding: '20px 0px 0px 0px',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  scrollStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'center',
    maxHeight: window.innerHeight * 0.95,
  },
  textStyle: {
    color: ColorPalette.PRIMARY_TEXT,
    textAlign: 'center',
    alignSelf: 'center',
    fontFamily: 'roboto',
    fontWeight: 'bold',
    fontSize: '1.2rem',
    paddingLeft: 10,
    paddingRight: 10,
  },
  sectionHeader: {
    color: ColorPalette.PRIMARY_TEXT,
    borderBottom: `1px solid ${ColorPalette.PRIMARY_BLUE}`,
    textTransform: 'uppercase',
    paddingBottom: 5,
    width: '84%',
    textAlign: 'center',
    alignSelf: 'center',
    fontFamily: 'roboto',
    fontWeight: '400',
    fontSize: '0.75rem',
    paddingLeft: 10,
    paddingRight: 10,
  },
  spacer: {
    padding: 60,
    width: '90%',
    textAlign: 'center',
    alignSelf: 'center',
  },
  warningTextStyle: {
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: '0.9rem',
    fontWeight: '300',
    marginTop: 30,
    color: ColorPalette.WARNING_RED,
  },
}

export default Radium(DocFieldSettingsModal)
